import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import cross from "../../../../../../img/Cross.png";
import deletee from "../../../../../../img/delete.png";
import config from "../../../../../Main";
import Swal from "sweetalert2";
import axios from "axios";

let authToken = localStorage.getItem("Token");
class Insurance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      car_photo: [
        {
          category: "insurance-police",
          sub_category: "",
          file: null,
          image: null,
        },
      ],
    };
  }

  handleAddFields = () => {
    const values = this.state.car_photo;
    values.push({
      category: "insurance-police",
      sub_category: "",
      file: null,
      image: null,
    });
    this.setState({
      values,
    });
  };

  handleRemoveFields(index) {
    const values = this.state.car_photo;
    values.splice(index, 1);
    this.setState({
      values,
    });
  }
  async onChange(e, index) {
    if (["category", "sub_category"].includes(e.target.name)) {
      let cats = [
        ...this.state.car_photo.filter(
          (c) => c.category === "insurance-police"
        ),
      ];
      cats[index][e.target.name] = e.target.value;
      await this.setState({
        cats,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  async handleImage(e, index) {
    let reader = new FileReader();
    let image = e.target.files[0];
    reader.readAsDataURL(image);

    if (["file"].includes(e.target.name)) {
      reader.onloadend = (e) => {
        let cats = [
          ...this.state.car_photo.filter(
            (c) => c.category === "insurance-police"
          ),
        ];
        let cats1 = [
          ...this.state.car_photo.filter(
            (c) => c.category === "insurance-police"
          ),
        ];
        cats[index]["file"] = reader.result;
        cats1[index]["lol"] = image;
        this.setState({
          cats,
        });
      };
    } else {
      reader.onloadend = (e) => {
        this.setState({
          [e.target.name]: reader.result,
        });
      };
    }

    console.log(this.state.car_photo);
  }
  handleFormSubmit = (event) => {
    event.preventDefault();

    const data = {
      car_photo: [...this.state.car_photo],
    };

    axios({
      method: "POST",
      url: `${config.apiUrl.car}${this.props.pk}/profile/file/`,
      headers: {
        Accept: "application/json",

        Authorization: "Bearer " + JSON.parse(authToken),
      },
      data,
    })
      .then((res) => {
        Swal.fire({
          title: "Car",
          type: "success",
          text: "Car Photo upload  !",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        Swal.fire({
          title: "Car",
          type: "error",
          text: "Error while uploading car photo!",
          timer: 2000,
        });
      });
  };

  componentDidMount() {
    axios
      .get(`${config.apiUrl.car}${this.props.pk}/profile/file/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(authToken),
        },
      })
      .then((res) => {
        this.setState({
          car_photo: [...res.data.car_photo],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { t } = this.props;
    const insurance = this.state.car_photo.filter(
      (c) => c.category === "insurance-police"
    );
    return (
      <div id="insurance" className="modal">
        <a href="# " rel="modal:close" className="float-right text-dark ">
          <img src={cross} alt="Cross" style={{ height: "1.1em" }} />
        </a>
        <p className="mod" style={{ marginTop: "10px" }}>
          {t("insurance_police").toUpperCase()}
        </p>
        <hr></hr>

        <div className="file_upload" style={{ marginTop: "25px" }}>
          <form onSubmit={this.handleFormSubmit}>
            {insurance.map((inputField, index) => (
              <div className="row">
                <div className="form-group">
                  <select
                    name="sub_category"
                    class="form-control donn"
                    value={inputField.sub_category}
                    onChange={(e) => {
                      this.onChange(e, index);
                    }}
                    style={{ width: "220px" }}
                  >
                    <option value="" selected display hidden>
                      Select Category
                    </option>

                    <option value="insurance_police_first_page">
                      {t("insurance_police_first_page")}
                    </option>
                    <option value="insurance_police_second_page">
                      {t("insurance_police_second_page")}
                    </option>
                    <option value="insurance_police_third_page">
                      {t("insurance_police_third_page")}
                    </option>
                    <option value="insurance_police_fourth_page">
                      {t("insurance_police_fourth_page")}
                    </option>
                    <option value="insurance_police_fifth_page">
                      {" "}
                      {t("insurance_police_fifth_page")}
                    </option>
                  </select>
                </div>
                &nbsp; &nbsp;
                <div className="">
                  {inputField.file === null ? (
                    ""
                  ) : (
                    <a
                      className="btn btn-success btn_image"
                      href={URL.createObjectURL(inputField.lol)}
                      target="_blank"
                      rel="noopener noreferrer"
                      type="button"
                    >
                      View Image
                    </a>
                  )}
                </div>
                <div class="form-group">
                  <div class="upload-btn-wrapper Specila_Button">
                    <button class="btn112" style={{ fontSize: "14px" }}>
                      <i className="fa fa-upload"></i>{" "}
                      {inputField.file === null ? "Upload file" : "Change File"}{" "}
                    </button>
                    <input
                      type="file"
                      name="file"
                      accept="image/*"
                      onChange={(e) => {
                        this.handleImage(e, index);
                      }}
                    />
                  </div>
                </div>
                <button
                  className="btn btn-danger"
                  style={{
                    background: "none",
                    border: "none",
                    marginTop: "-20px",
                  }}
                  type="button"
                  onClick={() => this.handleRemoveFields(index)}
                >
                  <img src={deletee} alt="Delete" />
                </button>
              </div>
            ))}

            {insurance.length === 5 ? (
              ""
            ) : (
              <a
                href="# "
                onClick={this.handleAddFields}
                className="p font-weight-bolder"
                style={{ color: "#b71c1c" }}
              >
                {t("add_photo")}
              </a>
            )}

            <div style={{ marginTop: "70px" }}>
              <center>
                <button
                  type="submit"
                  value="Get Data"
                  className="btn btn-login text-white font-weight-bolder boxx btn-vehicle"
                  id="button1"
                  style={{}}
                >
                  {t("save").toUpperCase()}
                </button>
              </center>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Insurance);
