import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import Recommendation from "../../../Components/Layout/Recommendation";
import Search from "../Search";
import config from "../../Main";
import User from "../../../Components/User";
import Datee from "../../Views/Date";
import { withTranslation } from "react-i18next";
import arrow from "../../../img/arrow.png";
import moment from "moment";
import Spinner from "../../../Container/Spinner";
import print from "../../../img/Rating/print.png";
import getPrint from "../../../Service/Arrow/Print";
import Summary from "../../../Service/Arrow/Car/Summary";
import compareByAsc1 from "../../../Service/Arrow/Sort/Asc1";
import compareByDesc1 from "../../../Service/Arrow/Sort/Desc1";
import DropDown from "../../../Service/Arrow/Car/DropDown";
import arrowL from "../../../Service/Arrow/Arrow";
import { getMyCar } from "../../../Service/Car";

class Th extends React.Component {
  handleClick = () => {
    const { onClick, id } = this.props;
    onClick(id);
  };
  render() {
    const { value } = this.props;
    return (
      <th onClick={this.handleClick} className="t" id="hell1">
        {value} <img src={arrow} alt="Arrow" style={{ height: "0.9em" }} />
      </th>
    );
  }
}
class SpeedFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walk: [],
      currentSort: "default",
      value: "select",
      speed: [],
      car: [],
      isLoading: true,
    };
    this.onSubmitt = this.onSubmitt.bind(this);
  }

  change(event) {
    this.setState({
      value: event.target.value,
    });
  }

  async onSubmitt(e) {
    try {
      let target = e.target;
      let value = target.value;
      e.preventDefault();
      const res = await fetch(
        `${config.apiUrl.car}?type=${value}`,
        config.head
      );
      const walk = await res.json();
      this.setState({
        walk,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async componentDidMount() {
    try {
      Promise.all([
        fetch(
          `${config.apiUrl1.myCar}?date__lte=${this.props.match.params.to}&date__gte=${this.props.match.params.from}&overspeeding_percentage__lte=${this.props.match.params.overspeeding_percentage__lte}&overspeeding_percentage__gt=${this.props.match.params.overspeeding_percentage__gt}`,
          config.head
        )
          .then((walk) => walk.json())
          .then((walk) => {
            walk.sort(
              (a, b) => a.overspeeding_percentage - b.overspeeding_percentage
            );
            this.setState({
              ...this.state,
              walk,
              isLoading: false,
            });
          }),
        fetch(
          `${config.apiUrl.carsummary}?date__lte=${this.props.match.params.to}&date__gte=${this.props.match.params.from}&overspeeding_percentage__lte=${this.props.match.params.overspeeding_percentage__lte}&overspeeding_percentage__gt=${this.props.match.params.overspeeding_percentage__gt}`,
          config.head
        )
          .then((speed) => speed.json())
          .then((speed) => {
            this.setState({
              speed,
            });
          }),
      ]);
    } catch (e) {
      console.log(e);
    }
  }

  getCarType = () => {
    getMyCar().then((data) => {
      this.setState({ car: data });
    });
  };

  sortBy(key) {
    let arrayCopy = [...this.state.walk];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(compareByAsc1(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(compareByDesc1(key));
    }
    this.setState({ walk: arrayCopy });
  }

  render() {
    const { walk, speed } = this.state;
    const { t } = this.props;

    const uniqueDates1 = [...new Set(this.state.car.map((i) => i.type))];

    const options11 = [];

    for (var i = 0; i < uniqueDates1.length; i++) {
      const don = uniqueDates1[i];

      options11.push(
        <option value={don} key={don}>
          {t("Engine_type")}:{don}
        </option>
      );
    }

    var d = new Date();
    var mm = d.getMonth() + 1;
    var dd = d.getDate();
    var yy = d.getFullYear();

    var cc = yy + "-" + mm + "-" + dd; //(US)
    const base = process.env.PUBLIC_URL;

    return (
      <div className="" style={{ marginTop: "20px" }}>
        <Helmet>
          <title> CARS </title>
        </Helmet>
        <div className="row row1" style={{ width: "100%" }}>
          <div className="col-sm-3" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
        </div>

        <div style={{ marginTop: "-15px" }}>
          <Recommendation />

          <div className="headerr">
            <div>
              <div
                className=" "
                style={{ width: "100%", marginBottom: "-30px" }}
              >
                <span
                  className="nav-item  float-right  "
                  disabled
                  style={{ display: "flex" }}
                >
                  <Search /> &nbsp; &nbsp;
                  <a href="# " onClick={getPrint}>
                    <img
                      src={print}
                      alt="contact"
                      style={{ marginTop: "-4px", height: "3em" }}
                    />
                  </a>
                </span>
              </div>
              <div style={{ marginTop: "40px" }}>
                <p className="font-weight-bolder">
                  {t("from")}{" "}
                  {this.props.match.params.from === "2016-01-04"
                    ? "-"
                    : `${moment(this.props.match.params.from).format(
                        "DD-MM-YYYY"
                      )}`}{" "}
                  {t("to")}
                  {this.props.match.params.to === cc
                    ? "-"
                    : `${moment(this.props.match.params.to).format(
                        "DD-MM-YYYY"
                      )}    `}{" "}
                </p>

                {walk.length > 0 ? (
                  <p></p>
                ) : (
                  <p>
                    <strong>&nbsp;&nbsp; {t("no_data")}</strong>
                  </p>
                )}
              </div>
            </div>

            <div
              className="caaar table-responsive"
              style={{ marginTop: "-40px", width: "100%" }}
            >
              {this.state.isLoading === true ? (
                <Spinner />
              ) : (
                <table className="table tabell1  table-hover" id="headerr">
                  <thead>
                    <tr className="th">
                      <th style={{ width: "2%" }} className=" t">
                        {t("Id")}
                      </th>
                      <th className="t">{t("plate_number")} </th>

                      <th className=" ">
                        <select
                          name="engine"
                          onChange={this.onChange}
                          style={{
                            marginTop: "12px",
                            position: "relative",
                            top: "0px",
                            fontSize: "10px",
                            width: "140px",
                          }}
                          class="minimal"
                        >
                          <option value="">
                            {" "}
                            {t("Engine_type")}: {t("AllCar")}{" "}
                          </option>
                          {options11}
                        </select>
                      </th>
                      <Th
                        onClick={() => this.sortBy("mileage")}
                        id="mileage"
                        value={t("Milage")}
                      />

                      <Th
                        onClick={() => this.sortBy("fuel_consumed")}
                        id="fuel_consumed"
                        value={t("FuelComsumed")}
                      />
                      <Th
                        onClick={() => this.sortBy("average_fuel_consumed")}
                        id="average_fuel_consumed"
                        value={t("AverageFuel")}
                      />

                      <Th
                        onClick={() => this.sortBy("overspeeding_percentage")}
                        id="overspeeding_percentage"
                        value={t("OverspeedingDistance")}
                      />
                      <Th
                        onClick={() => this.sortBy("dtc")}
                        id="dtc"
                        value={t("dtc")}
                      />

                      {/* For Weekend  driving */}

                      <th className="t">
                        <select
                          onChange={this.change.bind(this)}
                          value={this.state.value1}
                          defaultValue="1"
                          style={{
                            width: "200px",
                            top: "7px",
                          }}
                          class="minimal t"
                        >
                          <option value="1"> {t("weekend_driving")} </option>
                          <option value="2"> {t("weekend_milage")} </option>
                          <option value="3"> {t("weekend_fuel")} </option>
                        </select>
                      </th>

                      {/*  end weekend driving */}

                      {/* For Off Hours driving */}
                      <th className="t">
                        <select
                          onChange={this.change.bind(this)}
                          value={this.state.value1}
                          class="minimal"
                          style={{
                            width: "200px",
                            top: "7px",
                          }}
                        >
                          <option value="7"> {t("off_hour_driving")} </option>
                          <option value="9"> {t("off_hour_fuel")} </option>
                          <option value="8"> {t("off_hour_milage")} </option>
                        </select>
                      </th>

                      {/*  end Off hour driving */}

                      {/* For Off Zone driving */}

                      <th className="t">
                        <select
                          onChange={this.change.bind(this)}
                          value={this.state.value1}
                          class="minimal"
                          style={{
                            width: "200px",
                            top: "7px",
                          }}
                        >
                          <option value="4"> {t("off_zone_driving")} </option>
                          <option value="5"> {t("off_zone_fuel")} </option>
                          <option value="6"> {t("off_zone_milage")} </option>
                        </select>
                      </th>
                      {/*  end Off ZOne driving */}

                      <Th
                        onClick={() => this.sortBy("costs")}
                        id="costs"
                        value={t("total_cost")}
                      />

                      <th></th>
                    </tr>
                  </thead>

                  {/* Thead End here */}

                  <tbody>
                    {walk.map((c, i) => (
                      <tr key={c.id}>
                        <td>{i + 1}</td>
                        <td>
                          <a
                            href={base + "/overview/carmetrics/" + c.id}
                            className="font-weight-bold"
                            style={{ color: "#b71c1c" }}
                          >
                            {c.plate_number ? `${c.plate_number}` : " ? "}
                          </a>
                        </td>
                        <td>
                          {this.state.value1 === "11"
                            ? `${c.type ? `${(c.type = "Diesel")}` : "-"}`
                            : `${
                                this.state.value1 === "12"
                                  ? `${c.type ? `${(c.type = "PB")}` : "-"}`
                                  : `${c.type ? `${c.type}` : "-"}`
                              }`}
                        </td>
                        <td>
                          {c.span.mileage.value
                            ? `${c.span.mileage.value.toFixed(1)}`
                            : "-"}{" "}
                          {arrowL(
                            c.span.mileage.changeDirection,
                            c.span.mileage.changePositive,
                            c.span.mileage.change
                          )}
                        </td>
                        <td>
                          {c.span.fuel_consumed.value
                            ? `${c.span.fuel_consumed.value.toFixed(1)}`
                            : "-"}
                          {arrowL(
                            c.span.fuel_consumed.changeDirection,
                            c.span.fuel_consumed.changePositive,
                            c.span.fuel_consumed.change
                          )}
                        </td>

                        <td>
                          {c.span.average_fuel_consumed.value
                            ? `${c.span.average_fuel_consumed.value.toFixed(1)}`
                            : "-"}
                          {arrowL(
                            c.span.average_fuel_consumed.changeDirection,
                            c.span.average_fuel_consumed.changePositive,
                            c.span.average_fuel_consumed.change
                          )}
                        </td>

                        <td>
                          {c.span.overspeeding_percentage.value
                            ? `${c.span.overspeeding_percentage.value.toFixed(
                                1
                              )}`
                            : "-"}
                          {arrowL(
                            c.span.overspeeding_percentage.changeDirection,
                            c.span.overspeeding_percentage.changePositive,
                            c.span.overspeeding_percentage.change
                          )}
                        </td>
                        <td>
                          {c.span.dtc.value
                            ? `${c.span.dtc.value.toFixed(1)}`
                            : "-"}
                          {arrowL(
                            c.span.dtc.changeDirection,
                            c.span.dtc.changePositive,
                            c.span.dtc.change
                          )}
                        </td>
                        <td>
                          {DropDown(
                            this.state.value1,
                            c.span.weekend_driving,
                            c.span.weekend_milage,
                            c.span.weekend_fuel_consumed,
                            "1",
                            "2",
                            "3"
                          )}
                        </td>
                        <td>
                          {DropDown(
                            this.state.value1,
                            c.span.off_hour_driving,
                            c.span.off_hour_milage,
                            c.span.off_hour_consumed,
                            "7",
                            "8",
                            "9"
                          )}
                        </td>
                        <td>
                          {DropDown(
                            this.state.value1,
                            c.span.off_zone_driving,
                            c.span.off_zone_milage,
                            c.span.off_zone_fuel_consumed,
                            "4",
                            "5",
                            "6"
                          )}
                        </td>
                        <td>
                          {c.span.costs.value
                            ? `${c.span.costs.value.toFixed(1)}`
                            : "-"}
                          {arrowL(
                            c.span.costs.changeDirection,
                            c.span.costs.changePositive,
                            c.span.costs.change
                          )}
                        </td>
                        <td>
                          {" "}
                          <a
                            href={
                              base + "/overview/my/car/" +
                              c.id +
                              "/" +
                              c.plate_number +
                              "/" +
                              c.type
                            }
                            className="font-weight-bold"
                            style={{ color: "#b71c1c" }}
                          >
                            {t("trip").toUpperCase()}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>

                  <tfoot>
                    <tr className="thead sorttable_nosort">
                      <th className="sorttable_nosort"></th>

                      <th className="sorttable_nosort">
                        {" "}
                        {t("summary").toUpperCase()}
                      </th>
                      <th className="sorttable_nosort"></th>
                      <th className="sorttable_nosort">
                        {speed.milage ? `${speed.milage.toFixed(1)}` : "-"}
                      </th>
                      <th className="sorttable_nosort">
                        {speed.fuel_consumed
                          ? `${speed.fuel_consumed.toFixed(1)}`
                          : "-"}
                      </th>

                      <th className="sorttable_nosort">
                        {speed.average_fuel_consumed
                          ? `${speed.average_fuel_consumed.toFixed(1)}`
                          : "-"}
                      </th>

                      <th className="sorttable_nosort">
                        {speed.overspeeding_percentage
                          ? `${speed.overspeeding_percentage.toFixed(1)}`
                          : "-"}
                      </th>
                      <th className="sorttable_nosort">
                        {speed.dtc ? `${speed.dtc}` : "-"}{" "}
                      </th>
                      <th className="sorttable_nosort">
                        {Summary(
                          this.state.value1,
                          speed.weekend_driving,
                          speed.weekend_milage,
                          speed.weekend_fuel_consumed,
                          "1",
                          "2",
                          "3"
                        )}
                      </th>
                      <th className="sorttable_nosort">
                        {Summary(
                          this.state.value1,
                          speed.off_hour_driving,
                          speed.off_hour_milage,
                          speed.off_hour_consumed,
                          "7",
                          "8",
                          "9"
                        )}
                      </th>
                      <th className="sorttable_nosort">
                        {Summary(
                          this.state.value1,
                          speed.off_zone_driving,
                          speed.off_zone_milage,
                          speed.off_zone_fuel_consumed,
                          "4",
                          "5",
                          "6"
                        )}{" "}
                      </th>
                      <th className="sorttable_nosort">
                        {speed.costs ? `${speed.costs}` : "-"}
                      </th>
                      <th className="sorttable_nosort"></th>
                    </tr>
                  </tfoot>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(SpeedFilter));
