import React, { Component } from "react";
// import Form from "../../Views/Form";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header1 from "../../../Components/Layout/Header1";
import Search from "../Search";
import config from "../../Main";
import Datee from "../../Views/Date";
import User from "../../../Components/User";
import { withTranslation } from "react-i18next";
import arrow from "../../../img/arrow.png";

class FilterNorm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walk: [],
      currentSort: "default",
      value: "select",
      speed: [],
    };
    this.onSubmitt = this.onSubmitt.bind(this);
  }

  change(event) {
    this.setState({
      value: event.target.value,
    });
  }

  async onSubmitt(e) {
    try {
      let target = e.target;
      let value = target.value;
      e.preventDefault();
      const res = await fetch(
        `${config.apiUrl.car}?type=${value}`,
        config.head
      );
      const walk = await res.json();
      this.setState({
        walk,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async componentDidMount() {
    try {
      Promise.all([
        fetch(
          `${config.apiUrl.car}?date__year_month=${this.props.match.params.state}&fuel_ratio__lte=${this.props.match.params.fuel_ratio__lte}&fuel_ratio__gt=${this.props.match.params.fuel_ratio__gt}`,
          config.head
        )
          .then((walk) => walk.json())
          .then((walk) => {
            walk.sort(
              (a, b) => b.average_fuel_consumed - a.average_fuel_consumed
            );
            this.setState({
              ...this.state,
              walk,
            });
          }),
        fetch(
          `${config.apiUrl.carsummary}?date__year_month=${this.props.match.params.state}&fuel_ratio__lte=${this.props.match.params.fuel_ratio__lte}&fuel_ratio__gt=${this.props.match.params.fuel_ratio__gt}`,
          config.head
        )
          .then((speed) => speed.json())
          .then((speed) => {
            this.setState({
              speed,
            });
          }),
      ]);
    } catch (e) {
      console.log(e);
    }
  }

  compareByAsc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByDesc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return 1;
      if (a[key] > b[key]) return -1;
      return 0;
    };
  }

  sortBy(key) {
    let arrayCopy = [...this.state.walk];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(this.compareByAsc(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(this.compareByDesc(key));
    }
    this.setState({ walk: arrayCopy });
  }

  render() {
    const { walk } = this.state;
    const { t } = this.props;

    var d = new Date();
    var mm = d.getMonth() + 1;
    var dd = d.getDate();
    var yy = d.getFullYear();

    var cc = yy + "-" + mm + "-" + dd; //(US)

    var makemonth = new Date().getMonth();
    var makeyear = new Date().getFullYear();

    var makemonth1 = new Date(this.props.match.params.state).getMonth();
    var makeyear1 = new Date(this.props.match.params.state).getFullYear();

    const month =
      makemonth1 === 0
        ? t("january")
        : `${
            makemonth1 === 1
              ? t("feburuary")
              : `${
                  makemonth1 === 2
                    ? t("march")
                    : `${
                        makemonth1 === 3
                          ? t("april")
                          : `${
                              makemonth1 === 4
                                ? t("may")
                                : `${
                                    makemonth1 === 5
                                      ? t("june")
                                      : `${
                                          makemonth1 === 6
                                            ? t("july")
                                            : t("december")
                                        }`
                                  }`
                            }`
                      }`
                }`
          }`;
    const base = process.env.PUBLIC_URL;
    return (
      <div className="" style={{ marginTop: "20px" }}>
        <Helmet>
          <title> Filter Norm </title>
        </Helmet>
        <div className="row row1" style={{ width: "100%" }}>
          <div className="col-sm-3" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
        </div>

        <div style={{ marginTop: "-15px" }}>
          <Header1 />
          <div className="headerr">
            <div className="row" style={{ width: "100%" }}>
              <div style={{ marginTop: "5px" }} className="col-sm-4">
                <Search />
              </div>

              <div style={{ marginTop: "10px" }} className="col-sm-8">
                <p
                  className="font-weight-bolder"
                  style={{ marginTop: "-25px" }}
                >
                  {this.props.match.params.state === cc ? (
                    <span>
                      {makeyear} -{" "}
                      {makemonth === 2
                        ? t("feburuary")
                        : `${makemonth === 3 ? t("march") : t("april")}`}
                    </span>
                  ) : (
                    <span>
                      {makeyear1} - {month}
                    </span>
                  )}{" "}
                </p>
              </div>
            </div>

            <div className="caaar" style={{ marginTop: "-40px" }}>
              <table
                id="myTable"
                className="table  table-hover"
                style={{ marginTop: "20px", width: "100%" }}
                //
              >
                {/* Thead Starthere */}

                <thead>
                  <tr className="th">
                    <th style={{ width: "5%" }} className="t ">
                      {t("Id")}
                    </th>
                    <th
                      className="t"
                      onClick={() => this.sortBy("plate_number")}
                      style={{ width: "100px" }}
                    >
                      {t("plate_number")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>

                    <th
                      className="t"
                      style={{ width: "100px" }}
                      onClick={() => this.sortBy("fuel_norm")}
                    >
                      {t("monthly_norm")}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>

                    <th
                      onClick={() => this.sortBy("average_fuel_consumed")}
                      className="t"
                      style={{ width: "145px" }}
                    >
                      {t("AverageFuel")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>

                    <th
                      onClick={() => this.sortBy("milage")}
                      className="t"
                      style={{ width: "155px" }}
                    >
                      {t("distance")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>

                    <th className="t">{t("Brand")}</th>
                    <th className="t">{t("Model")}</th>

                    <th className="sorttable_nosort ">
                      <select
                        name="engine"
                        onChange={this.onSubmitt.bind(this)}
                        style={{
                          marginTop: "12px",
                          position: "relative",
                          top: "6px",
                          fontSize: "10px",
                          width: "140px",
                        }}
                        class="minimal"
                      >
                        <option value="">
                          {" "}
                          {t("Engine_type")}: {t("AllCar")}{" "}
                        </option>
                        <option value="Pb">{t("Engine_type")}: Pb</option>
                        <option value="ON"> {t("Engine_type")}: ON </option>
                        <option value="HEV">{t("Engine_type")}: HEV </option>
                        <option value="BEV">{t("Engine_type")}: BEV </option>
                        <option value="PHEV">{t("Engine_type")}: PHEV </option>
                        <option value="CNG">{t("Engine_type")}: CNG </option>
                        <option value="FCEV">{t("Engine_type")}: FCEV </option>
                      </select>
                    </th>
                    <th
                      onClick={() => this.sortBy("engine_capacity")}
                      className="t"
                    >
                      {t("engine_capacity1")}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>

                    <th
                      onClick={() => this.sortBy("engine_power")}
                      className="t"
                    >
                      {t("monthly_hors_power")}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                    <th
                      onClick={() => this.sortBy("production_year")}
                      className="t"
                    >
                      {t("production_year")}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {walk.map((c, i) => (
                    <tr key={c.id}>
                      <td>{i + 1}</td>
                      <td>
                        {" "}
                        <a
                          href={base + "/overview/carmetrics/" + c.id}
                          style={{ color: "#b71c1c" }}
                          className="font-weight-bold"
                        >
                          {c.plate_number}{" "}
                        </a>
                      </td>
                      <td>
                        {c.fuel_norm ? `${c.fuel_norm.toFixed(1)}` : "0.0"}
                      </td>
                      <td>
                        {c.average_fuel_consumed
                          ? `${c.average_fuel_consumed.toFixed(1)}`
                          : "-"}
                      </td>
                      <td>{c.milage ? `${c.milage.toFixed(1)}` : "-"}</td>
                      <td>{c.brand ? `${c.brand}` : "-"}</td>
                      <td>{c.model ? `${c.model}` : "-"}</td>
                      <td>{c.type ? `${c.type}` : "-"}</td>
                      <td>
                        {c.engine_capacity
                          ? `${c.engine_capacity.toFixed(1)}`
                          : "-"}
                      </td>
                      <td>
                        {c.engine_power ? `${c.engine_power.toFixed(1)}` : "-"}
                      </td>
                      <td>
                        {c.production_year ? `${c.production_year}` : "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(FilterNorm));
