import React, { Component } from "react";
import { Helmet } from "react-helmet";
import config from "../../../Main";
import { withRouter } from "react-router-dom";
import Search from "./Search";
import { withTranslation } from "react-i18next";
import User from "../../../../Components/User";
import Datee from "../../../Views/Date";
import Insurance from "../../../../Components/Layout/Insurance";
import Spinner from "../../../../Container/Spinner";
import { getMyCar } from "../../../../Service/Car";
// import Doc from "./Image/Doc";
import "./Car.css";

class InsuranceCarMetrics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: [],
      value: "select",
      vin: " ",
      plate: " ",
      brand: " ",
      model: " ",
      location: "",
      tmonth: "",
      tyear: "",
      imonth: "",
      iyear: "",
      year: "",
      engine: "",
      staus: "",
      car: [],
      is_loading: true,
    };
  }

  async onSubmitt1(e) {
    try {
      const res = await fetch(
        `${config.apiUrl.car}?brand__icontains=${this.state.brand}&location__icontains=${this.state.location}&model__icontains=${this.state.model}&vin__icontains=${this.state.vin}&license_plate__icontains=${this.state.plate}&production_year=${this.state.year}&type=${this.state.engine}&active=${this.state.staus}&next_registration_exam__month=${this.state.tmonth}&next_registration_exam__year=${this.state.tyear}&insurance_expiration__month=${this.state.imonth}&insurance_expiration__year=${this.state.iyear}`,
        config.head
      );
      const stats = await res.json();
      this.setState({
        stats,
        is_loading: false,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async onChange(e) {
    await this.setState({
      [e.target.name]: e.target.value,
    });

    this.onSubmitt1("");
  }

  getCarType = () => {
    getMyCar().then((data) => {
      this.setState({ car: data });
    });
  };

  componentDidMount() {
    this.onSubmitt1("");
    this.getCarType("");
  }

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }
  render() {
    const { stats } = this.state;
    const { t } = this.props;

    const uniqueDates1 = [...new Set(this.state.car.map((i) => i.type))];
    const options11 = [];
    for (var i = 0; i < uniqueDates1.length; i++) {
      const don = uniqueDates1[i];

      options11.push(
        <option value={don} key={don}>
          {t("Engine_type")}:{don}
        </option>
      );
    }
    const base = process.env.PUBLIC_URL;

    return (
      <div className="Main_section">
        {/* <Doc /> */}
        <Helmet>
          <title>Insurance Car Metrics</title>
        </Helmet>
        <div
          className="row row1"
          style={{
            width: "100%",
            marginTop: "-20px",
            marginBottom: "-40px",
          }}
        >
          {" "}
          <div className="col-sm-3 date_time">
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
        </div>
        <Insurance />

        <div className="headerr" style={{ marginTop: "-6px" }}>
          <div>
            {stats.length > 0 ? (
              stats.slice(0, 1).map((c) => (
                <strong hidden key={c.id}>
                  {c.participantCount}
                </strong>
              ))
            ) : (
              <strong>&nbsp;&nbsp; {t("no_data")}</strong>
            )}
          </div>
          <div
            className="caaar table-responsive"
            style={{ width: "100%", marginTop: "40px" }}
          >
            {this.state.is_loading === true ? (
              <Spinner />
            ) : (
              <table className="table tabell1  table-hover" id="headerr">
                <thead>
                  <tr style={{ marginTop: "-88px" }}>
                    <th className="t" style={{ width: "20px" }}>
                      {t("Id")}
                    </th>
                    <th className="t">{t("vin")}</th>
                    <th className="t">{t("plate_number1")}</th>
                    <th className="t">
                      <select
                        name="engine"
                        onChange={this.onChange.bind(this)}
                        className="minimal"
                        style={{
                          marginTop: "12px",
                          position: "relative",
                          top: "5px",
                          width: "150px",
                        }}
                      >
                        <option value="">
                          {" "}
                          {t("Engine_type")}: {t("AllCar")}{" "}
                        </option>
                        {options11}
                      </select>
                    </th>
                    <th className="t">{t("Brand")}</th>
                    <th className="t">{t("Model")}</th>
                    <th className="t">{t("production_year")}</th>
                    <th className="t">{t("energy_capacity_cm3")}</th>
                    <th className="t">{t("energy_power_kw")}</th>
                    <th className="t">{t("insurance_policy")}</th>
                    <th className="t">{t("technical_inspection")}</th>
                    <th className="t">{t("number_of_claims")}</th>
                    <th className="t">{t("vehicle-registration")}</th>
                    <th className="t">{t("vehicle-photo")}</th>
                    <th className="t">{t("police_photos")}</th>
                    <th className="t">{t("claim_doc")}</th>
                    <th className="t">{t("see_all")}</th>
                    <th className="t">
                      <select
                        name="staus"
                        onChange={this.onChange.bind(this)}
                        className="minimal"
                        style={{ width: "130px", top: "5px" }}
                      >
                        <option value=" "> {t("status")}</option>
                        <option value="true">{t("active")} </option>
                        <option value="false">{t("disable")}</option>
                      </select>
                    </th>
                  </tr>
                </thead>

                <Search load={this.onChange.bind(this)} vall={this.state} />

                <tbody>
                  {stats.length > 0 ? (
                    stats.map((c, i) => (
                      <tr key={c.id}>
                        <td>{i + 1}</td>
                        <td>{c.vin ? `${c.vin}` : "-"}</td>
                        <td>
                          <a
                            href={base + "/overview/app/carmetrics/" + c.id+"/"}
                            style={{ color: "#b71c1c" }}
                            className="font-weight-bold"
                          >
                            {c.plate_number ? `${c.plate_number}` : "-"}
                          </a>
                        </td>
                        <td>{c.type ? `${c.type}` : "-"}</td>
                        <td>{c.brand ? `${c.brand}` : "-"}</td>
                        <td>{c.model ? `${c.model}` : "-"}</td>
                        <td>
                          {c.production_year ? `${c.production_year}` : "-"}
                        </td>
                        <td>{c.engine_capacity ? c.engine_capacity : "-"}</td>
                        <td>{c.engine_power ? c.engine_power : "-"}</td>

                        <td>
                          {c.insurance_expiration
                            ? `${c.insurance_expiration}`
                            : "-"}
                        </td>
                        <td>
                          {c.next_registration_exam
                            ? `${c.next_registration_exam}`
                            : "-"}
                        </td>
                        <td>-</td>
                        <td>
                          {" "}
                          <label class="container1q">
                            <input
                              type="checkbox"
                              readOnly
                              checked={c.has_vehicle_registration}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                        <td>
                          <label class="container1q">
                            <input
                              type="checkbox"
                              readOnly
                              checked={c.has_vehicle_photo}
                            />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                        <td>
                          <label class="container1q">
                            <input type="checkbox" readOnly checked={false} />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                        <td>
                          <label class="container1q">
                            <input type="checkbox" readOnly checked={false} />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                        <td>
                          {" "}
                          <a
                            href="#contact"
                            rel="modal:open"
                            className={"btn btn-see"}
                            id="dd"
                          >
                            {t("see")}
                          </a>
                        </td>

                        <td>
                          <button
                            className={
                              c.status === "active"
                                ? "btn btn-success"
                                : "btn btn-danger"
                            }
                            id="dd"
                          >
                            {c.status === "active"
                              ? t("active_status")
                              : t("inactive_status")}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div></div>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(InsuranceCarMetrics));
