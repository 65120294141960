import React, { Component } from "react";
import Header1 from "../../../Components/Layout/Header1";
import Search from "./../Search";
// import Search from "../../Cars/Search"
import { Helmet } from "react-helmet";
import config from "../../Main";
import User from "../../../Components/User";
import Datee from "../../Views/Date";
import { withTranslation } from "react-i18next";
import arrow from "../../../img/arrow.png";
import Spinner from "../../../Container/Spinner";
import compareByAsc from "../../../Service/Arrow/Sort/Asc";
import compareByDesc from "../../../Service/Arrow/Sort/Desc";
import "../index";

class CritialOverspeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      todos: [],
      speed: [],
      car: [],
      car_speed: [],
      value: "select",
      lol: "",
      fol: "",
      switcher: "driver",
      isLoading: true,
    };
  }

  change(event) {
    this.setState({
      value: event.target.value,
    });
  }
  getDriverList() {
    try {
      Promise.all([
        fetch(
          `${config.apiUrl.driver}?critical_overspeed__gt=0&date__lte=${this.props.match.params.date__lte}&date__gte=${this.props.match.params.date__gte}`,
          config.head
        )
          .then((todos) => todos.json())
          .then((todos) => {
            todos.sort((a, b) => b.critical_overspeed - a.critical_overspeed);
            this.setState({
              ...this.state,
              todos,
              isLoading: false,
            });
          }),
        fetch(
          `${config.apiUrl.driversummary}?critical_overspeed__gt=0&date__lte=${this.props.match.params.date__lte}&date__gte=${this.props.match.params.date__gte}`,
          config.head
        )
          .then((speed) => speed.json())
          .then((speed) => {
            this.setState({
              speed,
            });
          }),
      ]);
    } catch (e) {
      console.log(e);
    }
  }

  getCarList() {
    try {
      Promise.all([
        fetch(
          `${config.apiUrl.car}?critical_overspeed__gt=0&date__lte=${this.props.match.params.date__lte}&date__gte=${this.props.match.params.date__gte}`,
          config.head
        )
          .then((car) => car.json())
          .then((car) => {
            car.sort((a, b) => b.critical_overspeed - a.critical_overspeed);
            this.setState({
              ...this.state,
              car,
              isLoading: false,
            });
          }),
        fetch(
          `${config.apiUrl.carsummary}?critical_overspeed__gt=0&date__lte=${this.props.match.params.date__lte}&date__gte=${this.props.match.params.date__gte}`,
          config.head
        )
          .then((car_speed) => car_speed.json())
          .then((car_speed) => {
            this.setState({
              car_speed,
            });
          }),
      ]);
    } catch (e) {
      console.log(e);
    }
  }

  async componentDidMount() {
    this.getDriverList("");
    this.getCarList("");
  }

  sortBy(key) {
    let arrayCopy = [...this.state.todos];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(compareByAsc(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(compareByDesc(key));
    }
    this.setState({ todos: arrayCopy });
  }

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { todos, speed, car, car_speed } = this.state;
    const { t } = this.props;

    var d = new Date();
    var mm = d.getMonth() + 1;
    var dd = d.getDate();
    var yy = d.getFullYear();

    var cc = yy + "-" + mm + "-" + dd; //(US)
    const base = process.env.PUBLIC_URL;

    // const overZero = todos.filter((c) => {
    //   return c.critical_overspeed > 0;
    // });
    // const carsOverZero = car.filter((c) => {
    //   return c.critical_overspeed > 0;
    // });

    const listSwitcher = this.state.switcher === "car" ? car : todos;
    const summary = this.state.switcher === "car" ? car_speed : speed;

    //console.log(carsOverZero);

    return (
      <div className="Main_section">
        <Helmet>
          <title>Driver </title>
        </Helmet>
        <div className="row row1" style={{ width: "100%" }}>
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
        </div>

        <div style={{ marginTop: "-20px" }}>
          <Header1 />

          <div className="headerr">
            <div className="row" style={{ width: "100%" }}>
              <div style={{ marginTop: "7px" }} className="col-sm-3">
                <Search />
              </div>

              <div style={{ marginTop: "0px" }} className="col-sm-4">
                <p className="font-weight-bolder" style={{ marginTop: "10px" }}>
                  {t("from")}{" "}
                  {this.props.match.params.date__gte === "2016-01-04"
                    ? "-"
                    : `${this.props.match.params.date__gte}`}{" "}
                  {t("to")}{" "}
                  {this.props.match.params.date__lte === cc
                    ? "-"
                    : `${this.props.match.params.date__lte}`}
                </p>

                {/* <p style={{ marginTop: "50px" }}> {t("warning")}</p> */}
              </div>

              <div
                className="col-sm-5"
                style={{ position: "relative", top: "10px" }}
              >
                <div className="row">
                  <div className=" col-sm-3 form-group">
                    <input
                      type="radio"
                      name="switcher"
                      id="switchClassic13"
                      defaultChecked
                      onChange={this.onChange}
                      value="driver"
                    />
                    <label id="p" htmlFor="switchClassic13">
                      {t("my_driver")}
                    </label>
                  </div>

                  <div className="form-group col-sm-5">
                    <input
                      type="radio"
                      name="switcher"
                      id="switcherElectric12"
                      onChange={this.onChange}
                      class="form-check-input"
                      value="car"
                    />
                    <label id="p" htmlFor="switcherElectric12">
                      {t("my_car")}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            {this.state.isLoading === true ? (
              <Spinner />
            ) : (
              <div className="caaar" style={{ marginTop: "-20px" }}>
                <table id="myTable" className="table table-hover">
                  <thead>
                    <tr className="th ">
                      <th className="" style={{ width: "5%" }}>
                        {t("Id")}
                      </th>
                      {this.state.switcher === "driver" ? (
                        <th className="" onClick={() => this.sortBy("name")}>
                          {t("Name")}{" "}
                          <img
                            src={arrow}
                            alt="Arrow"
                            style={{ height: "0.9em" }}
                          />{" "}
                        </th>
                      ) : (
                        <th
                          className="t"
                          onClick={() => this.sortBy("plate_number")}
                        >
                          {t("plate_number")}{" "}
                          <img
                            src={arrow}
                            alt="Arrow"
                            style={{ height: "0.9em" }}
                          />{" "}
                        </th>
                      )}

                      {this.state.switcher === "driver" ? (
                        <th
                          className="t"
                          onClick={() => this.sortBy("carquanttity")}
                        >
                          {t("NOCar")}{" "}
                          <img
                            src={arrow}
                            alt="Arrow"
                            style={{ height: "0.9em" }}
                          />
                        </th>
                      ) : (
                        ""
                      )}
                      <th className="t" onClick={() => this.sortBy("milage")}>
                        {t("Milage")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>
                      <th
                        className="t"
                        onClick={() => this.sortBy("fuel_consumed")}
                      >
                        {t("FuelComsumed")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>
                      <th
                        className="t"
                        onClick={() => this.sortBy("average_fuel_consumed")}
                      >
                        {t("AverageFuel")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>
                      <th
                        className="t"
                        onClick={() => this.sortBy("overspeeding_distance")}
                      >
                        {t("OverspeedingDistance")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>
                      <th
                        className="t"
                        onClick={() => this.sortBy("critical_overspeed")}
                      >
                        {t("CriticalSpeed")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>
                      {this.state.switcher === "driver" ? (
                        <th className="">
                          <select
                            onChange={this.change.bind(this)}
                            value={this.state.value}
                            style={{
                              position: "relative",
                              top: "8px",
                              width: "130px",
                            }}
                            class="minimal"
                          >
                            <option value="1"> {t("OverallScore")} </option>
                            <option value="2">{t("OverallSpeed")} </option>
                            <option value="3"> {t("OverallDynamic")} </option>
                            <option value="4">
                              {" "}
                              {t("OverallEfficiency")}{" "}
                            </option>
                          </select>

                          <img
                            onClick={() =>
                              this.state.value === "1"
                                ? this.sortBy("overallscore")
                                : this.state.value === "2"
                                ? this.sortBy("overallspeedscore")
                                : this.state.value === "3"
                                ? this.sortBy("overalldynamicscore")
                                : this.state.value === "4"
                                ? this.sortBy("overallefficienyscore")
                                : this.sortBy("overallscore")
                            }
                            src={arrow}
                            alt="Arrow"
                            style={{ height: "0.9em" }}
                          />
                        </th>
                      ) : (
                        ""
                      )}
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {listSwitcher.map((c, i) => (
                      <tr key={c.pk}>
                        <td>{i + 1}</td>
                        <td>
                          {this.state.switcher === "driver" ? (
                            <a
                              href={base + "/overview/drivermetrics/" + c.id}
                              className="font-weight-bold"
                              style={{ color: "#b71c1c" }}
                            >
                              {c.name ? `${c.name}` : " ? "}
                            </a>
                          ) : (
                            <a
                              href={base + "/overview/carmetrics/" + c.id}
                              className="font-weight-bold"
                              style={{ color: "#b71c1c" }}
                            >
                              {c.plate_number ? `${c.plate_number}` : " ? "}
                            </a>
                          )}
                        </td>
                        {this.state.switcher === "driver" ? (
                          <td>{c.carquanttity ? `${c.carquanttity}` : "-"} </td>
                        ) : (
                          ""
                        )}
                        <td>{c.milage ? `${c.milage.toFixed(1)}` : "-"} </td>
                        <td>
                          {c.fuel_consumed
                            ? `${c.fuel_consumed.toFixed(1)}`
                            : "-"}
                        </td>
                        <td>
                          {c.average_fuel_consumed
                            ? `${c.average_fuel_consumed.toFixed(1)}`
                            : "-"}{" "}
                        </td>
                        <td>
                          {c.overspeeding_percentage
                            ? `${c.overspeeding_percentage.toFixed(1)}`
                            : "-"}
                        </td>
                        <td>
                          {c.critical_overspeed
                            ? `${c.critical_overspeed}`
                            : "-"}
                        </td>

                        {this.state.switcher === "driver" ? (
                          <td>
                            {this.state.value === "1"
                              ? `${
                                  c.overallscore
                                    ? `${c.overallscore.toFixed(1)}`
                                    : " - "
                                }`
                              : `${
                                  this.state.value === "2"
                                    ? `${
                                        c.overallspeedscore
                                          ? `${c.overallspeedscore.toFixed(1)}`
                                          : " - "
                                      }`
                                    : `${
                                        this.state.value === "3"
                                          ? `${
                                              c.overalldynamicscore
                                                ? `${c.overalldynamicscore.toFixed(
                                                    1
                                                  )}`
                                                : " - "
                                            }`
                                          : `${
                                              this.state.value === "4"
                                                ? `${
                                                    c.overallefficienyscore
                                                      ? `${c.overallefficienyscore.toFixed(
                                                          1
                                                        )}`
                                                      : " - "
                                                  }`
                                                : `${
                                                    c.overallscore
                                                      ? `${c.overallscore.toFixed(
                                                          1
                                                        )}`
                                                      : " - "
                                                  }`
                                            }`
                                      }`
                                }`}
                          </td>
                        ) : (
                          ""
                        )}
                        <td>
                          {this.state.switcher === "driver" ? (
                            <a
                              href={
                                base +
                                `/ddoverview/my/driver/${c.id}/${this.props.match.params.date__lte}/${this.props.match.params.date__gte}/${c.name}/`
                              }
                              className="font-weight-bold"
                              title="Click to view Daily trip view"
                              style={{ color: "#b71c1c" }}
                            >
                              {t("trips").toUpperCase()}
                            </a>
                          ) : (
                            <a
                              href={
                                base +
                                `/ccoverview/my/car/${c.id}/${this.props.match.params.date__lte}/${this.props.match.params.date__gte}/${c.plate_number}/`
                              }
                              className="font-weight-bold"
                              title="Click to view Daily trip view"
                              style={{ color: "#b71c1c" }}
                            >
                              {t("trips").toUpperCase()}
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  {/* )} */}

                  <tfoot>
                    <tr className="thead sorttable_nosort">
                      <th className="sorttable_nosort"></th>

                      <th className="sorttable_nosort">
                        {" "}
                        {t("Summary1").toUpperCase()}
                      </th>
                      {this.state.switcher === "driver" ? (
                        <th className="sorttable_nosort">
                          {speed.carquantity ? `${speed.carquantity}` : "-"}
                        </th>
                      ) : (
                        ""
                      )}
                      <th className="sorttable_nosort">
                        {summary.milage ? `${summary.milage.toFixed(1)}` : "-"}
                      </th>
                      <th className="sorttable_nosort">
                        {summary.fuel_consumed
                          ? `${summary.fuel_consumed.toFixed(1)}`
                          : "-"}{" "}
                      </th>
                      <th className="sorttable_nosort">
                        {summary.average_fuel_consumed
                          ? `${summary.average_fuel_consumed.toFixed(1)}`
                          : "-"}
                      </th>
                      <th className="sorttable_nosort">
                        {summary.overspeeding_percentage
                          ? `${summary.overspeeding_percentage.toFixed(1)}`
                          : "-"}
                      </th>
                      <th className="sorttable_nosort">
                        {summary.critical_overspeed
                          ? `${summary.critical_overspeed.toFixed(1)}`
                          : "-"}
                      </th>
                      {this.state.switcher === "driver" ? (
                        <th className="sorttable_nosort">
                          {this.state.value === "1"
                            ? `${
                                speed.overallscore
                                  ? `${speed.overallscore.toFixed(1)}`
                                  : " - "
                              }`
                            : `${
                                this.state.value === "2"
                                  ? `${
                                      speed.overallspeedscore
                                        ? `${speed.overallspeedscore.toFixed(
                                            1
                                          )}`
                                        : " - "
                                    }`
                                  : `${
                                      this.state.value === "3"
                                        ? `${
                                            speed.overalldynamicscore
                                              ? `${speed.overalldynamicscore.toFixed(
                                                  1
                                                )}`
                                              : " - "
                                          }`
                                        : `${
                                            this.state.value === "4"
                                              ? `${
                                                  speed.overallefficienyscore
                                                    ? `${speed.overallefficienyscore.toFixed(
                                                        1
                                                      )}`
                                                    : " - "
                                                }`
                                              : `${
                                                  speed.overallscore
                                                    ? `${speed.overallscore.toFixed(
                                                        1
                                                      )}`
                                                    : " - "
                                                }`
                                          }`
                                    }`
                              }`}
                        </th>
                      ) : (
                        ""
                      )}
                      <th></th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CritialOverspeed);
