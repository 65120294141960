import React, { Component } from "react";
import { withTranslation } from "react-i18next";
// import Doc from "../../../Insurance/Car/Image/Doc";

export class CarDoc extends Component {
  render() {
    const { t } = this.props;
    return (
      <div className="" style={{ marginTop: "30px" }}>
        {/* <Doc /> */}
        <h3 className="font-weight-normal" style={{ marginTop: "30px" }}>
          {t("car_documents")}
        </h3>
        <hr></hr>
        <table className="table" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>{t("Id")}</th>
              <th style={{ width: "60%" }}>{t("documents")}</th>
              <th>Status</th>
              {/* <th>{t("upload_documents")}</th> */}
              <th>{t("see_documents")}</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>1</td>
              <td>{t("vehicle_photo")}</td>
              <td>
                {" "}
                <label class="container1q">
                  <input
                    type="checkbox"
                    checked={
                      this.props.photo.filter(
                        (c) => c.category === "vehicle-photo"
                      ).length > 0
                        ? true
                        : false
                    }
                  />
                  <span class="checkmark"></span>
                </label>
              </td>
              {/* <td>
                <a
                  href="#upload"
                  rel="modal:open"
                  className={"btn btn-upload"}
                  id="dd"
                >
                  {t("upload")}
                </a>
              </td> */}
              <td>
                <a
                  href="#contact"
                  rel="modal:open"
                  className={"btn btn-see"}
                  id="dd"
                >
                  {t("see")}
                </a>
              </td>
            </tr>
            <tr>
              <td>2</td>
              <td>{t("insurance_police")}</td>
              <td>
                {" "}
                <label class="container1q">
                  <input
                    type="checkbox"
                    checked={
                      this.props.photo.filter(
                        (c) => c.category === "insurance-police"
                      ).length > 0
                        ? true
                        : false
                    }
                  />
                  <span class="checkmark"></span>
                </label>
              </td>

              <td>
                <a
                  href="#contact"
                  rel="modal:open"
                  className={"btn btn-see"}
                  id="dd"
                >
                  <span style={{ position: "relative", top: "-2px" }}>
                    {" "}
                    {t("see")}
                  </span>
                </a>
              </td>
            </tr>
            <tr>
              <td>3</td>
              <td>{t("vehicle_registration")}</td>
              <td>
                {" "}
                <label class="container1q">
                  <input
                    type="checkbox"
                    checked={
                      this.props.photo.filter(
                        (c) => c.category === "vehicle-registration"
                      ).length > 0
                        ? true
                        : false
                    }
                  />
                  <span class="checkmark"></span>
                </label>
              </td>

              <td>
                <a
                  href="#contact"
                  rel="modal:open"
                  className={"btn btn-see"}
                  id="dd"
                >
                  {t("see")}
                </a>
              </td>
            </tr>
            <tr>
              <td>4</td>
              <td>{t("other_documents")}</td>
              <td>
                {" "}
                <label class="container1q">
                  <input
                    type="checkbox"
                    checked={
                      this.props.photo.filter((c) => c.category === "other")
                        .length > 0
                        ? true
                        : false
                    }
                  />
                  <span class="checkmark"></span>
                </label>
              </td>

              <td>
                <a
                  href="#contact"
                  rel="modal:open"
                  className={
                    this.props.photo.filter((c) => c.category === "other")
                      .length > 0
                      ? "btn btn-see"
                      : "btn btn-see opacity-2"
                  }
                  id="dd"
                >
                  {t("see")}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default withTranslation()(CarDoc);
