import React, { Component } from "react";
import deletee from "../../../../../../img/delete.png";
import { withTranslation } from "react-i18next";
import contextual from "../Option/Option";

import { getSegmentData } from "../../../../../../Service/Segment";
export class Parameter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHidden: true,
      context: "none",
      segment: [],
    };
  }

  handleAddFields = () => {
    const values = this.props.vall;
    values.push({
      segment_parameter: "",
      value: "",
      max_value: null,
      min_value: null,
    });
    this.setState({
      values,
    });
  };

  getData() {
    getSegmentData().then((data) => {
      this.setState({ segment: data.segment });
    });
  }
  // componentDidMount() {
  //   this.getData("");
  // }

  // componentDidUpdate() {
  //   this.getData("");
  // }

  toggleHidden = (id) => {
    this.setState({
      [`isHidden${id}`]: false,
    });
  };

  toggleHie = (id) => {
    this.setState({ [`isHidden${id}`]: true });
  };

  async onChange1(e) {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleRemoveFields = (index) => {
    const values = this.props.vall;
    values.splice(index, 1);
    this.setState({
      values,
    });
  };

  async onChange(e, index) {
    if (
      ["segment_parameter", "value", "max_value", "min_value"].includes(
        e.target.name
      )
    ) {
      let cats = [...this.props.vall];
      cats[index][e.target.name] = e.target.value;
      await this.setState({
        cats,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
    //console.log("Value", this.props.vall);
    this.getData("");
  }

  render() {
    const { t } = this.props;
    //   Object.keys(contextual)
    // .filter(key => !allowed.includes(key))
    // .forEach(key => delete contextual[key]);  // another option for this

    const allowed = [this.state.context];
    const filtered = Object.keys(contextual)
      .filter((key) => allowed.includes(key))
      .reduce((obj, key) => {
        obj[key] = contextual[key];
        return obj;
      }, {});

    // const capitalizeFirstLetter = (string) => {
    //   return string.charAt(0).toUpperCase() + string.slice(1);
    // };

    const getoption = filtered[allowed];
    const options1 = [];
    const translation = (name) =>
      name === "weather_sunny"
        ? t("sun")
        : name === "weather_cloudy"
        ? t("cloud")
        : name === "weather_rainy"
        ? t("rainy")
        : name === "weather_snowy"
        ? t("snowy")
        : name === "highway"
        ? t("highway")
        : name === "urban"
        ? t("urban")
        : name === "rural"
        ? t("rular")
        : name === "Weekend"
        ? t("weekend")
        : name === "Weekdays"
        ? t("working_days")
        : name === "known_roads"
        ? t("know_road")
        : name === "unknown_roads"
        ? t("unknown")
        : "-";
    for (var i = 0; i < getoption.length; i++) {
      const don = getoption[i];
      options1.push(
        <option
          value={
            don === "-∞;-10"
              ? "temp_minus_10"
              : don === "-10;0"
              ? "temp_minus_10_0"
              : don === "0;10"
              ? "temp_0_10"
              : don === "10;+∞"
              ? "temp_10"
              : don
          }
          key={don}
        >
          {don === "-∞;-10"
            ? "-∞;-10"
            : don === "-10;0"
            ? "-10;0"
            : don === "0;10"
            ? "0;10"
            : don === "10;+∞"
            ? "10;+∞"
            : translation(don)}
          {/* {translation(don)} */}
          {/* {capitalizeFirstLetter(don).split("_").join(" ")} */}
        </option>
      );
    }

    return (
      <div>
        {this.props.vall.slice(0, 4).map((inputField, index) => (
          <div>
            <div className="row" style={{ marginTop: "20px" }}>
              <div className=" col-sm-2 form-group">
                <input
                  type="radio"
                  name={`milage${index}`}
                  id={`r11r${index}`}
                  defaultChecked
                  onClick={() => this.toggleHidden(index)}
                  // onChange={this.onChange1.bind(this)}
                  value={`one${index}`}
                />
                <label
                  id="p"
                  style={{ fontSize: "14px" }}
                  htmlFor={`r11r${index}`}
                >
                  {t("single_parameter")}
                </label>
              </div>

              <div className="form-group col-sm-3">
                <span>
                  <input
                    type="radio"
                    name={`milage${index}`}
                    id={`r133r${index}`}
                    onClick={() => this.toggleHie(index)}
                    class="form-check-input"
                    value={`double${index}`}
                  />
                  <label
                    id="p"
                    style={{ fontSize: "14px" }}
                    htmlFor={`r133r${index}`}
                  >
                    {t("parameter_second")}
                  </label>
                </span>
              </div>
            </div>
            <div className="row" key={`${inputField}~${index}`}>
              {!this.state[`isHidden${index}`] === true ? (
                <div className="col-sm-4">
                  <div className="form-group">
                    <label id="p" htmlFor="Plate_number">
                      {t("single_parameter").toUpperCase()}{" "}
                      <span className="start_icon"> * </span>
                    </label>
                    <select
                      name="segment_parameter"
                      onChange={(e) => {
                        this.onChange(e, index);
                      }}
                      value={inputField.segment_parameter}
                      class="form-control donn"
                    >
                      <option value="" selected disabled hidden>
                        {t("choose_parameter")}
                      </option>

                      <option value="average_fuel_consumed">
                        {t("AverageFuel")}
                      </option>
                      <option value="average_energy_consumed">
                        {t("average_energy_cosumed")}
                      </option>
                      {/* <option value="average energy consumed">
                        {t("average_daily_distance")}
                      </option> */}
                      <option value="overspeeding_percentage">
                        {t("overspeeding_distance")}
                      </option>
                      <option value="critical_overspeed">
                        {t("critical_overspeding")}
                      </option>
                      {/* <option value="risk event 100">
                        {t("total_risk_events_100km")}
                      </option> */}
                      <option value="aggresive_turns_per100km">
                        {t("aggresive_turn_avg")}
                      </option>
                      <option value="aggresive_braking_per100km">
                        {t("aggresive_breaking_avg")}
                      </option>
                      <option value="aggresive_acceleration_per100km">
                        {t("aggresive_acceleration_avg")}
                      </option>
                      <option value="overspeeding_event" hidden>
                        {t("overspeeding_events")}/100km
                      </option>
                      <option value="overall_score">{t("OverallScore")}</option>
                      <option value="speed_score">{t("OverallSpeed")}</option>
                      <option value="dynamics_score">
                        {t("OverallDynamic")}
                      </option>
                      <option value="fuel_score">
                        {t("OverallEfficiency")}
                      </option>
                    </select>
                  </div>
                </div>
              ) : (
                <div className="col-sm-4">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label id="p" htmlFor="Plate_number">
                          {t("parameter_second").toUpperCase()}
                        </label>
                        <select
                          name="context"
                          class="form-control donn"
                          onChange={this.onChange1.bind(this)}
                        >
                          <option value="" selected disabled hidden>
                            {t("choose_parameter")}
                          </option>

                          {/* <option value="time_of_day">Time of the day</option> */}
                          <option value="weather">
                            {t("weather_condition")}
                          </option>

                          <option value="temprature">{t("temprature")}</option>
                          <option value="driving_days">
                            {t("driving_days_1")}
                          </option>
                          <option value="road_familirity">
                            {t("road_familiarity")}
                          </option>
                          <option value="road_type">{t("kinda_roads")}</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label id="p" htmlFor="Plate_number">
                          {t("category_date").toUpperCase()}{" "}
                          <span className="start_icon"> * </span>
                        </label>
                        <select
                          name="segment_parameter"
                          onChange={(e) => {
                            this.onChange(e, index);
                          }}
                          value={inputField.segment_parameter}
                          class="form-control donn"
                        >
                          {options1}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-sm-2">
                <div className="form-group">
                  <label id="p" htmlFor="from">
                    {t("curren_value").toUpperCase()}
                  </label>
                  <input
                    type="text"
                    name="value"
                    disabled
                    value={
                      (inputField.value = this.state.segment.length
                        ? this.state.segment
                            .filter(
                              (d) => d.label === inputField.segment_parameter
                            )
                            .map((c) => c.value)
                            .join("")
                        : "Loading ...")
                    }
                    // value={inputField.value}
                    onChange={(e) => {
                      this.onChange(e, index);
                    }}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-sm-2">
                <div className="form-group">
                  <label id="p" htmlFor="from">
                    {t("from").toUpperCase()}{" "}
                    <span className="start_icon"> * </span>
                  </label>
                  <input
                    type="number"
                    name="max_value"
                    value={inputField.max_value}
                    onChange={(e) => {
                      this.onChange(e, index);
                    }}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-sm-2">
                <div className="form-group">
                  <label id="p" htmlFor="to">
                    {t("to").toUpperCase()}{" "}
                    <span className="start_icon"> * </span>
                  </label>
                  <input
                    type="number"
                    name="min_value"
                    value={inputField.min_value}
                    onChange={(e) => {
                      this.onChange(e, index);
                    }}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-sm-2">
                <button
                  className="btn btn-danger"
                  style={{
                    marginTop: "30px",
                    background: "none",
                    border: "none",
                  }}
                  type="button"
                  onClick={() => this.handleRemoveFields(index)}
                >
                  <img src={deletee} alt="Delete" />
                </button>
              </div>
            </div>
          </div>
        ))}

        {this.props.vall.length === 4 ? (
          ""
        ) : (
          <a
            href="# "
            onClick={this.handleAddFields}
            className="p font-weight-bolder"
            style={{ color: "#b71c1c" }}
          >
            {t("add_parameter")}
          </a>
        )}
      </div>
    );
  }
}

export default withTranslation()(Parameter);
