import config from "../views/Main";

export async function getDriver(to, from) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.driver}?date__lte=${to ? to : ""}&date__gte=${
        from ? from : ""
      }`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getDriverCampaign(ids) {
  let products = new Promise((resolve, reject) => {
    fetch(`${config.apiUrl.driver}?id__in=${ids}`, config.head)
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getDriverSummary(to, from, year, month) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.driversummary}?date__lte=${to ? to : ""}&date__gte=${
        from ? from : ""
      }&date__year=${year ? year : ""}&date__month=${month ? month : ""}`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getDriverDaily(pk, to, from, year, month) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.driver}${pk}/days/?date__lte=${to ? to : ""}&date__gte=${
        from ? from : ""
      }&date__year=${year ? year : ""}&date__month=${month ? month : ""}`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getDriverSummaryDaily(pk, to, from, year, month) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.driver}${pk}/days/summary/?date__lte=${
        to ? to : ""
      }&date__gte=${from ? from : ""}&date__year=${
        year ? year : ""
      }&date__month=${month ? month : ""}`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getDriverProfile(pk, to, from, year, month) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.driver}${pk}/?date__lte=${to ? to : ""}&date__gte=${
        from ? from : ""
      }&date__year=${year ? year : ""}&date__month=${month ? month : ""}`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getMyDriver(to, from, year, month) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl1.myDriver}?date__lte=${to ? to : ""}&date__gte=${
        from ? from : ""
      }&date__year=${year ? year : ""}&date__month=${month ? month : ""}`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

//Function to get DriverRecommendation.
export async function getMyDriverRecommendation(id) {
  let products = new Promise((resolve, reject) => {
    fetch(`${config.apiUrl.driver}recommendation/?driver=${id}`, config.head)
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}
