import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import User from "../../../../../Components/User";
import arrow_left from "../../../../../img/Aarrow.png";
import Datee from "../../../../Views/Date";
import { Helmet } from "react-helmet";
import Recommendation from "../../../../../Components/Layout/Recommendation";
import edit from "../../../../../img/edit.png";
import deletew from "../../../../../img/delete.png";
import {
  getSegmentDetails,
  deleteSegment,
} from "../../../../../Service/Segment";
import Table from "./Table/Table";
import moment from "moment";

export class DetailView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      segment: [],
      parameter: [],
      selectOptions: [],
      selectedItems: [],
      checkedBoxCheck: false,
    };
  }

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  segmentDetails() {
    getSegmentDetails(this.props.match.params.pk).then((data) => {
      this.setState({ segment: data, parameter: data.parameter_detail });
    });
  }

  onDeleteClick() {
    deleteSegment(this.props.match.params.pk).then((data) => {
      this.setState({ data });
    });
  }

  componentDidMount() {
    this.segmentDetails("");
  }

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  render() {
    const { t } = this.props;
    const { segment, parameter } = this.state;

    const translation = (name) =>
      name === "average_fuel_consumed"
        ? t("AverageFuel")
        : name === "average_energy_consumed"
        ? t("average_energy_cosumed")
        : name === "overspeeding_percentage"
        ? t("overspeeding_distance")
        : name === "critical_overspeed"
        ? t("critical_overspeding")
        : name === "aggresive_turns_per100km"
        ? t("aggresive_turn_avg")
        : name === "aggresive_braking_per100km"
        ? t("aggresive_breaking_avg")
        : name === "aggresive_acceleration_per100km"
        ? t("aggresive_acceleration_avg")
        : name === "overspeeding_event"
        ? t("overspeeding_events")
        : name === "overall_score"
        ? t("OverallScore")
        : name === "speed_score"
        ? t("OverallSpeed")
        : name === "dynamics_score"
        ? t("OverallDynamic")
        : name === "fuel_score"
        ? t("OverallEfficiency")
        : name === "weather_sunny"
        ? t("sun")
        : name === "weather_cloudy"
        ? t("cloud")
        : name === "weather_rainy"
        ? t("rainy")
        : name === "weather_snowy"
        ? t("snowy")
        : name === "highway"
        ? t("highway")
        : name === "urban"
        ? t("urban")
        : name === "rural"
        ? t("rular")
        : name === "Weekend"
        ? t("weekend")
        : name === "Weekdays"
        ? t("working_days")
        : name === "known_roads"
        ? t("know_road")
        : name === "unknown_roads"
        ? t("unknown")
        : "-";

    return (
      <div className="Main_section">
        <Helmet>
          <title> View Driver Segment </title>
        </Helmet>

        <div
          className="row row1"
          style={{ width: "100%", marginBottom: "-30px" }}
        >
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
        </div>
        <Recommendation />

        <div className="headerr">
          <div>
            <div>
              <a href="# " onClick={this.handleClick.bind(this)}>
                <img
                  src={arrow_left}
                  alt="Left_Arrow"
                  style={{ height: "2.6em" }}
                />
              </a>
              &nbsp;
              {t("view_segment").toUpperCase()}{" "}
              <strong>"{segment.segment_title}"</strong> &nbsp;
            </div>

            <nav
              className="navbar navbar-expand-lg navbar-dark float-right"
              style={{ marginTop: "-50px" }}
            >
              <ul className="navbar-nav ml-auto">
                {segment.from_date > moment().format("YYYY-MM-DD") && (
                  <li className="nav-item  ">
                    <section>
                      <a
                        className=""
                        title="Click to edit driver data"
                        href="# "
                      >
                        <img
                          src={edit}
                          alt="contact"
                          className={
                            window.localStorage.i18nextLng === "pl"
                              ? "polish1"
                              : "english"
                          }
                          style={{
                            width: "1.6em",
                            marginLeft: "15px",
                            marginTop: "5px",
                          }}
                        />
                        <br></br>
                        <span
                          style={{
                            color: "rgb(183, 28, 28)",
                            fontSize: "11px",
                            marginLeft: "15px",
                          }}
                        >
                          {t("edit")}
                        </span>
                      </a>
                    </section>
                  </li>
                )}

                <li className="nav-item  ">
                  <section>
                    <a
                      className=""
                      title="Click to edit driver data"
                      href="# "
                      onClick={this.onDeleteClick.bind(this)}
                    >
                      <img
                        src={deletew}
                        alt="contact"
                        className={
                          window.localStorage.i18nextLng === "pl"
                            ? "polish1"
                            : "english"
                        }
                        style={{
                          width: "1.6em",
                          marginLeft: "15px",
                          marginTop: "5px",
                        }}
                      />
                      <br></br>
                      <span
                        style={{
                          color: "rgb(183, 28, 28)",
                          fontSize: "11px",
                          marginLeft: "15px",
                        }}
                      >
                        {t("delete")}
                      </span>
                    </a>
                  </section>
                </li>
              </ul>
            </nav>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-8">
                  <div className="form-group">
                    <p id="pppp">{t("description").toUpperCase()}</p>
                    <p className="pppp1">{segment.segment_description}</p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-8">
                  <div className="form-group">
                    <p id="pppp">{t("choosen_parameter").toUpperCase()}</p>

                    {parameter.map((d, i) => (
                      <p className="pppp1" key={i + 1}>
                        {i + 1}. {translation(d.segment_parameter)}
                        {/* {this.capitalizeFirstLetter(
                          d.segment_parameter.split("_").join(" ")
                        )} */}
                        &nbsp; &nbsp; &nbsp; {t("value")}: &nbsp;{" "}
                        <span>
                          {" "}
                          {t("from")} :{" "}
                          {d.max_value === null
                            ? "-"
                            : parseFloat(d.max_value).toFixed(1)}{" "}
                        </span>{" "}
                        &nbsp;{" "}
                        <span>
                          {" "}
                          {t("to")} :{" "}
                          {d.min_value === null
                            ? "-"
                            : parseFloat(d.min_value).toFixed(1)}
                        </span>{" "}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-8">
                  <div className="row">
                    <div className="form-group">
                      <p id="pppp">{t("choosen_date").toUpperCase()}</p>
                      <p className="pppp1">
                        {" "}
                        {segment.from_date} - {segment.to_date}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <h3 className="font-weight-normal" style={{ marginTop: "30px" }}>
              {t("driver_list")}
            </h3>
            <hr></hr>
            <Table param={parameter} parameter={segment} trans={translation} />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(DetailView);
