const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "2px solid white",
    height: "20px",
    width: "200px",
    fontSize: "12px",
    borderBottom: "1px solid gray",
  }),
};

export default colourStyles;
