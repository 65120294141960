import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import search from "../../img/search.png";
import { withTranslation } from "react-i18next";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#1B1B23",
      opacity: "0.6",
      fontSize: "15px",
      letterSpacing: "0.2px",
    },

    "& label": {
      color: "rgb(27, 27, 35,0.5)",
      marginLeft: "5px",
      borderBottomColor: "rgb(27, 27, 35,0.2)",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#B71C1C",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#1B1B23",
      opacity: "0.6",
    },
  },
})(TextField);

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <form noValidate autoComplete="off">
        <div className="form-group">
          <CssTextField
            style={{
              width: "200px",
            }}
            id="custom-css-outlined-input"
            label={t("search")}
            name="search"
            value={this.props.state1}
            onChange={this.props.change}
            autoComplete="off"
          />
        </div>
        <img
          src={search}
          alt="Search"
          className="search"
          style={{ width: "25px", marginTop: "10px" }}
        />
      </form>
    );
  }
}

export default withTranslation()(Search);
