import React, { Component } from "react";
import cross from "../../../img/Cross.png";
import config from "../../Main";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Cookies from "universal-cookie";
const cookie = new Cookies();
let authToken = cookie.get("Token");

class AddFuelReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walk: [],
      cardata: [],
      fuelData: [],
      month: "",
      selectOptions: [],
      year: "",
      lol: "",
      fol: "",
      carr: "",
      plate: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async handlSubmit(e) {
    const base = process.env.PUBLIC_URL;
    try {
      const month = e.target.elements.month.value;
      const year = e.target.elements.year.value;
      const selections = [...e.target.elements.selectOptions.options].filter(
        (opt) => opt.selected
      );
      const selectedValues = selections.map((opt) => opt.value);
      const selectedString = selectedValues.join(",");
      e.preventDefault();

      Promise.all([
        fetch(
          `${config.apiUrl.fuel1}?datetime__year=${year}&datetime__month=${month}&car__in=${selectedString}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + authToken,
            },
          }
        ).then((response) => response.json()),
        fetch(
          `${config.apiUrl.fuel_detected}?datetime__year=${year}&datetime__month=${month}&car__in=${selectedString}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + authToken,
            },
          }
        ).then((response) => response.json()),
      ]).then((resData) => {
        // console.log("Fuel Car", resData);
        this.setState({
          ...this.state,
          cardata: resData,
          fuelData: resData,
          lol: month,
          fol: year,
          carr: selectedString,
        });

        this.props.history.push({
          pathname: base + "/fuel",
          state: {
            cardata: resData,
            fuelData: resData,
            lol: month,
            fol: year,
            carr: selectedString,
          },
        });
        window.location.reload();
      });
    } catch (e) {
      console.log(e);
    }
  }

  async onSubmitt1(e) {
    try {
      const res = await fetch(
        `${config.apiUrl.car}?license_plate__icontains=${this.state.plate}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + authToken,
          },
        }
      );
      const walk = await res.json();
      this.setState({
        walk,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async componentDidMount() {
    await this.onSubmitt1("");
  }

  async onChange(e) {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.onSubmitt1("");
  }

  async handleChange(e) {
    let target = e.target;
    let name = target.name;
    let value = Array.from(target.selectedOptions, (option) => option.value);
    await this.setState({
      [name]: value,
    });
  }

  render() {
    const option = [];

    const date = new Date();
    const year = date.getFullYear();

    for (let i = 2; i >= 0; i--) {
      option.push((year - i).toString());
    }

    const options1 = [];
    for (var i = 2019; i <= 2050; i++) {
      const don = i;

      options1.push(
        <option value={don} key={don}>
          {don}
        </option>
      );
    }

    const { walk } = this.state;
    const { t } = this.props;

    // let options = walk.map(function(city) {
    //   return { value: city.id, label: city.plate_number };
    // });
    return (
      <div id="fuel" class="modal">
        <a
          href="# "
          rel="modal:close"
          className="float-right text-dark "
          style={{ marginBottom: "90px" }}
        >
          <img src={cross} alt="Cross" style={{ height: "1.1em" }} />
        </a>
        <p className="mod" style={{ marginTop: "10px" }}>
          {t("monthly_report").toUpperCase()}
        </p>
        <form
          style={{ marginTop: "40px" }}
          onSubmit={this.handlSubmit.bind(this)}
        >
          <p id="ppp" style={{ marginTop: "10px" }}>
            {t("select_month_year").toUpperCase()}
          </p>
          <div className="row">
            <div className="col-md-6">
              <select
                name="month"
                required
                className="form-control select donn"
                onChange={this.onChange}
                value={this.state.month}
              >
                <option value="" disabled selected hidden>
                  {t("month")}
                </option>

                <option value="1">{t("january")}</option>
                <option value="2">{t("feburuary")}</option>
                <option value="3">{t("march")}</option>
                <option value="4">{t("april")}</option>
                <option value="5">{t("may")}</option>
                <option value="6">{t("june")}</option>
                <option value="7">{t("july")}</option>
                <option value="8">{t("august")}</option>
                <option value="9">{t("september")}</option>
                <option value="10">{t("october")}</option>
                <option value="11">{t("november")}</option>
                <option value="12">{t("december")}</option>
              </select>
            </div>
            <div className="col-md-6">
              <select
                name="year"
                className="form-control select donn"
                onChange={this.onChange}
                required
                value={this.state.year}
              >
                <option value="" disabled selected hidden>
                  {t("year")}
                </option>

                {options1}
              </select>
            </div>
          </div>

          <p id="ppp" style={{ marginTop: "15px" }}>
            {t("ChooseCar").toUpperCase()}
          </p>
          <div
            className=""
            style={{ marginBottom: "30px", marginTop: "-10px" }}
          >
            <input
              className="form-control finds"
              placeholder={t("search")}
              type="text"
              name="plate"
              style={{
                width: "100%",
              }}
              value={this.state.plate}
              onChange={this.onChange.bind(this)}
            />
            <select
              name="selectOptions"
              style={{ width: "100%" }}
              multiple={true}
              onChange={this.handleChange}
              value={this.state.selectOptions}
              class="form-control select  "
            >
              <option value="" disabled hidden selected>
                {" "}
                {t("choose_car1")}
              </option>
              <option value=" "> {t("select_car")}</option>
              {walk.map((c) => (
                <option value={c.id} key={c.id}>
                  {" "}
                  {c.plate_number}
                </option>
              ))}
            </select>
          </div>

          <center>
            <button
              type="submit"
              value="Get Data"
              className="btn btn-login text-white font-weight-bolder boxx "
              id="button1"
              style={{
                height: "40px",
                fontSize: "13px",
                width: "200px",
                background: "rgba(183, 28, 28, 1)",
                border: "none",
                color: "white",
                margin: "auto",
              }}
            >
              {t("raport").toUpperCase()}
            </button>
          </center>
        </form>
      </div>
    );
  }
}

export default withTranslation()(withRouter(AddFuelReport));
