import React, { Component } from "react";
import Chart from "react-apexcharts";
import { withTranslation } from "react-i18next";
// import config from "../../../Main";

const colors = ["#783bff", "#ff6d43", "#f9c45c", "#6ac75a"];
export class Type extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        chart: {
          type: "donut",
        },
        labels: props.load.map((c) => {
          return c.label;
        }),
        dataLabels: {
          enabled: false,
        },
        colors: colors,
        title: {
          align: "center",
          style: {
            fontSize: "17px",
            color: "black",
          },
        },
        tooltip: {
          y: {
            formatter: (value) => {
              return Number.parseFloat(value).toFixed(0) + "%";
            },
          },
        },
        legend: {
          position: "bottom",
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
          },
        },
      },
      series: props.load.map((c) => {
        return c.data;
      }),
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="col-sm-3">
        <h5
          className="text-left font-weight-normal"
          style={{ marginLeft: "26px", marginTop: "10px" }}
        >
          {t("kinda_roads")}
        </h5>
        <Chart
          style={{ marginTop: "22px" }}
          options={this.state.options}
          series={this.state.series}
          type="donut"
          height="250"
        />
      </div>
    );
  }
}

export default withTranslation()(Type);
