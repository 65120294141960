import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

function Form(props) {
  // const [fromDate, setFromDate] = useState("");

  // const [toDate, setToDate] = useState("");

  // const assignFromDate = (e) => {
  //   console.log(e.target.value);
  //   setFromDate(e.target.value);
  // };
  const { t } = useTranslation();
  return (
    <form>
      <div className="row">
        <div className="col-sm-6">
          <div className="form-group">
            <label id="p" htmlFor="to">
              {t("from")} <span className="start_icon"> * </span>
            </label>
            <input
              type="date"
              name="from_date"
              id="lol"
              value={props.from}
              placeholder="Select Date"
              min={moment().format("YYYY-MM-DD")}
              onChange={props.cange}
              className="form-control "
              style={{ width: "150px" }}
            />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="form-group">
            <label id="p" htmlFor="to">
              {t("to")}
            </label>{" "}
            <span className="start_icon"> * </span>
            <input
              type="date"
              name="to_date"
              id="lol"
              value={props.to}
              min={props.from}
              placeholder="Select Date"
              onChange={props.cange}
              className="form-control "
              style={{ width: "150px" }}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default Form;
