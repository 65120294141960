import config from "../views/Main";
import Swal from "sweetalert2";
import axios from "axios";

export async function getCampaign(status) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.campaignn}?status=${status ? status : ""}`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getCampaignDetail(pk) {
  let products = new Promise((resolve, reject) => {
    fetch(`${config.apiUrl.campaignn}${pk}/manage/`, config.head)
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function deleteCampaign(pk, props) {
  let country = new Promise((resolve, reject) => {
    Swal.fire({
      title: "You won't be able to retrieve ? ",
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yea, Delete it",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${config.apiUrl.campaignn}${pk}/manage/`, config.head)
          .then((response) => {
            if (response.ok) {
              Swal.fire({
                title: "Cost Deleted",
                type: "error",
                text: "Error While deleting cost ??",
                timer: 3000,
              });
            } else {
              Swal.fire({
                title: "Cost Deleted",
                type: "success",
                text: "Cost Deleted Successfully !!",
                showConfirmButton: false,
                timer: 2000,
              });

              window.location.reload();
              window.location.href = "app/planned/campaign/";
              //props.history.push("/campaign/");
            }
            resolve(response.json());
          })

          .catch((reject) => console.log(reject));
      }
    });
  });

  return country;
}
