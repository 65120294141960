import React, { Component } from "react";
// import Form from "../Form";
// import config from "../../Main";
import { withTranslation } from "react-i18next";
import arrow from "../../../img/arrow.png";
import { getDriver } from "../../../Service/Driver";

export class DriverList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedBoxCheck: false,
      lol: "",
      fol: "",
      selectAll: false,
      checkAll: false,
      data: [],
      walk: [],
      selectedItems: [],
    };
  }

  // Get Data from filter date
  getData = async (e) => {
    const from = e.target.elements.from.value;
    const to = e.target.elements.to.value;
    e.preventDefault();

    getDriver(to, from).then((data) => {
      this.setState({ walk: data, lol: from, fol: to });
    });
  };

  componentDidMount() {
    getDriver().then((data) => {
      this.setState({ walk: data });
    });
  }

  async createCampaign(e) {
    const base = process.env.PUBLIC_URL;
    e.preventDefault();
    this.props.history.push({
      pathname: base + "/driver/create/campaign/",
      state: {
        selectedItems: this.state.selectedItems,
      },
    });
  }

  async onItemSelect(row) {
    const selectedItems = this.state.selectedItems.slice(0);
    if (selectedItems.includes(row)) {
      selectedItems.splice(selectedItems.indexOf(row), 1);
    } else {
      selectedItems.push(row);
    }
    await this.setState({
      selectedItems,
    });
    console.log("Hello", this.state.selectedItems);
  }

  toggleSelectAll() {
    let selectedItems = [];
    var checkedBoxCheck = !this.state.checkedBoxCheck;
    if (checkedBoxCheck) {
      this.state.walk.forEach((x) => {
        selectedItems.push(x.id);
      });
    } else {
      selectedItems = [];
    }
    this.setState((prevState) => ({
      selectedItems,
      checkedBoxCheck,
    }));

    console.log(selectedItems);
  }

  async onChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
    console.log(this.state.driver);
  }

  compareByAsc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByDesc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return 1;
      if (a[key] > b[key]) return -1;
      return 0;
    };
  }

  sortBy(key) {
    let arrayCopy = [...this.state.todos];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(this.compareByAsc(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(this.compareByDesc(key));
    }
    this.setState({ todos: arrayCopy });
  }

  render() {
    const { walk } = this.state;
    const { t } = this.props;
    return (
      <div className="Main_section">
        {walk.length > 0 && (
          <div
            className="headerr"
            style={{ marginTop: "5px", border: "1px solid gray" }}
          >
            <div className="drive_score">
              <h6>{t("special_report").toUpperCase()}</h6>
              <span className="float-right">
                <form onSubmit={this.createCampaign.bind(this)}>
                  <div className="float-right">
                    <button className="btn" id="b" style={{ marginTop: "0px" }}>
                      {t("create_campaings").toUpperCase()}
                    </button>
                  </div>
                </form>
              </span>
            </div>
            <div className="caaar" style={{ marginTop: "-30px" }}>
              <table id="table-to-xls" className="table table-hover">
                <thead>
                  <tr className="th ">
                    <th>
                      <input
                        type="checkbox"
                        class="select-all checkbox t"
                        name="driverList"
                        id="selectAll1"
                        checked={this.state.checkedBoxCheck}
                        onClick={this.toggleSelectAll.bind(this)}
                      />
                    </th>
                    <th className="  sorttable_nosort" style={{ width: "5%" }}>
                      {t("Id")}
                    </th>
                    <th onClick={() => this.sortBy("name")}>
                      {t("Name")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />{" "}
                    </th>

                    <th onClick={() => this.sortBy("overallscore")}>
                      {t("OverallScore")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />{" "}
                    </th>
                    <th onClick={() => this.sortBy("overallspeedscore")}>
                      {t("OverallSpeed")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />{" "}
                    </th>
                    <th onClick={() => this.sortBy("overalldynamicscore")}>
                      {t("OverallDynamic")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />{" "}
                    </th>
                    <th onClick={() => this.sortBy("overallefficienyscore")}>
                      {t("OverallEfficiency")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {walk.map((c, i) => (
                    <tr key={c.id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={this.state.selectedItems.includes(c.id)}
                          className="checkbox"
                          name="driverList"
                          onClick={() => this.onItemSelect(c.id)}
                        />
                      </td>
                      <td className="font-weight-bolder">{i + 1}</td>
                      <td
                        style={{ color: "#b71c1c" }}
                        className="font-weight-bolder"
                      >
                        {c.name ? `${c.name}` : " ? "}
                      </td>
                      <td>
                        {c.overallscore
                          ? `${c.overallscore.toFixed(1)}`
                          : " - "}
                      </td>
                      <td>
                        {c.overallspeedscore
                          ? `${c.overallspeedscore.toFixed(1)}`
                          : " - "}
                      </td>
                      <td>
                        {c.overalldynamicscore
                          ? `${c.overalldynamicscore.toFixed(1)}`
                          : " - "}
                      </td>
                      <td>
                        {c.overallefficienyscore
                          ? `${c.overallefficienyscore.toFixed(1)}`
                          : " - "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(DriverList);
