
const compareByDesc1 = (key) => {
    return function (a, b) {
      if (a["span"][key]["value"] < b["span"][key]["value"]) return 1;
      if (a["span"][key]["value"] > b["span"][key]["value"]) return -1;
      return 0;
    };
  };


  export default  compareByDesc1 ;