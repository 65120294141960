import React, { Component } from "react";
import { withTranslation } from "react-i18next";
class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <tbody>
        <tr>
          <td></td>
          <td>
            {" "}
            <input
              className="form-control "
              placeholder={t("search")}
              type="text"
              name="vin"
              style={{
                width: "70%",
                marginLeft: "-2%",
                textAlign: "left",
              }}
              // value={this.props.vall.vin}
              onChange={this.props.load}
            />
          </td>
          <td>
            <input
              className="form-control finds"
              placeholder={t("search")}
              type="text"
              name="plate"
              style={{
                width: "70%",
                marginLeft: "-2%",
                textAlign: "left",
              }}
              //value={this.props.vall.plate}
              onChange={this.props.load}
            />
          </td>
          <td></td>
          <td>
            <input
              className="form-control finds"
              placeholder={t("search")}
              type="text"
              name="brand"
              style={{
                width: "70%",
                marginLeft: "-2%",
                textAlign: "left",
              }}
              // value={this.props.vall.brand}
              onChange={this.props.load}
            />
          </td>
          <td>
            <input
              className="form-control finds"
              placeholder={t("search")}
              type="text"
              name="model"
              style={{
                width: "70%",
                marginLeft: "-2%",
                textAlign: "left",
              }}
              // value={this.props.vall.model}
              onChange={this.props.load}
            />
          </td>
          <td>
            <input
              className="form-control finds"
              placeholder={t("search")}
              type="text"
              name="year"
              maxLength="4"
              style={{
                width: "100%",
                marginLeft: "-2%",
                textAlign: "left",
              }}
              // value={this.props.vall.year}
              onChange={this.props.load}
            />
          </td>
          <td></td>
          <td>
            {/* <div className="row">
            <div className="col">
              <input
                className="form-control "
                placeholder={t("month")}
                type="number"
                maxLength="2"
                name="imonth"
                onChange={this.props.load}
              />
            </div>
            <div className="col">
              <input
                className="form-control "
                placeholder={t("year")}
                type="number"
                maxLength="4"
                name="iyear"
                onChange={this.props.load}
              />
            </div>
          </div> */}
          </td>
          <td>
            <input
              className="form-control finds"
              placeholder={t("search")}
              type="text"
              name="location"
              style={{
                width: "70%",
                marginLeft: "-2%",
                textAlign: "left",
              }}
              // value={this.props.vall.location}
              onChange={this.props.load}
            />
          </td>
          <td>
            <input
              className="form-control finds"
              placeholder={t("search")}
              type="text"
              name="location"
              style={{
                width: "70%",
                marginLeft: "-2%",
                textAlign: "left",
              }}
              // value={this.props.vall.location}
              onChange={this.props.load}
            />
          </td>
        </tr>
      </tbody>
    );
  }
}

export default withTranslation()(Search);
