import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import config from "../../../Main";
import deletee from "../../../../img/delete.png";

class Fine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driver: [],

      isHidden: true,
    };
  }

  handleAddFields = () => {
    const values = this.props.vall;
    values.push({
      car: "",
      text: "",
      date: null,
      value: "",
      other: "",
    });
    this.setState({
      values,
    });
  };

  toggleHidden =(id)=> {
    this.setState({
      [`isHidden${id}`]: false
    });
  }

  toggleHie =(id)=> {
    this.setState({ [`isHidden${id}`]: true });
  }

  async componentDidMount() {
    const res = await fetch(config.apiUrl.car, config.head);
    const driver = await res.json();
    this.setState({
      driver,
    });
  }

  handleRemoveFields = (index) => {
    const values = this.props.vall;
    values.splice(index, 1);
    this.setState({
      values,
    });
  };

  async onChange(e, index) {
    if (["car", "text", "date", "value", "other"].includes(e.target.name)) {
      let cats = [...this.props.vall];
      cats[index][e.target.name] = e.target.value;
      await this.setState({
        cats,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
    //console.log(this.props.vall);
  }
  render() {
    const { t } = this.props;
    const { driver } = this.state;

    return (
      <div>
        {this.props.vall.map((inputField, index) => (
          <div className="row" key={`${inputField}~${index}`}>
            <div className="col-sm-2">
              <div className="form-group">
                <label id="p" htmlFor="from">
                  {t("date").toUpperCase()}
                </label>
                <input
                  type="date"
                  value={inputField.date}
                  onChange={(e) => {
                    this.onChange(e, index);
                  }}
                  name="date"
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-sm-3">
              <div className="form-group">
                <label id="p" htmlFor="to">
                  {t("description").toUpperCase()}
                </label>
                <input
                  type="text"
                  placeholder={t("search")}
                  value={inputField.text}
                  onChange={(e) => {
                    this.onChange(e, index);
                  }}
                  name="text"
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-sm-2">
              <div className="form-group">
                <label id="p" htmlFor="to">
                  {t("value").toUpperCase()}
                </label>
                <input
                  type="number"
                  value={inputField.value}
                  onChange={(e) => {
                    this.onChange(e, index);
                  }}
                  name="value"
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-sm-1" style={{ marginTop: "5px" }}>
              <div>
                <input
                  type="radio"
                  placeholder="A Short Description"
                  name={`milage${index}`}
                  id={`r1r${index}`}
                 
                  onClick={() =>this.toggleHie(index)}
                  value="car"
                />
                <label id="p" htmlFor={`r1r${index}`}>
                  {t("choose_fleet_car")}
                </label>
              </div>

              <div style={{ marginRight: "-10px" }}>
                <input
                  type="radio"
                  placeholder="A Short Description"
                  name={`milage${index}`}
                  id={`r13r${index}`}
                  defaultChecked
                  onClick={() =>this.toggleHidden(index)}
                  class="form-check-input"
                  value="other"
                />
                <label id="p" htmlFor={`r13r${index}`}>
                  {t("other")}
                </label>
              </div>
            </div>

            {this.state[`isHidden${index}`] === true ? (
              <div className="col-sm-2">
                <div className="form-group">
                  <label id="p" htmlFor="Plate_number">
                    {t("plate_number1").toUpperCase()}
                  </label>
                  <select
                    name="car"
                    onChange={(e) => {
                      this.onChange(e, index);
                    }}
                    value={inputField.car}
                    class="form-control donn"
                  >
                    <option value="" disabled hidden selected>
                      {t("choose_fleet_car")}
                    </option>

                    {driver.map((c) => (
                      <option value={c.id} key={c.id}>
                        {c.plate_number}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ) : (
              <div className="col-sm-2">
                <div className="form-group">
                  <label id="p" htmlFor="to">
                    {t("other").toUpperCase()}
                  </label>
                  <input
                    type="text"
                    placeholder={t("type")}
                    value={inputField.other}
                    onChange={(e) => {
                      this.onChange(e, index);
                    }}
                    name="other"
                    className="form-control"
                  />
                </div>
              </div>
            )}

            <div className="col-sm-2">
              <button
                className="btn btn-danger"
                style={{
                  marginTop: "30px",
                  background: "none",
                  border: "none",
                }}
                type="button"
                onClick={() => this.handleRemoveFields(index)}
              >
                <img src={deletee} alt="Delete" />
              </button>
            </div>
          </div>
        ))}

        <a
          href="# "
          onClick={() => this.handleAddFields()}
          className="p font-weight-bolder"
          style={{ color: "#b71c1c" }}
        >
          {t("add_fine")}
        </a>
      </div>
    );
  }
}

export default withTranslation()(Fine);
