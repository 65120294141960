import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class Formal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        {/* Row Start Here */}

        <div className="row">
          <div className="col-sm-3">
            <div className="form-group">
              <label id="p" htmlFor="plate_number">
                {t("plate_number").toUpperCase()}{" "}
                <span className="start_icon"> * </span>
              </label>
              <input
                type="text"
                name="plate_number"
                maxlength={13}
                placeholder={t("type")}
                value={this.props.vall.plate_number}
                onChange={this.props.loadd}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-sm-3">
            <div className="form-group">
              <label id="p" htmlFor="plate_number">
                {t("vin").toUpperCase()} <span className="start_icon"> * </span>
              </label>
              <input
                type="text"
                name="vin"
                maxlength={17}
                placeholder={t("type")}
                value={this.props.vall.vin}
                onChange={this.props.loadd}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-sm-3">
            <div className="form-group">
              <label id="p" for="exampleInputEmail1">
                {t("date_of_registration").toUpperCase()}{" "}
                <span className="start_icon"> * </span>
              </label>
              <input
                type="date"
                name="first_registration"
                value={this.props.vall.first_registration}
                onChange={this.props.loadd}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-sm-3">
            <div className="form-group">
              <label id="p" for="exampleInputEmail1">
                {t("date_of_next_inspection").toUpperCase()}{" "}
                <span className="start_icon"> * </span>
              </label>
              <input
                type="date"
                name="next_registration_exam"
                value={this.props.vall.next_registration_exam}
                onChange={this.props.loadd}
                className="form-control"
              />
            </div>
          </div>

          {/* Row End Here */}

          {/* Row Start Here */}

          <div className="col-sm-3">
            <div className="form-group">
              <label id="p" for="exampleInputEmail1">
                {t("insurance_expiration_date").toUpperCase()}{" "}
                <span className="start_icon"> * </span>
              </label>
              <input
                type="date"
                name="insurance_expiration"
                value={this.props.vall.insurance_expiration}
                onChange={this.props.loadd}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-sm-3">
            <div className="form-group">
              <label id="p" for="exampleInputEmail1">
                {t("maintainance_interval").toUpperCase()}{" "}
                <span className="start_icon"> * </span>
              </label>
              <input
                type="number"
                placeholder={t("type")}
                name="oil_change_interval"
                value={this.props.vall.oil_change_interval}
                onChange={this.props.loadd}
                className="form-control"
              />
            </div>
          </div>

          {/* Row End Here */}

          {/* Row Start Here */}

          <div className="col-sm-3">
            <div className="form-group">
              <label id="p" for="exampleInputEmail1">
                {t("last_maintaince_date").toUpperCase()}{" "}
                <span className="start_icon"> * </span>
              </label>
              <input
                type="date"
                name="last_oil_inspecion"
                value={this.props.vall.last_oil_inspecion}
                onChange={this.props.loadd}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-sm-3">
            <div className="form-group">
              <label id="p" for="exampleInputEmail1">
                {t("last_maintaince_mileage").toUpperCase()}{" "}
                <span className="start_icon"> * </span>
              </label>
              <input
                type="number"
                placeholder={t("type")}
                name="last_oil_change_mileage"
                value={this.props.vall.last_oil_change_mileage}
                onChange={this.props.loadd}
                className="form-control"
              />
            </div>
          </div>

          {/* Row End Here */}

          {/* Row Start Here */}

          <div className="col-sm-3">
            <div className="form-group">
              <label id="p" for="exampleInputEmail1">
                {t("car_zone").toUpperCase()}{" "}
                <span className="start_icon"> * </span>
              </label>
              <select
                name="zone"
                value={this.props.vall.zone}
                onChange={this.props.loadd}
                class="form-control donn"
              >
                <option value="" disabled hidden selected>
                  {t("choose")}
                </option>
                <option value="1">Country</option>
                <option value="2">Abroad</option>
              </select>
            </div>
          </div>

          <div className="col-sm-3">
            <div className="form-group">
              <label id="p" for="exampleInputEmail1">
                {t("working_hour").toUpperCase()}{" "}
                <span className="start_icon"> * </span>
              </label>
              <div className="row">
                <div className="col-sm-6">
                  <input
                    type="time"
                    placeholder={t("from")}
                    name="hours_start"
                    step="any"
                    min={0}
                    value={this.props.vall.hours_start}
                    onChange={this.props.loadd}
                    className="form-control donn"
                  />
                </div>

                <div className="col-sm-6">
                  <input
                    type="time"
                    placeholder={t("to")}
                    name="hours_end"
                    min={0}
                    step="any"
                    value={this.props.vall.hours_end}
                    onChange={this.props.loadd}
                    className="form-control donn"
                  />
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Formal);
