import React, { createRef, Component } from "react";
import config from "../../Main";
import {
  Map,
  TileLayer,
  Polyline,
  Marker,
  Popup,
  FeatureGroup,
} from "react-leaflet";
import { withRouter } from "react-router-dom";
import Spinner from "../../../Container/Spinner";
import L from "leaflet";
import { withTranslation } from "react-i18next";
import start from "../../../img/Start.svg";
import end from "../../../img/End.svg";
import orange from "../../../img/Rating/orange.png";
import yellow from "../../../img/Rating/yellow.png";
import red from "../../../img/Rating/braking.png";
import acceleration1 from "../../../img/Rating/acceleration.png";
import redicon from "../../../img/Rating/red.png";
import one from "../../../img/Rating/1x1.png";
import { getMyCar } from "../../../Service/Car";
import arrow_left from "../../../img/Aarrow.png";
import moment from "moment";
import "react-leaflet-fullscreen/dist/styles.css";
import FullscreenControl from "react-leaflet-fullscreen";

class Mapp1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true,
      isOption: true,
      mapa: [],
      road: [],
      engine: [],
      cords: [],
      start: [],
      segment: [],
      critical: [],
      isLoading: true,
      switch: "speed",
      car: [],
    };

    this.mapRef = createRef();
    this.groupRef = createRef();
    this.popup = React.createRef();
  }

  // the magic
  closePopusOnClick() {
    this.popup.current.leafletElement.options.leaflet.map.closePopup();
  }

  // For Hide and show option Map
  toggleHidden1 = () => {
    this.setState({
      isOption: false,
    });
  };

  toggleHie = () => {
    this.setState({ isOption: true });
  };

  // For Hide And Show Map Form
  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  handleClick() {
    const map = this.mapRef.current.leafletElement;
    const group = this.groupRef.current.leafletElement;
    map.fitBounds(group.getBounds());
  }

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  getData = () => {
    getMyCar().then((data) => {
      this.setState({
        car: data,
      });
    });
  };

  async componentDidMount() {
    this.getData("");
    try {
      const res = await fetch(
        `${config.apiUrl.app_trip}${this.props.match.params.id}/`,
        config.head
      );
      const mapa = await res.json();
      this.setState({
        isLoading: false,
        mapa,
        road: mapa.roadParameters,
        engine: mapa.engineParameters,
        cords: mapa.coordinates,
        start: mapa.coordinates[0],
        critical: mapa.criticalOverspeedingEvents,
        segment: mapa.shapes,
      });
      let mapInst = this.mapRef.current.leafletElement;
      const group = this.groupRef.current.leafletElement; //get native featureGroup instance
      mapInst.fitBounds(group.getBounds());
    } catch (e) {
      console.log(e);
    }
  }

  entering = (e) => {
    e.children[0].style.borderTopColor = "black";
    e.children[1].style.color = "black";
    e.children[1].style.border = "1px solid black";
    e.children[1].style.background = "white";
  };

  render() {
    const { mapa, engine, segment } = this.state; //critical , car
    const { cords } = this.state;
    const { t } = this.props;

    const pointerIcon = new L.Icon({
      iconUrl: start,
      iconAnchor: [25, 40],
      iconSize: [50, 50],
    });

    const suitcasePoint = new L.Icon({
      iconUrl: end,
      iconAnchor: [25, 45],
      iconSize: [50, 50],
    });

    // const critical1 = new L.Icon({
    //   iconUrl: map1,
    //   iconAnchor: [22, 60],
    //   iconSize: [45, 60],
    // });

    const red_color = new L.Icon({
      iconUrl: redicon,
      iconAnchor: [18, 35],
      iconSize: [35, 35],
      className: "yellow_icon",
    });
    const red1 = new L.Icon({
      iconUrl: red,
      iconAnchor: [18, 35],
      iconSize: [35, 35],
      className: "yellow_icon",
    });
    const orange1 = new L.Icon({
      iconUrl: orange,
      iconAnchor: [18, 35],
      iconSize: [35, 35],
      className: "yellow_icon",
    });
    const yellow1 = new L.Icon({
      iconUrl: yellow,
      iconAnchor: [18, 35],
      iconSize: [35, 35],
      className: "yellow_icon",
    });

    const acc = new L.Icon({
      iconUrl: acceleration1,
      iconAnchor: [18, 35],
      iconSize: [35, 35],
      className: "yellow_icon",
    });
    const ee = new L.Icon({
      iconUrl: one,
      iconAnchor: [1, 3],
      iconSize: [3, 3],
      className: "yellow_icon",
    });

    const speed = segment.filter((c) => c.slug === "speed");
    const acceleration = segment.filter((c) => c.slug === "acceleration");
    const breaking = segment.filter((c) => c.slug === "braking");
    // const fuel = segment.filter(c => c.slug === "fuel")

    const switcher =
      this.state.switch === "speed"
        ? speed
        : this.state.switch === "acceleration"
        ? acceleration
        : breaking;

    const map_icon = (data) =>
      data.icon === "overspeeding-yellow"
        ? yellow1
        : data.icon === "overspeeding-orange"
        ? orange1
        : data.icon === "braking-yellow"
        ? red1
        : data.icon === "acceleration-yellow"
        ? acc
        : data.icon === "overspeeding-red"
        ? red_color
        : ee;
    const map_icon_class = (data) =>
      data.icon === "overspeeding-yellow"
        ? "yellow1"
        : data.icon === "overspeeding-orange"
        ? "orange11"
        : data.icon === "braking-yellow"
        ? "yellow1"
        : data.icon === "acceleration-yellow"
        ? "yellow1"
        : data.icon === "overspeeding-red"
        ? "red1"
        : ee;

    console.log(typeof this.props.match.params.year);
    return (
      <div className="Main_section">
        {this.state.isLoading === true ? (
          <Spinner />
        ) : (
          <span>
            <div style={{ marginTop: "20px" }}>
              <a href="# " onClick={this.props.history.goBack}>
                <img
                  src={arrow_left}
                  alt="Left_Arrow"
                  style={{ height: "2.6em" }}
                />
              </a>
            </div>
            &nbsp; &nbsp;
            <div className="headerr">
              <h2 style={{ marginTop: "20px" }} id="mod1">
                {t("map")}
                &nbsp; &nbsp;
                {}
                {t("trip")} {this.props.match.params.trip} &nbsp;
                {/* {Number.isInteger(this.props.match.params.year) === false ? (
                  <select
                    value={this.props.match.params.year}
                    disabled
                    className="font-weight-bolder "
                    style={{
                      border: "none",
                      fontSize: "14px", 
                      color: "black",
                      opacity: "1",
                      background: "white",
                    }}
                  >
                    {car.map((c) => (
                      <option value={c.id}>{c.plate_number}</option>
                    ))}
                  </select>
                ) : (
                  this.props.match.params.year
                )} */}
                {this.props.match.params.year} &nbsp; &nbsp;{" "}
                {this.props.match.params.from} &nbsp;
              </h2>

              <div
                className="row"
                style={{ marginTop: "20px", marginBottom: "-25px" }}
              >
                <div className="col-md-8">
                  <div className="row">
                    <div className=" col-sm-2 form-group">
                      <input
                        type="radio"
                        name="switch"
                        id="speed"
                        defaultChecked
                        onChange={this.onChange}
                        value="speed"
                      />
                      <label id="p" htmlFor="speed">
                        {t("speed")}
                      </label>
                    </div>
                    <div className=" col-sm-2 form-group">
                      <input
                        type="radio"
                        name="switch"
                        id="acceleration"
                        onChange={this.onChange}
                        value="acceleration"
                      />
                      <label id="p" htmlFor="acceleration">
                        {t("acceleration")}
                      </label>
                    </div>
                    <div className=" col-sm-2 form-group">
                      <input
                        type="radio"
                        name="switch"
                        id="braking"
                        onChange={this.onChange}
                        value="braking"
                      />
                      <label id="p" htmlFor="braking">
                        {t("braking")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: "30px" }}>
                {cords && (
                  <div className="col-sm-6">
                    <Map
                      center={cords[0]}
                      zoom={12}
                      maxZoom={19}
                      ref={this.mapRef}
                      bounceAtZoomLimits={true}
                      maxBoundsViscosity={0.95}
                      maxBounds={[
                        [-180, -90],
                        [180, 90],
                      ]}
                      style={{ width: "auto", height: "500px" }}
                    >
                      <FeatureGroup ref={this.groupRef}>
                        <Marker position={cords[0]} icon={pointerIcon}>
                          <Popup className="start_point">
                            <span className="h6">
                              StarPoint : {mapa.startPlace}
                            </span>{" "}
                            <br />
                            <span
                              className="ppp"
                              style={{ fontSize: "12px", opacity: "0.7" }}
                            >
                              {moment(mapa.startTime).format("hh:mm")}
                            </span>
                          </Popup>
                        </Marker>

                        <Marker
                          position={cords[cords.length - 1]}
                          icon={suitcasePoint}
                        >
                          <Popup
                            onEntering={this.entering}
                            className="end_point"
                          >
                            <span className="h6">
                              EndPoint : {mapa.endPlace} <br />{" "}
                            </span>
                            <span
                              className="ppp"
                              style={{ fontSize: "12px", opacity: "0.7" }}
                            >
                              {moment(mapa.endTime).format("hh:mm")}
                            </span>
                          </Popup>
                        </Marker>
                      </FeatureGroup>

                      {/* {this.state.switch === "acceleration" ||
                      this.state.switch === "braking" ? (
                        ""
                      ) : (
                        <FeatureGroup ref={this.groupRef}>
                          {critical.map((c) => (
                            <Marker position={c.location} icon={critical1}>
                              <Popup
                              
                                closeOnClick={false}
                                className="red1"
                              >
                                {t("point")} : {c.address} <br />{" "}
                                <span
                                  className="ppp"
                                  style={{ fontSize: "12px", opacity: "0.7" }}
                                >
                                  <i> {c.time} </i>
                                </span>
                                <p style={{ marginTop: "-2px" }}>
                                  {t("max_overspeed")} {c.maxOverspeed} km/h
                                </p>
                                <p style={{ marginTop: "-20px" }}>
                                  {t("speed_limit")} : {c.speedLimit} km/h
                                </p>
                                <p style={{ marginTop: "-20px" }}>
                                  {t("distance")} : {c.distance}
                                </p>
                              </Popup>
                            </Marker>
                          ))}
                        </FeatureGroup>
                      )} */}

                      <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />

                      <FeatureGroup ref={this.groupRef}>
                        <Polyline
                          positions={cords}
                          color={"#00008b"}
                          lineJoin={"round"}
                          lineCap={"round"}
                          opacity={1}
                          weight={5}
                          smoothFactor={1}
                          fillRule={"evenodd"}
                        ></Polyline>
                      </FeatureGroup>

                      <FeatureGroup ref={this.groupRef}>
                        {switcher.map((d) =>
                          d.segments.map((c) => (
                            <Polyline
                              positions={c.coordinates}
                              color={
                                c.icon === "overspeeding-yellow"
                                  ? "yellow"
                                  : `${
                                      c.icon === "overspeeding-red"
                                        ? "red"
                                        : `${
                                            c.icon === "overspeeding-orange"
                                              ? "orange"
                                              : "#00008b"
                                          }`
                                    }`
                              }
                              lineJoin={"round"}
                              lineCap={"round"}
                              opacity={1}
                              weight={5}
                              smoothFactor={1}
                              fillRule={"evenodd"}
                            >
                              <FeatureGroup ref={this.groupRef}>
                                {c.stats.length > 0 && (
                                  <Marker
                                    position={c.coordinates[0]}
                                    icon={map_icon(c)}
                                  >
                                    <Popup
                                      autoClose={false}
                                      closeOnClick={false}
                                      className={map_icon_class(c)}
                                    >
                                      <div className="row">
                                        {c.stats.map((dd) => (
                                          <div className="col-md-6 heheje">
                                            <p id="pppp_fff">{dd.label}</p>
                                            <p className="pppp1_ff">
                                              {dd.value ? dd.value : "-"}
                                            </p>
                                          </div>
                                        ))}
                                      </div>
                                    </Popup>
                                  </Marker>
                                )}
                              </FeatureGroup>
                            </Polyline>
                          ))
                        )}
                      </FeatureGroup>
                      <FullscreenControl position="topleft" />
                    </Map>
                    <div className="row">
                      <div className="col-md-6">
                        <p style={{ marginTop: "20px" }}>
                          <span class="circle"></span>
                          {t("blue")}
                        </p>

                        <p style={{ marginTop: "20px" }} className="blue">
                          <span class="yellow"></span>
                          {t("yellow")}
                        </p>
                      </div>

                      <div className="col-md-6">
                        <p style={{ marginTop: "20px" }} className="blue">
                          <span class="orange1"></span>
                          {t("orange")}
                        </p>
                        <p style={{ marginTop: "20px" }} className="blue">
                          <span class="red"></span>

                          {t("red")}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                <div class="col-md-6  ">
                  <span className="pp" style={{ marginBottom: "20px" }}>
                    {mapa.startPlace}
                  </span>{" "}
                  -<br></br>
                  <span className="pp">{mapa.endPlace}</span>
                  <div className="row" style={{ marginTop: "40px" }}>
                    <div className="col-sm-5">
                      <div class="">
                        <input
                          type="radio"
                          name="r"
                          id="r112"
                          defaultChecked
                          onClick={this.toggleHie}
                        />
                        <label for="r112" className="line">
                          {t("road_parameter")}
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div class="">
                        <input
                          type="radio"
                          name="r"
                          id="r1111"
                          onClick={this.toggleHidden1}
                        />
                        <label for="r1111" className="line">
                          {t("engine_parameter")}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* This Section is for diaplsy data like milage and other Parameters   */}
                  {this.state.isOption ? (
                    <div className="row" style={{ marginTop: "30px" }}>
                      {this.state.road.map((c) => (
                        <div
                          className="col-sm-4"
                          key={c.id}
                          style={{ marginBottom: "20px" }}
                        >
                          <p id="ppp" className="t">
                            {c.label.toUpperCase()}
                          </p>
                          <p class="pp">{c.value}</p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="row" style={{ marginTop: "30px" }}>
                      {engine.map((c) => (
                        <div className="col-sm-3" key={c.id}>
                          <p id="ppp">
                            {c.label ? c.label.toUpperCase() : "-"}
                          </p>
                          <p class="pp">{c.value ? c.value : "-"}</p>
                        </div>
                      ))}
                    </div>
                  )}
                  {/* END This Section is for diaplsy data like milage and other Parameters   */}
                  <div className="map_route">
                    <img
                      src={start}
                      alt="Start Point "
                      style={{ width: "40px" }}
                    />
                    <strong className="pp">
                      {t("start_trip").toUpperCase()}
                    </strong>
                    : <i>{moment(mapa.startTime).format("hh:mm")}</i> {" - "}{" "}
                    {mapa.startPlace}
                  </div>
                  <br></br>
                  <div className="map_route" style={{ marginTop: "-10px" }}>
                    <img src={end} alt="End Point " style={{ width: "40px" }} />
                    <strong className="pp">
                      {t("end_trip").toUpperCase()}
                    </strong>
                    : <i>{moment(mapa.endTime).format("hh:mm")}</i> {" - "}{" "}
                    {mapa.endPlace}
                  </div>
                </div>
              </div>
            </div>
          </span>
        )}
      </div>
    );
  }
}

export default withTranslation()(withRouter(Mapp1));
