import React, { Component } from "react";
import { Helmet } from "react-helmet";
import arrow_left from "../../../../img/Aarrow.png";
import map from "../../../../img/route1.png";
import Battery from "./Chart/Battery";
import Trip from "./Chart/Trip";
import Charging from "./Chart/Charging";
import Trips from "./Chart/Trip1";
import Charging1 from "./Chart/Charging1";
import Energy from "./Donut/Energy";
import Recover from "./Donut/Recover";
import Spent from "./Donut/Spent";
import Charge from "./Charger/Charge";
import EnergyTime from "./Donut/EnergyTIme";
import Daily from "./Donut/Daily";
import Operation from "./Donut/Operation";
import Header1 from "../../../../Components/Layout/Header1";
// import Form from "./Form";

import DailyReport from "./Report/DailyReport";
import { withTranslation } from "react-i18next";
import { getEvDaily } from "../../../../Service/EvCar";
import Spinner from "../../../../Container/Spinner";

export class DailyView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      basic: [],
      battery: [],
      trip: [],
      trip1: [],
      charging1: [],
      charging2: [],
      recover: [],
      charge: [],
      energy: [],
      energy_time: [],
      station: [],
      daily: [],
      operation: [],
      consumed: [],
      isLoading: true,
    };
  }

  async componentDidMount() {
    getEvDaily(this.props.match.params.pk, this.props.match.params.date).then(
      (data) => {
        this.setState({
          data: data,
          recover: data.spent_recovered_time,
          charge: data.recovered_time_kwh,
          energy: data.energy_source_kwh,
          energy_time: data.energy_source_time,
          basic: data.basic_information.basic_data,
          battery: data.basic_information.battery,
          trip: data.trips.Battery_consumed,
          consumed: data.trips.Overall_battery_consumed,
          trip1: data.trips.trips,
          charging1: data.charging.Battery_charging,
          charging2: data.charging.Overall_battery_charging,
          station: data.charging_station,
          daily: data.daily_time,
          operation: data.opration_time,
          isLoading: false,
        });
      }
    );
  }

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  render() {
    const {
      basic,
      battery,
      trip,
      trip1,
      charging1,
      recover,
      charge,
      energy,
      energy_time,
      station,
      daily,
      operation,
      data,
      charging2,
      consumed,
    } = this.state;

    const { t } = this.props;
    const base = process.env.PUBLIC_URL;

    return (
      <div className="Main_section">
        <Helmet> Electric Car Daily View</Helmet>
        <DailyReport basic={basic} data={data} />

        <div>
          <Header1 />
        </div>
        <div className="headerr">
          <div className="row" style={{ width: "100%" }}>
            <div className="col-sm-12">
              {" "}
              <a href="# " onClick={this.handleClick.bind(this)}>
                <img
                  src={arrow_left}
                  alt="Left_Arrow"
                  style={{ height: "2.6em", marginTop: "-3px" }}
                />
              </a>
              &nbsp;&nbsp;&nbsp;
              <span>
                {" "}
                {t("daily_elecric_profile").toUpperCase()} / {data.car_plate} /{" "}
                <strong>
                  {" "}
                  {data.date} - {t("battery").toUpperCase()}
                </strong>{" "}
              </span>
            </div>
          </div>
          {this.state.isLoading === true ? <Spinner /> : ""}
          {/* <div className="main_start">
            <h4 className="font-weight-bolder" style={{ fontSize: "19px" }}>
              <strong>{t("daily_elecric_profile").toUpperCase()}</strong>
            </h4>
            <Form />
          </div> */}

          <div className="row" style={{ width: "100%", paddingLeft: "20px" }}>
            <div className="col-md-7">
              <h3 className="font-weight-normal" style={{ marginTop: "30px" }}>
                {t("general_information")}
                <a
                  href={
                    base +
                    `/carmap/${this.props.match.params.pk}/${data.date}/${data.car_plate}`
                  }
                  className="float-right"
                >
                  <img
                    src={map}
                    alt="mapa"
                    title={t("map_title")}
                    style={{ height: "20px" }}
                  />
                </a>
              </h3>
              <hr></hr>

              {/* This section belong to batter and deatils */}
              <span>
                {battery.length > 1 ? <Battery battery={battery} /> : ""}

                <div className="row" style={{ marginTop: "60px" }}>
                  {basic.map((c, i) => (
                    <div className="col-md-4">
                      <div className=" n">
                        <p id="pppp">{c.label.toUpperCase()}</p>
                        <p className="pppp1">
                          {c.label === "Daily Energy from charging" ||
                          c.label === "ENERGIA OD ŁADOWANIA"
                            ? `${c.value ? c.value : 0} kWh`
                            : c.value
                            ? c.value
                            : 0}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </span>

              {/* This section belong to Trips and charging */}
            </div>
            <div className="col-md-5">
              <h3 className="font-weight-normal" style={{ marginTop: "30px" }}>
                {t("charging_information")}
              </h3>
              <hr></hr>

              <Charge
                station={station}
                last={data}
                id={this.props.match.params.pk}
              />
            </div>
          </div>

          {/* Trip information section */}

          <div className="row">
            <div className="col-md-12">
              <div className="trips_charging">
                <h3
                  className="font-weight-normal"
                  style={{ marginTop: "40px" }}
                >
                  {t("trips_and_charging")}
                </h3>
                <hr></hr>
                <div className="row">
                  <div className="col-md-6">
                    {trip.length > 1 ? (
                      <Trip trip1={trip} />
                    ) : (
                      <p className="pppp1 something_should_work">
                        No Trip on this day
                      </p>
                    )}
                  </div>
                  <div className="col-md-6">
                    {charging1.length > 0 ? (
                      <Charging charging1={charging1} />
                    ) : (
                      <p className="pppp1 something_should_work">
                        No Charging on this day
                      </p>
                    )}
                  </div>
                  <div className="col-md-6" style={{ marginTop: "50px" }}>
                    {consumed.length > 0 ? (
                      <Trips trip1={consumed} />
                    ) : (
                      <p className="pppp1 something_should_work">
                        No Trip on this day
                      </p>
                    )}
                  </div>
                  <div className="col-md-6" style={{ marginTop: "50px" }}>
                    {charging2.length > 0 ? (
                      <Charging1 charging1={charging2} />
                    ) : (
                      <p className="pppp1 something_should_work">
                        No Charging on this day
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="trip_info" style={{ marginTop: "70px" }}>
                <h3 className="font-weight-normal">
                  {" "}
                  {t("trips_information")}
                </h3>
                <hr></hr>

                {/* Trip deatils section */}

                <div className=" trips">
                  {trip1.map((c, i) => (
                    <div
                      className="row  trips_details"
                      key={i + 1}
                      style={{ marginTop: "20px" }}
                    >
                      <div
                        className="trip"
                        style={{ position: "relative", top: "35px" }}
                      >
                        <p className="pppp1">
                          {" "}
                          {t("trip")} {i + 1}
                        </p>
                      </div>
                      <div className="col-md-3" style={{ width: "110%" }}>
                        <div className="n">
                          <ul class="timeline">
                            <li>
                              <p className="pppp1">
                                {c.startPlace} ({c.startTime})
                              </p>
                            </li>

                            <li style={{ marginTop: "-10px" }}>
                              <p className="pppp1">
                                {c.endPlace} ({c.endTime})
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="n">
                          <p id="pppp">{t("distance").toUpperCase()}</p>
                          <p className="pppp1">{c.mileage.toFixed(1)} km</p>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="n">
                          <p id="pppp">{t("driving_time").toUpperCase()}</p>
                          <p className="pppp1">{c.drivingTime}</p>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="n">
                          <p id="pppp">{t("spent_energy").toUpperCase()}</p>
                          <p className="pppp1">
                            {c.spentEnergy.toFixed(1)} kWh
                          </p>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="n">
                          <p id="pppp">{t("recover_energy").toUpperCase()}</p>
                          <p className="pppp1">
                            {c.recoverEnergy.toFixed(1)}kWh
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Trip deatils section */}
              </div>
              {/* Trip information section end */}
            </div>

            {/* This section belong to spend recovered damaged */}
            <div className="donut_spent" style={{ marginTop: "60px" }}>
              <h3 className="font-weight-normal" style={{ marginTop: "40px" }}>
                {t("energy_statics")}
              </h3>
              <hr></hr>
              <div className="row">
                <div className="col-md-4">
                  {daily.length > 0 ? (
                    <Daily energy={daily} />
                  ) : (
                    <p className="pppp1 something_should_work">
                      No data available
                    </p>
                  )}
                </div>
                <div className="col-md-4">
                  {recover.length > 0 ? (
                    <Recover recover={recover} />
                  ) : (
                    <p className="pppp1 something_should_work">
                      No Energy spent on this day
                    </p>
                  )}
                </div>
                <div className="col-md-4">
                  {charge.length > 0 ? (
                    <Spent charge={charge} />
                  ) : (
                    <p className="pppp1 something_should_work">
                      No driving or charging
                    </p>
                  )}
                </div>
                <div className="col-md-4">
                  {operation.length > 0 ? (
                    <Operation energy={operation} />
                  ) : (
                    <p className="pppp1 something_should_work">
                      No data available
                    </p>
                  )}
                </div>
                <div className="col-md-4">
                  {energy_time.length > 0 ? (
                    <EnergyTime energy={energy_time} />
                  ) : (
                    <p className="pppp1 something_should_work">
                      No Charging data
                    </p>
                  )}
                </div>

                <div className="col-md-4">
                  {energy.length > 0 ? (
                    <Energy energy={energy} />
                  ) : (
                    <p className="pppp1 something_should_work">
                      No data available
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "60px" }}>
            <center>
              <a
                className="btn btn-login text-white font-weight-bolder boxx "
                href="#dailyreport"
                rel="modal:open"
                id="bttt"
                style={{
                  height: "40px",
                  fontSize: "13px",
                  marginTop: "30px",

                  background: "rgba(183, 28, 28, 1)",
                  border: "none",
                  color: "white",
                  margin: "auto",
                }}
              >
                {t("daily_report")}
              </a>
            </center>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(DailyView);
