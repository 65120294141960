import React, { useState } from "react";
import axios from "axios";
import config from "../../Main";

const CarsTable = ({ state, setSelectedCars, setFilteredCars }) => {
  const [showTable, setShowTable] = useState(true);

  const availableCarIds = state.ev2.map((car) => {
    return car.car;
  });

  const onSearchTermChange = async (e) => {
    //console.log(e.target);
    const name = e.target.name;
    const value = e.target.value;

    try {
      axios
        .get(
          `${config.apiUrl.car}?brand__icontains=${
            name === "brand" ? value : ""
          }&model__icontains=${
            name === "model" ? value : ""
          }&license_plate__icontains=${
            name === "plate" ? value : ""
          }&production_year=${name === "year" ? value : ""}&type=${
            name === "engineType" ? value : ""
          }&drivers_name=${""}`,
          config.head
        )
        .then((res) => {
          console.log("RESULT IS:", res);
          setFilteredCars(res.data, true);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const search = (fieldName) => {
    const searchBar = (
      <div class="input-group">
        <span class="input-group-addon" style={{ padding: "0" }}>
          <i
            class="fa fa-search"
            aria-hidden="true"
            style={{ marginTop: "4px" }}
          />
        </span>
        <input
          class="form-control"
          type="text"
          placeholder="Search"
          name={fieldName}
          onChange={onSearchTermChange}
        />
      </div>
    );
    return searchBar;
  };

  const handleChange2 = async (e, id) => {
    console.log("HANDLECHANGE 2!!!");

    let checkbox = e.target;

    // if this car id is alredy selected
    if (id === "all") {
      if (!checkbox.checked) {
        // remove id from array
        const array = [...state.selectOptions];
        array.splice(0, array.length);
        // await this.setState({
        //   selectOptions: array,
        //   allOptionsSelected: false,
        // });
        await setSelectedCars(array, false);
      } else {
        // push id in array
        const availableCarIds = state.ev2.map((car) => {
          return car.car;
        });
        // await this.setState({
        //   selectOptions: [...availableCarIds],
        //   allOptionsSelected: true,
        // });
        await setSelectedCars([...availableCarIds], true);
      }
    } else if (!checkbox.checked) {
      // remove id from array
      const array = [...state.selectOptions];
      const index = array.indexOf(id);
      if (index !== -1) {
        array.splice(index, 1);
        // await this.setState({
        //   selectOptions: array,
        // });
        await setSelectedCars(array);
      }
    } else {
      // push id in array
      //   await this.setState({
      //     selectOptions: [...this.state.selectOptions, id],
      //   });
      await setSelectedCars([...state.selectOptions, id]);
    }
  };

  const engineTypeDropDown = (
    <select
      className="form-control donn"
      style={{ maxWidth: "12rem" }}
      name="engineType"
      onChange={onSearchTermChange}
    >
      <option value="" selected>
        Engine type
      </option>
      <option>Pb</option>
      <option>ON</option>
    </select>
  );

  const toggleTable = () => {
    console.log("table toggled!");
    setShowTable(!showTable);
  };

  return (
    <div>
      <div
        className="caaar"
        style={
          !state.isHide && showTable
            ? {
                height: "420px",
                marginLeft: "5.5em",
                marginTop: "3em",
              }
            : {
                height: "0px",
                marginLeft: "5.5em",
                marginTop: "3em",
              }
        }
      >
        <button
          id="toggle"
          type="button"
          class="btn  font-weight-bold"
          style={{ color: "#b71c1c", borderRadius: "10px" }}
          onClick={toggleTable}
          value="kdkdkd"
        >
          {showTable ? "Hide" : "Show"}
        </button>
        {!state.isHide && showTable && (
          <table className="table">
            <thead style={{ backgroundColor: "#5ae309" }}>
              <tr style={{ backgroundColor: "white" }}>
                <th style={{ textAlign: "center" }}></th>
                <th style={{ textAlign: "center" }}>id</th>
                <th style={{ textAlign: "center" }}>Plate number</th>
                <th style={{ textAlign: "center" }}>Driver's name</th>
                <th style={{ textAlign: "center" }}>Engine type</th>
                <th style={{ textAlign: "center" }}>brand</th>
                <th style={{ textAlign: "center" }}>model</th>
                <th style={{ textAlign: "center" }}>Production year</th>
                <th style={{ textAlign: "center" }}>Engine capacity</th>
              </tr>
              <tr style={{ backgroundColor: "white" }}>
                <td style={{ textAlign: "center" }}>
                  <input
                    type="checkbox"
                    onClick={(e) => handleChange2(e, "all")}
                  />
                </td>
                <td></td>
                <td style={{ paddingLeft: "0", marginLeft: "-10px" }}>
                  {search("plate")}
                </td>
                <td style={{ paddingLeft: "0", marginLeft: "-10px" }}>
                  {search("driversName")}
                </td>
                <td style={{ paddingLeft: "0", marginLeft: "-10px" }}>
                  {engineTypeDropDown}
                </td>
                <td style={{ paddingLeft: "0", marginLeft: "-10px" }}>
                  {search("brand")}
                </td>
                <td style={{ paddingLeft: "0", marginLeft: "-10px" }}>
                  {search("model")}
                </td>
                <td style={{ paddingLeft: "0", marginLeft: "-10px" }}>
                  {search("year")}
                </td>
              </tr>
            </thead>

            <tbody>
              {state.car2
                .filter((car) => {
                  if (availableCarIds.includes(car.id)) {
                    return true;
                  } else {
                    return false;
                  }
                })
                .map((car, i) => {
                  return (
                    <tr>
                      <td style={{ textAlign: "center" }}>
                        <input
                          checked={state.selectOptions.includes(car.id)}
                          type="checkbox"
                          onChange={(e) => {
                            handleChange2(e, car.id);
                          }}
                        />
                      </td>
                      <td style={{ textAlign: "center" }}>{i + 1}</td>
                      <td style={{ textAlign: "center" }}>
                        <a
                          href={`/overview/app/carmetrics/${car.id}/`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#b71c1c" }}
                        >
                          {car.plate_number}
                        </a>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <a
                          href={`/overview/app/drivermetrics/${car.driver_id}/`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#b71c1c" }}
                        >
                          {car.driver_name}
                        </a>
                      </td>
                      <td style={{ textAlign: "center" }}>{car.type}</td>
                      <td style={{ textAlign: "center" }}>{car.brand}</td>
                      <td style={{ textAlign: "center" }}>{car.model}</td>
                      <td style={{ textAlign: "center" }}>
                        {car.production_year}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {car.engine_capacity}
                      </td>

                      {/* {console.log("CAR IS: ", car)} */}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default CarsTable;
