import React, { Component } from "react";
import config from "../../Main";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import Search from "./Search";
import arrow_left from "../../../img/Aarrow.png";
import Spinner from "../../../Container/Spinner";
import User from "../../../Components/User";
import Datee from "../../Views/Date";
import Recommendation from "../../../Components/Layout/Recommendation";
export class Choose extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedBoxCheck: false,
      isHidden: "checked",
      stats: [],
      value: "select",
      vin: " ",
      plate: " ",
      brand: " ",
      model: " ",
      location: "",
      technical: "",
      year: "",
      tmonth: "",
      tyear: "",
      imonth: "",
      iyear: "",
      engine: "",
      staus: "",
      selected: {},
      selectedItems: [],
      selectedPlates: [],
      isLoading: true,
    };
    this.toggleHidden = this.toggleHidden.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async handleSubmit(e) {
    const baseUrl = process.env.PUBLIC_URL;
    e.preventDefault();
    this.props.history.push({
      pathname: baseUrl + "/ev/recommendation/",
      state: {
        selectedItems: this.state.selectedItems,
        selectedPlates: this.state.selectedPlates,
      },
    });
  }

  async onSubmitt1(e) {
    try {
      const res = await fetch(
        `${config.apiUrl.car}?brand__icontains=${this.state.brand}&location__icontains=${this.state.location}&model__icontains=${this.state.model}&vin__icontains=${this.state.vin}&license_plate__icontains=${this.state.plate}&production_year=${this.state.year}&type=${this.state.engine}&active=${this.state.staus}&&next_registration_exam__month=${this.state.tmonth}&next_registration_exam__year=${this.state.tyear}&insurance_expiration__month=${this.state.imonth}&insurance_expiration__year=${this.state.iyear}`,
        config.head
      );
      const stats = await res.json();
      this.setState({
        stats,
        isLoading: false,
      });
    } catch (e) {
      console.log(e);
    }
  }

  toggleHidden() {
    this.setState({
      checked: !this.state.checked,
    });
  }

  async onChange(e) {
    await this.setState({
      [e.target.name]: e.target.value,
    });

    this.onSubmitt1("");
  }

  componentDidMount() {
    this.onSubmitt1("");
  }

  handleSelect = (e) => {
    const selected = this.state.selected;
    selected[e.target.name] = e.target.checked;
    this.setState({ selected });
  };

  async onItemSelect(row, plate_number) {
    const selectedItems = this.state.selectedItems.slice(0);

    const selectedPlates = this.state.selectedPlates.slice(0);

    if (selectedItems.includes(row)) {
      selectedItems.splice(selectedItems.indexOf(row), 1);
    } else {
      selectedItems.push(row);
    }

    if (selectedPlates.includes(plate_number)) {
      selectedPlates.splice(selectedPlates.indexOf(plate_number), 1);
    } else {
      selectedPlates.push(plate_number);
    }

    await this.setState({
      selectedItems,
      selectedPlates,
    });
  }

  toggleSelectAll() {
    let selectedItems = [];

    let selectedPlates = [];

    var checkedBoxCheck = !this.state.checkedBoxCheck;
    // this.setState({ checkedBoxCheck: checkedBoxCheck });
    if (checkedBoxCheck) {
      this.state.stats.forEach((x) => {
        selectedItems.push(x.id);
        selectedPlates.push(x.plate_number);
      });
    } else {
      selectedItems = [];
      selectedPlates = [];
    }
    this.setState((prevState) => ({
      selectedItems,
      selectedPlates,
      checkedBoxCheck,
    }));
  }

  render() {
    const { t } = this.props;
    const baseUrl = process.env.PUBLIC_URL;
    const onlyClassic = this.state.stats.filter((c) => c.type !== "BEV");
    return (
      <div className="container1" style={{ marginTop: "30px" }}>
        <Helmet>
          <title>CHOOSE LIST</title>
        </Helmet>

        <div className="row row1" style={{ width: "100%" }}>
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
        </div>

        <div style={{ marginTop: "-15px" }}>
          <Recommendation />
        </div>

        <div className="headerr">
          <div>
            <a href={baseUrl + "/ev/recommendation/"}>
              <img
                src={arrow_left}
                alt="Left_Arrow"
                title="Back to previous page"
                style={{ height: "2.6em" }}
              />
            </a>
            Back &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;{" "}
            <strong>{t("apply_to_recommendation")}</strong> &nbsp;
            <div>
              {" "}
              <form onSubmit={this.handleSubmit.bind(this)}>
                <div className="float-right">
                  <button className="btn" id="b" style={{ marginTop: "-15px" }}>
                    {t("apply").toUpperCase()}
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div
            className="caaar table-responsive"
            style={{ marginTop: "25px", width: "100%" }}
          >
            {this.state.isLoading === true ? (
              <Spinner />
            ) : (
              <table className="table tabell1  table-hover" id="headerr">
                <thead>
                  <tr style={{ marginTop: "-88px" }}>
                    <th class="active">
                      <input
                        type="checkbox"
                        class="select-all checkbox"
                        name="first_name"
                        id="selectAll1"
                        checked={this.state.checkedBoxCheck}
                        onClick={this.toggleSelectAll.bind(this)}
                      />
                    </th>
                    <th className="">{t("Id")}</th>
                    <th className="">{t("vin")}</th>
                    <th className="">{t("plate_number1")}</th>
                    <th className="">
                      <select
                        name="engine"
                        onChange={this.onChange.bind(this)}
                        className="minimal"
                        style={{
                          marginTop: "12px",
                          position: "relative",
                          top: "0px",
                          width: "150px",
                        }}
                      >
                        <option value="">
                          {" "}
                          {t("Engine_type")}: {t("AllCar")}{" "}
                        </option>
                        <option value="Pb">{t("Engine_type")}: Pb</option>
                        <option value="ON"> {t("Engine_type")}: ON </option>
                        <option value="HEV">{t("Engine_type")}: HEV </option>
                      </select>
                    </th>
                    <th className="">{t("Brand")}</th>
                    <th className="">{t("Model")}</th>
                    <th className="">{t("production_year")}</th>
                    <th className="t">{t("technical_inspection")}</th>
                    <th className="t">{t("engine_capacity1")}</th>

                    <th className="t">{t("monthly_hors_power")}</th>
                    <th>{t("location")}</th>
                    <th className="t">
                      <select
                        name="staus"
                        onChange={this.onChange.bind(this)}
                        className="minimal"
                        style={{ width: "130px", top: "0px" }}
                      >
                        <option value=" "> {t("status")}</option>
                        <option value="true">{t("active")} </option>
                        <option value="false">{t("disable")}</option>
                      </select>
                    </th>
                  </tr>
                </thead>

                <Search load={this.onChange.bind(this)} vall={this.state} />

                <tbody>
                  {onlyClassic.map((c, i) => (
                    <tr key={c.id}>
                      <td>
                        <input
                          type="checkbox"
                          // checked={this.state.selectedItems.includes(c.id)}
                          checked={this.state.selectedItems.includes(c.id)}
                          className="checkbox"
                          name="selectOptions"
                          onClick={() =>
                            this.onItemSelect(c.id, c.plate_number)
                          }
                        />
                      </td>
                      <td>{i + 1}</td>
                      <td>
                        <p>{c.vin ? `${c.vin}` : "-"}</p>
                      </td>
                      <td>
                        <a
                          href={baseUrl + "/overview/carmetrics/" + c.id}
                          style={{ color: "#b71c1c" }}
                          className="font-weight-bold"
                        >
                          {c.plate_number ? `${c.plate_number}` : "-"}
                        </a>
                      </td>
                      <td>{c.type ? `${c.type}` : "-"}</td>
                      <td>{c.brand ? `${c.brand}` : "-"}</td>
                      <td>{c.model ? `${c.model}` : "-"}</td>
                      <td>
                        {c.production_year ? `${c.production_year}` : "-"}
                      </td>
                      <td>
                        {c.next_registration_exam
                          ? `${c.next_registration_exam}`
                          : "-"}
                      </td>
                      <td>
                        {c.engine_capacity
                          ? `${c.engine_capacity.toFixed(1)}`
                          : "-"}
                      </td>
                      <td>
                        {c.engine_power ? `${c.engine_power.toFixed(1)}` : "-"}
                      </td>
                      <td>{c.location ? `${c.location}` : "-"}</td>
                      <td>
                        <button
                          className={
                            c.status === "active"
                              ? "btn btn-success"
                              : "btn btn-danger"
                          }
                          id="dd"
                        >
                          {c.status}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(withRouter(Choose));
