import React, { Component } from "react";
import edit from "../../img/edit.png";
import delet from "../../img/delete.png";
import { Helmet } from "react-helmet";
import arrow_left from "../../img/Aarrow.png";
import { withTranslation } from "react-i18next";
import { getCostDetail, deleteCost } from "../../Service/Cost";

class CostDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: [],
    };
  }

  componentDidMount() {
    getCostDetail(this.props.match.params.pk).then((data) => {
      this.setState({ stats: data });
    });
  }

  onDeleteClick() {
    deleteCost(this.props.match.params.pk).then((data) => {
      this.setState({ data });
    });
  }

  render() {
    const { stats } = this.state;
    const base = process.env.PUBLIC_URL;
    const { t } = this.props;
    if (stats === null) return <p>Loading ....</p>;
    return (
      <div className="container" style={{ marginTop: "10px" }}>
        <Helmet>
          <title>Cost Details</title>
        </Helmet>
        {/* <UpdateInvoice id={stats.id} /> */}
        <div className="row">
          <div className="" style={{ marginTop: "20px" }}>
            <a href={base + "/cost/"}>
              <img
                src={arrow_left}
                alt="Left_Arrow"
                style={{ height: "2.6em" }}
              />
            </a>
            &nbsp;{" "}
            <strong>
              {stats.invoice_number ? `${stats.invoice_number}` : "-"}
            </strong>{" "}
            &nbsp;
          </div>
          <div className="">
            <nav className="navbar navbar-expand-lg navbar-dark fixed">
              <ul className="navbar-nav mx-auto">
                <li className="nav-item btnn ">
                  <a href={base + `/edit/cost/${stats.id}/`}>
                    {" "}
                    <img
                      src={edit}
                      alt="contact"
                      style={{ marginBottom: "-5px", height: "1.5em" }}
                    />
                  </a>
                  <br></br>
                  <span
                    style={{
                      color: "rgb(183, 28, 28)",
                      fontSize: "11px",
                      position: "relative",
                    }}
                  >
                    {t("edit")}
                  </span>
                </li>
                &nbsp;&nbsp;
                <li className="nav-item btnn ">
                  <a href="# " onClick={(e) => this.onDeleteClick(e, stats.id)}>
                    &nbsp;{" "}
                    <img
                      src={delet}
                      alt="contact"
                      style={{ marginBottom: "-5px", height: "1.5em" }}
                    />
                  </a>
                  <br></br>
                  <span
                    style={{
                      color: "rgb(183, 28, 28)",
                      fontSize: "11px",
                      position: "relative",
                    }}
                  >
                    {t("delete")}
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="headerr" style={{ marginTop: "5px" }}>
          <p className="mod" style={{ marginTop: "0px" }}>
            {t("invoice_data").toUpperCase()}
          </p>
          <hr style={{ marginTop: "-5px" }}></hr>

          <div className="row" style={{ marginTop: "30px" }}>
            <div className="col-sm-2">
              <p id="ppp"> {t("date").toUpperCase()}</p>
              <p className="pp">{stats.date ? `${stats.date}` : "-"}</p>
            </div>

            <div className="col-sm-2">
              <p id="ppp"> {t("plate_number").toUpperCase()}</p>
              <p className="pp">
                {stats.car_plate_number ? `${stats.car_plate_number}` : "-"}
              </p>
            </div>

            <div className="col-sm-2">
              <p id="ppp"> {t("vin").toUpperCase()}</p>
              <p className="pp">{stats.car_vin ? `${stats.car_vin}` : "-"}</p>
            </div>

            <div className="col-sm-2">
              <p id="ppp"> {t("invoice_number").toUpperCase()}</p>
              <p className="pp">
                {stats.invoice_number ? `${stats.invoice_number}` : "-"}
              </p>
            </div>
          </div>

          <div className="row" style={{ marginTop: "30px" }}>
            <div className="col-sm-2">
              <p id="ppp">{t("invoice_type").toUpperCase()}</p>
              <p className="pp">
                {stats.cost_type ? `${stats.cost_type}` : "-"}
              </p>
            </div>

            <div className="col-sm-2">
              <p id="ppp">{t("Milage1").toUpperCase()}</p>
              <p className="pp">
                {stats.car_mileage ? `${stats.car_mileage}` : "-"} km
              </p>
            </div>

            <div className="col-sm-2">
              <p id="ppp">{t("price_brutto").toUpperCase()}</p>
              <p className="pp">{stats.value ? `${stats.value}` : "-"} PLN</p>
            </div>

            <div className="col-sm-2">
              <p id="ppp">{t("price_netto").toUpperCase()}</p>
              <p className="pp">
                {stats.net_value ? `${stats.net_value}` : "-"} PLN
              </p>
            </div>
          </div>

          <div className="row" style={{ marginTop: "40px" }}>
            <div className="col-sm-6">
              <p className="h6 font-weight-bolder">
                {t("description").toUpperCase()}
              </p>
              <p>{stats.description ? `${stats.description}` : "-"}</p>
            </div>
          </div>

          {/* <center>
            <a
              href="/cost"
              className="btn  br text-white "
              style={{ background: "#b71c1c" }}
            >
              Close
            </a>
          </center> */}
        </div>
      </div>
    );
  }
}

export default withTranslation()(CostDetail);
