import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Fleet from "./Additional/Fleet";
import Available from "./Additional/Table/Available";
import moment from "moment";
import OtherCar from "./Additional/Table/OtherCar";
import { Helmet } from "react-helmet";
import config from "../../Main";
import {
  getRecommend,
  getBrand,
  getModel,
} from "../../../Service/Recommendation";
import User from "../../../Components/User";
import Datee from "../../Views/Date";
import Recommendation from "../../../Components/Layout/Recommendation";
import { withRouter } from "react-router";
import CarsTable from "./CarsTable";

class Recommend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true,
      isHide: true,
      maintaine: "",
      ev: [], // This is for filter ev recommend
      ev2: [], ////////////////
      allOptionsSelected: false, ////////////////
      car: [], // This id for get all cars and filter by car id
      car2: [], // duplicate of cars, that changes based on filtering
      filtered: false, /////////////////
      modell: [], // This is for filter model by car
      modell2: [], // similar to model, but always contains all the initial values
      summary: [], // This is for summary
      selectOptions: [],
      plate: "", // this indicate car_plate_number filter name
      engine: "", // This is for type of car
      brand: "", // This is for brand of car
      modal: "", // This is for model of car
      price_from: "", // This is for price from
      price_to: "", // This is for price_to
      saving: "", // This is for saving car money
      co2: "", // This is for co2 saving
      customRadioInline2: "", // This is for select all car
      don: [],
      isLoading: true,
      selectedPlates: this.getSafe(
        () => this.props.history.location.state.selectedPlates
      ),
      selectedIds: this.getSafe(
        () => this.props.history.location.state.selectedItems
      ),
    };

    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleGet = this.handleGet.bind(this);
  }

  toggleHiddden() {
    this.setState({
      isHide: false,
    });
  }

  toggleHide() {
    this.setState({
      isHide: true,
    });
  }

  async handleGet(e) {
    this.setState({ isLoading: true });
    // const selections = [...e.target.elements.selectOptions.options].filter(
    //   (opt) => opt.selected
    // );
    const selections = this.state.selectOptions;
    // const selectedValues = selections.map((opt) => opt.value);
    // const selectedString = selectedValues.join(",");
    const selectedString = selections.join(",");
    e.preventDefault();
    getRecommend(
      this.state.saving,
      this.state.engine,
      this.state.brand,
      this.state.modal,
      this.state.price_from,
      this.state.price_to,
      // this.state.customRadioInline2
      //   ? this.state.customRadioInline2
      //   : selectedString
      selectedString
    ).then((data) => {
      this.setState({
        ev: data,
        isLoading: false,
      });
    });

    Promise.all([
      getBrand(this.state.brand).then((data) => {
        this.setState({
          modell: data,
        });
      }),
      getBrand("").then((data) => {
        this.setState({
          modell2: data,
        });
      }),
      await fetch(
        `${config.apiUrl.ecrecomend}?yearly_savings__gte=${
          this.state.saving
        }&type=${this.state.engine}&make=${this.state.brand}&model=${
          this.state.modal === "Leaf e+" ? "Leaf+e%2B" : `${this.state.modal}`
        }&price_pln__gte=${this.state.price_from}&price_pln__lte=${
          this.state.price_to
        }&car_id__in=${selectedString}`,
        config.head
      )
        .then((ev) => ev.json())
        .then((ev) => {
          this.setState({
            ev,
            isLoading: false,
          });
        }),
      await fetch(
        `${config.apiUrl.evsummary}?yearly_savings__gte=${
          this.state.saving
        }&type=${this.state.engine}&make=${this.state.brand}&model=${
          this.state.modal === "Leaf e+" ? "Leaf+e%2B" : `${this.state.modal}`
        }&price_pln__gte=${this.state.price_from}&price_pln__lte=${
          this.state.price_to
        }&car_id__in=${selectedString}`,
        config.head
      )
        .then((summary) => summary.json())
        .then((summary) => {
          this.setState({
            summary,
            isLoading: false,
          });
        }),
    ]);
  }

  async onSubmitt1(e) {
    Promise.all([
      getBrand(this.state.brand).then((data) => {
        this.setState({
          modell: data,
        });
      }),
      getModel(this.state.plate).then((data) => {
        this.setState({
          car: data,
          car2: data, /////////////////
        });
      }),
    ]);
  }

  componentDidMount() {
    //////////////// we want the initial diagram to contain all cars. So make a request to get all cars
    const selections = this.state.car;
    const selectedString = selections.join(",");

    getRecommend(
      this.state.saving,
      this.state.engine,
      this.state.brand,
      this.state.modal,
      this.state.price_from,
      this.state.price_to,
      this.state.customRadioInline2
        ? this.state.customRadioInline2
        : selectedString
    ).then((data) => {
      this.setState({
        ev: data,
        ev2: data, ////////////
        isLoading: false,
      });
    });

    getBrand("").then((data) => {
      this.setState({
        modell2: data,
      });
    });

    this.onSubmitt1("");
  }

  async onChange1(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
  }

  async onChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });

    this.onSubmitt1("");
  }

  async handleChange(e) {
    let target = e.target;
    let name = target.name;
    let value = Array.from(target.selectedOptions, (option) => option.value);
    await this.setState({
      [name]: value,
    });
  }

  getSafe(fn, defaultVal) {
    try {
      return fn();
    } catch (e) {
      return defaultVal;
    }
  }
  ///////////////////////

  createArray = (itemType) => {
    let array = this.state.car.map((car) => {
      return car[itemType];
    });
    array = [...new Set(array)];
    return [array.map((item) => <option>{item}</option>)];
  };

  // used inside CarsTable component in order to change the selected cars
  setSelectedCars = async (selectOptions, allOptionsSelected) => {
    await this.setState({
      selectOptions: selectOptions,
      allOptionsSelected: allOptionsSelected,
    });
  };

  // used inside CarsTable component in order to change the selected cars after filtering
  setFilteredCars = (filteredCars, filtered) => {
    this.setState({ car2: filteredCars, filtered: filtered });
  };

  render() {
    const { t } = this.props;

    const notrecom = this.state.ev.filter((c) => c.recommended.length > 0);
    const recom1 = this.state.ev.filter((c) => c.recommended.length === 0);
    const baseUrl = process.env.PUBLIC_URL;

    // create engines, brands, models arrays in order to display them in the dropdown inputs
    let engines = [
      <option key="BEV" value="electric">
        {"BEV"}
      </option>,
    ];

    let brands = this.state.modell2.map((obj) => {
      return obj.make;
    });
    brands = [...new Set(brands)];

    let models = this.state.modell.map((obj) => {
      return obj.model;
    });
    models = [...new Set(models)];

    const allCarsBtn = (
      <div className="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          placeholder="A Short Description"
          name="customRadioInline2"
          onClick={this.toggleHide.bind(this)}
          //onChange={this.onChange1.bind(this)}
          onChange={() => this.setState({ selectOptions: [] })}
          id="r4"
          value=" "
          defaultChecked
        />
        <label htmlFor="r4" className="custom-control-label">
          {t("All.cars")}
        </label>
      </div>
    );

    const individualCarsBtn = (
      <div className="custom-control custom-radio custom-control-inline ">
        <input
          type="radio"
          placeholder="A Short Description"
          name="customRadioInline2"
          value="year"
          onChange={() => {
            this.toggleHiddden();
            this.setState({ selectOptions: [] });
          }}
          id="r3"
        />
        <label htmlFor="r3" className="custom-control-label">
          {t("Car.from.metrics")}
        </label>
      </div>
    );

    return (
      <div className="Main_section">
        <Helmet>
          <title> Electric Car </title>
        </Helmet>

        <div
          className="row row1"
          style={{ width: "100%", marginBottom: "-30px" }}
        >
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
        </div>

        <div style={{ marginTop: "-15px" }}>
          <Recommendation />
        </div>

        <div className="headerr">
          <div className=" " style={{ width: "100%", marginTop: "20px" }}>
            <p className="pp" style={{ fontSize: "14px" }}>
              {this.state.ev.length > 0
                ? `${t("Recommendation.Result.Updated")}  ${notrecom
                    .slice(0, 1)
                    .map((c) => moment(c.updated_at).format("YYYY-MM-DD"))}`
                : t("Module")}
            </p>
          </div>

          <Fleet
            load={this.state.summary.can_replace_electric}
            yearly={this.state.summary.yearly_savings}
            state={this.state}
            loadd={this.state.summary}
            recom={this.state.ev}
            notrecom={notrecom}
          />

          <form onSubmit={this.handleGet.bind(this)}>
            <div className="row" style={{ width: "100%" }}>
              <div class="col-md-4">
                <h3 className="font-weight-bold" style={{ marginTop: "30px" }}>
                  {t("Step.1.Choose.cars")}
                </h3>
                <p style={{ marginTop: "10px" }}>
                  {t(
                    "Please.select.the.cars.from.your.fleet.you.want.to.receive.recommendations.for"
                  )}
                </p>
                <hr></hr>

                <div class="row">
                  <div class="col-md-6" style={{ textAlign: "center" }}>
                    {allCarsBtn}
                  </div>
                  <div class="col-md-6" style={{ textAlign: "center" }}>
                    {individualCarsBtn}
                  </div>
                </div>
              </div>
              <div class="col-md-8">
                <h3 className="font-weight-bold" style={{ marginTop: "30px" }}>
                  {t("Step.2.Apply.filters")}
                </h3>
                <p style={{ marginTop: "10px" }}>
                  {t(
                    "Please.select.the.filters.you.want.to.be.applied.on.the.recommended.cars"
                  )}
                </p>
                <hr></hr>
                <div class="row">
                  <div class="col-md-2">
                    <p
                      id="ppp"
                      style={{ textTransform: "uppercase", color: "gray" }}
                    >
                      {t("Engine_type")}
                    </p>

                    <select
                      className="form-control donn"
                      style={{ height: "40px" }}
                      name="engine"
                      // disabled={this.state.selectOptions.length < 1}
                      onChange={this.onChange}
                    >
                      <option value="" selected>
                        {t("Engine_type")}
                      </option>

                      {engines}
                    </select>

                    <br />
                  </div>

                  <div class="col-md-2">
                    <p
                      id="ppp"
                      style={{ textTransform: "uppercase", color: "gray" }}
                    >
                      {t("Brand")}
                    </p>

                    <select
                      className="form-control donn"
                      style={{ height: "40px" }}
                      name="brand"
                      onChange={this.onChange}
                      disabled={!this.state.engine}
                    >
                      <option key="defaultBrand" value="" selected>
                        {t("ChooseCar")}
                      </option>
                      {[
                        brands.map((brand) => {
                          return <option key={brand}>{brand}</option>;
                        }),
                      ]}
                    </select>
                  </div>

                  <div class="col-md-2">
                    <p
                      id="ppp"
                      style={{ textTransform: "uppercase", color: "gray" }}
                    >
                      {t("Model")}
                    </p>
                    <select
                      className="form-control donn"
                      style={{ height: "40px" }}
                      name="modal"
                      onChange={this.onChange}
                      disabled={!this.state.brand || !this.state.engine}
                    >
                      <option key="defaultModel" value="" selected>
                        Choose Model
                      </option>
                      {[
                        models.map((model) => {
                          return <option key={model}>{model}</option>;
                        }),
                      ]}
                    </select>
                  </div>

                  <div class="col-md-3">
                    <div class="row">
                      <div class="col-md-12" style={{ textAlign: "center" }}>
                        <p
                          id="ppp"
                          style={{ textTransform: "uppercase", color: "gray" }}
                        >
                          {t("PURCHASE.COST")}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div className="col-md-6">
                        <input
                          onChange={(e) => {
                            this.setState({ price_from: e.target.value });
                          }}
                          type="text"
                          placeholder={t("from")}
                          name="name"
                          class="form-control"
                          style={{ height: "40px" }}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          onChange={(e) => {
                            this.setState({ price_to: e.target.value });
                          }}
                          type="text"
                          placeholder={t("to")}
                          name="name"
                          class="form-control"
                          style={{ height: "40px" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="row">
                      <div class="col-md-12" style={{ textAlign: "center" }}>
                        <p
                          id="ppp"
                          style={{ textTransform: "uppercase", color: "gray" }}
                        >
                          {t("CO2.EMISSIONS")}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div className="col-md-6">
                        <input
                          type="text"
                          placeholder={t("from")}
                          name="name"
                          class="form-control"
                          style={{ height: "40px" }}
                          disabled
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          placeholder={t("to")}
                          name="name"
                          class="form-control"
                          style={{ height: "40px" }}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="row">
                      <div class="col-md-12" style={{ textAlign: "center" }}>
                        <p
                          id="ppp"
                          style={{ textTransform: "uppercase", color: "gray" }}
                        >
                          {t("EXPECTED.MAINTENANCE.COST")}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div className="col-md-6">
                        <input
                          type="text"
                          placeholder="from"
                          name="saving"
                          class="form-control"
                          style={{ height: "40px" }}
                          disabled
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          placeholder="to"
                          name="name"
                          class="form-control"
                          style={{ height: "40px" }}
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="row">
                      <div class="col-md-12" style={{ textAlign: "center" }}>
                        <p
                          id="ppp"
                          style={{ textTransform: "uppercase", color: "gray" }}
                        >
                          {t("EXPECTED.FINANCIAL.BENEFITS")}
                        </p>
                      </div>
                    </div>
                    <div class="row">
                      <div className="col-md-12">
                        <input
                          type="text"
                          placeholder="from"
                          name="name"
                          onChange={(e) => {
                            this.setState({ saving: e.target.value });
                          }}
                          class="form-control"
                          style={{ height: "40px", textAlign: "center" }}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                {!this.state.isHide && (
                  <CarsTable
                    state={this.state}
                    setSelectedCars={this.setSelectedCars}
                    setFilteredCars={this.setFilteredCars}
                  />
                )}
              </div>
            </div>
            <center style={{ marginTop: "0" }}>
              <button
                className="btn btn-danger br text-white"
                value="create campaign"
                //disabled={this.state.isLoading === true}
                style={{ background: "#b71c1c" }}
              >
                {t("apply_criteria").toUpperCase()}
              </button>
            </center>
          </form>

          <div className="tablle" style={{ marginTop: "100px" }}>
            {/* {this.state.ev.length > 0 && ( */}
            <div className="row">
              <div className="col-md-6">
                <Available
                  load={notrecom}
                  state={this.state}
                  donn={this.state.ev}
                  mode={this.state.brand}
                  loading={this.state.isLoading}
                  mode1={this.state.modal}
                />
              </div>

              <div className="col-md-6">
                <OtherCar load={recom1} loading={this.state.isLoading} />
              </div>

              {/* <div className="col-sm-4">
                  <Conditionally load={ev} />
                </div>*/}
            </div>
            {/* )} */}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Recommend));
