export { default as Ddetails } from "../MainDashboard/MyDriver/DriverList/Ddetails";
export { default as DriverMetrics } from "../MainDashboard/MyDriver/Metric/DriverMetrics";

export { default as AddDriver } from "../Settings/Driver/AddDriver";
export { default as EditDriver } from "../Settings/Driver/EditDriver";
export { default as DriverReportt } from "../Report/Driver/DriverReport";

export { default as CritialOverspeed } from "../Driver/CriticalOverspee/CriticalOverspeed";
export { default as FilterDriver } from "../Driver/Filter/FilterDriver";
export { default as FuelFilter } from "../Driver/Filter/Fuelfilter";
export { default as FilterDynamic } from "../Driver/Filter/DynamicFilter";
export { default as FilterSpeed } from "../Driver/Filter/SpeedFilter";
export { default as CDdetails } from "../Driver/CriticalOverspee/Criticaldetails";

export { default as MyDriver } from "../MainDashboard/MyDriver/DriverList/MyDriver";
export { default as MyProfile } from "../MainDashboard/MyDriver/Profile/MyProfile";

export { default as CoDriverCar } from "../Driver/CriticalOverspee/CoDriverCar";
export { default as DriverList } from "../Campaign/Report/DriverList";
