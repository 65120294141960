import React, { Component } from "react";
import cross from "../../../../../img/Cross.png";

export class ViewImage extends Component {
  render() {
    return (
      <div
        id={"chart_score1" + this.props.id}
        class="modal campaign_chart_marker"
      >
        <a
          href="# "
          rel="modal:close"
          className="float-right text-dark "
          style={{ marginBottom: "0px" }}
        >
          <img
            src={cross}
            alt="Cross"
            style={{ height: "1.1em", marginTop: "30px" }}
          />
        </a>

        <img src={this.props.file} alt="FIle ame" className="image_view" />
      </div>
    );
  }
}

export default ViewImage;
