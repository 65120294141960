// import React from "react";
// import { useTranslation } from "react-i18next";

const contextual = {
  // time_of_day: ["-", "7-10", "10-15", "15-19", "19-7"],
  temprature: ["-", "-∞;-10", "-10;0", "0;10", "10;+∞"],
  weather: [
    ("-", "-"),
    ("sun", "weather_sunny"),
    ("cloudy", "weather_cloudy"),
    ("rainy", "weather_rainy"),
    ("snowy", "weather_snowy"),
  ],
  road_type: [
    ("-", "-"),
    ("highway", "highway"),
    ("urban", "urban"),
    ("rural", "rural"),
  ],
  driving_days: [("-", "-"), ("weekend", "Weekend"), ("weekdays", "Weekdays")],
  road_familirity: [
    ("-", "-"),
    ("known_road", "known_roads"),
    ("unknown_road", "unknown_roads"),
  ],
  none: ["-"],
};

export default contextual;
