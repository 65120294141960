import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#1B1B23",
      opacity: "0.6",
      fontSize: "12px",
      letterSpacing: "0.2px",
    },

    "& label": {
      color: "rgb(27, 27, 35,0.5)",
      marginLeft: "5px",
      borderBottomColor: "rgb(27, 27, 35,0.2)",
      fontSize: "12px",
      marginTop: "5px",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#B71C1C",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#1B1B23",
      opacity: "0.6",
    },
  },
})(TextField);

export default CssTextField;
