import React, { Component } from "react";
import Chart from "react-apexcharts";
import { ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { getOverSpeeding } from "../../../../Service/Dashboard";
import Spinner from "../../../../Container/Spinner";

const colors = ["#13B760", "#FFC105", "#F57C01", "#B71C1C"];
var d = new Date();
var mm = d.getMonth() + 1;
var dd = d.getDate();
var yy = d.getFullYear();

var cc = yy + "-" + mm + "-" + dd;
const base = process.env.PUBLIC_URL;
class Speed extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;

    this.state = {
      ...props,
      options: {
        chart: {
          id: "Speeding",
          events: {
            dataPointSelection: function (event, chartContext, opts) {
              switch (opts.w.config.xaxis.categories[opts.dataPointIndex]) {
                case "0-10%":
                  window.open(
                    window.location.origin +
                      base +
                      `/overspeeding/recommendation/driver/10/0/${
                        this.props.too ? `${this.props.too}` : `${cc}`
                      }/${
                        this.props.fromm ? `${this.props.fromm}` : "2016-01-04"
                      }`
                  );
                  break;
                case "10-30%":
                  window.open(
                    window.location.origin +
                      base +
                      `/overspeeding/recommendation/driver/30/10/${
                        this.props.too ? `${this.props.too}` : `${cc}`
                      }/${
                        this.props.fromm ? `${this.props.fromm}` : "2016-01-04"
                      }`
                  );
                  break;
                case "30-50%":
                  window.open(
                    window.location.origin +
                      base +
                      `/overspeeding/recommendation/driver/50/30/${
                        this.props.too ? `${this.props.too}` : `${cc}`
                      }/${
                        this.props.fromm ? `${this.props.fromm}` : "2016-01-04"
                      }`
                  );
                  break;
                case "50%+":
                  window.open(
                    window.location.origin +
                      base +
                      `/overspeeding/recommendation/driver/100/50/${
                        this.props.too ? `${this.props.too}` : `${cc}`
                      }/${
                        this.props.fromm ? `${this.props.fromm}` : "2016-01-04"
                      }`
                  );
                  break;
                default:
                  window.open(window.location.origin + base + "/car");
              }
            }.bind(this),
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "category",
          title: {
            text: t("x-axis-speed"),
            style: {
              fontSize: "10px",
              color: "#8D8D90",
            },
          },
          labels: {
            style: {
              fontSize: "12px",
              color: "#8D8D90",
            },
          },
          categories: [],
        },

        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            title: {
              text: t("y-axios-speed"),
              style: {
                fontSize: "10px",
                color: "#8D8D90",
              },
            },
            labels: {
              formatter: function (value) {
                return value + " % ";
              },
              style: {
                fontSize: "10px",
                opacity: "0.2",
                color: "#8D8D90",
              },
            },
            axisBorder: {
              show: true,
            },
          },
        ],

        colors: colors,
        plotOptions: {
          bar: {
            columnWidth: "70%",
            distributed: true,
          },
        },

        title: {
          align: "center",
          style: {
            fontSize: "20px",
            color: "black",
          },
        },
        labels: [],
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: "Temprature",
          data: [],
        },
      ],
      isLoading: true,
    };
  }

  fetchData() {
    getOverSpeeding(this.props.too, this.props.fromm).then((data) => {
      const newXaxis = data.labels;
      const newSeries = [];
      newSeries.push({
        data: data.data,
        name: "OverSpeeding",
      });
      this.setState({
        series: newSeries,
        isLoading: false,
        options: {
          ...this.state.options,
          labels: newXaxis,
          xaxis: { ...this.state.options.xaxis, categories: newXaxis },
        },
      });
    });
  }
  componentDidMount() {
    this.fetchData();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.series !== this.state.series) {
      this.fetchData();
    }
  }

  entering = (e) => {
    e.children[0].style.borderTopColor = "white";
    e.children[1].style.color = "black";
    e.children[1].style.border = "1px solid black";
    e.children[1].style.backgroundColor = "white";
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="row" style={{ marginBottom: "47px" }}>
          <div className="col-md-12">
            <h3 className="text-left font-weight-bolder">
              {" "}
              <ButtonToolbar className="">
                <OverlayTrigger
                  key="top"
                  onEntering={this.entering}
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-topop">{t("second_graph")}</Tooltip>
                  }
                >
                  <span>
                    {t("Speed")}
                    <i className="fa fa-info-circle "></i>
                  </span>
                </OverlayTrigger>
              </ButtonToolbar>
            </h3>
          </div>
        </div>

        <div className="row hidden" style={{ marginTop: "5px" }}>
          <div className="col-sm-4"> {t("choose_period")} &nbsp; </div>

          <div
            className="col-sm-4"
            style={{ marginTop: "-10px", marginLeft: "-9%" }}
          >
            <select className="form-control donn" name="engine">
              <option selected disabled hidden>
                {t("year")}-{t("month")}
              </option>
              <option>2020-02</option>
              <option>2020-01</option>
              <option>2019-12</option>
            </select>
          </div>
        </div>

        <div className="boxxx" style={{ marginTop: "17px" }}>
          {this.state.isLoading === true ? (
            <Spinner />
          ) : (
            <Chart
              style={{ marginTop: "20px" }}
              options={this.state.options}
              series={this.state.series}
              type="bar"
              height="250"
            />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Speed);
