import React from "react";
import { Helmet } from "react-helmet";
import User from "../../../../Components/User";
import Datee from "../../../Views/Date";
import Recommendation from "../../../../Components/Layout/Recommendation";
import { useTranslation } from "react-i18next";
import WorkingR from "../../../Views/Progress/WorkingR";

function CostR() {
  const { t } = useTranslation();
  return (
    <div className="Main_section">
      <Helmet>
        <title> Cost Recommendation</title>
      </Helmet>

      <div
        className="row row1"
        style={{ width: "100%", marginBottom: "-30px" }}
      >
        <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
          <strong>
            {t("Hello")}, <User />
          </strong>
          <Datee />
        </div>
      </div>

      <div style={{ marginTop: "-15px" }}>
        <Recommendation />
      </div>

      <div className="headerr">
        <WorkingR />
      </div>
    </div>
  );
}

export default CostR;
