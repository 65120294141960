import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Filter from "../../Filter";
import Search from "../../../Cars/Search";
import { withTranslation } from "react-i18next";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import User from "../../../../Components/User";
import Datee from "../../../Views/Date";
import print from "../../../../img/Rating/print.png";
import print_pl from "../../../../img/Rating/print_pl.png";
import DateGte from "../../../../Service/Arrow/DateGte";
import DateLte from "../../../../Service/Arrow/DateL";
import Insurance from "../../../../Components/Layout/Insurance";
import getPrint from "../../../../Service/Arrow/Print";
import Spinner from "../../../../Container/Spinner";
import { getMyCar } from "../../../../Service/Car";
import { getMyDriver } from "../../../../Service/Driver";
import compareByAsc1 from "../../../../Service/Arrow/Sort/Asc1";
import compareByDesc1 from "../../../../Service/Arrow/Sort/Desc1";
import arrowL from "../../../../Service/Arrow/Arrow";
import arrow from "../../../../img/arrow.png";
const moment = extendMoment(originalMoment);

class Th extends React.Component {
  handleClick = () => {
    const { onClick, id } = this.props;
    onClick(id);
  };
  render() {
    const { value } = this.props;
    return (
      <th onClick={this.handleClick} className="t">
        {value} <img src={arrow} alt="Arrow" style={{ height: "0.9em" }} />
      </th>
    );
  }
}
export class Risk extends Component {
  constructor(props) {
    super(props);
    const today = moment();
    this.state = {
      stats: [],
      isLoading: true,
      lol: null,
      fol: null,
      isOpen: false,
      today: null,
      driver: [],
      neww: [],
      switcher: "driver",
      value: moment.range(today.clone().subtract(0, "days"), today.clone()),
    };
  }

  onToggle = (event) => {
    if (event.target.value === "date") {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  getData = async (value, states) => {
    this.setState({ isLoading: true });
    getMyCar(
      this.state.engine,
      value.end.format("YYYY-MM-DD"),
      value.start.format("YYYY-MM-DD")
    ).then((data) => {
      this.setState({
        stats: data,
        lol: value.start,
        fol: value.end,
        isOpen: !this.state.isOpen,
        isLoading: false,
      });
    });
  };

  getData1 = async (value, states) => {
    this.setState({ isLoading: true });
    getMyDriver(
      value.end.format("YYYY-MM-DD"),
      value.start.format("YYYY-MM-DD")
    ).then((data) => {
      this.setState({
        driver: data,
        isLoading: false,
        lol: value.start,
        fol: value.end,
        isOpen: !this.state.isOpen,
      });
    });
  };

  getOptionData = async () => {
    getMyCar(
      this.state.engine,
      DateGte(this.state.today)
        ? DateGte(this.state.today)
        : this.props.match.params.date__lte,
      DateLte(this.state.today)
        ? DateLte(this.state.today)
        : this.props.match.params.date__gte
    ).then((data) => {
      data.sort((a, b) => b.span.mileage.value - a.span.mileage.value);
      this.setState({ stats: data, isLoading: false });
    });
  };

  getDriverOption = async () => {
    getMyDriver(
      DateGte(this.state.today)
        ? DateGte(this.state.today)
        : this.props.match.params.date__lte,
      DateLte(this.state.today)
        ? DateLte(this.state.today)
        : this.props.match.params.date__gte
    ).then((data) => {
      data.sort((a, b) => b.span.mileage.value - a.span.mileage.value);
      this.setState({ driver: data, isLoading: false });
    });
  };
  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.getOptionData("");
    this.getDriverOption("");
  };

  componentDidMount() {
    this.getOptionData("");
    this.getDriverOption("");
  }

  sortBy(key) {
    let arrayCopy = [...this.state.stats];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(compareByAsc1(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(compareByDesc1(key));
    }
    this.setState({ stats: arrayCopy });
  }

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  render() {
    const { stats, driver } = this.state;
    const { t } = this.props;
    const base = process.env.PUBLIC_URL;
    const nonElectric = stats.filter((c) => c.type !== "BEV");
    const Electric = stats.filter((c) => c.type === "BEV");
    const switcher1 =
      this.state.switcher === "classic"
        ? nonElectric
        : this.state.switcher === "electric"
        ? Electric
        : this.state.switcher === "driver"
        ? driver
        : driver;

    // const overZero = switcher1.filter(
    //   (c) =>
    //     c.span.aggresive_turns.value < 0 ||
    //     c.span.aggresive_braking.value < 0 ||
    //     c.span.aggresive_acceleration.value < 0
    // );
    const switcher = window.location.pathname.includes("/overall/risk/")
      ? switcher1
      : switcher1;

    const mileage = switcher.reduce((accumulator, pilot) => {
      var output = accumulator + pilot.span.mileage.value;
      return output;
    }, 0);

    const aggresive_turns = switcher.reduce((accumulator, pilot) => {
      var output = accumulator + pilot.span.aggresive_turns.value;
      return output;
    }, 0);
    const aggresive_braking = switcher.reduce((accumulator, pilot) => {
      var output = accumulator + pilot.span.aggresive_braking.value;
      return output;
    }, 0);
    const aggresive_acceleration = switcher.reduce((accumulator, pilot) => {
      var output = accumulator + pilot.span.aggresive_acceleration.value;
      return output;
    }, 0);
    const overspeeding_events = switcher.reduce((accumulator, pilot) => {
      var output = accumulator + pilot.span.overspeeding_events.value;
      return output;
    }, 0);
    const overall_risk_event = switcher.reduce((accumulator, pilot) => {
      var output = accumulator + pilot.span.overall_risk_event.value;
      return output;
    }, 0);
    const critical_overspeed = switcher.reduce((accumulator, pilot) => {
      var output = accumulator + pilot.span.critical_overspeed.value;
      return output;
    }, 0);
    const claim_count = switcher.reduce((accumulator, pilot) => {
      var output = accumulator + pilot.claim_count;
      return output;
    }, 0);

    const average_aggresive_turns = ((aggresive_turns / mileage) * 100).toFixed(
      1
    );
    const average_aggresive_braking = (
      (aggresive_braking / mileage) *
      100
    ).toFixed(1);
    const average_aggresive_acceleration = (
      (aggresive_acceleration / mileage) *
      100
    ).toFixed(1);
    return (
      <div className="Main_section">
        <Helmet>
          <title>Risk Assessment</title>
        </Helmet>

        <div
          className="row row1"
          style={{
            width: "100%",
            marginTop: "-20px",
            marginBottom: "-40px",
          }}
        >
          {" "}
          <div className="col-sm-3 date_time">
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
          <div className="col-sm-6 date_time">
            <Filter
              lol={this.state.lol}
              fol={this.state.fol}
              today={this.state.today}
              option={this.state.isOpen}
              toggle={this.onToggle}
              change={
                this.state.switcher === "driver" ? this.getData1 : this.getData
              }
              hello={this.onChange}
            />
          </div>
        </div>

        <Insurance />

        <div className="headerr" style={{ marginTop: "-6px" }}>
          <div className="row">
            <div
              className="col-sm-7"
              style={{ position: "relative", top: "25px" }}
            >
              <div className="row">
                <div className="form-group col-sm-3">
                  <input
                    type="radio"
                    name="switcher"
                    id="switcherDriver"
                    onChange={this.onChange}
                    defaultChecked
                    class="form-check-input"
                    value="driver"
                  />
                  <label id="p" htmlFor="switcherDriver">
                    {t("my_driver")}
                  </label>
                </div>
                <div className=" col-sm-3 form-group">
                  <input
                    type="radio"
                    name="switcher"
                    id="switchClassic1"
                    onChange={this.onChange}
                    value="classic"
                  />
                  <label id="p" htmlFor="switchClassic1">
                    {t("classic_car")}
                  </label>
                </div>

                <div className="form-group col-sm-3">
                  <input
                    type="radio"
                    name="switcher"
                    id="switcherElectric1"
                    onChange={this.onChange}
                    class="form-check-input"
                    value="electric"
                  />
                  <label id="p" htmlFor="switcherElectric1">
                    {t("EV_cars")}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div>
            {stats.length > 0 ? (
              stats
                .slice(0, 1)
                .map((c) => <strong hidden>{c.participantCount}</strong>)
            ) : (
              <strong>&nbsp;&nbsp; {t("no_data")}</strong>
            )}
          </div>

          <div className="" style={{ width: "100%", marginTop: "-30px" }}>
            <span
              className="nav-item  float-right  "
              disabled
              style={{ display: "flex" }}
            >
              <Search /> &nbsp; &nbsp;
              <a href="# " onClick={getPrint}>
                <img
                  src={
                    window.localStorage.i18nextLng === "en" ? print : print_pl
                  }
                  alt="contact"
                  style={{ marginTop: "-4px", height: "3em" }}
                />
              </a>
            </span>
          </div>

          <div className="caaar table-responsive" style={{ width: "100%" }}>
            {this.state.isLoading === true ? (
              <Spinner />
            ) : (
              <table id="headerr" className="table  table-hover tabell12">
                <thead>
                  <tr style={{ marginTop: "-88px" }}>
                    <th className="t" style={{ width: "20px" }}>
                      {t("Id")}
                    </th>
                    {this.state.switcher === "driver" ? (
                      <th className="t">{t("Name")}</th>
                    ) : (
                      <th className="t">{t("plate_number1")}</th>
                    )}
                    <Th
                      onClick={() => this.sortBy("mileage")}
                      id="mileage"
                      value={t("distance")}
                    />
                    <Th
                      onClick={() => this.sortBy("aggresive_turns")}
                      id="aggresive_turns"
                      value={t("aggresive_turn")}
                    />

                    <Th
                      onClick={() => this.sortBy("aggresive_braking")}
                      id="aggresive_braking"
                      value={t("aggresive_breaking")}
                    />

                    <Th
                      onClick={() => this.sortBy("aggresive_acceleration")}
                      id="aggresive_acceleration"
                      value={t("aggresive_acceleration")}
                    />

                    <Th
                      onClick={() => this.sortBy("overspeeding_events")}
                      id="overspeeding_events"
                      value={t("overspeeding_events")}
                    />

                    <Th
                      onClick={() => this.sortBy("overall_risk_event")}
                      id="overall_risk_event"
                      value={t("overall_events")}
                    />

                    <Th
                      onClick={() => this.sortBy("aggresive_turns_per100km")}
                      id="aggresive_turns_per100km"
                      value={t("aggresive_turn_avg")}
                    />
                    <Th
                      onClick={() => this.sortBy("aggresive_braking_per100km")}
                      id="aggresive_braking_per100km"
                      value={t("aggresive_breaking_avg")}
                    />
                    <Th
                      onClick={() =>
                        this.sortBy("aggresive_acceleration_per100km")
                      }
                      id="aggresive_acceleration_per100km"
                      value={t("aggresive_acceleration_avg")}
                    />
                    <Th
                      onClick={() =>
                        this.sortBy("average_overspeeding_events_per100km")
                      }
                      id="average_overspeeding_events_per100km"
                      value={`${t("overspeeding_events")}/100km`}
                    />

                    <Th
                      onClick={() => this.sortBy("summary_per100km")}
                      id="summary_per100km"
                      value={t("summary_100")}
                    />

                    <Th
                      onClick={() => this.sortBy("overspeeding_percentage")}
                      id="overspeeding_percentage"
                      value={t("overspeeding_distance")}
                    />

                    <Th
                      onClick={() => this.sortBy("critical_overspeed")}
                      id="critical_overspeed"
                      value={t("critical_overspeding")}
                    />

                    {window.location.pathname.includes("/overall/risk/") ? (
                      ""
                    ) : (
                      <Th
                        onClick={() => this.sortBy("critical_overspeed")}
                        id="critical_overspeed"
                        value={t("claims_no")}
                      />
                    )}
                    {window.location.pathname.includes("/overall/risk/") ? (
                      ""
                    ) : (
                      <Th
                        // onClick={() => this.sortBy("risk_rate")}
                        id="risk_rate"
                        value={t("risk_rate")}
                      />
                    )}
                  </tr>
                </thead>

                <tbody>
                  {stats.length > 0 ? (
                    switcher.map((c, i) => (
                      <tr>
                        <td>{i + 1}</td>

                        {this.state.switcher === "driver" ? (
                          <td>
                            <a
                              href={
                                base + "/overview/app/drivermetrics/" + c.id
                              }
                              style={{ color: "#b71c1c" }}
                              className="font-weight-bold"
                            >
                              {c.name ? `${c.name}` : "-"}
                            </a>
                          </td>
                        ) : (
                          <td>
                            <a
                              href={base + "/overview/app/carmetrics/" + c.id}
                              style={{ color: "#b71c1c" }}
                              className="font-weight-bold"
                            >
                              {c.plate_number ? `${c.plate_number}` : "-"}
                            </a>
                          </td>
                        )}

                        <td>
                          {c.span.mileage.value
                            ? `${c.span.mileage.value.toFixed(1)}`
                            : "-"}
                          {arrowL(
                            c.span.mileage.changeDirection,
                            c.span.mileage.changePositive,
                            c.span.mileage.change
                          )}
                        </td>
                        <td>
                          {c.span.aggresive_turns.value
                            ? `${c.span.aggresive_turns.value.toFixed(0)}`
                            : "-"}
                          {arrowL(
                            c.span.aggresive_turns.changeDirection,
                            c.span.aggresive_turns.changePositive,
                            c.span.aggresive_turns.change
                          )}
                        </td>
                        <td>
                          {c.span.aggresive_braking.value
                            ? `${c.span.aggresive_braking.value.toFixed(0)}`
                            : "-"}
                          {arrowL(
                            c.span.aggresive_braking.changeDirection,
                            c.span.aggresive_braking.changePositive,
                            c.span.aggresive_braking.change
                          )}
                        </td>
                        <td>
                          {c.span.aggresive_acceleration.value
                            ? `${c.span.aggresive_acceleration.value.toFixed(
                                0
                              )}`
                            : "-"}
                          {arrowL(
                            c.span.aggresive_acceleration.changeDirection,
                            c.span.aggresive_acceleration.changePositive,
                            c.span.aggresive_acceleration.change
                          )}
                        </td>
                        <td>
                          {c.span.overspeeding_events.value
                            ? `${c.span.overspeeding_events.value.toFixed(0)}`
                            : "-"}
                          {arrowL(
                            c.span.overspeeding_events.changeDirection,
                            c.span.overspeeding_events.changePositive,
                            c.span.overspeeding_events.change
                          )}
                        </td>

                        <td>
                          {c.span.overall_risk_event.value
                            ? `${c.span.overall_risk_event.value.toFixed(0)}`
                            : "-"}
                          {arrowL(
                            c.span.overall_risk_event.changeDirection,
                            c.span.overall_risk_event.changePositive,
                            c.span.overall_risk_event.change
                          )}
                        </td>
                        <td>
                          {c.span.aggresive_turns_per100km.value
                            ? `${c.span.aggresive_turns_per100km.value.toFixed(
                                1
                              )}`
                            : "-"}

                          {arrowL(
                            c.span.aggresive_turns_per100km.changeDirection,
                            c.span.aggresive_turns_per100km.changePositive,
                            c.span.aggresive_turns_per100km.change
                          )}
                        </td>
                        <td>
                          {c.span.aggresive_braking_per100km.value
                            ? `${c.span.aggresive_braking_per100km.value.toFixed(
                                1
                              )}`
                            : "-"}
                          {arrowL(
                            c.span.aggresive_braking_per100km.changeDirection,
                            c.span.aggresive_braking_per100km.changePositive,
                            c.span.aggresive_braking_per100km.change
                          )}
                        </td>
                        <td>
                          {c.span.aggresive_acceleration_per100km.value
                            ? `${c.span.aggresive_acceleration_per100km.value.toFixed(
                                1
                              )}`
                            : "-"}
                          {arrowL(
                            c.span.aggresive_acceleration_per100km
                              .changeDirection,
                            c.span.aggresive_acceleration_per100km
                              .changePositive,
                            c.span.aggresive_acceleration_per100km.change
                          )}
                        </td>

                        <td>
                          {" "}
                          {c.span.average_overspeeding_events_per100km.value
                            ? `${c.span.average_overspeeding_events_per100km.value.toFixed(
                                1
                              )}`
                            : "-"}
                          {arrowL(
                            c.span.average_overspeeding_events_per100km
                              .changeDirection,
                            c.span.average_overspeeding_events_per100km
                              .changePositive,
                            c.span.average_overspeeding_events_per100km.change
                          )}
                        </td>
                        <td>
                          {" "}
                          {c.span.summary_per100km.value
                            ? `${c.span.summary_per100km.value.toFixed(1)}`
                            : "-"}
                          {arrowL(
                            c.span.summary_per100km.changeDirection,
                            c.span.summary_per100km.changePositive,
                            c.span.summary_per100km.change
                          )}
                        </td>
                        <td>
                          {" "}
                          {c.span.overspeeding_percentage.value
                            ? `${c.span.overspeeding_percentage.value.toFixed(
                                1
                              )}`
                            : "-"}
                          {arrowL(
                            c.span.overspeeding_percentage.changeDirection,
                            c.span.overspeeding_percentage.changePositive,
                            c.span.overspeeding_percentage.change
                          )}
                        </td>

                        <td>
                          {c.span.critical_overspeed.value
                            ? `${c.span.critical_overspeed.value.toFixed(0)}`
                            : "-"}
                          {arrowL(
                            c.span.critical_overspeed.changeDirection,
                            c.span.critical_overspeed.changePositive,
                            c.span.critical_overspeed.change
                          )}
                        </td>
                        {window.location.pathname.includes(
                          base + "/overall/risk/"
                        ) ? (
                          ""
                        ) : (
                          <td>{c.claim_count ? `${c.claim_count}` : 0}</td>
                        )}
                        {window.location.pathname.includes(
                          base + "/overall/risk/"
                        ) ? (
                          ""
                        ) : (
                          <td>
                            {c.span.risk_rate.value
                              ? `${c.span.risk_rate.value}`
                              : "-"}

                            {arrowL(
                              c.span.risk_rate.changeDirection,
                              c.span.risk_rate.changePositive,
                              c.span.risk_rate.change
                            )}
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <div></div>
                  )}
                </tbody>

                <tfoot>
                  <tr className="thead sorttable_nosort">
                    <th></th>

                    <th className="sorttable_nosort">
                      {" "}
                      {t("summary").toUpperCase()}
                    </th>

                    <th>{mileage ? `${mileage.toFixed(1)}` : "-"}</th>
                    <th>
                      {aggresive_turns ? `${aggresive_turns.toFixed(1)}` : "-"}
                    </th>

                    <th>
                      {aggresive_braking
                        ? `${aggresive_braking.toFixed(1)}`
                        : "-"}
                    </th>

                    <th>
                      {aggresive_acceleration
                        ? `${aggresive_acceleration.toFixed(1)}`
                        : "-"}
                    </th>
                    <th className="sorttable_nosort">
                      {overspeeding_events ? `${overspeeding_events}` : "-"}{" "}
                    </th>
                    <th>
                      {overall_risk_event ? `${overall_risk_event}` : "-"}{" "}
                    </th>
                    <th>{average_aggresive_turns}</th>
                    <th>{average_aggresive_braking}</th>
                    <th>{average_aggresive_acceleration}</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>
                      {critical_overspeed ? `${critical_overspeed}` : "-"}{" "}
                    </th>
                    {window.location.pathname.includes("/overall/risk/") ? (
                      ""
                    ) : (
                      <th>{claim_count ? claim_count : 0}</th>
                    )}
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Risk);
