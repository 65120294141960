import config from "../views/Main";
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "universal-cookie";
const cookie = new Cookies();
let authToken = cookie.get("Token");

export async function addSegment(product) {
  return axios({
    method: "POST",
    url: config.apiUrl.segment,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
    data: product,
  })
    .then((res) => {
      if (!res.ok) {
        Swal.fire({
          title: "Segment Added",
          type: "success",
          text: "Segment claim successful!",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(function () {
          window.location.href = "/app/recommendation/driver/";
        }, 2000);
      } else {
        Swal.fire({
          title: "Error",
          type: "error",
          text: "Error while add segment",
          showConfirmButton: false,
          timer: 2000,
        });
      }
      return res.json();
    })
    .catch((err) => console.log("error"));
}

export async function getSegment(id) {
  let products = new Promise((resolve, reject) => {
    fetch(`${config.apiUrl.segment}`, config.head)
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getSegmentDetails(id) {
  let products = new Promise((resolve, reject) => {
    fetch(`${config.apiUrl.segment}${id}/`, config.head)
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function deleteSegment(pk, props) {
  let country = new Promise((resolve, reject) => {
    Swal.fire({
      title: " Czy na pewno chcesz usunąć? ",
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: " Tak, chcę",
      cancelButtonText: " Nie, anuluj",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${config.apiUrl.segment}${pk}/`, config.head)
          .then((response) => {
            if (response.ok) {
              Swal.fire({
                title: "Segment",
                type: "error",
                text: "Error While deleting Segment ??",
                timer: 3000,
              });
            } else {
              Swal.fire({
                title: "Segment",
                type: "success",
                text: "Segment Deleted Successfully !!",
                showConfirmButton: false,
                timer: 2000,
              });

              setTimeout(function () {
                window.location.href = "/recommendation/driver/";
              }, 2000);
            }
            resolve(response.json());
          })

          .catch((reject) => console.log(reject));
      }
    });
  });

  return country;
}

export async function getSegmentData(id) {
  let products = new Promise((resolve, reject) => {
    fetch(config.apiUrl.dataSegment, config.head)
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}
