import React from "react";
import arrowL from "../Arrow";

const DropDown = (data, data1, data2, data3, a, b, c) => (
  <span>
    <span>
      {data === a
        ? `${data1.value ? `${data1.value.toFixed(1)}` : "-"}`
        : `${
            data === b
              ? `${data2.value ? `${data2.value.toFixed(1)}` : "-"}`
              : `${
                  data === c
                    ? `${data3.value ? `${data3.value.toFixed(1)}` : "-"}`
                    : `${data1.value ? `${data1.value.toFixed(1)}` : "-"}`
                }`
          }`}
    </span>
    <span>
      {data === a
        ? arrowL(data1.changeDirection, data1.changePositive, data1.change)
        : data === b
        ? arrowL(data2.changeDirection, data2.changePositive, data2.change)
        : data === c
        ? arrowL(data3.changeDirection, data3.changePositive, data3.change)
        : arrowL(data1.changeDirection, data1.changePositive, data1.change)}
    </span>
  </span>
);

export default DropDown;
