import React, { Component } from "react";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import { withTranslation } from "react-i18next";
import moment from "moment";

import "moment/min/moment-with-locales";

require("moment/locale/pl");

export class Filter extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      locale: "",
    };
  }

  render() {
    const { t } = this.props;
    const data1 = window.location.pathname === "/claim/insurance/";
    return (
      <div className="filter_range">
        <select
          disabled={data1}
          class="form-control donn"
          style={{
            width: "auto",
            opacity: "1",
            marginTop: "-2px",
            border: "2px solid #b71c1c",
          }}
          name="today"
          onChange={(e) => {
            this.props.toggle(e);
            this.props.hello(e);
          }}
        >
          <option selected disabled hidden>
            {t("overall")}
          </option>
          <option value="today">{t("today")}</option>
          <option value="yesturday">{t("yesturday")}</option>
          <option value="week">{t("last_week")}</option>
          <option value="month">{t("last_month")}</option>
          {/* <option value="quarter">{t("last_quarter")}</option> */}
          <option value="year">{t("last_year")}</option>
          <option value="">{t("overall")}</option>
          {/* <option>Custom Day</option> */}
          <option disabled>───────</option>
          {this.props.lol !== null && this.props.fol !== null && (
            <option value="date">
              {" "}
              {this.props.lol === null && this.props.fol === null
                ? moment(this.props.lol).format("LL") +
                  " - " +
                  moment(this.props.fol).format("LL") //this.props.fol.format("MMMM Do YYYY")
                : moment(this.props.lol).format("LL") +
                  " - " +
                  moment(this.props.fol).format("LL")}
            </option>
          )}
          <option value="date">
            {this.props.lol === null && this.props.fol === null
              ? t("custom")
              : moment(this.props.lol).local("pl").format("LL") +
                " - " +
                moment(this.props.fol).format("LL")}
          </option>
        </select>

        {this.props.option && (
          <DateRangePicker
            value={this.props.value}
            onSelect={this.props.change}
            singleDateRange={true}
            isOpen={false}
            locale={
              window.localStorage.i18nextLng === "en"
                ? moment.locale("en")
                : moment.locale("pl")
            }
            maximumDate={new Date()}
            closeCalendar={true}
            numberOfCalendars={2}
            showLegend={true}
          />
        )}

        {/* {this.state.isOpen && (
          <DateRangePicker
            value={this.state.value}
            numberOfCalendars={1}
            onSelect={this.onSelect}
            selectionType="single"
          />
        )} */}
      </div>
    );
  }
}

export default withTranslation()(Filter);
