import React, { Component } from "react";
import TimeAgo from "react-timeago";
import Header from "./Header";
import moment from "moment";
export class New extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // const { t } = this.props;
    return (
      <div>
        <Header
          len={this.props.noti}
          next={this.props.next}
          prev={this.props.prev}
          data={this.props.notification}
        />

        {this.props.notification && (
          <span>
            {this.props.notification.data
              .filter(
                (c) =>
                  moment(c.created_at).format("DD-MM-YYYY") ===
                  moment().format("DD-MM-YYYY")
              )
              .map((c) => (
                <nav class="navbar navbar-expand-lg navbar-light notification_data_bar">
                  <ul class="navbar-nav mr-auto">
                    <li class="nav-item active">
                      <span class="nav-link">
                        <input
                          type="checkbox"
                          class="select-all checkbox"
                          name="first_name"
                          id="selectAll1"
                          //   checked={this.state.checkedBoxCheck}
                          //   onClick={this.toggleSelectAll.bind(this)}
                        />
                      </span>
                    </li>
                    <li class="nav-item active">
                      <a class="nav-link" href="# ">
                        {c.body}{" "}
                        <a
                          href={c.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <strong style={{ color: "rgb(199, 47, 47)" }}>
                            {" "}
                            {c.link.split("/").reverse()[1]}{" "}
                          </strong>
                        </a>
                      </a>
                    </li>
                  </ul>
                  <ul class="navbar-nav ml-auto">
                    <li class="nav-item active">
                      <span class="nav-link disabled">
                        {" "}
                        <span
                          style={{
                            fontSize: "13px",
                            opacity: "0.6",
                            marginTottom: "-22px",
                          }}
                        >
                          <i>
                            {" "}
                            <TimeAgo date={c.created_at} locale="pl" />
                          </i>
                        </span>
                      </span>
                    </li>
                    <li class="nav-item active">
                      {moment(c.created_at).format("DD-MM-YYYY") ===
                      moment().format("DD-MM-YYYY") ? (
                        <span class="nav-link " style={{ color: "#b71c1c" }}>
                          {" "}
                          New
                        </span>
                      ) : (
                        <span class="nav-link disabled"></span>
                      )}
                    </li>
                  </ul>
                </nav>
              ))}
          </span>
        )}
      </div>
    );
  }
}

export default New;
