import React, { Component } from "react";
import Chart from "react-apexcharts";

import { withTranslation } from "react-i18next";
import { getFuel } from "../../../../../Service/Dashboard";

const colors = ["#B71C1C", "#F57C01", "#FFC105", "#13B760"];
var d = new Date();
var mm = d.getMonth() + 1;
var dd = d.getDate();
var yy = d.getFullYear();

var cc = yy + "-" + mm + "-" + dd;
const base = process.env.PUBLIC_URL;
class Fuel extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      options: {
        chart: {
          id: "Fuel Economy",
          events: {
            dataPointSelection: function (event, chartContext, opts) {
              switch (opts.w.config.xaxis.categories[opts.dataPointIndex]) {
                case "0–4":
                  window.open(
                    window.location.origin +
                      base +
                      `/fuelfilter/recommendation/driver/4/0/${
                        this.props.to ? `${this.props.to}` : `${cc}`
                      }/${this.props.frm ? `${this.props.frm}` : "2016-01-04"}`
                  );
                  break;
                case "4–6":
                  window.open(
                    window.location.origin +
                      base +
                      `/fuelfilter/recommendation/driver/6/4/${
                        this.props.to ? `${this.props.to}` : `${cc}`
                      }/${this.props.frm ? `${this.props.frm}` : "2016-01-04"}`
                  );
                  break;
                case "6–8":
                  window.open(
                    window.location.origin +
                      base +
                      `/fuelfilter/recommendation/driver/8/6/${
                        this.props.to ? `${this.props.to}` : `${cc}`
                      }/${this.props.frm ? `${this.props.frm}` : "2016-01-04"}`
                  );
                  break;
                case "8–10":
                  window.open(
                    window.location.origin +
                      base +
                      `/fuelfilter/recommendation/driver/10/8/${
                        this.props.to ? `${this.props.to}` : `${cc}`
                      }/${this.props.frm ? `${this.props.frm}` : "2016-01-04"}`
                  );
                  break;
                default:
                  window.open(window.location.origin + base + "/my/driver/");
              }
            }.bind(this),
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: colors,
        xaxis: {
          type: "category",
          title: {
            text: t("x-axis-summary"),
          },
          labels: {
            style: {
              colors: colors,
              fontSize: "12px",
            },
          },
          categories: [],
        },

        plotOptions: {
          bar: {
            // columnWidth: "70%",
            // endingShape: "rounded",
            distributed: true,
          },
        },
        stroke: {
          width: [2],
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return value + " % ";
            },

            title: {
              text: t("y-axios-summary"),
            },

            style: {
              fontSize: "10px",
              opacity: "0.2",
            },
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
        },
        title: {
          text: "",
          align: "center",
          style: {
            fontSize: "20px",
            color: "black",
          },
        },
        labels: [],
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: t("Fuel"),
          data: [],
        },
      ],
    };
  }

  fetchData() {
    getFuel(this.props.to, this.props.frm).then((data) => {
      const newXaxis = data.labels;
      const newSeries = [];
      newSeries.push({
        data: data.data,
        name: "Fuel",
      });
      this.setState({
        series: newSeries,
        options: {
          ...this.state.options,
          labels: newXaxis,
          xaxis: { ...this.state.options.xaxis, categories: newXaxis },
        },
      });
    });
  }
  componentDidMount() {
    this.fetchData();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.series !== this.state.series) {
      this.fetchData();
    }
  }
  render() {
    return (
      <div
        className="tab-pane fade"
        id="pills-contact"
        role="tabpanel"
        aria-labelledby="pills-contact-tab"
      >
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height="250"
        />
      </div>
    );
  }
}

export default withTranslation()(Fuel);
