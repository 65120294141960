import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import User from "../../../../Components/User";
import Datee from "../../../Views/Date";
import Upload from "./Upload";
import arrow_left from "../../../../img/Aarrow.png";
import Insurance from "../../../../Components/Layout/Insurance";
import { addClaim } from "../../../../Service/Claim";
import Damage from "./Damage/Damage";
import Swal from "sweetalert2";

export class AddClaim extends Component {
  constructor(props) {
    super(props);

    this.state = {
      swt: true,
      swt1: true,
      choice: "own",
      isHidden: true,
      selectedOption: "",
      selectedOption1: "",
      driverChange: "",
      describe: "",
      car_list: [],
      driver_list: [],
      car: "",
      car_vin: "",
      driver: null,
      net_value: null,
      damage_date: null,
      date_claim: null,
      insurance_number: "",
      status: "",
      type_ac_oc: "",
      description: "",
      claim_details: [
        {
          car: null,
          car_vin: "",
          driver: null,
          driver_name: "",
          address: "",
          documents_number: "",
          driving_licence_number: "",
          phone_number: "",
          email: "",
          car_owner_number: "",
          car_vin_number: "",
          car_owner_address: "",
          pesel_number: "",
          nip_number: "",
          other_car_vin_number: "",
          company_email: "",
          company_phone: "",
          file: null,
        },
      ],
      claim_photo: [
        {
          file: null,
          category: "",
          sub_category: "",
          image: null,
        },
      ],
    };
  }

  handleAddFields = () => {
    const values = this.state.claim_details;
    values.push({
      car: null,
      car_vin: "",
      driver: null,
      driver_name: "",
      address: "",
      documents_number: "",
      driving_licence_number: "",
      phone_number: "",
      email: "",
      car_vin_number: "",
      car_owner_number: "",
      car_owner_address: "",
      pesel_number: "",
      nip_number: "",
      other_car_vin_number: "",
      company_email: "",
      company_phone: "",
    });
    this.setState({
      values,
    });
  };

  handleRemoveFields = (index) => {
    const values = this.state.claim_details;
    values.splice(index, 1);
    this.setState({
      values,
    });
  };

  handleSubmit(e) {
    //this.handleImage1("");
    const data1 = {
      net_value: this.state.net_value,
      damage_date: this.state.damage_date,
      date_claim: this.state.date_claim,

      description: this.state.description,
      insurance_number: this.state.insurance_number,
      status: this.state.swt === true ? "active" : "inactive",
      type_ac_oc: this.state.type_ac_oc,
      claim_photo: [...this.state.claim_photo],
      claim_details: [...this.state.claim_details],
    };

    e.preventDefault();

    Swal.fire({
      title: "Loading",
      type: "warning",
      showCancelButton: false,
      showConfirmButton: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    addClaim(data1).then((data) => {
      this.setState({ data });
    });
  }

  async onChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  onFocus = (msg, e) => {
    const id = e.target.id;
    const msgId = id + "_msg";
    document.getElementById(msgId).innerHTML = msg;
  };

  onBlur = (e) => {
    const id = e.target.id;
    const msgId = id + "_msg";
    document.getElementById(msgId).innerHTML = "&nbsp;";
  };

  async onClick(e) {
    await this.setState({
      swt: !this.state.swt,
    });
  }
  render() {
    const { t } = this.props;

    return (
      <div
        className=""
        style={{
          marginTop: "px",
          width: "100%",
          padding: "40px",
          overflow: "hidden",
        }}
      >
        <Helmet>
          <title>Claim Adding</title>
        </Helmet>

        <div
          className="row row1"
          style={{
            width: "100%",
            marginTop: "-20px",
            marginBottom: "-40px",
          }}
        >
          {" "}
          <div className="col-sm-3 date_time">
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
        </div>
        <Insurance />

        <div className="headerr" style={{ marginTop: "-6px" }}>
          <div style={{ padding: "10px" }}>
            <a href="# " onClick={this.handleClick.bind(this)}>
              <img
                src={arrow_left}
                alt="Left_Arrow"
                title="Back to previous page"
                style={{ height: "2.6em" }}
              />
            </a>
            &nbsp;&nbsp;
            {t("claim_ading").toUpperCase()} &nbsp;
          </div>

          <div className="" style={{ marginTop: "5px", marginLeft: "-20px" }}>
            <div className="custom-control custom-radio custom-control-inline ">
              <input
                type="radio"
                name="choice"
                id="rrown"
                defaultChecked
                value="own"
                onClick={() => this.handleRemoveFields(1)}
                // onChange={this.onChange.bind(this)}
              />
              <label htmlFor="rrown"> {t("own_damage")}</label>
            </div>
            <div
              class="custom-control custom-radio custom-control-inline"
              style={{ marginLeft: "20px" }}
            >
              <input
                type="radio"
                name="choice"
                id="rrcoll"
                value="collision"
                onClick={this.handleAddFields}
                // onChange={this.onChange.bind(this)}
              />
              <label htmlFor="rrcoll" className="line ">
                {t("vehicle_collision")}
              </label>
            </div>
          </div>

          <form onSubmit={this.handleSubmit.bind(this)}>
            <h3 className="font-weight-normal" style={{ marginTop: "30px" }}>
              {t("new_claim")} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
              <span className="h6">
                {t("type_of_claims_OC/AC").toUpperCase()}
              </span>
              <span>
                <span className="custom-control custom-radio custom-control-inline ">
                  <input
                    type="radio"
                    name="type_ac_oc"
                    value="AC"
                    className="h6"
                    onChange={this.onChange.bind(this)}
                    id="rrA"
                  />
                  <label htmlFor="rrA" className=" h6">
                    AC
                  </label>
                </span>
                <span
                  class="custom-control custom-radio custom-control-inline"
                  style={{ marginLeft: "-20px" }}
                >
                  <input
                    type="radio"
                    name="type_ac_oc"
                    value="OC"
                    className="h6"
                    onChange={this.onChange.bind(this)}
                    id="rrO"
                  />
                  <label htmlFor="rrO" className=" h6">
                    OC
                  </label>
                </span>
              </span>
              <div>
                <span
                  className="float-right"
                  style={{
                    marginTop: "-26px",
                  }}
                >
                  <label class="switch1">
                    <input
                      type="checkbox"
                      id="togBtn"
                      name="status"
                      onClick={this.onClick.bind(this)}
                    />
                    <div class="slider1 round"></div>
                  </label>
                </span>

                <p
                  className="float-right"
                  style={{
                    marginRight: "10px",
                    fontsize: "15px",
                    marginTop: "-25px",
                  }}
                >
                  {" "}
                  <span>
                    {" "}
                    {this.state.swt ? `${t("disable")}` : `${t("active")}`}
                  </span>
                </p>
              </div>
            </h3>
            <hr></hr>

            <div className="row">
              <div className="col-md-3">
                <div className="form-group">
                  <label id="p" for="exampleInputEmail1">
                    {t("insurance_no").toUpperCase()}
                    <span className="start_icon"> * </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("type")}
                    name="insurance_number"
                    value={this.state.insurance_number}
                    onChange={this.onChange.bind(this)}
                    className="form-control"
                    onFocus={this.onFocus.bind(
                      this,
                      "please fill in the field"
                    )}
                    onBlur={this.onBlur.bind(this)}
                    id="insurance_number"
                  />
                  <p style={{ color: "#b71c1c" }} id="insurance_number_msg">
                    &nbsp;
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label id="p" for="exampleInputEmail1">
                    {t("damage_date").toUpperCase()}
                    <span className="start_icon"> * </span>
                  </label>
                  <input
                    type="date"
                    placeholder={t("type")}
                    name="damage_date"
                    value={this.state.damage_date}
                    onChange={this.onChange.bind(this)}
                    className="form-control"
                    onFocus={this.onFocus.bind(
                      this,
                      "please fill in the field"
                    )}
                    onBlur={this.onBlur.bind(this)}
                    id="damage_date"
                  />
                  <p style={{ color: "#b71c1c" }} id="damage_date_msg">
                    &nbsp;
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label id="p" for="exampleInputEmail1">
                    {t("claim_date").toUpperCase()}
                    <span className="start_icon"> * </span>
                  </label>
                  <input
                    type="date"
                    placeholder={t("type")}
                    name="date_claim"
                    value={this.state.date}
                    onChange={this.onChange.bind(this)}
                    className="form-control"
                    onFocus={this.onFocus.bind(
                      this,
                      "please fill in the field"
                    )}
                    onBlur={this.onBlur.bind(this)}
                    id="date_claim"
                  />
                  <p style={{ color: "#b71c1c" }} id="date_claim_msg">
                    &nbsp;
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group" style={{ marginTop: "-5px" }}>
                  <label id="p">{t("Costs").toUpperCase()}</label>
                  <span className="start_icon"> * </span>
                  <input
                    type="number"
                    placeholder={t("type")}
                    name="net_value"
                    step="any"
                    value={this.state.net_value}
                    onChange={this.onChange.bind(this)}
                    className="form-control"
                    onFocus={this.onFocus.bind(
                      this,
                      "please fill in the field"
                    )}
                    onBlur={this.onBlur.bind(this)}
                    id="net_value"
                  />
                  <p style={{ color: "#b71c1c" }} id="net_value_msg">
                    &nbsp;
                  </p>
                </div>
              </div>
            </div>

            {/* {this.state.choice === "own" ? (
             
            ) : (
              ""
            )} */}

            <div>
              <Damage state={this.state} />
            </div>

            <div className="col-sm-6">
              <div className="form-group" style={{ marginTop: "0px" }}>
                <label
                  id="p"
                  className="font-weight-bolder"
                  for="exampleInputEmail1"
                >
                  {t("description_claim").toUpperCase()}
                  <span className="start_icon"> * </span>
                </label>
                <textarea
                  className="form-control"
                  rows="2"
                  col="10"
                  name="description"
                  value={this.state.description}
                  onChange={this.onChange.bind(this)}
                  placeholder={t("type")}
                  onFocus={this.onFocus.bind(this, "please fill in the field")}
                  onBlur={this.onBlur.bind(this)}
                  id="description"
                ></textarea>
                <p style={{ color: "#b71c1c" }} id="description_msg">
                  &nbsp;
                </p>
              </div>
            </div>

            <div className="another_section" style={{ marginTop: "50px" }}>
              <h3 className="font-weight-normal" style={{ marginTop: "0px" }}>
                {t("claims_documents")}
              </h3>
              <hr></hr>

              <Upload state={this.state.claim_photo} />
            </div>
            <center>
              <button
                className="btn btn-danger br text-white"
                value="create campaign"
                style={{ background: "#b71c1c" }}
              >
                {t("add_claim")}
              </button>
            </center>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AddClaim);
