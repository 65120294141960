import config from "../views/Main";
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "universal-cookie";
const cookie = new Cookies();
let authToken = cookie.get("Token");

const base = process.env.PUBLIC_URL;
export async function changePassword(product) {
  let country = new Promise((resolve, reject) => {
    axios({
      url: config.apiUrl.changePassword,
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
      data: product,
    })
      .then((response) => {
        if (!response.ok) {
          Swal.fire({
            title: "Change Password ",
            type: "success",
            text: "Passoword Change",
            showConfirmButton: false,
            timer: 4000,
          });
        } else {
          console.log("ss");
        }
        setTimeout(function () {
          window.location.href = base + "/fleet/dashboard/";
        }, 2000);
        resolve(response.json());
      })
      .catch((error) => {
        if (error.response) {
          Swal.fire({
            title: " Error !",
            type: "error",
            text: error.response.data.old_password
              ? "Old password " + error.response.data.old_password
              : error.response.data.new_password,
            timer: 2000,
          });
          console.log("Err", error.response.data);
        } else {
          console.log("Error", error.message);
        }
      });
  });
  return country;
}

export async function forgetPassword(product, email) {
  let country = new Promise((resolve, reject) => {
    axios({
      url: config.apiUrl.forget,
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: product,
    })
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: "Email Sent ",
            icon: "success",
            text: "Email sent to" + email + "Forget Password",
            showConfirmButton: false,
            timer: 4000,
          });
        } else {
          Swal.fire({
            title: " Error !",
            icon: "error",
            text: "Please Check Your email id??",
            timer: 2000,
          });
          Swal.fire({
            title: "Email Sent ",
            icon: "success",
            text: "Email sent to " + email + " Forget Password",
            showConfirmButton: false,
            timer: 4000,
          });
        }
        resolve(response.json());
      })
      .catch((reject) => {
        console.log(reject);
        Swal.fire({
          title: "reset password",
          type: "error",
          text: reject.response.data.message,
          timer: 2000,
        });
      });
  });
  return country;
}
