import React, { Component } from "react";
import Route1 from "./views/Link/Router/Route";
import "./views/Style/Model.css";
import "./views/Style/Leaf.css";
import "./views/Style/MyCss.css";
import "./App.css";
import LoadingBar from "react-top-loading-bar";

function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 2500));
}
class App extends Component {
  state = {
    loading: true,
    loadingBarProgress: 0,
  };
  componentDidMount() {
    demoAsyncCall().then(() => this.setState({ loading: false }));
    const base = process.env.PUBLIC_URL;
    const data =
      window.location.pathname === base + "/app" ||
      window.location.pathname === base + "/app/" ||
      window.location.pathname === base + "/"
        ? ""
        : localStorage.setItem("path", window.location.pathname);
    this.setState({
      data,
    });
  }
  complete = () => {
    this.setState({ loadingBarProgress: 100 });
  };

  onLoaderFinished = () => {
    this.setState({ loadingBarProgress: 0 });
  };
  render() {
    return (
      <div>
        <LoadingBar
          progress={this.state.loadingBarProgress}
          height={3}
          color="red"
          onLoaderFinished={() => this.onLoaderFinished()}
        />
        <Route1 />
      </div>
    );
  }
}

export default App;
