import config from "../views/Main";

export async function getFleetNotification(to, from) {
  let products = new Promise((resolve, reject) => {
    fetch(config.apiUrl.fleetnotification, config.head)
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getUserNotification(to, from) {
  let products = new Promise((resolve, reject) => {
    fetch(config.apiUrl.usernotification, config.head)
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getNotification1(result) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.fnotification}?result=${result ? result : ""}`, //`http://127.0.0.1:8000/api/2/notifications/fleet/?result=${result}`,
      config.head //config.headPost1
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function updateNotification1(pk) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.fnotification}${pk}/`,
      //`http://127.0.0.1:8000/api/2/notifications/fleet/${pk}/`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function deleteNotification(pk) {
  let products = new Promise((resolve, reject) => {
    fetch(`${config.apiUrl.fnotification}${pk}/`, config.headDelete)
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function deleteSelectedNotification(pk) {
  let products = new Promise((resolve, reject) => {
    fetch(`${config.apiUrl.fnotification}?id=${pk}`, config.headDelete)
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function MarkSelectedNotification(pk) {
  let products = new Promise((resolve, reject) => {
    fetch(`${config.apiUrl.fnotification}?id=${pk}`, config.headPut)
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}
