import React from "react";
import { useTranslation } from "react-i18next";

function Driverlist(props) {
  const base = process.env.PUBLIC_URL;
  const { t } = useTranslation();
  return (
    <div className="" style={{ marginTop: "30px" }}>
      <h3 className="font-weight-normal" style={{ marginTop: "30px" }}>
        {t("Driver")}
      </h3>
      <hr></hr>
      <table className="table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>{t("Id")}</th>
            <th>{t("Name")}</th>

            <th>{t("used_from")}</th>
            <th>{t("used_to")}</th>

            <th></th>
          </tr>
        </thead>

        <tbody>
          {props.driver.map((c, i) => (
            <tr key={c.id}>
              <td>{i + 1}</td>
              <td>
                <a
                  href={base + "/overview/app/drivermetrics/" + c.id+ "/"}
                  style={{ color: "#b71c1c" }}
                  title="Click to view driver profile"
                  className="font-weight-bold"
                >
                  {c.name ? c.name : "-"}
                </a>
              </td>

              <td>{c.since ? `${c.since}` : " - "}</td>
              <td>{c.until ? `${c.until}` : " - "}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Driverlist;
