import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Vehicle from "./PhotoType/Vehicle";
import Insurance from "./PhotoType/Insurance";
import Other from "./PhotoType/Other";
import Registration from "./PhotoType/Registration";
import "./Upload.css";

class UploadDoc extends Component {
  render() {
    const { t } = this.props;

    return (
      <div className="row">
        <Vehicle pk={this.props.id} />
        <Insurance pk={this.props.id} />
      <Other
          pk={this.props.id}
        /> 
        <Registration pk={this.props.id} />
        <div className="col-sm-3 sd">
          <a href="#vehicle" rel="modal:open" className="btn upload  text-dark">
            {t("vehicle_photo")}
          </a>
        </div>
        <div className="col-sm-3 sd">
          <a
            href="#insurance"
            rel="modal:open"
            className="btn  upload text-dark"
          >
            {t("insurance_police")}
          </a>
        </div>
        <div className="col-sm-3 sd">
          <a
            href="#registration"
            rel="modal:open"
            className="btn upload   text-dark"
          >
            {t("vehicle_registration")}
          </a>
        </div>
        <div className="col-sm-3 sd">
          <a href="#other" rel="modal:open" className="btn  upload text-dark">
            {t("other_documents")}
          </a>
        </div>
      </div>
    );
  }
}

export default withTranslation()(UploadDoc);
