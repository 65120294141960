import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header1 from "../../../Components/Layout/Header1";
import { getCarDaily, getSummaryDaily } from "../../../Service/Car";
import User from "../../../Components/User";
import Datee from "../../Views/Date";
import print from "../../../img/Rating/print.png";
import print_pl from "../../../img/Rating/print_pl.png";
import { withTranslation } from "react-i18next";
import DateGte from "../../../Service/Arrow/DateGte";
import DateLte from "../../../Service/Arrow/DateL";
import compareByAsc from "../../../Service/Arrow/Sort/Asc";
import compareByDesc from "../../../Service/Arrow/Sort/Desc";
import getPrint from "../../../Service/Arrow/Print";
import Filter from "../Filter";
import arrow_left from "../../../img/Aarrow.png";
// import storm from "../../../img/storm.png";
// import map from "../../../img/route1.png";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import Spinner from "../../../Container/Spinner";

const moment = extendMoment(originalMoment);
export class ElectrcDetails extends Component {
  constructor(props) {
    super(props);
    const today = moment();
    this.state = {
      ev: [],
      speed: [],
      isOpen: false,
      today: null,
      lol: null,
      fol: null,
      isLoading: true,
      value: moment.range(today.clone().subtract(0, "days"), today.clone()),
    };
  }

  onToggle = (event) => {
    if (event.target.value === "date") {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  // Get Data from filter date
  getData = async (value, states) => {
    this.setState({ isLoading: true });
    Promise.all([
      getCarDaily(
        this.props.match.params.pk,
        value.end.format("YYYY-MM-DD"),
        value.start.format("YYYY-MM-DD")
      ).then((data) => {
        this.setState({
          ev: data,
          lol: value.start,
          fol: value.end,
          isOpen: !this.state.isOpen,
          isLoading: false,
        });
      }),
      getSummaryDaily(
        this.props.match.params.pk,
        value.end.format("YYYY-MM-DD"),
        value.start.format("YYYY-MM-DD")
      ).then((data) => {
        this.setState({ speed: data });
      }),
    ]);
  };

  getOptionData = async () => {
    this.setState({ isLoading: true });
    Promise.all([
      getCarDaily(
        this.props.match.params.pk,
        DateGte(this.state.today),
        DateLte(this.state.today)
      ).then((data) => {
        this.setState({ ev: data, isLoading: false });
      }),
      getSummaryDaily(
        this.props.match.params.pk,
        DateGte(this.state.today),
        DateLte(this.state.today)
      ).then((data) => {
        this.setState({ speed: data });
      }),
    ]);
  };

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.getOptionData("");
  };

  componentDidMount() {
    this.getOptionData("");
  }

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  // Sorting Resolve

  sortBy(key) {
    let arrayCopy = [...this.state.ev];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(compareByAsc(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(compareByDesc(key));
    }
    this.setState({ ev: arrayCopy });
  }

  render() {
    const { t } = this.props;
    const { ev, speed } = this.state;
    const base = process.env.PUBLIC_URL;

    return (
      <div className="Main_section">
        <Helmet>
          {ev.slice(0, 1).map((c) => (
            <title>
              {c.plate_number ? `${c.plate_number}` : " Plate Number "}{" "}
            </title>
          ))}
          <meta charSet="utf-8" />
          <meta
            name="Helmet application"
            content="Electric  Days Trip details"
          />
        </Helmet>

        <div
          className="row row1"
          style={{ width: "100%", marginBottom: "-30px" }}
        >
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
          <div className="col-sm-6 date_time" style={{ marginTop: "-5px" }}>
            <Filter
              lol={this.state.lol}
              fol={this.state.fol}
              today={this.state.today}
              option={this.state.isOpen}
              toggle={this.onToggle}
              change={this.getData}
              hello={this.onChange}
            />
          </div>
        </div>

        <div style={{ marginTop: "-15px" }}>
          <Header1 />
        </div>

        <div className="headerr">
          <div className="drive_score">
            <span>
              <a href="# " onClick={this.handleClick.bind(this)}>
                <img
                  src={arrow_left}
                  alt="Left_Arrow"
                  style={{ height: "2.6em" }}
                />
              </a>{" "}
              &nbsp;
              {t("back")}
              &nbsp;&nbsp;&nbsp;
              {ev.length > 0 ? (
                ev.slice(0, 1).map((c) => (
                  <span>
                    {" "}
                    {t("my_ev_cars")} / <strong>{c.plate_number}</strong>
                  </span>
                ))
              ) : (
                <strong>&nbsp;&nbsp; {t("no_data")}</strong>
              )}
            </span>
            <span className="float-right">
              <a href="# " onClick={getPrint}>
                <img
                  src={
                    window.localStorage.i18nextLng === "en" ? print : print_pl
                  }
                  alt="Print page"
                  style={{ marginTop: "-4px", height: "3em" }}
                />
              </a>
            </span>
          </div>

          <div
            className="caaar table-responsive"
            style={{ marginTop: "20px", overflowX: "auto" }}
          >
            {this.state.isLoading === true ? (
              <Spinner />
            ) : (
              <table
                id="myTable"
                className="table tabell1  table-hover"
                style={{ marginTop: "20px" }}
              >
                <thead>
                  <tr>
                    <th> {t("Id")}</th>
                    <th>{t("date")}</th>

                    <th className="t">{t("Milage")}</th>
                    <th className="t">{t("energy_cosumed")}</th>
                    <th className="t">{t("average_energy_cosumed")}</th>
                    <th className="t">{t("fuel_eq")}</th>
                    <th className="t">{t("energy_from_recuperation")}</th>
                    <th className="t">{t("driving_time")}</th>
                    <th className="t">{t("charging_time")}</th>
                    <th className="t">{t("number_of_charging")}</th>
                    <th className="t">{t("energy_from_charging")}</th>
                    <th className="t">{t("energy_from_ac")}</th>
                    <th className="t">{t("energy_from_dc")}</th>
                    <th className="t"></th>

                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {ev.map((c, i) => (
                    <tr
                      key={c.id}
                      className={
                        c.date
                          ? `${
                              new Date(c.date).getDay() === 6 ||
                              new Date(c.date).getDay() === 0
                                ? "driver_color"
                                : "text"
                            } `
                          : "-"
                      }
                    >
                      <td>{i + 1}</td>
                      <td>{c.date ? c.date : "-"}</td>
                      <td>{c.milage ? c.milage.toFixed(1) : "-"}</td>

                      <td>
                        {c.energy_consumed ? c.energy_consumed.toFixed(1) : "-"}
                      </td>
                      <td>
                        {c.average_energy_consumption
                          ? c.average_energy_consumption.toFixed(1)
                          : "-"}
                      </td>
                      <td>{c.fuel_eq ? c.fuel_eq.toFixed(1) : "-"}</td>
                      <td>
                        {c.energy_from_recuperation
                          ? c.energy_from_recuperation.toFixed(1)
                          : "-"}
                      </td>
                      <td>{c.driving_time ? c.driving_time : "-"}</td>
                      <td>{c.charging_time ? c.charging_time : "-"}</td>
                      <td>
                        {c.number_of_charging ? c.number_of_charging : "-"}
                      </td>
                      <td>
                        {c.energy_from_charging
                          ? c.energy_from_charging.toFixed(1)
                          : "-"}
                      </td>
                      <td>
                        {c.energy_from_ac_charging
                          ? c.energy_from_ac_charging.toFixed(1)
                          : "-"}
                      </td>
                      <td>
                        {c.energy_from_dc_charging
                          ? c.energy_from_dc_charging.toFixed(1)
                          : "-"}
                      </td>
                      <td>
                        <a
                          className="font-weight-bold"
                          href={
                            base + `/dailyview/app/electric/${c.id}/${c.date}/`
                          }
                          style={{ color: "#b71c1c", opacity: "1" }}
                        >
                          {t("battery").toUpperCase()}
                        </a>
                      </td>
                      <td>
                        <a
                          className="font-weight-bold"
                          href={
                            base + `/carmap/${c.id}/${c.date}/${c.plate_number}`
                          }
                          style={{ color: "#b71c1c", opacity: "1" }}
                        >
                          {t("map")}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="thead sorttable_nosort">
                    <th className="sorttable_nosort"></th>

                    <th className="sorttable_nosort">
                      {" "}
                      {t("summary").toUpperCase()}
                    </th>

                    <th className="sorttable_nosort">
                      {speed.milage ? `${speed.milage.toFixed(1)}` : "-"}
                    </th>
                    <th className="sorttable_nosort">
                      {speed.energy_consumed
                        ? `${speed.energy_consumed.toFixed(1)}`
                        : "-"}
                    </th>

                    <th className="sorttable_nosort">
                      {speed.average_energy_consumed
                        ? `${speed.average_energy_consumed.toFixed(1)}`
                        : "-"}
                    </th>

                    <th className="sorttable_nosort">
                      {speed.fuel_eq ? `${speed.fuel_eq.toFixed(1)}` : "-"}
                    </th>
                    <th className="sorttable_nosort">
                      {speed.energy_from_recuperation
                        ? `${speed.energy_from_recuperation.toFixed(1)}`
                        : "-"}{" "}
                    </th>
                    <th className="sorttable_nosort">
                      {speed.driving_time ? `${speed.driving_time}` : "-"}{" "}
                    </th>

                    <th className="sorttable_nosort">
                      {speed.charging_time ? `${speed.charging_time}` : "-"}{" "}
                    </th>

                    <th className="sorttable_nosort">
                      {speed.number_of_charging
                        ? `${speed.number_of_charging}`
                        : "-"}{" "}
                    </th>
                    <th className="sorttable_nosort">
                      {speed.energy_from_charging
                        ? `${speed.energy_from_charging.toFixed(1)}`
                        : "-"}{" "}
                    </th>
                    <th className="sorttable_nosort">
                      {speed.energy_from_ac_charging
                        ? `${speed.energy_from_ac_charging.toFixed(1)}`
                        : "-"}{" "}
                    </th>
                    <th className="sorttable_nosort">
                      {speed.energy_from_dc_charging
                        ? `${speed.energy_from_dc_charging.toFixed(1)}`
                        : "-"}{" "}
                    </th>
                    <th className="sorttable_nosort"></th>
                  </tr>
                </tfoot>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ElectrcDetails));
