import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "../../Components/Layout/index";
import cross from "../../img/Cross.png";
import { getOption, AddCost } from "../../Service/Cost";
import { getCar } from "../../Service/Car";
import Select from "react-select";
import CssTextField from "../../Service/Arrow/Csstext";
import colourStyles from "../../Service/Arrow/Color";
import Swal from "sweetalert2";
class AddInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      car_plate_number: "",
      car_vin: "",
      invoice_number: "",
      net_value: null,
      value: "",
      date: "",
      description: "",
      cost_type: "",
      car: [],
      quantity: "",
      label: [],
      car_mileage: null,
      isHidden: true,
      selectedOption: "",
      selectedOption1: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggleHidden() {
    this.setState({
      isHidden: false,
    });
  }

  toggleHie() {
    this.setState({ isHidden: true });
  }

  async onChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleChange = async (selectedOption) => {
    await this.setState({ selectedOption });
  };
  handleChange1 = async (selectedOption1) => {
    await this.setState({ selectedOption1 });
  };

  componentDidMount() {
    Promise.all([
      getCar().then((data) => {
        this.setState({ car: data });
      }),
      getOption().then((data) => {
        this.setState({ label: data.actions.POST.cost_type.choices });
      }),
    ]);
  }

  handleSubmit(e) {
    const data1 = {
      car_plate_number: this.state.selectedOption.value,
      car_vin: this.state.selectedOption1.value,
      invoice_number: this.state.invoice_number,
      net_value: this.state.net_value,
      value: this.state.value,
      date: this.state.date,
      description: this.state.description,
      cost_type: this.state.cost_type,
      car_mileage: this.state.car_mileage,
      quantity: this.state.quantity,
    };
    Swal.fire({
      title: "Loading",
      type: "warning",
      showCancelButton: false,
      showConfirmButton: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    e.preventDefault();
    AddCost(data1).then((data) => {
      this.setState({ data });
    });
  }

  render() {
    const { t } = this.props;
    const { car, label } = this.state;

    let options1 = car.map(function (city) {
      return { value: city.plate_number, label: city.plate_number };
    });
    let options2 = car.map(function (city) {
      return { value: city.vin, label: city.vin };
    });

    const { selectedOption, selectedOption1 } = this.state;

    return (
      <div>
        <div id="ex1" className="modal">
          <a
            href="# "
            rel="modal:close"
            className="float-right text-dark "
            style={{ marginBottom: "90px" }}
          >
            <img src={cross} alt="Cross" style={{ height: "1.1em" }} />
          </a>
          <p className="mod" style={{ marginTop: "10px" }}>
            {t("invoice_adding").toUpperCase()}
          </p>

          <label id="ppp">{t("choose_one").toUpperCase()}</label>
          <div className="" style={{ marginTop: "5px", marginLeft: "-10px" }}>
            <div className="custom-control custom-radio custom-control-inline ">
              <input
                type="radio"
                name="r"
                id="r265"
                defaultChecked
                onClick={this.toggleHie.bind(this)}
              />
              <label htmlFor="r265">{t("plate_number1")}</label>
            </div>
            <div
              class="custom-control custom-radio custom-control-inline"
              style={{ marginLeft: "20px" }}
            >
              <input
                type="radio"
                name="r"
                id="r165"
                onClick={this.toggleHidden.bind(this)}
              />
              <label htmlFor="r165" className="line">
                {t("vin")}
              </label>
            </div>
          </div>

          <form
            noValidate
            autoComplete="off"
            onSubmit={this.handleSubmit}
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            <div className="row">
              <div className="">
                <div className=" col-md-3 " style={{ marginRight: "30px" }}>
                  <CssTextField
                    id="date"
                    label={t("date").toUpperCase()}
                    margin="normal"
                    type="date"
                    name="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.date}
                    onChange={this.onChange}
                  />
                </div>
              </div>

              {this.state.isHidden && (
                <div className="col-md-3 " style={{ marginTop: "30px" }}>
                  <Select
                    name="car_plate_number"
                    // value={this.state.car_plate_number}
                    //onChange={this.onChange}
                    value={selectedOption}
                    onChange={this.handleChange}
                    options={options1}
                    styles={colourStyles}
                    className="Select-menu-outer"
                    placeholder={t("plate_number1")}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,

                      colors: {
                        ...theme.colors,
                        primary25: "#faeded",
                        primary: "#b71c1c",
                      },
                    })}
                  />
                </div>
              )}

              {!this.state.isHidden && (
                <div className="col-md-3 " style={{ marginTop: "30px" }}>
                  <Select
                    name="car_vin"
                    // value={this.state.car_vin.toUpperCase()}
                    //onChange={this.onChange}
                    options={options2}
                    styles={colourStyles}
                    placeholder={t("vin")}
                    value={selectedOption1}
                    onChange={this.handleChange1}
                    className="Select-menu-outer"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,

                      colors: {
                        ...theme.colors,
                        primary25: "#faeded",
                        primary: "#b71c1c",
                      },
                    })}
                  />
                </div>
              )}

              <div className="col-md-3 ">
                <CssTextField
                  id="custom-css-outlined-input"
                  label={t("invoice_number")}
                  margin="normal"
                  name="invoice_number"
                  autoComplete="off"
                  value={this.state.invoice_number}
                  onChange={this.onChange}
                />
              </div>

              <div className="col-md-3 " style={{ marginTop: "11px" }}>
                <label for="exampleInputEmail1" id="ppp"></label>
                <select
                  name="cost_type"
                  value={this.state.cost_type}
                  onChange={this.onChange}
                  class="form-control donn"
                  style={{
                    marginTop: "0px",
                    marginLeft: "-2px",
                    fontSize: "12px",

                    borderBottom: "1px solid black",
                  }}
                >
                  <option value="" disabled selected hidden>
                    {t("invoice_type")}
                  </option>
                  {label.map((c, i) => (
                    <option value={c.value} key={i}>
                      {c.display_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-3 ">
                <CssTextField
                  style={{}}
                  id="custom-css-outlined-input"
                  label={t("Milage1")}
                  margin="normal"
                  name="car_mileage"
                  autoComplete="off"
                  value={this.state.car_mileage}
                  onChange={this.onChange}
                />
              </div>

              <div className="col ">
                <CssTextField
                  style={{}}
                  id="custom-css-outlined-input"
                  label={t("quantity")}
                  margin="normal"
                  name="quantity"
                  type="number"
                  autoComplete="off"
                  value={this.state.quantity}
                  onChange={this.onChange}
                />
              </div>

              <div className="col-md-3 ">
                <CssTextField
                  id="custom-css-outlined-input"
                  label={t("price_netto")}
                  margin="normal"
                  name="net_value"
                  type="number"
                  autoComplete="off"
                  value={this.state.net_value}
                  onChange={this.onChange}
                />
              </div>

              <div className="col-md-3 ">
                <CssTextField
                  id="custom-css-outlined-input"
                  label={t("price_brutto")}
                  margin="normal"
                  name="value"
                  type="number"
                  autoComplete="off"
                  value={this.state.value}
                  onChange={this.onChange}
                />
              </div>
            </div>

            <div style={{ marginTop: "40px" }}>
              <p className="message">{t("description").toUpperCase()} </p>

              <textarea
                name="description"
                value={this.state.description}
                onChange={this.onChange}
                style={{ border: "1px solid rgb(227, 225, 220)" }}
                rows="4"
                cols="70"
                className="form-control"
                maxLength="240"
                data-max="240"
              ></textarea>
              <span
                className="font-weight-normal word_count float-right"
                style={{ position: "relative" }}
              >
                {this.state.description.length}/240
              </span>
            </div>

            <div style={{ marginTop: "130px", marginLeft: "-250px" }}>
              <center>
                <button
                  type="submit"
                  value="Add Invoice"
                  className="btn btn-danger br"
                  style={{ background: "#b71c1c", color: "white" }}
                >
                  {t("add_invoice")}
                </button>
              </center>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AddInvoice);
