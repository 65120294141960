import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Additional from "./Form/Additional";
import UsedCar from "./Form/UsedCar";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../Main";
import { withTranslation } from "react-i18next";
import arrow_left from "../../../img/Aarrow.png";
import deletee from "../../../img/delete.png";
import ProfileTop from "../../../Components/Layout/ProfileTop";

import Cookies from "universal-cookie";
const cookie = new Cookies();
let authToken = cookie.get("Token");

class AddDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      swt: true,
      first_name: "",
      last_name: "",
      employment_date: null,
      location: "",
      phone_number: "",
      email: "",
      gender: "",
      birthday: null,
      claim_count: null,
      driving_license_issued: null,
      assigned_license_plate: "",
      notes: [
        {
          text: ".",
        },
      ],
      cars: [
        {
          since: null,
          until: null,
          car: "",
        },
      ],
      // notes:""
    };
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async onChange1(e, index) {
    if (["text"].includes(e.target.name)) {
      let cats = [...this.state.notes];

      cats[index][e.target.name] = e.target.value;
      await this.setState({
        cats,
      });
    } else {
      await this.setState({ [e.target.name]: e.target.value });
    }
    console.log(this.state.notes);
  }
  async onChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
    console.log(this.state);
  }

  handleRemoveFields = (index) => {
    const values = this.state.notes;
    values.splice(index, 1);
    this.setState({
      values,
    });
  };

  handleSubmit(e) {
    const { t } = this.props;
    const baseUrl = process.env.PUBLIC_URL;
    Swal.fire({
      title: "Loading",
      type: "warning",
      showCancelButton: false,
      showConfirmButton: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    e.preventDefault();
    const data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      employment_date: this.state.employment_date,
      location: this.state.location,
      phone_number: this.state.phone_number,
      email: this.state.email,
      gender: this.state.gender,
      birthday: this.state.birthday,
      claim_count: this.state.claim_count,
      driving_license_issued: this.state.driving_license_issued,
      assigned_license_plate: this.state.assigned_license_plate,
      notes: [...this.state.notes],
      cars: [...this.state.cars],
    };

    axios({
      method: "post",
      url: config.apiUrl.driver,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
      data,
    })
      .then((res) => {
        this.setState({
          first_name: "",
          last_name: "",
          employment_date: null,
          location: "",
          phone_number: "",
          email: "",
          gender: "",
          birthday: null,
          claim_count: null,
          driving_license_issued: null,
          assigned_license_plate: "",
        });

        Swal.fire({
          title: t("single_driver"),
          type: "success",
          text: t("added_success"),
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(function () {
          window.location.href = baseUrl + `/drivermetrics/`;
        }, 2000);
      })
      .catch((reject) => {
        if (reject.response) {
          Swal.fire({
            title: " Error !",
            type: "error",
            text: reject.response.data.email
              ? reject.response.data.email
              : "Claim Count " + reject.response.data.claim_count,
            timer: 2000,
          });
          // console.log("Err", reject.response.data);
        } else {
          console.log("Error", reject.message);
        }
      });
  }

  async onClick(e) {
    await this.setState({
      swt: !this.state.swt,
    });
  }

  render() {
    const { t } = this.props;
    const baseUrl = process.env.PUBLIC_URL;
    return (
      <div className="container1" style={{ marginTop: "30px" }}>
        <Helmet>
          <title>{t("driver_add_edit")}</title>
        </Helmet>

        <ProfileTop />
        <div className="headerr" id="headerr" style={{ marginTop: "-6px" }}>
          <div style={{ padding: "10px" }}>
            <a href={baseUrl + "/drivermetrics/"}>
              <img
                src={arrow_left}
                alt="Left_Arrow"
                style={{ height: "2.6em" }}
              />
            </a>
            &nbsp;&nbsp;
            {t("driver_add_edit").toUpperCase()}&nbsp;
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-sm-6">
                <h3 className="font-weight-bold" style={{ marginTop: "30px" }}>
                  {t("basic_information")}

                  <span
                    className="float-right"
                    style={{
                      // borderBottom: "2px solid  rgb(183, 28, 28, 0.2)",
                      marginTop: "-6px",
                      //marginLeft: "-12px"
                    }}
                  >
                    <label class="switch1">
                      <input
                        type="checkbox"
                        id="togBtn"
                        checked={this.state.swt}
                        onClick={this.onClick.bind(this)}
                        name="notify_critical_overspeeding"
                      />
                      <div class="slider1 round"></div>
                    </label>
                  </span>

                  <p
                    className="float-right"
                    style={{
                      marginRight: "10px",
                      fontsize: "15px",
                      marginTop: "-5px",
                      // borderBottom: "2px solid  rgb(183, 28, 28, 0.2)"
                    }}
                  >
                    {" "}
                    <span>
                      {" "}
                      {!this.state.swt ? `${t("disable")}` : `${t("active")}`}
                    </span>
                  </p>
                </h3>
                <hr></hr>
                <div className="row">
                  <div className="col-sm-5">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("first_name").toUpperCase()}
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        name="first_name"
                        value={this.state.first_name}
                        onChange={this.onChange}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-sm-5">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("surname").toUpperCase()}
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        name="last_name"
                        value={this.state.last_name}
                        onChange={this.onChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-sm-5">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("phone").toUpperCase()}
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        name="phone_number"
                        value={this.state.phone_number}
                        onChange={this.onChange}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-sm-5">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("email").toUpperCase()}
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        name="email"
                        value={this.state.email}
                        onChange={this.onChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <h3 className="font-weight-bold" style={{ marginTop: "30px" }}>
                  {t("demographic_information")}
                </h3>
                <hr></hr>
                <Additional loaddata={this.onChange} vall={this.state} />
              </div>
            </div>

            <div className="carr" style={{ marginTop: "30px" }}>
              <h3 className="font-weight-bold" style={{ marginTop: "30px" }}>
                {t("used_car")}
              </h3>
              <hr></hr>
              <UsedCar loaddata={this.onChange} vall={this.state.cars} />
            </div>

            {this.state.notes.map((inputField, index) => (
              <div
                className="Note"
                style={{ marginTop: "60px" }}
                key={`${inputField}~${index}`}
              >
                <div className="row">
                  <div className="col-sm-6">
                    <label
                      id="p"
                      className="font-weight-bolder"
                      for="exampleInputEmail1"
                    >
                      {t("notes")}
                    </label>
                    <textarea
                      className="form-control"
                      rows="4"
                      col="10"
                      name="text"
                      value={inputField.text}
                      onChange={(e) => {
                        this.onChange1(e, index);
                      }}
                      placeholder={t("type")}
                      maxLength="240"
                      data-max="240"
                    ></textarea>
                    <span
                      className="font-weight-bolder float-right"
                      style={{ position: "relative" }}
                    >
                      {inputField.text.length}/240
                    </span>
                  </div>
                  <button
                    className="btn btn-danger"
                    style={{
                      marginTop: "30px",
                      background: "none",
                      border: "none",
                    }}
                    type="button"
                    onClick={() => this.handleRemoveFields(index)}
                  >
                    <img src={deletee} alt="Delete" />
                  </button>
                </div>
              </div>
            ))}

            <center>
              <button
                className="btn btn-danger br text-white"
                value="create campaign"
                style={{ background: "#b71c1c" }}
              >
                {t("add_driver")}
              </button>
            </center>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AddDriver);
