import React from "react";
import { useTranslation } from "react-i18next";

function ClaimDetail(props) {
  const { t } = useTranslation();
  return (
    <div>
      <div className="row">
        <div className="col-md-2">
          {" "}
          <h3 className="font-weight-normal" style={{ marginTop: "30px" }}>
            {t("car_details")}
          </h3>
          <hr></hr>
          <div className="col">
            <p id="ppp"> {t("plate_number1").toUpperCase()}</p>
            <p className="pp">
              {props.data.car_plate_number
                ? props.data.car_plate_number
                : props.data.car_vin_number}{" "}
            </p>
          </div>
          <div className="col">
            <p id="ppp">{t("vin").toUpperCase()}</p>
            <p className="pp">
              {" "}
              {props.data.car_vin
                ? props.data.car_vin
                : props.data.other_car_vin_number}{" "}
            </p>
          </div>
          <div className="col">
            <p id="ppp"> {t("policy_number").toUpperCase()}</p>
            <p className="pp">
              {" "}
              {props.data.documents_number
                ? props.data.documents_number
                : "-"}{" "}
            </p>
          </div>
        </div>
        <div className="col-md-5">
          <h3 className="font-weight-normal" style={{ marginTop: "30px" }}>
            Driver Details{" "}
          </h3>
          <hr></hr>
          <div className="row">
            <div className="col-md-4">
              <p id="ppp"> {t("driver_name").toUpperCase()}</p>
              <p className="pp">
                {" "}
                {props.data.driver_name ? props.data.driver_name : "-"}
              </p>
            </div>
            <div className="col-md-4">
              <p id="ppp"> {t("driver_licence_number").toUpperCase()}</p>
              <p className="pp">
                {" "}
                {props.data.driving_licence_number
                  ? props.data.driving_licence_number
                  : "-"}{" "}
              </p>
            </div>
            <div className="col-md-4">
              <p id="ppp"> {t("pesel_number").toUpperCase()}</p>
              <p className="pp">
                {" "}
                {props.data.pesel_number ? props.data.pesel_number : "-"}{" "}
              </p>
            </div>

            <div className="col-md-4">
              <p id="ppp"> {t("address").toUpperCase()}</p>
              <p className="pp">
                {props.data.address ? props.data.address : "-"}{" "}
              </p>
            </div>

            <div className="col-md-4">
              <p id="ppp"> {t("email").toUpperCase()}</p>
              <p className="pp">{props.data.email ? props.data.email : "-"} </p>
            </div>

            <div className="col-md-4">
              <p id="ppp"> {t("phone").toUpperCase()}</p>
              <p className="pp">
                {" "}
                {props.data.phone_number ? props.data.phone_number : "-"}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <h3 className="font-weight-normal" style={{ marginTop: "30px" }}>
            {t("car_owner_details")}
          </h3>
          <hr></hr>
          <div className="row">
            <div className="col-md-4">
              <p id="ppp"> {t("car_owner_name").toUpperCase()}</p>
              <p className="pp">
                {" "}
                {props.data.car_owner_number
                  ? props.data.car_owner_number
                  : "-"}{" "}
              </p>
            </div>

            <div className="col-md-4">
              <p id="ppp"> {t("pesel_number").toUpperCase()}/NIP</p>
              <p className="pp">
                {props.data.nip_number ? props.data.nip_number : "-"}{" "}
              </p>
            </div>
            <div className="col-md-4">
              <p id="ppp"> {t("email").toUpperCase()}</p>
              <p className="pp">
                {" "}
                {props.data.company_email ? props.data.company_email : "-"}
              </p>
            </div>

            <div className="col-md-4">
              <p id="ppp"> {t("phone").toUpperCase()}</p>
              <p className="pp">
                {props.data.company_phone ? props.data.company_phone : "-"}{" "}
              </p>
            </div>
            <div className="col-md-4">
              <p id="ppp"> {t("address").toUpperCase()}</p>
              <p className="pp">
                {" "}
                {props.data.car_owner_address
                  ? props.data.car_owner_address
                  : "-"}{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClaimDetail;
