import React, { Component } from "react";
//import ReactToExcel from "react-html-table-to-excel";
import DriverReport from "../DriverReport";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import arrow from "../../../img/arrow.png";
import TableExport from "tableexport";
import "../index";

class DriverReportt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: [],
      value: "select",
      movie: this.props.location
    };
  }

  handledownload() {
    TableExport(document.getElementsByTagName("table"), {
      headers: true, // (Boolean), display table headers (th or td elements) in the <thead>, (default: true)
      footers: true, // (Boolean), display table footers (th or td elements) in the <tfoot>, (default: false)
      formats: ["xlsx", "csv"], // (String[]), filetype(s) for the export, (default: ['xlsx', 'csv', 'txt'])
      filename: "Driver-Report", // (id, String), filename for the downloaded file, (default: 'id')
      // bootstrap: true, // (Boolean), style buttons using bootstrap, (default: true)
      exportButtons: true, // (Boolean), automatically generate the built-in export buttons for each of the specified formats (default: true)
      position: "bottom", // (top, bottom), position of the caption element relative to table, (default: 'bottom')
      // ignoreRows: null,                   // (Number, Number[]), row indices to exclude from the exported file(s) (default: null)
      // ignoreCols: null,                   // (Number, Number[]), column indices to exclude from the exported file(s) (default: null)
      trimWhitespace: false // (Boolean), remove all leading/trailing newlines, spaces, and tabs from cell text in the exported file(s) (default: false)
      // RTL: true // (Boolean), set direction of the worksheet to right-to-left (default: false)
      // sheetname: "id"                     // (id, String), sheet name for the exported spreadsheet, (default: 'id')
    });
  }

  change(event) {
    this.setState({
      value: event.target.value
    });
  }

  compareByAsc(key) {
    return function(a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByDesc(key) {
    return function(a, b) {
      if (a[key] < b[key]) return 1;
      if (a[key] > b[key]) return -1;
      return 0;
    };
  }

  sortBy(key) {
    let arrayCopy = [...this.props.location.state.movie[0]];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(this.compareByAsc(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(this.compareByDesc(key));
    }
    //const filter =  this.props.location.state.movie
    this.setState({
      ...(this.props.location.state.movie[0] = arrayCopy)
    });
  }

  render() {
    const { t } = this.props;

    const don = this.props.location.state.selectOptions1;

    return (
      <div className="" style={{ marginTop: "50px" }}>
        <DriverReport />
        <Helmet>
          <title>Driver Report </title>
        </Helmet>
        <div className="headerr" style={{ marginTop: "25px" }}>
          <h6>{t("raport").toUpperCase()}</h6>
          <p className="p">
            {t("from")} {this.props.location.state.lol} {t("to")}{" "}
            {this.props.location.state.fol}
          </p>

          <div className="driver report caaar">
            <table id="table-to-xls" className="table table-hover">
              <p className="p" hidden>
                {t("from")} {this.props.location.state.lol} &nbsp; {t("to")}{" "}
                &nbsp;
                {this.props.location.state.fol}
              </p>

              <thead>
                {/* {don.length > 0 && (
                  <tr dangerouslySetInnerHTML={{ __html: text1 }} />
                )} */}

                <tr className="th ">
                  <th className="  sorttable_nosort" style={{ width: "5%" }}>
                    {t("Id")}
                  </th>
                  <th onClick={() => this.sortBy("name")}>
                    {t("Name")}{" "}
                    <img src={arrow} alt="Arrow" style={{ height: "0.9em" }} />{" "}
                  </th>
                  {don.includes("1") || don.includes("0") ? (
                    <th
                      className=""
                      onClick={() => this.sortBy("carquanttity")}
                    >
                      {t("NOCar")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("2") || don.includes("0") ? (
                    <th className="" onClick={() => this.sortBy("milage")}>
                      {t("Milage")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("3") || don.includes("0") ? (
                    <th
                      className=""
                      onClick={() => this.sortBy("fuel_consumed")}
                    >
                      {t("FuelComsumed")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("4") || don.includes("0") ? (
                    <th
                      className=""
                      onClick={() => this.sortBy("average_fuel_consumed")}
                    >
                      {t("AverageFuel")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("5") || don.includes("0") ? (
                    <th
                      className=""
                      onClick={() => this.sortBy("overspeeding_distance")}
                    >
                      {t("OverspeedingDistance")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("6") || don.includes("0") ? (
                    <th
                      className=""
                      onClick={() => this.sortBy("critical_overspeed")}
                    >
                      {t("CriticalSpeed")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("7") || don.includes("0") ? (
                    <th className="">
                      <select
                        // name="value"
                        onChange={this.change.bind(this)}
                        value={this.state.value}
                        style={{
                          position: "relative",
                          top: "0px",
                          width: "130px"
                        }}
                        class="minimal"
                      >
                        <option value="1"> {t("OverallScore")} </option>
                        <option value="2">{t("OverallSpeed")} </option>
                        <option value="3"> {t("OverallDynamic")} </option>
                        <option value="4"> {t("OverallEfficiency")} </option>
                      </select>

                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                        onClick={() =>
                          this.state.value === "1"
                            ? this.sortBy("overallscore")
                            : this.state.value === "2"
                            ? this.sortBy("overallspeedscore")
                            : this.state.value === "3"
                            ? this.sortBy("overalldynamicscore")
                            : this.state.value === "4"
                            ? this.sortBy("overallefficienyscore")
                            : this.sortBy("overallscore")
                        }
                      />
                    </th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
            
              <tbody>

                {this.props.location.state.movie[0].map((c, i) => (
                  <tr key={c.id}>
                    <td>{i + 1}</td>

                    <td
                      style={{ color: "#b71c1c" }}
                      className="font-weight-bolder"
                    >
                      {c.name ? `${c.name}` : " ? "}
                    </td>
                    {don.includes("1") || don.includes("0") ? (
                      <td>{c.carquanttity ? `${c.carquanttity}` : "-"} </td>
                    ) : (
                      ""
                    )}
                    {don.includes("2") || don.includes("0") ? (
                      <td>{c.milage ? `${c.milage.toFixed(1)}` : "-"} </td>
                    ) : (
                      ""
                    )}
                    {don.includes("3") || don.includes("0") ? (
                      <td>
                        {c.fuel_consumed
                          ? `${c.fuel_consumed.toFixed(1)}`
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}
                    {don.includes("4") || don.includes("0") ? (
                      <td>
                        {c.average_fuel_consumed
                          ? `${c.average_fuel_consumed.toFixed(1)}`
                          : "-"}{" "}
                      </td>
                    ) : (
                      ""
                    )}
                    {don.includes("5") || don.includes("0") ? (
                      <td>
                        {c.overspeeding_percentage
                          ? `${c.overspeeding_percentage.toFixed(1)}`
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}
                    {don.includes("6") || don.includes("0") ? (
                      <td>
                        {c.critical_overspeed
                          ? `${c.critical_overspeed.toFixed(1)}`
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}
                    {don.includes("7") || don.includes("0") ? (
                      <td>
                        {this.state.value === "1"
                          ? `${
                              c.overallscore
                                ? `${c.overallscore.toFixed(1)}`
                                : 0.0
                            }`
                          : `${
                              this.state.value === "2"
                                ? `${
                                    c.overallspeedscore
                                      ? `${c.overallspeedscore.toFixed(1)}`
                                      : 0.0
                                  }`
                                : `${
                                    this.state.value === "3"
                                      ? `${
                                          c.overalldynamicscore
                                            ? `${c.overalldynamicscore.toFixed(
                                                1
                                              )}`
                                            : 0.0
                                        }`
                                      : `${
                                          this.state.value === "4"
                                            ? `${
                                                c.overallefficienyscore
                                                  ? `${c.overallefficienyscore.toFixed(
                                                      1
                                                    )}`
                                                  : 0.0
                                              }`
                                            : `${
                                                c.overallscore
                                                  ? `${c.overallscore.toFixed(
                                                      1
                                                    )}`
                                                  : 0.0
                                              }`
                                        }`
                                  }`
                            }`}

                        {/* {c.overallscore ? `${c.overallscore.toFixed(1)}` : 0} */}
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ))}
              </tbody>

              <tfoot>
                <tr className="thead sorttable_nosort">
                  <th className="sorttable_nosort"></th>

                  <th className="sorttable_nosort">
                    {" "}
                    {t("Summary1").toUpperCase()}
                  </th>
                  {don.includes("1") || don.includes("0") ? (
                    <th className="sorttable_nosort">
                      {this.props.location.state.movie[1].carquantity
                        ? `${this.props.location.state.movie[1].carquantity}`
                        : "-"}
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("2") || don.includes("0") ? (
                    <th className="sorttable_nosort">
                      {this.props.location.state.movie[1].milage
                        ? `${this.props.location.state.movie[1].milage.toFixed(
                            1
                          )}`
                        : "-"}
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("3") || don.includes("0") ? (
                    <th className="sorttable_nosort">
                      {this.props.location.state.movie[1].fuel_consumed
                        ? `${this.props.location.state.movie[1].fuel_consumed.toFixed(
                            1
                          )}`
                        : "-"}{" "}
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("4") || don.includes("0") ? (
                    <th className="sorttable_nosort">
                      {this.props.location.state.movie[1].average_fuel_consumed
                        ? `${this.props.location.state.movie[1].average_fuel_consumed.toFixed(
                            1
                          )}`
                        : "-"}
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("5") || don.includes("0") ? (
                    <th className="sorttable_nosort">
                      {this.props.location.state.movie[1]
                        .overspeeding_percentage
                        ? `${this.props.location.state.movie[1].overspeeding_percentage.toFixed(
                            1
                          )}`
                        : "-"}
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("6") || don.includes("0") ? (
                    <th className="sorttable_nosort">
                      {this.props.location.state.movie[1].critical_overspeed
                        ? `${this.props.location.state.movie[1].critical_overspeed.toFixed(
                            1
                          )}`
                        : "-"}
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("7") || don.includes("0") ? (
                    <th className="sorttable_nosort">
                      {this.state.value === "1"
                        ? `${
                            this.props.location.state.movie[1].overallscore
                              ? `${this.props.location.state.movie[1].overallscore.toFixed(
                                  1
                                )}`
                              : " - "
                          }`
                        : `${
                            this.state.value === "2"
                              ? `${
                                  this.props.location.state.movie[1]
                                    .overallspeedscore
                                    ? `${this.props.location.state.movie[1].overallspeedscore.toFixed(
                                        1
                                      )}`
                                    : " - "
                                }`
                              : `${
                                  this.state.value === "3"
                                    ? `${
                                        this.props.location.state.movie[1]
                                          .overalldynamicscore
                                          ? `${this.props.location.state.movie[1].overalldynamicscore.toFixed(
                                              1
                                            )}`
                                          : " - "
                                      }`
                                    : `${
                                        this.state.value === "4"
                                          ? `${
                                              this.props.location.state.movie[1]
                                                .overallefficienyscore
                                                ? `${this.props.location.state.movie[1].overallefficienyscore.toFixed(
                                                    1
                                                  )}`
                                                : " - "
                                            }`
                                          : `${
                                              this.props.location.state.movie[1]
                                                .overallscore
                                                ? `${this.props.location.state.movie[1].overallscore.toFixed(
                                                    1
                                                  )}`
                                                : " - "
                                            }`
                                      }`
                                }`
                          }`}
                    </th>
                  ) : (
                    ""
                  )}
                </tr>
              </tfoot>
            </table>

            <div style={{ marginTop: "40px" }}>
              <center>
                <input
                  type="button"
                  className="btn btn-success btn-lg bb"
                  // id="btnExport"
                  onClick={this.handledownload.bind(this)}
                  value={t("export_report")}
                />

                <a className="btn" href="#driver" rel="modal:open" id="bttt">
                  {t("new_report")}
                </a>
              </center>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(DriverReportt);
