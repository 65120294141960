import React, { useState, useEffect } from "react";
import config from "../../../../Main";
import Spinner from "../../../../../Container/Spinner";
import { useTranslation } from "react-i18next";

function CarCount4(props) {
  const [car, setCar] = useState([]);
  const [loading, setLoading] = useState(true);

  const parameter_only_lte = props.parameter
    .slice(4, 5)
    .map((c) =>
      c.parameter_detail
        .map(
          (d) =>
            `${d.segment_parameter}__lte=${
              d.min_value === null ? "" : parseFloat(d.min_value).toFixed(1)
            }`
        )
        .join("&")
    );

  const parameter_only_gte = props.parameter
    .slice(4, 5)
    .map((c) =>
      c.parameter_detail
        .map(
          (d) =>
            `${d.segment_parameter}__gte=${
              d.max_value === null ? "" : parseFloat(d.max_value).toFixed(1)
            }`
        )
        .join("&")
    );

  const from_to = props.parameter
    .slice(4, 5)
    .map((c) => `date__lte=${c.to_date}&date__gte=${c.from_date}`);
  useEffect(() => {
    localeData();
    // eslint-disable-next-line
  }, [car]);

  const localeData = async () => {
    const res = await fetch(
      `${config.apiUrl1.myDriver}?${from_to}&${parameter_only_lte}&${parameter_only_gte}`,
      config.head
    );
    const carCount = await res.json();
    setCar(carCount);
    setLoading(false);
  };

  const { t } = useTranslation();
  const base = process.env.PUBLIC_URL;

  return (
    <span>
      {loading === true ? (
        <Spinner />
      ) : props.parameter.length > 5 ? (
        props.parameter.slice(4, 5).map((c) => (
          <div className="box  driver_segemnt " id="driver_segemnt">
            <div className="fleet_data">
              <a href={base + `/view/segment/recommendation/driver/${c.id}/`}>
                <p
                  id=" driver_segement_text"
                  className="text-center segment_text "
                >
                  {c.segment_title}
                  <br></br>
                  <h1 className="fleet_title text-center">{car.length}</h1>
                </p>
              </a>

              <div className="row  text-section "></div>
            </div>
          </div>
        ))
      ) : (
        <div className="box  driver_segemnt " id="driver_segemnt">
          <div className="fleet_data">
            <a href={base + "/segment/recommendation/driver/"}>
              <p
                id=" driver_segement_text"
                className="text-center segment_text "
              >
                {t("create_segment")}
                <br></br>
              </p>
            </a>
          </div>
        </div>
      )}
    </span>
  );
}

export default CarCount4;
