import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { getRemoveToken } from "../../Service/Revoke";
import { withRouter } from "react-router-dom";
const cookie = new Cookies();
const LogoutPopup = () => {
  const [signoutTime, setSignoutTime] = useState(1800000);
  const [warningTime, setWarningTime] = useState(1500000);
  let warnTimeout;
  let logoutTimeout;

  const warn = () => {
    Swal.fire({
      title: "Your session in going to expire soon ",
      type: "warning",
      html: "Session will expire <b>30</b> sec.",
      // text: "Session will expire in 30 sec",
      showConfirmButton: false,
      timer: 3000,
    });
  };
  const logout = () => {
    getRemoveToken();
    Swal.fire({
      title: "Logged out ",
      type: "success",
      text: "You have been loged out",
      showConfirmButton: false,
      timer: 3000,
    });
    const base = process.env.PUBLIC_URL;
    localStorage.removeItem("fleet");
    cookie.remove("Token", { path: "/" });
    window.location.href = base + "/";
    window.location.reload();
  };

  const setTimeouts = () => {
    warnTimeout = setTimeout(warn, warningTime);
    logoutTimeout = setTimeout(logout, signoutTime);
  };

  const clearTimeouts = () => {
    if (warnTimeout) clearTimeout(warnTimeout);
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  // function TrainingTime(totalSeconds) {
  //   totalSeconds %= 3600;
  //   let minutes = Math.floor(totalSeconds / 60);
  //   let seconds = totalSeconds % 60;
  //   return `${minutes} : ${seconds}`;
  // }

  var count = -1;
  const deadTime = Math.floor(signoutTime / 1000);
  function beginTimer() {
    count++;

    if (count < deadTime) {
      // var don = deadTime - count;
      // var minutes = TrainingTime(don);
      // document.getElementById("demo").innerHTML = minutes;
      setTimeout(beginTimer, 2000);
    } else {
      endTimer();
    }
  }

  function endTimer() {
    console.log("Time is finished");
  }

  useEffect(() => {
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
      "wheel",
      "keydown",
    ];

    beginTimer();

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };
    setSignoutTime();
    setWarningTime();

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();
    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <h5 id="demo" className="text-white">
        {" "}
      </h5>
      {/* <li class=" navbar-nav nav-item dropdown" style={{ marginTop: "-7px" }}>
        <h1></h1>
      </li> */}
    </div>
  );
};
export default withRouter(LogoutPopup);
