import React, { Component } from "react";
import axios from "axios";
import { UserMessage } from "../../Service/Arrow/Message/UserMessage";
import config from "../../views/Main";
import Language from "../Layout/Language";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Cookies from "universal-cookie";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { getRemoveToken } from "../../Service/Revoke";

import "react-lazy-load-image-component/src/effects/blur.css";
import "./Auth.css";
var CLIENT_ID = "DigitalFleet-Dashboard";
var GRANT_TYPE = "password";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#1B1B23",
      opacity: "0.6",
      fontSize: "15px",
      letterSpacing: "0.2px",
    },
    "& label": {
      marginLeft: "5px",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#B71C1C",
      outline: 0,
    },

    "& .MuiInput-underline:before": {
      borderBottomColor: "#B71C1C",
    },
    "& .MuiInput-underline:hover": {
      borderBottomColor: "#B71C1C",
    },
  },
})(TextField);
export class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      showPassword: false,
      failed: 0,
      loading: false,
      token: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  handleMouseDownPassword(e) {
    e.preventDefault();
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  componentDidMount() {
    this.redirect();
  }

  handleSubmit(e) {
    const { t } = this.props;
    this.setState({ loading: true });
    e.preventDefault();
    // make request in order to get the TOKEN
    const mailformat = RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
    const isEnabled = this.state.username.match(mailformat);
    if (isEnabled) {
      axios({
        method: "post",
        url: config.apiUrl.login,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: `grant_type=${GRANT_TYPE}&username=${this.state.username}&password=${this.state.password}&client_id=${CLIENT_ID}`,
      })
        .then((response) => {
          this.setState({ loading: false });
          const token = response.data.access_token;
          var ls = require("local-storage");
          ls.set("passwordAge", response.data["password_age"]);
          // Check that user get the token is correct or not
          this.getRightToken(
            response.data.access_token,
            response.data["first_login"]
          );
        })
        .catch((reject) => {
          this.setState({ loading: false });
          UserMessage(
            "Login not successfull",
            reject.response.data.message
              ? reject.response.data.message
              : reject.response.data.error_description,
            "error"
          );
        });
    } else {
      UserMessage(t("login_error"), "Please type correct email id", "warning");
    }
  }

  change() {
    const base = process.env.PUBLIC_URL;
    this.cookies = new Cookies();
    if (this.cookies.get("Token")) {
      this.props.history.push(base + "/change/password/");
      window.location.reload();
    }
  }

  async getRightToken(token, first) {
    const cookies = new Cookies();
    const base = process.env.PUBLIC_URL;
    await fetch(`https://digitalfleet.eu/api/1/users/accesstoken/${token}/`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.detail) {
          getRemoveToken();
          this.props.history.push(base + "/");
        } else {
          axios({
            method: "get",
            url: config.apiUrl.my,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          })
            .then((response2) => {
              this.setState({ loading: false });
              // if user has access to Digital Fleet then give him a TOKEN
              if (response2.data.access_digitalfleet) {
                cookies.set("Token", data.token, {
                  path: "/",
                  maxAge: 36000,
                });
                UserMessage(
                  "Log In successfull",
                  "You have successfully logged in",
                  "success"
                );

                if (first === true) {
                  this.change();
                } else {
                  this.redirect();
                }
              } else {
                this.setState({ loading: false });
                // if user is not authorized, then show him a message
                UserMessage(
                  "Login not successfull",
                  "Sorry, you do not have access rights to Digital fleet",
                  "error"
                );
              }
            })
            .catch((reject) => {
              this.setState({ loading: false });
              UserMessage(
                "Login not successfull",
                reject.response.data.message
                  ? reject.response.data.message
                  : reject.response.data.error_description,
                "error"
              );
            });

          this.setState({ token: data.token });
          console.log(this.state.token);
        }
      })
      .catch((reject) => console.log(reject));
  }

  redirect() {
    const base = process.env.PUBLIC_URL;
    const cookies = new Cookies();
    if (cookies.get("Token")) {
      // if (
      //   localStorage.getItem("path") === null ||
      //   localStorage.getItem("path") === "/app" ||
      //   localStorage.getItem("path") === "/app/" ||
      //   localStorage.getItem("path") === ""
      // ) {
      //   this.props.history.push(base + "/fleet/dashboard/");
      // } else {
      //   this.props.history.push(localStorage.getItem("path"));
      // }

      this.props.history.push(base + "/fleet/dashboard/");
      window.location.reload();
    }
  }

  render() {
    const { t } = this.props;
    const { username, password } = this.state;
    const isEnabled = username.length > 0 && password.length > 0;
    const baseUrl = process.env.PUBLIC_URL;
    return (
      <section className="login-block ">
        <Helmet>
          <title>DigitalFleet Login</title>
          <meta name="description" content="Digialfleet app login page  " />
        </Helmet>

        <div className="main_login">
          <form
            autoComplete="off"
            onSubmit={this.handleSubmit}
            style={{ margin: "auto" }}
          >
            <div className="image_login">
              <div
                style={{
                  float: "left",
                  zIndex: "100",
                  left: "70%",
                  top: "0%",
                  position: "relative",
                }}
              >
                <Language />
              </div>
              <img
                src="https://storage.waw.cloud.ovh.net/v1/AUTH_9c3bbac620464ace851c819300f9c925/digitalfleet-media/Logo.png"
                alt="Digital fleet"
                className="image_app"
              />
            </div>
            <div className="form-group">
              <CssTextField
                style={{
                  width: "100%",
                }}
                id="custom-css-outlined-input"
                label={t("Login")}
                margin="normal"
                name="username"
                autoComplete="off"
                value={this.state.username}
                onChange={this.handleChange}
              />
            </div>
            <div className="form-group" style={{ marginTop: "-25px" }}>
              <CssTextField
                style={{
                  width: "100%",
                  marginTop: "40px",
                }}
                id="standard-adornment-password"
                label={t("Password")}
                className="lol"
                type={this.state.showPassword ? "text" : "password"}
                margin="normal"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
              />
              <span
                className="full"
                aria-label="toggle password visibility"
                onClick={this.handleClickShowPassword}
                onMouseDown={this.handleMouseDownPassword}
              >
                {this.state.showPassword ? (
                  <Visibility className="opp" />
                ) : (
                  <VisibilityOff className="opp" />
                )}
              </span>
            </div>
            <p className="forget">
              <a
                href={baseUrl + "/forgetpassword"}
                style={{ color: "#b71c1c", opacity: "0.8" }}
              >
                {t("Forgetpassword")}?
              </a>
            </p>

            <button
              type="submit"
              value="Login"
              disabled={!isEnabled}
              className="btn btn-login  text-white font-weight-bolder boxx "
            >
              {this.state.loading ? "Loading..." : t("login_button")}
            </button>
          </form>
        </div>

        <div className="Main_section1 fix_top">
          <div class="wrapper">
            <section class="link">
              <div className="logos">
                <h4 className="question">
                  Questions?
                  <a
                    href="mailto: contact@digitalfleet.eu"
                    className="email_data"
                  >
                    contact@digitalfleet.eu
                  </a>
                </h4>
              </div>
              <div class="sub-links">
                <ul>
                  <li>
                    <a href={baseUrl + "/faq/"}>FAQ</a>
                  </li>
                  <li>
                    <a href={baseUrl + "/corporate/information/"}>
                      Corporate information
                    </a>
                  </li>
                  <li>
                    <a href={baseUrl + "/termsofuse/"}>Terms of use</a>
                  </li>
                  <li>
                    <a href={baseUrl + "/privacy/statement/"}>
                      Privacy statement
                    </a>
                  </li>
                  <li>
                    <a href={baseUrl + "/contact/us/"}>Contact us</a>
                  </li>
                </ul>
              </div>
            </section>

            <footer>
              <p>&Copy 2019-{new Date().getFullYear()} DigitalFleet, Inc.</p>
              <p>Viva Drive & Copy {new Date().getFullYear()}</p>
            </footer>
          </div>
        </div>

        {/*  */}
      </section>
    );
  }
}

export default withTranslation()(Auth);
