import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Filter from "../../Filter";
import Insurance from "../../../../Components/Layout/Insurance";
import Temprature from "./Chart/Temprature";
import Familiarty from "./Chart/Familiarty";
import ProfileTop from "../../../../Components/Layout/ProfileTop";
import User from "../../../../Components/User";
import Datee from "../../../Views/Date";
import { withTranslation } from "react-i18next";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { getInsuranceProfile } from "../../../../Service/Company";
import DateGte from "../../../../Service/Arrow/DateGte";
import DateLte from "../../../../Service/Arrow/DateL";
import arrowL from "../../../../Service/Arrow/Arrow";
import Spinner from "../../../../Container/Spinner";
const moment = extendMoment(originalMoment);
export class InsuranceProfile extends Component {
  constructor(props) {
    super(props);
    const today = moment();
    this.state = {
      todos: [],
      profile: [],
      basic: [],
      profile1: [],
      basic1: [],
      road: [],
      country: [],
      weather: [],
      time: [],
      temp: [],
      drive: [],
      family: [],
      chart: [],
      isHidden: true,
      lol: null,
      fol: null,
      isOpen: false,
      today: null,
      neww: [],
      isLoading: true,
      value: moment.range(today.clone().subtract(0, "days"), today.clone()),
    };
  }

  onToggle = (event) => {
    if (event.target.value === "date") {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  getData(value, states) {
    getInsuranceProfile(
      value.end.format("YYYY-MM-DD"),
      value.start.format("YYYY-MM-DD")
    ).then((data) => {
      this.setState({
        todos: data,
        basic: data.widgets,
        profile: data.basic_metrics,
        chart: data.charts,
        lol: value.start,
        fol: value.end,
        isOpen: !this.state.isOpen,
        isLoading: false,
      });
    });
  }

  InsuranceProfile() {
    getInsuranceProfile(
      DateGte(this.state.today),
      DateLte(this.state.today)
    ).then((data) => {
      this.setState({
        todos: data,
        basic: data.widgets,
        profile: data.basic_metrics,
        chart: data.charts,
        isLoading: false,
      });
    });
  }

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.InsuranceProfile("");
  };

  async componentDidMount() {
    this.InsuranceProfile("");
  }

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  render() {
    const { t } = this.props;
    const { profile, basic, chart } = this.state;

    const pie = chart.filter((c) => c.type === "pie-chart");
    const bar = chart.filter((c) => c.type === "bar-chart");
    return (
      <div className="Main_section">
        <Helmet>
          <title>Company Profile Data</title>
        </Helmet>

        <div>
          &nbsp;{" "}
          <div
            className="row row1"
            style={{
              width: "100%",
              marginTop: "-40px",
              marginBottom: "-40px",
            }}
          >
            {" "}
            <div className="col-sm-3 date_time">
              <strong>
                {t("Hello")}, <User />
              </strong>
              <Datee />
            </div>
            <div className="col-sm-6 date_time">
              <Filter
                lol={this.state.lol}
                fol={this.state.fol}
                today={this.state.today}
                option={this.state.isOpen}
                toggle={this.onToggle}
                change={this.getData}
                hello={this.onChange}
              />
            </div>
          </div>
          {window.location.pathname === "/data/comp/" ? (
            <ProfileTop />
          ) : (
            <Insurance />
          )}
        </div>

        <div className="headerr" style={{ marginTop: "-6px" }}>
          {this.state.isLoading === true ? (
            <Spinner />
          ) : (
            <div>
              <div
                className="row  justify-content-center"
                style={{ width: "100%", paddingLeft: "0px" }}
              >
                {basic.map((ii, i) => (
                  <div
                    className="col-md-2 something_image"
                    style={{
                      marginTop: "5px",

                      backgroundImage: `url(${ii.background})`,
                    }}
                    key={i + 1}
                  >
                    <div
                      className="box  "
                      style={{
                        width: "200px",
                        marginTop: "25px",
                        height: "132px",
                      }}
                    >
                      <div className="fleet_data">
                        <p id="fleet_main_title" className="text-left">
                          {ii.label ? ii.label : "-"}
                        </p>

                        <div className="row  text-section ">
                          <div
                            className={
                              ii.icon === null ? "col-md-12" : "col-md-5"
                            }
                          >
                            <h1 className="fleet_title">
                              {" "}
                              {ii.value ? ii.value : "-"}
                            </h1>

                            <p style={{ marginTop: "-10px" }}>
                              {arrowL(
                                ii.changeDirection,
                                ii.changePositive,
                                ii.change
                              )}
                            </p>
                          </div>
                          <div
                            className={ii.icon === null ? "col" : "col-md-7"}
                          >
                            {ii.icon !== null && (
                              <img
                                src={ii.icon ? ii.icon : ""}
                                alt="weight"
                                className="fleet_image"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* More Information */}
              <div>
                <h3
                  className="font-weight-normal"
                  style={{ marginTop: "70px" }}
                >
                  {t("more_information")}
                </h3>
                <hr></hr>

                <div className="row" style={{ marginTop: "20px" }}>
                  {profile.map((c, i) => (
                    <div className="col-sm-3" key={i + 1}>
                      <p id="pppp">{c.label.toUpperCase()}</p>
                      <p className="pppp1">{c.value ? `${c.value}` : "-"}</p>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                {" "}
                <h3
                  className="font-weight-normal"
                  style={{ marginTop: "50px" }}
                >
                  {t("static")}
                </h3>
                <hr></hr>
                <div>
                  <div className="row" style={{ marginTop: "40px" }}>
                    {" "}
                    {chart.length > 1
                      ? bar.map((c, i) => (
                          <div className="col-md-4" key={i + 1}>
                            <Temprature energy={c} />{" "}
                          </div>
                        ))
                      : ""}
                  </div>
                </div>
                <div>
                  <div className="row" style={{ marginTop: "40px" }}>
                    {" "}
                    {chart.length > 1
                      ? pie.map((c, i) => (
                          <div
                            className="col-md-4 "
                            key={i + 1}
                            style={{ marginTop: "20px" }}
                          >
                            <Familiarty energy={c} />{" "}
                          </div>
                        ))
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(InsuranceProfile);
