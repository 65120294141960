import config from "../views/Main";
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "universal-cookie";
const cookie = new Cookies();
let authToken = cookie.get("Token");

export async function addClaim(product) {
  return axios({
    method: "POST",
    url: config.apiUrl.claim,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + authToken,
    },
    data: product,
  })
    .then((res) => {
      if (!res.ok) {
        Swal.fire({
          title: "Claim Added",
          type: "success",
          text: "Added claim successful!",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(function () {
          window.location.href = "/app/claim/insurance/";
        }, 2000);
      } else {
        console.log("");
      }
      return res.json();
    })
    .catch((err) => {
      if (err.response) {
        Swal.fire({
          title: " Error !",
          type: "error",
          text: err.response.data.status
            ? "Status " + err.response.data.status
            : "Error while adding campaignn",
          timer: 2000,
        });
        console.log("Err", err.response.data);
      } else {
        console.log("Error", err.message);
      }
    });
}

export async function editClaim(id, product) {
  return axios({
    method: "PUT",
    url: `${config.apiUrl.claim}${id}/`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + JSON.parse(authToken),
    },
    data: product,
  })
    .then((res) => {
      if (!res.ok) {
        Swal.fire({
          title: "Claim updated",
          type: "success",
          text: "updated claim successful!",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(function () {
          window.location.href = "/app/view/claim/insurance/" + id + "/";
        }, 2000);
      } else {
        Swal.fire({
          title: "Error",
          type: "error",
          text: "Error while add claim",
          showConfirmButton: false,
          timer: 2000,
        });
      }
      return res.json();
    })
    .catch((err) => console.log("error"));
}

export async function getClaim(to, from, status, type, car) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.claim}?date__lte=${to ? to : ""}&date__gte=${
        from ? from : ""
      }&status=${status}&type_ac_oc=${
        type ? type : ""
      }&car__license_plate__icontains=${car ? car : ""}`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getClaimDetail(id) {
  let products = new Promise((resolve, reject) => {
    fetch(`${config.apiUrl.claim}${id}/`, config.head)
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}
