import $ from "jquery";

$(document).ready(function () {
  $("#button").click(function (e) {
    e.stopPropagation();
    $("#driver").hide(function () {
      window.top.close();
    });
  });
});
