import React from "react";
import not from "../../../img/404.png";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import "./NotFound.css";

class NotFoundPage extends React.Component {
  render() {
    const { t } = this.props;
    const base = process.env.PUBLIC_URL;
    return (
      <div className="row not_found_lol" style={{ width: "100%" }}>
        <div className="col-md-5">
          <p class="not_oops" style={{ textAlign: "center" }}>
            <h1 className="not_title">Oops!</h1>
            <p className="not_text">{t("not_found")}</p>
            <Link
              to={base + "/fleet/dashboard/"}
              className="btn text-white font-weight-bold btn_main"
              style={{ padding: "8px", background: "#B71C1C" }}
            >
              {t("Dashboard")}{" "}
            </Link>
          </p>
        </div>
        <div className="col-md-7">
          <img class="not_found" src={not} alt="Not Found Page" />
        </div>
      </div>
    );
  }
}
export default withTranslation()(NotFoundPage);
