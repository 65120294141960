import React, { Component } from "react";
import forget from "../../img/forget1.jpg";
import Swal from "sweetalert2";
import axios from "axios";
import { withTranslation } from "react-i18next";
import PasswordStrength from "./Passsword/PasswordStrength";

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      token: new URLSearchParams(window.location.search).get("token"),
      confirm_password: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    Swal.fire({
      title: "Loading",
      type: "warning",
      showCancelButton: false,
      showConfirmButton: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    const { t } = this.props;

    const data = {
      password: this.state.password,
      token: new URLSearchParams(window.location.search).get("token"),
    };
    ///^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/
    const re = RegExp(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
    ); //
    const isOk = re.test(this.state.password);
    console.log(isOk);
    if (isOk) {
      axios({
        method: "post",
        url: `https://digitalfleet.eu/api/1/users/changeForgottenPassword/`,
        headers: {
          Accept: "application/json",
          "Accept-Language": window.localStorage.i18nextLng,
          "Content-Type": "application/json",
        },
        data: data,
      })
        .then((response) => {
          this.setState({
            password: "",
            token: new URLSearchParams(window.location.search).get("token"),
          });
          Swal.fire({
            title: t("reset_sent"),
            type: "success",
            text: t("reset_sent_text"),
            showConfirmButton: false,
            timer: 3000,
          });
          setTimeout(function () {
            this.props.history.push("/");
          }, 2000);
        })
        .catch((reject) => {
          console.log(reject);
          Swal.fire({
            title: t("rest_password"),
            type: "error",
            text: reject.response.data.message,
            timer: 2000,
          });
        });
    } else {
      Swal.fire({
        title: "Password Error",
        type: "warning",
        text:
          "Your password must contain at least one small , big letter ,  least one digit, at least one special characters and at least 8 characters",
        timer: 3000,
      });
    }
  }
  render() {
    const base = process.env.PUBLIC_URL;
    const { t } = this.props;
    return (
      <div className="container hip1">
        {/* <!-- Outer Row --> */}
        <div class="row justify-content-center">
          <div class="col-xl-10 col-lg-12 col-md-9">
            <div class="card o-hidden border-0 shadow-lg my-5">
              <div class="card-body p-0">
                {/* <!-- Nested Row within Card Body --> */}
                <div class="row">
                  <div class="col-lg-6 d-none d-lg-block bg-password-image">
                    <img
                      src={forget}
                      alt="Forget Password"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div class="col-lg-6 don_sm">
                    <div class="p-5">
                      <div class="text-center">
                        <h1
                          class="h4 hee forget_name text-gray-900 mb-2"
                          style={{ marginTop: "-40px", lineHeight: "30px" }}
                        >
                          {t("ResetPassword")}?
                        </h1>
                        <p class="mb-4 " style={{ marginTop: "60px" }}>
                          {t("reset_password_text")}
                        </p>
                      </div>
                      <form
                        class="user "
                        onSubmit={this.handleSubmit}
                        autoComplete="off"
                      >
                        <div class="form-group">
                          <input
                            name="password"
                            className="form-control"
                            type="password"
                            placeholder={t("new_password")}
                            onChange={this.handleChange}
                          />
                          <span style={{ top: "10px", position: "relative" }}>
                            <PasswordStrength password={this.state.password} />
                          </span>
                        </div>
                        <div class="form-group">
                          <input
                            style={{ marginTop: "15px" }}
                            name="confirm_password"
                            type="password"
                            className="form-control"
                            placeholder={t("confirm_password")}
                            onChange={this.handleChange}
                          />
                          {this.state.password !==
                          this.state.confirm_password ? (
                            this.state.confirm_password.length > 1 ? (
                              <span
                                className="text-danger"
                                style={{ marginTop: "-15px" }}
                              >
                                {t("password_did_not_match")}
                              </span>
                            ) : (
                              ""
                            )
                          ) : this.state.confirm_password.length > 1 ? (
                            <span
                              className="text-success"
                              style={{ marginTop: "-55px" }}
                            >
                              {t("password_match")}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <button
                          type="submit"
                          value="Login"
                          disabled={
                            this.state.password !== this.state.confirm_password
                          }
                          className="btn btn-login btn-block text-white font-weight-bolder boxx "
                          style={{
                            background: "rgba(183, 28, 28, 1)",
                            padding: "10px",
                            fontSize: "13px",
                            boxShadow: "0px 8px 25px -7px #c0c0c0",
                            borderRadius: "4px",
                            width: "100%",
                          }}
                        >
                          {t("Reset Password").toUpperCase()}
                          {/* <i
                            className="fas fa-location-arrow"
                            aria-hidden="true"
                            style={{ fontSize: "18px" }}
                          ></i> */}
                        </button>
                      </form>
                      <hr />
                      <div class="text-center">
                        <a
                          class="small"
                          href={base + "/"}
                          style={{ color: "#b71c1c", opacity: "0.8" }}
                        >
                          {t("login_page")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ResetPassword);
