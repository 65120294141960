import React, { Component } from "react";
import { withTranslation } from "react-i18next";
// import config from "../../Main";
import ReactToExcel from "react-html-table-to-excel";

class Data extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect = e => {
    const selected = this.state.selected;
    selected[e.target.name] = e.target.checked;
    this.setState({ selected });
  };
  render() {
    const { t } = this.props;
    return (
      <div className="container" style={{ marginTop: "50px" }}>
        <div className="headerr" style={{ marginTop: "25px" }}>
          <h6>{t("raport").toUpperCase()}</h6>
          <p className="p">
            {t("from")} 12-17-2019 {t("to")} 12-12-2019
          </p>
          <div className="driver report caaar">
            <table
              // id="myTable"
              className="table table-hover sortable table_driver sortable"
              id="table-to-xls"
              style={{ marginTop: "20px" }}
            >
              <thead>
                <tr>
                  <th style={{ width: "5%" }}>{t("Id")}</th>
                  <th>{t("Name")}</th>
                  <th>{t("OverallScore")}</th>
                  <th>{t("OverallSpeed")}</th>
                  <th>{t("OverallDynamic")}</th>
                  <th>{t("OverallEfficiency")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      name="id"
                      //   selected={this.state.selected}
                      type="checkbox"
                      checked={this.state.selected}
                      onChange={this.handleSelect}
                    />
                  </td>
                  <td>1</td>
                  <td>Rahul Raj</td>
                  <td>9.0</td>
                  <td>9.0</td>
                  <td>8.0</td>
                  <td>10.0</td>
                </tr>
              </tbody>
            </table>
            <div style={{ marginTop: "40px" }}>
              <center>
                <ReactToExcel
                  className="btn btn-success btn-lg bb"
                  table="table-to-xls"
                  filename="SoftbikeReport"
                  sheet="sheet 1"
                  buttonText={t("export_report")}
                />
              </center>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Data);
