import React, { Component } from "react";
import Chart from "react-apexcharts";
import { withTranslation } from "react-i18next";

const colors = ["#783bff", "#6ac75a"];
export class Energy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        chart: {
          type: "pie",
        },
        labels: props.energy.map((c) => {
          return c.label;
        }),
        dataLabels: {
          enabled: false,
        },
        colors: colors,
        title: {
          align: "center",
          style: {
            fontSize: "17px",
            color: "black",
          },
        },
        tooltip: {
          y: {
            formatter: (value) => {
              return Number.parseFloat(value).toFixed(0) + "%";
            },
          },
        },

        legend: {
          position: "bottom",
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
          },
        },
      },
    };
  }

  render() {
    const fun = this.props.energy;
    const { t } = this.props;
    const dat1 = fun.map((c) => {
      return c.value;
    });

    return (
      <div className="">
        <h5
          className="text-left font-weight-bold"
          style={{ marginLeft: "26px", marginTop: "30px", fontSize: "13px" }}
        >
          {t("charge_energy")}
        </h5>

        <Chart
          style={{ marginTop: "22px" }}
          options={this.state.options}
          series={dat1}
          type="pie"
          height="250"
        />
      </div>
    );
  }
}

export default withTranslation()(Energy);
