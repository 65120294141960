import config from "../views/Main";

export async function getCar(value, to, from) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.car}?type=${value ? value : ""}&date__lte=${
        to ? to : ""
      }&date__gte=${from ? from : ""}`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getSummary(value, to, from, year, month) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.carsummary}?type=${value ? value : ""}&date__lte=${
        to ? to : ""
      }&date__gte=${from ? from : ""}&date__year=${
        year ? year : ""
      }&date__month=${month ? month : ""}`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getCarDaily(pk, to, from, year, month) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.car}${pk}/days/?date__lte=${to ? to : ""}&date__gte=${
        from ? from : ""
      }&date__year=${year ? year : ""}&date__month=${month ? month : ""}`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getSummaryDaily(pk, to, from, year, month) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.car}${pk}/days/summary/?date__lte=${
        to ? to : ""
      }&date__gte=${from ? from : ""}&date__year=${
        year ? year : ""
      }&date__month=${month ? month : ""}`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getCarMap(pk, date) {
  let products = new Promise((resolve, reject) => {
    fetch(`${config.apiUrl.carmap}${pk}/day/${date}/`, config.head)
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getCarReport(pk, date) {
  let products = new Promise((resolve, reject) => {
    fetch(`${config.apiUrl.mapreport}${pk}/day/${date}/`, config.head)
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getCarProfile(pk, to, from, year, month) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.car}${pk}/?date__lte=${to ? to : ""}&date__gte=${
        from ? from : ""
      }&date__year=${year ? year : ""}&date__month=${month ? month : ""}`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

// New Car Data Endpoint

export function getMyCar(value, to, from, year, month) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl1.myCar}?type=${value ? value : ""}&date__lte=${
        to ? to : ""
      }&date__gte=${from ? from : ""}&date__year=${
        year ? year : ""
      }&date__month=${month ? month : ""}`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export function getcarInfo(setinfo) {
  fetch(config.apiUrl.carInfo, config.head)
    .then((response) => response.json())
    .then((data) => {
      setinfo(data);
    })
    .catch((err) => console.log(err));
}
