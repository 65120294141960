import React, { Component } from "react";
import Form from "../Views/Form";
import AddInvoice from "./AddInvoice";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ButtonToolbar, OverlayTrigger, Popover } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { getCost, getOption } from "../../Service/Cost";
import Spinner from "../../Container/Spinner";
import Import from "./Import";

class Cost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true,
      stats: [],
      csvData: null,
      value: "select",
      vin: "",
      plate: "",
      invoice: "",
      type: "",
      cost_type: "",
      label: [],
      isLoading: true,
    };
  }

  change(event) {
    this.setState({
      value: event.target.value,
    });
  }

  // For Upload CSV file On Table
  handleFiles = (files) => {
    var reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        csvData: reader.result,
      });
    };
    reader.readAsText(files[0]);
    console.log("File Name" + reader.readAsText(files[0].name));
  };

  getData = async (e) => {
    const from = e.target.elements.from.value;
    const to = e.target.elements.to.value;
    e.preventDefault();
    getCost(to, from).then((data) => {
      this.setState({ stats: data, isLoading: false });
    });
  };

  async onSubmitt1(from, to) {
    Promise.all([
      getCost(
        to,
        from,
        this.state.vin,
        this.state.plate,
        this.state.invoice,
        this.state.cost_type
      ).then((data) => {
        data.sort((a, b) => b.date - a.date);
        this.setState({ stats: data, isLoading: false });
      }),
      getOption().then((data) => {
        this.setState({ label: data.actions.POST.cost_type.choices });
      }),
    ]);
  }

  // For live Search
  async onChange(e) {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.onSubmitt1("");
  }
  componentDidMount() {
    this.onSubmitt1("");
  }

  render() {
    const { stats, label } = this.state;
    if (stats === null) return <p>Loading ....</p>;

    const cost = stats.reduce((accumulator, pilot) => {
      var output = accumulator + pilot.net_value;
      return output;
    }, 0);

    const cost1 = stats.reduce((accumulator, pilot) => {
      var output = accumulator + pilot.value;
      return output;
    }, 0);

    const { t } = this.props;
    const base = process.env.PUBLIC_URL;

    return (
      <div className="Main_section" style={{ marginTop: "50px" }}>
        <Helmet>
          <title>Cost</title>
        </Helmet>
        <AddInvoice />
        <Import load={this.handleFiles} />
        <div className="row" style={{ width: "100%" }}>
          <div className="col-sm-2" style={{ marginTop: "-5px" }}>
            <h6> &nbsp; &nbsp;&nbsp;&nbsp;{t("cost_invoie")}</h6>
          </div>
          <div className="col-sm-6" style={{ marginTop: "-30px" }}>
            <Form loaddata={this.getData} />
          </div>

          <div
            className="col-sm-4 "
            style={{ marginTop: "-14px", left: "10px", position: "relative" }}
          >
            <a className="btn" id="bttt" href="#ex1" rel="modal:open">
              {t("add_invoice")}
            </a>

            <a className="btn" id="btt" href="#invoice" rel="modal:open">
              &nbsp; &nbsp; {t("import_invoice")}
            </a>
          </div>
        </div>

        <div className="headerr" style={{ marginTop: "0px" }}>
          <div className="invoi caaar table-responsive">
            {stats.length > 0 ? (
              this.state.isLoading === true ? (
                <Spinner />
              ) : (
                <table className="table table-hover" id="myTable">
                  <thead>
                    <tr>
                      <th>{t("Id")}</th>
                      <th className="t">{t("data_cost")}</th>
                      <th className="t">{t("plate_number")}</th>
                      <th className="t">{t("vin")}</th>
                      <th className="t">{t("Milage1")}</th>
                      <th className="t">{t("invoice_number")}</th>
                      <th>
                        <select
                          class="minimal"
                          name="cost_type"
                          onChange={this.onChange.bind(this)}
                          style={{ width: "150px", top: "9px" }}
                        >
                          {" "}
                          <option value="" disabled selected hidden>
                            {" "}
                            {t("invoice_type")}
                          </option>
                          <option value=""> {t("select_all")}</option>
                          {label.map((c, i) => (
                            <option value={c.value} key={i}>
                              {t("cost_type")}: {c.display_name}
                            </option>
                          ))}
                        </select>
                      </th>
                      <th className="t">{t("quantity")}</th>
                      <th className="t">{t("description")}</th>
                      <th>
                        <select
                          onChange={this.change.bind(this)}
                          value={this.state.value}
                          class="minimal"
                          style={{ width: "120px", top: "9px" }}
                        >
                          <option value="11"> {t("price_netto")} </option>
                          <option value="12"> {t("price_brutto")} </option>
                        </select>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          id="myInput"
                          name="plate"
                          onChange={this.onChange.bind(this)}
                          placeholder={t("search")}
                          style={{
                            width: "100%",
                            marginLeft: "-2%",
                            textAlign: "left",
                          }}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          id="myInput"
                          name="vin"
                          onChange={this.onChange.bind(this)}
                          placeholder={t("search")}
                          style={{
                            width: "70%",
                            marginLeft: "-2%",
                            textAlign: "left",
                          }}
                        />
                      </td>
                      <td></td>
                      <td>
                        <input
                          className="form-control"
                          type="text"
                          id="myInput"
                          name="invoice"
                          onChange={this.onChange.bind(this)}
                          style={{
                            width: "70%",
                            marginLeft: "-2%",
                            textAlign: "left",
                          }}
                        />
                      </td>
                      <td></td>
                    </tr>
                  </tbody>

                  <tbody>
                    {stats.map((c, i) => (
                      <tr key={c.id}>
                        <td>{i + 1}</td>
                        <td style={{ width: "10%" }}>{c.date} </td>
                        <td style={{ width: "10%" }}>
                          {" "}
                          <a
                            href={base + `/overview/cost/${c.id}`}
                            style={{ color: "#b71c1c" }}
                            className="font-weight-bold"
                          >
                            {c.car_plate_number ? `${c.car_plate_number}` : "-"}
                          </a>
                        </td>
                        <td style={{ width: "10%" }}>
                          {" "}
                          {c.car_vin ? `${c.car_vin}` : "-"}
                        </td>
                        <td>{c.car_mileage ? `${c.car_mileage}` : "-"}</td>
                        <td>
                          {c.invoice_number ? `${c.invoice_number}` : "-"}
                        </td>
                        <td>
                          {c.cost_type_display ? `${c.cost_type_display}` : "-"}
                        </td>
                        <td>{c.quantity ? `${c.quantity.toFixed(1)}` : "-"}</td>
                        <td style={{ width: "200px" }}>
                          <ButtonToolbar>
                            <OverlayTrigger
                              trigger="click"
                              key="top"
                              placement="top"
                              overlay={
                                <Popover id="popover-positioned-top">
                                  <Popover.Content>
                                    {c.description}
                                  </Popover.Content>
                                </Popover>
                              }
                            >
                              <button className="btn">
                                {c.description.substring(0, 45)}
                              </button>
                            </OverlayTrigger>
                          </ButtonToolbar>
                        </td>
                        <td>
                          {this.state.value === "11"
                            ? `${c.net_value ? `${c.net_value}` : "-"}`
                            : `${
                                this.state.value === "12"
                                  ? `${c.value ? `${c.value.toFixed(1)}` : "-"}`
                                  : `${
                                      c.net_value
                                        ? `${c.net_value.toFixed(1)}`
                                        : "-"
                                    }`
                              }`}{" "}
                          PLN
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="9">
                        <strong>{t("total_price").toUpperCase()}</strong>
                      </td>
                      <td className="font-weight-bolder h6">
                        {" "}
                        {this.state.value === "11"
                          ? `${cost ? `${cost}` : "-"}`
                          : `${
                              this.state.value === "12"
                                ? `${cost1 ? `${cost1.toFixed(1)}` : "-"}`
                                : `${cost ? `${cost.toFixed(1)}` : "-"}`
                            }`}{" "}
                        PLN
                      </td>
                    </tr>
                  </tfoot>
                </table>
              )
            ) : (
              <p
                className="text-center h4 font-weight-bolder"
                style={{ marginTop: "30px" }}
              >
                {t("no_data")}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Cost));
