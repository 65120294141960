import React, { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

function Datee({ loaddata }) {
  const [fromDate, setFromDate] = useState("");

  const [toDate, setToDate] = useState("");

  const assignFromDate = e => {
    // console.log(e.target.value);
    setFromDate(e.target.value);
  };

  const { t } = useTranslation();

  return (
    <div className="row" style={{ position: "relative", right: "10px" }}>
      <div className="col-sm-6">
        <div className="form-group">
          <span style={{ opacity: "0.6", fontSize: "10px" }}>
            {t("from").toUpperCase()}
          </span>
          <input
            type="date"
            name="from"
            id="lol"
            value={fromDate}
            max={moment().format("YYYY-MM-DD")}
            onChange={assignFromDate}
            className="form-control "
            // style={{ width: "150px" }}
          />
        </div>
      </div>
      <div className="col-sm-6">
        <div className="form-group">
          <span style={{ opacity: "0.6", fontSize: "10px" }}>
            {t("to").toUpperCase()}
          </span>
          <input
            type="date"
            name="to"
            id="lol"
            min={fromDate}
            value={toDate}
            max={moment().format("YYYY-MM-DD")}
            placeholder="Select Date"
            onChange={e => setToDate(e.target.value)}
            className="form-control "
            // style={{ width: "150px" }}
          />
        </div>
      </div>
    </div>
  );
}

export default Datee;
