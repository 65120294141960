import axios from "axios";
import Cookies from "universal-cookie";
import config from "../views/Main";

const CLIENT_ID = "DigitalFleet-Dashboard";
const CLIENT_SECRET =
  "tZczHZh4DuqMqYpuRuTGSSvvySrvH97OVGhHSlUnpSWKxHpsG0vSQ8SX9nY6nWUdT9spYNxhKp13YeZ8EoHVMnFWfNQqrmftDKu1omY5977dJEjw5CX1vPRJXW9aHiZ6";

const cookie = new Cookies();
const authToken = cookie.get("Token");

export async function getRemoveToken() {
  axios({
    method: "POST",
    url: config.apiUrl.logout,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: `token=${authToken}&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}`,
  })
    .then((response) => {
      if (response.status === 200) {
        cookie.remove("Token", { path: "/" });
      }
    })
    .catch((reject) => {
      console.log(reject);
    });
}
