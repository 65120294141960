import React, { useState, useEffect } from "react";
import config from "../views/Main";

function User() {
  const [name, setName] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    fetch(config.apiUrl.me, config.head)
      .then(async (response) => {
        const data = await response.json();
        setName(data);
      })
      .catch((reject) => console.log(reject));
  };

  return (
    <span>
      {localStorage.getItem("passwordAge") >= 30 ? (
        <span className="font-weight-normal"> {name.displayName}</span>
      ) : (
        name.displayName
      )}{" "}
    </span>
  );
}

export default User;
