import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import edit from "../../../../img/edit.png";
import arrow_left from "../../../../img/Aarrow.png";
import List from "./Image/List";
import User from "../../../../Components/User";
import Datee from "../../../Views/Date";
import Insurance from "../../../../Components/Layout/Insurance";
import Spinner from "../../../../Container/Spinner";
import { getClaimDetail } from "../../../../Service/Claim";
import ViewImage from "./Damage/ViewImage";
import "./Claim.css";
import ClaimDetail from "./Detail/ClaimDetail";
export class ViewClaim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      claim: [],
      photoCliaim: [],
      car_data: [],
      isLoading: true,
    };
  }

  getFleetClaimDetail = () => {
    getClaimDetail(this.props.match.params.pk).then((data) => {
      this.setState({
        claim: data,
        photoCliaim: data.claim_photo,
        car_data: data.claim_details,
        isLoading: false,
      });
    });
  };

  componentDidMount() {
    this.getFleetClaimDetail(" ");
  }

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  render() {
    const { t } = this.props;
    const { claim, photoCliaim, car_data } = this.state;

    const name = [
      {
        name: "driving-licence",
        value: t("driver_licence"),
      },
      {
        name: "damaged-photo",
        value: t("Damage_photo"),
      },
      {
        name: "additional-documents",
        value: t("addition_documents"),
      },
      {
        name: "other",
        value: t("other_documents"),
      },
      {
        name: "insurance-police",
        value: t("insurance_police"),
      },
    ];
    const base = process.env.PUBLIC_URL;
    return (
      <div className="" style={{ width: "100%", padding: "40px" }}>
        <Helmet>
          <title>View Insurance Claim</title>
        </Helmet>

        <div
          className="row row1"
          style={{
            width: "100%",
            marginTop: "-20px",
            marginBottom: "-40px",
          }}
        >
          {" "}
          <div className="col-sm-3 date_time">
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
        </div>
        <Insurance />

        {this.state.isLoading === true ? <Spinner /> : ""}
        <div className="headerr" style={{ marginTop: "-6px" }}>
          <div>
            <a href="# " onClick={this.handleClick.bind(this)}>
              <img
                src={arrow_left}
                alt="Left_Arrow"
                title="Back to previous page"
                style={{ height: "2.6em" }}
              />
            </a>
            {t("back")}
            &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; {t("insurance_claims")} /{" "}
            <strong>{claim.insurance_number}</strong>
          </div>
          <div className="" style={{ marginTop: "-50px" }}>
            <nav className="navbar navbar-expand-lg navbar-dark float-right">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item  ">
                  <section>
                    <a
                      className=""
                      title="Click to edit claim"
                      href={base + "/edit/app/claim/insurance/" + claim.id + "/"}
                    >
                      <img
                        src={edit}
                        alt="contact"
                        className={
                          window.localStorage.i18nextLng === "pl"
                            ? "polish1"
                            : "english"
                        }
                        style={{
                          width: "1.6em",
                          marginLeft: "15px",
                          marginTop: "5px",
                        }}
                      />
                      <br></br>
                      <span
                        style={{
                          color: "rgb(183, 28, 28)",
                          fontSize: "11px",
                          marginLeft: "15px",
                        }}
                      >
                        {t("edit")}
                      </span>
                    </a>
                  </section>
                </li>
              </ul>
            </nav>
          </div>
          <div className="row"></div>
          <h3 className="font-weight-normal"> {t("new_claim")}</h3>
          <hr></hr>
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-6">
                  <p id="ppp"> {t("damage_date").toUpperCase()}</p>
                  <p className="pp">{claim.damage_date}</p>
                </div>
                <div className="col-sm-6">
                  <p id="ppp"> {t("claim_date").toUpperCase()}</p>
                  <p className="pp">{claim.date_claim}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-10">
                  <p id="ppp">{t("description_claim").toUpperCase()}</p>
                  <p className="pp">
                    {claim.description ? claim.description : "-"}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="row">
                <div className="col-sm-6">
                  <p id="ppp">{t("type_of_claims_OC/AC").toUpperCase()}</p>
                  <p className="pp">
                    {" "}
                    {claim.type_ac_oc ? claim.type_ac_oc : "-"}
                  </p>
                </div>
                <div className="col-sm-6">
                  <p id="ppp">{t("Costs").toUpperCase()}</p>
                  <p className="pp">
                    {" "}
                    {claim.net_value ? claim.net_value.toFixed(1) : "-"} PLN
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <p id="ppp">STATUS </p>
                  <p className="pp">
                    {" "}
                    {claim.status === "active"
                      ? t("active_status")
                      : t("inactive_status")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="another_section" style={{ marginTop: "50px" }}>
            {car_data.map((d, i) => (
              <div key={i + 1}>
                <ClaimDetail data={d} />

                <div id="headerr">
                  <ViewImage id={i + 1} file={d.file} />

                  <a
                    href={`#chart_score1${i + 1}`}
                    rel="modal:open"
                    style={{ color: "#b71c1c" }}
                    title="Click to view image"
                  >
                    <i
                      className="fa fa-eye "
                      style={{ color: "#b71c1c", fontSize: "19px" }}
                    ></i>
                  </a>
                </div>
              </div>
            ))}
          </div>

          <div className="another_section" style={{ marginTop: "50px" }}>
            <h3 className="font-weight-normal">{t("claims_documents")}</h3>
            <hr></hr>

            <div className="row">
             

              <table className="table" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>{t("Id")}</th>
                    <th>{t("documents")}</th>
                    <th>Status</th>
                    {/* <th>{t("upload_documents")}</th> */}
                    <th>{t("see_documents")}</th>
                  </tr>
                </thead>
                <tbody>
                  {name.map((c, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td> {c.value}</td>
                      <td>
                        {" "}
                        <label class="container1q">
                          <input
                            type="checkbox"
                            checked={
                              photoCliaim.filter((dd) => dd.category === c.name)
                                .length > 0
                                ? true
                                : false
                            }
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td>
                        <List
                          id={i}
                          cc={c.value}
                          val={c.name}
                          c={photoCliaim}
                        />

                        <a
                          href={"#something" + i}
                          rel="modal:open"
                          className={
                            photoCliaim.filter((dd) => dd.category === c.name)
                              .length > 0
                              ? "btn btn-see"
                              : "btn btn-see opacity-2"
                          }
                        >
                          <span style={{ position: "relative", top: "-2px" }}>
                            {" "}
                            {t("see")}
                          </span>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ViewClaim);
