import React, { Component } from "react";
import Datee from "./Date";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import "./index";
import cross from "../../img/Cross.png";
import config from "../Main";

class DriverReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: [],
      selectOptions1: [],
      movie: [],
      walk: [],
      isHidden: true,
      lol: "",
      fol: "",
      name: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.toggleHidden = this.toggleHidden.bind(this);
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  async handleSubmit(e) {
    try {
      const base = process.env.PUBLIC_URL;
      const from = e.target.elements.from.value;
      const to = e.target.elements.to.value;
      const selections = [...e.target.elements.selectOptions.options].filter(
        (opt) => opt.selected
      );
      const selectedValues = selections.map((opt) => opt.value);
      const selectedString = selectedValues.join(",");
      e.preventDefault();
      Promise.all([
        fetch(
          `${config.apiUrl.driver}?date__lte=${to}&date__gte=${from}&id__in=${selectedString}`,
          config.head
        ).then((response) => response.json()),
        fetch(
          `${config.apiUrl.driversummary}?date__lte=${to}&date__gte=${from}&id__in=${selectedString}`,
          config.head
        ).then((response) => response.json()),
      ]).then((resData) => {
        this.setState({
          ...this.state,
          movie: resData,
          speed: resData,
          lol: from,
          fol: to,
        });

        this.props.history.push({
          pathname: base + "/driverreport/",
          state: {
            movie: resData,
            speed: resData,
            lol: from,
            fol: to,
            selectOptions1: this.state.selectOptions1,
          },
        });
        window.location.reload();
      });
    } catch (e) {
      console.log(e);
    }
  }

  async onSubmitt1(e) {
    try {
      const res = await fetch(
        `${config.apiUrl.driver}?name=${this.state.name}`,
        config.head
      );
      const walk = await res.json();
      this.setState({
        walk,
      });
    } catch (e) {
      console.log(e);
    }
  }

  componentDidMount() {
    this.onSubmitt1("");
  }

  async onChange(e) {
    await this.setState({
      [e.target.name]: e.target.value,
    });

    this.onSubmitt1("");
  }

  handleChange = async (e) => {
    let target = e.target;
    let name = target.name;
    let value = Array.from(target.selectedOptions, (option) => option.value);
    await this.setState({
      [name]: value,
    });
  };

  result(params) {
    //console.log(params);
  }

  render() {
    const { t } = this.props;
    const { walk } = this.state;

    return (
      <span>
        {!this.state.hidden && (
          <div id="driver" className="modal">
            <a href="# " rel="modal:close" className="float-right text-dark ">
              <img src={cross} alt="Cross" style={{ height: "1.1em" }} />
            </a>
            <p className="mod" style={{ marginTop: "10px" }}>
              {t("drive_report").toUpperCase()}
            </p>

            <form style={{ position: "relative" }} onSubmit={this.handleSubmit}>
              <div>
                <Datee />
              </div>

              <div className="row">
                <div className="col-md-6 col-lg-4">
                  <div className="form-group" style={{ marginTop: "20px" }}>
                    <label style={{ opacity: "0.6", fontSize: "10px" }}>
                      {t("choose_driver")}
                    </label>

                    <input
                      className="form-control finds"
                      placeholder={t("search")}
                      type="text"
                      name="name"
                      value={this.state.name}
                      style={{ width: "150px" }}
                      onChange={this.onChange}
                    />
                    <select
                      name="selectOptions"
                      multiple={true}
                      style={{ width: "150px" }}
                      onChange={this.handleChange}
                      value={this.state.selectOptions}
                      className="form-control donn"
                    >
                      <option value="" disabled hidden selected>
                        {" "}
                        {t("choose_driver1")}
                      </option>
                      <option value=" ">{t("select_driver")}</option>
                      {walk.map((c) => (
                        <option value={c.id} key={c.id}>
                          {" "}
                          {c.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div
                  className="col-md-6 col-lg-4"
                  style={{ marginLeft: "30px" }}
                >
                  <div className="form-group" style={{ marginTop: "20px" }}>
                    <label style={{ opacity: "0.6", fontSize: "10px" }}>
                      {t("choose_header").toUpperCase()}
                    </label>
                    <select
                      name="selectOptions1"
                      multiple={true}
                      style={{ width: "240px", marginTop: "33px" }}
                      onChange={this.handleChange}
                      value={this.state.selectOptions1}
                      className="form-control donn"
                    >
                      <option value={0}>{t("select_all")}</option>

                      <option value={1}>{t("NOCar")}</option>
                      <option value={2}>{t("Milage")}</option>
                      <option value={3}> {t("FuelComsumed")}</option>
                      <option value={4}> {t("AverageFuel")}</option>
                      <option value={5}>{t("OverspeedingDistance")}</option>
                      <option value={6}>{t("CriticalSpeed")}</option>
                      <option value={7}> {t("score")}</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* <Multiselect
                options={data}
                onSelectOptions={this.result}
                name="selectOptions"
                // onChange={this.handleChange}
              /> */}

              <div style={{ marginTop: "70px" }}>
                <center>
                  <button
                    type="submit"
                    value="Get Data"
                    className="btn btn-login text-white font-weight-bolder boxx "
                    id="button"
                    onClick={this.toggleHidden}
                    style={{
                      height: "40px",
                      fontSize: "13px",
                      // lineHeight: "30px",
                      width: "200px",
                      background: "rgba(183, 28, 28, 1)",
                      border: "none",
                      color: "white",
                      margin: "auto",
                    }}
                  >
                    {t("raport").toUpperCase()}
                  </button>
                </center>
              </div>
            </form>
            {/* {this.state.movie.length > 0 && (
              <Redirect
                to={{
                  pathname: "/driverreport",
                  state: { movie: JSON.stringify(this.state.movie) }
                }}
              />
            )} */}
            {/* <a href="#" rel="modal:close">
          Close
        </a> */}
          </div>
        )}
      </span>
    );
  }
}

export default withTranslation()(withRouter(DriverReport));
