import React from "react";
import "./Erroe.css";
class ErrorHandle extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }
  click() {
    const base = process.env.PUBLIC_URL;
    window.location.href = base + "/fleet/dashboard/";
  }

  render() {
    if (this.state.hasError) {
      return (
        <div class="">
          <section class="centered">
            <h1>500 Server Error</h1>
            <div class="container">
              <span class="message" id="js-whoops"></span>{" "}
              <span class="message" id="js-appears"></span>{" "}
              <span class="message" id="js-error"></span>{" "}
              <span class="message" id="js-apology"></span>
              <div>
                <span id="js-hidden" className="hidden1">
                  This is awkward. It just so happens a bug appeared. Our bad
                  Sorry !!.
                </span>
              </div>
            </div>

            <button
              onClick={this.click.bind(this)}
              className="btn text-white font-weight-bold btn_main"
              style={{ padding: "8px", background: "#B71C1C" }}
            >
              DASHBOARD
            </button>
          </section>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorHandle;
