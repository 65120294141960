export { default as InsuranceCarMetrics } from "../MainDashboard/Insurance/Car/InsuranceCar";
export { default as Risk } from "../MainDashboard/Insurance/Risk/Risk";
export { default as InsuranceProfile } from "../MainDashboard/Insurance/Profile/InsuranceProfile";
export { default as Claim } from "../MainDashboard/Insurance/Claim/Claim";
export { default as AddClaim } from "../MainDashboard/Insurance/Claim/AddClaim";
export { default as EditClaim } from "../MainDashboard/Insurance/Claim/EditClaim";
export { default as ViewClaim } from "../MainDashboard/Insurance/Claim/ViewClaim";

// Cost Link
export { default as Cost } from "../Cost/Cost";
export { default as CostDetail } from "../Cost/CostDetail";
export { default as UpdateInvoice } from "../Cost/UpdateInvoice";

// Fleet Dashboard link
export { default as Fleet } from "../MainDashboard/Fleet/Fleet";
export { default as Working } from "../Views/Progress/Working";

// Campaign Section Link
export { default as ActiveCampaign } from "../Campaign/Campaign/Active";
export { default as Finished } from "../Campaign/Campaign/Finished";
export { default as Planned } from "../Campaign/Campaign/Planned";
// export { default as Cookies } from "../MainDashboard/Cookies/Cookies";
export { default as Clickk } from "../MainDashboard/Insurance/Claim/Click";
export { default as Footer } from "../../Components/Footer/Footer";
