import React, { Component } from "react";
import { Helmet } from "react-helmet";
import config from "../Main";
import ReportData from "./Report/ReportData";
import Additional from "./Additional";
import Prize from "./Prize";
import Campaign from "../../Components/Layout/Campaign";
import User from "../../Components/User";
import Datee from "../Views/Date";
import arrow_left from "../../img/Aarrow.png";
import Swal from "sweetalert2";
import axios from "axios";

import { withTranslation } from "react-i18next";
import Cookies from "universal-cookie";
const cookie = new Cookies();
let authToken = cookie.get("Token");
class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true,
      title: "",
      lead: "",
      text: "",
      span: "",
      without: "",
      export: "",
      auto: "",
      isChecked: false,
      checkedBoxCheck: false,
      driver: [],
      startDate: null,
      endDate: null,
      parameter: "",
      driverList: [],
      prizes: [
        {
          number_list: [],
          prize_type: "",
          name: "",
          value: null,
          quantity: "",
        },
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.toggleHie = this.toggleHie.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChecked() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  async onChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    Swal.fire({
      title: "Loading",
      type: "warning",
      showCancelButton: false,
      showConfirmButton: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    const base = process.env.PUBLIC_URL;

    const data = {
      title: this.state.title,
      lead: this.state.lead,
      span: this.state.span,
      text: this.state.text,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      parameter: this.state.parameter,
      prizes: [...this.state.prizes],
      driverList: this.state.driverList,
    };

    axios({
      method: "post",
      url: config.apiUrl.campaignn,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },

      data,
    })
      .then((res) => {
        this.setState({
          title: "",
          lead: "",
          text: "",
          span: "",
          startDate: "",
          endDate: "",
          parameter: "",
          prizes: [
            {
              number_list: [],
              prize_type: "",
              name: "",
              value: null,
              quantity: "",
            },
          ],
          driverList: [],
        });

        setTimeout(function () {
          window.location.href = base + "/active/campaign/";
        }, 2000);

        Swal.fire({
          title: "Campaign",
          type: "success",
          text: "Campaig Added Successfully  !",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Campaign",
          type: "error",
          text: "Error while Creating new Campaign!",
          timer: 2000,
        });
      });
  }

  handleChange = async (e) => {
    let target = e.target;
    let name = target.name;
    let value = Array.from(target.selectedOptions, (option) => option.value);
    await this.setState({
      [name]: value,
    });

    console.log(this.state.driverList);
  };

  toggleHidden() {
    this.setState({
      isHidden: false,
    });
  }

  toggleHie() {
    this.setState({ isHidden: true });
  }
  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  onClickDriver = (e) => {
    const base = process.env.PUBLIC_URL;
    e.preventDefault();
    window.location.href = base + "/select/driver/";
  };

  render() {
    const { t } = this.props;
    console.log("driver list", this.state.driverList);
    return (
      <div
        className="Main_section"
        style={{ marginTop: "30px", paddingLeft: "30px", paddingRight: "30px" }}
      >
        <Helmet>
          <title>{t("Camapaing_Add")}</title>
        </Helmet>

        <div
          className="row row1"
          style={{ width: "100%", marginBottom: "-30px" }}
        >
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
        </div>
        <Campaign />

        <div className="headerr">
          <div>
            <div>
              <a href="# " onClick={this.handleClick.bind(this)}>
                <img
                  src={arrow_left}
                  alt="Left_Arrow"
                  style={{ height: "2.6em" }}
                />
              </a>
              &nbsp;
              {t("Camapaing_Add").toUpperCase()} &nbsp;
            </div>
          </div>
          <form>
            <div className="row">
              <div className="col-sm-6">
                <h3
                  className="font-weight-normal"
                  style={{ marginTop: "30px" }}
                >
                  {t("new_campaing")}
                </h3>
                <hr style={{ width: "200%" }}></hr>

                {/* Start row here */}
                <div className="row">
                  <div className="col-sm-8">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("Campain_name").toUpperCase()}
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        name="title"
                        value={this.state.title}
                        onChange={this.onChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                {/* End Here Row */}

                {/* Start row here */}
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-sm-8">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("campaign_intro").toUpperCase()}
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        name="lead"
                        value={this.state.lead}
                        onChange={this.onChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-sm-8">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("description").toUpperCase()}
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        name="text"
                        value={this.state.text}
                        onChange={this.onChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                {/* End Here Row */}
              </div>

              <div className="col-sm-6">
                <h3
                  className="font-weight-normal"
                  style={{ marginTop: "64px" }}
                >
                  {/* {t("additonal_information")} */}
                </h3>

                <Additional
                  loaddata={this.onChange}
                  vall={this.state}
                  handle={this.handleChange}
                />
              </div>
            </div>

            <div
              style={{
                marginTop: "20px",
              }}
            >
              <ReportData
                vall={this.state}
                loaddata={this.handleChecked}
                select1={this.handleChange1}
                handle={this.handleChange}
                driverlist={this.state.driverList}
                check={this.state.checkedBoxCheck}
                selectall={this.handleCheckAll}
              />
            </div>

            <div>
              <Prize
                loaddata={this.onChange}
                vall={this.state.prizes}
                state={this.state.without}
                handle={this.handleList}
              />
            </div>
            <center>
              <button
                className="btn btn-danger br text-white"
                value="create campaign"
                onClick={this.handleSubmit}
                style={{ background: "#b71c1c" }}
              >
                {t("create_campaing")}
              </button>
            </center>
          </form>

          {/* Form End Here */}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Create);
