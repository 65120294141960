import React, { Component } from "react";
import config from "../../Main";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import Datee from "../../Report/Date";
import Search from "../../Settings/Recommend/Search";
// import arrow_left from "../../../img/Aarrow.png";
import Spinner from "../../../Container/Spinner";
import CarReports from "../../Report/CarReports";

export class Choose1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedBoxCheck: false,
      isHidden: "checked",
      stats: [],
      value: "select",
      vin: " ",
      plate: " ",
      brand: " ",
      model: " ",
      location: "",
      technical: "",
      year: "",
      tmonth: "",
      tyear: "",
      imonth: "",
      iyear: "",
      engine: "",
      staus: "",
      lol: "",
      fol: "",
      first_name: "",
      selected: {},
      selectedItems: [],
      selectOptions1: [],
      isLoading: true,
    };
    this.toggleHidden = this.toggleHidden.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async handleSubmit(e) {
    const base = process.env.PUBLIC_URL;
    e.preventDefault();
    const from = e.target.elements.from.value;
    const to = e.target.elements.to.value;
    this.props.history.push({
      pathname: base + "/metric/report/",
      state: {
        selectedItems: this.state.selectedItems,
        selectOptions1: this.state.selectOptions1,
        lol: from,
        fol: to,
        isLoading: false,
      },
    });
    // window.location.reload();
  }

  async onSubmitt1(e) {
    try {
      const res = await fetch(
        `${config.apiUrl.car}?brand__icontains=${this.state.brand}&location__icontains=${this.state.location}&model__icontains=${this.state.model}&vin__icontains=${this.state.vin}&license_plate__icontains=${this.state.plate}&production_year=${this.state.year}&type=${this.state.engine}&active=${this.state.staus}&&next_registration_exam__month=${this.state.tmonth}&next_registration_exam__year=${this.state.tyear}&insurance_expiration__month=${this.state.imonth}&insurance_expiration__year=${this.state.iyear}`,
        config.head
      );
      const stats = await res.json();
      this.setState({
        stats,
        isLoading: false,
      });
    } catch (e) {
      console.log(e);
    }
  }

  toggleHidden() {
    this.setState({
      checked: !this.state.checked,
    });
  }

  async onChange(e) {
    await this.setState({
      [e.target.name]: e.target.value,
    });

    this.onSubmitt1("");
  }

  componentDidMount() {
    this.onSubmitt1("");
  }

  handleSelect = (e) => {
    const selected = this.state.selected;
    selected[e.target.name] = e.target.checked;
    this.setState({ selected });
  };

  async onItemSelect(row) {
    const selectedItems = this.state.selectedItems.slice(0);
    if (selectedItems.includes(row)) {
      selectedItems.splice(selectedItems.indexOf(row), 1);
    } else {
      selectedItems.push(row);
    }
    await this.setState({
      selectedItems,
    });
    console.log("Hello", this.state.selectedItems);
  }

  toggleSelectAll() {
    let selectedItems = [];
    var checkedBoxCheck = !this.state.checkedBoxCheck;
    if (checkedBoxCheck) {
      this.state.stats.forEach((x) => {
        selectedItems.push(x.id);
      });
    } else {
      selectedItems = [];
    }
    this.setState((prevState) => ({
      selectedItems,
      checkedBoxCheck,
    }));

    console.log(selectedItems);
  }

  async handleChange(e) {
    let target = e.target;
    let name = target.name;
    //here
    let value = Array.from(target.selectedOptions, (option) => option.value);
    await this.setState({
      [name]: value,
    });
  }

  render() {
    const { stats } = this.state;
    const { t } = this.props;
    if (stats === null) return <p>Loading ....</p>;

    return (
      <div className="container1" style={{ marginTop: "30px" }}>
        <Helmet>
          <title>Choose Car From List</title>
        </Helmet>
        <CarReports data={this.state.selectedItems} />

        <div>
          &nbsp; <strong>{t("vehicle").toUpperCase()}</strong> &nbsp;
        </div>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <div
            className="row"
            style={{ width: "100%", marginLeft: "10px", marginTop: "10px" }}
          >
            <div className="col-md-4" style={{ marginTop: "25px" }}>
              <label
                style={{
                  opacity: "0.6",
                  fontSize: "10px",
                  marginLeft: "-10px",
                }}
              >
                {t("choose_date1").toUpperCase()}
              </label>
              <Datee />
            </div>
            <div className="col-md-6">
              <div className="form-group" style={{ marginTop: "20px" }}>
                <label style={{ opacity: "0.6", fontSize: "10px" }}>
                  {t("choose_header").toUpperCase()}
                </label>
                <select
                  name="selectOptions1"
                  multiple={true}
                  style={{ width: "240px", marginTop: "3px" }}
                  onChange={this.handleChange}
                  value={this.state.selectOptions1}
                  class="form-control donn"
                >
                  <option value={0}>{t("select_all")}</option>

                  <option value={1}>{t("Engine_type")}</option>
                  <option value={2}>{t("Milage")}</option>
                  <option value={3}> {t("FuelComsumed")}</option>
                  <option value={4}> {t("AverageFuel")}</option>
                  <option value={5}> {t("OverspeedingDistance")}</option>
                  <option value={6}> {t("dtc")}</option>
                  <option value={7}> {t("weekend_driving")}</option>
                  <option value={8}> {t("off_hour_driving")}</option>
                  <option value={9}>{t("off_zone_driving")}</option>
                  <option value={10}>{t("total_cost")}</option>
                </select>
              </div>
            </div>
            <div
              className="float-right col-md-2"
              style={{ marginTop: "100px" }}
            >
              <button className="btn" id="b">
                {t("raport").toUpperCase()}
              </button>
            </div>
          </div>
        </form>

        <div className="headerr" style={{ marginTop: "-10px" }}>
          <div
            className="caaar table-responsive"
            style={{ marginTop: "0px", width: "100%" }}
          >
            {this.state.isLoading === true ? (
              <Spinner />
            ) : (
              <table className="table tabell1 table-hover">
                <thead>
                  <tr style={{ marginTop: "-88px" }}>
                    <th class="active" style={{ width: "20px" }}>
                      <input
                        type="checkbox"
                        class="select-all checkbox"
                        name="first_name"
                        id="selectAll1"
                        checked={this.state.checkedBoxCheck}
                        onClick={this.toggleSelectAll.bind(this)}
                      />
                    </th>
                    <th className="" style={{ width: "20px" }}>
                      {t("Id")}
                    </th>
                    <th className="t">{t("vin")}</th>
                    <th className="t">{t("plate_number1")}</th>
                    <th className="">
                      <select
                        name="engine"
                        onChange={this.onChange.bind(this)}
                        className="minimal"
                        style={{
                          marginTop: "12px",
                          position: "relative",
                          top: "0px",
                          width: "150px",
                        }}
                      >
                        <option value="">
                          {" "}
                          {t("Engine_type")}: {t("AllCar")}{" "}
                        </option>
                        <option value="Pb">{t("Engine_type")}: Pb</option>
                        <option value="ON"> {t("Engine_type")}: ON </option>
                        <option value="HEV">{t("Engine_type")}: HEV </option>
                      </select>
                    </th>
                    <th className="t">{t("Brand")}</th>
                    <th className="t">{t("Model")}</th>
                    <th className="t">{t("production_year")}</th>
                    <th className="t">{t("technical_inspection")}</th>
                    <th className="t">{t("engine_capacity1")}</th>

                    <th className="t">{t("monthly_hors_power")}</th>
                    <th>{t("location")}</th>
                    <th className="t">
                      <select
                        name="staus"
                        onChange={this.onChange.bind(this)}
                        className="minimal"
                        style={{ width: "130px", top: "0px" }}
                      >
                        <option value=" "> {t("status")}</option>
                        <option value="true">{t("active")} </option>
                        <option value="false">{t("disable")}</option>
                      </select>
                    </th>
                  </tr>
                </thead>

                <Search load={this.onChange.bind(this)} vall={this.state} />

                <tbody>
                  {stats.map((c, i) => (
                    <tr key={c.id}>
                      <td>
                        <input
                          type="checkbox"
                          // checked={this.state.selectedItems.includes(c.id)}
                          checked={this.state.selectedItems.includes(c.id)}
                          className="checkbox"
                          name="selectOptions"
                          onClick={() => this.onItemSelect(c.id)}
                        />
                      </td>
                      <td>{i + 1}</td>
                      <td>
                        <a
                          href=" "
                          style={{ color: "#b71c1c" }}
                          className="font-weight-bold"
                        >
                          {c.vin ? `${c.vin}` : "-"}
                        </a>
                      </td>
                      <td>
                        <a
                          href=" "
                          style={{ color: "#b71c1c" }}
                          className="font-weight-bold"
                        >
                          {c.plate_number ? `${c.plate_number}` : "-"}
                        </a>
                      </td>
                      <td>{c.type ? `${c.type}` : "-"}</td>
                      <td>{c.brand ? `${c.brand}` : "-"}</td>
                      <td>{c.model ? `${c.model}` : "-"}</td>
                      <td>
                        {c.production_year ? `${c.production_year}` : "-"}
                      </td>
                      <td>
                        {c.next_registration_exam
                          ? `${c.next_registration_exam}`
                          : "-"}
                      </td>
                      <td>
                        {c.engine_capacity
                          ? `${c.engine_capacity.toFixed(1)}`
                          : "-"}
                      </td>
                      <td>
                        {c.engine_power ? `${c.engine_power.toFixed(1)}` : "-"}
                      </td>
                      <td>{c.location ? `${c.location}` : "-"}</td>
                      <td>
                        <button
                          className={
                            c.status === "active"
                              ? "btn btn-success"
                              : "btn btn-danger"
                          }
                          id="dd"
                        >
                          {c.status}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(withRouter(Choose1));
