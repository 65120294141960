import React, { Component } from "react";
import Header1 from "../../../Components/Layout/Header1";
import User from "../../../Components/User";
import Datee from "../../Views/Date";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import arrow_left from "../../../img/Aarrow.png";
import cost from "../../../img/cost.png";
import mat from "../../../img/cmat.png";
import map from "../../../img/route1.png";
import arrow from "../../../img/arrow.png";
import { withTranslation } from "react-i18next";
import { getCarDaily, getSummaryDaily } from "../../../Service/Car";

class CriticalMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      movie: [],
      speed: [],
      value: "select",

      currentSort: "default",
    };
  }
  change(event) {
    this.setState({
      value: event.target.value,
    });
  }

  async componentDidMount() {
    Promise.all([
      getCarDaily(
        this.props.match.params.pk,
        this.props.match.params.date,
        this.props.match.params.date
      ).then((data) => {
        this.setState({ movie: data });
      }),
      getSummaryDaily(
        this.props.match.params.pk,
        this.props.match.params.date,
        this.props.match.params.date
      ).then((data) => {
        this.setState({ speed: data });
      }),
    ]);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  // Sorting Resolve

  compareByAsc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByDesc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return 1;
      if (a[key] > b[key]) return -1;
      return 0;
    };
  }

  sortBy(key) {
    let arrayCopy = [...this.state.movie];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(this.compareByAsc(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(this.compareByDesc(key));
    }
    this.setState({ movie: arrayCopy });
  }

  render() {
    const { movie, speed } = this.state;
    const base = process.env.PUBLIC_URL;
    const { t } = this.props;

    return (
      <div className="" style={{ marginTop: "20px" }}>
        <Helmet>
          {movie.slice(0, 1).map((c) => (
            <title>
              {c.plate_number ? `${c.plate_number}` : " Plate Number "}{" "}
            </title>
          ))}
        </Helmet>

        <div className="row row1">
          <div className="col-sm-3" style={{ marginTop: "5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
          <div className="col-sm-6"></div>
        </div>
        <Header1 />

        <div className="headerr">
          {/* Start Upper portion */}
          <div className="row" style={{ width: "100%", paddingBottom: "9px" }}>
            <div className="col-sm-2">
              <a href="# " onClick={this.handleClick.bind(this)}>
                <img
                  src={arrow_left}
                  alt="Left_Arrow"
                  style={{ height: "2.6em" }}
                />
              </a>
              &nbsp;
              {movie.length > 0 ? (
                movie
                  .slice(0, 1)
                  .map((c) => (
                    <strong>{this.props.match.params.plate_number}</strong>
                  ))
              ) : (
                <strong>&nbsp;&nbsp; {t("no_data")}</strong>
              )}
            </div>

            <div className="">
              {this.state.movie.length > 0 ? (
                <span>
                  {movie.slice(0, 1).map((c) => (
                    <nav className="navbar navbar-expand-lg navbar-dark fixed">
                      <ul className="navbar-nav ">
                        <li className="nav-item btnn ">
                          <a className="" href="#contact" rel="modal:open">
                            {" "}
                            <img
                              src={cost}
                              alt="contact"
                              disabled
                              className="setting1"
                            />
                            <br></br>
                            <span
                              style={{
                                color: "rgb(183, 28, 28)",
                                fontSize: "11px",
                              }}
                            >
                              {t("Costs")}
                            </span>
                          </a>
                        </li>
                        &nbsp;&nbsp;
                        <li className="nav-item btnn ">
                          &nbsp;{" "}
                          <a href={`/carmerics/details/` + c.id}>
                            <img
                              src={mat}
                              alt="Matrics"
                              className="setting2"
                              style={{ marginLeft: "12px" }}
                            />
                            <br></br>

                            <span
                              style={{
                                color: "rgb(183, 28, 28)",
                                fontSize: "11px",
                              }}
                            >
                              {t("metric")}
                            </span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  ))}
                </span>
              ) : (
                <p></p>
              )}
            </div>
            {/* <div style={{ marginTop: "10px" }} className="col-sm-8 text-center">
              {t("warning")}
            </div> */}
          </div>

          {/* End Upper portion */}
          <div className="caaar">
            <table className="table table-hover " style={{ width: "100%" }}>
              <thead style={{ position: "relative" }}>
                <tr className="th">
                  <th className="" style={{ width: "5%" }}>
                    {t("Id")}
                  </th>
                  <th className="" onClick={() => this.sortBy("date")}>
                    {t("date")}
                  </th>
                  {/* <th className=" sorttable_nosort">{t("plate_number")}</th> */}
                  <th className="t" onClick={() => this.sortBy("milage")}>
                    {t("Milage")}{" "}
                    <img src={arrow} alt="Arrow" style={{ height: "0.9em" }} />
                  </th>
                  <th
                    className="t"
                    onClick={() => this.sortBy("fuel_consumed")}
                  >
                    {t("FuelComsumed")}{" "}
                    <img src={arrow} alt="Arrow" style={{ height: "0.9em" }} />
                  </th>
                  <th
                    className=" t"
                    onClick={() => this.sortBy("average_fuel_consumed")}
                  >
                    {t("AverageFuel")}{" "}
                    <img src={arrow} alt="Arrow" style={{ height: "0.9em" }} />
                  </th>
                  <th
                    className="t"
                    onClick={() => this.sortBy("overspeeding_percentage")}
                  >
                    {t("OverspeedingDistance")}{" "}
                    <img src={arrow} alt="Arrow" style={{ height: "0.9em" }} />
                    {/* <img src={arrow} alt="Arrow" style={{ height: "0.9em" }} /> */}
                  </th>

                  <th
                    className="t"
                    onClick={() => this.sortBy("off_zone_driving")}
                  >
                    <select
                      onChange={this.change.bind(this)}
                      value={this.state.value}
                      class="minimal"
                      style={{
                        width: "220px",
                        top: "0px",
                      }}
                    >
                      <option value="4"> {t("off_zone_driving")} </option>
                      <option value="5"> {t("off_zone_fuel")} </option>
                      <option value="6"> {t("off_zone_milage")} </option>
                    </select>
                    <img src={arrow} alt="Arrow" style={{ height: "0.9em" }} />
                  </th>

                  <th className="t">
                    <select
                      onChange={this.change.bind(this)}
                      value={this.state.value}
                      class="minimal"
                      style={{
                        width: "240px",
                        top: "0px",
                      }}
                    >
                      <option value="7"> {t("off_hour_driving")} </option>
                      <option value="9"> {t("off_hour_fuel")} </option>
                      <option value="8"> {t("off_hour_milage")} </option>
                    </select>
                    <img
                      src={arrow}
                      onClick={() =>
                        this.state.value === "7"
                          ? this.sortBy("off_hour_driving")
                          : this.state.value === "8"
                          ? this.sortBy("off_hour_milage")
                          : this.state.value === "9"
                          ? this.sortBy("off_hour_consumed")
                          : this.sortBy("off_hour_driving")
                      }
                      alt="Arrow"
                      style={{ height: "0.9em" }}
                    />
                  </th>
                  <th className=" t " style={{ width: "1%" }}></th>
                </tr>
              </thead>
              <tbody>
                {movie.map((c, i) => (
                  <tr key={c.id}>
                    <td>{i + 1}</td>
                    <td style={{ width: "100px" }}>
                      {c.date ? `${c.date}` : "00-00-0000"}
                    </td>

                    <td>{c.milage ? `${c.milage.toFixed(1)}` : 0}</td>
                    <td>
                      {c.fuel_consumed ? `${c.fuel_consumed.toFixed(1)}` : 0}
                    </td>
                    <td>
                      {c.average_fuel_consumed
                        ? `${c.average_fuel_consumed.toFixed(1)}`
                        : 0}
                    </td>
                    <td>
                      {c.overspeeding_percentage
                        ? `${c.overspeeding_percentage.toFixed(1)}`
                        : 0}
                    </td>
                    {/* <td>{c.dtc ? `${c.dtc}` : 0}</td> */}
                    <td>
                      {this.state.value === "4"
                        ? `${
                            c.off_zone_driving
                              ? `${c.off_zone_driving.toFixed(1)}`
                              : "-"
                          }`
                        : `${
                            this.state.value === "5"
                              ? `${
                                  c.off_zone_milage
                                    ? `${c.off_zone_milage.toFixed(1)}`
                                    : "-"
                                }`
                              : `${
                                  this.state.value === "6"
                                    ? `${
                                        c.off_zone_fuel_consumed
                                          ? `${c.off_zone_fuel_consumed.toFixed(
                                              1
                                            )}`
                                          : "-"
                                      }`
                                    : `${
                                        c.off_zone_driving
                                          ? `${c.off_zone_driving.toFixed(1)}`
                                          : "-"
                                      }`
                                }`
                          }`}
                    </td>
                    <td>
                      {this.state.value === "7"
                        ? `${
                            c.off_hour_driving
                              ? `${c.off_hour_driving.toFixed(1)}`
                              : "-"
                          }`
                        : `${
                            this.state.value === "8"
                              ? `${
                                  c.off_hour_milage
                                    ? `${c.off_hour_milage.toFixed(1)}`
                                    : "-"
                                }`
                              : `${
                                  this.state.value === "9"
                                    ? `${
                                        c.off_hour_consumed
                                          ? `${c.off_hour_consumed.toFixed(1)}`
                                          : "-"
                                      }`
                                    : `${
                                        c.off_hour_driving
                                          ? `${c.off_hour_driving.toFixed(1)}`
                                          : "-"
                                      }`
                                }`
                          }`}
                    </td>
                    <td>
                      <a
                        href={
                          base + `/carmap/${c.id}/${c.date}/${c.plate_number}`
                        }
                      >
                        <img src={map} alt="mapa" style={{ height: "20px" }} />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot className="thead">
                <tr>
                  <th className="sorttable_nosort"></th>
                  <th className="sorttable_nosort">
                    {" "}
                    {t("Summary1").toUpperCase()}
                  </th>

                  <th className="sorttable_nosort">
                    {speed.milage ? `${speed.milage.toFixed(1)}` : "-"}
                  </th>
                  <th className="sorttable_nosort">
                    {speed.fuel_consumed
                      ? `${speed.fuel_consumed.toFixed(1)}`
                      : "-"}
                  </th>
                  <th className="sorttable_nosort">
                    {speed.average_fuel_consumed
                      ? `${speed.average_fuel_consumed.toFixed(1)}`
                      : "-"}
                  </th>

                  <th className="sorttable_nosort">
                    {speed.overspeeding_percentage
                      ? `${speed.overspeeding_percentage.toFixed(1)}`
                      : "-"}
                  </th>
                  <th className="sorttable_nosort">
                    {" "}
                    {this.state.value === "4"
                      ? `${
                          speed.off_zone_driving
                            ? `${speed.off_zone_driving.toFixed(1)}`
                            : "-"
                        }`
                      : `${
                          this.state.value === "5"
                            ? `${
                                speed.off_zone_milage
                                  ? `${speed.off_zone_milage.toFixed(1)}`
                                  : "-"
                              }`
                            : `${
                                this.state.value === "6"
                                  ? `${
                                      speed.off_zone_fuel_consumed
                                        ? `${speed.off_zone_fuel_consumed.toFixed(
                                            1
                                          )}`
                                        : "-"
                                    }`
                                  : `${
                                      speed.off_zone_driving
                                        ? `${speed.off_zone_driving.toFixed(1)}`
                                        : "-"
                                    }`
                              }`
                        }`}
                  </th>
                  <th className="sorttable_nosort">
                    {this.state.value === "7"
                      ? `${
                          speed.off_hour_driving
                            ? `${speed.off_hour_driving.toFixed(1)}`
                            : "-"
                        }`
                      : `${
                          this.state.value === "8"
                            ? `${
                                speed.off_hour_milage
                                  ? `${speed.off_hour_milage.toFixed(1)}`
                                  : "-"
                              }`
                            : `${
                                this.state.value === "9"
                                  ? `${
                                      speed.off_hour_consumed
                                        ? `${speed.off_hour_consumed.toFixed(
                                            1
                                          )}`
                                        : "-"
                                    }`
                                  : `${
                                      speed.off_hour_driving
                                        ? `${speed.off_hour_driving.toFixed(1)}`
                                        : "-"
                                    }`
                              }`
                        }`}
                  </th>
                  <th className="sorttable_nosort"></th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(CriticalMap));
