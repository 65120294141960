import React, { Component } from "react";
// import Chart from "react-apexcharts";
//import arrowL from "../../../../Service/Arrow/Arrow";
import { withTranslation } from "react-i18next";
import { Line } from "react-chartjs-2";
export class LineChart extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const unit1 = this.props.chat.yRange.unit;
    const label1 = this.props.chat.label;

    const options = {
      pan: {
        enabled: true,
        mode: "x",
      },
      zoom: {
        enabled: true,
        mode: "xy",
      },
      legend: {
        display: false,
      },

      tooltips: {
        callbacks: {
          label: function (tooltipItem) {
            return (
              label1 +
              " : " +
              new Intl.NumberFormat().format(tooltipItem.value) +
              "" +
              unit1
            );
          },
        },

        padding: 20,
        titleFontSize: 15,
        titleFontColor: "white",
        bodyFontColor: "white",
        bodyFontSize: 13,
        yPadding: 10,
        xPadding: 10,
        mode: "index",
        borderColor: "#00ff44",
        borderWidth: 2,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              min: this.props.chat.yRange.min,
              max: this.props.chat.yRange.max,
              padding: 5,
              maxTicksLimit: 5,
              callback: function (value, index, props) {
                return Number.parseFloat(value).toFixed(0) + unit1;
              },
              stepSize: 3, //interval between ticks
            },
            scaleLabel: {
              display: true,
              labelString: this.props.chat.yAxis,
            },
            beginAtZero: true,
            gridLines: {
              drawTicks: false,
            },
          },
        ],
        xAxes: [
          {
            tricks: {
              maxRotation: 90,
              maxTicksLimit: 10,
              callback: function (value, index, props) {
                return Number.parseFloat(value).toFixed(0) + "  ";
              },
              suggestedMax: 11.12 * 2,
              stepSize: 1, //interval between ticks
            },
            offset: true,
            scaleLabel: {
              display: true,
              labelString: this.props.chat.xAxis,
            },
            // offset: true,
            gridLines: {
              drawOnChartArea: false,
            },
          },
        ],
      },
    };

    return (
      <div>
        {/* {this.props.chat.series.map((c) => c.data.map((d) => d.label.value))} */}
        <Line
          options={options}
          data={{
            labels: this.props.chat.xLabels,

            datasets: [
              {
                label: this.props.chat.label,
                data: this.props.chat.value, //dailyData.map(({ confirmed }) => confirmed),
                backgroundColor: "#00ff44",
                borderColor: "#00ff44",
                fill: false,
                pointRadius: 4,
                pointHoverRadius: 5,
              },
            ],
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(LineChart);
