import React, { Component } from "react";
import Chart from "react-apexcharts";
import { withTranslation } from "react-i18next";
import moment from "moment";

export class Area extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;

    this.state = {
      options: {
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },

        labels: props.load.SOC_data.map((c) => c.label),

        xaxis: {
          type: "datetime",
          title: {
            offsetY: -5,
            text: t("station_x"),
            style: {
              fontSize: "10px",
              color: "#8D8D90",
            },
          },
          categories: props.load.SOC_data.map((c) => c.label),

          labels: {
            formatter: function (value) {
              return moment(value).format("HH:mm");
            },
            datetimeUTC: true,
            show: true,
          },
          // labels: {
          //   show: true,
          // },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 5,
          title: {
            text: t("station_y"),

            style: {
              fontSize: "10px",
              color: "#8D8D90",
            },
          },
          labels: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
        },
        colors: ["#b71c1c"],
        stroke: {
          curve: "straight",
        },

        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 0.5,
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.3,
            opacityTo: 0.4,
            stops: [0, 100],
          },
        },
      },
    };
  }

  render() {
    const { t } = this.props;
    const rahul = [
      {
        name: "Charging KW/M",
        data: this.props.load.SOC_data.map((c) => c.value),
      },
    ];

    return (
      <div style={{ marginBottom: "50px" }}>
        <p
          id="pppp"
          className="float-left"
          style={{ fontSize: "11px", marginLeft: "10px" }}
        >
          {t("charging_kwm")}
        </p>
        <Chart
          style={{ marginTop: "0px" }}
          options={this.state.options}
          series={rahul}
          type="area"
          height="200px"
        />
      </div>
    );
  }
}

export default withTranslation()(Area);
