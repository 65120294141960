const compareByAsc1 = (key) => {
  return function (a, b) {
    if (a["span"][key]["value"] < b["span"][key]["value"]) return -1;
    if (a["span"][key]["value"] > b["span"][key]["value"]) return 1;
    return 0;
  };
};

export default compareByAsc1;

// Another way to shorting
// handleSort = (id) => {
//   this.setState((prev) => {
//     const ordered = prev.walk.sort((a, b) =>
//       prev.order === "ASC"
//         ? a["span"][id]["value"] < b["span"][id]["value"]
//         : a["span"][id]["value"] > b["span"][id]["value"]
//     );
//     return {
//       sortBy: id,
//       order: prev.order === "ASC" ? "DESC" : "ASC",
//       walk: ordered,
//     };
//   });
// };
