import React, { Component } from "react";
import Chart from "react-apexcharts";
import { withTranslation } from "react-i18next";
// import config from "../../../Main";

const colors = ["#ff6d43"];
export class Temprature extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      ...props,
      options: {
        chart: {
          id: "Temperature",
          stacked: false,
          animations: {
            enabled: true,
            easing: "linear",
            dynamicAnimation: {
              speed: 1000,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: colors,
        xaxis: {
          type: "category",
          labels: {
            style: {
              fontSize: "13px",
            },
          },
          categories: props.load.map((c) => {
            return c.label;
          }),
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            labels: {
              formatter: function (value) {
                return value + " % ";
              },
            },
            axisBorder: {
              show: true,
            },
          },
        ],

        tooltip: {
          shared: false,
          intersect: true,
          y: {
            formatter: (value) => {
              return Number.parseFloat(value).toFixed(0) + "%";
            },
          },
        },

        plotOptions: {
          bar: {
            columnWidth: "30%",
            endingShape: "flat",
            distributed: true,
          },
        },
        title: {
          align: "center",
          style: {
            fontSize: "17px",
            color: "black",
          },
        },
        labels: props.load.map((c) => {
          return c.label;
        }),
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: t("temprature"),
          data: props.load.map((c) => {
            return c.data;
          }),
        },
      ],
    };
  }

  render() {
    const { t } = this.props;

    return (
      <div className="col-sm-4">
        <h5
          className="text-left font-weight-normal"
          style={{ marginLeft: "26px", marginTop: "10px" }}
        >
          {t("temprature")}
        </h5>
        <Chart
          style={{ marginTop: "22px" }}
          options={this.state.options}
          series={this.state.series}
          type="bar"
          stacked="true"
          height="250"
        />
      </div>
    );
  }
}

export default withTranslation()(Temprature);
