import React, { Component } from "react";
import TimeAgo from "react-timeago";
import { Helmet } from "react-helmet";
import Header from "./Header";
import moment from "moment";
import Search from "./Search";
import {
  updateNotification1,
  deleteNotification,
  deleteSelectedNotification,
  MarkSelectedNotification,
} from "../../Service/Notification";
import { withTranslation } from "react-i18next";
export class All extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: props.state1.selectedItems,
      checkbox: props.state1.checkedBoxCheck,
      isRead: {},

      // stats : props.notification.data
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  async onItemSelect(row) {
    const selectedItems = this.state.selectedItems.slice(0);
    if (selectedItems.includes(row)) {
      selectedItems.splice(selectedItems.indexOf(row), 1);
    } else {
      selectedItems.push(row);
    }
    await this.setState({
      selectedItems,
    });
    console.log("Hello", this.state.selectedItems);
  }

  toggleSelectAll() {
    let selectedItems = [];
    var checkedBoxCheck = !this.state.checkedBoxCheck;
    if (checkedBoxCheck) {
      this.props.notification.data.forEach((x) => {
        // selectedItems[x.id] = x.id
        selectedItems.push(x.id);
      });
    } else {
      selectedItems = [];
    }
    this.setState((prevState) => ({
      selectedItems,
      checkedBoxCheck,
    }));

    console.log(selectedItems);
  }

  handleSubmit(e, id) {
    e.preventDefault();
    updateNotification1(id).then((res) => {
      this.setState({
        res,
      });
    });
  }

  handleDelete(e, id) {
    e.preventDefault();
    deleteNotification(id).then((res) => {
      this.setState({
        res,
      });
    });
  }

  handleSelectDelete = (e) => {
    e.preventDefault();
    deleteSelectedNotification(this.state.selectedItems).then((res) => {
      this.setState({
        res,
      });
    });
  };

  handMarkSelected = (e) => {
    e.preventDefault();
    MarkSelectedNotification(this.state.selectedItems).then((res) => {
      this.setState({
        res,
      });
    });
  };

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Helmet>
          <title>Fleet Manager Notification</title>
        </Helmet>
        <div>
          <Search
            state1={this.props.state1.search}
            change={this.props.search}
          />
        </div>
        <br></br>

        <div className="" style={{ marginTop: "-50px" }}>
          <Header
            delete1={this.handleSelectDelete}
            len={this.props.noti}
            state11={this.state.selectedItems}
            next={this.props.next}
            prev={this.props.prev}
            state1={this.state.checkedBoxCheck}
            mark={this.handMarkSelected}
            check={this.toggleSelectAll.bind(this)}
            data={this.props.notification}
          />
        </div>

        {this.props.notification && (
          <span>
            {this.props.notification.data.map((c, i) => (
              <nav
                class="navbar navbar-expand-lg navbar-light notification_data_bar notification_li"
                key={i + 1}
              >
                <ul class="navbar-nav mr-auto ">
                  <li class="nav-item active">
                    <span class="nav-link">
                      <input
                        type="checkbox"
                        class="select-all checkbox"
                        name="first_name"
                        id="selectAll1"
                        checked={this.state.selectedItems.includes(c.id)}
                        onClick={() => this.onItemSelect(c.id)}
                      />
                    </span>
                  </li>
                  <li class="nav-item active">
                    <a class="nav-link" href="# ">
                      <span
                        className={
                          c.isRead === true
                            ? "font-weight-normal"
                            : "font-weight-bold"
                        }
                      >
                        {c.body}{" "}
                      </span>
                      <a
                        href={`/carmapdetail/${c.trip}/${moment(
                          c.created_at
                        ).format("YYYY-MM-DD")}/${
                          c.link.split("/").reverse()[1]
                        }/1`}
                        // target="_blank"
                        rel="noopener noreferrer"
                      >
                        <strong style={{ color: "rgb(199, 47, 47)" }}>
                          {" "}
                          {c.link.split("/").reverse()[1]}{" "}
                        </strong>
                      </a>
                    </a>
                  </li>
                </ul>

                <ul class="navbar-nav mx-auto hover_button">
                  <li class="nav-item   ">{t("ARCHIVE")}</li>

                  {c.isRead === false ? (
                    <li
                      class="nav-item   "
                      name="isRead"
                      onClick={(e) => this.handleSubmit(e, c.id)}
                    >
                      {t("Mark_as_read")}
                    </li>
                  ) : (
                    <li
                      class="nav-item   "
                      onClick={(e) => this.handleSubmit(e, c.id)}
                    >
                      {t("Marks_as_unread")}
                    </li>
                  )}
                  <li
                    class="nav-item   "
                    onClick={(e) => this.handleDelete(e, c.id)}
                  >
                    {t("delete")}
                  </li>
                </ul>
                <ul class="navbar-nav ml-auto">
                  <li class="nav-item active">
                    <span class="nav-link disabled">
                      {" "}
                      <span
                        style={{
                          fontSize: "13px",
                          opacity: "0.6",
                          marginTottom: "-22px",
                        }}
                      >
                        <i>
                          {" "}
                          <TimeAgo date={c.created_at} locale="pl" />
                        </i>
                      </span>
                    </span>
                  </li>
                  <li class="nav-item active">
                    {moment(c.created_at).format("DD-MM-YYYY") ===
                    moment().format("DD-MM-YYYY") ? (
                      <span class="nav-link  new" style={{ color: "#b71c1c" }}>
                        {" "}
                        New
                      </span>
                    ) : (
                      <span class="nav-link disabled"></span>
                    )}
                  </li>
                </ul>
              </nav>
            ))}
          </span>
        )}
      </div>
    );
  }
}

export default withTranslation()(All);
