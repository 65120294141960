import React, { useState } from "react";
import ImageMarker, { Marker, MarkerComponentProps } from "react-image-marker";
import { useTranslation } from "react-i18next";
import CarImage from "../../../../../img/carimage.png";
import Truck from "../../../../../img/Truck.png";
import Bike from "../../../../../img/Bike.png";
import Van from "../../../../../img/Van.png";
import cross from "../../../../../img/Cross.png";

function Marker1(props) {
  const [markers, setMarkers] = useState([]);
  const [markers1, setMarkers1] = useState([]);
  const [markers2, setMarkers2] = useState([]);
  const [markers3, setMarkers3] = useState([]);
  const [product, setProduct] = useState({ van: "car" });
  const CustomMarker = (props: MarkerComponentProps) => {
    return (
      <p className="custom-marker">
        {" "}
        <i className="fa fa-times "></i>
        {/* <span>{props.itemNumber + 1}</span> */}
      </p>
    );
  };
  const onChange = (event) => {
    const { name, value } = event.target;
    setProduct({ ...product, [name]: value });
  };

  const { t } = useTranslation();

  return (
    <div>
      <div id={"chart_score" + props.id} class="modal campaign_chart_marker">
        <a
          href="# "
          rel="modal:close"
          className="float-right text-dark "
          style={{ marginBottom: "0px" }}
        >
          <img
            src={cross}
            alt="Cross"
            style={{ height: "1.1em", marginTop: "30px" }}
          />
        </a>

        <div className="" style={{ marginTop: "5px", marginLeft: "-20px" }}>
          <div className="custom-control custom-radio custom-control-inline ">
            <input
              type="radio"
              name="van"
              id="something"
              value="car"
              defaultChecked
              onChange={onChange}
            />
            <label htmlFor="something">CAR</label>
          </div>
          <div
            class="custom-control custom-radio custom-control-inline"
            style={{ marginLeft: "20px" }}
          >
            <input
              type="radio"
              name="van"
              id="van"
              value="van"
              onChange={onChange}
            />
            <label htmlFor="van" className="line">
              VAN
            </label>
          </div>

          <div
            class="custom-control custom-radio custom-control-inline"
            style={{ marginLeft: "20px" }}
          >
            <input
              type="radio"
              name="van"
              id="truck"
              value="truck"
              onChange={onChange}
            />
            <label htmlFor="truck" className="line">
              TRUCK
            </label>
          </div>
          <div
            class="custom-control custom-radio custom-control-inline"
            style={{ marginLeft: "20px" }}
          >
            <input
              type="radio"
              name="van"
              id="bike"
              value="bike"
              onChange={onChange}
            />
            <label htmlFor="bike" className="line">
              MOTORCYCLE
            </label>
          </div>
        </div>
        <div
          className=""
          id="chart_score"
          style={{
            width: " 700px",
            height: "700px",
            marginTop: "10px",
            marginBottom: "0",
          }}
        >
          {product.van === "car" && (
            <ImageMarker
              src={CarImage}
              markers={markers}
              onAddMarker={(marker: Marker) => setMarkers([...markers, marker])}
              markerComponent={CustomMarker}
            />
          )}
          {product.van === "truck" && (
            <ImageMarker
              src={Truck}
              markers={markers1}
              onAddMarker={(marker: Marker) =>
                setMarkers1([...markers1, marker])
              }
              markerComponent={CustomMarker}
            />
          )}
          {product.van === "bike" && (
            <ImageMarker
              src={Bike}
              markers={markers2}
              onAddMarker={(marker: Marker) =>
                setMarkers2([...markers2, marker])
              }
              markerComponent={CustomMarker}
            />
          )}
          {product.van === "van" && (
            <ImageMarker
              src={Van}
              markers={markers3}
              onAddMarker={(marker: Marker) =>
                setMarkers3([...markers3, marker])
              }
              markerComponent={CustomMarker}
            />
          )}
        </div>

        <center>
          <a
            href="# "
            id="close"
            className="btn btn-danger btn-lg bb1"
            onClick={props.click}
            style={{ position: "relative", marginTop: "-200px" }}
            onLoad={props.click}
          >
            {t("save").toUpperCase()}
          </a>
        </center>
      </div>
    </div>
  );
}

export default Marker1;
