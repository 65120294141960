import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Filter from "../../Filter";
import Search from "../../../Cars/Search";
import print from "../../../../img/Rating/print.png";
import print_pl from "../../../../img/Rating/print_pl.png";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import User from "../../../../Components/User";
import getPrint from "../../../../Service/Arrow/Print";
import Datee from "../../../Views/Date";
import { ButtonToolbar, OverlayTrigger, Popover } from "react-bootstrap";
import Insurance from "../../../../Components/Layout/Insurance";
import DateGte from "../../../../Service/Arrow/DateGte";
import DateLte from "../../../../Service/Arrow/DateL";
import Spinner from "../../../../Container/Spinner";
import { getClaim } from "../../../../Service/Claim";
// import Doc from "../Car/Image/Doc";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);
class Claim extends Component {
  constructor(props) {
    super(props);
    const today = moment();
    this.state = {
      isLoading: true,
      lol: null,
      fol: null,
      isOpen: false,
      type: "",
      status: "",
      neww: [],
      claim: [],
      photo1: [],
      today: null,
      value: moment.range(today.clone().subtract(0, "days"), today.clone()),
    };
  }

  getFleetClaim = () => {
    getClaim(
      DateGte(this.state.today),
      DateLte(this.state.today),
      this.state.status,
      this.state.type
    ).then((data) => {
      this.setState({
        claim: data,
        photo1: data.photoclaim,
        isLoading: false,
      });
    });
  };

  async onChange(e) {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.getFleetClaim(" ");
  }

  componentDidMount() {
    this.getFleetClaim(" ");
  }

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }
  render() {
    const { t } = this.props;
    const { claim } = this.state;
    const hasInsuranceImage = (Color) =>
      Color.photoclaim.filter((c) => {
        return c.category === "insurance-police";
      }).length > 0;

    const hasDamageImage = (Color) =>
      Color.photoclaim.filter((c) => {
        return c.category === "damaged-photo";
      }).length > 0;

    const hasAdditionalImage = (Color) =>
      Color.photoclaim.filter((c) => {
        return c.category === "additional-documents";
      }).length > 0;

    const base = process.env.PUBLIC_URL;

    return (
      <div className="Main_section">
        <Helmet>
          <title>Insurance Claim List</title>
        </Helmet>

        <div
          className="row row1"
          style={{
            width: "100%",
            marginTop: "-20px",
            marginBottom: "-40px",
          }}
        >
          {" "}
          <div className="col-sm-3 date_time">
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
          <div className="col-sm-6 date_time">
            <Filter
              lol={this.state.lol}
              fol={this.state.fol}
              today={this.state.today}
              option={this.state.isOpen}
              toggle={this.onToggle}
              change={this.getData}
              hello={this.onChange}
            />
          </div>
        </div>
        <div className="float-right" style={{ marginTop: "65px" }}>
          <a className="btn" id="b" href={base + "/add/app/claim/insurance/"}>
            + {t("add_claim").toUpperCase()}
          </a>
        </div>
        <Insurance />

        <div className="headerr" style={{ marginTop: "-6px" }}>
          <div
            className=" float-right "
            style={{ width: "100%", marginBottom: "-30px" }}
          >
            <span
              className="nav-item  float-right  "
              disabled
              style={{ display: "flex" }}
            >
              <Search /> &nbsp; &nbsp;
              <a href="# " onClick={getPrint}>
                <img
                  src={
                    window.localStorage.i18nextLng === "en" ? print : print_pl
                  }
                  alt="contact"
                  style={{ marginTop: "-4px", height: "3em" }}
                />
              </a>
            </span>
          </div>
          {/* Check There is data or not */}

          <div
            className="caaar table-responsive"
            style={{ width: "100%", marginTop: "60px" }}
          >
            {claim.length > 0 ? (
              ""
            ) : (
              <span className="font-weight-bold">{t("no_data")}</span>
            )}
            {this.state.isLoading === true ? (
              <Spinner />
            ) : (
              <table
                id="headerr"
                className="table  table-hover tabell12"
                style={{ marginTop: "25px" }}
              >
                <thead>
                  <tr style={{ marginTop: "-88px" }}>
                    <th className="t" style={{ width: "20px" }}>
                      {t("Id")}
                    </th>
                    <th className="t"> {t("damage_date")}</th>
                    <th className="t">{t("claim_date")}</th>
                    <th className="t">{t("insurance_no")}</th>
                    <th className="t">{t("description")}</th>
                    {/* <th className="t">{t("vin")}</th>

                    <th className="t">{t("plate_number1")}</th> */}
                    <th className="t">
                      <select
                        name="type"
                        onChange={this.onChange.bind(this)}
                        className="minimal"
                        style={{
                          marginTop: "12px",
                          position: "relative",
                          top: "5px",
                          width: "150px",
                        }}
                      >
                        <option value="">{t("type_of_claims_OC/AC")}</option>
                        <option value="OC">OC</option>
                        <option value="AC">AC </option>
                      </select>
                    </th>
                    <th className="t">{t("repair_cost")}</th>
                    <th className="t">{t("Damage_photo")}</th>
                    <th className="t">{t("insurance_police_photo")}</th>
                    <th className="t">{t("addition_documents")}</th>

                    <th className="t">{t("liquid_status")}</th>

                    {/* <th className="t">{t("see_all")}</th> */}

                    <th className="t">
                      <select
                        name="status"
                        onChange={this.onChange.bind(this)}
                        className="minimal"
                        style={{ width: "130px", top: "5px" }}
                      >
                        <option value=""> {t("status")}</option>
                        <option value="active">{t("active")} </option>
                        <option value="inactive">{t("disable")}</option>
                      </select>
                    </th>
                  </tr>
                </thead>

                {/* <Search load={this.onChange.bind(this)} vall={this.state} /> */}

                <tbody>
                  {claim.map((c, i) => (
                    <tr key={i + 1}>
                      <td>{i + 1}</td>
                      <td>{c.damage_date ? c.damage_date : "-"}</td>
                      <td>{c.date_claim ? c.date_claim : "-"}</td>
                      <td>
                        <a
                          href={
                            base + "/view/app/claim/insurance/" + c.id + "/"
                          }
                          style={{ color: "#b71c1c" }}
                          className="font-weight-bold"
                        >
                          {c.insurance_number ? c.insurance_number : "-"}
                        </a>
                      </td>
                      <td
                        style={{
                          width: "200px",
                          position: "relative",
                          top: "-4px",
                        }}
                      >
                        <ButtonToolbar>
                          <OverlayTrigger
                            trigger="click"
                            key="top"
                            placement="left"
                            overlay={
                              <Popover id="popover-positioned-top">
                                <Popover.Content>
                                  {c.description}
                                </Popover.Content>
                              </Popover>
                            }
                          >
                            <button className="btn">
                              {" "}
                              {c.description
                                ? c.description.substring(0, 20)
                                : "-"}
                            </button>
                          </OverlayTrigger>
                        </ButtonToolbar>
                      </td>
                      {/* <td> {c.car_vin ? c.car_vin : "-"}</td>
                      <td> {c.car_plate_number ? c.car_plate_number : "-"}</td> */}
                      <td>{c.type_ac_oc ? c.type_ac_oc : "-"}</td>
                      <td>{c.net_value ? c.net_value.toFixed(1) : "-"}</td>
                      <td>
                        <label class="container1q">
                          <input type="checkbox" checked={hasDamageImage(c)} />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td>
                        {" "}
                        <label class="container1q">
                          <input
                            type="checkbox"
                            checked={hasInsuranceImage(c)}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td>
                        {" "}
                        <label class="container1q">
                          <input
                            type="checkbox"
                            checked={hasAdditionalImage(c)}
                          />
                          <span class="checkmark"></span>
                        </label>
                      </td>
                      <td>50%</td>

                      {/* <td>
                        <Doc claim={c} id={i + 1} />
                        <a
                          href={`#contact${i + 1}`}
                          rel="modal:open"
                          className={"btn btn-see"}
                          id="dd"
                        >
                          {t("see")}
                        </a>
                      </td> */}

                      <td>
                        <button
                          className={
                            c.status === "active"
                              ? "btn btn-success"
                              : "btn btn-danger"
                          }
                          id="dd"
                        >
                          {c.status === "active"
                            ? t("active_status")
                            : t("inactive_status")}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Claim));
