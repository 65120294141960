import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import arrow from "../../img/arrow.png";
import { getCampaign } from "../../Service/Campaign";
import compareByAsc from "../../Service/Arrow/Sort/Asc";
import compareByDesc from "../../Service/Arrow/Sort/Desc";
import Spinner from "../../Container/Spinner";
class Campaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      camp: [],
      status: "",
      isLoading: true,
    };
  }

  onSubmit() {
    getCampaign(this.state.status).then((data) => {
      this.setState({ camp: data, isLoading: false });
    });
  }

  async onChange(e) {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.onSubmit("");
  }

  componentDidMount() {
    this.onSubmit("");
  }

  sortBy(key) {
    let arrayCopy = [...this.state.camp];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(compareByAsc(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(compareByDesc(key));
    }
    this.setState({ camp: arrayCopy });
  }

  render() {
    const { camp } = this.state;
    const { t, history } = this.props;
    const base = process.env.PUBLIC_URL;
    return (
      <div className="" style={{ marginTop: "30px" }}>
        <Helmet>
          <title>{t("Campaigns")}</title>
          <meta
            name="description"
            content="Campaigns pages from here we can add new campaigns and see overview of each campaigns "
          />
        </Helmet>
        <div style={{ padding: "10px" }}>
          <strong>{t("Campaigns").toUpperCase()}</strong> &nbsp;
        </div>

        <div className="float-right">
          <a className="btn" id="b" href={base + "/create/campaign/"}>
            {t("create_campaings").toUpperCase()}
          </a>
        </div>

        <div className="headerr" style={{ marginTop: "15px" }}>
          <div>
            {camp.length > 0 ? (
              camp
                .slice(0, 1)
                .map((c) => <strong hidden>{c.participantCount}</strong>)
            ) : (
              <strong>&nbsp;&nbsp; {t("no_data")}</strong>
            )}
          </div>
          {this.state.isLoading === true ? <Spinner /> : ""}
          <table className="table table-hover">
            <thead>
              <tr>
                <th style={{ width: "20px" }} className="sorttable_nosort">
                  {" "}
                  {t("Id")}
                </th>
                <th onClick={() => this.sortBy("title")}>
                  {t("name")}{" "}
                  <img src={arrow} alt="Arrow" style={{ height: "0.9em" }} />
                </th>
                <th onClick={() => this.sortBy("startDate")}>
                  {t("start_date")}{" "}
                  <img src={arrow} alt="Arrow" style={{ height: "0.9em" }} />
                </th>
                <th onClick={() => this.sortBy("endDate")}>
                  {t("end_date")}{" "}
                  <img src={arrow} alt="Arrow" style={{ height: "0.9em" }} />
                </th>
                <th onClick={() => this.sortBy("participantCount")}>
                  {t("participant_amount")}{" "}
                  <img src={arrow} alt="Arrow" style={{ height: "0.9em" }} />
                </th>
                <th onClick={() => this.sortBy("prizeAmount")}>
                  {t("prize_amount")}{" "}
                  <img src={arrow} alt="Arrow" style={{ height: "0.9em" }} />
                </th>
                <th className="t">
                  <select
                    name="status"
                    onChange={this.onChange.bind(this)}
                    style={{
                      position: "relative",
                      top: "0px",
                      width: "150px",
                    }}
                    class="minimal"
                  >
                    <option value=" " disabled hidden selected>
                      {t("status")}
                    </option>
                    <option value=""> {t("status")}</option>
                    <option value="active">{t("status1")}: Active </option>
                    <option value="finished">{t("status1")}: Finished </option>
                    <option value="planned">{t("status1")}: Planned </option>
                  </select>
                </th>
              </tr>
            </thead>

            <tbody>
              {camp.map((c) => (
                <tr
                  key={c.id}
                  onClick={() =>
                    history.push(
                      base + "/overview/campaign/" + c.id + "/" + c.title + "/"
                    )
                  }
                >
                  <td>{c.id}</td>
                  <td> {c.title ? `${c.title}` : "-"} </td>
                  <td>{c.startDate ? `${c.startDate}` : "-"}</td>
                  <td>{c.endDate ? `${c.endDate}` : "-"}</td>
                  <td>{c.participantCount ? `${c.participantCount}` : "-"}</td>
                  <td>{c.prizeAmount ? `${c.prizeAmount}` : "-"}</td>
                  <td>
                    <button
                      className={
                        c.status === "active"
                          ? "btn btn-success"
                          : `${
                              c.status === "finished"
                                ? "btn btn-secondary"
                                : "btn btn-info"
                            }`
                      }
                      id="dd"
                    >
                      {c.status ? `${c.status}` : "-"}
                      {c.status === "planned" && (
                        <span
                          className={c.status === "planned" ? "badge" : ""}
                          id="bad"
                          title="Need To Confirm"
                        >
                          i
                        </span>
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Campaign));
