import React from "react";
import TableExport from "tableexport";
import { useTranslation } from "react-i18next";
import cross from "../../../img/Cross.png";

function TripReport(props) {
  const handledownload = () => {
    TableExport(document.getElementsByTagName("table"), {
      headers: true,
      footers: true,
      formats: ["xlsx", "csv", "txt"],
      exportButtons: true,
      position: "bottom",
      trimWhitespace: false,
      filename: `${props.plate}_${props.load}`,
    });
  };

  const { t } = useTranslation();
  return (
    <div id="tripreport" class="modal">
      <a
        href="# "
        rel="modal:close"
        className="float-right text-dark "
        style={{ marginBottom: "90px" }}
      >
        <img src={cross} alt="Cross" style={{ height: "1.1em" }} />
      </a>
      <p className="mod" style={{ marginTop: "10px" }}>
        {t("trip_report").toUpperCase()} -{" "}
        <strong>
          {props.load} - {props.plate}
        </strong>
      </p>

      <div
        className="row"
        id="myInput"
        style={{ width: "100%", position: "relative", top: "-8%" }}
      >
        <table className="table" id="table">
          {props.mapa.map((c, i) => (
            <tbody key={i + 1} className="text-pelo">
              <p className="someth_text">
                {" "}
                {t("trip").toUpperCase()} {i + 1}{" "}
              </p>
              <tr className="text_hello">
                <td>
                  {t("from").toUpperCase()} - {c.startPlace} - {c.startTime}
                </td>
              </tr>

              <tr className="text_hello">
                <td>
                  {t("to").toUpperCase()} - {c.endPlace} - {c.endTime}
                </td>
              </tr>
              <tr className="text_hello">
                <td>
                  {" "}
                  {t("distance").toUpperCase()} - {c.mileage.toFixed(1)} km{" "}
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>

      <div style={{ marginTop: "-50px" }}>
        <center>
          <a
            href="# "
            type="submit"
            value="Get Data"
            className="btn btn-login text-white font-weight-bolder boxx  btn-vehicle"
            id="save"
            onClick={handledownload}
          >
            {t("save_file").toUpperCase()}
          </a>
        </center>
      </div>
    </div>
  );
}

export default TripReport;
