import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Spinner from "../../../../../Container/Spinner";

class OtherCar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { t } = this.props;

    return (
      <div>
        <h3 className="font-weight-bold h5">{t("other_car")}</h3>
        <hr></hr>

        {this.props.loading === true ? (
          <div style={{ position: "relative", marginTop: "100px" }}>
            <Spinner />
          </div>
        ) : (
          <div className="caaar">
            <table className="table">
              <thead style={{ backgroundColor: "#ababab" }}>
                <tr>
                  <th
                    className="t"
                    style={{
                      textAlign: "center",
                      backgroundColor: "#999393",
                      color: "white",
                      width: "5%",
                    }}
                  >
                    {t("id")}
                  </th>
                  <th
                    className="t"
                    style={{
                      textAlign: "center",
                      backgroundColor: "#999393",
                      color: "white",
                    }}
                  >
                    {t("plate_number")}
                  </th>
                  <th
                    className="t"
                    style={{
                      textAlign: "center",
                      backgroundColor: "#999393",
                      color: "white",
                    }}
                  >
                    {t("potential") + " [PLN]"}
                  </th>
                  <th
                    className="t"
                    style={{
                      textAlign: "center",
                      backgroundColor: "#999393",
                      color: "white",
                    }}
                  >
                    Level of charging infrastructure
                  </th>
                </tr>
              </thead>

              <tbody>
                {this.props.load.map((c, ii) => (
                  <tr key={c.car}>
                    <td style={{ textAlign: "center" }}>{ii + 1}</td>
                    <td style={{ textAlign: "center" }}>
                      <a
                        href="# "
                        style={{ color: "#ababab" }}
                        className="font-weight-bold"
                      >
                        {c.car_plate_number}
                      </a>
                    </td>
                    <td style={{ textAlign: "center" }}> {c.yearly_savings}</td>
                    <td style={{ textAlign: "center" }}>{c.charging}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(OtherCar);
