import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./index.css";
import "./active.js";

class Campaign extends Component {
  render() {
    const { t } = this.props;
    const baseUrl = process.env.PUBLIC_URL;
    return (
      <nav
        className="navbar navbar-expand-lg navbar-dark  "
        style={{ position: "relative", left: "-0.7%", marginBottom: "-7px" }}
      >
        <button
          className="navbar-toggler"
          style={{ background: "#13B760" }}
          type="button"
          data-toggle="collapse"
          data-target="#nnavbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="nnavbarSupportedContent">
          <ul
            className={
              window.location.pathname === baseUrl + "/create/campaign/" ||
              window.location.pathname === baseUrl + "/driver/create/campaign/"
                ? "navbar-nav mr-auto main_header dont"
                : "navbar-nav mr-auto main_header "
            }
          >
            <li className="nav-item  btnnn ">
              <a
                className="nav-link font-weight-bold ra  "
                href={baseUrl + "/active/campaign/"}
                style={{ color: "rgba(183, 28, 28, 1)", marginTop: "20px" }}
              >
                {t("active_campaign")}
              </a>
            </li>
            <li className="nav-item btnnn  ">
              <a
                className="nav-link font-weight-bold ra "
                disabled
                href={baseUrl + "/finished/campaign/"}
                style={{ color: "rgba(183, 28, 28, 1)", marginTop: "20px" }}
              >
                {t("finished_campaign")}
              </a>
            </li>
            <li className="nav-item btnnn  ">
              <a
                className="nav-link font-weight-bold ra "
                href={baseUrl + "/planned/campaign/"}
                disabled
                style={{ color: "rgba(183, 28, 28, 1)", marginTop: "20px" }}
              >
                {t("planned_campaign")}
              </a>
            </li>
          </ul>

          {window.location.pathname === baseUrl + "/create/campaign/" ||
          window.location.pathname === baseUrl + "/driver/create/campaign/" ? (
            ""
          ) : (
            <ul className="navbar-nav ml-auto main_header ">
              <li className="" style={{ marginTop: "20px" }}>
                <a
                  className="btn"
                  id="createCampaign"
                  href={baseUrl + "/create/campaign/"}
                >
                  {t("create_campaings").toUpperCase()}
                </a>
              </li>
            </ul>
          )}
        </div>
      </nav>
    );
  }
}

export default withTranslation()(Campaign);
