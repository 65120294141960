import React, { Component } from "react";
import Chart from "react-apexcharts";
import { withTranslation } from "react-i18next";

// import config from "../../../Main";

const colors = ["#f9c45c"];
export class Weather extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      options: {
        chart: {
          id: "Weather Conditions",
        },
        dataLabels: {
          enabled: false,
        },
        colors: colors,
        xaxis: {
          type: "category",
          labels: {
            style: {
              fontSize: "14px",
            },
          },

          categories: props.load.map((c) => {
            return c.label;
          }),
        },

        tooltip: {
          y: {
            formatter: (value) => {
              return Number.parseFloat(value).toFixed(0) + "%";
            },
          },
        },

        plotOptions: {
          bar: {
            columnWidth: "30%",
            endingShape: "flat",
            distributed: true,
          },
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            labels: {
              formatter: function (value) {
                return Number.parseFloat(value).toFixed(0) + " % ";
              },
            },
            axisBorder: {
              show: true,
            },
          },
        ],
        title: {
          align: "center",
          style: {
            fontSize: "17px",
            color: "black",
          },
        },
        labels: props.load.map((c) => {
          return c.label;
        }),
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: t("weather_condition"),
          data: props.load.map((c) => {
            return c.data;
          }),
        },
      ],
    };
  }

  render() {
    const { t } = this.props;

    return (
      <div className="col-sm-4">
        <h5
          className="text-left font-weight-normal"
          style={{ marginLeft: "26px", marginTop: "10px" }}
        >
          {t("weather_condition")}
        </h5>
        <Chart
          style={{ marginTop: "22px" }}
          options={this.state.options}
          series={this.state.series}
          type="bar"
          stacked="true"
          height="250"
        />
      </div>
    );
  }
}

export default withTranslation()(Weather);
