import React, { Component } from "react";
import { getCar } from "../../../../../Service/Car";
import { getDriver } from "../../../../../Service/Driver";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import Marker1 from "./Marker";
import colourStyles from "../../../../../Service/Arrow/Color";
import html2canvas from "html2canvas";
import "jquery";
import $ from "jquery";

export class Damage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      feet0: "fleet",
      feet1: "fleet",
      driver_detail0: "fleet",
      driver_detail1: "fleet",
      value0: "plate",
      value1: "plate",
      company0: "private",
      company1: "private",
      car_list: [],
      driver_list: [],
      isHidden: true,
    };
  }
  toggleHie() {
    this.setState({ isHidden: true });
  }
  toggleHidden() {
    this.setState({
      isHidden: false,
    });
  }

  handleChange = async (selectedOption) => {
    await this.setState({ value: selectedOption });
  };
  handleChange1 = async (selectedOption1) => {
    await this.setState({ value: selectedOption1 });
  };
  handleDriverChange = async (driverChange) => {
    await this.setState({ value: driverChange });
  };

  // async onChange(event) {
  //   await this.setState({
  //     [event.target.name]: event.target.value,
  //   });
  // }

  driverList() {
    getDriver().then((data) => {
      this.setState({ driver_list: data });
    });
  }

  carList() {
    getCar().then((data) => {
      this.setState({ car_list: data });
    });
  }

  handleImage1 = async (index) => {
    let cats = [...this.props.state.claim_details];

    const input = document.getElementById("chart_score");
    window.scrollTo(0, 0);
    await html2canvas(input).then((canvas) => {
      document.body.appendChild(canvas);

      const imgData = canvas.toDataURL("image/png");
      cats[index]["file"] = imgData;

      // window.open(imgData);
      // console.log(imgData);
    });
    window.scrollTo(
      0,
      document.body.scrollHeight || document.documentElement.scrollHeight
    );
    $(document.getElementById("close")).modal({
      clickClose: true,
    });
  };

  componentDidMount() {
    this.carList("");
    this.driverList("");
  }
  handleChangeCar = (idx) => (selectedOption) => {
    const { value } = selectedOption;

    const task_results = [...this.props.state.claim_details];
    task_results[idx]["car"] = value;
    this.setState({
      value: task_results,
    });
  };

  handleChangeVin = (idx) => (selectedOption1) => {
    const { label } = selectedOption1;

    const task_results = [...this.props.state.claim_details];
    task_results[idx]["car_vin"] = label;
    this.setState({
      label: task_results,
    });
  };
  handleChangeDriver = (idx) => (driverChange) => {
    const { value } = driverChange;

    const task_results = [...this.props.state.claim_details];
    task_results[idx]["driver"] = value;
    this.setState({
      value: task_results,
    });
  };

  async onChange(e, index) {
    if (
      [
        "driver_name",
        "address",
        "documents_number",
        "driving_licence_number",
        "phone_number",
        "email",
        "car_owner_number",
        "car_owner_address",
        "pesel_number",
        "nip_number",
        "car_vin_number",
        "other_car_vin_number",
        "company_email",
        "company_phone",
      ].includes(e.target.name)
    ) {
      let cats = [...this.props.state.claim_details];
      cats[index][e.target.name] = e.target.value;
      await this.setState({
        cats,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
    console.log(this.props.state.claim_details);
  }

  onFocus = (msg, e) => {
    const id = e.target.id;
    const msgId = id + "_msg";
    document.getElementById(msgId).innerHTML = msg;
  };

  onBlur = (e) => {
    const id = e.target.id;
    const msgId = id + "_msg";
    document.getElementById(msgId).innerHTML = "&nbsp;";
  };

  render() {
    const baseUrl = process.env.PUBLIC_URL;
    const { t } = this.props;
    const { car_list, driver_list } = this.state;
    let options1 = car_list.map(function (city) {
      return { value: city.id, label: city.plate_number };
    });
    let options2 = car_list.map(function (city) {
      return { value: city.vin, label: city.vin };
    });
    let driverList = driver_list.map(function (city) {
      return { value: city.id, label: city.name };
    });

    
    return (
      <div>
        {" "}
        {this.props.state.claim_details.map((inputField, index) => (
          <div>
            {" "}
            <div className="row">
              <div className="col-sm-3" key={`${inputField}~${index}`}>
                <h3
                  className="font-weight-normal"
                  style={{ marginTop: "30px" }}
                >
                  {t("car_details")}{" "}
                  {this.props.state.claim_details.length > 1 ? index + 1 : ""}
                  {window.location.pathname.includes(
                    baseUrl + "/edit/claim/insurance/"
                  ) ? (
                    ""
                  ) : (
                    <div className="float-right">
                      <div id="headerr">
                        <Marker1
                          id={index + 1}
                          print={this.props.print}
                          state={this.props.state}
                          file={inputField.file}
                          click={
                            this.props.state.claim_details.length > 1
                              ? () => this.handleImage1(1)
                              : () => this.handleImage1(0)
                          }
                        />

                        <a
                          href={`#chart_score${index + 1}`}
                          rel="modal:open"
                          style={{ color: "#b71c1c" }}
                        >
                          {" "}
                          <span className="h6"> {t("car_damage")} </span>
                          <i
                            className="fa fa-car "
                            style={{ color: "#b71c1c", fontSize: "19px" }}
                          ></i>
                        </a>
                      </div>
                    </div>
                  )}
                </h3>
                <hr></hr>

                <div
                  className=""
                  style={{ marginTop: "5px", marginLeft: "-20px" }}
                >
                  <div className="custom-control custom-radio custom-control-inline ">
                    <input
                      type="radio"
                      name={`feet${index}`}
                      id={`rr2f${index}`}
                      value="fleet"
                      defaultChecked
                      onChange={this.onChange.bind(this)}
                    />
                    <label htmlFor={`rr2f${index}`}>{t("from_fleet")}</label>
                  </div>
                  <div
                    class="custom-control custom-radio custom-control-inline"
                    style={{ marginLeft: "-15px" }}
                  >
                    <input
                      type="radio"
                      name={`feet${index}`}
                      id={`rr1u${index}`}
                      value="unknown"
                      onChange={this.onChange.bind(this)}
                    />
                    <label htmlFor={`rr1u${index}`} className="line">
                      {t("unknown_fleet")}
                    </label>
                  </div>
                </div>
                {this.state[`feet${index}`] === "fleet" && (
                  <div>
                    <label id="p" style={{ marginTop: "0px" }}>
                      {t("choose_one").toUpperCase()}
                      <span className="start_icon"> * </span>
                    </label>
                    <div
                      className=""
                      style={{ marginTop: "5px", marginLeft: "-20px" }}
                    >
                      <div className="custom-control custom-radio custom-control-inline ">
                        <input
                          type="radio"
                          name={`value${index}`}
                          // name="value"
                          id={`rr2${index}`}
                          value="plate"
                          defaultChecked
                          onChange={this.onChange.bind(this)}
                        />
                        <label htmlFor={`rr2${index}`}>
                          {t("plate_number1")}
                        </label>
                      </div>
                      <div
                        class="custom-control custom-radio custom-control-inline"
                        style={{ marginLeft: "-15px" }}
                      >
                        <input
                          type="radio"
                          name={`value${index}`}
                          id={`rr1${index}`}
                          value="vin"
                          onChange={this.onChange.bind(this)}
                        />
                        <label htmlFor={`rr1${index}`} className="line">
                          {t("vin")}
                        </label>
                      </div>
                    </div>
                  </div>
                )}

                {this.state[`feet${index}`] === "unknown" && (
                  <div className="col " style={{ marginLeft: "-15px" }}>
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("plate_number1").toUpperCase()}
                        <span className="start_icon"> * </span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        value={inputField.car_vin_number}
                        name="car_vin_number"
                        onChange={(e) => {
                          this.onChange(e, index);
                        }}
                        className="form-control"
                        onFocus={this.onFocus.bind(
                          this,
                          "please fill in the field"
                        )}
                        onBlur={this.onBlur.bind(this)}
                        id="car_vin_number"
                      />
                      <p style={{ color: "#b71c1c" }} id="car_vin_number_msg">
                        &nbsp;
                      </p>
                    </div>
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("vin")}
                        <span className="start_icon"> * </span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        value={inputField.other_car_vin_number}
                        name="other_car_vin_number"
                        onChange={(e) => {
                          this.onChange(e, index);
                        }}
                        className="form-control"
                        onFocus={this.onFocus.bind(
                          this,
                          "please fill in the field"
                        )}
                        onBlur={this.onBlur.bind(this)}
                        id="car_vin_number_lol"
                      />
                      <p
                        style={{ color: "#b71c1c" }}
                        id="car_vin_number_lol_msg"
                      >
                        &nbsp;
                      </p>
                    </div>
                  </div>
                )}

                {this.state[`feet${index}`] === "fleet" && (
                  <span>
                    {this.state[`value${index}`] === "plate" && (
                      <div className="col " style={{ marginLeft: "-15px" }}>
                        <Select
                          name="car"
                          //   value={inputField.car}
                          onChange={this.handleChangeCar(index)}
                          options={options1}
                          styles={colourStyles}
                          className="Select-menu-outer"
                          placeholder={t("plate_number1")}
                          defaultValue={{
                            label: inputField.car_plate_number
                              ? inputField.car_plate_number
                              : t("plate_number1"),
                            value: inputField.car,
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,

                            colors: {
                              ...theme.colors,
                              primary25: "#faeded",
                              primary: "#b71c1c",
                            },
                          })}
                        />
                      </div>
                    )}

                    {this.state[`value${index}`] === "vin" && (
                      <div className="col " style={{ marginLeft: "-15px" }}>
                        <Select
                          name="car_vin"
                          options={options2}
                          styles={colourStyles}
                          //   placeholder={t("vin")}
                          onChange={this.handleChangeVin(index)}
                          defaultValue={{
                            label: inputField.car_vin
                              ? inputField.car_vin
                              : t("vin"),
                            value: inputField.car_vin,
                          }}
                          className="Select-menu-outer"
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,

                            colors: {
                              ...theme.colors,
                              primary25: "#faeded",
                              primary: "#b71c1c",
                            },
                          })}
                        />
                      </div>
                    )}
                  </span>
                )}
                <div className="form-group" style={{ marginTop: "5px" }}>
                  <label id="p" for="exampleInputEmail1">
                    {t("policy_number").toUpperCase()}
                    <span className="start_icon"> * </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("type")}
                    value={inputField.documents_number}
                    onChange={(e) => {
                      this.onChange(e, index);
                    }}
                    name="documents_number"
                    className="form-control"
                    onFocus={this.onFocus.bind(
                      this,
                      "please fill in the field"
                    )}
                    onBlur={this.onBlur.bind(this)}
                    id="documents_number"
                  />
                  <p style={{ color: "#b71c1c" }} id="documents_number_msg">
                    &nbsp;
                  </p>
                </div>
              </div>

              <div className="col-sm-5">
                <h3
                  className="font-weight-normal"
                  style={{ marginTop: "30px" }}
                >
                  {t("driver_details")}{" "}
                  {this.props.state.claim_details.length > 1 ? index + 1 : ""}
                </h3>
                <hr></hr>
                <div
                  className=""
                  style={{ marginTop: "5px", marginLeft: "-20px" }}
                >
                  <div className="custom-control custom-radio custom-control-inline ">
                    <input
                      type="radio"
                      name={`driver_detail${index}`}
                      id={`rr2dd${index}`}
                      value="fleet"
                      defaultChecked
                      onChange={this.onChange.bind(this)}
                    />
                    <label htmlFor={`rr2dd${index}`}> {t("from_fleet")}</label>
                  </div>
                  <div
                    class="custom-control custom-radio custom-control-inline"
                    style={{ marginLeft: "20px" }}
                  >
                    <input
                      type="radio"
                      name={`driver_detail${index}`}
                      id={`rr1dd${index}`}
                      value="unknown"
                      onChange={this.onChange.bind(this)}
                    />
                    <label htmlFor={`rr1dd${index}`} className="line">
                      {t("unknown_fleet")}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    {this.state[`driver_detail${index}`] === "fleet" && (
                      <div className="form-group">
                        <label id="p" for="exampleInputEmail1">
                          {t("driver_name").toUpperCase()}
                          <span className="start_icon"> * </span>
                        </label>
                        <Select
                          name="car_vin"
                          options={driverList}
                          styles={colourStyles}
                          placeholder="Name"
                          defaultValue={{
                            label: inputField.driver_name
                              ? inputField.driver_name
                              : t("Name"),
                            value: inputField.driver,
                          }}
                          onChange={this.handleChangeDriver(index)}
                          className="Select-menu-outer"
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,

                            colors: {
                              ...theme.colors,
                              primary25: "#faeded",
                              primary: "#b71c1c",
                            },
                          })}
                        />
                      </div>
                    )}

                    {this.state[`driver_detail${index}`] === "unknown" && (
                      <div className="form-group">
                        <label id="p" for="exampleInputEmail1">
                          {t("driver_name").toUpperCase()}
                          <span className="start_icon"> * </span>
                        </label>
                        <input
                          type="text"
                          placeholder={t("type")}
                          name="driver_name"
                          value={inputField.driver_name}
                          onChange={(e) => {
                            this.onChange(e, index);
                          }}
                          className="form-control"
                          onFocus={this.onFocus.bind(
                            this,
                            "please fill in the field"
                          )}
                          onBlur={this.onBlur.bind(this)}
                          id="driver_name"
                        />
                        <p style={{ color: "#b71c1c" }} id="driver_name_msg">
                          &nbsp;
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("driver_licence_number").toUpperCase()}
                        <span className="start_icon"> * </span>
                      </label>
                      <input
                        type="text"
                        value={inputField.driving_licence_number}
                        onChange={(e) => {
                          this.onChange(e, index);
                        }}
                        placeholder={t("type")}
                        name="driving_licence_number"
                        className="form-control"
                        onFocus={this.onFocus.bind(
                          this,
                          "please fill in the field"
                        )}
                        onBlur={this.onBlur.bind(this)}
                        id="driving_licence_number"
                      />
                      <p
                        style={{ color: "#b71c1c" }}
                        id="driving_licence_number_msg"
                      >
                        &nbsp;
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("pesel_number").toUpperCase()}
                        <span className="start_icon"> * </span>
                      </label>
                      <input
                        type="text"
                        value={inputField.pesel_number}
                        placeholder={t("type")}
                        onChange={(e) => {
                          this.onChange(e, index);
                        }}
                        name="pesel_number"
                        className="form-control"
                        onFocus={this.onFocus.bind(
                          this,
                          "please fill in the field"
                        )}
                        onBlur={this.onBlur.bind(this)}
                        id="pesel_number"
                      />
                      <p style={{ color: "#b71c1c" }} id="pesel_number_msg">
                        &nbsp;
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("address").toUpperCase()}
                        <span className="start_icon"> * </span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        value={inputField.address}
                        onChange={(e) => {
                          this.onChange(e, index);
                        }}
                        name="address"
                        className="form-control"
                        onFocus={this.onFocus.bind(
                          this,
                          "please fill in the field"
                        )}
                        onBlur={this.onBlur.bind(this)}
                        id="address"
                      />
                      <p style={{ color: "#b71c1c" }} id="address_msg">
                        &nbsp;
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("email").toUpperCase()}
                        <span className="start_icon"> * </span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        value={inputField.email}
                        name="email"
                        onChange={(e) => {
                          this.onChange(e, index);
                        }}
                        className="form-control"
                        onFocus={this.onFocus.bind(
                          this,
                          "please fill in the field"
                        )}
                        onBlur={this.onBlur.bind(this)}
                        id="email"
                      />
                      <p style={{ color: "#b71c1c" }} id="email_msg">
                        &nbsp;
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("phone").toUpperCase()}
                        <span className="start_icon"> * </span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        value={inputField.phone_number}
                        onChange={(e) => {
                          this.onChange(e, index);
                        }}
                        name="phone_number"
                        className="form-control"
                        onFocus={this.onFocus.bind(
                          this,
                          "please fill in the field"
                        )}
                        onBlur={this.onBlur.bind(this)}
                        id="phone_number"
                      />
                      <p style={{ color: "#b71c1c" }} id="phone_number_msg">
                        &nbsp;
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <h3
                  className="font-weight-normal"
                  style={{ marginTop: "30px" }}
                >
                  {t("car_owner_details")}{" "}
                  {this.props.state.claim_details.length > 1 ? index + 1 : ""}
                </h3>
                <hr></hr>

                <div
                  className=""
                  style={{ marginTop: "5px", marginLeft: "-20px" }}
                >
                  <div className="custom-control custom-radio custom-control-inline ">
                    <input
                      type="radio"
                      name={`company${index}`}
                      id={`rr2p${index}`}
                      value="private"
                      defaultChecked
                      onChange={this.onChange.bind(this)}
                    />
                    <label htmlFor={`rr2p${index}`}> {t("private")} </label>
                  </div>
                  <div
                    class="custom-control custom-radio custom-control-inline"
                    style={{ marginLeft: "-15px" }}
                  >
                    <input
                      type="radio"
                      name={`company${index}`}
                      id={`rr1c${index}`}
                      value="company"
                      onChange={this.onChange.bind(this)}
                    />
                    <label htmlFor={`rr1c${index}`} className="line">
                      {t("company")}
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {this.state[`company${index}`] === "company"
                          ? t("company_name").toUpperCase()
                          : t("car_owner_name").toUpperCase()}
                        <span className="start_icon"> * </span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        value={inputField.car_owner_number}
                        onChange={(e) => {
                          this.onChange(e, index);
                        }}
                        name="car_owner_number"
                        className="form-control"
                        onFocus={this.onFocus.bind(
                          this,
                          "please fill in the field"
                        )}
                        onBlur={this.onBlur.bind(this)}
                        id="car_owner_number"
                      />
                      <p style={{ color: "#b71c1c" }} id="car_owner_number_msg">
                        &nbsp;
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {this.state[`company${index}`] === "company"
                          ? t("NIP").toUpperCase()
                          : t("pesel_number").toUpperCase()}
                        <span className="start_icon"> * </span>
                      </label>
                      <input
                        type="text"
                        value={inputField.nip_number}
                        placeholder={t("type")}
                        onChange={(e) => {
                          this.onChange(e, index);
                        }}
                        name="nip_number"
                        className="form-control"
                        onFocus={this.onFocus.bind(
                          this,
                          "please fill in the field"
                        )}
                        onBlur={this.onBlur.bind(this)}
                        id="nip_number"
                      />
                      <p style={{ color: "#b71c1c" }} id="nip_number_msg">
                        &nbsp;
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("email").toUpperCase()}
                        <span className="start_icon"> * </span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        value={inputField.company_email}
                        name="company_email"
                        onChange={(e) => {
                          this.onChange(e, index);
                        }}
                        className="form-control"
                        onFocus={this.onFocus.bind(
                          this,
                          "please fill in the field"
                        )}
                        onBlur={this.onBlur.bind(this)}
                        id="email"
                      />
                      <p style={{ color: "#b71c1c" }} id="email_msg">
                        &nbsp;
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("phone").toUpperCase()}
                        <span className="start_icon"> * </span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        value={inputField.company_phone}
                        onChange={(e) => {
                          this.onChange(e, index);
                        }}
                        name="company_phone"
                        className="form-control"
                        onFocus={this.onFocus.bind(
                          this,
                          "please fill in the field"
                        )}
                        onBlur={this.onBlur.bind(this)}
                        id="phone_number1"
                      />
                      <p style={{ color: "#b71c1c" }} id="phone_number1_msg">
                        &nbsp;
                      </p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {this.state[`company${index}`] === "company"
                          ? t("address").toUpperCase()
                          : t("address").toUpperCase()}
                        <span className="start_icon"> * </span>
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        value={inputField.car_owner_address}
                        onChange={(e) => {
                          this.onChange(e, index);
                        }}
                        name="car_owner_address"
                        className="form-control"
                        onFocus={this.onFocus.bind(
                          this,
                          "please fill in the field"
                        )}
                        onBlur={this.onBlur.bind(this)}
                        id="car_owner_address"
                      />
                      <p
                        style={{ color: "#b71c1c" }}
                        id="car_owner_address_msg"
                      >
                        &nbsp;
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        ))}
      </div>
    );
  }
}

export default withTranslation()(Damage);
