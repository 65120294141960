import React, { Component } from "react";
import Header1 from "../../../../Components/Layout/Header1";
import User from "../../../../Components/User";
import Datee from "../../../Views/Date";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import arrow_left from "../../../../img/Aarrow.png";
import print_pl from "../../../../img/Rating/print_pl.png";
import arrow from "../../../../img/arrow.png";
import print from "../../../../img/Rating/print.png";
import { withTranslation } from "react-i18next";
import { getCarDaily, getSummaryDaily } from "../../../../Service/Car";
import DateGte from "../../../../Service/Arrow/DateGte";
import DateLte from "../../../../Service/Arrow/DateL";
import compareByAsc from "../../../../Service/Arrow/Sort/Asc";
import compareByDesc from "../../../../Service/Arrow/Sort/Desc";
import getPrint from "../../../../Service/Arrow/Print";
import Spinner from "../../../../Container/Spinner";
import Filter from "../../Filter";
import originalMoment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(originalMoment);

class Cdetails extends Component {
  constructor(props) {
    super(props);
    const today = moment();
    this.state = {
      movie: [],
      speed: [],
      value1: "select",
      currentSort: "default",
      isOpen: false,
      today: null,
      lol: null,
      fol: null,
      isLoading: true,
      value: moment.range(today.clone().subtract(0, "days"), today.clone()),
    };
  }

  onToggle = (event) => {
    if (event.target.value === "date") {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  change(event) {
    this.setState({
      value1: event.target.value,
    });
  }

  // Get Data from filter date
  getData = async (value, states) => {
    Promise.all([
      getCarDaily(
        this.props.match.params.pk,
        value.end.format("YYYY-MM-DD"),
        value.start.format("YYYY-MM-DD")
      ).then((data) => {
        this.setState({
          movie: data,
          lol: value.start,
          fol: value.end,
          isOpen: !this.state.isOpen,
        });
      }),
      getSummaryDaily(
        this.props.match.params.pk,
        value.end.format("YYYY-MM-DD"),
        value.start.format("YYYY-MM-DD")
      ).then((data) => {
        this.setState({ speed: data });
      }),
    ]);
  };

  getOptionData = async () => {
    this.setState({ isLoading: true });
    Promise.all([
      getCarDaily(
        this.props.match.params.pk,
        DateGte(this.state.today),
        DateLte(this.state.today)
      ).then((data) => {
        this.setState({ movie: data, isLoading: false });
      }),
      getSummaryDaily(
        this.props.match.params.pk,
        DateGte(this.state.today),
        DateLte(this.state.today)
      ).then((data) => {
        this.setState({ speed: data });
      }),
    ]);
  };

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.getOptionData("");
  };

  componentDidMount() {
    this.getOptionData("");
  }

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  // Sorting Resolve

  sortBy(key) {
    let arrayCopy = [...this.state.movie];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(compareByAsc(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(compareByDesc(key));
    }
    this.setState({ movie: arrayCopy });
  }

  render() {
    const { movie, speed } = this.state;
    const { t } = this.props;
    const base = process.env.PUBLIC_URL;

    return (
      <div className="Main_section">
        <Helmet>
          {movie.slice(0, 1).map((c) => (
            <title>
              {c.plate_number ? `${c.plate_number}` : " Plate Number "}{" "}
            </title>
          ))}
        </Helmet>
        <div
          className="row row1"
          style={{ width: "100%", marginBottom: "-30px" }}
        >
          <div className="col-sm-3 date_time" style={{ marginTop: "5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
          <div className="col-sm-6 date_time">
            <Filter
              lol={this.state.lol}
              fol={this.state.fol}
              today={this.state.today}
              option={this.state.isOpen}
              toggle={this.onToggle}
              change={this.getData}
              hello={this.onChange}
            />
          </div>
        </div>
        <Header1 />

        <div className="headerr">
          <div className="drive_score">
            <span>
              <a href="# " onClick={this.handleClick.bind(this)}>
                <img
                  src={arrow_left}
                  alt="Left_Arrow"
                  style={{ height: "2.6em" }}
                />
              </a>{" "}
              &nbsp;
              {t("back")}
              &nbsp;&nbsp;&nbsp;
              {movie.length > 0 ? (
                movie.slice(0, 1).map((c) => (
                  <span>
                    {" "}
                    {t("my_cars")} /<strong>{c.plate_number}</strong>
                  </span>
                ))
              ) : (
                <strong>&nbsp;&nbsp; {t("no_data")}</strong>
              )}
            </span>
            <span className="float-right">
              <a href="# " onClick={getPrint}>
                <img
                  src={
                    window.localStorage.i18nextLng === "en" ? print : print_pl
                  }
                  alt="Print page"
                  style={{ marginTop: "-4px", height: "3em" }}
                />
              </a>
            </span>
          </div>
          {/* Start Upper portion */}

          {/* End Upper portion */}
          <div className="caaar table-responsive" style={{ marginTop: "30px" }}>
            {this.state.isLoading ? (
              <Spinner />
            ) : this.state.movie.length ? (
              <table
                className="table table-hover tabell12"
                style={{ width: "100%" }}
              >
                <thead style={{ position: "relative" }}>
                  <tr className="th">
                    <th className="t" style={{ width: "5%" }}>
                      {t("Id")}
                    </th>
                    <th className="t" onClick={() => this.sortBy("date")}>
                      {t("date")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                    <th
                      className="t"
                      onClick={() => this.sortBy("milage")}
                      style={{ width: "105px" }}
                    >
                      {t("Milage")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>

                    {this.props.match.params.type === "BEV" ? (
                      <th
                        className="t"
                        onClick={() => this.sortBy("fuel_consumed")}
                      >
                        {t("energy_cosumed")}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>
                    ) : (
                      <th
                        className="t"
                        onClick={() => this.sortBy("fuel_consumed")}
                      >
                        {t("FuelComsumed")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>
                    )}

                    {this.props.match.params.type === "BEV" ? (
                      <th
                        className="t"
                        onClick={() => this.sortBy("fuel_consumed")}
                      >
                        {t("fuel_eq")}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>
                    ) : (
                      ""
                    )}

                    {this.props.match.params.type === "BEV" ? (
                      <th
                        className="t "
                        onClick={() => this.sortBy("average_fuel_consumed")}
                      >
                        {t("average_energy_cosumed")}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>
                    ) : (
                      <th
                        className="t "
                        onClick={() => this.sortBy("average_fuel_consumed")}
                      >
                        {t("AverageFuel")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>
                    )}

                    <th
                      className="t"
                      onClick={() => this.sortBy("overspeeding_percentage")}
                    >
                      {t("OverspeedingDistance")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>

                    <th
                      className="t"
                      onClick={() => this.sortBy("off_zone_driving")}
                    >
                      <select
                        onChange={this.change.bind(this)}
                        value={this.state.value1}
                        class="minimal"
                        style={{
                          width: "220px",
                          top: "5px",
                        }}
                      >
                        <option value="4"> {t("off_zone_driving")} </option>
                        <option value="5"> {t("off_zone_fuel")} </option>
                        <option value="6"> {t("off_zone_milage")} </option>
                      </select>
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>

                    <th className="t">
                      <select
                        onChange={this.change.bind(this)}
                        value={this.state.value1}
                        class="minimal"
                        style={{
                          width: "220px",
                          top: "5px",
                        }}
                      >
                        <option value="7"> {t("off_hour_driving")} </option>
                        <option value="9"> {t("off_hour_fuel")} </option>
                        <option value="8"> {t("off_hour_milage")} </option>
                      </select>
                      <img
                        src={arrow}
                        onClick={() =>
                          this.state.value1 === "7"
                            ? this.sortBy("off_hour_driving")
                            : this.state.value1 === "8"
                            ? this.sortBy("off_hour_milage")
                            : this.state.value1 === "9"
                            ? this.sortBy("off_hour_consumed")
                            : this.sortBy("off_hour_driving")
                        }
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                    <th
                      className=" t sorttable_nosort"
                      style={{ width: "1%" }}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {movie.map((c, i) => (
                    <tr
                      key={c.id}
                      className={
                        c.date
                          ? `${
                              new Date(c.date).getDay() === 6 ||
                              new Date(c.date).getDay() === 0
                                ? "driver_color"
                                : "text"
                            } `
                          : "-"
                      }
                    >
                      <td>{i + 1}</td>
                      <td style={{ width: "100px" }}>
                        {c.date ? `${c.date}` : "00-00-0000"}
                      </td>

                      <td>{c.milage ? `${c.milage.toFixed(1)}` : 0}</td>
                      {this.props.match.params.type === "BEV" ? (
                        <td>
                          {c.energy_consumed
                            ? `${c.energy_consumed.toFixed(1)}`
                            : 0}
                        </td>
                      ) : (
                        <td>
                          {c.fuel_consumed
                            ? `${c.fuel_consumed.toFixed(1)}`
                            : 0}
                        </td>
                      )}

                      {this.props.match.params.type === "BEV" ? (
                        <td>{c.fuel_eq ? `${c.fuel_eq.toFixed(1)}` : 0}</td>
                      ) : (
                        ""
                      )}

                      {this.props.match.params.type === "BEV" ? (
                        <td>
                          {c.average_energy_consumed
                            ? `${c.average_energy_consumed.toFixed(1)}`
                            : 0}
                        </td>
                      ) : (
                        <td>
                          {c.average_fuel_consumed
                            ? `${c.average_fuel_consumed.toFixed(1)}`
                            : 0}
                        </td>
                      )}
                      <td>
                        {c.overspeeding_percentage
                          ? `${c.overspeeding_percentage.toFixed(1)}`
                          : 0}
                      </td>
                      {/* <td>{c.dtc ? `${c.dtc}` : 0}</td> */}
                      <td>
                        {this.state.value1 === "4"
                          ? `${
                              c.off_zone_driving
                                ? `${c.off_zone_driving.toFixed(1)}`
                                : "-"
                            }`
                          : `${
                              this.state.value1 === "5"
                                ? `${
                                    c.off_zone_milage
                                      ? `${c.off_zone_milage.toFixed(1)}`
                                      : "-"
                                  }`
                                : `${
                                    this.state.value1 === "6"
                                      ? `${
                                          c.off_zone_fuel_consumed
                                            ? `${c.off_zone_fuel_consumed.toFixed(
                                                1
                                              )}`
                                            : "-"
                                        }`
                                      : `${
                                          c.off_zone_driving
                                            ? `${c.off_zone_driving.toFixed(1)}`
                                            : "-"
                                        }`
                                  }`
                            }`}
                      </td>
                      <td>
                        {this.state.value1 === "7"
                          ? `${
                              c.off_hour_driving
                                ? `${c.off_hour_driving.toFixed(1)}`
                                : "-"
                            }`
                          : `${
                              this.state.value1 === "8"
                                ? `${
                                    c.off_hour_milage
                                      ? `${c.off_hour_milage.toFixed(1)}`
                                      : "-"
                                  }`
                                : `${
                                    this.state.value1 === "9"
                                      ? `${
                                          c.off_hour_consumed
                                            ? `${c.off_hour_consumed.toFixed(
                                                1
                                              )}`
                                            : "-"
                                        }`
                                      : `${
                                          c.off_hour_driving
                                            ? `${c.off_hour_driving.toFixed(1)}`
                                            : "-"
                                        }`
                                  }`
                            }`}
                      </td>
                      <td>
                        <a
                          href={
                            base + `/carmap/${c.id}/${c.date}/${c.plate_number}`
                          }
                          style={{ color: "#b71c1c" }}
                        >
                          {t("map")}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot className="thead">
                  <tr>
                    <th className="sorttable_nosort"></th>
                    <th className="sorttable_nosort">
                      {" "}
                      {t("Summary1").toUpperCase()}
                    </th>

                    <th className="sorttable_nosort">
                      {speed.milage ? `${speed.milage.toFixed(1)}` : "-"}
                    </th>

                    {this.props.match.params.type === "BEV" ? (
                      <th className="sorttable_nosort">
                        {speed.energy_consumed
                          ? `${speed.energy_consumed.toFixed(1)}`
                          : "-"}
                      </th>
                    ) : (
                      <th className="sorttable_nosort">
                        {speed.fuel_consumed
                          ? `${speed.fuel_consumed.toFixed(1)}`
                          : "-"}
                      </th>
                    )}

                    {this.props.match.params.type === "BEV" ? (
                      <th>
                        {speed.fuel_eq ? `${speed.fuel_eq.toFixed(1)}` : 0}
                      </th>
                    ) : (
                      ""
                    )}

                    {this.props.match.params.type === "BEV" ? (
                      <th className="sorttable_nosort">
                        {speed.average_energy_consumed
                          ? `${speed.average_energy_consumed.toFixed(1)}`
                          : "-"}
                      </th>
                    ) : (
                      <th className="sorttable_nosort">
                        {speed.average_fuel_consumed
                          ? `${speed.average_fuel_consumed.toFixed(1)}`
                          : "-"}
                      </th>
                    )}

                    <th className="sorttable_nosort">
                      {speed.overspeeding_percentage
                        ? `${speed.overspeeding_percentage.toFixed(1)}`
                        : "-"}
                    </th>
                    <th className="sorttable_nosort">
                      {" "}
                      {this.state.value1 === "4"
                        ? `${
                            speed.off_zone_driving
                              ? `${speed.off_zone_driving.toFixed(1)}`
                              : "-"
                          }`
                        : `${
                            this.state.value1 === "5"
                              ? `${
                                  speed.off_zone_milage
                                    ? `${speed.off_zone_milage.toFixed(1)}`
                                    : "-"
                                }`
                              : `${
                                  this.state.value1 === "6"
                                    ? `${
                                        speed.off_zone_fuel_consumed
                                          ? `${speed.off_zone_fuel_consumed.toFixed(
                                              1
                                            )}`
                                          : "-"
                                      }`
                                    : `${
                                        speed.off_zone_driving
                                          ? `${speed.off_zone_driving.toFixed(
                                              1
                                            )}`
                                          : "-"
                                      }`
                                }`
                          }`}
                    </th>
                    <th className="sorttable_nosort">
                      {this.state.value1 === "7"
                        ? `${
                            speed.off_hour_driving
                              ? `${speed.off_hour_driving.toFixed(1)}`
                              : "-"
                          }`
                        : `${
                            this.state.value1 === "8"
                              ? `${
                                  speed.off_hour_milage
                                    ? `${speed.off_hour_milage.toFixed(1)}`
                                    : "-"
                                }`
                              : `${
                                  this.state.value1 === "9"
                                    ? `${
                                        speed.off_hour_consumed
                                          ? `${speed.off_hour_consumed.toFixed(
                                              1
                                            )}`
                                          : "-"
                                      }`
                                    : `${
                                        speed.off_hour_driving
                                          ? `${speed.off_hour_driving.toFixed(
                                              1
                                            )}`
                                          : "-"
                                      }`
                                }`
                          }`}
                    </th>
                    <th className="sorttable_nosort"></th>
                  </tr>
                </tfoot>
              </table>
            ) : (
              <p
                className="text-center h4 font-weight-bolder"
                style={{ marginTop: "30px" }}
              >
                {t("no_data")}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Cdetails));
