import React from "react";
import Header from "./Header";
import arrow_left from "../../../img/Aarrow.png";
import WorkingR from "../../../views/Views/Progress/WorkingR";

function Terms() {
  const baseUrl = process.env.PUBLIC_URL;
  return (
    <div className="bg-white">
      <Header />
      <div className="Main_section1" style={{ minHeight: "60vh" }}>
        <div className="text_section">
          <div className="first_para">
            <a
              href={
                localStorage.getItem("Token")
                  ? baseUrl + "/fleet/dashboard/"
                  : baseUrl + "/"
              }
              className="float-left back_to_home"
            >
              {" "}
              <img
                src={arrow_left}
                alt="Left_Arrow"
                style={{ height: "2em" }}
              />
              Back to home{" "}
            </a>
            <a
              href="# "
              className="float-right print_page"
              onClick={() => window.print()}
            >
              {" "}
              <i className="fa fa-print"></i> Print{" "}
            </a>
          </div>
        </div>
        <div className="second_para">
          <WorkingR />
        </div>
      </div>
    </div>
  );
}

export default Terms;
