import React, { Component } from "react";
import { Helmet } from "react-helmet";
import config from "../../../Main";
import ProfileTop from "../../../../Components/Layout/ProfileTop";
import { withRouter } from "react-router-dom";
import Search from "../../../CarMetrics/Search";
import { withTranslation } from "react-i18next";
import Spinner from "../../../../Container/Spinner";
import User from "../../../../Components/User";
import Datee from "../../../Views/Date";

class Eprofile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: [],
      value: "select",
      vin: " ",
      plate: " ",
      brand: " ",
      model: " ",
      location: "",
      tmonth: "",
      tyear: "",
      imonth: "",
      iyear: "",
      year: "",
      engine: "",
      staus: "",
      isLoading: true,
    };
  }

  async onSubmitt1(e) {
    // this.setState({ isLoading: true });
    const res = await fetch(
      `${config.apiUrl.car}?brand__icontains=${this.state.brand}&location__icontains=${this.state.location}&model__icontains=${this.state.model}&vin__icontains=${this.state.vin}&license_plate__icontains=${this.state.plate}&production_year=${this.state.year}&type=${this.state.engine}&active=${this.state.staus}&next_registration_exam__month=${this.state.tmonth}&next_registration_exam__year=${this.state.tyear}&insurance_expiration__month=${this.state.imonth}&insurance_expiration__year=${this.state.iyear}`,
      config.head
    );
    const stats = await res.json();
    this.setState({
      stats,
      isLoading: false,
    });
  }

  async onChange(e) {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.onSubmitt1("");
  }

  componentDidMount() {
    this.onSubmitt1("");
  }

  render() {
    const { stats } = this.state;
    const { t } = this.props;
    const energy = stats.filter((player) => player.type.includes("BEV"));
    const base = process.env.PUBLIC_URL;

    return (
      <div className="Main_section" style={{ marginTop: "30px" }}>
        <Helmet>
          <title>{t("CarMetrics")}</title>
        </Helmet>
        <div>
          &nbsp;{" "}
          <div
            className="row row1"
            style={{ width: "100%", marginTop: "-30px", marginBottom: "-40px" }}
          >
            {" "}
            <div className="col-sm-3 date_time">
              <strong>
                {t("Hello")}, <User />
              </strong>
              <Datee />
            </div>
          </div>
        </div>

        <ProfileTop />

        <div className="headerr" style={{ marginTop: "-6px" }}>
          <div>
            {energy.length > 0 ? (
              energy
                .slice(0, 1)
                .map((c) => <strong hidden>{c.participantCount}</strong>)
            ) : (
              <strong>&nbsp;&nbsp; {t("no_data")}</strong>
            )}
          </div>
          <div className="caaar" style={{ marginTop: "0px", width: "100%" }}>
            {this.state.isLoading === true ? (
              <Spinner />
            ) : (
              <table className="table tabell12  table-hover">
                <thead>
                  <tr style={{ marginTop: "-88px" }}>
                    <th className="" style={{ width: "20px" }}>
                      {t("Id")}
                    </th>
                    <th className="">{t("vin")}</th>
                    <th className="">{t("plate_number1")}</th>

                    <th className="">{t("Brand")}</th>
                    <th className="">{t("Model")}</th>
                    <th className="t">{t("production_year")}</th>
                    <th className="t">{t("technical_inspection")}</th>
                    <th className="t" style={{ width: "90px" }}>
                      {t("insurance_policy")}
                    </th>
                    <th>{t("location")}</th>
                    <th className="t">
                      <select
                        name="staus"
                        onChange={this.onChange.bind(this)}
                        className="minimal"
                        style={{ width: "130px", top: "0px" }}
                      >
                        <option value=" "> {t("status")}</option>
                        <option value="true">{t("active")} </option>
                        <option value="false">{t("disable")}</option>
                      </select>
                    </th>
                  </tr>
                </thead>

                <Search load={this.onChange.bind(this)} vall={this.state} />

                <tbody>
                  {energy.length > 0 ? (
                    energy.map((c, i) => (
                      <tr key={c.id}>
                        <td>{i + 1}</td>
                        <td>{c.vin ? `${c.vin}` : "-"}</td>
                        <td>
                          <a
                            href={
                              base + "/overview/app/profile/electric/" + c.id
                            }
                            style={{ color: "#b71c1c" }}
                            className="font-weight-bold"
                          >
                            {c.plate_number ? `${c.plate_number}` : "-"}
                          </a>
                        </td>
                        {/* <td>{c.type ? `${c.type}` : "-"}</td> */}
                        <td>{c.brand ? `${c.brand}` : "-"}</td>
                        <td>{c.model ? `${c.model}` : "-"}</td>
                        <td>
                          {c.production_year ? `${c.production_year}` : "-"}
                        </td>
                        <td>
                          {c.next_registration_exam
                            ? `${c.next_registration_exam}`
                            : "-"}
                        </td>
                        <td>
                          {c.insurance_expiration
                            ? `${c.insurance_expiration}`
                            : "-"}
                        </td>
                        <td>{c.location ? `${c.location}` : "-"}</td>
                        <td>
                          <button
                            className={
                              c.status === "active"
                                ? "btn btn-success"
                                : "btn btn-danger"
                            }
                            id="dd"
                          >
                            {c.status}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div></div>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Eprofile));
