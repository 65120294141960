import React, { Component } from "react";

import { getSegment } from "../../../../../Service/Segment";
import CarCount from "./CarCount";
import CarCount1 from "./CarCount1";
import CarCount2 from "./CarCount2";
import CarCount3 from "./CarCount3";
import CarCount4 from "./CarCount4";

export class SegmentView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      segment: [],
      car: [],
      driver: [],
      parameter: [],
    };
  }

  segmentData() {
    getSegment().then((data) => {
      this.setState({ segment: data, parameter: data.parameter_detail });
    });
  }
  componentDidMount() {
    this.segmentData("");
  }

  render() {
    return (
      <div>
        <CarCount parameter={this.state.segment} />
        <CarCount1 parameter={this.state.segment} />
        <CarCount2 parameter={this.state.segment} />
        <CarCount3 parameter={this.state.segment} />
        <CarCount4 parameter={this.state.segment} />
      </div>
    );
  }
}

export default SegmentView;
