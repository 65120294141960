import React, { PureComponent } from "react";
import Chart from "react-apexcharts";
import { withTranslation } from "react-i18next";
import config from "../../../Main";
import Spinner from "../../../../Container/Spinner";

import { withRouter } from "react-router";
import Cookies from "universal-cookie";
const cookie = new Cookies();
let authToken = cookie.get("Token");
const colors = ["#5ae309", "#ababab"];

class Fleet extends PureComponent {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      options: {
        chart: {
          type: "pie",
        },
        a: 0,
        labels: [t("Available.to.change.to.EV.car"), t("Not.available.to.change")],
        dataLabels: {
          enabled: false,
        },
        colors: colors,
        title: {
          align: "center",
          style: {
            fontSize: "17px",
            color: "black",
          },
        },

        tooltip: {
          min: 0.0,
          max: 100.0,
          y: {
            formatter: function (val) {
              return Number.parseFloat(val).toFixed(2) + "%";
            },
          },
        },

        legend: {
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          horizontalAlign: "center",
          floating: false,

          position: "right",

          min: -2.01,
          max: 5.17,

          formatter: function (val, opts) {
            return (
              val +
              " - " +
              Number.parseFloat(
                opts.w.globals.series[opts.seriesIndex]
              ).toFixed(2) +
              "%" +
              " (" +
              Number.parseFloat(
                (props.state.ev.length *
                  Number.parseFloat(
                    opts.w.globals.series[opts.seriesIndex]
                  ).toFixed(2)) /
                  100
              ).toFixed(0) +
              ` ${t("cars")}) `
            );
          },
          tooltipHoverFormatter: function (seriesName, opts) {
            return (
              seriesName +
              " - <strong>" +
              opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
              "</strong>"
            );
          },
        },
      },
      series: [],
      don: "",
      isLoading: true,
    };
  }

  async fetchData() {
    await fetch(
      `${config.apiUrl.evsummary}?yearly_savings__gte=${
        this.props.state.saving
      }&type=${this.props.state.engine}&make=${this.props.state.brand}&model=${
        this.props.state.modal === "Leaf e+"
          ? "Leaf+e%2B"
          : `${this.props.state.modal}`
      }&price_pln__gte=${this.props.state.price_from}&price_pln__lte=${
        this.props.state.price_to
      }&car_id__in=${
        this.props.loll
          ? `${this.props.loll}`
          : `${this.props.state.selectOptions}`
      }`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + authToken,
        },
      }
    )
      .then((response) => response.json())
      .then((dat) => {
        // const newSeries1 = this.props.loadd.can_replace_electric
        //   ? this.props.loadd.can_replace_electric
        //   : dat.can_replace_electric;
        this.setState({
          series: dat.can_replace_electric, // previous newSeries1
          don: dat.yearly_savings,
          isLoading: false,
        });
      });
  }

  async componentDidMount() {
    this.fetchData("");
  }

  async componentDidUpdate(prevProps, prevState) {
    const { t } = this.props;
    if (
      prevProps.notrecom.length !== this.props.notrecom.length ||
      prevProps.state.ev.length !== this.props.state.ev.length
    ) {
      this.fetchData("");
      let carsLength = this.props.state.ev.length;

      this.setState({
        options: {
          ...this.state.options,
          a: Math.random(),
          legend: {
            //...this.state.options.legend,
            min: -2.03, // just a small change in the value, so that the chart is re-rendered and the carsLength is updated
            formatter: function (val, opts) {
              return (
                val +
                " - " +
                Number.parseFloat(
                  opts.w.globals.series[opts.seriesIndex]
                ).toFixed(2) +
                "%" +
                " (" +
                Number.parseFloat(
                  (carsLength *
                    Number.parseFloat(
                      opts.w.globals.series[opts.seriesIndex]
                    ).toFixed(2)) /
                    100
                ).toFixed(0) +
                ` ${t("cars")}) `
              );
            },
          },
        },
      });
    }
  }

  render() {
    const { t } = this.props;
    //console.log(state.car);
    const estimatedAnnualSavings = this.props.loadd.yearly_savings
      ? `${Number.parseFloat(this.props.loadd.yearly_savings).toFixed(2)}`
      : `${Number.parseFloat(this.state.don).toFixed(2)} PLN`;

    return this.state.isLoading === true ? (
      <div className="row" style={{ width: "100%", height: "250px" }}>
        <div className="col-md-12">
          <Spinner />
        </div>
      </div>
    ) : (
      <div className="row" style={{ width: "100%" }}>
        <div className="col-md-6">
          <h3 className="font-weight-bold" style={{ marginTop: "30px" }}>
            {t("Your.car.fleet")}
          </h3>

          <hr></hr>

          <div>
            <Chart
              style={{ marginTop: "22px" }}
              options={this.state.options}
              series={this.state.series}
              type="pie"
              height="250"
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="profit">
            <h3 className="font-weight-bold" style={{ marginTop: "30px" }}>
              {t("Expected.benefits.if.full.recommendation.applied")}
            </h3>
            <hr></hr>

            <div className="row" style={{ width: "100%" }}>
              <div className="col-md-4">
                <p id="pppp">{t("annual").toUpperCase()}: </p>
                <p className="pppp1">{estimatedAnnualSavings}</p>
              </div>
              <div className="col-md-4">
                <p id="pppp">{t("ESTIMATED.CO2.REDUCTION")}</p>
                <p className="pppp1">-</p>
              </div>
              <div className="col-md-4 t">
                <p id="pppp" className="t">
                  {t("ESTIMATED.ANNUAL.FUEL.SAVING")}
                </p>
                <p className="pppp1">-</p>
              </div>
            </div>

            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Fleet));
