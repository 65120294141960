import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import User from "../../../../Components/User";
import Datee from "../../../Views/Date";

import Upload from "./Upload";
import arrow_left from "../../../../img/Aarrow.png";

import Insurance from "../../../../Components/Layout/Insurance";
import Swal from "sweetalert2";
import { editClaim, getClaimDetail } from "../../../../Service/Claim";
import Damage from "./Damage/Damage";
export class EditClaim extends Component {
  constructor(props) {
    super(props);

    this.state = {
      car_number: "",
      isHidden: true,
      driver_name: "",
      selectedOption: "",
      selectedOption1: "",
      driverChange: "",
      describe: "",
      car_list: [],
      driver_list: [],
      net_value: null,
      damage_date: null,
      date_claim: null,
      insurance_number: "",
      status: null,
      type_ac_oc: "",
      description: "",

      claim_details: [
        {
          car: null,
          car_vin: "",
          driver: null,
          driver_name: "",
          address: "",
          documents_number: "",
          driving_licence_number: "",
          phone_number: "",
          email: "",
          car_owner_number: "",
          car_vin_number: "",
          car_owner_address: "",
          pesel_number: "",
          nip_number: "",
          other_car_vin_number: "",
          company_email: "",
          company_phone: "",
        },
      ],

      claim_photo: [
        {
          file: null,
          category: "",
          sub_category: "",
        },
      ],
    };
  }

  getClaimData() {
    getClaimDetail(this.props.match.params.pk).then((data) => {
      this.setState({
        net_value: data.net_value,
        damage_date: data.damage_date,
        date_claim: data.date_claim,
        insurance_number: data.insurance_number,
        status: data.status,
        type_ac_oc: data.type_ac_oc,
        description: data.description,
        claim_photo: [...data.claim_photo],
        claim_details: [...data.claim_details],
      });
    });
  }

  handleChangeData = (e) => {
    e.preventDefault();
    // let data2 = new FormData();
    // data2.append(
    //   "car",
    //   this.state.car ? this.state.car : this.state.selectedOption.value
    // );
    // data2.append("claim_details", [...this.state.claim_details]);

    // data2.append(
    //   "driver",
    //   this.state.driver ? this.state.driver : this.state.driverChange.value
    // );
    // data2.append("net_value", this.state.net_value);
    // data2.append("date", this.state.date);
    // data2.append("description", this.state.description);
    // data2.append("insurance_number", this.state.insurance_number);
    // data2.append("status", this.state.status);
    // data2.append("type", this.state.type_ac_oc);

    // this.state.claim_photo.forEach((item) => {
    //   data2.append("sub_category", item.sub_category);
    //   data2.append("category", item.category);
    //   data2.append("file", item.file);
    // });
    const data1 = {
      net_value: this.state.net_value,
      damage_date: this.state.damage_date,
      date_claim: this.state.date_claim,

      description: this.state.description,
      insurance_number: this.state.insurance_number,
      status: this.state.status,
      type_ac_oc: this.state.type_ac_oc,
      // claim_photo: [...this.state.claim_photo],
      claim_details: [...this.state.claim_details],
    };
    e.preventDefault();
    Swal.fire({
      title: "Loading",
      type: "warning",
      showCancelButton: false,
      showConfirmButton: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    editClaim(this.props.match.params.pk, data1).then((data) => {
      this.setState({ data });
    });
  };

  componentDidMount() {
    this.getClaimData("");
  }

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  async onChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
  }

  render() {
    const { t } = this.props;

    return (
      <div
        className=""
        style={{ marginTop: "px", width: "100%", padding: "40px" }}
      >
        <Helmet>
          <title>Claim Editing</title>
        </Helmet>
        <div
          className="row row1"
          style={{
            width: "100%",
            marginTop: "-20px",
            marginBottom: "-40px",
          }}
        >
          {" "}
          <div className="col-sm-3 date_time">
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
        </div>
        <Insurance />
        <div className="headerr" style={{ marginTop: "-6px" }}>
          <div style={{ padding: "10px" }}>
            <a href="# " onClick={this.handleClick.bind(this)}>
              <img
                src={arrow_left}
                alt="Left_Arrow"
                title="Back to previous page"
                style={{ height: "2.6em" }}
              />
            </a>
            &nbsp;&nbsp;
            {t("claim_editing").toUpperCase()} &nbsp;
          </div>

          <form onSubmit={this.handleChangeData}>
            <div className="row">
              <div className="col-sm-3">
                <div className="form-group">
                  <label id="p" for="exampleInputEmail1">
                    {t("insurance_no").toUpperCase()}
                    <span className="start_icon"> * </span>
                  </label>
                  <input
                    type="text"
                    placeholder={t("type")}
                    name="insurance_number"
                    value={this.state.insurance_number}
                    onChange={this.onChange.bind(this)}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-group">
                  <label id="p" for="exampleInputEmail1">
                    {t("damage_date").toUpperCase()}
                    <span className="start_icon"> * </span>
                  </label>
                  <input
                    type="date"
                    placeholder={t("type")}
                    name="date"
                    value={this.state.damage_date}
                    onChange={this.onChange.bind(this)}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <label id="p" for="exampleInputEmail1">
                    {t("claim_date").toUpperCase()}
                    <span className="start_icon"> * </span>
                  </label>
                  <input
                    type="date"
                    placeholder={t("type")}
                    name="date"
                    value={this.state.date}
                    onChange={this.onChange.bind(this)}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <label id="p">{t("Costs").toUpperCase()}</label>
                  <span className="start_icon"> * </span>
                  <input
                    type="number"
                    placeholder={t("type")}
                    name="net_value"
                    step="any"
                    value={this.state.net_value}
                    onChange={this.onChange.bind(this)}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <label id="p">
                    {t("type_of_claims_OC/AC").toUpperCase()}
                    <span className="start_icon"> * </span>
                  </label>
                  <div className="">
                    <div className="custom-control custom-radio custom-control-inline ">
                      <input
                        type="radio"
                        name="type_ac_oc"
                        value="AC"
                        checked={this.state.type_ac_oc === "AC"}
                        onChange={this.onChange.bind(this)}
                        id="rrA"
                      />
                      <label htmlFor="rrA">AC</label>
                    </div>
                    <div
                      class="custom-control custom-radio custom-control-inline"
                      style={{ marginLeft: "20px" }}
                    >
                      <input
                        type="radio"
                        name="type_ac_oc"
                        value="OC"
                        checked={this.state.type_ac_oc === "OC"}
                        onChange={this.onChange.bind(this)}
                        id="rrO"
                      />
                      <label htmlFor="rrO" className="line">
                        OC
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <label id="p">
                    STATUS <span className="start_icon"> * </span>
                  </label>
                  <div
                    className=""
                    style={{ marginTop: "5px", marginLeft: "-20px" }}
                  >
                    <div className="custom-control custom-radio custom-control-inline ">
                      <input
                        type="radio"
                        name="status"
                        checked={this.state.status === "active"}
                        onChange={this.onChange.bind(this)}
                        value="active"
                        id="rrAA"
                      />
                      <label htmlFor="rrAA">{t("active_status")}</label>
                    </div>

                    <div
                      class="custom-control custom-radio custom-control-inline"
                      style={{ marginLeft: "20px" }}
                    >
                      <input
                        type="radio"
                        name="status"
                        checked={this.state.status === "inactive"}
                        onChange={this.onChange.bind(this)}
                        value="inactive"
                        id="rrI"
                      />
                      <label htmlFor="rrI" className="line">
                        {t("inactive_status")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <Damage state={this.state} />
            </div>
            <div className="col-sm-6">
              <div className="form-group" style={{ marginTop: "25px" }}>
                <label
                  id="p"
                  className="font-weight-bolder"
                  for="exampleInputEmail1"
                >
                  {t("description_claim").toUpperCase()}
                  <span className="start_icon"> * </span>
                </label>
                <textarea
                  className="form-control"
                  rows="2"
                  col="10"
                  name="description"
                  value={this.state.description}
                  onChange={this.onChange.bind(this)}
                  placeholder={t("type")}
                  maxLength="240"
                  data-max="240"
                ></textarea>

                <span
                  className="font-weight-bolder float-right"
                  style={{ position: "relative" }}
                >
                  {this.state.describe.length}/240
                </span>
              </div>
            </div>

            <div className="another_section" style={{ marginTop: "50px" }}>
              <h3 className="font-weight-normal" style={{ marginTop: "0px" }}>
                {t("claims_documents")}
              </h3>
              <hr></hr>

              <Upload state={this.state.claim_photo} />
            </div>

            <center>
              <button
                className="btn btn-danger br text-white"
                value="create campaign"
                style={{ background: "#b71c1c" }}
              >
                {t("save")}
              </button>
            </center>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(EditClaim);
