import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import cross from "../../../../img/Cross.png";
import { getDriverSummary } from "../../../../Service/Driver";
import ScoreChart from "./ScoreChart";

class Model extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFull: false,
      speed: [],
    };
  }

  getLolData() {
    getDriverSummary("2020-10-18", "2020-09-22").then((data) => {
      this.setState({
        speed: data,
      });
    });
  }

  componentDidMount() {
    this.getLolData();
  }

  render() {
    const { t } = this.props;

    const inital_score = (data) =>
      data === "ogolny" || data === "overall"
        ? this.props.inital
        : data === "predkosc" || data === "speed"
        ? this.props.speed
        : data === "dynamika" || data === "dynamics"
        ? this.props.dynamic
        : data === "ekonomia" || data === "efficiency"
        ? this.props.fuel
        : this.props.inital;

    const ongoing_score = (data) =>
      data === "ogolny" || data === "overall"
        ? this.state.speed.overallscore
        : data === "predkosc" || data === "speed"
        ? this.state.speed.overallspeedscore
        : data === "dynamika" || data === "dynamics"
        ? this.state.speed.overalldynamicscore
        : data === "ekonomia" || data === "efficiency"
        ? this.state.speed.overallefficienyscore
        : this.state.speed.overallscore;

    return (
      <div id="driver_score" className="modal campaign_chart">
        <h3>{t("score")}</h3>
        <a href="# " rel="modal:close" className="float-right text-dark ">
          <img src={cross} alt="Cross" style={{ height: "1.1em" }} />
        </a>

        <div className="" style={{ marginTop: "5px" }}>
          <span class="chart_line">
            {/* <span onClick={getPrintChart}> <i class="fa fa-print"></i> </span> */}
            <nav class="somethin_work">
              <ul
                className="nav nav-pills mx-auto  tabbable bg-white"
                id="pills-tab"
                role="tablist"
                style={{ width: "100%" }}
              >
                {this.props.label.map((c, ii) => (
                  <li className="nav-item btnn" key={ii + 1}>
                    <a
                      className={
                        ii + 1 === 1
                          ? "nav-link  font-weight-bold active"
                          : "nav-link  font-weight-bold"
                      }
                      id={`pills-home-tab${c.slug}`}
                      data-toggle="pill"
                      href={`#pills-home${c.slug}`}
                      role="tab"
                      aria-controls={`pills-home${c.slug}`}
                      aria-selected="true"
                      style={{
                        background: "none",
                        color: "black",
                      }}
                    >
                      {c.label ? c.label : "-"}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>

            <div className="tab-content" id="pills-tabContent">
              {this.props.label.map((c, ii) => (
                <div
                  key={ii + 1}
                  className={
                    ii + 1 === 1
                      ? "tab-pane fade show  fleet_data active"
                      : "tab-pane fade show  fleet_data"
                  }
                  id={`pills-home${c.slug}`}
                  role="tabpanel"
                  aria-labelledby={`pills-home-tab${c.slug}`}
                >
                  {this.props.label.length > 0 ? (
                    <ScoreChart
                      chat={c}
                      inital={inital_score(c.slug)}
                      ongoing={ongoing_score(c.slug)}
                      label={c.label}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          </span>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Model);
