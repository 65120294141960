import React from "react";
import { useTranslation } from "react-i18next";

function UsedCar(props) {
  const { t } = useTranslation();
  const base = process.env.PUBLIC_URL;
  return (
    <div className="" style={{ marginTop: "30px" }}>
      <h3 className="font-weight-normal" style={{ marginTop: "30px" }}>
        {t("used_car")}
      </h3>
      <hr></hr>
      {props.car.length > 0 ? (
        props.car
          .slice(0, 1)
          .map((c) => <strong hidden key={c.id}>{c.participantCount}</strong>)
      ) : (
        <strong >&nbsp;&nbsp; {t("no_data")}</strong>
      )}

      <table className="table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>{t("Id")}</th>
            <th>{t("plate_number")}</th>
            <th>{t("used_from")}</th>
            <th>{t("used_to")}</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {props.car.map((c, i) => (
            <tr key={i + 1}>
              <td style={{ width: "10%" }}>{i + 1}</td>
              <td>
                <a
                  href={base + "/overview/app/carmetrics/" + c.id + "/"}
                  title="Click here for Car map and data"
                  style={{ color: "#b71c1c" }}
                  className="font-weight-bold"
                >
                  {" "}
                  {c.plateNumber ? `${c.plateNumber}` : " - "}{" "}
                </a>
              </td>
              <td>{c.since ? `${c.since}` : " - "}</td>
              <td>{c.until ? `${c.until}` : " - "}</td>
              <td>
                <button
                  className={
                    c.status === "active" ? "btn btn-success" : "btn btn-danger"
                  }
                  id="dd"
                >
                  {c.status}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UsedCar;
