import React, { Component } from "react";

import Summery from "./Driver/Summery";
import Speed from "./Driver/Speed";
import Dynamic from "./Driver/Dynamic";
import Fuel from "./Driver/Fuel";
import { ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import "./index.js";

class Driver extends Component {
  entering = (e) => {
    e.children[0].style.borderTopColor = "black";
    e.children[1].style.color = "black";
    e.children[1].style.border = "1px solid black";

    e.children[1].style.background = "white";
  };
  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="row" style={{ marginBottom: "47px" }}>
          <div className="col-sm-12">
            <h3 className="text-left font-weight-bolder">
              <ButtonToolbar className="">
                <OverlayTrigger
                  key="top"
                  onEntering={this.entering}
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-topop">{t("first_graph")}</Tooltip>
                  }
                >
                  <span>
                    {t("Scores")}
                    <i className="fa fa-info-circle "></i>
                  </span>
                </OverlayTrigger>
              </ButtonToolbar>
            </h3>
          </div>
        </div>

        <div className="row hidden" style={{ marginTop: "5px" }}>
          <div className="col-sm-4"> {t("choose_period")} &nbsp; </div>

          <div
            className="col-sm-4"
            style={{ marginTop: "-10px", marginLeft: "-9%" }}
          >
            <select className="form-control donn" name="engine">
              <option selected disabled hidden>
                {t("year")}-{t("month")}
              </option>
              <option>2020-02</option>
              <option>2020-01</option>
              <option>2019-12</option>
            </select>
          </div>
        </div>
        <div className="boxx1" style={{ marginTop: "15px" }}>
          <ul className="nav nav-pills mx-auto " id="pills-tab" role="tablist">
            <li className="nav-item btnn">
              <a
                className="nav-link active font-weight-bold"
                id="pills-home-tab"
                data-toggle="pill"
                href="#pills-home"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                style={{
                  background: "none",
                  color: "black",
                }}
              >
                {t("Summary")}
              </a>
            </li>
            <li className="nav-item btnn">
              <a
                className="nav-link"
                id="pills-profile-tab"
                data-toggle="pill"
                href="#pills-profile"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
                style={{
                  background: "none",
                  color: "black",
                }}
              >
                {t("Speeding")}
              </a>
            </li>

            <li className="nav-item btnn">
              <a
                className="nav-link"
                id="pills-profile-tab"
                data-toggle="pill"
                href="#pills-profile1"
                role="tab"
                aria-controls="pills-profile1"
                aria-selected="false"
                style={{
                  background: "none",
                  color: "black",
                }}
              >
                {t("Dynamics")}
              </a>
            </li>
            <li className="nav-item btnn">
              <a
                className="nav-link"
                id="pills-contact-tab"
                data-toggle="pill"
                href="#pills-contact"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
                style={{
                  background: "none",
                  color: "black",
                }}
              >
                {t("Fuel")}
              </a>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <Summery
              don={this.props.load}
              frm={this.props.fromm}
              to={this.props.too}
            />
            <Speed
              don={this.props.load1}
              frm={this.props.fromm}
              to={this.props.too}
            />
            <Dynamic
              don={this.props.load111}
              frm={this.props.fromm}
              to={this.props.too}
            />
            <Fuel
              don={this.props.load11}
              frm={this.props.fromm}
              to={this.props.too}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Driver);
