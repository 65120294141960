import React, { useState, useEffect } from "react";
import config from "../../../../../Main";
import Spinner from "../../../../../../Container/Spinner";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

const baseUrl = process.env.PUBLIC_URL;
function Table(props) {
  const [car, setCar] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]);
  const [checkedBoxCheck, setCheckedBoxCheck] = useState(false);
  // selectedItems: [],
  // checkedBoxCheck: false,

  const parameter_only_lte = props.param
    .map(
      (d) =>
        `${d.segment_parameter}__lte=${
          d.min_value === null ? "" : parseFloat(d.min_value).toFixed(1)
        }`
    )
    .join("&");

  const parameter_only_gte = props.param
    .map(
      (d) =>
        `${d.segment_parameter}__gte=${
          d.max_value === null ? "" : parseFloat(d.max_value).toFixed(1)
        }`
    )
    .join("&");

  const from_to = `date__lte=${
    props.parameter.to_date ? props.parameter.to_date : ""
  }&date__gte=${props.parameter.from_date ? props.parameter.from_date : ""}`;

  useEffect(() => {
    localeData();
    // eslint-disable-next-line
  }, [car]);

  const localeData = async () => {
    const res = await fetch(
      `${config.apiUrl1.myDriver}?${from_to}&${parameter_only_lte}&${parameter_only_gte}`,
      config.head
    );
    const car = await res.json();
    setCar(car);
    setLoading(false);
  };

  // const capitalizeFirstLetter = (string) => {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // };

  const toggleSelectAll = () => {
    let selectedItems = [];
    let box = !checkedBoxCheck;
    if (box) {
      car.forEach((x) => {
        selectedItems.push(x.id);
      });
    } else {
      selectedItems = [];
    }
    setSelectedItems(selectedItems);
    setCheckedBoxCheck(box);

    console.log(selectedItems);
  };

  const onItemSelect = (row) => {
    let item = selectedItems.slice(0);
    if (item.includes(row)) {
      item.splice(item.indexOf(row), 1);
    } else {
      item.push(row);
    }
    setSelectedItems(item);
    console.log("hello", item);
  };

  const createCampaign = (e) => {
    e.preventDefault();
    props.history.push({
      pathname: baseUrl + "/driver/create/campaign/",
      state: {
        selectedItems: selectedItems,
      },
    });
    // window.location.reload();
  };

  const { t } = useTranslation();

  return (
    <div>
      <table className="table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th class="active">
              <input
                type="checkbox"
                class="select-all checkbox"
                name="first_name"
                id="selectAll1"
                checked={checkedBoxCheck}
                onClick={toggleSelectAll}
              />
            </th>
            <th>{t("Id")}</th>
            <th>{t("Name")}</th>
            <th>{t("plate_number")}</th>
            {props.param.map((d, i) => (
              <th key={i + 1}>
                {" "}
                {props.trans(d.segment_parameter)}
                {/* {capitalizeFirstLetter(
                  d.segment_parameter.split("_").join(" ")
                )} */}
              </th>
            ))}
          </tr>
        </thead>
        {loading === true ? (
          <Spinner />
        ) : (
          <tbody>
            {car.map((c, i) => (
              <tr key={i + 1}>
                <td>
                  <input
                    type="checkbox"
                    class="select-all checkbox"
                    name="first_name"
                    id="selectAll1"
                    checked={selectedItems.includes(c.id)}
                    onClick={() => onItemSelect(c.id)}
                    // onClick={onItemSelect(c.id)}
                    // checked={checkedBoxCheck}
                    // onClick={toggleSelectAll}
                  />
                </td>
                <td>{i + 1}</td>
                <td>
                  {" "}
                  <a
                    href={"/overview/app/drivermetrics/" + c.id}
                    className="font-weight-bold"
                    title="Click to view profile overview"
                    style={{ color: "#b71c1c" }}
                  >
                    {c.name ? c.name : "-"}{" "}
                  </a>
                </td>
                <td>{c.plate_number ? c.plate_number : "-"}</td>

                {props.param.map((d, i) => (
                  <td>
                    {" "}
                    {parseFloat(
                      c.span[
                        d.segment_parameter === "fuel_score"
                          ? "efficiency_score"
                          : d.segment_parameter === "dynamics_score"
                          ? "dynamic_score"
                          : d.segment_parameter
                      ].value
                    ).toFixed(1)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>
      <div style={{ marginTop: "30px" }}>
        <center>
          <form onSubmit={createCampaign}>
            <button
              type="submit"
              value="Get Data"
              disabled={selectedItems.length === 0}
              className="btn btn-login text-white font-weight-bolder boxx button_text "
              id="button1"
            >
              {t("create_campaings").toUpperCase()}
            </button>
          </form>
        </center>
      </div>
    </div>
  );
}

export default withRouter(Table);
