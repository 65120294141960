import React, { Component } from "react";
import { Helmet } from "react-helmet";
import User from "../../../../Components/User";
import Datee from "../../../Views/Date";
import Form from "../../../Views/Form";
import Recommendation from "../../../../Components/Layout/Recommendation";
import getPrint from "../../../../Service/Arrow/Print";
import print from "../../../../img/Rating/print.png";
import Driver from "../../../../views/Views/OldDashboard/Graph/driver";
import Speed from "../../../../views/Views/OldDashboard/Graph/Speed";
import Fuel from "../../../../views/Views/OldDashboard/Graph/Fuel";
import { getStats } from "../../../../Service/Dashboard";
import { getSegment } from "../../../../Service/Segment";
import { withTranslation } from "react-i18next";
import CarCount1 from "./SegmentView/CarCount1";
import CarCount2 from "./SegmentView/CarCount2";
import CarCount3 from "./SegmentView/CarCount3";
import CarCount4 from "./SegmentView/CarCount4";
import CarCount from "./SegmentView/CarCount";
import "./style/Driver.css";

export class DriverCar extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      c: [],
      monthly: [],
      lol: null,
      fol: null,
      year: "",
      segment: [],
      parameter: [],
    };
  }

  getData = async (e) => {
    const from = e.target.elements.from.value;
    const to = e.target.elements.to.value;
    e.preventDefault();

    getStats(to, from).then((data) => {
      this.setState({ c: data, lol: from, fol: to });
    });
  };
  segmentData() {
    getSegment().then((data) => {
      this.setState({ segment: data, parameter: data.parameter_detail });
    });
  }

  componentDidMount() {
    getStats().then((data) => {
      this.setState({ c: data });
    });
    this.segmentData("");
  }

  render() {
    const { t } = this.props;

    return (
      <div className="Main_section">
        <Helmet>
          <title> Driver Car Recommendation</title>
        </Helmet>

        <div
          className="row row1"
          style={{ width: "100%", marginBottom: "-30px" }}
        >
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
          <div className="col-sm-6 date_time" style={{ marginTop: "-25px" }}>
            <Form loaddata={this.getData} />
          </div>
        </div>

        <div style={{ marginTop: "-15px" }}>
          <Recommendation />
        </div>

        <div className="headerr">
          <div>
            <h4
              className="text-left font-weight-bolder  "
              style={{
                marginTop: "10px",
                paddingLeft: "20px",
                marginBottom: "0px",
              }}
            >
              <span className="summary_text">{t("driver_segment")}</span>

              <span
                className="nav-item float-right  "
                disabled
                style={{ marginLeft: "20px" }}
              >
                <a href="# " onClick={getPrint}>
                  <img
                    src={print}
                    alt="contact"
                    style={{ marginTop: "2px", height: "1.6em" }}
                  />
                </a>
              </span>
            </h4>
          </div>

          <div
            className="row just_finish justify-content-center"
            style={{
              marginTop: "40px",
              paddingLeft: "20px",
              width: "100%",
              position: "relative",
            }}
          >
            <div className="col-lg-4 ">
              <Driver fromm={this.state.lol} too={this.state.fol} />
            </div>
            <div className="col-lg-4 ">
              <Speed fromm={this.state.lol} too={this.state.fol} />
            </div>
            <div className="col-lg-4  ">
              <Fuel />
            </div>
          </div>

          <div className="row " style={{ width: "100%", marginBottom: "30px" }}>
            <div
              className=" row  "
              style={{ width: "100%", paddingLeft: "30px" }}
            >
              <div
                className="col-md-2 something_image"
                style={{
                  marginTop: "5px",
                }}
              >
                <CarCount parameter={this.state.segment} />
              </div>
              <div
                className="col-md-2 something_image"
                style={{
                  marginTop: "5px",
                }}
              >
                <CarCount1 parameter={this.state.segment} />
              </div>
              <div
                className="col-md-2 something_image"
                style={{
                  marginTop: "5px",
                }}
              >
                <CarCount2 parameter={this.state.segment} />
              </div>
              <div
                className="col-md-2 something_image"
                style={{
                  marginTop: "5px",
                }}
              >
                <CarCount3 parameter={this.state.segment} />
              </div>
              <div
                className="col-md-2 something_image"
                style={{
                  marginTop: "5px",
                }}
              >
                <CarCount4 parameter={this.state.segment} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(DriverCar);
