import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import config from "../Main";
import axios from "axios";
import Swal from "sweetalert2";
import cross from "../../img/Cross.png";

import Cookies from "universal-cookie";
const cookie = new Cookies();
let authToken = cookie.get("Token");

class Import extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true,
      cost_type: null,
      invoice_number: "",
      csv: "",
      mapping: {
        date: "",
        car: "",
        car_vin: "",
        car_plate_number: "",
        car_mileage: "",
        value: "",
        invoice_number: "",
        cost_type: "",
        description: "",
        net_value: "",
        quantity: "",
      },
      start_row: null,
      label: [],
      importedCSV: "Please import a .csv file",
    };
    this.fileInput = React.createRef();
    this.onChange = this.onChange.bind(this);
  }

  toggleHidden() {
    this.setState({
      isHidden: false,
    });
  }

  toggleHie() {
    this.setState({ isHidden: true });
  }

  async handleChange1(event) {
    const { name, value } = event.target;
    await this.setState({
      mapping: {
        ...this.state.mapping,
        [name]: value.toUpperCase(),
      },
    });
    console.log(this.state.mapping);
  }

  async onChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
    console.log(this.state);
  }

  async componentDidMount() {
    const invoice_number = localStorage.getItem("Filename");
    const csv = localStorage.getItem("fiile");
    await fetch(config.apiUrl.cost, {
      method: "OPTIONS",
      headers: {
        Accept: "application/json",
        "Accept-Language": window.localStorage.i18nextLng,
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + authToken,
      },
    })
      .then((label) => label.json())
      .then((label) => {
        this.setState({
          label: label.actions.POST.cost_type.choices,
          invoice_number,
          csv,
        });
      });
  }

  componentDidUpdate() {
    const invoice_number = localStorage.getItem("Filename");
    const csv = localStorage.getItem("fiile");
    setTimeout(() => {
      this.setState({
        csv: csv,
        nvoice_number: invoice_number,
      });
    }, 100);
  }

  handleChangee = (event) => {
    this.setState({
      csv: event.target.files[0],
    });
  };

  handleSubmit(e) {
    e.preventDefault();

    Swal.fire({
      title: "Loading",
      type: "warning",
      showCancelButton: false,
      showConfirmButton: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    const reader = new FileReader();
    reader.onload = () => {
      csv.parse(reader.result, (err, data) => {
        var userList = [];
        const csv = data;
        const newUser = {
          csv: csv,
        };
        userList.push(newUser);
      });
    };

    const { csv } = this.state;
    const data = {
      cost_type: this.state.cost_type,
      invoice_number: this.state.invoice_number,
      csv: this.state.csv,
      mapping: { ...this.state.mapping },
      start_row: this.state.start_row,
    };

    axios({
      method: "post",
      url: `https://digitalfleet.eu/api/1/costs/upload/`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
      data,
    })
      .then((res) => {
        this.setState({
          cost_type: null,
          invoice_number: "",
          csv: "",
          mapping: {
            date: "",
            car: "",
            car_vin: "",
            car_plate_number: "",
            car_mileage: "",
            value: "",
            invoice_number: "",
            cost_type: "",
            description: "",
            net_value: "",
            quantity: "",
          },
          start_row: null,
        });

        Swal.fire({
          title: "Cost Import",
          type: "success",
          text: "Cost imported  Successfully in database !",
          showConfirmButton: false,
          timer: 2000,
        });
        // window.location.href = "/cost";
        window.location.reload(1);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Cost Import",
          type: "error",
          text:
            "Error While importing  cost please check & type correct input value !!",
          timer: 3000,
        });
      });
  }

  read(e) {
    console.log(e.target.value);
    /////////////////////////////// CSV
    switch (
      e.target.value &&
      // eslint-disable-next-line
      e.target.value.match(/\.([^\.]+)$/i)[1].toLowerCase()
    ) {
      case "csv" /* since they might have a CSV saved as TXT */:
        let filename = e.target.value;
        filename = filename.substring(filename.lastIndexOf("\\") + 1);
        this.setState({ importedCSV: filename });

        const reader = new FileReader();
        const csv1 = e.target.files[0];

        reader.readAsText(csv1);
        reader.onload = function (e) {
          localStorage.setItem("fiile", e.target.result);
        };
        localStorage.setItem("Filename", e.target.files[0].name.split(".")[0]);
        break;
      default:
        // alert('Please choose a .csv file');
        Swal.fire({
          title: "Cost Import File",
          type: "error",
          text: "Please choose a .csv file",
          timer: 2000,
        });
        e.target.value = "";
        return;
    }
  }

  closeModal = () => {
    this.setState({
      cost_type: "",
      invoice_number: "",
      mapping: {
        date: "",
        car: "",
        car_vin: "",
        car_plate_number: "",
        car_mileage: "",
        value: "",
        invoice_number: "",
        cost_type: "",
        description: "",
        net_value: "",
        quantity: "",
      },
      start_row: null,
      importedCSV: "Please import a .csv file",
    });
    document.getElementById("importDataRow").value = "";
  };

  //////////////////////////////////

  render() {
    const options = [];
    for (var i = 65; i <= 90; i++) {
      const don = String.fromCharCode(i);

      options.push(
        <option value={don} key={don}>
          {don}
        </option>
      );
    }
    const { t } = this.props;
    const { label } = this.state;

    return (
      <div>
        <div id="invoice" className="modal">
          <a
            href="# "
            rel="modal:close"
            className="float-right text-white h4"
            style={{ marginBottom: "90px" }}
            onClick={this.closeModal}
          >
            <img src={cross} alt="Cross" style={{ height: "0.7em" }} />
          </a>
          <p className="mod" style={{ marginTop: "40px" }}>
            {t("importInvoice")}
          </p>
          <hr></hr>
          <form
            noValidate
            autoComplete="off"
            onSubmit={this.handleSubmit.bind(this)}
            style={{}}
          >
            <div classNam="import_invoice">
              <div className="form-group" style={{ marginTop: "15px" }}>
                <label for="exampleInputEmail1" id="ppp">
                  {t("cost_type").toUpperCase()}
                </label>
                <select
                  name="cost_type"
                  value={this.state.cost_type}
                  onChange={this.onChange}
                  class="form-control donn"
                  style={{ width: "300px", marginTop: "10px" }}
                >
                  <option value="" disabled selected hidden>
                    {t("choose")}
                  </option>
                  {label.map((c, i) => (
                    <option value={c.value} key={i}>
                      {c.display_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <div className="form-group" style={{ marginTop: "35px" }}>
                    <p id="ppp">{t("import_cost_file")}</p>
                    <div class="upload-btn-wrapper">
                      <button class="btn112" style={{ fontSize: "16px" }}>
                        {t("select_file").toUpperCase()}
                      </button>
                      <p>
                        {this.state.importedCSV !==
                        "Please import a .csv file" ? (
                          <span>
                            Uploaded File:{" "}
                            <strong> {this.state.importedCSV} </strong>
                          </span>
                        ) : (
                          this.state.importedCSV
                        )}
                      </p>
                      <input
                        type="file"
                        name="csv"
                        accept=".csv, text/csv"
                        //value={this.state.csv}
                        onChange={this.read.bind(this)}

                        //onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-4" style={{ marginTop: "65px" }}>
                  <label
                    for="exampleInputEmail1"
                    className="font-weight-bold h6"
                    style={{ fontSize: "13px" }}
                  >
                    {t("import_data_from_row").toUpperCase()}
                  </label>
                  <input
                    style={{ marginTop: "6px" }}
                    type="number"
                    min={1}
                    value={this.state.start_row}
                    onChange={this.onChange}
                    placeholder={t("type")}
                    name="start_row"
                    id="importDataRow"
                  />
                </div>

                {this.state.cost_type === "fuel-card" && (
                  <div className="col-sm-4" style={{ marginTop: "65px" }}>
                    <label
                      for="exampleInputEmail1"
                      className="font-weight-bold h6"
                      style={{ fontSize: "13px" }}
                    >
                      {t("invoice_number").toUpperCase()}
                    </label>
                    <input
                      type="text"
                      placeholder={t("type")}
                      name="invoice_number"
                      value={this.state.invoice_number}
                      className="form-control"
                      onChange={this.onChange}
                    />
                  </div>
                )}
              </div>

              <p
                className="font-weight-bold h6"
                style={{ fontSize: "13px", marginTop: "15px" }}
              >
                INVOICE NUMBER
              </p>

              <div
                className=""
                style={{ marginTop: "0px", marginLeft: "-20px" }}
              >
                <div className="custom-control custom-radio custom-control-inline ">
                  <input
                    type="radio"
                    name="r"
                    id="r22"
                    defaultChecked
                    onClick={this.toggleHie.bind(this)}
                  />
                  <label htmlFor="r22">Type</label>
                </div>
                <div
                  class="custom-control custom-radio custom-control-inline"
                  style={{ marginLeft: "20px" }}
                >
                  <input
                    type="radio"
                    name="r"
                    id="r11"
                    onClick={this.toggleHidden.bind(this)}
                  />
                  <label htmlFor="r11" className="line">
                    Select column
                  </label>
                </div>

                {this.state.cost_type === "fuel-card" ? (
                  <p></p>
                ) : (
                  <span>
                    {this.state.isHidden && (
                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          type="text"
                          placeholder={t("type")}
                          name="invoice_number"
                          value={this.state.invoice_number}
                          className="form-control"
                          onChange={this.onChange}
                        />
                      </div>
                    )}

                    {!this.state.isHidden && (
                      <div class="custom-control custom-radio custom-control-inline">
                        <input
                          type="text"
                          placeholder={t("type")}
                          maxLength={2}
                          className="form-control"
                          name="invoice_number"
                          value={this.state.mapping.invoice_number}
                          onChange={this.handleChange1.bind(this)}
                        />
                      </div>
                    )}
                  </span>
                )}
              </div>

              <p className=" " id="ppp" style={{ marginTop: "15px" }}>
                {t("select_columns").toUpperCase()}
              </p>

              <div className="row" style={{ marginTop: "15px" }}>
                <div className="col-sm-3">
                  <label
                    for="exampleInputEmail1"
                    className="font-weight-bold h6"
                    style={{ fontSize: "13px" }}
                  >
                    {t("date").toUpperCase()}
                  </label>
                  <input
                    type="text"
                    placeholder={t("type")}
                    maxLength={2}
                    className="form-control"
                    name="date"
                    value={this.state.mapping.date}
                    onChange={this.handleChange1.bind(this)}
                    class="form-control donn"
                  />
                </div>

                <div className="col-sm-4">
                  <label
                    for="exampleInputEmail1"
                    className="font-weight-bold h6"
                    style={{ fontSize: "13px" }}
                  >
                    {t("plate_number").toUpperCase()}
                  </label>
                  <input
                    type="text"
                    placeholder={t("type")}
                    maxLength={2}
                    className="form-control"
                    name="car_plate_number"
                    value={this.state.mapping.car_plate_number}
                    onChange={this.handleChange1.bind(this)}
                  />
                </div>

                <div className="col-sm-2">
                  <label
                    for="exampleInputEmail1"
                    className="font-weight-bold h6"
                    style={{ fontSize: "13px" }}
                  >
                    {t("vin").toUpperCase()}
                  </label>
                  <input
                    type="text"
                    placeholder={t("type")}
                    maxLength={2}
                    className="form-control"
                    name="car_vin"
                    value={this.state.mapping.car_vin}
                    onChange={this.handleChange1.bind(this)}
                  />
                </div>

                <div className="col-sm-3">
                  <label
                    for="exampleInputEmail1"
                    className="font-weight-bold h6"
                    style={{ fontSize: "13px" }}
                  >
                    {t("description").toUpperCase()}
                  </label>
                  <input
                    type="text"
                    placeholder={t("type")}
                    maxLength={2}
                    className="form-control"
                    name="description"
                    value={this.state.mapping.description}
                    onChange={this.handleChange1.bind(this)}
                  />
                </div>
              </div>

              <div className="row" style={{ marginTop: "25px" }}>
                <div className="col-sm-3">
                  <label
                    for="exampleInputEmail1"
                    className="font-weight-bold h6"
                    style={{ fontSize: "13px" }}
                  >
                    {t("quantity").toUpperCase()}
                  </label>
                  <input
                    type="text"
                    placeholder={t("type")}
                    maxLength={2}
                    className="form-control"
                    name="quantity"
                    value={this.state.mapping.quantity}
                    onChange={this.handleChange1.bind(this)}
                  />
                </div>

                <div className="col-sm-3">
                  <label
                    for="exampleInputEmail1"
                    className="font-weight-bold h6"
                    style={{ fontSize: "13px" }}
                  >
                    {t("price_netto_import").toUpperCase()}
                  </label>
                  <input
                    type="text"
                    placeholder={t("type")}
                    maxLength={2}
                    className="form-control"
                    name="net_value"
                    value={this.state.mapping.net_value}
                    onChange={this.handleChange1.bind(this)}
                  />
                </div>

                <div className="col-sm-3">
                  <label
                    for="exampleInputEmail1"
                    className="font-weight-bold h6"
                    style={{ fontSize: "13px" }}
                  >
                    {t("price_brutto_import").toUpperCase()}
                  </label>
                  <input
                    type="text"
                    placeholder={t("type")}
                    maxLength={2}
                    className="form-control"
                    name="value"
                    value={this.state.mapping.value}
                    onChange={this.handleChange1.bind(this)}
                  />
                </div>

                <div className="col-sm-3">
                  <label
                    for="exampleInputEmail1"
                    className="font-weight-bold h6"
                    style={{ fontSize: "13px" }}
                  >
                    {t("Milage1").toUpperCase()}
                  </label>
                  <input
                    type="text"
                    placeholder={t("type")}
                    maxLength={2}
                    className="form-control"
                    name="car_mileage"
                    value={this.state.mapping.car_mileage}
                    onChange={this.handleChange1.bind(this)}
                  />
                </div>
              </div>

              <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                <center>
                  <button
                    type="submit"
                    value="Add Invoice"
                    style={{ background: "#b71c1c", color: "white" }}
                    className="btn btn-danger br"
                  >
                    {t("import_invoice")}
                  </button>
                </center>
              </div>
            </div>
          </form>

          {/* <CSVReader
            onFileLoaded={this.handleReadCSV}
            inputRef={this.fileInput}
            style={{ display: "none" }}
            onError={this.handleOnError}
          />
          <button onClick={this.handleImportOffer}>Import</button> */}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Import);
