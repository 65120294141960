import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header1 from "../../../../Components/Layout/Header1";
import Search from "../../../Cars/Search";
import User from "../../../../Components/User";
import Datee from "../../../Views/Date";
import { withTranslation } from "react-i18next";
import arrow from "../../../../img/arrow.png";
import print from "../../../../img/Rating/print.png";
import print_pl from "../../../../img/Rating/print_pl.png";
import { getSummary, getMyCar } from "../../../../Service/Car";
import Filter from "../../Filter";
import arrowL from "../../../../Service/Arrow/Arrow";
import DateGte from "../../../../Service/Arrow/DateGte";
import DateLte from "../../../../Service/Arrow/DateL";
import getPrint from "../../../../Service/Arrow/Print";
import DropDown from "../../../../Service/Arrow/Car/DropDown";
import Summary from "../../../../Service/Arrow/Car/Summary";
import Spinner from "../../../../Container/Spinner";
import compareByAsc1 from "../../../../Service/Arrow/Sort/Asc1";
import compareByDesc1 from "../../../../Service/Arrow/Sort/Desc1";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

class Th extends React.Component {
  handleClick = () => {
    const { onClick, id } = this.props;
    onClick(id);
  };
  render() {
    const { value } = this.props;
    return (
      <th
        onClick={this.handleClick}
        className="t"
        // id="hell1"
        style={{ width: "150px" }}
      >
        {value} <img src={arrow} alt="Arrow" style={{ height: "0.9em" }} />
      </th>
    );
  }
}

class MyCar extends Component {
  constructor(props) {
    super(props);
    const today = moment();
    this.state = {
      sortBy: null,
      order: "ASC",
      newDriver: [],
      walk: [],
      currentSort: "default",
      value1: "select",
      speed: [],
      car: [],
      engine: "",
      lol: null,
      fol: null,
      isOpen: false,
      today: null,
      isLoading: true,

      value: moment.range(today.clone().subtract(0, "days"), today.clone()),
    };
  }
  onToggle = (event) => {
    if (event.target.value === "date") {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  change(event) {
    this.setState({
      value1: event.target.value,
    });
  }

  getData = async (value, states) => {
    this.setState({ isLoading: true });
    Promise.all([
      getMyCar(
        this.state.engine,
        value.end.format("YYYY-MM-DD"),
        value.start.format("YYYY-MM-DD")
      ).then((data) => {
        data.sort((a, b) => b.span.mileage.value - a.span.mileage.value);
        this.setState({
          walk: data,
          lol: value.start,
          fol: value.end,
          isOpen: !this.state.isOpen,
          isLoading: false,
        });
      }),
      getSummary(
        this.state.engine,
        value.end.format("YYYY-MM-DD"),
        value.start.format("YYYY-MM-DD")
      ).then((data) => {
        this.setState({
          speed: data,
        });
      }),
    ]);
  };

  getOptionData = async () => {
    // this.setState({ isLoading: true });

    Promise.all([
      getMyCar(
        this.state.engine,
        DateGte(this.state.today),
        DateLte(this.state.today)
      ).then((data) => {
        data.sort((a, b) => b.span.mileage.value - a.span.mileage.value);
        this.setState({ walk: data, isLoading: false });
      }),
      getSummary(
        this.state.engine,
        DateGte(this.state.today),
        DateLte(this.state.today)
      ).then((data) => {
        this.setState({ speed: data });
      }),
    ]);
  };

  getCarType = () => {
    getMyCar().then((data) => {
      data.sort((a, b) => b.span.mileage.value - a.span.mileage.value);
      this.setState({ car: data });
    });
  };

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.getOptionData("");
  };

  componentDidMount() {
    this.getOptionData("");
    this.getCarType("");
  }

  sortBy(key) {
    let arrayCopy = [...this.state.walk];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(compareByAsc1(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(compareByDesc1(key));
    }
    this.setState({ walk: arrayCopy });
  }

  render() {
    const { walk, speed } = this.state;
    const { t } = this.props;
    // const energy = walk.filter((player) => player.type.includes("BEV"));

    const uniqueDates1 = [...new Set(this.state.car.map((i) => i.type))];
    const options11 = [];
    for (var i = 0; i < uniqueDates1.length; i++) {
      const don = uniqueDates1[i];

      options11.push(
        <option value={don} key={don}>
          {t("Engine_type")}:{don}
        </option>
      );
    }
    const base = process.env.PUBLIC_URL;

    return (
      <div className="Main_section">
        <Helmet>
          <title> CARS </title>
          <meta charSet="utf-8" />
          <meta
            name="Helmet application"
            content="Classic car list with details of driven mileage, fuel consumed, critical event, score name and more"
          />
        </Helmet>
        <div
          className="row row1"
          style={{ width: "100%", marginBottom: "-30px" }}
        >
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
          <div className="col-sm-6 date_time" style={{ marginTop: "-5px" }}>
            <Filter
              lol={this.state.lol}
              fol={this.state.fol}
              today={this.state.today}
              option={this.state.isOpen}
              toggle={this.onToggle}
              change={this.getData}
              hello={this.onChange}
            />
          </div>
        </div>

        <div style={{ marginTop: "-15px" }}>
          <Header1 />

          <div className="headerr" id>
            <div className=" " style={{ width: "100%" }}>
              <div className=" " style={{ width: "100%" }}>
                <span
                  className="nav-item  float-right  "
                  disabled
                  style={{ display: "flex" }}
                >
                  <Search /> &nbsp; &nbsp;
                  <a href="# " onClick={getPrint}>
                    <img
                      src={
                        window.localStorage.i18nextLng === "en"
                          ? print
                          : print_pl
                      }
                      alt="Print page"
                      style={{ marginTop: "-4px", height: "3em" }}
                    />
                  </a>
                </span>
              </div>
            </div>

            <div
              className="caaar car_my table-responsive"
              style={{ marginTop: "-40px", width: "100%" }}
            >
              {this.state.isLoading === true ? (
                <Spinner />
              ) : (
                <table className="table tabell1  table-hover" id="headerr">
                  <thead>
                    <tr className="th">
                      <th style={{ width: "2%" }} className=" t">
                        {t("Id")}
                      </th>
                      <th className="t">{t("plate_number")} </th>
                      <th className="t">{t("Name")} </th>

                      <th className="t">{t("Brand")}</th>
                      <th className="t">{t("Model")}</th>

                      <th className=" ">
                        <select
                          name="engine"
                          onChange={this.onChange}
                          style={{
                            marginTop: "12px",
                            position: "relative",
                            top: "0px",
                            fontSize: "10px",
                            width: "140px",
                          }}
                          class="minimal"
                        >
                          <option value="">
                            {" "}
                            {t("Engine_type")}: {t("AllCar")}{" "}
                          </option>
                          {options11}
                        </select>
                      </th>
                      <Th
                        onClick={() => this.sortBy("mileage")}
                        id="mileage"
                        style={{ width: "160px" }}
                        value={t("Milage")}
                      />

                      <Th
                        onClick={() => this.sortBy("fuel_consumed")}
                        id="fuel_consumed"
                        value={t("FuelComsumed")}
                      />
                      <Th
                        onClick={() => this.sortBy("average_fuel_consumed")}
                        id="average_fuel_consumed"
                        value={t("AverageFuel")}
                      />

                      <Th
                        onClick={() => this.sortBy("overspeeding_percentage")}
                        id="overspeeding_percentage"
                        style={{ width: "140px" }}
                        value={t("OverspeedingDistance")}
                      />

                      {/* For Weekend  driving */}

                      <th className="t">
                        <select
                          onChange={this.change.bind(this)}
                          value={this.state.value1}
                          defaultValue="1"
                          style={{
                            width: "200px",
                            top: "7px",
                          }}
                          class="minimal t"
                        >
                          <option value="1"> {t("weekend_driving")} </option>
                          <option value="2"> {t("weekend_milage")} </option>
                          <option value="3"> {t("weekend_fuel")} </option>
                        </select>
                      </th>

                      {/*  end weekend driving */}

                      {/* For Off Hours driving */}
                      <th className="t">
                        <select
                          onChange={this.change.bind(this)}
                          value={this.state.value1}
                          class="minimal"
                          style={{
                            width: "200px",
                            top: "7px",
                          }}
                        >
                          <option value="7"> {t("off_hour_driving")} </option>
                          <option value="9"> {t("off_hour_fuel")} </option>
                          <option value="8"> {t("off_hour_milage")} </option>
                        </select>
                      </th>

                      {/*  end Off hour driving */}

                      {/* For Off Zone driving */}

                      <th className="t">
                        <select
                          onChange={this.change.bind(this)}
                          value={this.state.value1}
                          class="minimal"
                          style={{
                            width: "200px",
                            top: "7px",
                          }}
                        >
                          <option value="4"> {t("off_zone_driving")} </option>
                          <option value="5"> {t("off_zone_fuel")} </option>
                          <option value="6"> {t("off_zone_milage")} </option>
                        </select>
                        {/* <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em", top: "7px" }}
                        /> */}
                      </th>
                      {/*  end Off ZOne driving */}

                      <Th
                        onClick={() => this.sortBy("costs")}
                        id="costs"
                        value={t("total_cost")}
                      />

                      <th></th>
                    </tr>
                  </thead>

                  {/* Thead End here */}

                  <tbody>
                    {walk.map((c, i) => (
                      <tr key={c.id}>
                        <td>{i + 1}</td>
                        <td>
                          <a
                            href={
                              base + "/overview/app/carmetrics/" + c.id + "/"
                            }
                            className="font-weight-bold"
                            style={{ color: "#b71c1c" }}
                          >
                            {c.plate_number ? `${c.plate_number}` : " ? "}
                          </a>
                        </td>
                        <td>
                          <a
                            href={
                              base +
                              "/overview/app/drivermetrics/" +
                              c.driver_id +
                              "/"
                            }
                            className="font-weight-bold"
                            style={{ color: "#b71c1c" }}
                          >
                            {c.driver_name ? `${c.driver_name}` : " ? "}
                          </a>
                        </td>
                        <td> {c.brand ? `${c.brand}` : " ? "} </td>
                        <td> {c.model ? `${c.model}` : " ? "} </td>

                        <td>
                          {this.state.value1 === "11"
                            ? `${c.type ? `${(c.type = "Diesel")}` : "-"}`
                            : `${
                                this.state.value1 === "12"
                                  ? `${c.type ? `${(c.type = "PB")}` : "-"}`
                                  : `${c.type ? `${c.type}` : "-"}`
                              }`}
                        </td>
                        <td>
                          {c.span.mileage.value
                            ? `${c.span.mileage.value.toFixed(1)}`
                            : "-"}{" "}
                          {arrowL(
                            c.span.mileage.changeDirection,
                            c.span.mileage.changePositive,
                            c.span.mileage.change
                          )}
                        </td>
                        <td>
                          {c.span.fuel_consumed.value
                            ? `${c.span.fuel_consumed.value.toFixed(1)}`
                            : "-"}
                          {arrowL(
                            c.span.fuel_consumed.changeDirection,
                            c.span.fuel_consumed.changePositive,
                            c.span.fuel_consumed.change
                          )}
                        </td>

                        <td>
                          {c.span.average_fuel_consumed.value
                            ? `${c.span.average_fuel_consumed.value.toFixed(1)}`
                            : "-"}
                          {arrowL(
                            c.span.average_fuel_consumed.changeDirection,
                            c.span.average_fuel_consumed.changePositive,
                            c.span.average_fuel_consumed.change
                          )}
                        </td>

                        <td>
                          {c.span.overspeeding_percentage.value
                            ? `${c.span.overspeeding_percentage.value.toFixed(
                                1
                              )}`
                            : "-"}
                          {arrowL(
                            c.span.overspeeding_percentage.changeDirection,
                            c.span.overspeeding_percentage.changePositive,
                            c.span.overspeeding_percentage.change
                          )}
                        </td>
                        {/* <td>
                          {c.span.dtc.value
                            ? `${c.span.dtc.value.toFixed(1)}`
                            : "-"}
                          {arrowL(
                            c.span.dtc.changeDirection,
                            c.span.dtc.changePositive,
                            c.span.dtc.change
                          )}
                        </td> */}
                        <td>
                          {DropDown(
                            this.state.value1,
                            c.span.weekend_driving,
                            c.span.weekend_milage,
                            c.span.weekend_fuel_consumed,
                            "1",
                            "2",
                            "3"
                          )}
                        </td>
                        <td>
                          {DropDown(
                            this.state.value1,
                            c.span.off_hour_driving,
                            c.span.off_hour_milage,
                            c.span.off_hour_consumed,
                            "7",
                            "8",
                            "9"
                          )}
                        </td>
                        <td>
                          {DropDown(
                            this.state.value1,
                            c.span.off_zone_driving,
                            c.span.off_zone_milage,
                            c.span.off_zone_fuel_consumed,
                            "4",
                            "5",
                            "6"
                          )}
                        </td>
                        <td>
                          {c.span.costs.value
                            ? `${c.span.costs.value.toFixed(1)}`
                            : "-"}
                          {arrowL(
                            c.span.costs.changeDirection,
                            c.span.costs.changePositive,
                            c.span.costs.change
                          )}
                        </td>
                        <td>
                          {" "}
                          <a
                            href={
                              base +
                              "/overview/app/my/car/" +
                              c.id +
                              "/" +
                              c.type +
                              "/"
                            }
                            className="font-weight-bold"
                            style={{ color: "#b71c1c" }}
                          >
                            {t("trip").toUpperCase()}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>

                  <tfoot>
                    <tr className="thead sorttable_nosort">
                      <th className="sorttable_nosort"></th>

                      <th className="sorttable_nosort">
                        {" "}
                        {t("summary").toUpperCase()}
                      </th>
                      <th className="sorttable_nosort"></th>
                      <th className="sorttable_nosort"></th>
                      <th className="sorttable_nosort"></th>
                      <th className="sorttable_nosort"></th>
                      <th className="sorttable_nosort">
                        {speed.milage ? `${speed.milage.toFixed(1)}` : "-"}
                      </th>
                      <th className="sorttable_nosort">
                        {speed.fuel_consumed
                          ? `${speed.fuel_consumed.toFixed(1)}`
                          : "-"}
                      </th>

                      <th className="sorttable_nosort">
                        {speed.average_fuel_consumed
                          ? `${speed.average_fuel_consumed.toFixed(1)}`
                          : "-"}
                      </th>

                      <th className="sorttable_nosort">
                        {speed.overspeeding_percentage
                          ? `${speed.overspeeding_percentage.toFixed(1)}`
                          : "-"}
                      </th>

                      <th className="sorttable_nosort">
                        {Summary(
                          this.state.value1,
                          speed.weekend_driving,
                          speed.weekend_milage,
                          speed.weekend_fuel_consumed,
                          "1",
                          "2",
                          "3"
                        )}
                      </th>
                      <th className="sorttable_nosort">
                        {Summary(
                          this.state.value1,
                          speed.off_hour_driving,
                          speed.off_hour_milage,
                          speed.off_hour_consumed,
                          "7",
                          "8",
                          "9"
                        )}
                      </th>
                      <th className="sorttable_nosort">
                        {Summary(
                          this.state.value1,
                          speed.off_zone_driving,
                          speed.off_zone_milage,
                          speed.off_zone_fuel_consumed,
                          "4",
                          "5",
                          "6"
                        )}{" "}
                      </th>
                      <th className="sorttable_nosort">
                        {speed.costs ? `${speed.costs}` : "-"}
                      </th>
                      <th className="sorttable_nosort"></th>
                    </tr>
                  </tfoot>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(MyCar));
