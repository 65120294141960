import React, { Component } from "react";
import Recommendation from "../../../Components/Layout/Recommendation";
import Search from ".././Search";
import { Helmet } from "react-helmet";
import config from "../../Main";
import User from "../../../Components/User";
import Datee from "../../Views/Date";
import { withTranslation } from "react-i18next";
import arrow from "../../../img/arrow.png";
import moment from "moment";
import arrowL from "../../../Service/Arrow/Arrow";
import Spinner from "../../../Container/Spinner";
import print from "../../../img/Rating/print.png";
import getPrint from "../../../Service/Arrow/Print";
import "../index";

class Th extends React.Component {
  handleClick = () => {
    const { onClick, id } = this.props;
    onClick(id);
  };
  render() {
    const { value } = this.props;
    return (
      <th onClick={this.handleClick} className="t">
        {value} <img src={arrow} alt="Arrow" style={{ height: "0.9em" }} />
      </th>
    );
  }
}

class FilterDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      todos: [],
      speed: [],
      isLoading: true,
      currentSort: "default",
      value1: "select",
      selectedItems: [],
      checkedBoxCheck: false,
    };
  }

  change(event) {
    this.setState({
      value1: event.target.value,
    });
  }

  async componentDidMount() {
    try {
      Promise.all([
        fetch(
          `${config.apiUrl1.myDriver}?date__lte=${this.props.match.params.to}&date__gte=${this.props.match.params.from}&overall_score__lte=${this.props.match.params.overall_score__lte}&overall_score__gt=${this.props.match.params.overall_score__gt}`,
          config.head
        )
          .then((todos) => todos.json())
          .then((todos) => {
            todos.sort((a, b) => a.overallscore - b.overallscore);
            this.setState({
              ...this.state,
              todos,
              isLoading: false,
            });
          }),
        fetch(
          `${config.apiUrl.driversummary}?date__lte=${this.props.match.params.to}&date__gte=${this.props.match.params.from}&overall_score__lte=${this.props.match.params.overall_score__lte}&overall_score__gt=${this.props.match.params.overall_score__gt}`,
          config.head
        )
          .then((speed) => speed.json())
          .then((speed) => {
            this.setState({
              speed,
            });
          }),
      ]);
    } catch (e) {
      console.log(e);
    }
  }

  async onItemSelect(row) {
    const selectedItems = this.state.selectedItems.slice(0);
    if (selectedItems.includes(row)) {
      selectedItems.splice(selectedItems.indexOf(row), 1);
    } else {
      selectedItems.push(row);
    }
    await this.setState({
      selectedItems,
    });
  }

  toggleSelectAll() {
    let selectedItems = [];
    var checkedBoxCheck = !this.state.checkedBoxCheck;
    if (checkedBoxCheck) {
      this.state.todos.forEach((x) => {
        selectedItems.push(x.id);
      });
    } else {
      selectedItems = [];
    }
    this.setState((prevState) => ({
      selectedItems,
      checkedBoxCheck,
    }));
  }

  handleSort = (id) => {
    this.setState((prev) => {
      const ordered = prev.todos.sort((a, b) =>
        prev.order === "ASC"
          ? a["span"][id]["value"] < b["span"][id]["value"]
          : a["span"][id]["value"] > b["span"][id]["value"]
      );
      return {
        sortBy: id,
        order: prev.order === "ASC" ? "DESC" : "ASC",
        todos: ordered,
      };
    });
  };

  async createCampaign(e) {
    const base = process.env.PUBLIC_URL;
    e.preventDefault();
    this.props.history.push({
      pathname: base + "/driver/create/campaign/",
      state: {
        selectedItems: this.state.selectedItems,
      },
    });
    // window.location.reload();
  }

  render() {
    const color = (data) =>
      data <= 4
        ? "fourth"
        : `${
            data <= 6
              ? "third"
              : `${data <= 8 ? "second" : `${data <= 10}` ? "first" : ""}`
          }`;
    const { todos } = this.state;
    const { speed } = this.state;
    var d = new Date();
    var mm = d.getMonth() + 1;
    var dd = d.getDate();
    var yy = d.getFullYear();
    const base = process.env.PUBLIC_URL;
    var cc = yy + "-" + mm + "-" + dd; //(US)

    const { t } = this.props;

    return (
      <div className="Main_section">
        <Helmet>
          <title>FIlter Data Driver </title>
        </Helmet>
        <div className="row row1" style={{ width: "100%" }}>
          <div className="col-sm-3" style={{ marginTop: "5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
        </div>

        <div className="float-right">
          <form onSubmit={this.createCampaign.bind(this)}>
            <div className="float-right">
              <button className="btn" id="b" style={{ marginTop: "70px" }}>
                {t("create_campaings").toUpperCase()}
              </button>
            </div>
          </form>
        </div>
        <Recommendation />

        <div className="headerr">
          <div>
            <div className=" " style={{ width: "100%", marginBottom: "-30px" }}>
              <span
                className="nav-item  float-right  "
                disabled
                style={{ display: "flex" }}
              >
                <Search /> &nbsp; &nbsp;
                <a href="# " onClick={getPrint}>
                  <img
                    src={print}
                    alt="contact"
                    style={{ marginTop: "-4px", height: "3em" }}
                  />
                </a>
              </span>
            </div>
            <div style={{ marginTop: "40px" }}>
              <p className="font-weight-bolder">
                {t("from")}{" "}
                {this.props.match.params.from === "2016-01-04"
                  ? "-"
                  : `${moment(this.props.match.params.from).format(
                      "DD-MM-YYYY"
                    )}`}{" "}
                {t("to")}
                {this.props.match.params.to === cc
                  ? "-"
                  : `${moment(this.props.match.params.to).format(
                      "DD-MM-YYYY"
                    )}    `}{" "}
              </p>

              {todos.length > 0 ? (
                <p></p>
              ) : (
                <p>
                  <strong>&nbsp;&nbsp; {t("no_data")}</strong>
                </p>
              )}
            </div>
          </div>
          <div className="caaar table-responsive" style={{ width: "100%" }}>
            {this.state.isLoading === true ? (
              <Spinner />
            ) : (
              <table id="headerr" className="table table-hover tabell12">
                <thead>
                  <tr className="th ">
                    <th class="active ">
                      <input
                        type="checkbox"
                        class="select-all checkbox t"
                        name="first_name"
                        id="selectAll1"
                        checked={this.state.checkedBoxCheck}
                        onClick={this.toggleSelectAll.bind(this)}
                      />
                    </th>
                    <th className="t" style={{ width: "5%" }}>
                      {t("Id")}
                    </th>
                    <th className="t" onClick={() => this.sortBy("name")}>
                      {t("Name")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>

                    <Th
                      onClick={this.handleSort}
                      id="mileage"
                      value={t("Milage")}
                    />
                    <Th
                      onClick={this.handleSort}
                      id="fuel_consumed"
                      value={t("FuelComsumed")}
                    />
                    <Th
                      onClick={this.handleSort}
                      id="average_fuel_consumed"
                      value={t("AverageFuel")}
                    />
                    <th className="t font-weight-bolder">
                      <select
                        onChange={this.change.bind(this)}
                        value={this.state.value1}
                        style={{
                          position: "relative",
                          top: "8px",
                          width: "130px",
                        }}
                        class="minimal "
                      >
                        <option value="1"> {t("OverallScore")} </option>
                        <option value="2">{t("OverallSpeed")} </option>
                        <option value="3"> {t("OverallDynamic")} </option>
                        <option value="4"> {t("OverallEfficiency")} </option>
                      </select>
                    </th>

                    <Th
                      onClick={this.handleSort}
                      id="overspeeding_percentage"
                      value={t("OverspeedingDistance")}
                    />
                    <Th
                      onClick={this.handleSort}
                      id="critical_overspeed"
                      value={t("CriticalSpeed")}
                    />
                    <th className="t">
                      {t("NOCar")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {todos.map((c, i) => (
                    <tr key={c.pk}>
                      <td>
                        <input
                          type="checkbox"
                          checked={this.state.selectedItems.includes(c.id)}
                          className="checkbox"
                          name="selectOptions"
                          onClick={() => this.onItemSelect(c.id)}
                        />
                      </td>
                      <td>{i + 1}</td>
                      <td>
                        <a
                          href={base + "/overview/drivermetrics/" + c.id}
                          className="font-weight-bold"
                          title="Click to view profile overview"
                          style={{ color: "#b71c1c" }}
                        >
                          {c.name ? `${c.name}` : " ? "}
                        </a>
                      </td>

                      <td>
                        {c.span.mileage.value
                          ? `${c.span.mileage.value.toFixed(1)}`
                          : "-"}{" "}
                        {arrowL(
                          c.span.mileage.changeDirection,
                          c.span.mileage.changePositive,
                          c.span.mileage.change
                        )}
                      </td>
                      <td>
                        {c.span.fuel_consumed.value
                          ? `${c.span.fuel_consumed.value.toFixed(1)}`
                          : "-"}
                        {arrowL(
                          c.span.fuel_consumed.changeDirection,
                          c.span.fuel_consumed.changePositive,
                          c.span.fuel_consumed.change
                        )}
                      </td>
                      <td>
                        {c.span.average_fuel_consumed.value
                          ? `${c.span.average_fuel_consumed.value.toFixed(1)}`
                          : "-"}{" "}
                        {arrowL(
                          c.span.average_fuel_consumed.changeDirection,
                          c.span.average_fuel_consumed.changePositive,
                          c.span.average_fuel_consumed.change
                        )}
                      </td>
                      <td>
                        <span
                          className={
                            this.state.value1 === "1"
                              ? color(c.span.overall_score.value)
                              : this.state.value1 === "2"
                              ? color(c.span.speed_score.value)
                              : this.state.value1 === "3"
                              ? color(c.span.dynamic_score.value)
                              : this.state.value1 === "4"
                              ? color(c.span.efficiency_score.value)
                              : color(c.span.overall_score.value)
                          }
                          style={{ width: "35px" }}
                        >
                          {this.state.value1 === "1"
                            ? `${
                                c.span.overall_score.value
                                  ? `${Number(
                                      c.span.overall_score.value.toFixed(1)
                                    )}`
                                  : 0.0
                              }`
                            : `${
                                this.state.value1 === "2"
                                  ? `${
                                      c.span.speed_score.value
                                        ? `${Number(
                                            c.span.speed_score.value.toFixed(1)
                                          )}`
                                        : 0.0
                                    }`
                                  : `${
                                      this.state.value1 === "3"
                                        ? `${
                                            c.span.dynamic_score.value
                                              ? `${Number(
                                                  c.span.dynamic_score.value.toFixed(
                                                    1
                                                  )
                                                )}`
                                              : 0.0
                                          }`
                                        : `${
                                            this.state.value1 === "4"
                                              ? `${
                                                  c.span.efficiency_score.value
                                                    ? `${Number(
                                                        c.span.efficiency_score.value.toFixed(
                                                          1
                                                        )
                                                      )}`
                                                    : 0.0
                                                }`
                                              : `${
                                                  c.span.overall_score.value
                                                    ? `${Number(
                                                        c.span.overall_score.value.toFixed(
                                                          1
                                                        )
                                                      )}`
                                                    : 0.0
                                                }`
                                          }`
                                    }`
                              }`}
                        </span>

                        <span className="special">
                          {this.state.value1 === "1"
                            ? arrowL(
                                c.span.overall_score.changeDirection,
                                c.span.overall_score.changePositive,
                                c.span.overall_score.change
                              )
                            : this.state.value1 === "2"
                            ? arrowL(
                                c.span.speed_score.changeDirection,
                                c.span.speed_score.changePositive,
                                c.span.speed_score.change
                              )
                            : this.state.value1 === "3"
                            ? arrowL(
                                c.span.overall_score.changeDirection,
                                c.span.overall_score.changePositive,
                                c.span.overall_score.change
                              )
                            : this.state.value1 === "4"
                            ? arrowL(
                                c.span.dynamic_score.changeDirection,
                                c.span.dynamic_score.changePositive,
                                c.span.dynamic_score.change
                              )
                            : arrowL(
                                c.span.efficiency_score.changeDirection,
                                c.span.efficiency_score.changePositive,
                                c.span.efficiency_score.change
                              )}
                        </span>
                      </td>
                      <td>
                        {c.span.overspeeding_percentage.value
                          ? `${c.span.overspeeding_percentage.value.toFixed(1)}`
                          : "-"}{" "}
                        {arrowL(
                          c.span.overspeeding_percentage.changeDirection,
                          c.span.overspeeding_percentage.changePositive,
                          c.span.overspeeding_percentage.change
                        )}
                      </td>
                      <td>
                        {c.span.critical_overspeed.value
                          ? `${c.span.critical_overspeed.value.toFixed(1)}`
                          : "-"}{" "}
                        {arrowL(
                          c.span.critical_overspeed.changeDirection,
                          c.span.critical_overspeed.changePositive,
                          c.span.critical_overspeed.change
                        )}
                      </td>

                      <td>{c.carquanttity ? `${c.carquanttity}` : "-"} </td>
                      <td>
                        <a
                          href={
                            base + "/overview/my/driver/" + c.id + "/" + c.name
                          }
                          className="font-weight-bold"
                          title="Click to view Daily trip view"
                          style={{ color: "#b71c1c" }}
                        >
                          {t("trips").toUpperCase()}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>

                <tfoot>
                  <tr className="thead sorttable_nosort">
                    <th className="sorttable_nosort"></th>
                    <th className="sorttable_nosort"></th>

                    <th className="sorttable_nosort">
                      {" "}
                      {t("Summary1").toUpperCase()}
                    </th>

                    <th className="sorttable_nosort">
                      {speed.milage ? `${speed.milage.toFixed(1)}` : "-"}
                    </th>
                    <th className="sorttable_nosort">
                      {speed.fuel_consumed
                        ? `${speed.fuel_consumed.toFixed(1)}`
                        : "-"}{" "}
                    </th>
                    <th className="sorttable_nosort">
                      {speed.average_fuel_consumed
                        ? `${speed.average_fuel_consumed.toFixed(1)}`
                        : "-"}
                    </th>
                    <th>
                      <span
                        className={
                          this.state.value1 === "1"
                            ? color(speed.overallscore)
                            : this.state.value1 === "2"
                            ? color(speed.overallspeedscore)
                            : this.state.value1 === "3"
                            ? color(speed.overalldynamicscore)
                            : this.state.value1 === "4"
                            ? color(speed.overallefficienyscore)
                            : color(speed.overallscore)
                        }
                        style={{ width: "35px" }}
                      >
                        {this.state.value1 === "1"
                          ? `${
                              speed.overallscore
                                ? `${Number(speed.overallscore.toFixed(1))}`
                                : " - "
                            }`
                          : `${
                              this.state.value1 === "2"
                                ? `${
                                    speed.overallspeedscore
                                      ? `${Number(
                                          speed.overallspeedscore.toFixed(1)
                                        )}`
                                      : " - "
                                  }`
                                : `${
                                    this.state.value1 === "3"
                                      ? `${
                                          speed.overalldynamicscore
                                            ? `${Number(
                                                speed.overalldynamicscore.toFixed(
                                                  1
                                                )
                                              )}`
                                            : " - "
                                        }`
                                      : `${
                                          this.state.value1 === "4"
                                            ? `${
                                                speed.overallefficienyscore
                                                  ? `${Number(
                                                      speed.overallefficienyscore.toFixed(
                                                        1
                                                      )
                                                    )}`
                                                  : " - "
                                              }`
                                            : `${
                                                speed.overallscore
                                                  ? `${Number(
                                                      speed.overallscore.toFixed(
                                                        1
                                                      )
                                                    )}`
                                                  : " - "
                                              }`
                                        }`
                                  }`
                            }`}
                      </span>
                    </th>
                    <th className="sorttable_nosort">
                      {speed.overspeeding_percentage
                        ? `${speed.overspeeding_percentage.toFixed(1)}`
                        : "-"}
                    </th>
                    <th className="sorttable_nosort">
                      {speed.critical_overspeed
                        ? `${speed.critical_overspeed.toFixed(1)}`
                        : "-"}
                    </th>

                    <th className="sorttable_nosort">
                      {speed.carquantity ? `${speed.carquantity}` : "-"}
                    </th>
                    <th></th>
                  </tr>
                </tfoot>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FilterDriver);
