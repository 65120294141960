import React from "react";
import "./Footer.css";

function Footer() {
  const baseUrl = process.env.PUBLIC_URL;

  return (
    <>
      {window.location.pathname === baseUrl + "/" ? (
        ""
      ) : (
        <div
          className={
            window.location.pathname === baseUrl + "/"
              ? " helll_my justify-content-center align-self-center hamm_hi backgro"
              : " helll_my justify-content-center align-self-center hamm_hi backgroud1"
          }
        >
          {localStorage.getItem("fleet") === "Aereco" ? (
            ""
          ) : (
            <div className="Main_section1">
              <div class="wrapper">
                <section class="link">
                  <div className="logos">
                    <h4 className="question">
                      Questions?
                      <a
                        href="mailto: contact@digitalfleet.eu"
                        className="email_data"
                      >
                        contact@digitalfleet.eu
                      </a>
                    </h4>
                  </div>
                  <div class="sub-links">
                    <ul>
                      <li>
                        <a href={baseUrl + "/faq/"}>FAQ</a>
                      </li>
                      <li>
                        <a href={baseUrl + "/corporate/information/"}>
                          Corporate information
                        </a>
                      </li>
                      <li>
                        <a href={baseUrl + "/termsofuse/"}>Terms of use</a>
                      </li>
                      <li>
                        <a href={baseUrl + "/privacy/statement/"}>
                          Privacy statement
                        </a>
                      </li>
                      <li>
                        <a href={baseUrl + "/contact/us/"}>Contact us</a>
                      </li>
                    </ul>
                  </div>
                </section>

                <footer>
                  <p>
                    &Copy 2019-{new Date().getFullYear()} DigitalFleet, Inc.
                  </p>
                  <p>Viva Drive & Copy {new Date().getFullYear()}</p>
                </footer>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Footer;
