const Summary = (data, data1, data2, data3, a, b, c) =>
  data === a
    ? `${data1 ? `${data1.toFixed(1)}` : "-"}`
    : `${
        data === b
          ? `${data2 ? `${data2.toFixed(1)}` : "-"}`
          : `${
              data === c
                ? `${data3 ? `${data3.toFixed(1)}` : "-"}`
                : `${data1 ? `${data1.toFixed(1)}` : "-"}`
            }`
      }`;

export default Summary;
