import React, { useState, useEffect } from "react";
import notifi from "../../img/notification.png";
import { useTranslation } from "react-i18next";
import TimeAgo from "react-timeago";
import {
  getNotification1,
  deleteNotification,
} from "../../Service/Notification";

const Notification = () => {
  const [notification, setNotification] = useState([]);

  const handleDelete = (id) => (e) => {
    e.preventDefault();
    deleteNotification(id).then((res) => {});
  };

  useEffect(() => {
    getNotification1().then((res) => {
      setNotification(res);
    });
  }, []);

  const { t } = useTranslation();
  const baseUrl = process.env.PUBLIC_URL;
  return (
    <li className="nav-item dropdown  " style={{ marginTop: "5px" }}>
      <a
        className="nav-link  a"
        href="# "
        id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style={{ color: "#212226" }}
      >
        <img
          src={notifi}
          alt="Notification"
          className="setting"
          style={{ height: "23px" }}
        />
        {/* <span className="badge setting badge-danger">4</span> */}
        <br></br>
        {t("Notification")}
      </a>
      <div className="dropdown-menu notif " aria-labelledby="navbarDropdown">
        <span className="table table-hover ">
          <h6
            className="container font-weight-bolder"
            style={{ padding: "10px" }}
          >
            {t("Notification").toUpperCase()}

            <span
              aria-hidden="true"
              className="pull-right"
              style={{
                opacity: "0.5",
                fontSize: "25px",
                fontWeight: "normal",
                position: "relative",
                top: "-9px",
              }}
            >
              &times;
            </span>
          </h6>

          {localStorage.getItem("passwordAge") >= 30 ? (
            <div className="col-sm-12">
              <p style={{ color: "#212226", marginBottom: "-6px" }}>
                {t("password_change_text")}{" "}
                <strong>
                  {localStorage.getItem("passwordAge") <= 30
                    ? `30 ${t("password_change_days")}`
                    : ` ${
                        Math.floor(localStorage.getItem("passwordAge") / 30) >
                        11
                          ? 1
                          : Math.floor(localStorage.getItem("passwordAge") / 30)
                      }
            
            ${
              Math.floor(localStorage.getItem("passwordAge") / 30) > 11
                ? t("password_change_years")
                : t("password_change_month")
            }`}{" "}
                  .
                </strong>
              </p>
              <span style={{ fontSize: "12px", marginTop: "5 px" }}>
                <a href={baseUrl + "/change/password"}>
                  <strong style={{ color: "rgb(199, 47, 47)" }}>
                    {" "}
                    - {t("change_password")}
                  </strong>
                </a>
              </span>
              <hr></hr>
            </div>
          ) : (
            ""
          )}

          {notification.slice(0, 5).map((c) => (
            <div className="col-sm-12" key={c.id}>
              <p style={{ color: "#212226", marginBottom: "-6px" }}>
                {" "}
                {c.title}
              </p>
              <span style={{ fontSize: "12px" }}>
                {c.body}-{" "}
                <a href={c.link} target="_blank" rel="noopener noreferrer">
                  <strong style={{ color: "rgb(199, 47, 47)" }}>
                    {" "}
                    {c.link.split("/").reverse()[1]}{" "}
                  </strong>
                </a>
              </span>
              <br></br>

              <span
                style={{
                  fontSize: "13px",
                  opacity: "0.6",
                  marginTottom: "-22px",
                }}
              >
                <i>
                  {" "}
                  <TimeAgo date={c.created_at} locale="pl" />
                </i>
              </span>

              <span className="float-right">
                {" "}
                <button
                  type="button"
                  class="btn btn-danger"
                  onClick={handleDelete(c.id)}
                  style={{
                    background: "none",
                    border: "none",
                    opacity: 0.6,
                  }}
                >
                  <i className="fa fa-trash"></i>
                </button>
              </span>
              <hr></hr>
            </div>
          ))}

          <hr></hr>
          <center>
            <a
              href={baseUrl + "/all/notification/"}
              className="head"
              style={{ fontSize: "14px", color: "rgb(199, 47, 47)" }}
            >
              {" "}
              {t("see_all_notification")}
            </a>
          </center>
        </span>
      </div>
    </li>
  );
};

export default Notification;
