import React, { Component } from "react";
import Chart from "react-apexcharts";
import { withTranslation } from "react-i18next";

export class Trips extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      ...props,

      series: [
        {
          name: "series1",
          data: [],
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "area",
        },
        labels: [],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },

        fill: {
          opacity: 0.5,
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.3,
            opacityTo: 0.4,
            stops: [0, 100],
          },
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return Number.parseFloat(value).toFixed(2) + " %";
            },
          },
          x: {
            format: "category",
            formatter: function (val) {
              return t("time") + ": " + Number.parseFloat(val).toFixed(2);
            },
          },
        },
        xaxis: {
          type: "numeric",
          title: {
            offsetY: 10,
            text: t("trip_x"),
            style: {
              fontSize: "10px",
              color: "#8D8D90",
            },
          },
          labels: {
            formatter: function (value, timestamp, index) {
              return Number.parseFloat(value).toFixed(2);
            },
            style: {
              fontSize: "12px",
              color: "#8D8D90",
            },
          },

          categories: props.trip1.map((c) => c.label),
        },
        legend: {
          position: "bottom",
          horizontalAlign: "left",
          itemMargin: {
            horizontal: 15,
            vertical: 10,
          },
        },
        yaxis: {
          min: 0,
          max: 100,
          tickAmount: 5,
          title: {
            text: t("trp1_x"),

            style: {
              fontSize: "10px",
              color: "#8D8D90",
            },
          },

          labels: {
            formatter: function (value) {
              return value + " % ";
            },
            style: {
              fontSize: "10px",
              opacity: "0.2",
              color: "#8D8D90",
            },
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
        },
      },
    };
  }

  render() {
    const data1 = this.props.trip1.map((c, label) => ({
      label: c.label,
      value: c.value,
    }));

    const dat1 = [];

    for (let i = 0; i < data1.length; i++) {
      dat1.push({
        name: "T" + (i + 1),
        data: data1[i].value,
      });
    }
    // const rahul = [
    //   {
    //     name: "T 1",
    //     data: [
    //       { x: 0, y: 0 },
    //       { x: 1, y: 30 },
    //       { x: 2, y: 60 },
    //       { x: 3, y: 70 },
    //     ],
    //   },
    //   {
    //     name: "T 2",
    //     data: [
    //       { x: 0, y: 0 },
    //       { x: 0.5, y: 5 },
    //       { x: 1, y: 18 },
    //       { x: 1.5, y: 25 },
    //     ],
    //   },
    // ];
    const { t } = this.props;

    // console.log(this.props.battery.map((c) => c.label));
    return (
      <div style={{ marginLeft: "10px" }}>
        <p
          id="pppp"
          className=" float-left"
          style={{ paddingLeft: "25px", fontSize: "13px" }}
        >
          {t("trips").toUpperCase()}
        </p>
        <Chart
          style={{ marginTop: "0px" }}
          options={this.state.options}
          series={dat1}
          type="area"
          height="300px"
        />
      </div>
    );
  }
}

export default withTranslation()(Trips);
