import config from "../views/Main";

export async function getEv(to, from) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl1.ev_car}?date__lte=${to ? to : ""}&date__gte=${
        from ? from : ""
      }`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getEvDaily(id, date, to, from) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl1.ev_daily_view}${id}/day/${date}/profile/`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}
