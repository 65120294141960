import React from "react";
import ReactDOM from "react-dom";
import "react-app-polyfill/ie11";
// import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "jquery/dist/jquery.slim.js";

import "jquery";
import "react-app-polyfill/stable";
import "./index.css";
import "font-awesome/css/font-awesome.min.css";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "jquery-modal";

import App from "./App";

import * as serviceWorker from "./serviceWorker";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>,
  document.getElementById("root")
);

serviceWorker.register(); // Do this to resolve error
//serviceWorker.unregister();
console.clear();
