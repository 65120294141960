import Cookies from "universal-cookie";
const api = `https://digitalfleet.eu/api/1/`;
const api1 = `https://digitalfleet.eu/api/2/`;
// const local = `http://localhost:8000/api/2/`;
// let authToken = localStorage.getItem("Token");  #Move to Cookies for more security reson
const cookie = new Cookies();
let authToken = cookie.get("Token");

const config = {
  apiUrl: {
    driver: `${api}drivers/`,
    driversummary: `${api}drivers/summary/`,
    stats: `${api}stats/`,
    overall: `${api}histogram/score/overall/`,
    dynamics: `${api}histogram/score/dynamics/`,
    speed: `${api}histogram/score/speed/`,
    fuel: `${api}histogram/score/fuel/`,
    monthlyfuel: `${api}histogram/fuel/`,
    overspeeding: `${api}histogram/score/overspeeding/`,
    fueleconomy: `${api}histogram/fuel/`,
    campaignn: `${api}campaigns/`,
    car: `${api}cars/`,
    carsummary: `${api}cars/summary/`,
    carmap: `${api}trips/car/`,
    mapreport: `${api}stats/manager/car/`,
    carmapN: `${api}trips/fleet/trip/`,
    email: `${api}/notifications/email/`,
    sms: `${api}/notifications/sms/`,
    app: `${api}/notifications/`,
    me: `${api}users/me/`,
    my: `${api}fleets/my/`,
    login: `${api}oauth/token/dashboard/`,
    logout: ` ${api}oauth/revoke_token/`,
    validate_token:`${api}users/accesstoken/`,
    fnotification: `${api}notifications/fleet/`,
    fuel1: `${api}fuel/`,
    fuel_detected: `${api}fuel/detected/`,
    fleetnotification: `${api}fleets/notifications/`,
    usernotification: `${api}users/notifications/`,
    cost: `${api}costs/`,
    claim: `${api}claims/`,
    ecrecomend: `${api}evrecommend/`,
    evCar: `${api}evrecommend/car/`,
    evsummary: `${api}evrecommend/summary/`,
    app_trip: `${api}trips/`,
    segment: `${api}drivers/segment/`,
    dataSegment: `${api}fleets/segment-api/`,
    changePassword: `${api}users/update-password/`,
  },
  apiUrl1: {
    newDashboard: `${api1}dashboard/`, //`https://run.mocky.io/v3/5118853a-5844-4dee-94c8-31db6f429221`, //`${api1}dashboard/index.json`,
    myDriver: `${api1}drivers/`,
    myCar: `${api1}cars/`,
    ev_car: `${api1}cars/ev/`,
    company_profile: `${api1}fleets/insurance-profile/`,
    ev_daily_view: `${api1}cars/ev/`,
  },
  head: {
    method: "GET",
    headers: {
      //"X-Frame-Options": "SAMEORIGIN",
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Language": window.localStorage.i18nextLng,
      Authorization: "Bearer " + authToken,
    },
  },

  headPost: {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Language": window.localStorage.i18nextLng,
      Authorization: "Bearer " + authToken,
    },
  },

  headDelete: {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Language": window.localStorage.i18nextLng,
      Authorization: "Bearer " + authToken,
    },
  },
  headOption: {
    method: "OPTIONS",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Language": window.localStorage.i18nextLng,
      Authorization: "Bearer " + authToken,
      // "X-Frame-Options": "SAMEORIGIN",
      // "X-XSS-Protection": "1; mode=block",
      // "Referrer-Policy": "no-referrer-when-downgrade",
      // "X-Content-Type-Options": "nosniff",
      // "Feature-Policy":
      //   "fullscreen 'self; geolocation 'self'; notifications 'self'",
    },
  },

  headPut: {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Language": window.localStorage.i18nextLng,
      Authorization: "Bearer " + authToken,
      // "X-Frame-Options": "SAMEORIGIN",
      // "X-XSS-Protection": "1; mode=block",
      // "Referrer-Policy": "no-referrer-when-downgrade",
      // "X-Content-Type-Options": "nosniff",
      // "Feature-Policy":
      //   "fullscreen 'self; geolocation 'self'; notifications 'self'",
    },
  },

  headDelete1: {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Language": window.localStorage.i18nextLng,
      Authorization: "Bearer notification",
    },
  },
};

export default config;

// Fake data for Electric car daily view.
//http://www.mocky.io/v2/5ebbd4bf360000790cf7ea3a
