import originalMoment from "moment";

const DateLte = (data, data1) =>
  data === "today"
    ? originalMoment().format("YYYY-MM-DD")
    : data === "yesturday"
    ? originalMoment().subtract(1, "days").format("YYYY-MM-DD")
    : data === "week"
    ? originalMoment().subtract(1, "weeks").startOf("week").format("YYYY-MM-DD")
    : data === "quarter"
    ? originalMoment()
        .subtract(1, "quarter")
        .startOf("quarter")
        .format("YYYY-MM-DD")
    : data === "year"
    ? originalMoment().subtract(1, "year").startOf("year").format("YYYY-MM-DD")
    : data === "month"
    ? originalMoment()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD")
    : data === "date"
    ? data1
    : "";

export default DateLte;

//originalMoment().subtract(1, "year").endOf("year").format("YYYY")
//originalMoment().subtract(1, "month").endOf("month").format("M")
