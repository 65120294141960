import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import AddFuelReport from "./AddFuelReport";
import MonthlyReport from "./MonthlyReport";
import Spinner from "../../../Container/Spinner";
import config from "../../Main";
import moment from "moment";
import TableExport from "tableexport";

class Fuel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fake: [],
      fuel: [],
      fueladd: [],
      date1: "",
      date2: "",
      loading: true,
    };
    this.onChange = this.onChange.bind(this);
  }

  handledownload() {
    TableExport(document.getElementsByTagName("table"), {
      headers: true, // (Boolean), display table headers (th or td elements) in the <thead>, (default: true)
      footers: true, // (Boolean), display table footers (th or td elements) in the <tfoot>, (default: false)
      formats: ["xlsx"], // (String[]), filetype(s) for the export, (default: ['xlsx', 'csv', 'txt'])
      filename: "Fuelreport", // (id, String), filename for the downloaded file, (default: 'id')
      exportButtons: true, // (Boolean), automatically generate the built-in export buttons for each of the specified formats (default: true)
      position: "bottom", // (top, bottom), position of the caption element relative to table, (default: 'bottom')
      trimWhitespace: false, // (Boolean), remove all leading/trailing newlines, spaces, and tabs from cell text in the exported file(s) (default: false)
    });
  }
  async handleupdate(e) {
    try {
      Promise.all([
        await fetch(
          `${config.apiUrl.fuel1}?datetime__year=${this.props.location.state.fol}&datetime__month=${this.props.location.state.lol}&datetime__day=${this.state.date1}&car__in=${this.props.location.state.carr}`,
          config.head
        )
          .then((fuel) => fuel.json())
          .then((fuel) => {
            this.setState({
              fuel,
              loading: false,
            });
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          }),
        await fetch(
          `${config.apiUrl.fuel_detected}?datetime__year=${this.props.location.state.fol}&datetime__month=${this.props.location.state.lol}&datetime__day=${this.state.date2}&car__in=${this.props.location.state.carr}`,
          config.head
        )
          .then((fueladd) => fueladd.json())
          .then((fueladd) => {
            this.setState({
              fueladd,
              loading: false,
            });
          })
          .catch((e) => {
            this.setState({
              loading: false,
            });
          }),
      ]);
    } catch (e) {
      console.log(e);
    }
  }

  async onChange(e) {
    await this.setState({
      [e.target.name]: e.target.value,
    });

    this.handleupdate("");
  }

  async componentDidMount() {
    this.handleupdate("");
  }

  render() {
    const { fuel, fueladd } = this.state;
    const { t } = this.props;
    localStorage.setItem("Year", this.props.location.state.fol);
    localStorage.setItem("Month", this.props.location.state.lol);

    const fuelsumm = fuel.reduce((accumulator, pilot) => {
      var output = accumulator + pilot.liters;
      return output;
    }, 0);

    const fueladdsum = fueladd.reduce((accumulator, pilot) => {
      var output = accumulator + pilot.liters;
      return output;
    }, 0);
    const month =
      this.props.location.state.lol === "1"
        ? t("january")
        : this.props.location.state.lol === "2"
        ? t("feburuary")
        : this.props.location.state.lol === "3"
        ? t("march")
        : this.props.location.state.lol === "4"
        ? t("april")
        : this.props.location.state.lol === "5"
        ? t("may")
        : this.props.location.state.lol === "6"
        ? t("june")
        : this.props.location.state.lol === "7"
        ? t("july")
        : this.props.location.state.lol === "8"
        ? t("august")
        : this.props.location.state.lol === "9"
        ? t("september")
        : this.props.location.state.lol === "10"
        ? t("october")
        : this.props.location.state.lol === "11"
        ? t("november")
        : this.props.location.state.lol === "12"
        ? t("december")
        : "-";

    const uniqueDates = [
      ...new Set(
        this.state.fuel.map((i) => `${moment(i.datetime).format("DD")}`)
      ),
    ];

    const options1 = [];

    for (var i = 0; i < uniqueDates.length; i++) {
      const don = uniqueDates[i];

      options1.push(
        <option value={don} key={don}>
          {don}
        </option>
      );
    }

    // const uniqueDates1 = [
    //   ...new Set(
    //     this.state.fueladd.map(i => `${moment(i.datetime).format("DD")}`)
    //   )
    // ];

    // const options11 = [];

    // for (var i = 0; i < uniqueDates1.length; i++) {
    //   const don = uniqueDates1[i];

    //   options11.push(
    //     <option value={don} key={don}>
    //       {don}
    //     </option>
    //   );
    // }

    return (
      <div className="container1">
        <AddFuelReport />
        <MonthlyReport
          mon={month}
          year={this.props.location.state.fol}
          month={this.props.location.state.lol}
        />
        <div className="headerr" style={{ marginTop: "40px" }}>
          <h6>
            {t("fuel_report").toUpperCase()}
            <span style={{ marginLeft: "40px" }}>
              {month} - {this.props.location.state.fol}
            </span>

            <a
              href="# "
              // onClick={this.handleupdate.bind(this)}
              className="float-right "
              style={{ color: "#b71c1c" }}
            >
              <i className="fa fa-refresh" style={{ fontSize: "14px" }}></i>{" "}
              Display Uploaded Data
            </a>
          </h6>

          <div className="row">
            <div className="col-md-6  caaar">
              {this.state.loading ? (
                <Spinner />
              ) : (
                <table
                  id="table-to-xls"
                  className="table table-hover table-bordered"
                >
                  <thead>
                    <tr>
                      <th
                        colSpan="5"
                        className="h6"
                        style={{ textAlign: "center" }}
                      >
                        {t("data_from_car").toUpperCase()}
                      </th>
                    </tr>
                    <tr className="th ">
                      <th>{t("Id")}</th>
                      <th>{t("plate_number1")}</th>
                      <th>
                        {t("refil_date")} &nbsp;
                        <input
                          type="number"
                          maxLength={2}
                          min={1}
                          style={{
                            border: "2px solid #faeded",
                            borderBottom: "1px solid black",
                            background: "#faeded",
                            marginTop: "-25px",
                            width: "40px",
                          }}
                          onChange={this.onChange}
                          name="date2"
                        />
                      </th>
                      <th>{t("fuel_mileage")}</th>
                      <th>{t("fuel_add")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fueladd.map((c, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>
                          {c.car_plate_number ? `${c.car_plate_number}` : "-"}
                        </td>
                        <td>
                          {c.datetime
                            ? `${moment(c.datetime).format("DD-MM-YYYY")}`
                            : "-"}
                        </td>
                        <td>{c.odometer ? `${c.odometer.toFixed(1)}` : "-"}</td>
                        <td>{c.liters ? `${c.liters.toFixed(1)}` : "-"}</td>
                      </tr>
                    ))}
                  </tbody>

                  <tfoot>
                    <th colSpan={4} className="h6">
                      {" "}
                      {t("Summary1").toUpperCase()}
                    </th>
                    <th className="h6">
                      {" "}
                      {fueladdsum ? `${fueladdsum.toFixed(1)}` : "-"}
                    </th>
                  </tfoot>
                </table>
              )}
            </div>
            <div className="col-md-6  caaar">
              {this.state.loading ? (
                <Spinner />
              ) : (
                <table
                  id="table-to-xls1"
                  className="table   table-hover table-bordered"
                >
                  <thead>
                    <tr>
                      <th
                        colSpan="5"
                        className="h6"
                        style={{ textAlign: "center" }}
                      >
                        {t("data_from_fuel_card").toUpperCase()}

                        <a
                          href="#montly"
                          rel="modal:open"
                          className="float-right"
                          style={{ color: "#b71c1c" }}
                        >
                          {t("add")}
                        </a>
                      </th>
                    </tr>
                    <tr className="th ">
                      <th>{t("Id")}</th>
                      <th>{t("plate_number1")}</th>
                      <th>
                        {t("refil_date")} &nbsp;
                        <input
                          type="number"
                          maxLength={2}
                          min={1}
                          style={{
                            border: "2px solid #faeded",
                            borderBottom: "1px solid black",
                            background: "#faeded",
                            marginTop: "-25px",
                            width: "40px",
                          }}
                          onChange={this.onChange}
                          name="date1"
                        />
                      </th>
                      <th>{t("fuel_mileage")}</th>
                      <th>{t("fuel_add")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fuel.map((c, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>
                          {c.car_plate_number ? `${c.car_plate_number}` : "-"}
                        </td>
                        <td>
                          {c.datetime
                            ? `${moment(c.datetime).format("DD-MM-YYYY")}`
                            : "-"}
                        </td>
                        <td>{c.odometer ? `${c.odometer.toFixed(1)}` : "-"}</td>
                        <td>{c.liters ? `${c.liters.toFixed(1)}` : "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <th colSpan={4} className="h6">
                      {" "}
                      {t("Summary1").toUpperCase()}
                    </th>
                    <th className="h6">
                      {" "}
                      {fuelsumm ? `${fuelsumm.toFixed(1)}` : "-"}
                    </th>
                  </tfoot>
                </table>
              )}
            </div>
          </div>

          <center>
            {" "}
            <input
              type="button"
              className="btn btn-success btn-lg bb"
              // id="btnExport"
              onClick={this.handledownload.bind(this)}
              value={t("export_report")}
            />
            <a
              className="btn"
              href="#fuel"
              rel="modal:open"
              id="bttt"
              style={{ marginTop: "10px" }}
            >
              {t("new_report")}
            </a>
          </center>
          {/* <div className="float-right" style={{ marginTop: "-80px" }}>
            <left></left>
          </div> */}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Fuel);
