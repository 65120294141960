import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Formal from "./AdditionAdd/Formal";
import { withTranslation } from "react-i18next";
import config from "../../Main";
import Informations from "./Additional/Informations";
import UsedDriver from "./AdditionAdd/UsedDriver";
import UploadDoc from "./Additional/Upload/UploadDoc";
import ProfileTop from "../../../Components/Layout/ProfileTop";
import arrow_left from "../../../img/Aarrow.png";
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "universal-cookie";
const cookie = new Cookies();
let authToken = cookie.get("Token");
class EditCar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      swt: true,
      isHidden: true,

      isHidden1: true,
      type: null,
      brand: "",
      model: "",
      vin: "",
      production_year: "",
      engine_capacity: "",
      engine_power: "",
      transmission_type: "",
      plate_number: "",
      first_registration: "",
      next_registration_exam: "",
      insurance_expiration: "",
      oil_change_interval: "",
      last_oil_inspecion: "",
      last_oil_change_mileage: "",
      hours_start: "",
      hours_end: "",
      status: "",
      drivers: [
        {
          since: null,
          until: null,
          driver: "",
        },
      ],
      car_photo: [
        {
          category: "",
          sub_category: "",
          file: [],
        },
      ],
      additional_information: [
        {
          information: "",
          date: "",
          mileage: "",
          notification_date: "",
          notification_mileage: "",
          notification_by_mileage: false,
          notification_by_date: false,
        },
      ],
    };
    this.onChange = this.onChange.bind(this);
    this.toggleHidden = this.toggleHidden.bind(this);
    this.toggleHidden1 = this.toggleHidden1.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }
  toggleHidden1() {
    this.setState({
      isHidden1: !this.state.isHidden1,
    });
  }

  handleSubmit(e) {
    const base = process.env.PUBLIC_URL;
    const { t } = this.props;
    const id = this.props.match.params.pk;
    e.preventDefault();

    const data = {
      type: this.state.type,
      brand: this.state.brand,
      model: this.state.model,
      vin: this.state.vin,
      production_year: this.state.production_year,
      engine_capacity: this.state.engine_capacity,
      engine_power: this.state.engine_power,
      transmission_type: this.state.transmission_type,
      plate_number: this.state.plate_number,
      first_registration: this.state.first_registration,
      next_registration_exam: this.state.next_registration_exam,
      insurance_expiration: this.state.insurance_expiration,
      oil_change_interval: this.state.oil_change_interval,
      last_oil_inspecion: this.state.last_oil_inspecion,
      last_oil_change_mileage: this.state.last_oil_change_mileage,
      hours_start: this.state.hours_start,
      hours_end: this.state.hours_end,
      drivers: [...this.state.drivers],
      additional_information: [...this.state.additional_information],
      status: this.state.status,
    };

    fetch(`${config.apiUrl.car}${this.props.match.params.pk}/profile/`, {
      method: "PUT",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "Accept-Language": window.localStorage.i18nextLng,
        Authorization: "Bearer " + authToken,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: "Car",
            type: "success",
            text: t("Edit_success"),
            showConfirmButton: false,
            timer: 3000,
          });
          setTimeout(function () {
            window.location.href =
              base + "/overview/app/carmetrics/" + id + "/";
          }, 2000);
        } else {
          Swal.fire({
            title: "Car",
            type: "error",
            text: "Error while updating new Car!",
            timer: 2000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    axios
      .get(`${config.apiUrl.car}${this.props.match.params.pk}/profile/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + authToken,
        },
      })
      .then((res) => {
        this.setState({
          type: res.data.type,
          brand: res.data.brand,
          model: res.data.model,
          production_year: res.data.production_year,
          engine_capacity: res.data.engine_capacity,
          engine_power: res.data.engine_power,
          transmission_type: res.data.transmission_type,
          plate_number: res.data.plate_number,
          vin: res.data.vin,
          first_registration: res.data.first_registration,
          next_registration_exam: res.data.next_registration_exam,
          insurance_expiration: res.data.insurance_expiration,
          oil_change_interval: res.data.oil_change_interval,
          last_oil_inspecion: res.data.last_oil_inspecion,
          last_oil_change_mileage: res.data.last_oil_change_mileage,
          hours_start: res.data.hours_start,
          hours_end: res.data.hours_end,
          drivers: [...res.data.drivers],
          additional_information: [...res.data.additional_information],
          car_photo: [...res.data.car_photo],
          status: res.data.status,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async onChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
    console.log(this.state);
  }

  async onClick(e) {
    await this.setState({
      swt: !this.state.swt,
    });
  }

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }
  render() {
    let fol = [];
    let year = 0;
    for (year = 2000; year < 2099; year++) {
      fol.push(year);
    }

    const { t } = this.props;

    return (
      <div className="container1" style={{ marginTop: "30px" }}>
        <Helmet>
          <title>{t("car_metrics_edit")}</title>
        </Helmet>
        {/* <Doc /> */}

        <ProfileTop />
        <div className="headerr" id="headerr" style={{ marginTop: "-6px" }}>
          <div style={{ padding: "10px" }}>
            <a href="# " onClick={this.handleClick.bind(this)}>
              <img
                src={arrow_left}
                alt="Left_Arrow"
                style={{ height: "2.6em" }}
              />
            </a>
            &nbsp;&nbsp;
            {t("car_metrics_edit").toUpperCase()} /{" "}
            <strong>
              {this.state.brand} {this.state.model}
            </strong>{" "}
            &nbsp;
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-sm-12">
                <h3 className="font-weight-bold" style={{ marginTop: "30px" }}>
                  {t("formal_information")}
                  <span
                    className="float-right"
                    style={{
                      // borderBottom: "2px solid  rgb(183, 28, 28, 0.2)",
                      marginTop: "-6px",
                      //marginLeft: "-12px"
                    }}
                  >
                    <label class="switch1">
                      <input
                        type="checkbox"
                        id="togBtn"
                        name="status"
                        onChange={this.onChange.bind(this)}
                        checked={this.state.status === "active" ? true : false}
                        value={
                          this.state.status === "active" ? "inactive" : "active"
                        }
                      />
                      <div class="slider1 round"></div>
                    </label>
                  </span>

                  <p
                    className="float-right"
                    style={{
                      marginRight: "10px",
                      fontsize: "15px",
                      marginTop: "-5px",
                    }}
                  >
                    {" "}
                    <span>
                      {" "}
                      {!this.state.swt ? `${t("disable")}` : `${t("active")}`}
                    </span>
                  </p>
                </h3>
                <hr></hr>
                <Formal loadd={this.onChange} vall={this.state} />

                {/* ANd Row start from here */}
                {/* <div className="form-group">
                  <label id="p" for="exampleInputEmail1">
                    {t("engine_type").toUpperCase()}
                  </label>
                  <div className="row">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="col-sm-2">
                      <input
                        type="radio"
                        placeholder="A Short Description"
                        name="type"
                        // defaultValue={this.state.type}
                        value="ON"
                        id="r1"
                        onChange={this.onChange}
                        checked={this.state.type === "ON"}
                        class="form-check-input"
                      />
                      <label id="p" htmlFor="r1">
                        ON
                      </label>
                    </div>
                    <div
                      className="col-sm-9"
                      style={{ position: "relative", left: "-5%" }}
                    >
                      <div className="row">
                        <div className="col-sm-2">
                          <input
                            type="radio"
                            placeholder="A Short Description"
                            name="type"
                            //defaultValue={this.state.type}
                            value="Pb"
                            id="r2"
                            checked={this.state.type === "Pb"}
                            onChange={this.onChange}
                            class="form-check-input"
                          />
                          <label id="p" htmlFor="r2">
                            Pb
                          </label>
                        </div>
                        <div className="col-sm-2">
                          <input
                            type="radio"
                            placeholder="A Short Description"
                            name="type"
                            //defaultValue={this.state.type}
                            id="r3"
                            value="BEV"
                            checked={this.state.type === "BEV"}
                            onChange={this.onChange}
                            class="form-check-input"
                          />
                          <label id="p" htmlFor="r3">
                            BEV
                          </label>
                        </div>
                        <div className="col-sm-2">
                          <input
                            type="radio"
                            placeholder="A Short Description"
                            name="type"
                            //defaultValue={this.state.type}
                            id="r4"
                            value="HEV"
                            checked={this.state.type === "HEV"}
                            onChange={this.onChange}
                            class="form-check-input"
                          />
                          <label id="p" htmlFor="r4">
                            HEV
                          </label>
                        </div>
                        <div className="col-sm-2">
                          <input
                            type="radio"
                            placeholder="A Short Description"
                            name="type"
                            // defaultValue={this.state.type}
                            id="r5"
                            value="PHEV"
                            onChange={this.onChange}
                            class="form-check-input"
                          />
                          <label id="p" htmlFor="r5">
                            PHEV
                          </label>
                        </div>

                        <div className="col-sm-2">
                          <input
                            type="radio"
                            placeholder="A Short Description"
                            name="type"
                            // defaultValue={this.state.type}
                            id="r55"
                            value="CNG"
                            onChange={this.onChange}
                            class="form-check-input"
                          />
                          <label id="p" htmlFor="r55">
                            CNG
                          </label>
                        </div>
                        <div className="col-sm-2">
                          <input
                            type="radio"
                            placeholder="A Short Description"
                            name="type"
                            // defaultValue={this.state.type}
                            id="r45"
                            value="FCEV"
                            onChange={this.onChange}
                            class="form-check-input"
                          />
                          <label id="p" htmlFor="r45">
                            FCEV
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* Row End Here */}

                {/* ANd Row start from here */}
                {/* <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label id="p" htmlFor="brand">
                        {t("Brand").toUpperCase()}
                      </label>

                      <input
                        placeholder={t("type")}
                        name="brand"
                        value={this.state.brand}
                        onChange={this.onChange}
                        class="form-control donn"
                      />

                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="form-group">
                      <label id="p" htmlFor="model">
                        {t("Model").toUpperCase()}
                      </label>
                      <input
                        placeholder={t("type")}
                        name="model"
                        value={this.state.model}
                        onChange={this.onChange}
                        class="form-control donn"
                      />
                     
                    </div>
                  </div> */}
                {/* 
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label id="p" htmlFor="year">
                        {t("production_year").toUpperCase()}
                      </label>
                      <input
                        placeholder={t("type")}
                        name="production_year"
                        value={this.state.production_year}
                        onChange={this.onChange}
                        class="form-control donn"
                      />
                    </div>
                  </div> */}
                {/* </div> */}
                {/* Row End Here */}

                {/* ANd Row start from here */}
                {/* <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label id="p" htmlFor="brand">
                        {t("engine_capacity").toUpperCase()}
                      </label>
                      <input
                        name="engine_capacity"
                        placeholder={t("type")}
                        value={this.state.engine_capacity}
                        onChange={this.onChange}
                        className="form-control donn"
                      />
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="form-group">
                      <label id="p" htmlFor="model">
                        {t("power").toUpperCase()} [kW]
                      </label>

                      <input
                        placeholder={t("type")}
                        name="engine_power"
                        value={this.state.engine_power}
                        onChange={this.onChange}
                        class="form-control donn"
                      />
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="form-group">
                      <label id="p" htmlFor="year">
                        {t("gearbox_type").toUpperCase()}
                      </label>
                      <select
                        name="transmission_type"
                        value={this.state.transmission_type}
                        onChange={this.onChange}
                        class="form-control donn"
                      >
                        <option value={this.state.transmission_type}>
                          {this.state.transmission_type}
                        </option>
                        <option value="automatic">Automatic</option>
                        <option value="manual">Manual</option>
                      </select>
                    </div>
                  </div>
                </div> */}

                {/* Row End Here */}

                <div className="carr" style={{ marginTop: "30px" }}>
                  <h3
                    className="font-weight-bold"
                    style={{ marginTop: "30px" }}
                  >
                    {t("car_documents")}
                  </h3>
                  <hr></hr>

                  <UploadDoc
                    doc={this.state.car_photo}
                    loaddata={this.onChange}
                    submit={this.handleSubmit}
                    id={this.props.match.params.pk}
                  />
                </div>

                <div className="carr" style={{ marginTop: "50px" }}>
                  <h3
                    className="font-weight-bold"
                    style={{ marginTop: "30px" }}
                  >
                    {t("used_driver")}
                  </h3>
                  <hr></hr>
                  <UsedDriver
                    loaddata={this.onChange}
                    vall={this.state.drivers}
                  />
                </div>

                {/* ANd Row start from here */}
              </div>

              {/* <div className="col-sm-5">
                <h3 className="font-weight-bold" style={{ marginTop: "30px" }}>
                  Formal Information
                </h3>
                <hr></hr>
                <Formal loadd={this.onChange} vall={this.state} />
              </div> */}
            </div>

            <div className="carr" style={{ marginTop: "30px" }}>
              <h3 className="font-weight-bold" style={{ marginTop: "30px" }}>
                {t("additonal_information")}
              </h3>
              <hr></hr>
              <Informations
                loaddata={this.onChange}
                vall={this.state.additional_information}
              />
            </div>

            <center>
              <button
                className="btn btn-danger br text-white"
                value="create campaign"
                style={{ background: "#b71c1c" }}
              >
                {t("save").toUpperCase()}
              </button>
            </center>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(EditCar);
