import React from "react";
import { useTranslation } from "react-i18next";

function Input(props) {
  const { t } = useTranslation();

  const parameter = [
    {
      label: t("notification_critical_overspeeding"),
      name: "notify_critical_overspeeding",
    },
    {
      label: t("notification__overspeeding"),
      name: "notify_overspeeding",
    },
    {
      label: t("notification__exceeding_fuel"),
      name: "notify_exceeding_fuel",
    },
    {
      label: t("notification__campaign_confirmation"),
      name: "notify_campaign_confirmation",
    },
    {
      label: t("notification__campaign_start"),
      name: "notify_campaign_start",
    },
    {
      label: t("notification__vechicle_technical"),
      name: "notify_car_inspection",
    },
    {
      label: t("notification__insurance"),
      name: "notify_insurance",
    },
    {
      label: t("notification__maintenance"),
      name: "notify_maintenance",
    },
  ];

  const user_parameter = [
    {
      label: t("notification_critical_overspeeding"),
      name: "notify_critical_overspeeding",
    },
    {
      label: t("notification__overspeeding"),
      name: "notify_overspeeding",
    },
  ];

  return (
    <div>
      <div className="row" style={{ marginTop: "10px", marginBottom: "30px" }}>
        {parameter.map((c, i) => (
          <div
            key={i + 1}
            class=" col-md-7 "
            style={{
              marginTop: "20px",
              margin: "auto",
            }}
          >
            <div
              style={{
                padding: "10px",
                fontSize: "15px",
                marginTop: "20px",
              }}
            >
              <strong class="float-left font-weight-bold">{c.label}</strong>
              <small
                className="float-right"
                style={{ position: "relative", top: "-1px" }}
              >
                <label class="switch1">
                  <input
                    type="checkbox"
                    id="togBtn"
                    name={c.name}
                    onChange={props.change}
                    checked={props.state[c.name]}
                  />
                  <div class="slider1 round"></div>
                </label>
              </small>
            </div>
            <hr></hr>
          </div>
        ))}
      </div>
      <div>
        {" "}
        <h6 className="col-sm-7" style={{ margin: "auto" }}>
          <span className="font-weight-bold h5">{t("individual_setting")}</span>
          <span className="h5"></span>
        </h6>
        <hr></hr>
        <div className="row">
          {user_parameter.map((c, i) => (
            <div className="col-md-7" style={{ margin: "auto" }}>
              <div
                style={{
                  padding: "10px",
                  fontSize: "15px",
                }}
              >
                <strong class="float-left font-weight-bold">{c.label}</strong>
                <small
                  className="float-right"
                  style={{ position: "relative", top: "-1px" }}
                >
                  <label class="switch1">
                    <input
                      type="checkbox"
                      id="togBtn"
                      name={c.name}
                      onChange={props.change}
                      checked={props.state[c.name]}
                    />
                    <div class="slider1 round"></div>
                  </label>
                </small>
              </div>
              <hr></hr>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Input;
