import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Datee from "../../Views/Date";
import User from "../../../Components/User";
import Header1 from "../../../Components/Layout/Header1";
import { withTranslation } from "react-i18next";
import Driver from "../Driver/Driver";
import Filter from "../Filter";
import { getNewDashboardData } from "../../../Service/NewDashboard";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import print from "../../../img/Rating/print.png";
import print_pl from "../../../img/Rating/print_pl.png";
import arrowL from "../../../Service/Arrow/Arrow";
import DateGte from "../../../Service/Arrow/DateGte";
import DateLte from "../../../Service/Arrow/DateL";
import getPrint from "../../../Service/Arrow/Print";
import Spinner from "../../../Container/Spinner";
import "./Fleet.css";

const moment = extendMoment(originalMoment);

export class Fleet extends Component {
  constructor(props, context) {
    super(props, context);
    const today = moment();

    this.state = {
      c: [],
      speed: [],
      overall: [],
      speed1: [],
      monthly: [],
      fuel: [],
      dynamic: [],
      lol: null,
      fol: null,
      lol1: null,
      fol1: null,
      year: "",
      isOpen: false,
      today: null,
      neww: [],
      isLoading: true,
      value: moment.range(today.clone().subtract(0, "days"), today.clone()),
    };
  }

  onToggle = (event) => {
    if (event.target.value === "date") {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  getData = (value, states) => {
    this.setState({ isLoading: true });
    getNewDashboardData(
      value.end.format("YYYY-MM-DD"),
      value.start.format("YYYY-MM-DD")
    ).then((data) => {
      this.setState({
        neww: data.rows,
        lol1: value.start,
        fol1: value.end,
        isOpen: !this.state.isOpen,
        isLoading: false,
      });
    });

    localStorage.setItem("Date_gte", this.state.fol1);
    localStorage.setItem("Date_lte", this.state.lol1);
  };

  async newData() {
    this.setState({ isLoading: true });
    getNewDashboardData(
      DateGte(this.state.today),
      DateLte(this.state.today)
    ).then((data) => {
      this.setState({
        neww: data.rows,
        lol: this.state.today,
        fol: this.state.today,
        isLoading: false,
      });
    });
  }

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
      isLoading: false,
    });
    this.newData("");
  };

  componentDidMount() {
    this.newData("");
  }

  render() {
    const { t } = this.props;
    const { neww, lol, fol, lol1, fol1 } = this.state; //c
    var d = new Date();
    var mm = d.getMonth() + 1;
    var dd = d.getDate();
    var yy = d.getFullYear();
    var ccc = yy + "-" + mm + "-" + dd; //(US)
    const widget = neww.filter((c) => c.type === "widgets");
    const chart = neww.filter((c) => c.type === "charts");
    const base = process.env.PUBLIC_URL;

    return (
      <div className="Main_section" id="main_section">
        {/* <div class="elfsight-app-7efb87d7-ed6f-426e-b99d-8335f2d34e29"></div> */}
        <Helmet>
          <title>Fleet Dashboard</title>
        </Helmet>

        <div className="row row1" style={{ width: "100%" }}>
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <Filter
              lol={this.state.lol1}
              fol={this.state.fol1}
              today={this.state.today}
              option={this.state.isOpen}
              toggle={this.onToggle}
              change={this.getData}
              hello={this.onChange}
            />
          </div>
          <div className="col-sm-6" style={{ marginTop: "-5px" }}></div>
          <div style={{ marginTop: "-20px" }}>
            <Header1 />
          </div>
        </div>
        <div className="headerr fleet_main1" id="headerr">
          {this.state.isLoading === true ? (
            <Spinner />
          ) : (
            <div style={{ marginTop: "50px", paddingLeft: "60px" }}>
              <div className="cont">
                <h4
                  className="text-left font-weight-bolder "
                  style={{ paddingLeft: "18px" }}
                >
                  <span className="summary_text">{t("Summary1")}</span>

                  <span
                    className="nav-item float-right  "
                    disabled
                    style={{ marginLeft: "20px", marginTop: "-60px" }}
                  >
                    <a href="# " onClick={getPrint}>
                      <img
                        src={
                          window.localStorage.i18nextLng === "en"
                            ? print
                            : print_pl
                        }
                        alt="Print page"
                        style={{ marginTop: "2px", height: "1.6em" }}
                      />
                    </a>
                  </span>
                </h4>
                <div className="row " style={{ width: "100%" }}>
                  <div
                    className=" row  just_finish justify-content-center"
                    style={{ width: "100%", paddingLeft: "30px" }}
                  >
                    {widget.map((cc) =>
                      cc.items.map((ii, i) => (
                        <div
                          className="col-md-2 something_image "
                          style={{
                            marginTop: "5px",

                            backgroundImage: `url(${ii.background})`,
                          }}
                          key={i + 1}
                        >
                          <div
                            className="box mobile_version "
                            style={{
                              width: "200px",
                              marginTop: "25px",
                              height: "132px",
                            }}
                          >
                            <div className="fleet_data">
                              {ii.link === "critical" ? (
                                <p id="fleet_main_title" className="text-left">
                                  <a
                                    href={
                                      base +
                                      `/ddriver/my/driver/${
                                        lol1
                                          ? `${lol1.format("YYYY-MM-DD")}`
                                          : lol
                                          ? DateLte(this.state.today)
                                          : "2016-01-04"
                                      }/${
                                        fol1
                                          ? `${fol1.format("YYYY-MM-DD")}`
                                          : fol
                                          ? DateGte(this.state.today)
                                          : `${ccc}`
                                      }`
                                    }
                                    title="Click to view Critical overSpeeding"
                                    id="fleet_main_title "
                                    className="link_title"
                                  >
                                    {ii.label ? ii.label : "-"}
                                  </a>
                                </p>
                              ) : ii.link === "risk" ? (
                                <p id="fleet_main_title" className="text-left">
                                  <a
                                    href={
                                      base +
                                      `/overall/risk/${
                                        lol1
                                          ? `${lol1.format("YYYY-MM-DD")}`
                                          : lol
                                          ? DateLte(this.state.today)
                                          : "2016-01-04"
                                      }/${
                                        fol1
                                          ? `${fol1.format("YYYY-MM-DD")}`
                                          : fol
                                          ? DateGte(this.state.today)
                                          : `${ccc}`
                                      }`
                                    }
                                    title="Click to view Critical overSpeeding"
                                    id="fleet_main_title "
                                    className="link_title"
                                  >
                                    {ii.label ? ii.label : "-"}
                                  </a>
                                </p>
                              ) : (
                                <p id="fleet_main_title" className="text-left">
                                  {ii.label ? ii.label : "-"}
                                </p>
                              )}

                              <div className="row  text-section ">
                                <div
                                  className={
                                    ii.icon === null ? "col-md-12" : "col-md-6"
                                  }
                                >
                                  <h1 className="fleet_title">
                                    {" "}
                                    {ii.value ? ii.value : "-"}
                                  </h1>

                                  <p className="percentage_change">
                                    {arrowL(
                                      ii.changeDirection,
                                      ii.changePositive,
                                      ii.change
                                    )}
                                  </p>
                                </div>
                                <div
                                  className={
                                    ii.icon === null ? "col" : "col-md-6"
                                  }
                                >
                                  {ii.icon !== null && (
                                    <img
                                      src={ii.icon ? ii.icon : ""}
                                      style={{ marginLeft: "-20px" }}
                                      alt="weight"
                                      className="fleet_image mobile_image"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>

              <div className="fleet_graph">
                <div
                  className="row just_finish justify-content-center"
                  style={{ width: "100%" }}
                >
                  {chart.map((c) =>
                    c.items.map((i, ii) => (
                      <div className="col-lg-6 sm mobile_chart" key={ii + 1}>
                        <Driver chart={chart} i={i} />
                      </div>
                    ))
                  )}
                </div>
              </div>

              <center>
                <a
                  href={base + "/on/progress/"}
                  className="btn btn-recommend mobile_button"
                  style={{ width: "auto" }}
                >
                  {t("learn_how")}
                </a>
              </center>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Fleet);
