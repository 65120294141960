import React, { Component } from "react";
import { Helmet } from "react-helmet";
import config from "../../../Main";
import { withTranslation } from "react-i18next";
import User from "../../../../Components/User";
import Datee from "../../../Views/Date";
import Recommendation from "../../../../Components/Layout/Recommendation";
import Spinner from "../../../../Container/Spinner";

class Profilee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true,
      stats: [],
      recomend: [],
      detail: [],
      car: [],
      basic: [],
      power: [],
      isLoading: true,
      isLoading1: true,
    };
  }

  async componentDidMount() {
    try {
      Promise.all([
        await fetch(
          `${config.apiUrl.car}${this.props.match.params.pk}/`,
          config.head
        )
          .then((stats) => stats.json())
          .then((stats) => {
            this.setState({
              ...this.state,
              stats,
              detail: stats.stats,
              basic: stats.basicInformation,
              power: stats.powerBodyKit,
              isLoading: false,
            });
          }),

        fetch(
          `${config.apiUrl.evCar}${this.props.match.params.pk}/`,
          config.head
        )
          .then((recomend) => recomend.json())
          .then((recomend) => {
            this.setState({
              ...this.state,
              recomend,
              car: recomend.recommended,
              isLoading1: false,
            });
          }),
      ]);
    } catch (err) {
      this.setState({ isLoading1: false });
      console.log(err);
    }
  }

  render() {
    const { t } = this.props;
    const { stats, detail, car, power, basic } = this.state;

    const filtercar = car.filter(
      (c) => c.version.make === this.props.match.params.mode
    );

    const filtermodel = car.filter(
      (c) => c.version.model === this.props.match.params.mode1
    );
    const model = basic.filter((c) => c.label === "Model");
    const make = basic.filter((c) => c.label === "Make");
    const year = basic.filter((c) => c.label === "Production year");
    const type = basic.filter((c) => c.label === "Vehicle type");
    const capacity = power.filter((c) => c.label === "Engine capacity");
    const powerr = power.filter((c) => c.label === "Power");

    return (
      <div className="Main_section" style={{ marginTop: "50px" }}>
        <Helmet>
          <title>Profile Information</title>
        </Helmet>
        <div className="row row1" style={{ width: "100%" }}>
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
        </div>

        <div style={{ marginTop: "-15px" }}>
          <Recommendation />
        </div>

        <div className="headerr">
          <div>
            <strong>
              {stats.license_plate} &nbsp; {make.map((c) => c.value)} &nbsp;
              {model.map((c) => c.value)} &nbsp; {year.map((c) => c.value)}{" "}
              &nbsp;
              {type.map((c) => c.value)} &nbsp; &nbsp; &nbsp;{" "}
              {capacity.map((c) => c.value)}, &nbsp;{" "}
              {powerr.map((c) => c.value)}
            </strong>
          </div>
          <div style={{ marginTop: "30px" }}>
            <h3 className="font-weight-normal" style={{ marginTop: "30px" }}>
              {t("profile_information")}
            </h3>

            <hr></hr>

            <div className="row" style={{ width: "100%" }}>
              {detail.map((c) => (
                <div className="col-sm-3" style={{ marginBottom: "20px" }}>
                  <div className=" n">
                    <p id="pppp">{c.label.toUpperCase()}</p>
                    <p className="pppp1">{c.value ? `${c.value}` : "-"}</p>
                  </div>
                </div>
              ))}
            </div>

            <h3 className="font-weight-normal" style={{ marginTop: "30px" }}>
              {t("recommended_cars")}
            </h3>
            <hr></hr>
            {this.state.isLoading === true ? (
              <Spinner />
            ) : (
              <div className="caaar">
                <table className="table ">
                  <thead>
                    <tr>
                      <th>{t("Id")}</th>
                      <th>{t("Brand")}</th>
                      <th>{t("Model")}</th>
                      <th>{t("Engine_type")}</th>
                      <th>{t("batter_engine")}</th>
                      <th>{t("power")} [kw]</th>
                      <th>{t("price")} [PLN] </th>
                      <th>{t("potential_saving")} [PLN]</th>
                      {/* <th>{t("max_saving")}</th> */}
                    </tr>
                  </thead>

                  {this.props.match.params.mode1 === "demo1" ? (
                    <tbody>
                      {this.props.match.params.mode === "demo"
                        ? car.map((c, i) => (
                            <tr key={i + 1}>
                              <td>{i + 1}</td>
                              <td>
                                {c.version.make ? `${c.version.make}` : "-"}
                              </td>
                              <td>
                                {c.version.model ? `${c.version.model}` : "-"}
                              </td>
                              <td>
                                {c.version.type ? `${c.version.type}` : "-"}
                              </td>
                              <td>
                                {c.version.engine_capacity
                                  ? `${c.version.engine_capacity}`
                                  : "-"}
                              </td>
                              <td>
                                {c.version.engine_power
                                  ? `${c.version.engine_power}`
                                  : "-"}
                              </td>
                              <td>
                                {c.version.price_pln
                                  ? `${c.version.price_pln}`
                                  : "-"}
                              </td>
                              <td>
                                {c.yearly_savings ? `${c.yearly_savings}` : "-"}
                              </td>
                            </tr>
                          ))
                        : filtercar.map((c, i) => (
                            <tr key={i + 1}>
                              <td>{i + 1}</td>
                              <td>
                                {c.version.make ? `${c.version.make}` : "-"}
                              </td>
                              <td>
                                {c.version.model ? `${c.version.model}` : "-"}
                              </td>
                              <td>
                                {c.version.type ? `${c.version.type}` : "-"}
                              </td>
                              <td>
                                {c.version.engine_capacity
                                  ? `${c.version.engine_capacity}`
                                  : "-"}
                              </td>
                              <td>
                                {c.version.engine_power
                                  ? `${c.version.engine_power}`
                                  : "-"}
                              </td>
                              <td>
                                {c.version.price_pln
                                  ? `${c.version.price_pln}`
                                  : "-"}
                              </td>
                              <td>
                                {c.yearly_savings ? `${c.yearly_savings}` : "-"}
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  ) : (
                    <tbody>
                      {filtermodel.map((c, i) => (
                        <tr key={i + 1}>
                          <td>{i + 1}</td>
                          <td>{c.version.make ? `${c.version.make}` : "-"}</td>
                          <td>
                            {c.version.model ? `${c.version.model}` : "-"}
                          </td>
                          <td>{c.version.type ? `${c.version.type}` : "-"}</td>
                          <td>
                            {c.version.engine_capacity
                              ? `${c.version.engine_capacity}`
                              : "-"}
                          </td>
                          <td>
                            {c.version.engine_power
                              ? `${c.version.engine_power}`
                              : "-"}
                          </td>
                          <td>
                            {c.version.price_pln
                              ? `${c.version.price_pln}`
                              : "-"}
                          </td>
                          <td>
                            {c.yearly_savings ? `${c.yearly_savings}` : "-"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Profilee);
