import React, { Component } from "react";
import deletee from "../../../../img/delete.png";
import { withTranslation } from "react-i18next";

class Informations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      swt: false,
    };
  }

  async onClick(e) {
    await this.setState({
      swt: !this.state.swt,
    });
  }

  handleAddFields = () => {
    const values = this.props.vall;
    values.push({
      information: "",
      date: "",
      mileage: "",
      notification_date: "",
      notification_mileage: "",
      notification_by_mileage: false,
      notification_by_date: false,
    });
    this.setState({
      values,
    });
  };

  handleRemoveFields = (index) => {
    const values = this.props.vall;
    values.splice(index, 1);
    this.setState({
      values,
    });
  };

  async onChange(e, index) {
    if (
      [
        "information",
        "date",
        "mileage",
        "notification_date",
        "notification_mileage",
        "notification_by_mileage",
      ].includes(e.target.name)
    ) {
      let cats = [...this.props.vall];
      cats[index][e.target.name] = e.target.value;
      await this.setState({
        cats,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
    console.log(this.props.vall);
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        {this.props.vall.map((inputField, index) => (
          <div className="row" key={`${inputField}~${index}`}>
            <div className="col-sm-2">
              <div className="form-group">
                <label id="p" htmlFor="exampleInputEmail1">
                  {t("information").toUpperCase()}
                </label>
                <input
                  type="text"
                  placeholder={t("information")}
                  name="information"
                  value={inputField.information}
                  onChange={(e) => {
                    this.onChange(e, index);
                  }}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-sm-3">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label id="p" htmlFor="exampleInputEmail1">
                      {t("date").toUpperCase()}
                    </label>
                    <input
                      type="date"
                      name="date"
                      value={inputField.date}
                      onChange={(e) => {
                        this.onChange(e, index);
                      }}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label id="p" htmlFor="exampleInputEmail1">
                      {t("Milage").toUpperCase()}
                    </label>
                    <input
                      type="number"
                      placeholder={t("Milage")}
                      name="mileage"
                      value={inputField.mileage}
                      onChange={(e) => {
                        this.onChange(e, index);
                      }}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="form-group">
                <label id="p" htmlFor="exampleInputEmail1">
                  {t("Notification").toUpperCase()}
                </label>
                <div className="row">
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <div className="col-sm-4">
                    <label class="switch1">
                      <input
                        type="checkbox"
                        id="togBtn"
                        checked={this.state.swt}
                        onClick={this.onClick.bind(this)}
                        name="notify_critical_overspeeding"
                      />
                      <div class="slider1 round"></div>
                    </label>
                  </div>
                  {this.state.swt && (
                    <div className="col-md-7" style={{ top: "-35px" }}>
                      <div>
                        <div>
                          <input
                            type="radio"
                            placeholder="A Short Description"
                            name="notification_by_mileage"
                            id="r12"
                            value={inputField.notification_by_date}
                            onChange={(e) => {
                              this.onChange(e, index);
                            }}
                            class="form-check-input"
                          />
                          <label id="p" htmlFor="r12">
                            {t("date_info")}
                          </label>
                        </div>

                        <div style={{ marginRight: "-10px" }}>
                          <input
                            type="radio"
                            placeholder="A Short Description"
                            name="notification_by_mileage"
                            id="r13"
                            class="form-check-input"
                            value={inputField.notification_by_date}
                            onChange={(e) => {
                              this.onChange(e, index);
                            }}
                          />
                          <label id="p" htmlFor="r13">
                            {t("date_mileage")}
                          </label>
                        </div>

                        <div>
                          <input
                            type="radio"
                            placeholder="A Short Description"
                            name="notification_by_mileage"
                            value={inputField.notification_by_mileage}
                            class="form-check-input"
                            id="r14"
                            onChange={(e) => {
                              this.onChange(e, index);
                            }}
                          />
                          <label id="p" htmlFor="r14">
                            {t("both")}
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* </div> */}
              </div>
            </div>

            <div className="col-sm-4">
              <div className="row">
                <div className="col-sm-5">
                  <div className="form-group">
                    <label id="p" htmlFor="exampleInputEmail1">
                      {t("date").toUpperCase()}
                    </label>
                    <input
                      type="date"
                      name="notification_date"
                      value={inputField.notification_date}
                      onChange={(e) => {
                        this.onChange(e, index);
                      }}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-sm-5">
                  <div className="form-group">
                    <label id="p" htmlFor="exampleInputEmail1">
                      {t("milage").toUpperCase()}
                    </label>
                    <input
                      type="number"
                      placeholder={t("milage")}
                      name="notification_mileage"
                      value={inputField.notification_mileage}
                      onChange={(e) => {
                        this.onChange(e, index);
                      }}
                      className="form-control"
                    />
                  </div>
                </div>
                <button
                  className="btn btn-danger"
                  style={{
                    marginTop: "30px",
                    background: "none",
                    border: "none",
                  }}
                  type="button"
                  onClick={() => this.handleRemoveFields()}
                >
                  <img src={deletee} alt="Delete" />
                </button>
              </div>
            </div>
          </div>
        ))}
        <a
          href="# "
          onClick={() => this.handleAddFields()}
          className="p font-weight-bolder"
          style={{ color: "#b71c1c" }}
        >
          <i className="fas fa-plus" />
        </a>
      </div>
    );
  }
}

export default withTranslation()(Informations);
