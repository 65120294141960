import React, { Component } from "react";
// import Chart from "react-apexcharts";
//import arrowL from "../../../../Service/Arrow/Arrow";
import { withTranslation } from "react-i18next";
import { Line } from "react-chartjs-2";
// var customTooltips = function (tooltip) {
//   // Tooltip Element
//   var tooltipEl = document.getElementById("chartjs-tooltip");

//   if (!tooltipEl) {
//     tooltipEl = document.createElement("div");
//     tooltipEl.id = "chartjs-tooltip";
//     tooltipEl.innerHTML = "<table></table>";
//     this._chart.canvas.parentNode.appendChild(tooltipEl);
//   }

//   // Hide if no tooltip
//   if (tooltip.opacity === 0) {
//     tooltipEl.style.opacity = 0;
//     return;
//   }

//   // Set caret Position
//   tooltipEl.classList.remove("above", "below", "no-transform");
//   if (tooltip.yAlign) {
//     tooltipEl.classList.add(tooltip.yAlign);
//   } else {
//     tooltipEl.classList.add("no-transform");
//   }

//   function getBody(bodyItem) {
//     return bodyItem.lines;
//   }

//   // Set Text
//   if (tooltip.body) {
//     var titleLines = tooltip.title || [];
//     var bodyLines = tooltip.body.map(getBody);

//     var innerHtml = "<thead>";

//     titleLines.forEach(function (title) {
//       innerHtml += "<tr><th>" + title + "</th></tr>";
//     });
//     innerHtml += "</thead><tbody>";

//     bodyLines.forEach(function (body, i) {
//       var colors = tooltip.labelColors[i];
//       var style = "background:" + colors.backgroundColor;
//       style += "; border-color:" + colors.borderColor;
//       style += "; border-width: 2px";
//       var span =
//         '<span class="chartjs-tooltip-key" style="' + style + '"></span>';
//       innerHtml += "<tr><td>" + span + body + "</td></tr>";
//     });
//     innerHtml += "</tbody>";

//     var tableRoot = tooltipEl.querySelector("table");
//     tableRoot.innerHTML = innerHtml;
//   }

//   var positionY = this._chart.canvas.offsetTop;
//   var positionX = this._chart.canvas.offsetLeft;

//   // Display, position, and set styles for font
//   tooltipEl.style.opacity = 1;
//   tooltipEl.style.left = positionX + tooltip.caretX + "px";
//   tooltipEl.style.top = positionY + tooltip.caretY + "px";
//   tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
//   tooltipEl.style.fontSize = tooltip.bodyFontSize + "px";
//   tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
//   tooltipEl.style.padding = tooltip.yPadding + "px " + tooltip.xPadding + "px";
// };

export class Summary extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const unit1 = this.props.chat.yRange.unit;
    const label1 = this.props.chat.label;

    const options = {
      pan: {
        enabled: true,
        mode: "x",
      },
      zoom: {
        enabled: true,
        mode: "xy",
      },
      legend: {
        display: false,
      },

      tooltips: {
        callbacks: {
          label: function (tooltipItem) {
            return (
              " " +
              label1 +
              " : " +
              new Intl.NumberFormat().format(
                Number.parseFloat(tooltipItem.value).toFixed(1)
              ) +
              "" +
              unit1
            );
          },
        },
        padding: 20,
        titleFontSize: 15,
        titleFontColor: "white",
        bodyFontColor: "white",
        bodyFontSize: 13,
        yPadding: 10,
        xPadding: 10,
        mode: "index",
        borderColor: "#00ff44",
        borderWidth: 2,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              min: this.props.chat.yRange.min,
              max: this.props.chat.yRange.max,
              padding: 5,
              maxTicksLimit: 6,

              callback: function (value, index, props) {
                return Number.parseFloat(value).toFixed(0) + " " + unit1;
              },
            },
            scaleLabel: {
              display: true,
              labelString: this.props.chat.yAxis,
            },
            beginAtZero: true,
            gridLines: {
              drawTicks: false,
            },
          },
        ],

        xAxes: [
          {
            tricks: {
              maxRotation: 90,
              maxTicksLimit: 10,
              callback: function (value, index, props) {
                return Number.parseFloat(value).toFixed(0) + "  ";
              },
              suggestedMax: 11.12 * 2,
              stepSize: 1, //interval between ticks
            },
            scaleLabel: {
              display: true,
              labelString: this.props.chat.xAxis,
            },
            offset: true,
            gridLines: {
              drawOnChartArea: false,
            },
          },
        ],
      },
    };

    return (
      <div>
        <Line
          //width={1}
          height={170}
          options={options}
          data={{
            labels: this.props.chat.xLabels,

            datasets: [
              {
                label: this.props.chat.label,
                data: this.props.chat.value, //dailyData.map(({ confirmed }) => confirmed),
                backgroundColor: "#00ff44",
                borderColor: "#00ff44",
                fill: false,
                pointRadius: 4,
                pointHoverRadius: 5,
                // backgroundColor: this.props.chat.series.map((c) => c.color),
                // pointBorderColor: this.props.chat.series.map((c) => c.color),
              },
            ],
          }}
        />
      </div>
    );
  }
}

export default withTranslation()(Summary);
