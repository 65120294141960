import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class Additional extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    console.log();
    return (
      <div>
        <form>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label id="p" for="Gender">
                  {t("gender").toUpperCase()}
                </label>
                <div className="row">
                  &nbsp; &nbsp;&nbsp;
                  <div className="col-sm-6">
                    <input
                      type="radio"
                      placeholder="A Short Description"
                      name="gender"
                      checked={this.props.vall.gender === "male"}
                      value="male"
                      onChange={this.props.loaddata}
                      class="form-check-input"
                      id="r5"
                    />
                    <label id="p" htmlFor="r5">
                      {t("male")}
                    </label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="radio"
                      placeholder="A Short Description"
                      name="gender"
                      id="r6"
                      value="female"
                      checked={this.props.vall.gender === "female"}
                      onChange={this.props.loaddata}
                      class="form-check-input"
                    />
                    <label id="p" htmlFor="r6">
                      {t("female")}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="form-group">
                <label id="p" for="exampleInputEmail1">
                  {t("year_of_employment").toUpperCase()}
                </label>
                <input
                  type="date"
                  name="employment_date"
                  value={this.props.vall.employment_date}
                  onChange={this.props.loaddata}
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label id="p" for="exampleInputEmail1">
                  {t("date_of_birth").toUpperCase()}
                </label>
                <input
                  type="number"
                  name="birth_year"
                  // onKeyPress={(event) => this.onlyNumber.bind(event)}
                  maxlength={4}
                  placeholder={t("type")}
                  value={this.props.vall.birth_year}
                  onChange={this.props.loaddata}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-sm-5">
              <div className="form-group">
                <label id="p" for="exampleInputEmail1">
                  {t("number_claims").toUpperCase()}
                </label>
                <input
                  type="number"
                  placeholder={t("type")}
                  style={{
                    border: "1px solid white",
                    borderBottom: "1px solid black",
                  }}
                  name="claim_count"
                  value={this.props.vall.claim_count}
                  onChange={this.props.loaddata}
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 ">
              <div className="form-group">
                <label id="p" for="exampleInputEmail1">
                  {t("year_of_obtaining").toUpperCase()}
                </label>
                <input
                  type="date"
                  placeholder={t("type")}
                  name="driving_license_issued"
                  value={this.props.vall.driving_license_issued}
                  onChange={this.props.loaddata}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-sm-5">
              <div className="form-group">
                <label id="p" for="exampleInputEmail1">
                  {t("location").toUpperCase()}
                </label>
                <input
                  type="text"
                  name="location"
                  placeholder={t("type")}
                  value={this.props.vall.location}
                  class="form-control donn"
                  onChange={this.props.loaddata}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation()(Additional);
