import React, { Component } from "react";
import cross from "../../../img/Cross.png";
// import ReactFileReader from "react-file-reader";
import { withTranslation } from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
const cookie = new Cookies();
let authToken = cookie.get("Token");

class MonthlyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csv: "",
      mapping: {
        datetime: "",
        card: "",
        car: "",
        car_plate_number: "",
        odometer: "",
        liters: "",
        month: "",
        year: "",
      },
      start_row: null,
      year: null,
      month: null,
      importedCSV: "Please import a .csv file",
    };
    this.onChange = this.onChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
  }

  async handleChange1(event) {
    const { name, value } = event.target;
    await this.setState({
      mapping: {
        ...this.state.mapping,
        [name]: value.toUpperCase(),
      },
    });
    localStorage.setItem("datetime", this.state.mapping.datetime);
    localStorage.setItem("fuel_plate", this.state.mapping.car_plate_number);
    localStorage.setItem("odometer", this.state.mapping.odometer);
    localStorage.setItem("liters", this.state.mapping.liters);
  }

  async onChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });

    //  console.log(this.state);
  }

  read(e) {
    console.log(e.target.value);
    /////////////////////////////// CSV
    switch (
      e.target.value &&
      // eslint-disable-next-line
      e.target.value.match(/\.([^\.]+)$/i)[1].toLowerCase()
    ) {
      case "csv" /* since they might have a CSV saved as TXT */:
        let filename = e.target.value;
        filename = filename.substring(filename.lastIndexOf("\\") + 1);
        this.setState({ importedCSV: filename });

        const reader = new FileReader();
        const csv1 = e.target.files[0];

        reader.readAsText(csv1);
        reader.onload = function (e) {
          localStorage.setItem("fuelfile", e.target.result);
        };

        break;
      default:
        // alert('Please choose a .csv file');
        Swal.fire({
          title: "Cost Import File",
          type: "error",
          text: "Please choose a .csv file",
          timer: 2000,
        });
        e.target.value = "";
        return;
    }
  }

  // read(e) {
  //   const reader = new FileReader();
  //   const csv1 = e.target.files[0];

  //   reader.readAsText(csv1);
  //   reader.onload = function (e) {
  //     //var csv = e.target.result;
  //     window.localStorage.setItem("fuelfile", e.target.result);
  //   };
  // }

  async componentDidMount() {
    const csv = localStorage.getItem("fuelfile");
    const datetime = localStorage.getItem("datetime");
    const car_plate_number = localStorage.getItem("fuel_plate");
    const odometer = localStorage.getItem("odometer");
    const liters = localStorage.getItem("liters");
    const year = localStorage.getItem("Month");
    const month = localStorage.getItem("Year");
    this.setState({
      csv,
      mapping: {
        ...this.state.mapping,
        datetime,
        car_plate_number,
        odometer,
        liters,
        year,
        month,
      },
    });
  }

  closeModal = () => {
    //console.log("MODAL CLOSED!!!");
    this.setState({
      csv: "",
      mapping: {
        datetime: "",
        card: "",
        car: "",
        car_plate_number: "",
        odometer: "",
        liters: "",
        month: "",
        year: "",
      },
      start_row: null,
      year: null,
      month: null,
      importedCSV: "Please import a .csv file",
    });
    document.getElementById("importDataRow").value = "";
  };

  componentDidUpdate() {
    const csv = localStorage.getItem("fuelfile");
    setTimeout(() => {
      this.setState({
        csv: csv,
      });
    }, 100);
  }

  handleSubmit(e) {
    e.preventDefault();

    const data = {
      csv: this.state.csv,
      mapping: { ...this.state.mapping },
      start_row: this.state.start_row,
      year: this.state.year,
      month: this.state.month,
    };

    axios({
      method: "post",
      url: `https://digitalfleet.eu/api/1/fuel/upload/`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
      data,
    })
      .then((res) => {
        this.setState({
          csv: "",
          mapping: {
            datetime: "",
            card: "",
            car: "",
            car_plate_number: "",
            odometer: "",
            liters: "",
          },
          start_row: null,
          year: null,
          month: null,
        });

        Swal.fire({
          title: "Fuel Import",
          type: "success",
          text: "Fuel imported  Successfully in database !",
          showConfirmButton: false,
          timer: 2000,
        });
        // window.location.href = "/cost";
        window.location.reload(1);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Fuel Import",
          type: "error",
          text:
            "Error While importing  fuel please check & type correct input value !!",
          timer: 3000,
        });
      });
  }

  render() {
    var don = [];

    var start_year = new Date().getFullYear();

    for (let i = start_year; i > start_year - 3; i--) {
      don.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }

    var date = new Date();
    var dates = [];
    var i, len;
    // var don1 = [];

    for (i = 0, len = 12; i < len; i++) {
      date.setMonth(date.getMonth() - 1);

      dates.unshift(
        date.toLocaleString("en-us", {
          month: "long",
        })
      );
    }

    const alphabate = [];
    for (i = 65; i <= 90; i++) {
      const don = String.fromCharCode(i);

      alphabate.push(
        <option value={don} key={don}>
          {don}
        </option>
      );
    }

    const { t } = this.props;

    const month1 =
      localStorage.getItem("Month") === "1"
        ? t("january")
        : `${
            localStorage.getItem("Month") === "2"
              ? t("feburuary")
              : `${
                  localStorage.getItem("Month") === "3"
                    ? t("march")
                    : `${
                        localStorage.getItem("Month") === "4"
                          ? t("april")
                          : `${
                              localStorage.getItem("Month") === "5"
                                ? t("may")
                                : `${
                                    localStorage.getItem("Month") === "6"
                                      ? t("june")
                                      : `${
                                          localStorage.getItem("Month") === "7"
                                            ? t("july")
                                            : t("august")
                                        }`
                                  }`
                            }`
                      }`
                }`
          }`;

    return (
      <div id="montly" class="modal">
        <a
          href="# "
          rel="modal:close"
          className="float-right text-dark "
          style={{ marginBottom: "90px" }}
        >
          <img src={cross} alt="Cross" style={{ height: "1.1em" }} />
        </a>
        <p className="mod" style={{ marginTop: "10px" }}>
          {t("Add_data_from_fuel").toUpperCase()}
        </p>

        <form
          style={{ marginTop: "40px" }}
          autoComplete="off"
          onSubmit={this.handleSubmit.bind(this)}
        >
          <p id="ppp" style={{ marginTop: "10px" }}>
            {t("select_month_year").toUpperCase()}
          </p>

          <div className="row">
            <div className="col-md-6">
              <select
                name="month"
                className="form-control select donn"
                value={this.state.month}
                onChange={this.onChange}
                required
              >
                {localStorage.getItem("Month") ? (
                  <option value={localStorage.getItem("Month")} selected>
                    {month1}
                  </option>
                ) : (
                  <option value="Month" disabled hidden selected>
                    {t("month")}
                  </option>
                )}

                <option value="1">{t("january")}</option>
                <option value="2">{t("feburuary")}</option>
                <option value="3">{t("march")}</option>
                <option value="4">{t("april")}</option>
                <option value="5">{t("may")}</option>
                <option value="6">{t("june")}</option>
                <option value="7">{t("july")}</option>
                <option value="8">{t("august")}</option>
                <option value="9">{t("september")}</option>
                <option value="10">{t("october")}</option>
                <option value="11">{t("november")}</option>
                <option value="12">{t("december")}</option>
                {/* {options} */}
              </select>
            </div>

            <div className="col-md-6">
              <select
                name="year"
                className="form-control select donn"
                value={this.state.year}
                onChange={this.onChange}
                required
              >
                {localStorage.getItem("Year") ? (
                  <option value={localStorage.getItem("Year")} selected>
                    {localStorage.getItem("Year")}
                  </option>
                ) : (
                  <option value="" disabled hidden selected>
                    {t("year")}
                  </option>
                )}

                {don}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <p id="ppp" style={{ marginTop: "20px" }}>
                {t("import_fuel_file").toUpperCase()}
              </p>
              <div class="upload-btn-wrapper">
                <button class="btn112" style={{ fontSize: "16px" }}>
                  {t("select_file").toUpperCase()}
                </button>
                <p>
                  {this.state.importedCSV !== "Please import a .csv file" ? (
                    <span>
                      Uploaded File: <strong> {this.state.importedCSV} </strong>
                    </span>
                  ) : (
                    this.state.importedCSV
                  )}
                </p>
                <input
                  type="file"
                  accept=".csv, text/csv"
                  name="csv"
                  onChange={this.read.bind(this)}
                />
              </div>
            </div>
            <div className="col-md-6" style={{ marginTop: "19px" }}>
              <p id="ppp" style={{ marginTop: "20px" }}>
                {t("import_data_from_row").toUpperCase()}
              </p>
              <input
                type="number"
                min={1}
                placeholder={t("type")}
                value={this.state.start_row}
                onChange={this.onChange}
                name="start_row"
                required
              />
            </div>
          </div>

          <p className=" " id="ppp">
            {t("select_columns").toUpperCase()}
          </p>
          <div className="row" style={{ marginBottom: "30px" }}>
            <div className="col-md-6">
              <label
                for="exampleInputEmail1"
                className="font-weight-bold h6"
                style={{ fontSize: "13px" }}
              >
                {t("plate_number").toUpperCase()}
              </label>
              <input
                type="text"
                placeholder={t("type")}
                name="car_plate_number"
                value={this.state.mapping.car_plate_number}
                onChange={this.handleChange1}
                className="form-control"
                maxLength={2}
              />
            </div>

            <div className="col-md-6">
              <label
                for="exampleInputEmail1"
                className="font-weight-bold h6"
                style={{ fontSize: "13px" }}
              >
                {t("fuel_mileage").toUpperCase()}
              </label>
              <input
                type="text"
                placeholder={t("type")}
                name="odometer"
                maxLength={2}
                onChange={this.handleChange1}
                value={this.state.mapping.odometer}
                className="form-control"
              />
            </div>

            <div className="col-md-6" style={{ marginTop: "10px" }}>
              <label
                for="exampleInputEmail1"
                className="font-weight-bold h6"
                style={{ fontSize: "13px" }}
              >
                {t("refil_date").toUpperCase()}
              </label>
              <input
                type="text"
                placeholder={t("type")}
                name="datetime"
                value={this.state.mapping.datetime}
                onChange={this.handleChange1}
                maxLength={2}
                className="form-control"
              />
            </div>

            <div className="col-md-6" style={{ marginTop: "10px" }}>
              <label
                for="exampleInputEmail1"
                className="font-weight-bold h6"
                style={{ fontSize: "13px" }}
              >
                {t("fuel_add").toUpperCase()}
              </label>

              <input
                type="text"
                placeholder={t("type")}
                name="liters"
                value={this.state.mapping.liters}
                onChange={this.handleChange1}
                maxLength={2}
                className="form-control"
              />
            </div>
          </div>

          <center>
            <button
              type="submit"
              value="Get Data"
              className="btn btn-login text-white font-weight-bolder boxx "
              id="button1"
              style={{
                height: "40px",
                fontSize: "13px",
                width: "200px",
                background: "rgba(183, 28, 28, 1)",
                border: "none",

                color: "white",
                margin: "auto",
              }}
            >
              {t("save_fuel").toUpperCase()}
            </button>
          </center>
        </form>
      </div>
    );
  }
}

export default withTranslation()(MonthlyReport);
