import React, { Component } from "react";
// import Form from "../Form";
// import config from "../../Main";
import { withTranslation } from "react-i18next";
// import arrow from "../../../img/arrow.png";
import { getDriver } from "../../../Service/Driver";

class ReportData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true,
      isOption: true,
      checkedBoxCheck: false,
      lol: "",
      fol: "",
      todos: [],
      driver: [],
      selectAll: false,
      checkAll: false,
      data: [],
      walk: [],
      selectedItems: [],
      //checked: []
      // isChecked: false
    };
    this.toggleHie = this.toggleHie.bind(this);
    this.toggleHidden = this.toggleHidden.bind(this);
  }

  toggleHidden() {
    this.setState({
      isHidden: true,
    });
  }

  toggleHie() {
    this.setState({ isHidden: false });
  }
  toggleOption() {
    this.setState({ isOption: !this.state.isOption });
  }

  // Get Data from filter date
  getData = async (e) => {
    const from = e.target.elements.from.value;
    const to = e.target.elements.to.value;
    e.preventDefault();

    getDriver(to, from).then((data) => {
      this.setState({ todos: data, lol: from, fol: to });
    });
  };

  componentDidMount() {
    getDriver().then((data) => {
      this.setState({ walk: data });
    });
  }

  async onItemSelect(row) {
    const selectedItems = this.state.selectedItems.slice(0);
    if (selectedItems.includes(row)) {
      selectedItems.splice(selectedItems.indexOf(row), 1);
    } else {
      selectedItems.push(row);
    }
    await this.setState({
      selectedItems,
    });
  }

  toggleSelectAll() {
    let selectedItems = [];
    var checkedBoxCheck = !this.state.checkedBoxCheck;
    if (checkedBoxCheck) {
      this.state.todos.forEach((x) => {
        selectedItems.push(x.id);
      });
    } else {
      selectedItems = [];
    }
    this.setState((prevState) => ({
      selectedItems,
      checkedBoxCheck,
    }));

    console.log(selectedItems);
  }

  async onChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
    console.log(this.state.driver);
  }

  onClickDriver = (e) => {
    const base = process.env.PUBLIC_URL;
    e.preventDefault();
    window.location.href = base + "/select/driver/";
  };

  render() {
    const { walk } = this.state;
    const { t } = this.props;
    return (
      <div>
        <p id="ppp"> {t("selection_of_participant").toUpperCase()}</p>
        <div className="row">
          <div className="col-sm-3">
            <input
              class="form-check-input"
              type="radio"
              name="export"
              id="exampleRadios11"
              value="option1"
              onClick={this.toggleHidden}
              defaultChecked
            />
            <label class="form-check-label" for="exampleRadios11">
              {t("list").toUpperCase()}
            </label>
          </div>

          <div className="col-sm-3">
            <input
              class="form-check-input"
              type="radio"
              name="export"
              id="exampleRadios1"
              value="option1"
              onClick={this.onClickDriver}
            />
            <label class="form-check-label" for="exampleRadios1">
              {t("export_from_report").toUpperCase()}
            </label>
          </div>
          <div className="col-sm-3">
            <div>
              <input
                class="form-check-input"
                type="radio"
                name="export"
                id="exampleRadios2"
                value="option2"
              />
              <label class="form-check-label" for="exampleRadios2">
                {t("autosuggestion").toUpperCase()}
              </label>
            </div>
          </div>
        </div>

        {this.state.isHidden && (
          <div className="col-md-6 col-lg-4">
            <div className="form-group" style={{ marginTop: "20px" }}>
              <select
                name="driverList"
                multiple={true}
                style={{ width: "330px" }}
                onChange={this.props.handle}
                value={this.props.vall.driverList}
                class="form-control donn"
              >
                <option value="" disabled hidden selected>
                  {" "}
                  {t("choose_driver1")}
                </option>
                {/* <option value=" ">{t("select_driver")}</option> */}
                {walk.map((c) => (
                  <option value={c.id} key={c.id}>
                    {" "}
                    {c.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(ReportData);
