import React, { Component } from "react";
import forget from "../../img/forget1.jpg";
import Swal from "sweetalert2";
import axios from "axios";
import { withTranslation } from "react-i18next";
import "../Views/index.css";
import "./Froget.css";

export class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    Swal.fire({
      title: "Loading",
      type: "warning",
      showCancelButton: false,
      showConfirmButton: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    const { t } = this.props;

    const data = {
      email: this.state.email,
    };

    // https://digitalfleet.eu/api/1/users/password-reset/

    const mailformat = RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
    const isEnabled = this.state.email.match(mailformat);
    if (isEnabled) {
      axios({
        method: "post",
        url: `https://digitalfleet.eu/api/1/users/sendForgottenPasswordEmail/`,
        headers: {
          Accept: "application/json",
          "Accept-Language": window.localStorage.i18nextLng,
          "Content-Type": "application/json",
        },
        data: data,
      })
        .then((response) => {
          this.setState({
            email: "",
          });
          Swal.fire({
            title: `${t("email_reset_sent")}  ${data.email}`,
            type: "success",
            text: t("email_reset_sent_text"),
            showConfirmButton: false,
            timer: 3000,
          });
          this.props.history.push("/");
        })
        .catch((reject) => {
          console.log(reject);
          Swal.fire({
            title: t("rest_password"),
            type: "error",
            text: reject.response.data.message, //message
            timer: 2000,
          });
        });
    } else {
      Swal.fire({
        title: t("login_error"),
        type: "warning",
        text:
          "It's seems like you don't have correct email id. Please check it  !!",
        timer: 2000,
      });
    }
  }
  render() {
    //const { email } = this.state;
    // const mailformat = RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
    // const isEnabled = email.match(mailformat);
    const base = process.env.PUBLIC_URL;
    const { t } = this.props;
    return (
      <div className="container hip1">
        {/* <!-- Outer Row --> */}
        <div class="row justify-content-center">
          <div class="col-xl-10 col-lg-12 col-md-9">
            <div class="card o-hidden border-0 shadow-lg my-5">
              <div class="card-body p-0">
                {/* <!-- Nested Row within Card Body --> */}
                <div class="row">
                  <div class="col-lg-6 d-none d-lg-block bg-password-image">
                    <img
                      src={forget}
                      alt="Forget Password"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div class="col-lg-6 don_sm">
                    <div class="p-5">
                      <div class="text-center">
                        <h1
                          class="h4 hee forget_name text-gray-900 mb-2"
                          style={{ marginTop: "-40px" }}
                        >
                          {t("Forgetpassword")}?
                        </h1>
                        <p class="mb-4 " style={{ marginTop: "60px" }}>
                          {t("forget_password_text")}
                        </p>
                      </div>
                      <form
                        class="user "
                        onSubmit={this.handleSubmit}
                        autoComplete="off"
                      >
                        <div class="form-group">
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder={t("enter_email")}
                            value={this.state.email}
                            onChange={this.handleChange}
                          />
                        </div>
                        <button
                          type="submit"
                          value="Login"
                          className="btn btn-login btn-block text-white font-weight-bolder boxx "
                          style={{
                            background: "rgba(183, 28, 28, 1)",
                            padding: "10px",
                            fontSize: "13px",
                            boxShadow: "0px 8px 25px -7px #c0c0c0",
                            borderRadius: "4px",
                            width: "100%",
                          }}
                        >
                          {t("send_email").toUpperCase()}
                        </button>
                      </form>
                      <hr />
                      <div class="text-center">
                        <a
                          class="small"
                          href={base + "/"}
                          style={{ color: "#b71c1c", opacity: "0.8" }}
                        >
                          {t("login_page")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ForgetPassword);
