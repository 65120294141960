import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "../../views/Views/OldDashboard/Graph/index";
import polish from "../../img/pl_flag.png";
import english from "../../img/eng_flag.png";
// import french from "../../img/flags/french.png";
//import port from "../../img/flags/pl_flag-03.png";
import i18n from "../../i18n";
import { Link } from "react-router-dom";

class Language extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currLangFlag: "en",
    };
  }

  render() {
    const getCurrentLng = () =>
      i18n.language || window.localStorage.i18nextLng || "";

    const changeLan = (lng) => {
      this.setState({ currLangFlag: lng });
      i18n.changeLanguage(lng);
      window.location.reload(false);
    };
    const baseUrl = process.env.PUBLIC_URL;
    return (
      <li class=" navbar-nav nav-item dropdown" style={{ marginTop: "-7px" }}>
        <div
          class="nav-link"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {getCurrentLng() === "en" ? (
            <img
              src={english}
              className="setting"
              alt={this.state.currLangFlag}
              style={{ width: "25px", height: "18px" }}
            />
          ) : (
            <img
              src={polish}
              className="setting"
              alt={this.state.currLangFlag}
              style={{ width: "25px", height: "18px" }}
            />
          )}
          <br></br>
          <span
            className="text-dark font-weight-bold"
            style={{ opacity: "0.8" }}
          >
            {getCurrentLng().toUpperCase()}
          </span>
        </div>
        <div
          class={
            window.location.pathname === baseUrl + "/"
              ? "dropdown-menu not2"
              : "dropdown-menu not1"
          }
          aria-labelledby="navbarDropdown"
        >
          <Link
            onClick={() => changeLan("en")}
            to={this.props.location}
            class="dropdown-item"
          >
            <img
              className="flag"
              src={english}
              alt=""
              style={{ width: "20px", height: "20px" }}
            />
            &nbsp; &nbsp; English
          </Link>
          <Link
            onClick={() => changeLan("pl")}
            to={this.props.location}
            class="dropdown-item"
          >
            <img
              className="flag"
              src={polish}
              alt=""
              style={{ width: "20px", height: "20px" }}
            />
            &nbsp; &nbsp; Polish
          </Link>
          {/* <Link
            onClick={() => changeLan("fr")}
            to={this.props.location}
            class="dropdown-item"
          >
            <img
              className="flag"
              src={french}
              alt=""
              style={{ width: "20px", height: "20px" }}
            />
            &nbsp; &nbsp; France
          </Link> */}
          {/* <Link
            onClick={() => changeLan("pt")}
            to={this.props.location}
            class="dropdown-item"
          >
            <img
              className="flag"
              src={port}
              alt=""
              style={{ width: "20px", height: "20px" }}
            />
            &nbsp; &nbsp; Portuguese
          </Link> */}
        </div>
      </li>
    );
  }
}

export default withTranslation()(Language);
