import React, { useState, useEffect } from "react";
import Campaign from "../../../Components/Layout/Campaign";
import { Helmet } from "react-helmet";
import User from "../../../Components/User";
import Datee from "../../Views/Date";
import { useTranslation } from "react-i18next";
import { getCampaign } from "../../../Service/Campaign";
import Spinner from "../../../Container/Spinner";

function Planned(props) {
  const [name, setName] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getActiveCampaign();
  }, [name]);

  const getActiveCampaign = () => {
    getCampaign().then((data) => {
      setName(data);
      setLoading(false);
    });
  };
  const { t } = useTranslation();
  const { history } = props;
  const planned = name.filter(
    (c) => c.status === "planned" || c.status === "planowana"
  );
  const base = process.env.PUBLIC_URL;
  return (
    <div className="Main_section">
      <Helmet>
        <title>Planned Campaign </title>
      </Helmet>
      <div
        className="row row1"
        style={{ width: "100%", marginBottom: "-30px" }}
      >
        <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
          <strong>
            {t("Hello")}, <User />
          </strong>
          <Datee />
        </div>
      </div>
      <Campaign />

      <div className="headerr">
        <div>
          {planned.length > 0 ? (
            ""
          ) : (
            <strong>&nbsp;&nbsp; {t("no_data")}</strong>
          )}
        </div>
        {loading === true ? (
          <Spinner />
        ) : (
          <table className="table table-hover">
            <thead>
              <tr>
                <th style={{ width: "20px" }}> {t("Id")}</th>
                <th>{t("name")} </th>
                <th>{t("start_date")} </th>
                <th>{t("end_date")} </th>
                <th>{t("participant_amount")} </th>
                <th>{t("prize_amount")} </th>
                <th>{t("status")}</th>
              </tr>
            </thead>

            <tbody>
              {planned.map((c, i) => (
                <tr
                  key={c.id}
                  onClick={() =>
                    history.push(
                      base + "/overview/campaign/" + c.id + "/" + c.title + "/"
                    )
                  }
                >
                  <td>{i + 1}</td>
                  <td>
                    <span style={{ color: "#b71c1c" }} className="activee">
                      {" "}
                      {c.title ? `${c.title}` : "-"}{" "}
                    </span>
                  </td>
                  <td>{c.startDate ? `${c.startDate}` : "-"}</td>
                  <td>{c.endDate ? `${c.endDate}` : "-"}</td>
                  <td>{c.participantCount ? `${c.participantCount}` : "-"}</td>
                  <td>{c.prized_places ? `${c.prized_places}` : "-"}</td>
                  <td>
                    <button
                      className={
                        c.status === "active"
                          ? "btn btn-success"
                          : `${
                              c.status === "finished"
                                ? "btn btn-secondary"
                                : "btn btn-info"
                            }`
                      }
                      id="dd"
                    >
                      {c.status ? `${c.status}` : "-"}
                      {c.status === "planned" || c.status === "planowana" ? (
                        <span
                          className={
                            c.status === "planned" || c.status === "planowana"
                              ? "badge"
                              : ""
                          }
                          id="bad"
                          title="Need To Confirm"
                        >
                          i
                        </span>
                      ) : (
                        ""
                      )}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default Planned;
