import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import forget from "../../img/forget1.jpg";
import { changePassword } from "../../Service/Password";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Swal from "sweetalert2";
import PasswordStrength from "./Passsword/PasswordStrength";

function ChangePassword() {
  const [password, setPassword] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClickShowPassword = () => {
    setShow(!show);
  };
  const handleClickShowPassword1 = () => {
    setShow1(!show1);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPassword({ ...password, [name]: value });
  };

  const handlePasswordChange = (event) => {
    event.preventDefault();

    Swal.fire({
      title: "Loading",
      type: "warning",
      showCancelButton: false,
      showConfirmButton: false,
      timerProgressBar: true,
      timer: 3000,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    const re = RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/);
    const isOk = re.test(password.new_password);

    if (isOk) {
      changePassword(password).then((data) => {
        setPassword(data);
      });
    } else {
      Swal.fire({
        title: "Password Error",
        type: "warning",
        text:
          "Your password must contain at least one small , big letter and least one digit",
        timer: 2000,
      });
    }
  };

  const { t } = useTranslation();
  const base = process.env.PUBLIC_URL;

  return (
    <div className="container hip">
      {/* <!-- Outer Row --> */}
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              {/* <!-- Nested Row within Card Body --> */}
              <div className="row">
                <div className="col-lg-6 d-none d-lg-block bg-password-image">
                  <img
                    src={forget}
                    alt="change Password"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1
                        className="h4 hee change_password_text text-gray-900 mb-2"
                        style={{ marginTop: "-40px", lineHeight: "30px" }}
                      >
                        {t("change_password")}?
                      </h1>
                      <p className="mb-4" style={{ marginTop: "80px" }}>
                        {t("forget_password_text")}
                      </p>
                    </div>
                    <form
                      className="user "
                      onSubmit={handlePasswordChange}
                      autoComplete="off"
                    >
                      <div className="form-group">
                        <input
                          type="password"
                          name="old_password"
                          className="form-control"
                          placeholder={t("old_password")}
                          onChange={handleInputChange}
                        />

                        {password.new_password.includes}
                      </div>
                      <div className="form-group" style={{ marginTop: "30px" }}>
                        <input
                          name="new_password"
                          className="form-control"
                          type={show ? "text" : "password"}
                          placeholder={t("new_password")}
                          onChange={handleInputChange}
                        />
                        <span
                          className="full"
                          aria-label="toggle password visibility"
                          style={{ marginTop: "5px" }}
                          onClick={handleClickShowPassword}
                        >
                          {show ? (
                            <Visibility className="opp" />
                          ) : (
                            <VisibilityOff className="opp" />
                          )}
                        </span>

                        <PasswordStrength password={password.new_password} />
                      </div>
                      <div className="form-group">
                        <input
                          name="confirm_password"
                          type={show1 ? "text" : "password"}
                          className="form-control"
                          placeholder={t("confirm_password")}
                          onChange={handleInputChange}
                        />
                        <span
                          className="full"
                          aria-label="toggle password visibility"
                          style={{ marginTop: "5px" }}
                          onClick={handleClickShowPassword1}
                        >
                          {show1 ? (
                            <Visibility className="opp" />
                          ) : (
                            <VisibilityOff className="opp" />
                          )}
                        </span>
                        {password.new_password !== password.confirm_password ? (
                          password.confirm_password.length > 1 ? (
                            <span
                              className="text-danger"
                              style={{ marginTop: "-15px" }}
                            >
                              {t("password_did_not_match")}
                            </span>
                          ) : (
                            ""
                          )
                        ) : password.confirm_password.length > 1 ? (
                          <span
                            className="text-success"
                            style={{ marginTop: "-55px" }}
                          >
                            {t("password_match")}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <button
                        type="submit"
                        value="Login"
                        className="btn btn-login btn-block text-white font-weight-bolder boxx "
                        disabled={
                          password.new_password !== password.confirm_password
                        }
                        style={{
                          background: "rgba(183, 28, 28, 1)",
                          padding: "10px",
                          fontSize: "13px",
                          boxShadow: "0px 8px 25px -7px #c0c0c0",
                          borderRadius: "4px",
                          width: "100%",
                        }}
                      >
                        {t("change_password_button").toUpperCase()}
                        {/* <i
                            className="fas fa-location-arrow"
                            aria-hidden="true"
                            style={{ fontSize: "18px" }}
                          ></i> */}
                      </button>
                    </form>
                    <hr />
                    <div className="text-center">
                      <a
                        className="small"
                        href={base + "/fleet/dashboard/"}
                        style={{ color: "#b71c1c", opacity: "0.8" }}
                      >
                        {t("Dashboard")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
