import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import User from "../../../../../Components/User";
import arrow_left from "../../../../../img/Aarrow.png";
import Datee from "../../../../Views/Date";
import { Helmet } from "react-helmet";
import Recommendation from "../../../../../Components/Layout/Recommendation";
import Form from "./Form/Form";
import Parameter from "./Parameter/Parameter";
import { addSegment } from "../../../../../Service/Segment";
import Swal from "sweetalert2";

export class Segement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      parameter: [
        {
          segment_parameter: "",
          value: "",
          max_value: null,
          min_value: null,
        },
      ],
      segment_title: "",
      segment_description: "",
      from_date: null,
      to_date: null,
    };
    this.onChange = this.onChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  async onChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleChange = async (e) => {
    let target = e.target;
    let name = target.name;
    let value = Array.from(target.selectedOptions, (option) => option.value);
    await this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Loading",
      type: "warning",
      showCancelButton: false,
      showConfirmButton: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    const data1 = {
      segment_title: this.state.segment_title,
      segment_description: this.state.segment_description,
      from_date: this.state.from_date,
      to_date: this.state.to_date,
      parameter: [...this.state.parameter],
    };

    addSegment(data1).then((data) => {
      this.setState({ data });
    });
  };

  render() {
    const { t } = this.props;

    const disable =
      this.state.from_date === null ||
      this.state.to_date === null ||
      this.state.segment_title === "";
    return (
      <div className="Main_section">
        <Helmet>
          <title> Create Driver Segment </title>
        </Helmet>

        <div
          className="row row1"
          style={{ width: "100%", marginBottom: "-30px" }}
        >
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
        </div>
        <Recommendation />
        <div className="headerr">
          <div>
            <div>
              <a href="# " onClick={this.handleClick.bind(this)}>
                <img
                  src={arrow_left}
                  alt="Left_Arrow"
                  style={{ height: "2.6em" }}
                />
              </a>
              &nbsp;
              <strong>{t("add_segment")}</strong> &nbsp;
            </div>
          </div>

          <div style={{ marginTop: "30px" }}>
            <form onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="row">
                    <div className="col-sm-8">
                      <div className="form-group">
                        <label id="p" for="exampleInputEmail1">
                          {t("segment_title").toUpperCase()}
                          <span className="start_icon"> * </span>
                        </label>
                        <input
                          type="text"
                          placeholder={t("type")}
                          name="segment_title"
                          value={this.state.segment_title}
                          onChange={this.onChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-8">
                      <div className="form-group">
                        <label id="p" for="exampleInputEmail1">
                          {t("description").toUpperCase()}
                          <span className="start_icon"> * </span>
                        </label>
                        <input
                          type="text"
                          placeholder={t("type")}
                          name="segment_description"
                          maxLength={240}
                          value={this.state.segment_description}
                          onChange={this.onChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row">
                    <div className="col-sm-8" style={{ marginTop: "10px" }}>
                      <label id="p" for="exampleInputEmail1">
                        {t("choose_period").toUpperCase()}
                      </label>
                      <Form
                        cange={this.onChange}
                        to={this.state.to_date}
                        from={this.state.from_date}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div style={{ marginTop: "30px" }}>
                  <Parameter vall={this.state.parameter} />
                </div>
              </div>

              <div style={{ marginTop: "30px" }}>
                <center>
                  <button
                    type="submit"
                    className="btn btn-login text-white font-weight-bolder boxx button_text "
                    id="button1"
                    disabled={disable}
                  >
                    {t("driver_segment_button").toUpperCase()}
                  </button>
                </center>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Segement);
