import React, { Component } from "react";
import Header1 from "../../../../Components/Layout/Header1";
import { Helmet } from "react-helmet";
import arrow from "../../../../img/arrow.png";
import User from "../../../../Components/User";
import Datee from "../../../Views/Date";
import { withTranslation } from "react-i18next";
import arrow_left from "../../../../img/Aarrow.png";
import print from "../../../../img/Rating/print.png";
import print_pl from "../../../../img/Rating/print_pl.png";
import Filter from "../../Filter";
import DateGte from "../../../../Service/Arrow/DateGte";
import DateLte from "../../../../Service/Arrow/DateL";
import getPrint from "../../../../Service/Arrow/Print";
import compareByAsc from "../../../../Service/Arrow/Sort/Asc";
import compareByDesc from "../../../../Service/Arrow/Sort/Desc";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import {
  getDriverDaily,
  getDriverSummaryDaily,
} from "../../../../Service/Driver";
import Spinner from "../../../../Container/Spinner";

const moment = extendMoment(originalMoment);

class Ddetails extends Component {
  constructor(props) {
    super(props);
    const today = moment();
    this.state = {
      walk: [],
      speed: [],
      value1: "select",
      isOpen: false,
      today: null,
      lol: null,
      fol: null,
      isLoading: true,
      value: moment.range(today.clone().subtract(0, "days"), today.clone()),
    };
  }

  onToggle = (event) => {
    if (event.target.value === "date") {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  change(event) {
    this.setState({
      value1: event.target.value,
    });
  }

  // Get Data from filter date
  getData = async (value, states) => {
    Promise.all([
      getDriverDaily(
        this.props.match.params.pk,
        value.end.format("YYYY-MM-DD"),
        value.start.format("YYYY-MM-DD")
      ).then((data) => {
        this.setState({
          walk: data,
          lol: value.start,
          fol: value.end,
          isOpen: !this.state.isOpen,
        });
      }),
      getDriverSummaryDaily(
        this.props.match.params.pk,
        value.end.format("YYYY-MM-DD"),
        value.start.format("YYYY-MM-DD")
      ).then((data) => {
        this.setState({ speed: data });
      }),
    ]);
  };

  getOptionData = async () => {
    this.setState({ isLoading: true });
    try {
      Promise.all([
        getDriverDaily(
          this.props.match.params.pk,
          DateGte(this.state.today),
          DateLte(this.state.today)
        ).then((data) => {
          this.setState({ walk: data, isLoading: false });
        }),
        getDriverSummaryDaily(
          this.props.match.params.pk,
          DateGte(this.state.today),
          DateLte(this.state.today)
        ).then((data) => {
          this.setState({ isLoading: false, speed: data });
        }),
      ]);
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
      });
    }
  };

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.getOptionData("");
  };

  componentDidMount() {
    this.getOptionData("");
  }

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  sortBy(key) {
    let arrayCopy = [...this.state.walk];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(compareByAsc(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(compareByDesc(key));
    }
    this.setState({ walk: arrayCopy });
  }

  render() {
    const { walk, speed } = this.state;
    const { t } = this.props;
    const color = (data) =>
      data <= 4
        ? "fourth"
        : `${
            data <= 6
              ? "third"
              : `${data <= 8 ? "second" : `${data <= 10}` ? "first" : ""}`
          }`;
    const base = process.env.PUBLIC_URL;
    return (
      <div className="Main_section">
        <Helmet>
          {walk.slice(0, 1).map((c) => (
            <title>{c.name ? `${c.name}` : " Driver Name "} </title>
          ))}
          <meta charSet="utf-8" />
          <meta name="Helmet application" content="Driver Days Trip details" />
        </Helmet>

        <div
          className="row row1"
          style={{ width: "100%", marginBottom: "-30px" }}
        >
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
          <div className="col-sm-6 date_time" style={{ marginTop: "-5px" }}>
            <Filter
              lol={this.state.lol}
              fol={this.state.fol}
              today={this.state.today}
              option={this.state.isOpen}
              toggle={this.onToggle}
              change={this.getData}
              hello={this.onChange}
            />
          </div>
        </div>
        <div style={{ marginTop: "-20px" }}>
          <Header1 />

          <div className="headerr">
            <div className="drive_score">
              <span>
                <a href="# " onClick={this.handleClick.bind(this)}>
                  <img
                    src={arrow_left}
                    alt="Left_Arrow"
                    style={{ height: "2.6em" }}
                  />
                </a>
                &nbsp;
                {t("back")}
                &nbsp;&nbsp;&nbsp;
                {this.state.walk.length > 0 ? (
                  walk.slice(0, 1).map((c) => (
                    <span>
                      {" "}
                      {t("my_drivers")} /{" "}
                      <strong>{c.name ? `${c.name}` : " ? "}</strong>
                    </span>
                  ))
                ) : (
                  // <p
                  //   className="text-center h4 font-weight-bolder"
                  //   style={{ marginTop: "30px" }}
                  // >
                  //   {t("no_data")}
                  // </p>
                  <strong>&nbsp;&nbsp; {t("no_data")}</strong>
                )}
              </span>
              <span className="float-right">
                <a href="# " onClick={getPrint}>
                  <img
                    src={
                      window.localStorage.i18nextLng === "en" ? print : print_pl
                    }
                    alt="Print page"
                    style={{ marginTop: "-4px", height: "3em" }}
                  />
                </a>
              </span>
            </div>

            {this.state.isLoading ? (
              <Spinner />
            ) : (
              <div className="caaar">
                <table className="table  " style={{ width: "100%" }}>
                  <thead style={{ position: "relative" }}>
                    <tr className="th">
                      <th className="sorttable_nosort" style={{ width: "5%" }}>
                        {t("Id")}
                      </th>
                      <th onClick={() => this.sortBy("date")}>
                        {t("date")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />{" "}
                      </th>
                      <th className="t">{t("plate_number")}</th>

                      <th
                        className="t"
                        onClick={() => this.sortBy("milage")}
                        style={{ width: "120px" }}
                      >
                        {t("Milage")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>
                      <th
                        className="t"
                        onClick={() => this.sortBy("fuel_consumed")}
                        style={{ width: "130px" }}
                      >
                        {t("FuelComsumed")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>
                      <th
                        className="t"
                        onClick={() => this.sortBy("average_fuel_consumed")}
                      >
                        {t("AverageFuel")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>
                      <th className="t">
                        <select
                          // name="value"
                          onChange={this.change.bind(this)}
                          value={this.state.value1}
                          style={{
                            top: "0px",
                            width: "140px",
                          }}
                          class="minimal t"
                        >
                          <option value="1"> {t("OverallScore")} </option>
                          <option value="2">{t("OverallSpeed")} </option>
                          <option value="3"> {t("OverallDynamic")} </option>
                          <option value="4"> {t("OverallEfficiency")} </option>
                        </select>

                        {/* <img
                          src={arrow}
                          onClick={() =>
                            this.state.value1 === "1"
                              ? this.sortBy("overallscore")
                              : this.state.value1 === "2"
                              ? this.sortBy("overallspeedscore")
                              : this.state.value1 === "3"
                              ? this.sortBy("overalldynamicscore")
                              : this.state.value1 === "4"
                              ? this.sortBy("overallefficienyscore")
                              : this.sortBy("overallscore")
                          }
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        /> */}
                      </th>
                      <th
                        onClick={() => this.sortBy("overspeeding_distance")}
                        className="t"
                      >
                        {t("OverspeedingDistance")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>

                      <th
                        className="t"
                        onClick={() => this.sortBy("critical_overspeed")}
                      >
                        {t("CriticalSpeed")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {walk.map((c, i) => (
                      <tr
                        key={c.id}
                        className={
                          c.date
                            ? `${
                                new Date(c.date).getDay() === 6 ||
                                new Date(c.date).getDay() === 0
                                  ? "driver_color"
                                  : "text"
                              } `
                            : "-"
                        }
                      >
                        <td>{i + 1}</td>
                        <td>{c.date ? `${c.date} ` : "-"}</td>
                        <td>
                          <a
                            href={
                              base +
                              "/overview/app/carmetrics/" +
                              c.car_id +
                              "/"
                            }
                            style={{ color: "#b71c1c" }}
                            className="font-weight-bold"
                          >
                            {c.plate_number ? `${c.plate_number}` : "-"}
                          </a>
                        </td>
                        <td>{c.milage ? `${c.milage.toFixed(1)}` : "-"}</td>
                        <td>
                          {c.fuel_consumed
                            ? `${c.fuel_consumed.toFixed(1)}`
                            : "-"}
                        </td>
                        <td>
                          {c.average_fuel_consumed
                            ? `${c.average_fuel_consumed.toFixed(1)}`
                            : "-"}
                        </td>
                        <td>
                          <span
                            className={
                              this.state.value1 === "1"
                                ? color(c.overallscore)
                                : this.state.value1 === "2"
                                ? color(c.overallspeedscore)
                                : this.state.value1 === "3"
                                ? color(c.overalldynamicscore)
                                : this.state.value1 === "4"
                                ? color(c.overallefficienyscore)
                                : color(c.overallscore)
                            }
                          >
                            {this.state.value1 === "1"
                              ? `${
                                  c.overallscore
                                    ? `${c.overallscore.toFixed(1)}`
                                    : " - "
                                }`
                              : `${
                                  this.state.value1 === "2"
                                    ? `${
                                        c.overallspeedscore
                                          ? `${c.overallspeedscore.toFixed(1)}`
                                          : " - "
                                      }`
                                    : `${
                                        this.state.value1 === "3"
                                          ? `${
                                              c.overalldynamicscore
                                                ? `${c.overalldynamicscore.toFixed(
                                                    1
                                                  )}`
                                                : " - "
                                            }`
                                          : `${
                                              this.state.value1 === "4"
                                                ? `${
                                                    c.overallefficienyscore
                                                      ? `${c.overallefficienyscore.toFixed(
                                                          1
                                                        )}`
                                                      : " - "
                                                  }`
                                                : `${
                                                    c.overallscore
                                                      ? `${c.overallscore.toFixed(
                                                          1
                                                        )}`
                                                      : " - "
                                                  }`
                                            }`
                                      }`
                                }`}
                          </span>
                        </td>
                        <td>
                          {c.overspeeding_percentage
                            ? `${c.overspeeding_percentage.toFixed(1)}`
                            : "-"}
                        </td>
                        <td>
                          {c.critical_overspeed
                            ? `${c.critical_overspeed}`
                            : "-"}
                        </td>
                        {c.car_id === null ? (
                          ""
                        ) : (
                          <td>
                            <a
                              href={
                                base +
                                `/carmap/${c.car_id}/${c.date}/${c.plate_number}`
                              }
                              style={{ color: "#b71c1c" }}
                              className="font-weight-bold"
                            >
                              {t("map")}
                            </a>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>

                  <tfoot className="thead">
                    <tr>
                      <th className="sorttable_nosort"></th>
                      <th className="sorttable_nosort">
                        {t("Summary1").toUpperCase()}
                      </th>
                      <th className="sorttable_nosort"></th>
                      <th className="sorttable_nosort">
                        {speed.milage ? `${speed.milage.toFixed(1)}` : "-"}
                      </th>
                      <th className="sorttable_nosort">
                        {speed.fuel_consumed
                          ? `${speed.fuel_consumed.toFixed(1)}`
                          : "-"}
                      </th>
                      <th className="sorttable_nosort">
                        {speed.average_fuel_consumed
                          ? `${speed.average_fuel_consumed.toFixed(1)}`
                          : "-"}
                      </th>
                      <th>
                        <span
                          className={
                            this.state.value1 === "1"
                              ? color(speed.overallscore)
                              : this.state.value1 === "2"
                              ? color(speed.overallspeedscore)
                              : this.state.value1 === "3"
                              ? color(speed.overalldynamicscore)
                              : this.state.value1 === "4"
                              ? color(speed.overallefficienyscore)
                              : color(speed.overallscore)
                          }
                          style={{ width: "35px" }}
                        >
                          {this.state.value1 === "1"
                            ? `${
                                speed.overallscore
                                  ? `${speed.overallscore.toFixed(1)}`
                                  : " - "
                              }`
                            : `${
                                this.state.value1 === "2"
                                  ? `${
                                      speed.overallspeedscore
                                        ? `${speed.overallspeedscore.toFixed(
                                            1
                                          )}`
                                        : " - "
                                    }`
                                  : `${
                                      this.state.value1 === "3"
                                        ? `${
                                            speed.overalldynamicscore
                                              ? `${speed.overalldynamicscore.toFixed(
                                                  1
                                                )}`
                                              : " - "
                                          }`
                                        : `${
                                            this.state.value1 === "4"
                                              ? speed.overallefficienyscore
                                                ? `${speed.overallefficienyscore.toFixed(
                                                    1
                                                  )}`
                                                : " - "
                                              : `${
                                                  speed.overallscore
                                                    ? `${speed.overallscore.toFixed(
                                                        1
                                                      )}`
                                                    : " - "
                                                }`
                                          }`
                                    }`
                              }`}
                        </span>
                      </th>
                      <th className="sorttable_nosort">
                        {speed.overspeeding_percentage
                          ? `${speed.overspeeding_percentage.toFixed(1)}`
                          : "-"}
                      </th>
                      <th className="sorttable_nosort">
                        {speed.critical_overspeed
                          ? `${speed.critical_overspeed.toFixed(1)}`
                          : "-"}
                      </th>
                      <th></th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Ddetails);
