import React from "react";
import { Redirect } from "react-router";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from "../../../Components/Layout/Header";
import Auth from "../../../Components/Login/Auth";
import Dashboard from "../../Views/OldDashboard/Dashboard";
import Notfound from "../../Views/NotFound/Notfound";
import Notification from "../../Settings/Notification/Notification";
import Choose1 from "../../Cars/Choose/Choose";
import ForgetPassword from "../../Forget/ForgetPassword";
import ResetPassword from "../../Forget/ResetPassword";
import ChangePassword from "../../Forget/ChangePassword";
import MetricReport from "../../Report/Car/MetricReport";
import Fuel from "../../Report/Fuel/Fuel";
import ErrorHandle from "../../Views/Error/ErrorHandle";
import AllNoti from "../../Notification/AllNoti";
import Cookies from "universal-cookie";
import {
  Faq,
  Corporate,
  Contact,
  Privacy,
  Terms,
} from "../../../Components/Footer/Link";
import {
  Recommend,
  Profilee,
  Engament,
  Ecosystem,
  DriverCar,
  Mobility,
  CostR,
  Choose,
  Segement,
  DetailView,
  Dummy,
  SegmentView,
} from "../Recommendation";
import {
  InsuranceCarMetrics,
  Risk,
  InsuranceProfile,
  Claim,
  AddClaim,
  ViewClaim,
  EditClaim,
  Fleet,
  Working,
  ActiveCampaign,
  Finished,
  Planned,
  Clickk,
  Footer,
} from "../Index";
import {
  Ddetails,
  DriverMetrics,
  AddDriver,
  EditDriver,
  DriverReportt,
  CritialOverspeed,
  FilterDriver,
  FuelFilter,
  FilterDynamic,
  FilterSpeed,
  CDdetails,
  MyDriver,
  MyProfile,
  CoDriverCar,
  DriverList,
} from "../Driver";
import {
  Cdetails,
  CarMetrics,
  EditCar,
  Report,
  DailyView,
  ElectricCar,
  ElectrcDetails,
  Eprofile,
  EDetails,
  AddCar,
  CriticalMap,
  SpeedFilter,
  FilterNorm,
  Mapp,
  Mapp1,
  Cost,
  CostDetail,
  UpdateInvoice,
  // Campaign,
  CreateR,
  Create,
  Active,
  Data,
  Edit,
  MyCar,
  MyCarProfile,
  CriticalOverSpeedingCar,
} from "../Carlink";
// import RecommendationDetails from "../../Settings/Recommend/RecommendationDetails";

const baseUrl = process.env.PUBLIC_URL;
const cookies = new Cookies();
const PrivateRoute = ({ component: Component, ...props }) => {
  return (
    <Route
      {...props}
      render={(innerProps) =>
        cookies.get("Token") ? (
          <Component {...innerProps} />
        ) : (
          <Redirect
            to={{
              pathname: baseUrl + "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const WithoutTokenRoute = ({ component: Component, ...props }) => {
  return (
    <Route
      {...props}
      render={(innerProps) =>
        cookies.get("Token") ? (
          <Redirect
            to={{
              pathname: baseUrl + "/fleet/dashboard/",
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...innerProps} />
        )
      }
    />
  );
};

const ResetPasswordTokenRoute = ({ component: Component, ...props }) => {
  return (
    <Route
      {...props}
      render={(innerProps) =>
        cookies.get("Token") ? (
          <Redirect
            to={{
              pathname: baseUrl + "/fleet/dashboard/",
              state: { from: props.location },
            }}
          />
        ) : new URLSearchParams(window.location.search).get("token").length >=
          20 ? (
          <Component {...innerProps} />
        ) : (
          <Redirect
            to={{
              pathname: baseUrl + "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Route1 = () => {
  return (
    <BrowserRouter>
      {window.location.pathname === baseUrl + "/faq/" ||
      window.location.pathname === baseUrl + "/privacy/statement/" ||
      window.location.pathname === baseUrl + "/corporate/information/" ||
      window.location.pathname === baseUrl + "/termsofuse/" ||
      window.location.pathname === baseUrl + "/contact/us/"
        ? ""
        : cookies.get("Token") && (
            <Header loggedIn={cookies.get("Token") != null} />
          )}
      <ErrorHandle>
        <Switch>
          <Route exact path={baseUrl + "/"} component={Auth} />
          <Route path={baseUrl + "/faq/"} component={Faq} />
          <Route path={baseUrl + "/privacy/statement/"} component={Privacy} />
          <Route
            path={baseUrl + "/corporate/information/"}
            component={Corporate}
          />
          <Route path={baseUrl + "/termsofuse/"} component={Terms} />
          <Route path={baseUrl + "/contact/us/"} component={Contact} />
          <WithoutTokenRoute
            path={baseUrl + "/forgetpassword/"}
            component={ForgetPassword}
          />
          <ResetPasswordTokenRoute
            path={baseUrl + "/reset/password/"}
            component={ResetPassword}
          />
          <PrivateRoute
            path={baseUrl + "/change/password/"}
            component={ChangePassword}
          />
          <PrivateRoute
            path={baseUrl + "/ddriver/my/driver/:date__gte/:date__lte"}
            component={CritialOverspeed}
          />
          <PrivateRoute
            path={
              baseUrl + "/ddoverview/my/driver/:pk/:date__lte/:date__gte/:name/"
            }
            component={CDdetails}
          />
          <PrivateRoute
            path={
              baseUrl + "/ccoverview/my/car/:pk/:date__lte/:date__gte/:plate/"
            }
            component={CriticalOverSpeedingCar}
          />
          <PrivateRoute
            path={
              baseUrl + "/critical/overspeed/data/:pk/:date__lte/:date__gte/"
            }
            component={CoDriverCar}
          />

          <PrivateRoute
            path={
              baseUrl +
              "/filterdriver/recommendation/driver/:overall_score__lte/:overall_score__gt/:to/:from"
            }
            component={FilterDriver}
          />
          <PrivateRoute
            path={
              baseUrl +
              "/fuelfilter/recommendation/driver/:fuel_score__lte/:fuel_score__gt/:to/:from"
            }
            component={FuelFilter}
          />
          <PrivateRoute
            path={
              baseUrl +
              "/filterspeed/recommendation/driver/:speed_score__lte/:speed_score__gt/:to/:from"
            }
            component={FilterSpeed}
          />
          <PrivateRoute
            path={
              baseUrl +
              "/filterdynamic/recommendation/driver/:dynamics_score__lte/:dynamics_score__gt/:to/:from"
            }
            component={FilterDynamic}
          />

          <PrivateRoute
            path={
              baseUrl +
              "/overspeeding/recommendation/driver/:overspeeding_percentage__lte/:overspeeding_percentage__gt/:to/:from"
            }
            component={SpeedFilter}
          />
          <PrivateRoute
            path={
              baseUrl +
              "/filternorm/recommendation/my/car/:fuel_ratio__lte/:fuel_ratio__gt/:state/"
            }
            component={FilterNorm}
          />
          <PrivateRoute
            path={baseUrl + "/critical/caroverview/:pk/:date"}
            component={CriticalMap}
          />

          {/* Cost Section link */}
          <PrivateRoute path={baseUrl + "/cost/"} component={Cost} />
          <PrivateRoute
            path={baseUrl + "/overview/cost/:pk/"}
            component={CostDetail}
          />
          <PrivateRoute
            path={baseUrl + "/edit/cost/:pk/"}
            component={UpdateInvoice}
          />

          {/* Campaign Section link */}
          <PrivateRoute
            path={baseUrl + "/active/campaign/"}
            component={ActiveCampaign}
          />
          <PrivateRoute
            path={baseUrl + "/finished/campaign/"}
            component={Finished}
          />
          <PrivateRoute
            path={baseUrl + "/planned/campaign/"}
            component={Planned}
          />
          <PrivateRoute
            path={baseUrl + "/create/campaign/"}
            component={Create}
          />
          <PrivateRoute
            path={baseUrl + "/driver/create/campaign/"}
            component={CreateR}
          />

          <PrivateRoute
            path={baseUrl + "/overview/campaign/:pk/:title/"}
            component={Active}
          />
          <PrivateRoute
            path={baseUrl + "/edit/campaign/:pk/"}
            component={Edit}
          />
          <PrivateRoute path={baseUrl + "/score/campaign"} component={Data} />
          <PrivateRoute
            path={baseUrl + "/select/driver/"}
            component={DriverList}
          />

          {/* Report Section link  */}
          <PrivateRoute path={baseUrl + "/carreport/"} component={Report} />
          <PrivateRoute path={baseUrl + "/metric/car/"} component={Choose1} />
          <PrivateRoute
            path={baseUrl + "/metric/report/"}
            component={MetricReport}
          />
          <PrivateRoute
            path={baseUrl + "/driverreport"}
            component={DriverReportt}
          />
          <PrivateRoute path={baseUrl + "/Fuel/"} component={Fuel} />

          {/* Notification Section  */}
          <PrivateRoute
            path={baseUrl + "/notification/"}
            component={Notification}
          />
          <PrivateRoute
            path={baseUrl + "/all/notification/"}
            component={AllNoti}
          />

          {/* Insurance section link */}
          <PrivateRoute path={baseUrl + "/risk/"} component={Risk} />
          <PrivateRoute
            path={baseUrl + "/overall/risk/:date__gte/:date__lte"}
            component={Risk}
          />
          <PrivateRoute
            path={baseUrl + "/company/profile/"}
            component={InsuranceProfile}
          />
          <PrivateRoute
            path={baseUrl + "/claim/insurance/"}
            component={Claim}
          />
          <PrivateRoute
            path={baseUrl + "/add/app/claim/insurance/"}
            component={AddClaim}
          />
          <PrivateRoute
            path={baseUrl + "/edit/app/claim/insurance/:pk/"}
            component={EditClaim}
          />

          <PrivateRoute
            path={baseUrl + "/view/app/claim/insurance/:pk/"}
            component={ViewClaim}
          />
          <PrivateRoute
            path={baseUrl + "/insurance/carmetrics/"}
            component={InsuranceCarMetrics}
          />

          {/* Main Dashboard Link */}
          <PrivateRoute
            path={baseUrl + "/fleet/dashboard/"}
            component={Fleet}
          />
          <PrivateRoute path={baseUrl + "/my/fleet/"} component={Dashboard} />
          <PrivateRoute path={baseUrl + "/my/driver/"} component={MyDriver} />
          <PrivateRoute
            path={baseUrl + "/overview/app/my/driver/:pk/"}
            component={Ddetails}
          />
          <PrivateRoute path={baseUrl + "/my/car/"} component={MyCar} />
          <PrivateRoute
            path={baseUrl + "/overview/app/my/car/:pk/:type"}
            component={Cdetails}
          />
          <PrivateRoute
            path={baseUrl + "/carmap/:pk/:date/:plate_number"}
            component={Mapp}
          />
          <PrivateRoute
            path={baseUrl + "/carmapdetail/:id/:from/:year/:trip"}
            component={Mapp1}
          />
          <PrivateRoute path={baseUrl + "/electric/"} component={ElectricCar} />
          <PrivateRoute
            path={baseUrl + "/Overview/app/electric/:pk"}
            component={ElectrcDetails}
          />
          <PrivateRoute
            path={baseUrl + "/dailyview/app/electric/:pk/:date/"}
            component={DailyView}
          />

          {/* Profile section link */}
          <PrivateRoute
            path={baseUrl + "/drivermetrics/"}
            component={DriverMetrics}
          />
          <PrivateRoute
            path={baseUrl + "/adddriver/app/drivermetrics/"}
            component={AddDriver}
          />
          <PrivateRoute
            path={baseUrl + "/editdriver/app/drivermetrics/:pk/"}
            component={EditDriver}
          />
          <PrivateRoute
            path={baseUrl + "/carmetrics/"}
            component={CarMetrics}
          />
          <PrivateRoute
            path={baseUrl + "/addcar/app/carmetrics/"}
            component={AddCar}
          />
          <PrivateRoute
            path={baseUrl + "/editcar/app/carmetrics/:pk/"}
            component={EditCar}
          />
          <PrivateRoute
            path={baseUrl + "/overview/app/drivermetrics/:pk"}
            component={MyProfile}
          />
          <PrivateRoute
            path={baseUrl + "/overview/app/carmetrics/:pk"}
            component={MyCarProfile}
          />
          <PrivateRoute
            path={baseUrl + "/data/comp/"}
            component={InsuranceProfile}
          />
          <PrivateRoute
            path={baseUrl + "/profile/electric/"}
            component={Eprofile}
          />
          <PrivateRoute
            path={baseUrl + "/overview/app/profile/electric/:pk"}
            component={EDetails}
          />

          {/* Recommendation section link */}
          <PrivateRoute
            path={baseUrl + "/ev/recommendation/"}
            component={Recommend}
          />
          <PrivateRoute
            path={baseUrl + "/recommendation/driver/"}
            component={DriverCar}
          />
          <PrivateRoute
            path={baseUrl + "/segment/recommendation/driver/"}
            component={Segement}
          />
          <PrivateRoute
            path={baseUrl + "/view/segment/recommendation/driver/:pk/"}
            component={DetailView}
          />

          <PrivateRoute
            path={baseUrl + "/recommendation/costs/"}
            component={CostR}
          />
          <PrivateRoute
            path={baseUrl + "/ecommendation/engagement/"}
            component={Engament}
          />
          <PrivateRoute
            path={baseUrl + "/recommendation/mobility/"}
            component={Mobility}
          />

          <PrivateRoute
            path={baseUrl + "/recommendation/ecosystem/"}
            component={Ecosystem}
          />
          <PrivateRoute
            path={baseUrl + "/choose/ev/recommendation/"}
            component={Choose}
          />
          <PrivateRoute
            path={baseUrl + "/profile/ev/recommendation/:pk/:car/:mode/:mode1/"}
            component={Profilee} //////////////////////
            //component={RecommendationDetails}
          />

          {/* <PrivateRoute path={baseUrl + "/cookies/"} component={Cookies} /> */}

          {/* Not Found and On Progress Page */}
          <PrivateRoute path={baseUrl + "/on/dummy/"} component={Dummy} />
          <PrivateRoute
            path={baseUrl + "/segment/data/"}
            component={SegmentView}
          />
          <PrivateRoute path={baseUrl + "/click/"} component={Clickk} />

          <PrivateRoute path={baseUrl + "/on/progress/"} component={Working} />
          <PrivateRoute path="*" component={Notfound} />
        </Switch>
      </ErrorHandle>

      <Footer />
    </BrowserRouter>
  );
};

export default Route1;
