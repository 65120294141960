import React, { Component } from "react";
import Datee from "./Date";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import "./index1.js";
import config from "../Main";

import cross from "../../img/Cross.png";

export class CarReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: [],
      selectOptions1: [],
      movie: [],
      walk: [],
      mov: "",
      query: "",
      results: [],
      speed: [],
      lol: "",
      fol: "",
      plate: "",
      hidden: true,
      switcher: "classic",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  toggleHidden() {
    this.setState({
      hidden: true,
    });
  }

  toggleHidden1() {
    this.setState({
      hidden: false,
    });
  }

  async onSubmitt1(e) {
    const res = await fetch(
      `${config.apiUrl.car}?license_plate__icontains=${this.state.plate}`,
      config.head
    );
    const walk = await res.json();
    this.setState({
      walk,
    });
  }

  componentDidMount() {
    this.onSubmitt1("");
  }

  async onChange(e) {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.onSubmitt1("");
  }

  async handleSubmit(e) {
    try {
      const base = process.env.PUBLIC_URL;
      const from = e.target.elements.from.value;
      const to = e.target.elements.to.value;
      const selections = [...e.target.elements.selectOptions.options].filter(
        (opt) => opt.selected
      );
      const selectedValues = selections.map((opt) => opt.value);
      const selectedString = selectedValues.join(",");
      e.preventDefault();

      Promise.all([
        fetch(
          `${config.apiUrl.car}?date__lte=${to}&date__gte=${from}&id__in=${selectedString}`,
          config.head
        ).then((response) => response.json()),
        fetch(
          `${config.apiUrl.carsummary}?date__lte=${to}&date__gte=${from}&id__in=${selectedString}`,
          config.head
        ).then((response) => response.json()),
      ]).then((resData) => {
        this.setState({
          ...this.state,
          movie: resData,
          speed: resData,
          lol: from,
          fol: to,
        });

        this.props.history.push({
          pathname: base + "/carreport/",
          state: {
            movie: resData,
            speed: resData,
            lol: from,
            fol: to,
            selectOptions1: this.state.selectOptions1,
          },
        });
        window.location.reload();
      });
    } catch (e) {
      console.log(e);
    }
  }

  async handleChange(e) {
    let target = e.target;
    let name = target.name;
    //here
    let value = Array.from(target.selectedOptions, (option) => option.value);
    await this.setState({
      [name]: value,
    });
  }

  render() {
    const { t } = this.props;
    const { walk } = this.state;
    const base = process.env.PUBLIC_URL;
    const nonElectric = walk.filter((c) => c.type !== "BEV");
    const Electric = walk.filter((c) => c.type === "BEV");
    const switcher = this.state.switcher === "classic" ? nonElectric : Electric;

    return (
      <div id="car" className="modal">
        <a href="# " rel="modal:close" className="float-right text-dark ">
          <img src={cross} alt="Cross" style={{ height: "1.1em" }} />
        </a>
        <p className="mod" style={{ marginTop: "10px" }}>
          {t("car_reportt").toUpperCase()}
        </p>

        <form style={{ position: "relative" }} onSubmit={this.handleSubmit}>
          <div>
            <Datee />
          </div>

          <div className="row" style={{ marginTop: "15px", width: "100%" }}>
            <div className="col-md-6 col-lg-6">
              <div className=" " style={{ marginRight: "-15px" }}>
                <input
                  type="radio"
                  name="r"
                  id="r2"
                  style={{ width: "150px" }}
                  defaultChecked
                  onClick={this.toggleHidden.bind(this)}
                />
                <label htmlFor="r2" style={{ fontSize: "14px" }}>
                  {t("list")}
                </label>
              </div>
            </div>

            <div className="col-md-6 col-lg-6 ">
              <a
                href={base + "/metric/car/"}
                target="_blank"
                rel="noopener noreferrer"
                className="ppp font-weight-bolder"
                style={{ color: "#b71c1c" }}
              >
                {t("Choosemetrics")}
              </a>
            </div>
          </div>

          <div className="row" style={{ marginTop: "20px" }}>
            <div className=" col-sm-6 form-group">
              <input
                type="radio"
                name="switcher"
                id="switchClassic"
                defaultChecked
                onChange={this.onChange}
                value="classic"
              />
              <label
                id="p"
                style={{ fontSize: "14px" }}
                htmlFor="switchClassic"
              >
                {t("classic_car")}
              </label>
            </div>

            <div className="form-group col-sm-6">
              {Electric.length < 0 ? (
                ""
              ) : (
                <span>
                  <input
                    type="radio"
                    name="switcher"
                    id="switcherElectric"
                    onChange={this.onChange}
                    class="form-check-input"
                    value="electric"
                  />
                  <label
                    id="p"
                    style={{ fontSize: "14px" }}
                    htmlFor="switcherElectric"
                  >
                    {t("EV_cars")}
                  </label>
                </span>
              )}
            </div>
          </div>
          {this.state.hidden && (
            <div className="row" style={{ marginTop: "-20px" }}>
              <div className="col-md-6 col-lg-4">
                <div className="form-group" style={{ marginTop: "20px" }}>
                  <label style={{ opacity: "0.6", fontSize: "10px" }}>
                    {t("choose_car")}
                  </label>

                  <input
                    className="form-control finds"
                    placeholder={t("search")}
                    type="text"
                    name="plate"
                    style={{ width: "150px" }}
                    onChange={this.onChange}
                  />

                  <select
                    name="selectOptions"
                    style={{ width: "150px" }}
                    multiple={true}
                    onChange={this.handleChange}
                    value={this.state.selectOptions}
                    className="form-control select donn"
                  >
                    <option value="" disabled hidden>
                      {t("choose_car1").toUpperCase()}
                    </option>
                    <option value=" "> {t("select_car")}</option>
                    {switcher.map((c) => (
                      <option value={c.id} key={c.id}>
                        {" "}
                        {c.plate_number}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-md-6 col-lg-4" style={{ marginLeft: "30px" }}>
                <div className="form-group" style={{ marginTop: "20px" }}>
                  <label style={{ opacity: "0.6", fontSize: "10px" }}>
                    {t("choose_header").toUpperCase()}
                  </label>
                  {this.state.switcher === "classic" ? (
                    <select
                      name="selectOptions1"
                      multiple={true}
                      style={{ width: "240px", marginTop: "33px" }}
                      onChange={this.handleChange}
                      value={this.state.selectOptions1}
                      className="form-control donn"
                    >
                      <option value={0}>{t("select_all")}</option>

                      <option value={1}>{t("Engine_type")}</option>
                      <option value={2}>{t("Milage")}</option>
                      <option value={3}> {t("FuelComsumed")}</option>
                      <option value={4}> {t("AverageFuel")}</option>
                      <option value={5}> {t("OverspeedingDistance")}</option>
                      <option value={6}> {t("dtc")}</option>
                      <option value={7}> {t("weekend_driving")}</option>
                      <option value={8}> {t("off_hour_driving")}</option>
                      <option value={9}>{t("off_zone_driving")}</option>
                      <option value={10}>{t("total_cost")}</option>
                    </select>
                  ) : (
                    <select
                      name="selectOptions1"
                      multiple={true}
                      style={{ width: "240px", marginTop: "33px" }}
                      onChange={this.handleChange}
                      value={this.state.selectOptions1}
                      className="form-control donn"
                    >
                      <option value={11}> {t("energy_cosumed")}</option>
                      <option value={12}> {t("average_energy_cosumed")}</option>
                      <option value={13}> {t("fuel_eq")}</option>
                      <option value={14}>
                        {" "}
                        {t("energy_from_recuperation")}
                      </option>
                      <option value={15}>{t("driving_time")}</option>
                      <option value={16}>{t("charging_time")}</option>
                      <option value={17}>{t("number_of_charging")}</option>
                      <option value={18}>{t("energy_from_charging")}</option>
                      <option value={19}>{t("energy_from_ac")}</option>
                      <option value={20}>{t("energy_from_dc")}</option>
                    </select>
                  )}
                </div>
              </div>
            </div>
          )}

          {/* <Select load={this.handleChange} val={this.state.selectOptions} /> */}

          <div style={{ marginTop: "30px" }}>
            <center>
              <button
                type="submit"
                value="Get Data"
                className="btn btn-login text-white font-weight-bolder boxx "
                id="button1"
                style={{
                  height: "40px",
                  fontSize: "13px",

                  width: "200px",
                  background: "rgba(183, 28, 28, 1)",
                  border: "none",
                  color: "white",
                  margin: "auto",
                }}
              >
                {t("raport").toUpperCase()}
              </button>
            </center>
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation()(withRouter(CarReports));
