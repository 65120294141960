import config from "../views/Main";
import originalMoment from "moment";

// New Main Dashboard Stats endpoint
export async function getNewDashboardData(to, from, year, month) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl1.newDashboard}?date__lte=${to ? to : ""}&date__gte=${
        from ? from : ""
      }&date__year=${
        year
          ? year === "year"
            ? originalMoment().subtract(1, "year").endOf("year").format("YYYY")
            : ""
          : ""
      }&date__month=${
        month
          ? month === "month"
            ? originalMoment().subtract(1, "month").endOf("month").format("M")
            : ""
          : ""
      }`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}
