import React, { Component } from "react";
import Chart from "react-apexcharts";
import { withTranslation } from "react-i18next";


export class Battery extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      ...props,

      series: [
        {
          name: "series1",
          data: props.battery.map((c) => c.value),
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "area",
        },
        labels: props.battery.map((c) => c.label),
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },

        colors: ["#b71c1c"],
        fill: {
          opacity: 0.5,
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.3,
            opacityTo: 0.4,
            stops: [0, 100],
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " %";
            },
          },
          x: {
            formatter: function (val) {
              return t("time") + ": " + val;
            },
          },
        },
        xaxis: {
          type: "category",
          tickAmount: 10,
          tickPlacement: "between",
          title: {
            offsetY: -0,
            text: t("battery_x"),
            style: {
              fontSize: "10px",
              color: "#8D8D90",
            },
          },
          labels: {
            rotate: -10,
            style: {
              fontSize: "12px",
              color: "#8D8D90",
            },
          },
          categories: props.battery.map((c) => c.label),
        },
        yaxis: {
          min: 0,
          max: 120,
          tickAmount: 6,
          title: {
            text: t("battery_y"),

            style: {
              fontSize: "10px",
              color: "#8D8D90",
            },
          },

          labels: {
            formatter: function (value) {
              return value + " % ";
            },
            style: {
              fontSize: "10px",
              opacity: "0.2",
              color: "#8D8D90",
            },
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
        },
      },
      name: [],
    };
  }

  render() {
    const { t } = this.props;
    const rahul = [
      {
        name: t("battery_yy"),
        data: this.props.battery.map((c) => c.value),
      },
    ];

    return (
      <div style={{ marginLeft: "-10px" }}>
        <p
          id="pppp"
          className=" float-left"
          style={{ paddingLeft: "25px", fontSize: "14px" }}
        >
          {t("batter_stats")}
        </p>
        <Chart
          options={this.state.options}
          series={rahul}
          type="area"
          height="300px"
        />
      </div>
    );
  }
}

export default withTranslation()(Battery);
