import React from "react";
// import spinner from "./../img/Spinner/spinner.gif";
import spinner1 from "./../img/Spinner/spinner.gif";
const base = process.env.PUBLIC_URL;
const Spinner = () => {
  return (
    <div style={{ background: "transparent" }}>
      <img
        alt="loading"
        src={spinner1}
        className={
          window.location.pathname === base + "/claim/insurance/" ||
          window.location.pathname === base + "/risk/" ||
          window.location.pathname === base + "/my/driver/" ||
          window.location.pathname === base + "/my/car/" ||
          window.location.pathname === base + "/electric/" ||
          window.location.pathname === base + "/insurance/carmetrics/" ||
          window.location.pathname === base + "/choose/ev/recommendation/" ||
          window.location.pathname.includes(base + "/Overview/app/electric/") ||
          window.location.pathname.includes(base + "/overview/app/my/car/")
            ? "spinner1"
            : "spinner"
        }
      />
    </div>
  );
};

export default Spinner;
