import React, { Component } from "react";
import { withTranslation } from "react-i18next";

export class Datee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
    };
  }

  componentDidMount() {
    this.getDate();
  }

  getDate = () => {
    const { t } = this.props;
    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      lang: t("datee"),
    };
    var date = new Date().toLocaleDateString(options.lang, options);
    this.setState({ date });
  };

  render() {
    const { date } = this.state;
    const { t } = this.props;
    return (
      <div className="">
        <p style={{ fontSize: "13px", opacity: "0.7", lineHeight: "20px" }}>
          {t("Today")} {date}
        </p>
      </div>
    );
  }
}

export default withTranslation()(Datee);
