import config from "../views/Main";

// Stats endpoint
export async function getStats(to, from) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.stats}?date__lte=${to ? to : ""}&date__gte=${
        from ? from : ""
      }`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

// OverSpeeding Endpoint
export async function getOverSpeeding(to, from) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.overspeeding}?date__lte=${to ? to : ""}&date__gte=${
        from ? from : ""
      }`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

// Overall Endpoint
export async function getOverall(to, from) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.overall}?date__lte=${to ? to : ""}&date__gte=${
        from ? from : ""
      }`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

//Speed Endpoint
export async function getSpeed(to, from) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.speed}?date__lte=${to ? to : ""}&date__gte=${
        from ? from : ""
      }`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

//Fuel Endpoint
export async function getFuel(to, from) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.fuel}?date__lte=${to ? to : ""}&date__gte=${
        from ? from : ""
      }`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getDynamic(to, from) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.dynamics}?date__lte=${to ? to : ""}&date__gte=${
        from ? from : ""
      }`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getMonthly(make) {
  let products = new Promise((resolve, reject) => {
    fetch(`${config.apiUrl.monthlyfuel}?date__month=${make}`, config.head)
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

// Get Fuel data my month endpoint

export async function getFuelMonth(year) {
  let products = new Promise((resolve, reject) => {
    fetch(`${config.apiUrl.monthlyfuel}?date__year_month=${year}`, config.head)
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}
