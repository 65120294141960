import config from "../views/Main";

export async function getRecommend(
  saving,
  engine,
  brand,
  model,
  price_from,
  price_to, 
  id
) {
  //////
    //console.log("GET RECOMMEND IS CALLED");
    // let query = (
    //   `${config.apiUrl.ecrecomend}?yearly_savings__gte=${
    //     saving ? saving : ""
    //   }&type=${engine ? engine : ""}&make=${brand ? brand : ""}&model=${
    //     model === "Leaf e+" ? "Leaf+e%2B" : model ? model : ""
    //   }&price_pln__gte=${price_from ? price_from : ""}&price_pln__lte=${
    //     price_to ? price_to : ""
    //   }&car_id__in=${id ? id : ""}`
    // );
    //console.log("QUERY IS ", query);
  /////

  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.ecrecomend}?yearly_savings__gte=${
        saving ? saving : ""
      }&type=${engine ? engine : ""}&make=${brand ? brand : ""}&model=${
        model === "Leaf e+" ? "Leaf+e%2B" : model ? model : ""
      }&price_pln__gte=${price_from ? price_from : ""}&price_pln__lte=${
        price_to ? price_to : ""
      }&car_id__in=${id ? id : ""}`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getBrand(brand) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `https://digitalfleet.eu/api/1/carmarket/models/?make=${brand}`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getModel(plate) {
  let products = new Promise((resolve, reject) => {
    fetch(`${config.apiUrl.car}?license_plate__icontains=${plate}`, config.head)
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}
