import React, { Component } from "react";
import ImageMapper from "react-image-mapper";

export class Clickk extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoveredArea: null,
      msg: null,
      moveMsg: null,
    };
  }

  load() {
    this.setState({ msg: "Interact with image !" });
  }
  clicked(area) {
    this.setState({
      area: area.fillColor,
      msg: `You clicked on ${area.shape} at coords ${JSON.stringify(
        area.coords
      )} !`,
    });
  }
  clickedOutside(evt) {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    this.setState({
      msg: `You clicked on the image at coords ${JSON.stringify(coords)} !`,
    });
  }
  moveOnImage(evt) {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    this.setState({
      moveMsg: `You moved on the image at coords ${JSON.stringify(coords)} !`,
    });
  }
  enterArea(area) {
    this.setState({
      hoveredArea: area,
      msg: `You entered ${area.shape} ${area.name} at coords ${JSON.stringify(
        area.coords
      )} !`,
    });
  }
  leaveArea(area) {
    this.setState({
      hoveredArea: null,
      msg: `You leaved ${area.shape} ${area.name} at coords ${JSON.stringify(
        area.coords
      )} !`,
    });
  }
  moveOnArea(area, evt) {
    const coords = { x: evt.nativeEvent.layerX, y: evt.nativeEvent.layerY };
    this.setState({
      moveMsg: `You moved on ${area.shape} ${
        area.name
      } at coords ${JSON.stringify(coords)} !`,
    });
  }

  getTipPosition(area) {
    return { top: `${area.center[1]}px`, left: `${area.center[0]}px` };
  }
  render() {
    const URL = "";
    const MAP = {
      name: "my-map",
      areas: [
        {
          name: "1",
          shape: "poly",
          coords: [25, 33, 27, 300, 128, 240, 128, 94],
          preFillColor: "green",
          fillColor: "blue",
        },
        {
          name: "2",
          shape: "poly",
          coords: [219, 118, 220, 210, 283, 210, 284, 119],
          preFillColor: "pink",
        },
        {
          name: "3",
          shape: "poly",
          coords: [381, 241, 383, 94, 462, 53, 457, 282],
          fillColor: "yellow",
        },
        {
          name: "4",
          shape: "poly",
          coords: [245, 285, 290, 285, 274, 239, 249, 238],
          preFillColor: "red",
        },
        { name: "5", shape: "circle", coords: [170, 100, 25] },
      ],
    };
    return (
      <div>
        <div className="container">
          <ImageMapper
            src={URL}
            map={MAP}
            width={500}
            onLoad={() => this.load()}
            onClick={(area) => this.clicked(area)}
            onMouseEnter={(area) => this.enterArea(area)}
            onMouseLeave={(area) => this.leaveArea(area)}
            onMouseMove={(area, _, evt) => this.moveOnArea(area, evt)}
            onImageClick={(evt) => this.clickedOutside(evt)}
            onImageMouseMove={(evt) => this.moveOnImage(evt)}
          />
          {this.state.hoveredArea && (
            <span
              className="tooltip"
              style={{ ...this.getTipPosition(this.state.hoveredArea) }}
            >
              {this.state.hoveredArea && this.state.hoveredArea.name}
            </span>
          )}
        </div>

        {/* <img
          src="https://github.com/jamietre/ImageMapster/raw/master/examples/images/usa_map_720.png"
          usemap="#usa"
          style={{ width: "600px", height: "360px" }}
        /> */}

        {this.state.hoveredArea && (
          <span
            className="tooltip"
            style={{ ...this.getTipPosition(this.state.hoveredArea) }}
          >
            {this.state.hoveredArea && this.state.hoveredArea.name}
          </span>
        )}

        <pre className="message">{this.state.msg ? this.state.msg : null}</pre>
        <pre>{this.state.moveMsg ? this.state.moveMsg : null}</pre>
      </div>
    );
  }
}

export default Clickk;
