import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./Select";

class Additional extends Component {
  constructor(props) {
    super(props);
    this.state = { isHidden: true };
    this.toggleHie = this.toggleHie.bind(this);
  }

  toggleHidden() {
    this.setState({
      isHidden: false,
    });
  }

  toggleHie() {
    this.setState({ isHidden: true });
  }

  render() {
    const { t } = this.props;

    const options = [];
    for (var i = 1; i <= 20; i++) {
      const don = i;

      options.push(
        <option value={don} key={don}>
          {don}
        </option>
      );
    }
    return (
      <div>
        <div className="row">
          <div className="col-sm-8" style={{ marginTop: "10px" }}>
            <div className="form-group">
              <label id="p" for="exampleInputEmail1">
                {t("parameter_to_improve").toUpperCase()}
              </label>
              <select
                name="parameter"
                value={this.props.vall.parameter}
                onChange={this.props.loaddata}
                class="form-control donn"
              >
                <option value="" disabled hidden selected>
                  {t("choose")}
                </option>
                <option value="overall">{t("Summary_Score")}</option>
                <option value="speed">{t("Speeding")}</option>
                <option value="dynamics">{t("Dynamics")}</option>
                <option value="fuel">{t("Fuel")}</option>
              </select>
            </div>
          </div>
        </div>
        {/* Form End Here */}

        <label id="p" for="exampleInputEmail1">
          {t("duration").toUpperCase()}
        </label>
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-sm-3">
            <div className="custom-control custom-radio custom-control-inline">
              {" "}
              <input
                type="radio"
                placeholder="A Short Description"
                name="span"
                value="month"
                onChange={this.props.loaddata}
                onClick={this.toggleHie}
                id="r1"
                defaultChecked
              />
              <label htmlFor="r1" className="custom-control-label">
                {t("month").toUpperCase()}
              </label>
            </div>
          </div>

          <div className="col-sm-3">
            <div className="custom-control custom-radio custom-control-inline ">
              &nbsp;&nbsp;&nbsp;{" "}
              <input
                type="radio"
                placeholder="A Short Description"
                name="span"
                value="quarter"
                onChange={this.props.loaddata}
                onClick={this.toggleHie}
                id="r2"
              />
              <label htmlFor="r2" className="custom-control-label">
                {t("quarter").toUpperCase()}
              </label>
            </div>
          </div>

          <div className="col-sm-3">
            <div className="custom-control custom-radio custom-control-inline ">
              &nbsp;&nbsp;&nbsp;{" "}
              <input
                type="radio"
                placeholder="A Short Description"
                name="span"
                value="year"
                onChange={this.props.loaddata}
                onClick={this.toggleHie}
                id="r3"
              />
              <label htmlFor="r3" className="custom-control-label">
                {t("year").toUpperCase()}
              </label>
            </div>
          </div>

          <div className="col-sm-3">
            <div className="custom-control custom-radio custom-control-inline  ">
              &nbsp;&nbsp;&nbsp;{" "}
              <input
                type="radio"
                placeholder="A Short Description"
                name="span"
                value="week"
                onChange={this.props.loaddata}
                id="r4"
                onClick={this.toggleHidden.bind(this)}
              />
              <label htmlFor="r4" className="custom-control-label">
                {t("period").toUpperCase()}
              </label>
            </div>
          </div>
        </div>

        {/* End Here Row */}
        {/* Start Here Row */}
        <div className="row" style={{ marginTop: "10px" }}>
          {this.state.isHidden && (
            <div className="col-sm-6">
              <div className="form-group">
                <label id="p" for="exampleInputEmail1">
                  {t("choose_date")}
                </label>
                <input
                  type="date"
                  placeholder="A Short Description"
                  name="startDate"
                  value={this.props.vall.startDate}
                  onChange={this.props.loaddata}
                  className="form-control"
                />
              </div>
            </div>
          )}
          {!this.state.isHidden && (
            <div className="row" style={{ marginLeft: "20px" }}>
              <div className="col-md-6">
                <div className="form-group">
                  <label id="p" for="exampleInputEmail1">
                    {t("from")}
                  </label>
                  <input
                    type="date"
                    placeholder="A Short Description"
                    name="startDate"
                    value={this.props.vall.startDate}
                    onChange={this.props.loaddata}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label id="p" for="exampleInputEmail1">
                    {t("to")}
                  </label>
                  <input
                    type="date"
                    placeholder="A Short Description"
                    name="endDate"
                    value={this.props.vall.endDate}
                    onChange={this.props.loaddata}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Additional);
