// This related to Critical ,Speed and filter norm
export { default as CriticalMap } from "../Cars/Critical/CriticalMap";
export { default as SpeedFilter } from "../Cars/Filter/SpeedFilter";
export { default as FilterNorm } from "../Cars/Filter/FilterNorm";

// Electric Car Section
export { default as DailyView } from "../MainDashboard/Electric/Dailyview/DailyView";
export { default as ElectricCar } from "../MainDashboard/Electric/ElectricCar";
export { default as ElectrcDetails } from "../MainDashboard/Electric/ElectrcDetails";
export { default as Eprofile } from "../MainDashboard/Electric/Profile/Eprofile";
export { default as EDetails } from "../MainDashboard/Electric/Profile/EDetails";

export { default as CriticalOverSpeedingCar } from "../Driver/CriticalOverspee/CriticalOverSpeedingCar";

//Map link
export { default as Mapp } from "../Cars/Map/Map";
export { default as Mapp1 } from "../Cars/Map/MapDetails";

// Cost Link
export { default as Cost } from "../Cost/Cost";
export { default as CostDetail } from "../Cost/CostDetail";
export { default as UpdateInvoice } from "../Cost/UpdateInvoice";

// Campaign Link
export { default as Campaign } from "../Campaign/Campaign";
export { default as Create } from "../Campaign/Create";
export { default as CreateR } from "../Campaign/CreateCampaign";
export { default as Active } from "../Campaign/Detail/Active";
export { default as Data } from "../Campaign/Report/Data";
export { default as Edit } from "../Campaign/Edit";

// New Dashboard All used link is here
export { default as MyCar } from "../MainDashboard/MyCar/CarList/MyCar";
export { default as MyCarProfile } from "../MainDashboard/MyCar/Profile/CarProfile";
export { default as Cdetails } from "../MainDashboard/MyCar/CarList/Cdetails";
export { default as CarMetrics } from "../MainDashboard/MyCar/Metrics/CarMetrics";
export { default as EditCar } from "../Settings/Car/EditCar";
export { default as AddCar } from "../Settings/Car/AddCar";
export { default as Report } from "../Report/Car/Report";
