import React, { Component } from "react";
import Chart from "react-apexcharts";
import { withTranslation } from "react-i18next";

export class ScoreChart extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;

    this.state = {
      walk: [],
      isLoading: true,
      series: [
        {
          name: this.props.label,
          data: [this.props.inital].concat(this.props.chat.value),
        },
      ],

      options: {
        chart: {
          height: 350,
          type: "line",
        },
        dataLabels: {
          enabled: false,
        },

        labels: this.props.chat.xLabels.unshift("Start"),
        markers: {
          size: 5,
          colors: "#b71c1c",
        },
        xaxis: {
          type: "category",
          title: {
            offsetY: 10,
            text: t("date"),
            style: {
              fontSize: "10px",
              color: "#8D8D90",
            },
          },
          categories: this.props.chat.xLabels,

          labels: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
        },

        annotations: {
          yaxis: [
            {
              y: props.ongoing,
              borderColor:
                props.ongoing <= 4
                  ? "#b71c1c"
                  : `${
                      props.ongoing <= 6
                        ? "#f57c01"
                        : `${
                            props.ongoing <= 8
                              ? "#ffc105"
                              : `${props.ongoing <= 10}`
                              ? "#13b760"
                              : ""
                          }`
                    }`,
              borderWidth: 3,
              opacity: 1,
              label: {
                // borderColor: "#00E396",
                style: {
                  color: "#fff",
                  fontSize: "13px",
                  background:
                    props.ongoing <= 4
                      ? "#b71c1c"
                      : `${
                          props.ongoing <= 6
                            ? "#f57c01"
                            : `${
                                props.ongoing <= 8
                                  ? "#ffc105"
                                  : `${props.ongoing <= 10}`
                                  ? "#13b760"
                                  : ""
                              }`
                        }`,
                },
                text: "" + props.ongoing,
              },
            },
          ],
        },
        yaxis: {
          min: 0,
          max: 10,
          tickAmount: 5,
          title: {
            text: t("score"),

            style: {
              fontSize: "10px",
              color: "#8D8D90",
            },
          },
          labels: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
        },
        colors: ["#b71c1c"],
        stroke: {
          curve: "straight",
        },

        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
    };
  }

  render() {
    return (
      <div className="">
        <Chart
          style={{ marginTop: "22px" }}
          options={this.state.options}
          series={this.state.series}
          type="line"
          height="370"
        />
      </div>
    );
  }
}

export default withTranslation()(ScoreChart);
