import React, { Component } from "react";
import { Helmet } from "react-helmet";
import config from "../../../Main";
import ProfileTop from "../../../../Components/Layout/ProfileTop";
import { withTranslation } from "react-i18next";
import Spinner from "../../../../Container/Spinner";
import Search from "./Search";
import User from "../../../../Components/User";
import Datee from "../../../Views/Date";

class DriverMetrics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stats: [],
      value1: "select",
      value: "select",
      firstname: "",
      lastname: "",
      plate: "",
      phone: "",
      email: "",
      location: "",
      staus: "",
      isLoading: true,
      selectedItems: [],
      checkedBoxCheck: false,
    };
  }

  async onSubmitt1(e) {
    const res = await fetch(
      `${config.apiUrl.driver}?first_name__icontains=${this.state.firstname}&last_name__icontains=${this.state.lastname}&plate_number=${this.state.plate}&phone_number__icontains=${this.state.phone}&email__icontains=${this.state.email}&location__icontains=${this.state.location}&active=${this.state.staus}`,
      config.head
    );
    const stats = await res.json();
    this.setState({
      stats,
      isLoading: false,
    });
  }
  async onChange(e) {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.onSubmitt1("");
  }
  change(event) {
    this.setState({
      value1: event.target.value,
    });
  }

  componentDidMount() {
    this.onSubmitt1("");
  }

  async onItemSelect(row) {
    const selectedItems = this.state.selectedItems.slice(0);
    if (selectedItems.includes(row)) {
      selectedItems.splice(selectedItems.indexOf(row), 1);
    } else {
      selectedItems.push(row);
    }
    await this.setState({
      selectedItems,
    });
  }

  toggleSelectAll() {
    let selectedItems = [];
    var checkedBoxCheck = !this.state.checkedBoxCheck;
    if (checkedBoxCheck) {
      this.state.stats.forEach((x) => {
        selectedItems.push(x.id);
      });
    } else {
      selectedItems = [];
    }
    this.setState((prevState) => ({
      selectedItems,
      checkedBoxCheck,
    }));
  }

  async createCampaign(e) {
    e.preventDefault();
    this.props.history.push({
      pathname: "/driver/create/campaign/",
      state: {
        selectedItems: this.state.selectedItems,
      },
    });
  }

  render() {
    const { stats } = this.state;

    const { t } = this.props; //history
    const base = process.env.PUBLIC_URL;

    const color = (data) =>
      data <= 4
        ? "fourth"
        : `${
            data <= 6
              ? "third"
              : `${data <= 8 ? "second" : `${data <= 10}` ? "first" : ""}`
          }`;
    return (
      <div className="Main_section">
        <Helmet>
          <title>{t("DriverMetrics")}</title> <meta charSet="utf-8" />
          <meta
            name="Helmet application"
            content="Driver list with details including name, location, email phone and other details"
          />
        </Helmet>
        <div>
          &nbsp;
          <div
            className="row row1"
            style={{ width: "100%", marginTop: "-30px", marginBottom: "-40px" }}
          >
            {" "}
            <div className="col-sm-3 date_time">
              <strong>
                {t("Hello")}, <User />
              </strong>
              <Datee />
            </div>
          </div>
          <div className="float-right" style={{ marginTop: "68px" }}>
            <a
              className="btn"
              id="b"
              href={base + "/adddriver/app/drivermetrics/"}
            >
              + {t("add_driver")}
            </a>
          </div>
        </div>
        <ProfileTop />
        <div className="headerr" style={{ marginTop: "-6px" }}>
          <div>
            {stats.length > 0 ? (
              stats
                .slice(0, 1)
                .map((c) => <strong hidden>{c.participantCount}</strong>)
            ) : (
              <strong>&nbsp;&nbsp; {t("no_data")}</strong>
            )}
          </div>

          <div
            className="caaar caaar"
            style={{ marginTop: "0px", width: "100%" }}
          >
            {this.state.isLoading === true ? (
              <Spinner />
            ) : (
              <table className="table   table-hover float-left">
                <thead>
                  <tr>
                    {/* <th class="active">
                      <input
                        type="checkbox"
                        class="select-all checkbox"
                        name="first_name"
                        id="selectAll1"
                        checked={this.state.checkedBoxCheck}
                        onClick={this.toggleSelectAll.bind(this)}
                      />
                    </th> */}
                    <th style={{ width: "20px" }}>{t("Id")}</th>
                    <th>{t("Name")}</th>

                    <th>{t("plate_number")}</th>
                    <th>{t("phone")}</th>
                    <th>{t("email")}</th>
                    <th>{t("location")}</th>
                    <th className="">
                      <select
                        onChange={this.change.bind(this)}
                        value={this.state.value1}
                        style={{
                          top: "0px",
                          width: "130px",
                        }}
                        class="minimal"
                      >
                        <option value="1"> {t("OverallScore")} </option>
                        <option value="2">{t("OverallSpeed")} </option>
                        <option value="3"> {t("OverallDynamic")} </option>
                        <option value="4"> {t("OverallEfficiency")} </option>
                      </select>
                    </th>

                    <th>
                      <select
                        onChange={this.onChange.bind(this)}
                        className="minimal"
                        name="staus"
                        style={{ width: "130px", top: "0px" }}
                      >
                        <option value="">{t("status")}</option>
                        <option value="true">{t("active")}</option>
                        <option value="false">{t("disable")}</option>
                      </select>
                    </th>
                  </tr>
                </thead>

                <Search change={this.onChange.bind(this)} />

                <tbody>
                  {stats.map((c, i) => (
                    <tr key={c.id}>
                      {/* <td>
                        <input
                          type="checkbox"
                          checked={this.state.selectedItems.includes(c.id)}
                          className="checkbox"
                          name="selectOptions"
                          onClick={() => this.onItemSelect(c.id)}
                        />
                      </td> */}
                      <td>{i + 1}</td>

                      <td>
                        {" "}
                        <a
                          href={
                            base + "/overview/app/drivermetrics/" + c.id + "/"
                          }
                          style={{ color: "#b71c1c" }}
                          title="Click to view driver profile"
                          className="font-weight-bold"
                        >
                          {c.name ? `${c.name}` : " - "}
                        </a>
                      </td>
                      <td>
                        {" "}
                        <a
                          href={
                            base + "/overview/app/carmetrics/" + c.car_id + "/"
                          }
                          title="Click to view car profile"
                          style={{ color: "#b71c1c" }}
                          className="font-weight-bold"
                        >
                          {c.plate_number ? `${c.plate_number}` : " - "}{" "}
                        </a>
                      </td>
                      <td>{c.phone_number ? `${c.phone_number}` : " - "}</td>
                      <td>{c.email ? `${c.email}` : " - "}</td>
                      <td>{c.location ? `${c.location}` : " - "}</td>
                      <td>
                        <span
                          className={
                            this.state.value1 === "1"
                              ? color(c.overallscore)
                              : this.state.value1 === "2"
                              ? color(c.overallspeedscore)
                              : this.state.value1 === "3"
                              ? color(c.overalldynamicscore)
                              : this.state.value1 === "4"
                              ? color(c.overallefficienyscore)
                              : color(c.overallscore)
                          }
                          style={{ width: "35px" }}
                        >
                          {this.state.value1 === "1"
                            ? `${
                                c.overallscore
                                  ? `${Number(c.overallscore.toFixed(1))}`
                                  : 0.0
                              }`
                            : `${
                                this.state.value1 === "2"
                                  ? `${
                                      c.overallspeedscore
                                        ? `${Number(
                                            c.overallspeedscore.toFixed(1)
                                          )}`
                                        : 0.0
                                    }`
                                  : `${
                                      this.state.value1 === "3"
                                        ? `${
                                            c.overalldynamicscore
                                              ? `${Number(
                                                  c.overalldynamicscore.toFixed(
                                                    1
                                                  )
                                                )}`
                                              : 0.0
                                          }`
                                        : `${
                                            this.state.value1 === "4"
                                              ? `${
                                                  c.overallefficienyscore
                                                    ? `${Number(
                                                        c.overallefficienyscore.toFixed(
                                                          1
                                                        )
                                                      )}`
                                                    : 0.0
                                                }`
                                              : `${
                                                  c.overallscore
                                                    ? `${Number(
                                                        c.overallscore.toFixed(
                                                          1
                                                        )
                                                      )}`
                                                    : 0.0
                                                }`
                                          }`
                                    }`
                              }`}
                        </span>
                      </td>

                      <td>
                        <button
                          className={
                            c.status === "active"
                              ? "btn btn-success"
                              : "btn btn-danger"
                          }
                          id="dd"
                        >
                          {c.status}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(DriverMetrics);
