import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import config from "../../../Main";
import deletee from "../../../../img/delete.png";

class UsedDriver extends Component {
  constructor(props) {
    super(props);
    this.state = { driver: [] };
  }

  handleAddFields = () => {
    const values = this.props.vall;
    values.push({
      driver: "",
      since: null,
      until: null,
    });
    this.setState({
      values,
    });
  };

  async componentDidMount() {
    const res = await fetch(config.apiUrl.driver, config.head);
    const driver = await res.json();
    this.setState({
      driver,
    });
  }

  handleRemoveFields = (index) => {
    const values = this.props.vall;
    values.splice(index, 1);
    this.setState({
      values,
    });
  };

  async onChange(e, index) {
    if (["driver", "since", "until"].includes(e.target.name)) {
      let cats = [...this.props.vall];
      cats[index][e.target.name] = e.target.value;
      await this.setState({
        cats,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
    console.log(this.props.vall);
  }
  render() {
    const { t } = this.props;
    const { driver } = this.state;

    return (
      <div>
        {this.props.vall.map((inputField, index) => (
          <div className="row" key={`${inputField}~${index}`}>
            <div className="col-sm-4">
              <div className="form-group">
                <label id="p" htmlFor="Plate_number">
                  {t("Name").toUpperCase()}
                </label>
                <select
                  name="driver"
                  onChange={(e) => {
                    this.onChange(e, index);
                  }}
                  value={inputField.driver}
                  class="form-control donn"
                >
                  <option value="" disabled hidden selected>
                    {t("list")}
                  </option>
                  {driver.map((c) => (
                    <option value={c.id} key={c.id}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-sm-3">
              <div className="form-group">
                <label id="p" htmlFor="from">
                  {t("used_from").toUpperCase()}
                </label>
                <input
                  type="date"
                  value={inputField.since}
                  onChange={(e) => {
                    this.onChange(e, index);
                  }}
                  name="since"
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-sm-3">
              <div className="form-group">
                <label id="p" htmlFor="to">
                  {t("used_to").toUpperCase()}
                </label>
                <input
                  type="date"
                  value={inputField.until}
                  onChange={(e) => {
                    this.onChange(e, index);
                  }}
                  name="until"
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-sm-2">
              <button
                className="btn btn-danger"
                style={{
                  marginTop: "30px",
                  background: "none",
                  border: "none",
                }}
                type="button"
                onClick={() => this.handleRemoveFields(index)}
              >
                <img src={deletee} alt="Delete" />
              </button>
            </div>
          </div>
        ))}
        <a
          href="# "
          onClick={() => this.handleAddFields()}
          className="p font-weight-bolder"
          style={{ color: "#b71c1c" }}
        >
          {t("change_driver")}
        </a>
      </div>
    );
  }
}

export default withTranslation()(UsedDriver);
