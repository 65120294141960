import React, { Component } from "react";
import { Helmet } from "react-helmet";
import All from "./All";
import New from "./New";
import { getNotification1 } from "../../Service/Notification";
import paginate from "paginate-array";
import { withTranslation } from "react-i18next";
import "./Noti.css";

export class AllNoti extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      checkedBoxCheck: false,
      notification: [],
      search: "",
      size: 10,
      page: 1,
      isRead: true,
      currPage: null,
    };
    this.previousPage = this.previousPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
  }

  async getData() {
    getNotification1(this.state.search).then((res) => {
      const { page, size } = this.state;
      const currPage = paginate(res, page, size);
      this.setState({
        notification: res,
        currPage,
      });
    });
  }

  componentDidMount() {
    this.getData("");
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.state.notification) {
      this.getData();
    }
  }

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.getData("");
  };

  previousPage = () => {
    const { page, size, notification } = this.state;
    if (page > 1) {
      const newPage = page - 1;
      const newCurrPage = paginate(notification, newPage, size);
      this.setState({
        ...this.state,
        page: newPage,
        currPage: newCurrPage,
      });
    }
  };

  nextPage = () => {
    const { currPage, page, size, notification } = this.state;
    if (page < currPage.totalPages) {
      const newPage = page + 1;
      const newCurrPage = paginate(notification, newPage, size);
      this.setState({ ...this.state, page: newPage, currPage: newCurrPage });
    }
  };

  render() {
    const { t } = this.props;
    const { notification, currPage } = this.state;
    return (
      <div className="" style={{ width: "100%", padding: "50px" }}>
        <Helmet> All Notification Views</Helmet>

        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="home-tab"
              data-toggle="tab"
              href="#home"
              style={{ color: "#b71c1c" }}
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              {t("all")}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="profile-tab"
              data-toggle="tab"
              style={{ color: "#b71c1c" }}
              href="#profile"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              {t("NEW")}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="contact-tab"
              style={{ color: "#b71c1c" }}
              data-toggle="tab"
              href="#contactt"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
            >
              {t("ARCHIVE")}
            </a>
          </li>
        </ul>

        <div className="headerr" style={{ marginTop: "20px" }}>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <All
                notification={currPage}
                noti={notification}
                next={this.nextPage}
                state1={this.state}
                search={this.onChange}
                prev={this.previousPage}
              />
            </div>
            <div
              class="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <New
                notification={currPage}
                noti={notification}
                next={this.nextPage}
                prev={this.previousPage}
              />
            </div>
            <div
              class="tab-pane fade"
              id="contactt"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              ...
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AllNoti);
