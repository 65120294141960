const Color = (data) =>
  data <= 4
    ? "summary"
    : `${
        data <= 6
          ? "orange"
          : `${data <= 8 ? "dynamic" : `${data <= 10}` ? "speed" : ""}`
      }`;

export default Color;
