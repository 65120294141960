import React, { Component } from "react";
import { withTranslation } from "react-i18next";
export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        <nav class="navbar navbar-expand-lg navbar-light notification_bar">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
              <span class="nav-link">
                <input
                  type="checkbox"
                  class="select-all checkbox"
                  name="first_name"
                  id="selectAll1"
                  checked={this.props.state1}
                  onClick={this.props.check}
                />
              </span>
            </li>
            <li class="nav-item active">
              <a class="nav-link" href="# ">
                {t("Refresh")}
              </a>
            </li>
            <li class="nav-item active">
              <a class="nav-link" href="# ">
                {t("ARCHIVE")}
              </a>
            </li>
            <li class="nav-item active">
              <a class="nav-link " href="# " onClick={this.props.mark}>
                {t("Mark_as_read")}
              </a>
            </li>
            <li class="nav-item active" onClick={this.props.mark}>
              <a class="nav-link " href="# ">
                {t("Marks_as_unread")}
              </a>
            </li>
            <li class="nav-item active">
              <a class="nav-link " href="# ">
                |
              </a>
            </li>
            {this.props.state11 && this.props.state11.length > 0 && (
              <li class="nav-item active">
                <a class="nav-link " href="# " onClick={this.props.delete1}>
                  {t("delete")}
                </a>
              </li>
            )}
          </ul>
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <span class="nav-link disabled">
                1 - 10 of {this.props.len.length}
              </span>
            </li>
            <li class="nav-item active">
              <a
                class="nav-link  next"
                href="# "
                title="Previous"
                style={{ color: "#b71c1c" }}
                onClick={this.props.prev}
              >
                {" "}
                &#8249;
              </a>
            </li>
            <li class="nav-item active">
              <a
                class="nav-link next"
                title="Next"
                style={{ color: "#b71c1c" }}
                href="# "
                onClick={this.props.next}
              >
                {" "}
                &#8250;
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default withTranslation()(Header);
