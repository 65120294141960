import i18n from "i18next";
// import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./Container/language/en/en";
import pl from "./Container/language/pl/pl";
// import hi from "./Container/language/hi/hi";
// import fr from "./Container/language/fr/fr";
// import pt from "./Container/language/pt/pt";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en,
      pl,
      // hi,
      // fr,
      // pt,
    },
    fallbackLng: ["en", "pl"], //"fr", "pt"
    preload: ["en", "pl"], // "fr", "pt"
    whitelist: ["en", "pl"], //"fr", "pt"

    debug: true,
    ns: ["translations"], // If you want to use nested Json File then don't set this
    defaultNS: "translations", // If you want to use nested Json File then don't set this
    initImmediate: false,
    keySeparator: false,
    saveMissing: true, // send not translated keys to endpoint
    interpolation: {
      escapeValue: false,
      formatSeparator: ".",
    },
    react: {
      wait: true,
    },
  });
export default i18n;
