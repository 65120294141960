import React, { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

function Form({ loaddata }) {
  const [fromDate, setFromDate] = useState("");

  const [toDate, setToDate] = useState("");

  const assignFromDate = e => {
    console.log(e.target.value);
    setFromDate(e.target.value);
  };
  const { t } = useTranslation();
  return (
    <form onSubmit={loaddata}>
      <div className="row">
        <div className="col-sm-4">
          <div className="form-group">
            <span style={{ opacity: "0.6", fontSize: "10px" }}>
              {t("from")}
            </span>
            <input
              type="date"
              name="from"
              id="lol"
              value={fromDate}
              placeholder="Select Date"
              max={moment().format("YYYY-MM-DD")}
              onChange={assignFromDate}
              className="form-control "
              style={{ width: "150px" }}
            />
          </div>
        </div>
        <div className="col-sm-4">
          <div className="form-group">
            <span style={{ opacity: "0.6", fontSize: "10px" }}>{t("to")}</span>
            <input
              type="date"
              name="to"
              id="lol"
              min={fromDate}
              value={toDate}
              max={moment().format("YYYY-MM-DD")}
              placeholder="Select Date"
              onChange={e => setToDate(e.target.value)}
              className="form-control "
              style={{ width: "150px" }}
            />
          </div>
        </div>

        <button
          type="submit"
          value="Get Data"
          style={{ marginTop: "20px" }}
          className="btn  text-white font-weight-bolder  boxx1111 "
        >
          {t("showdata")}
        </button>
      </div>
    </form>
  );
}

export default Form;
