import React, { Component } from "react";
import Area from "./Area";
import "./Charger.css";
import { withTranslation } from "react-i18next";
import moment from "moment";

export class Charge extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHidden: [true],
    };
  }

  toggleHidden = (index) => {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <h6>{t("charging_station")}</h6>

        {this.props.station.length > 0 ? (
          <span>
            {this.props.station.map((c, i) => (
              <span>
                <ul class="timeline1" style={{ marginBottom: "-10px" }}>
                  <li>
                    <p className="someew">
                      {c.location}
                      <br></br>{" "}
                      <span className="timee">
                        <i>{c.startTime}</i>
                      </span>
                    </p>
                  </li>
                  <a
                    type="button"
                    class="btn charging "
                    href={`#collapseExample${i}`}
                    role="button"
                    data-toggle="collapse"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                    style={{ marginLeft: "10px" }}
                  >
                    <span class="faee-chevron-right"> {t("see_deatil")}</span>
                    <span class="faee-chevron-down">{t("hide_details")}</span>
                  </a>
                </ul>

                <div id={`collapseExample${i}`} class="collapse batter_info">
                  <h6>{t("charging_station_info")}</h6>
                  <div
                    className="row"
                    style={{ marginTop: "0px", marginLeft: "25px" }}
                  >
                    {c.charging_details.map((c, i) => (
                      <div className="col-md-6" key={i + 1}>
                        <div className=" n">
                          <p id="pppp">{c.label.toUpperCase()}</p>
                          <p className="pppp1">
                            {c.value ? `${c.value}` : "-"}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>

                  <Area load={c} />
                </div>
              </span>
            ))}
          </span>
        ) : (
          <ul class="timeline1">
            <li>
              <p className="someew">
                {t("Vechicle_not_charge")}(
                {moment(this.props.last.last_charging_date).format(
                  "DD-MM-YYYY"
                )}
                )
              </p>
            </li>
            <a
              href={`/dailyview/electric/${this.props.id}/${moment(
                this.props.last.last_charging_date
              ).format("YYYY-MM-DD")}/`}
              className="charging"
              style={{ color: "#b71c1c" }}
            >
              {t("last_charging")}
            </a>
          </ul>
        )}
      </div>
    );
  }
}

export default withTranslation()(Charge);
