import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header1 from "../../../Components/Layout/Header1";
import print from "../../../img/Rating/print.png";
import print_pl from "../../../img/Rating/print_pl.png";
import Search from "../../Cars/Search";
import { getSummary } from "../../../Service/Car";
import { getEv } from "../../../Service/EvCar";
import Filter from "../Filter";
// import arrow from "../../../img/arrow.png";
import DateGte from "../../../Service/Arrow/DateGte";
import DateLte from "../../../Service/Arrow/DateL";
import getPrint from "../../../Service/Arrow/Print";
import Spinner from "../../../Container/Spinner";
import User from "../../../Components/User";
import Datee from "../../Views/Date";
import { withTranslation } from "react-i18next";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);
// class Th extends React.Component {
//   handleClick = () => {
//     const { onClick, id } = this.props;
//     onClick(id);
//   };
//   render() {
//     const { value } = this.props;
//     return (
//       <th onClick={this.handleClick} className="t" id="hell1">
//         {value} <img src={arrow} alt="Arrow" style={{ height: "0.9em" }} />
//       </th>
//     );
//   }
// }

export class ElectricCar extends Component {
  constructor(props) {
    super(props);
    const today = moment();
    this.state = {
      sortBy: null,
      order: "ASC",
      newDriver: [],
      walk: [],
      currentSort: "default",
      value1: "select",
      speed: [],
      engine: "",
      lol: null,
      fol: null,
      isOpen: false,
      today: null,
      isLoading: true,

      value: moment.range(today.clone().subtract(0, "days"), today.clone()),
    };
  }

  onToggle = (event) => {
    if (event.target.value === "date") {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  change(event) {
    this.setState({
      value1: event.target.value,
    });
  }

  getData = async (value, states) => {
    this.setState({ isLoading: true });
    Promise.all([
      getEv(
        value.end.format("YYYY-MM-DD"),
        value.start.format("YYYY-MM-DD")
      ).then((data) => {
        this.setState({
          walk: data,
          lol: value.start,
          fol: value.end,
          isOpen: !this.state.isOpen,
          isLoading: false,
        });
      }),
      getSummary(
        value.end.format("YYYY-MM-DD"),
        value.start.format("YYYY-MM-DD")
      ).then((data) => {
        this.setState({
          speed: data,
        });
      }),
    ]);
  };

  getOptionData = async () => {
    this.setState({ isLoading: true });
    Promise.all([
      getEv(DateGte(this.state.today), DateLte(this.state.today)).then(
        (data) => {
          this.setState({ walk: data, isLoading: false });
        }
      ),
      getSummary(
        "BEV",
        DateGte(this.state.today),
        DateLte(this.state.today)
      ).then((data) => {
        this.setState({ speed: data });
      }),
    ]);
  };

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.getOptionData("");
  };

  componentDidMount() {
    this.getOptionData("");
  }

  handleSort = (id) => {
    this.setState((prev) => {
      const ordered = prev.walk.sort((a, b) =>
        prev.order === "ASC"
          ? a["span"][id]["value"] < b["span"][id]["value"]
          : a["span"][id]["value"] > b["span"][id]["value"]
      );
      return {
        sortBy: id,
        order: prev.order === "ASC" ? "DESC" : "ASC",
        walk: ordered,
      };
    });
  };

  render() {
    const { walk, speed } = this.state;
    const { t } = this.props;
    const base = process.env.PUBLIC_URL;
    const energy = walk.filter((player) => player.type.includes("BEV"));
    return (
      <div className="Main_section">
        <Helmet>
          <title> Electric Car </title>
          <meta
            name="Helmet application"
            content="Electric car list with details of driven mileage, fuel consumed, critical event, score name and more"
          />
        </Helmet>

        <div
          className="row row1"
          style={{ width: "100%", marginBottom: "-30px" }}
        >
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
          <div className="col-sm-6 date_time" style={{ marginTop: "-5px" }}>
            <Filter
              lol={this.state.lol}
              fol={this.state.fol}
              today={this.state.today}
              option={this.state.isOpen}
              toggle={this.onToggle}
              change={this.getData}
              hello={this.onChange}
            />
          </div>
        </div>

        <div style={{ marginTop: "-15px" }}>
          <Header1 />
        </div>

        <div className="headerr">
          <div className=" " style={{ width: "100%" }}>
            <span
              className="nav-item  float-right  "
              disabled
              style={{ display: "flex" }}
            >
              <Search /> &nbsp; &nbsp;
              <a href="# " onClick={getPrint}>
                <img
                  src={
                    window.localStorage.i18nextLng === "en" ? print : print_pl
                  }
                  alt="Print page"
                  style={{ marginTop: "-4px", height: "3em" }}
                />
              </a>
            </span>
          </div>
          <div
            className="caaar table-responsive"
            style={{ marginTop: "-40px", overflowX: "auto" }}
          >
            {this.state.isLoading === true ? (
              <Spinner />
            ) : (
              <table id="headerr" className="table tabell1 table-hover">
                <thead>
                  <tr>
                    <th> {t("Id")}</th>
                    <th className="">{t("plate_number")}</th>
                    <th className="t">{t("Milage")}</th>
                    <th className="t">{t("energy_cosumed")}</th>
                    <th className="t">{t("average_energy_cosumed")}</th>
                    <th className="t">{t("fuel_eq")}</th>
                    <th className="t">{t("energy_from_recuperation")}</th>
                    <th className="t">{t("driving_time")}</th>
                    <th className="t">{t("charging_time")}</th>
                    <th className="t">{t("number_of_charging")}</th>
                    <th className="t">{t("energy_from_charging")}</th>
                    <th className="t">{t("energy_from_ac")}</th>
                    <th className="t">{t("energy_from_dc")}</th>
                    <th className="t"></th>
                  </tr>
                </thead>
                <tbody>
                  {energy.length > 0 ? (
                    energy.map((c, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          <a
                            href={
                              base +
                              "/overview/app/profile/electric/" +
                              c.id +
                              "/"
                            }
                            className="font-weight-bold"
                            style={{ color: "#b71c1c" }}
                          >
                            {c.plate_number ? `${c.plate_number}` : " ? "}
                          </a>{" "}
                        </td>
                        <td>
                          {c.milage ? c.milage.toFixed(1) : "-"}
                          {/* {c.span.mileage.value
                        ? `${c.span.mileage.value.toFixed(1)}`
                        : "-"}{" "}
                      {arrowL(
                        c.span.mileage.changeDirection,
                        c.span.mileage.changePositive,
                        c.span.mileage.change
                      )} */}
                        </td>
                        <td>
                          {c.energy_consumed
                            ? c.energy_consumed.toFixed(1)
                            : "-"}{" "}
                        </td>
                        <td>
                          {c.average_energy_consumed
                            ? c.average_energy_consumed.toFixed(1)
                            : "-"}{" "}
                        </td>
                        <td> 0</td>
                        {/* {c.fue_eq ? c.fue_eq.toFixed(1) : "-"} */}
                        <td>
                          {c.energy_from_recuperation
                            ? c.energy_from_recuperation.toFixed(1)
                            : "-"}{" "}
                        </td>
                        <td>{c.driving_time ? c.driving_time : "-"} </td>
                        <td>{c.charging_time ? c.charging_time : "-"} </td>
                        <td>
                          {c.number_of_charging ? c.number_of_charging : "-"}{" "}
                        </td>
                        <td>
                          {c.energy_from_charging
                            ? c.energy_from_charging.toFixed(1)
                            : "-"}{" "}
                        </td>
                        <td>
                          {c.energy_from_ac_charging
                            ? c.energy_from_ac_charging.toFixed(1)
                            : "-"}{" "}
                        </td>
                        <td>
                          {c.energy_from_dc_charging
                            ? c.energy_from_dc_charging.toFixed(1)
                            : ""}{" "}
                        </td>
                        <td>
                          <a
                            href={base + "/Overview/app/electric/" + c.id + "/"}
                            className="font-weight-bold"
                            style={{ color: "#b71c1c" }}
                          >
                            {t("trips").toUpperCase()}
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <strong>&nbsp;&nbsp; {t("no_data")}</strong>
                  )}
                </tbody>
                {energy.length > 0 && (
                  <tfoot>
                    <tr className="thead sorttable_nosort">
                      <th className="sorttable_nosort"></th>

                      <th className="sorttable_nosort">
                        {" "}
                        {t("summary").toUpperCase()}
                      </th>

                      <th className="sorttable_nosort">
                        {speed.milage ? `${speed.milage.toFixed(1)}` : "-"}
                      </th>
                      <th className="sorttable_nosort">
                        {speed.energy_consumed
                          ? `${speed.energy_consumed.toFixed(1)}`
                          : "-"}
                      </th>

                      <th className="sorttable_nosort">
                        {speed.average_energy_consumed
                          ? `${speed.average_energy_consumed.toFixed(1)}`
                          : "-"}
                      </th>

                      <th className="sorttable_nosort">
                        {/* {speed.fuel_eq ? `${speed.fuel_eq.toFixed(1)}` : "-"} */}
                        0
                      </th>
                      <th className="sorttable_nosort">
                        {speed.energy_from_recuperation
                          ? `${speed.energy_from_recuperation}`
                          : "-"}{" "}
                      </th>
                      <th className="sorttable_nosort">
                        {speed.driving_time ? `${speed.driving_time}` : "-"}{" "}
                      </th>

                      <th className="sorttable_nosort">
                        {speed.charging_time ? `${speed.charging_time}` : "-"}{" "}
                      </th>

                      <th className="sorttable_nosort">
                        {speed.number_of_charging
                          ? `${speed.number_of_charging}`
                          : "-"}{" "}
                      </th>
                      <th className="sorttable_nosort">
                        {speed.energy_from_charging
                          ? `${speed.energy_from_charging.toFixed(1)}`
                          : "-"}{" "}
                      </th>
                      <th className="sorttable_nosort">
                        {speed.energy_from_ac_charging
                          ? `${speed.energy_from_ac_charging.toFixed(1)}`
                          : "-"}{" "}
                      </th>
                      <th className="sorttable_nosort">
                        {speed.energy_from_dc_charging
                          ? `${speed.energy_from_dc_charging.toFixed(1)}`
                          : "-"}{" "}
                      </th>
                      <th className="sorttable_nosort"></th>
                    </tr>
                  </tfoot>
                )}
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ElectricCar));
