import React from "react";
import { useTranslation } from "react-i18next";

function Add(props) {
  // const [swt, setSwt] = useState(true);
  const { t } = useTranslation();

  // const onClick = () => {
  //   setSwt((swt) => !swt);
  // };

  return (
    <div className="" style={{ marginTop: "30px" }}>
      <h3 className="font-weight-normal" style={{ marginTop: "30px" }}>
        {t("additonal_information")}
      </h3>
      <hr></hr>
      {props.add.length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th>
                {" "}
                <input
                  style={{ marginRight: "5px" }}
                  type="checkbox"
                  class="select-all checkbox"
                  name="first_name"
                  id="selectAll1"
                  //   checked={this.state.checkedBoxCheck}
                  //   onClick={this.toggleSelectAll.bind(this)}
                />
              </th>

              <th style={{ width: "10px" }}>{t("Id")}</th>
              <th style={{ width: "150px" }}>{t("information")}</th>
              <th>{t("Milage")}</th>
              <th>{t("date")}</th>
              <th>{t("Notification")}</th>
              <th>{t("expirtation_milage")}</th>
              <th>{t("expiration_date")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {props.add.map((c) => (
              <tr key={c.id}>
                <td>
                  {" "}
                  <input
                    style={{ marginRight: "5px" }}
                    type="checkbox"
                    class="select-all checkbox"
                    name="first_name"
                    id="selectAll1"
                    //   checked={this.state.checkedBoxCheck}
                    //   onClick={this.toggleSelectAll.bind(this)}
                  />
                </td>
                <td>{c.id}</td>
                <td>{c.information ? `${c.information}` : "-"}</td>
                <td>{c.mileage ? `${c.mileage}` : "-"} </td>
                <td>{c.date ? `${c.date}` : "-"}</td>
                <td>
                  {" "}
                  <span
                    style={{
                      marginTop: "-6px",
                    }}
                  >
                    <label class="switch1">
                      <input
                        type="checkbox"
                        id="togBtn"
                        checked={c.notification_by_mileage}
                        //onClick={onClick}
                        name="notification_by_mileage"
                      />
                      <div class="slider1 round"></div>
                    </label>
                  </span>
                </td>
                <td>
                  {c.notification_mileage ? `${c.notification_mileage}` : "-"}
                </td>
                <td>{c.notification_date ? `${c.notification_date}` : "-"}</td>
                <td>
                  <a
                    href="# "
                    title="Click here for Car map and data"
                    style={{ color: "#b71c1c" }}
                    className="font-weight-bold"
                  >
                    COMPLETE
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Add;
