import config from "../views/Main";
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "universal-cookie";
const cookie = new Cookies();
let authToken = cookie.get("Token");

export async function getCost(to, from, vin, plate, invoice, type) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl.cost}?date__lte=${to ? to : ""}&date__gte=${
        from ? from : ""
      }&car__vin__icontains=${vin ? vin : ""}&car__license_plate__icontains=${
        plate ? plate : ""
      }&invoice_number__icontains=${invoice ? invoice : ""}&cost_type=${
        type ? type : ""
      }`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}
export async function getCostDetail(pk) {
  let products = new Promise((resolve, reject) => {
    fetch(`${config.apiUrl.cost}${pk}/`, config.head)
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function getOption() {
  let products = new Promise((resolve, reject) => {
    fetch(config.apiUrl.cost, config.headOption)
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}

export async function AddCost(product) {
  return fetch(config.apiUrl.cost, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Language": window.localStorage.i18nextLng,
      Authorization: "Bearer " + authToken,
    },
    body: JSON.stringify(product),
  })
    .then((response) => {
      if (!response.ok) {
        Swal.fire({
          title: "Cost Added",
          type: "error",
          text: "While adding cost error!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        console.log("");

        window.location.reload();
        window.location.href = "/app/cost/";
      }

      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function updateCost(product, pk) {
  return fetch(`${config.apiUrl.cost}${pk}/`, {
    method: "put",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Language": window.localStorage.i18nextLng,
      Authorization: "Bearer " + authToken,
    },
    body: JSON.stringify(product),
  })
    .then((response) => {
      if (!response.ok) {
        Swal.fire({
          title: "Cost Updated",
          type: "error",
          text: "Error While Updating Cost !!",
          timer: 2000,
        });
      } else {
        Swal.fire({
          title: "Cost Updated",
          type: "success",
          text: "Cost Updated Successfully !",
          showConfirmButton: false,
          timer: 2000,
        });
        window.location.reload();
      }

      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function deleteCost(pk, direct) {
  let country = new Promise((resolve, reject) => {
    Swal.fire({
      title: "You won't be able to retrieve ? ",
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yea, Delete it",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        axios
          .delete(`${config.apiUrl.cost}${pk}/`, config.head)
          .then((response) => {
            if (response.ok) {
              Swal.fire({
                title: "Cost Deleted",
                type: "error",
                text: "Error While deleting cost ??",
                timer: 3000,
              });
            } else {
              Swal.fire({
                title: "Cost Deleted",
                type: "success",
                text: "Cost Deleted Successfully !!",
                showConfirmButton: false,
                timer: 2000,
              });

              window.location.reload();
              window.location.href = "/app/cost/";
            }
            resolve(response.json());
            // direct();
          })

          .catch((reject) => console.log(reject));
      }
    });
  });

  return country;
}
