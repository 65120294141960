import React, { Component } from "react";
import { withTranslation } from "react-i18next";
// import cross from "../../../../../../img/Cross.png";
import deletee from "../../../../../img/delete.png";
import config from "../../../../Main";
import Swal from "sweetalert2";
import axios from "axios";

let authToken = localStorage.getItem("Token");
class Dummy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      car_photo: [
        {
          category: "other",
          sub_category: "",
          file: null,
        },
      ],
    };
  }

  handleAddFields = () => {
    const values = this.state.car_photo;
    values.push({
      category: "other",
      sub_category: "",
      file: null,
    });
    this.setState({
      values,
    });
  };

  handleRemoveFields(index) {
    const values = this.state.car_photo;
    values.splice(index, 1);
    this.setState({
      values,
    });
  }
  async onChange(e, index) {
    if (["category", "sub_category"].includes(e.target.name)) {
      let cats = [...this.state.car_photo];
      cats[index][e.target.name] = e.target.value;
      await this.setState({
        cats,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  async handleImage(e, index) {
    let image = e.target.files[0];

    if (["file"].includes(e.target.name)) {
      let cats = [...this.state.car_photo];
      cats[index][e.target.name] = image;
      this.setState({
        cats,
      });
    } else {
      this.setState({
        [e.target.name]: image,
      });
    }

    console.log(this.state.blog_photo);
  }

  handleFormSubmit = (event) => {
    event.preventDefault();

    // const data = {
    //   car_photo: [...this.state.car_photo],
    // };
    let data = new FormData(); // creates a new FormData object
    this.state.car_photo.forEach((item) => {
      data.append("name", item.sub_category);
      data.append("category", item.category);
      data.append("file", item.file);
    });

    axios({
      method: "PUT",
      url: `${config.apiUrl.car}108/profile/file/`,
      headers: {
        Accept: "application/json",
        "Content-type": "multipart/form-data",
        "Content-Disposition": "attachment; filename=file",
        filename: "file",
        Authorization: "Bearer " + JSON.parse(authToken),
      },
      data,
    })
      .then((res) => {
        Swal.fire({
          title: "Car",
          type: "success",
          text: "Car Photo upload  !",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Car",
          type: "error",
          text: "Error while uploading car photo!",
          timer: 2000,
        });
      });
  };

  componentDidMount() {
    axios
      .get(`${config.apiUrl.car}108/profile/file/`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + JSON.parse(authToken),
        },
      })
      .then((res) => {
        this.setState({
          car_photo: [...res.data.car_photo],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { t } = this.props;

    return (
      <div id="other">
        <div className="file_upload" style={{ marginTop: "25px" }}>
          <form onSubmit={this.handleFormSubmit}>
            {this.state.car_photo.map((inputField, index) => (
              <div className="row">
                <div className="form-group">
                  <input
                    type="text"
                    name="sub_category"
                    class="form-control donn"
                    placeholder={t("type")}
                    value={inputField.sub_category}
                    onChange={(e) => {
                      this.onChange(e, index);
                    }}
                    style={{ width: "220px" }}
                  />
                </div>
                &nbsp; &nbsp;
                <div className="">
                  {inputField.file === null ? (
                    ""
                  ) : (
                    <a
                      className="btn btn-success btn_image"
                      href={inputField.file}
                      target="_blank"
                      rel="noopener noreferrer"
                      type="button"
                    >
                      View Image
                    </a>
                  )}
                </div>
                <div class="form-group">
                  <div class="upload-btn-wrapper Specila_Button">
                    <button class="btn112" style={{ fontSize: "14px" }}>
                      <i className="fa fa-upload"></i>{" "}
                      {inputField.file === null ? "Upload file" : "Change File"}{" "}
                    </button>
                    <input
                      type="file"
                      accept="image/*"
                      name="file"
                      onChange={(e) => {
                        this.handleImage(e, index);
                      }}
                    />
                  </div>
                </div>
                <button
                  className="btn btn-danger"
                  style={{
                    background: "none",
                    border: "none",
                    marginTop: "-20px",
                  }}
                  type="button"
                  onClick={() => this.handleRemoveFields(index)}
                >
                  <img src={deletee} alt="Delete" />
                </button>
              </div>
            ))}

            <a
              href="# "
              onClick={this.handleAddFields}
              className="p font-weight-bolder"
              style={{ color: "#b71c1c" }}
            >
              Add Photos
            </a>

            <div style={{ marginTop: "70px" }}>
              <center>
                <button
                  type="submit"
                  value="Get Data"
                  className="btn btn-login text-white font-weight-bolder boxx btn-vehicle"
                  id="button1"
                  style={{}}
                >
                  SAVE
                </button>
              </center>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Dummy);
