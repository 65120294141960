import React, { Component } from "react";
import Datee from "../Date";
import Form from "../Form";
import Header1 from "../../../Components/Layout/Header1";
import Driver from "./Graph/driver";
import Speed from "./Graph/Speed";
import Fuel from "./Graph/Fuel";
import User from "../../../Components/User";
import road from "../../../img/mileage.png";
import car from "../../../img/carr.png";
import fuel1 from "../../../img/fuel.png";
import speed11 from "../../../img/speed.png";
import hspeed from "../../../img/red.png";
import rating from "../../../img/tire.png";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import { ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getStats } from "../../../Service/Dashboard";

// var makedate = new Date().getMonth();

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      c: [],
      monthly: [],
      lol: null,
      fol: null,
      year: "",
    };
  }

  getData = async (e) => {
    const from = e.target.elements.from.value;
    const to = e.target.elements.to.value;
    e.preventDefault();

    getStats(to, from).then((data) => {
      this.setState({ c: data, lol: from, fol: to });
    });
  };

  componentDidMount() {
    getStats().then((data) => {
      this.setState({ c: data });
    });
  }
  entering = (e) => {
    e.children[0].style.borderTopColor = "black";
    e.children[1].style.color = "black";
    e.children[1].style.border = "1px solid black";
    e.children[1].style.background = "white";
  };
  render() {
    const { c, lol, fol } = this.state;
    const { t } = this.props;

    var d = new Date();
    var mm = d.getMonth() + 1;
    var dd = d.getDate();
    var yy = d.getFullYear();
    var cc = yy + "-" + mm + "-" + dd; //(US)

    return (
      <div className="containe Main_section">
        <Helmet>
          <title>Dashboard</title>
        </Helmet>

        <div className="row row1" style={{ width: "100%" }}>
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
          <div className="col-sm-6" style={{ marginTop: "-5px" }}>
            <Form loaddata={this.getData} />
          </div>
        </div>

        <div style={{ marginTop: "-20px" }}>
          <Header1 />

          <div className="headerr" style={{ width: "100%" }}>
            <div className="cont" style={{ marginTop: "0px", width: "100%" }}>
              <h4 className="text-left font-weight-bolder">{t("Summary1")}</h4>
              <div
                className="row justify-content-md-center"
                style={{ width: "100%" }}
              >
                {/* Start  Column 1 */}

                <div className="col-sm-2" style={{ marginTop: "5px" }}>
                  <div className="box">
                    <div className=" n">
                      <br></br>
                      <img
                        src={car}
                        alt="weight"
                        className="imag"
                        style={{ marginTop: "-29px" }}
                      />
                      <h5 style={{ marginTop: "1px" }}>
                        {c.vehicleCount ? `${c.vehicleCount}` : 0}
                      </h5>
                      <p id="p"> {t("Carc")} </p>
                    </div>
                  </div>
                </div>
                {/* End  Column 2 */}

                {/* Start  Column 2 */}
                <div className="col-sm-2" style={{ marginTop: "5px" }}>
                  <div className="box">
                    <div className=" n">
                      <br></br>
                      <img
                        src={road}
                        alt="weight"
                        className="imag"
                        style={{ marginTop: "-30px" }}
                      />
                      <h5 style={{ marginTop: "0px" }}>
                        {c.mileage ? `${c.mileage.toFixed(1)}` : 0} Km
                      </h5>
                      <p id="p">{t("TotalMilage")}</p>
                    </div>
                  </div>
                </div>

                {/* End  Column 2 */}

                {/* Start  Column 3 */}
                <div className="col-sm-2" style={{ marginTop: "5px" }}>
                  <div className="box">
                    <div className=" n">
                      <br></br>
                      <img
                        src={fuel1}
                        alt="weight"
                        className="imag"
                        style={{ marginTop: "-30px" }}
                      />
                      <h5 style={{ marginTop: "0px" }}>
                        {c.fuelSpent ? `${c.fuelSpent.toFixed(1)}` : 0} L
                      </h5>
                      <p id="p">{t("ConsumedFule")}</p>
                    </div>
                  </div>
                </div>

                {/* End  Column 3 */}

                {/* Start  Column 4 */}

                <div className="col-sm-2" style={{ marginTop: "5px" }}>
                  <div className="box" style={{ paddingBottom: "1px" }}>
                    <div className=" nn">
                      <br></br>
                      <img
                        src={speed11}
                        alt="weight"
                        className="imag"
                        style={{ marginTop: "-30px" }}
                      />
                      <h5 style={{ marginTop: "0px" }}>
                        {c.overspeedingPercentage
                          ? `${c.overspeedingPercentage.toFixed(1)}`
                          : 0}{" "}
                        %
                      </h5>
                      <p id="p">{t("DistanceSpeeding")}</p>
                    </div>
                  </div>
                </div>

                {/* End  Column 4 */}

                {/* Start  Column 5 */}
                <div className="col-sm-2" style={{ marginTop: "43px" }}>
                  <div className="box" style={{ paddingBottom: "1px" }}>
                    <div className="nn">
                      <ButtonToolbar className="float-right">
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          onEntering={this.entering}
                          overlay={
                            <Tooltip id="tooltip-topop">
                              {t("digram_text")}
                            </Tooltip>
                          }
                        >
                          <i className="fa fa-info-circle "></i>
                        </OverlayTrigger>
                      </ButtonToolbar>

                      <a
                        href={`/ddriver/my/driver/${
                          lol ? `${lol}` : "2016-01-04"
                        }/${fol ? `${fol}` : `${cc}`}`}
                      >
                        <img
                          src={hspeed}
                          alt="weight"
                          className="imag"
                          style={{ marginTop: "-38px", right: "-15px" }}
                        />
                      </a>
                      <h5 style={{ marginTop: "-7px" }}>
                        {c.criticalOverspeedingEvents
                          ? `${c.criticalOverspeedingEvents}`
                          : 0}
                      </h5>
                      <p id="p">{t("CriticalSpeeding")}</p>
                    </div>
                  </div>
                </div>

                {/* End  Column 5 */}

                {/* Start  Column 6 */}

                <div className="col-sm-2" style={{ marginTop: "5px" }}>
                  <div className="box" style={{ paddingBottom: "1px" }}>
                    <div className=" nn">
                      <br></br>
                      <img
                        src={rating}
                        alt="Star"
                        className="imag"
                        style={{
                          marginTop: "-15px",
                          height: "63px",
                          width: "63px",
                        }}
                      />
                      <h5 style={{ marginTop: "4px" }}>
                        {c.overallScore ? `${c.overallScore.toFixed(1)}` : 0}
                      </h5>
                      <p id="p">{t("AverageScore")}</p>
                    </div>
                  </div>
                </div>

                {/* End  Column 6 */}
              </div>

              <div
                className="row"
                style={{ marginTop: "100px", width: "100%" }}
              >
                <div className="col-md-4 col-sm-6">
                  <Driver fromm={this.state.lol} too={this.state.fol} />
                </div>

                <div className="col-md-4  col-sm-6">
                  <Speed fromm={this.state.lol} too={this.state.fol} />
                </div>

                <div className="col-md-4 col-sm-6 " style={{}}>
                  <Fuel />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Dashboard);
