import React from "react";

import { useTranslation } from "react-i18next";

function Search(props) {
  const { t } = useTranslation();
  return (
    <tbody>
      <td></td>
      <td>
        <input
          className="form-control finds"
          placeholder={t("search")}
          type="text"
          name="firstname"
          style={{ width: "70%", marginLeft: "-2%" }}
          onChange={props.change}
        />
      </td>
      <td>
        <input
          className="form-control finds"
          placeholder={t("search")}
          type="text"
          name="plate"
          style={{ width: "70%", marginLeft: "-2%" }}
          onChange={props.change}
        />
      </td>
      <td>
        <input
          className="form-control finds"
          placeholder={t("search")}
          type="text"
          name="phone"
          style={{ width: "70%", marginLeft: "-2%" }}
          onChange={props.change}
        />
      </td>
      <td>
        <input
          className="form-control finds"
          placeholder={t("search")}
          type="text"
          name="email"
          style={{ width: "70%", marginLeft: "-2%" }}
          onChange={props.change}
        />
      </td>
      <td>
        <input
          className="form-control finds"
          placeholder={t("search")}
          type="text"
          name="location"
          style={{
            width: "70%",
            marginLeft: "-2%",
            textAlign: "left",
          }}
          onChange={props.change}
        />
      </td>
    </tbody>
  );
}

export default Search;
