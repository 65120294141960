import React, { Component } from "react";
import Chart from "react-apexcharts";
import config from "../../../Main";
import { ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { getFuelMonth } from "../../../../Service/Dashboard";
import Spinner from "../../../../Container/Spinner";

const colors = ["#730202", "#F57C01", "#FF8C37", "#13B760", "#13B760"];
var d = new Date();
var mm = d.getMonth() + 1;
var dd = d.getDate();
var yy = d.getFullYear();
var cc = yy + "-" + mm + "-" + dd;

//var makemonth = new Date().getMonth();
const base = process.env.PUBLIC_URL;
class Fuel extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;

    this.state = {
      ...props,
      options: {
        chart: {
          id: "Speeding",
          events: {
            dataPointSelection: function (event, chartContext, opts) {
              switch (opts.w.config.xaxis.categories[opts.dataPointIndex]) {
                case "Much above norm":
                  window.open(
                    window.location.origin +
                      base +
                      `/filternorm/recommendation/my/car/10/1.3/${
                        this.props.state ? `${this.props.state}` : `${cc}`
                      }`
                  );
                  break;
                case "Above norm":
                  window.open(
                    window.location.origin +
                      base +
                      `/filternorm/recommendation/my/car/1.3/1.1/${
                        this.props.state ? `${this.props.state}` : `${cc}`
                      }`
                  );
                  break;
                case "Norm":
                  window.open(
                    window.location.origin +
                      base +
                      `/filternorm/recommendation/my/car/1.1/0.9/${
                        this.props.state ? `${this.props.state}` : `${cc}`
                      }`
                  );
                  break;
                case "Below norm":
                  window.open(
                    window.location.origin +
                      base +
                      `/filternorm/recommendation/my/car/0.9/0.7/${
                        this.props.state ? `${this.props.state}` : `${cc}`
                      }`
                  );
                  break;
                case "Much below norm":
                  window.open(
                    window.location.origin +
                      base +
                      `/filternorm/recommendation/my/car/0.7/-1.0/${
                        this.props.state ? `${this.props.state}` : `${cc}`
                      }`
                  );
                  break;
                default:
                  window.open(window.location.origin + base + "/car");
              }
            }.bind(this),
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "category",
          title: {
            text: t("x-axis-fuel"),
            style: {
              fontSize: "10px",
              color: "#8D8D90",
            },
          },
          labels: {
            style: {
              fontSize: "12px",
              color: "#8D8D90",
            },
          },
          categories: [],
        },

        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            title: {
              text: t("y-axios-speed"),
              style: {
                fontSize: "10px",
                color: "#8D8D90",
              },
            },
            labels: {
              formatter: function (value) {
                return value + " % ";
              },
              style: {
                fontSize: "10px",
                opacity: "0.2",
                color: "#8D8D90",
              },
            },
            axisBorder: {
              show: true,
              // color: "#b71c1c"
            },
          },
        ],

        colors: colors,
        plotOptions: {
          bar: {
            columnWidth: "70%",
            // endingShape: "rounded",
            distributed: true,
          },
        },

        title: {
          // text: "Speeding",
          align: "center",
          style: {
            fontSize: "20px",
            color: "black",
          },
        },
        labels: [],
        legend: {
          show: false,
        },
      },
      series: [
        {
          name: t("FuelEcomomy"),
          data: [],
        },
      ],
      fuel11: [],
      year: "",
      isLoading: true,
    };
  }

  fetchData() {
    this.setState({ isLoading: true });
    getFuelMonth(this.state.year ? this.state.year : cc).then((data) => {
      const newXaxis = data.labels;
      const newSeries = [];
      newSeries.push({
        data: data.data,
        name: "Fuel Economy",
      });
      this.setState({
        series: newSeries,
        isLoading: false,
        options: {
          ...this.state.options,
          labels: newXaxis,
          xaxis: { ...this.state.options.xaxis, categories: newXaxis },
        },
      });
    });
  }

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.fetchData("");
  };

  async fetchData1() {
    const res = await fetch(config.apiUrl.fuel_detected, config.head);
    const fuel11 = await res.json();
    this.setState({
      fuel11,
    });
  }
  componentDidMount() {
    this.fetchData();
    this.fetchData1();
  }

  entering = (e) => {
    e.children[0].style.borderTopColor = "white";
    e.children[1].style.color = "black";
    e.children[1].style.border = "1px solid black";
    e.children[1].style.backgroundColor = "white";
  };
  render() {
    const { t } = this.props;

    var makemonth = new Date().getMonth();
    var makeyear = new Date().getFullYear();
    const uniqueDates = [
      ...new Set(
        this.state.fuel11.map((i) => `${moment(i.datetime).format("YYYY-MM")}`)
      ),
    ];
    const reverse = uniqueDates.reverse();
    const options1 = [];
    for (var i = 0; i < reverse.length; i++) {
      const don = uniqueDates[i];

      options1.push(
        <option value={`${don}-01`} key={don}>
          {don}
        </option>
      );
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <h3 className="text-left font-weight-bolder">
              <ButtonToolbar className="">
                <OverlayTrigger
                  key="top"
                  onEntering={this.entering}
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-topop">{t("third_graph")}</Tooltip>
                  }
                >
                  <span>
                    {t("FuelEcomomy")}
                    <i className="fa fa-info-circle "></i>
                  </span>
                </OverlayTrigger>
              </ButtonToolbar>
            </h3>
          </div>
        </div>

        <div className="row" style={{ marginTop: "5px" }}>
          <div className="col-sm-5"> {t("choose_period")} &nbsp; </div>

          <div
            className="col-sm-4"
            style={{ marginTop: "-10px", marginLeft: "-9%" }}
          >
            <select
              name="year"
              className="form-control donn"
              onChange={this.onChange}
            >
              <option selected disabled hidden>
                {makeyear}-{makemonth}
              </option>
              {options1}
              <option value="2019-12-01">2019-12</option>
            </select>
          </div>
        </div>

        <div className="bo" style={{ marginTop: "17px" }}>
          {this.state.isLoading === true ? (
            <Spinner />
          ) : (
            <Chart
              style={{ marginTop: "22px" }}
              options={this.state.options}
              series={this.state.series}
              type="bar"
              height="250"
            />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Fuel);
