import React, { Component } from "react";
import Notification from "./Notification";
import head from "../../img/Logo1.png";
import setting from "../../img/setting.png";
import { withRouter } from "react-router-dom";
import config from "../../views/Main";
import profile from "../../img/Profiles.png";
import cash from "../../img/Cash.png";
import report from "../../img/Stats.png";
import trophy from "../../img/trophy.png";
import logout from "../../img/Log_out.png";
import dashboard from "../../img/rahul.png";
import insurance from "../../img/insurance.png";
import Language from "./Language";
import driver from "../../img/Profile_2.png";
import car from "../../img/Car.png";
import noti from "../../img/noti.png";
import alarm from "../../img/alarm1.png";
import recom from "../../img/Module.png";
import fuel from "../../img/fuel-can.png";
import recommend from "../../img/Rating/recommend.png";
import Cookies from "universal-cookie";
import "../../views/Views/OldDashboard/Graph/index";
import Feedback from "../../views/MainDashboard/Feedback/Feedback";
import DriverReport from "../../views/Report/DriverReport";
import CarReports from "../../views/Report/CarReports";
import AddFuelReport from "../../views/Report/Fuel/AddFuelReport";
import MonthlyReport from "../../views/Report/Fuel/MonthlyReport";
import { withTranslation } from "react-i18next";
import LogoutPopup from "../../views/Forget/LogoutPopup";
import { getRemoveToken } from "../../Service/Revoke";
import "./index";
import "./index.css";

const cookies = new Cookies();
export class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      movies: [],
      info: [],
      name: [],

      logged_in: localStorage.getItem("Token") ? true : false,
    };

    this.onLogout = this.onLogout.bind(this);
  }

  async componentDidMount() {
    const base = process.env.PUBLIC_URL;
    const res = await fetch(config.apiUrl.my, config.head);
    const name = await res.json();
    this.setState({
      name,
    });

    if (cookies.get("Token") === null || cookies.get("Token") === undefined) {
      localStorage.removeItem("passwordAge");
      this.cookies.remove("Token", { path: "/" });
      this.props.history.push(base + "/");
      window.location.reload(1);
    } else {
    }
  }

  onLogout() {
    getRemoveToken();
    const base = process.env.PUBLIC_URL;
    cookies.remove("Token", { path: "/" });
    localStorage.removeItem("passwordAge");
    this.props.history.push(base + "/");
    window.location.reload(1);
  }

  render() {
    const { loggedIn, t } = this.props;
    const { name } = this.state;
    const baseUrl = process.env.PUBLIC_URL;
    return (
      <div className="roww">
        <DriverReport />
        <CarReports />
        <AddFuelReport />
        <MonthlyReport />
        <Feedback />
        {loggedIn && (
          <nav className="navbar navbar-expand-lg navbar-dark bg-white fixed">
            {loggedIn && (
              <a
                className="navbar-brand"
                href={baseUrl + "/fleet/dashboard/"}
                style={{ color: "#212226" }}
              >
                <img
                  src={name.logo ? name.logo : head}
                  alt="MainImag"
                  style={{ height: "45px" }}
                ></img>{" "}
                &nbsp;{" "}
              </a>
            )}

            <button
              className="navbar-toggler"
              style={{ background: "#b71c1c" }}
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mx-auto">
                <li className="nav-item  btnn">
                  <a
                    className="nav-link a"
                    href={
                      window.location.pathname === baseUrl + "/my/car/" ||
                      window.location.pathname.includes(
                        baseUrl + "/overview/app/my/car/"
                      )
                        ? baseUrl + "/my/car/"
                        : window.location.pathname ===
                            baseUrl + "/my/driver/" ||
                          window.location.pathname.includes(
                            baseUrl + "/overview/app/my/driver/"
                          )
                        ? baseUrl + "/my/driver/"
                        : window.location.pathname === baseUrl + "/electric/" ||
                          window.location.pathname.includes(
                            baseUrl + "/Overview/app/electric/"
                          )
                        ? baseUrl + "/electric/"
                        : window.location.pathname === baseUrl + "/my/fleet/"
                        ? baseUrl + "/my/fleet/"
                        : window.location.pathname ===
                          baseUrl + "/firm/profile/"
                        ? baseUrl + "/firm/profile/"
                        : baseUrl + "/fleet/dashboard/"
                    }
                    style={{ color: "#212226", marginTop: "3px" }}
                  >
                    <img
                      src={dashboard}
                      alt="Dashboard"
                      className="setting"
                      style={{
                        height: "30px",
                        position: "fixed",
                        marginTop: "0px",
                      }}
                    />
                    <br></br>
                    {t("Dashboard1")}
                  </a>
                </li>

                <li className="nav-item  btnn">
                  <a
                    className="nav-link a"
                    href={
                      window.location.pathname === baseUrl + "/carmetrics/" ||
                      window.location.pathname.includes(
                        baseUrl + "/overview/app/carmetrics/"
                      )
                        ? baseUrl + "/carmetrics/"
                        : window.location.pathname ===
                            baseUrl + "/profile/electric/" ||
                          window.location.pathname.includes(
                            baseUrl + "/overview/app/profile/electric/"
                          )
                        ? baseUrl + "/profile/electric/"
                        : window.location.pathname ===
                          baseUrl + "/drivermetrics/"
                        ? baseUrl + "/drivermetrics/"
                        : window.location.pathname === baseUrl + "/data/comp/"
                        ? baseUrl + "/data/comp/"
                        : baseUrl + "/drivermetrics/"
                    }
                    style={{ color: "#212226", marginTop: "3px" }}
                  >
                    <img
                      src={profile}
                      alt="profile"
                      style={{
                        marginLeft: "-3px",
                        height: "40px",
                        position: "fixed",
                        marginTop: "-15px",
                      }}
                    />
                    <br></br>
                    {t("Profiles")}
                  </a>
                </li>

                {/* Ev Recommend Page */}
                <li className="nav-item  btnn">
                  <a
                    className="nav-link a"
                    href={
                      window.location.pathname ===
                      baseUrl + "/recommendation/driver/"
                        ? baseUrl + "/recommendation/driver/"
                        : window.location.pathname ===
                          baseUrl + "/recommendation/costs/"
                        ? baseUrl + "/recommendation/costs/"
                        : window.location.pathname ===
                          baseUrl + "/ecommendation/engagement/"
                        ? baseUrl + "/ecommendation/engagement/"
                        : window.location.pathname ===
                          baseUrl + "/recommendation/mobility/"
                        ? baseUrl + "/recommendation/mobility/"
                        : window.location.pathname ===
                          baseUrl + "/recommendation/ecosystem/"
                        ? baseUrl + "/recommendation/ecosystem/"
                        : window.location.pathname ===
                            baseUrl + "/ev/recommendation/" ||
                          window.location.pathname.includes(
                            baseUrl + "/profile/ev/recommendation/"
                          )
                        ? baseUrl + "/ev/recommendation/"
                        : window.location.pathname === baseUrl + "/on/progress/"
                        ? baseUrl + "/on/progress/"
                        : baseUrl + "/ev/recommendation/"
                    }
                    style={{ color: "#212226", marginTop: "3px" }}
                  >
                    <img
                      src={recommend}
                      alt="recommend"
                      className="setting"
                      style={{
                        height: "30px",
                        position: "fixed",
                        marginTop: "0px",
                      }}
                    />
                    <br></br>
                    {t("Recommendation1")}
                  </a>
                </li>

                {/* This Button For Costs */}

                <li className="nav-item  btnn">
                  <a
                    className="nav-link a"
                    href={baseUrl + "/cost/"}
                    style={{ color: "#212226", marginTop: "3px" }}
                  >
                    <img
                      src={cash}
                      alt="cash"
                      className="setting"
                      style={{
                        height: "40px",
                        position: "fixed",
                        marginTop: "-3px",
                      }}
                    />
                    <br></br>
                    {t("Costs")}
                  </a>
                </li>

                {/* This Button For Campaign */}

                <li className="nav-item btnn " style={{ marginTop: "5px" }}>
                  <a
                    className="nav-link a"
                    href={
                      window.location.pathname ===
                        baseUrl + "/planned/campaign/" ||
                      window.location.pathname.includes(
                        baseUrl + "/overview/profile/electric/"
                      )
                        ? baseUrl + "/planned/campaign/"
                        : window.location.pathname ===
                          baseUrl + "/finished/campaign/"
                        ? baseUrl + "/finished/campaign/"
                        : window.location.pathname ===
                          baseUrl + "/active/campaign/"
                        ? baseUrl + "/active/campaign/"
                        : baseUrl + "/active/campaign/"
                    }
                    style={{ color: "#212226" }}
                  >
                    <img
                      src={trophy}
                      alt="Trophy"
                      className="setting"
                      style={{ height: "23px" }}
                    />
                    <br></br>
                    {t("Campaigns")}
                  </a>
                </li>

                <li className="nav-item btnn " style={{ marginTop: "5px" }}>
                  <a
                    className="nav-link a"
                    href={
                      window.location.pathname ===
                      baseUrl + "/insurance/carmetrics/"
                        ? baseUrl + "/insurance/carmetrics/"
                        : window.location.pathname ===
                            baseUrl + "/claim/insurance/" ||
                          window.location.pathname ===
                            baseUrl + "/add/app/claim/insurance/" ||
                          window.location.pathname.includes(
                            baseUrl + "/view/app/claim/insurance/"
                          ) ||
                          window.location.pathname.includes(
                            baseUrl + "/edit/app/claim/insurance/"
                          )
                        ? baseUrl + "/claim/insurance/"
                        : window.location.pathname ===
                          baseUrl + "/company/profile/"
                        ? baseUrl + "/company/profile/"
                        : window.location.pathname === baseUrl + "/risk/" ||
                          window.location.pathname.includes(
                            baseUrl + "/overall/risk/"
                          )
                        ? baseUrl + "/risk/"
                        : baseUrl + "/company/profile/"
                    }
                    style={{ color: "#212226" }}
                  >
                    <img
                      src={insurance}
                      alt="Insurance data"
                      className="setting"
                      style={{ height: "23px" }}
                    />
                    <br></br>
                    {t("Insurance")}
                  </a>
                </li>

                {/* Report Section */}

                <li class="nav-item dropdown">
                  <a
                    className="nav-link  a"
                    href="# "
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ color: "#212226", marginTop: "3px" }}
                  >
                    <img
                      src={report}
                      alt="Report"
                      className="setting"
                      style={{
                        height: "35px",
                        position: "fixed",
                        marginTop: "0px",
                      }}
                    />
                    <br></br>
                    {t("Report")}
                  </a>
                  <ul
                    class="dropdown-menu not"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <a
                        className="dropdown-item"
                        href="#driver"
                        rel="modal:open"
                      >
                        &nbsp; &nbsp;{" "}
                        <img
                          src={driver}
                          alt="Driver Metrics"
                          style={{ width: "28px", marginLeft: "-3px" }}
                        />
                        &nbsp;&nbsp; &nbsp;&nbsp; {t("driver_report")}
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#car" rel="modal:open">
                        &nbsp;{" "}
                        <img
                          src={car}
                          alt="Driver Metrics"
                          style={{ height: "18px" }}
                        />
                        &nbsp;&nbsp; &nbsp; {t("car_report")}
                      </a>
                    </li>
                    <li class="dropdown-submenu">
                      <a
                        className="dropdown-item dropdown-toggle test"
                        href="# "
                      >
                        &nbsp; &nbsp;{" "}
                        <img
                          src={fuel}
                          alt="Fuel Tank"
                          style={{ width: "28px", marginLeft: "-3px" }}
                        />
                        &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; {t("fuel_report")}
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <a
                            class="dropdown-item"
                            href="#montly"
                            rel="modal:open"
                          >
                            {t("Add_data_from_fuel")}
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href="#fuel"
                            rel="modal:open"
                          >
                            {t("monthly_report")}
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                {/* This Button For Notification */}
                {loggedIn && <Notification />}

                {/* Alarm Section */}
                <li className="nav-item  btnn" style={{ marginTop: "2px" }}>
                  <a
                    className="nav-link a"
                    href="# "
                    style={{ color: "#212226", marginTop: "3px" }}
                  >
                    <img
                      src={alarm}
                      alt="Driver Metrics"
                      style={{ width: "20px", marginLeft: "8px" }}
                    />
                    <br></br>
                    {t("Alerts")}
                  </a>
                </li>
              </ul>
              <LogoutPopup />
              <ul className="navbar-nav">
                {/* This Button For Setting */}

                {loggedIn && (
                  <li
                    className="nav-item dropdown  btnn"
                    style={{ marginRight: "10px" }}
                  >
                    <a
                      className="nav-link  a"
                      href="# "
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{ color: "#212226" }}
                    >
                      <img
                        src={setting}
                        alt="Setting"
                        style={{
                          height: "20px",
                        }}
                        className="setting"
                      />
                      <br></br>
                      <span style={{ marginTop: "-30px" }}>
                        {t("Settings")}
                      </span>
                    </a>
                    <div
                      className="dropdown-menu no "
                      aria-labelledby="navbarDropdown"
                    >
                      <a
                        className="dropdown-item"
                        href={baseUrl + "/notification/"}
                      >
                        &nbsp;{" "}
                        <img
                          src={noti}
                          alt="Driver Metrics"
                          style={{ width: "20px" }}
                        />
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                        {t("NoticiactioManage")}
                      </a>

                      <a
                        className="dropdown-item"
                        href="#feedback"
                        rel="modal:open"
                      >
                        &nbsp;{" "}
                        <img
                          src={recom}
                          alt="Driver Metrics"
                          style={{ width: "25px" }}
                        />
                        &nbsp; &nbsp; &nbsp; &nbsp; Send feedback
                      </a>
                      <a
                        className="dropdown-item"
                        href={baseUrl + "/change/password/"}
                      >
                        &nbsp;{" "}
                        <img
                          src={recom}
                          alt="Driver Metrics"
                          style={{ width: "25px" }}
                        />
                        &nbsp; &nbsp; &nbsp; &nbsp; {t("change_password")}
                      </a>
                      <a
                        className="dropdown-item"
                        href="# "
                        onClick={this.onLogout}
                      >
                        <img
                          src={logout}
                          alt="Logout"
                          style={{ width: "35px" }}
                        />
                        &nbsp; &nbsp; &nbsp; &nbsp; {t("Logout")}
                      </a>
                    </div>
                  </li>
                )}
                <Language />
              </ul>
            </div>
          </nav>
        )}
      </div>
    );
  }
}

export default withTranslation()(withRouter(Header));
