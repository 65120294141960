import React, { Component } from "react";
import Chart from "react-apexcharts";
import { getDriverDaily } from "../../../../Service/Driver";
import { withTranslation } from "react-i18next";
import cross from "../../../../img/Cross.png";
import Spinner from "../../../../Container/Spinner";

export class LineChartt extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;

    this.state = {
      walk: [],
      isLoading: true,
      series: [
        {
          name: t("score"),
          data: [],
        },
      ],

      options: {
        chart: {
          height: 350,
          type: "line",
        },
        dataLabels: {
          enabled: false,
        },
        annotations: {
          yaxis: [
            {
              y: props.score_on,
              borderColor:
                props.score_on <= 4
                  ? "#b71c1c"
                  : `${
                      props.score_on <= 6
                        ? "#f57c01"
                        : `${
                            props.score_on <= 8
                              ? "#ffc105"
                              : `${props.score_on <= 10}`
                              ? "#13b760"
                              : ""
                          }`
                    }`,
              borderWidth: 3,
              opacity: 1,
              label: {
                // borderColor: "#00E396",
                style: {
                  color: "#fff",
                  fontSize: "13px",
                  background:
                    props.score_on <= 4
                      ? "#b71c1c"
                      : `${
                          props.score_on <= 6
                            ? "#f57c01"
                            : `${
                                props.score_on <= 8
                                  ? "#ffc105"
                                  : `${props.score_on <= 10}`
                                  ? "#13b760"
                                  : ""
                              }`
                        }`,
                },
                text: "" + props.score_on,
              },
            },
          ],
        },

        labels: [],
        markers: {
          size: 5,
          colors: "#b71c1c",
        },
        xaxis: {
          type: "category",
          title: {
            offsetY: 10,
            text: t("date"),
            style: {
              fontSize: "10px",
              color: "#8D8D90",
            },
          },
          categories: [],

          labels: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
        },
        yaxis: {
          min: 0,
          max: 10,
          tickAmount: 5,
          title: {
            text: t("score"),

            style: {
              fontSize: "10px",
              color: "#8D8D90",
            },
          },
          labels: {
            show: true,
          },
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
        },
        colors: ["#b71c1c"],
        stroke: {
          curve: "straight",
        },

        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
    };
  }

  getOptionData = () => {
    const { t } = this.props;
    getDriverDaily(
      this.props.driver ? this.props.driver : 151,
      this.props.to ? this.props.to : "",
      this.props.from ? this.props.from : ""
    ).then((data) => {
      const newXaxis = data.map((c) => c.date).reverse();
      const newData = data
        .map((c) =>
          this.props.score === "overall score" || "Ocena ogólna"
            ? c.overallscore
            : this.props.score === "speed score" || "Ocena prędkości"
            ? c.overallspeedscore
            : this.props.score === "dynamics" || "Ocena dynamiki"
            ? c.overalldynamicscore
            : c.overallefficienyscore
        )
        .reverse();

      const newData1 = newData.unshift(this.props.initial_score);
      const newXaxis1 = newXaxis.unshift("Start");
      console.log("data", newData1, newXaxis1);
      console.log("Reverse", newXaxis);
      const newSeries = [];
      newSeries.push({
        data: newData,
        name: t("score"),
      });

      this.setState({
        walk: data,
        isLoading: false,
        series: newSeries,
        options: {
          ...this.state.options,
          labels: newXaxis,
          xaxis: {
            ...this.state.options.xaxis,
            categories: newXaxis,
          },
        },
      });
    });
  };

  componentDidMount() {
    this.getOptionData("");
  }

  componentDidUpdate(prevProps) {
    if (this.state.walk === prevProps.userID) {
      this.getOptionData("");
    }
  }

  render() {
    // const { t } = this.props;
    return (
      <div
        id={"campaign_chart" + this.props.id}
        className="modal campaign_chart"
      >
        <a href="# " rel="modal:close" className="float-right text-dark ">
          <img src={cross} alt="Cross" style={{ height: "1.1em" }} />
        </a>
        <p className="mod font-weight-normal" style={{ marginTop: "10px" }}>
          Ocena ogólna{" "}
          <strong>{this.state.walk.slice(0, 1).map((c) => c.name)}</strong>
        </p>
        {this.state.isLoading ? (
          <Spinner />
        ) : (
          <Chart
            style={{ marginTop: "22px" }}
            options={this.state.options}
            series={this.state.series}
            type="line"
            height="370"
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(LineChartt);
