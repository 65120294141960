import React, { Component } from "react";
import "./index.css";
import "./active.js";
import { withTranslation } from "react-i18next";

class Header1 extends Component {
  render() {
    const { t } = this.props;
    const baseUrl = process.env.PUBLIC_URL;
    return (
      <nav
        className="navbar navbar-expand-lg navbar-dark  "
        style={{ marginLeft: "-15px", marginBottom: "-7px" }}
      >
        <button
          className="navbar-toggler mobile_header_1"
          style={{ background: "#b71c1c" }}
          type="button"
          data-toggle="collapse"
          data-target="#nnavbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="nnavbarSupportedContent">
          <ul className="navbar-nav mr-auto main_header first_header ">
            <li className="nav-item btnnn">
              <a
                className="nav-link font-weight-bold ra "
                href={baseUrl + "/fleet/dashboard/"}
                style={{ color: "rgba(183, 28, 28, 1)", marginTop: "20px" }}
              >
                <span style={{ lineHeight: "9px" }}>{t("Dashboard")}</span>
              </a>
            </li>

            <li className="nav-item btnnn">
              <a
                className="nav-link font-weight-bold ra "
                href={baseUrl + "/my/fleet/"}
                style={{ color: "rgba(183, 28, 28, 1)", marginTop: "20px" }}
              >
                <span style={{ lineHeight: "9px" }}> {t("my_fleet")}</span>
              </a>
            </li>

            <li className="nav-item btnnn  ">
              <a
                className="nav-link font-weight-bold ra "
                href={baseUrl + "/my/driver/"}
                style={{ color: "rgba(183, 28, 28, 1)", marginTop: "20px" }}
              >
                {t("my_driver")}
              </a>
            </li>

            <li className="nav-item btnnn  ">
              <a
                className="nav-link font-weight-bold ra "
                href={baseUrl + "/my/car/"}
                style={{ color: "rgba(183, 28, 28, 1)", marginTop: "20px" }}
              >
                {t("my_car")}
              </a>
            </li>

            <li className="nav-item  btnnn ">
              <a
                className="nav-link font-weight-bold ra  "
                href={baseUrl + "/electric/"}
                style={{ color: "rgba(183, 28, 28, 1)", marginTop: "20px" }}
              >
                {t("electric_car")}
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default withTranslation()(Header1);
