import React, { Component } from "react";
import Chart from "react-apexcharts";
import { withTranslation } from "react-i18next";
import Color from "../Color";

const colors = ["#ff6d43", "#f9c45c"];
export class Zone extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      options: {
        chart: {
          type: "donut",
        },
        labels: [t("country"), t("abroad")],
        dataLabels: {
          enabled: false,
        },
        colors: colors,
        title: {
          align: "center",
          style: {
            fontSize: "17px",
            color: "black",
          },
        },
        tooltip: {
          y: {
            formatter: (value) => {
              return value + "%";
            },
          },
        },

        legend: {
          position: "bottom",
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
          },
        },
      },
      series: [],
    };
  }

  render() {
    const fun = this.props.load;
    const dat1 = fun.map((c) => {
      return c.data;
    });
    const { t } = this.props;
    return (
      <div className="row">
        <div className="col-sm-4">
          <h5
            className="text-left font-weight-normal"
            style={{ marginLeft: "26px", marginTop: "10px" }}
          >
            {t("zone")}
          </h5>
          <Chart
            style={{ marginTop: "22px" }}
            options={this.state.options}
            series={dat1}
            type="donut"
            stacked="true"
            height="250"
          />
        </div>

        <div className="col-sm-8" style={{ marginTop: "20px" }}>
          <table className="table">
            <thead>
              <tr>
                <th className="th_line">{t("zone")}</th>
                <th className="th_line">{t("percentage")}</th>
                <th className="th_line">{t("OverallScore")}</th>
                <th className="th_line">{t("OverallSpeed")}</th>
                <th className="th_line"> {t("OverallDynamic")}</th>
                <th className="th_line">{t("OverallEfficiency")}</th>
              </tr>
            </thead>
            <tbody>
              {fun.length > 0 ? (
                fun.map((c, i) => (
                  <tr key={i + 1}>
                    <td>{c.label}</td>
                    <td>{c.data} %</td>
                    <td className="lom">
                      <span className={Color(c.overall_score)}>
                        {c.overall_score
                          ? `${c.overall_score.toFixed(1)}`
                          : "-"}
                      </span>
                    </td>
                    <td className="lom">
                      <span className={Color(c.speed_score)}>
                        {c.speed_score ? `${c.speed_score.toFixed(1)}` : "-"}
                      </span>
                    </td>
                    <td className="lom">
                      <span className={Color(c.dynamics_score)}>
                        {c.dynamics_score
                          ? `${c.dynamics_score.toFixed(1)}`
                          : "-"}
                      </span>
                    </td>
                    <td className="lom">
                      <span className="summary">
                        {/* {c.fuel_score ? `${c.fuel_score.toFixed(1)}` : "-"} */}
                        -
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <p className="text-center">No Data Available in this table</p>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Zone);
