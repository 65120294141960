import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import "./index.css";
import "./active.js";

class Recommendation extends Component {

  render() {

    const baseUrl = process.env.PUBLIC_URL;
    const { t } = this.props;
    return (
      <nav
        className="navbar navbar-expand-lg navbar-dark  "
        style={{ position: "relative", left: "-0.7%", marginBottom: "-7px" }}
      >
        <button
          className="navbar-toggler"
          style={{ background: "#13B760" }}
          type="button" 
          data-toggle="collapse"
          data-target="#nnavbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="nnavbarSupportedContent">
          <ul className="navbar-nav mr-auto main_header ">
            <li className="nav-item  btnnn ">
              <a
                className="nav-link font-weight-bold ra  "
                href={baseUrl + "/ev/recommendation/"}
                style={{ color: "rgba(183, 28, 28, 1)", marginTop: "20px" }}
              >
                {t("ev_recommend")}
              </a>
            </li>
            <li className="nav-item btnnn  ">
              <a
                className="nav-link font-weight-bold ra "
                disabled
                href={baseUrl + "/recommendation/driver/"}
                style={{ color: "rgba(183, 28, 28, 1)", marginTop: "20px" }}
              >
                {t("driver_car_recommendation")}
              </a>
            </li>
            <li className="nav-item btnnn  ">
              <a
                className="nav-link font-weight-bold ra "
                href={baseUrl + "/recommendation/costs/"}
                disabled
                style={{ color: "rgba(183, 28, 28, 1)", marginTop: "20px" }}
              >
                {t("Costs")}
              </a>
            </li>

            <li className="nav-item  btnnn ">
              <a
                className="nav-link font-weight-bold ra  "
                href={baseUrl + "/ecommendation/engagement/"}
                style={{ color: "rgba(183, 28, 28, 1)", marginTop: "20px" }}
              >
                {t("engagement_recommendation")}
              </a>
            </li>

            <li className="nav-item  btnnn ">
              <a
                className="nav-link font-weight-bold ra  "
                href={baseUrl + "/recommendation/mobility/"}
                style={{ color: "rgba(183, 28, 28, 1)", marginTop: "20px" }}
              >
                {t("mobility_recommendation")}
              </a>
            </li>
            <li className="nav-item  btnnn ">
              <a
                className="nav-link font-weight-bold ra  "
                href={baseUrl + "/recommendation/ecosystem/"}
                style={{ color: "rgba(183, 28, 28, 1)", marginTop: "20px" }}
              >
                {t("ecosystem_recommendation")}
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default withTranslation()(withRouter(Recommendation));
