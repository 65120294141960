import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Campaign from "../../../Components/Layout/Campaign";
import User from "../../../Components/User";
import Datee from "../../Views/Date";
import edit from "../../../img/edit.png";
import arrow_left from "../../../img/Aarrow.png";
import deletee from "../../../img/delete.png";
import ReactToExcel from "react-html-table-to-excel";
import { withTranslation } from "react-i18next";
import { getCampaignDetail, deleteCampaign } from "../../../Service/Campaign";
import Spinner from "../../../Container/Spinner";
import LineChartt from "./Chart/LineChartt";
import "./Chart/Style.css";
import moment from "moment";
import Model from "./Chart/Model";

class Active extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true,
      active: [],
      prize: [],
      confirmed: true,
      participants: [],
      isLoading: true,
      days: [],
      score: [],
    };
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  champaingDetail() {
    getCampaignDetail(this.props.match.params.pk).then((data) => {
      data.participants.sort((a, b) => b.ongoing_score - a.ongoing_score);
      data.prizes.sort((a, b) => b.value - a.value);
      this.setState({
        active: data,
        prize: data.prizes,
        participants: data.participants,
        score: data.participants_score,
        isLoading: false,
      });
    });
  }

  componentDidMount() {
    this.champaingDetail("");
  }

  // componentDidUpdate() {
  //   this.champaingDetail("");
  // }
  onDeleteClick() {
    deleteCampaign(this.props.match.params.pk).then((data) => {
      this.setState({ data });
    });
  }
  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  render() {
    const base = process.env.PUBLIC_URL;
    const { active, participants, prize, score } = this.state;
    const { t } = this.props;
    const color = (data) =>
      data <= 4
        ? "fourth"
        : `${
            data <= 6
              ? "third"
              : `${data <= 8 ? "second" : `${data <= 10}` ? "first" : ""}`
          }`;

    // const startDate = moment(active.startDate).subtract(1, "days");
    // const startDate1 = startDate.format("YYYY-MM-DD");  Not TO work now

    const initial_score_overall = participants.reduce((accumulator, pilot) => {
      var output = accumulator + pilot.initial_score_overall;
      return output;
    }, 0);
    const initial_score_dynamic = participants.reduce((accumulator, pilot) => {
      var output = accumulator + pilot.initial_score_dynamic;
      return output;
    }, 0);

    const initial_score_fuel = participants.reduce((accumulator, pilot) => {
      var output = accumulator + pilot.initial_score_fuel;
      return output;
    }, 0);
    const initial_score_speed = participants.reduce((accumulator, pilot) => {
      var output = accumulator + pilot.initial_score_speed;
      return output;
    }, 0);

    const overall = initial_score_overall / participants.length;
    const dynamic = initial_score_dynamic / participants.length;
    const fuel = initial_score_fuel / participants.length;
    const speed = initial_score_speed / participants.length;

    return (
      <div
        className="Main_section"
        style={{ marginTop: "30px", paddingLeft: "30px", paddingRight: "30px" }}
      >
        <Helmet>
          <title>{active.title}</title>
        </Helmet>

        <div
          className="row row1"
          style={{ width: "100%", marginBottom: "-30px" }}
        >
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
        </div>
        <Campaign />

        <div className="headerr" style={{ marginTop: "0px" }}>
          <div>
            <div>
              <a href="# " onClick={this.handleClick.bind(this)}>
                <img
                  src={arrow_left}
                  alt="Left_Arrow"
                  style={{ height: "2.6em" }}
                />
              </a>
              &nbsp;
              <strong>
                {active.title ? `${active.title.toUpperCase()}` : "-"}
              </strong>{" "}
            </div>
          </div>
          <div className="" style={{ marginTop: "-50px" }}>
            {active.status === "planned" || active.status === "planowana" ? (
              <nav className="navbar navbar-expand-lg navbar-dark float-right">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item  ">
                    <a
                      className=""
                      title="Click to edit Planned campaign"
                      href={base + `/edit/campaign/${active.id}`}
                    >
                      <img
                        src={edit}
                        alt="contact"
                        className={
                          window.localStorage.i18nextLng === "pl"
                            ? "polish1"
                            : "english"
                        }
                        style={{
                          width: "1.6em",
                          marginLeft: "15px",
                          marginTop: "5px",
                        }}
                      />
                      <br></br>
                      <span
                        style={{
                          color: "rgb(183, 28, 28)",
                          fontSize: "11px",
                          marginLeft: "15px",
                        }}
                      >
                        {t("edit")}
                      </span>
                    </a>
                  </li>

                  <li
                    className="nav-item  "
                    style={{ marginTop: "2px", marginLeft: "10px" }}
                  >
                    <a
                      href="# "
                      onClick={(e) => this.onDeleteClick(e, active.id)}
                    >
                      &nbsp;{" "}
                      <img
                        src={deletee}
                        alt="contact"
                        onClick={(e) => this.onDeleteClick(e, active.id)}
                        style={{ marginBottom: "-5px", height: "1.5em" }}
                      />
                    </a>
                    <br></br>
                    <span
                      style={{
                        color: "rgb(183, 28, 28)",
                        fontSize: "11px",
                        position: "relative",
                      }}
                    >
                      {t("delete")}
                    </span>
                  </li>
                </ul>
              </nav>
            ) : (
              ""
            )}
          </div>
          {this.state.isLoading === true ? <Spinner /> : ""}
          <div className="row" style={{ marginTop: "30px" }}>
            <div className="col-sm-6">
              <h3 className="font-weight-normal" style={{ marginTop: "30px" }}>
                {t("basic_information")}
                <button
                  className={
                    active.status === "active" || active.status === "aktywny"
                      ? "btn btn-success float-right"
                      : `${
                          active.status === "finished" ||
                          active.status === "ukończona"
                            ? "btn btn-secondary float-right"
                            : "btn btn-info float-right"
                        }`
                  }
                  id="dd"
                >
                  {active.status}
                  {active.status === "planned" ||
                  active.status === "planowana" ? (
                    <span
                      className={
                        active.status === "planned" ||
                        active.status === "planowana"
                          ? "badge"
                          : ""
                      }
                      id="bad"
                      title="Need To Confirm"
                    >
                      i
                    </span>
                  ) : (
                    ""
                  )}
                </button>
              </h3>

              <hr></hr>

              <div className="row">
                <div className="col-sm-10">
                  <p id="ppp">{t("Campain_name").toUpperCase()}</p>
                  <p className="pp">{active.title ? `${active.title}` : "-"}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-10">
                  <p id="ppp"> {t("campaign_intro").toUpperCase()}</p>
                  <p className="pp ">{active.lead ? `${active.lead}` : "-"}</p>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-10">
                  <p id="ppp"> {t("description").toUpperCase()}</p>
                  <p className="pp " style={{ textAlign: " justify" }}>
                    {active.text ? `${active.text}` : "-"}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-5">
                  <p id="ppp">{t("from")}</p>
                  <p className="pp">
                    {active.startDate ? `${active.startDate}` : "-"}
                  </p>
                </div>

                <div className="col-sm-5">
                  <p id="ppp">{t("to")}</p>
                  <p className="pp">
                    {active.endDate ? `${active.endDate}` : "-"}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <h3 className="font-weight-normal" style={{ marginTop: "30px" }}>
                {t("additonal_information")}
              </h3>
              <hr></hr>

              <div className="row">
                <div className="col-sm-5">
                  <p id="ppp"> {t("parameter_to_improve").toUpperCase()}</p>
                  <p className="pp">{active.parameterDisplay}</p>

                  <div>
                    <p id="ppp"> {t("participant_number").toUpperCase()}</p>
                    <p className="pp">
                      {active.participantCount
                        ? `${active.participantCount}`
                        : "-"}{" "}
                      {/* {t("drivers")} */}
                    </p>
                  </div>

                  {/* <div>
                    <p id="ppp">{t("campaign_type")}</p>
                    <p className="pp">
                      {speed.prize_type ? `${speed.prize_type}` : "-"}
                    </p>
                  </div> */}

                  <div>
                    <p id="ppp"> {t("number_of_prize").toUpperCase()}</p>
                    <p className="pp">
                      {active.prized_places ? `${active.prized_places}` : "-"}
                    </p>
                  </div>
                  <div>
                    <Model
                      score={score}
                      inital={overall.toFixed(1)}
                      fuel={fuel.toFixed(1)}
                      speed={speed.toFixed(1)}
                      dynamic={dynamic.toFixed(1)}
                      label={score}
                      start={active.startDate}
                      end={active.endDate}
                      participant={participants}
                      parameter={active.parameterDisplay}
                    />

                    <a
                      href="#driver_score"
                      rel="modal:open"
                      style={{ color: "#b71c1c" }}
                    >
                      {t("campaign_score")}
                    </a>
                  </div>
                </div>

                <div className="col-sm-6">
                  {active.confirmed === true && (
                    <div>
                      <p id="ppp">{t("effective")}</p>
                      <p className="pp">
                        {active.effectiveness ? `${active.effectiveness}` : "-"}{" "}
                        %
                      </p>

                      <p id="ppp">{t("ranking_list")}</p>
                      {participants.slice(0, 3).map((c, i) => (
                        <p
                          className="pp "
                          style={{ color: "#b71c1c" }}
                          key={c.id}
                        >
                          {" "}
                          {i + 1}. {c.name ? `${c.name}` : "-"}{" "}
                          {active.endDate <= moment().format("YYYY-MM-DD") && (
                            <i
                              className={
                                i === 0
                                  ? "fa fa-trophy gold"
                                  : i === 1
                                  ? "fa fa-trophy silver"
                                  : i === 2
                                  ? "fa fa-trophy aluminium"
                                  : ""
                              }
                              aria-hidden="true"
                            ></i>
                          )}
                        </p>
                      ))}
                      <a
                        href="# "
                        className="ppp"
                        style={{ color: "#b71c1c" }}
                        onClick={this.toggleHidden.bind(this)}
                      >
                        {t("show_detail")}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {!this.state.isHidden && (
            <div style={{ marginTop: "70px" }}>
              <table className="table table-hover" id="table-to-xls">
                <thead>
                  <tr>
                    <th>{t("ranking")}</th>
                    <th>{t("Name")}</th>
                    <th>{t("driving_days1")}</th>
                    <th>{t("Milage")}</th>
                    <th>{t("location")}</th>

                    <th>{t("initial_general_score")}</th>
                    <th>{t("ongoing_general_score")} </th>
                    <th>{t("ongoing_effectiveness")}</th>
                    <th>{t("details")}</th>
                  </tr>
                </thead>
                <tbody>
                  {participants.map((c, i) => (
                    <tr key={c.id}>
                      <td>{i + 1}</td>
                      <td>
                        <a
                          href={base + "/overview/app/drivermetrics/" + c.id}
                          className="font-weight-bold"
                          title="Click to view profile overview"
                          style={{ color: "#b71c1c" }}
                        >
                          {c.name ? `${c.name}` : "-"}
                        </a>
                      </td>
                      <td>{c.driving_days ? `${c.driving_days}` : "-"}</td>
                      <td>{c.mileage ? `${c.mileage.toFixed(1)}` : "-"}</td>
                      <td>{c.location ? `${c.location}` : "-"}</td>
                      <td>
                        <span
                          className={color(c.initial_score)}
                          style={{ width: "35px" }}
                        >
                          {c.initial_score
                            ? `${c.initial_score.toFixed(1)}`
                            : "-"}
                        </span>
                      </td>
                      <td>
                        {" "}
                        <span
                          className={color(c.ongoing_score)}
                          style={{ width: "35px" }}
                        >
                          {c.ongoing_score
                            ? `${c.ongoing_score.toFixed(1)}`
                            : "-"}
                        </span>
                      </td>
                      <td>
                        <span
                        // className={
                        //   c.effectiveness < 0 ? "fleet_down" : "fleet_up"
                        // }
                        >
                          {c.effectiveness
                            ? `${Math.abs(c.effectiveness.toFixed(1))}`
                            : "-"}
                          {"% "}
                          &nbsp;
                          <i
                            className={
                              c.effectiveness < 0
                                ? "fa fa-arrow-down text-danger"
                                : "fa fa-arrow-up text-success "
                            }
                          ></i>
                        </span>
                      </td>

                      <td>
                        <LineChartt
                          from={active.startDate}
                          initial_score={c.initial_score}
                          to={active.endDate}
                          id={i + 1}
                          score_on={c.ongoing_score}
                          driver={c.id}
                          score={active.parameterDisplay}
                        />

                        <a
                          href={`#campaign_chart${i + 1}`}
                          rel="modal:open"
                          style={{ color: "#b71c1c" }}
                        >
                          <i
                            className="fa fa-line-chart "
                            style={{ color: "#b71c1c", fontSize: "19px" }}
                          ></i>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <a
                href="# "
                className="ppp float-right"
                style={{ color: "#b71c1c" }}
                onClick={this.toggleHidden.bind(this)}
              >
                {t("hide_details")}
              </a>
            </div>
          )}
          {!this.state.isHidden && (
            <span>
              {active.confirmed === true ? (
                <center>
                  <ReactToExcel
                    className="btn btn-success btn-lg bb1"
                    table="table-to-xls"
                    filename="CampaignReport"
                    sheet="sheet 1"
                    buttonText={t("export_rank")}
                  />
                </center>
              ) : (
                ""
              )}
            </span>
          )}

          <div style={{ marginTop: "70px" }}>
            <h3 className="font-weight-normal" style={{ marginTop: "30px" }}>
              {t("prize_specificiation")}
            </h3>
            <hr></hr>

            <table className="table table-hover">
              <thead>
                <tr>
                  <th>{t("Id")}</th>
                  <th>{t("name")}</th>
                  <th>{t("type_of_prize")}</th>
                  <th>{t("prize_value1")}</th>
                  <th>{t("prize_quantity")}</th>
                  <th>{t("kind_of_metrics")}</th>
                </tr>
              </thead>
              <tbody>
                {prize.map((c, i) => (
                  <tr key={c.id}>
                    <td>{i + 1}</td>
                    <td>{c.name ? `${c.name}` : "-"} </td>
                    <td>{c.prize_type ? `${c.prize_type}` : "-"}</td>
                    <td>{c.value ? `${c.value}` : "-"}</td>
                    <td>{c.quantity ? `${c.quantity}` : "-"}</td>
                    <td>{c.number_list.join(" , ")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Active);
