import React, { Component } from "react";
import moment from "moment";
import config from "../../Main";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import "./index";
import Cookies from "universal-cookie";
const cookie = new Cookies();
let authToken = cookie.get("Token");

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "2px solid white",
    height: "20px",
    borderBottom: "1px solid gray",
  }),
};

class MapForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walk: [],
    };
    this.ofChange = this.ofChange.bind(this);
  }

  async ofChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
    console.log(this.state);
  }

  async componentDidMount() {
    try {
      const res = await fetch(config.apiUrl.car, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + authToken,
        },
      });
      const walk = await res.json();
      this.setState({
        walk,
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { t } = this.props;
    const { walk } = this.state;
    let options = walk.map(function (city) {
      return { value: city.id, label: city.plate_number };
    });

    return (
      <form onSubmit={this.props.loaddata}>
        <div className="row" style={{ marginTop: "20px" }}>
          <div className="col-sm-3">
            <div className="form-group">
              <label
                for="exampleInputEmail1"
                className="font-weight-normal h6"
                style={{
                  fontSize: "10px",
                  fontWeight: "300",
                  opacity: "0.5",
                  top: "-17px",
                }}
              >
                {t("plate_number").toUpperCase()}
              </label>
              <Select
                name="plate"
                options={options}
                //onChange={this.ofChange}
                styles={colourStyles}
                className="Select-menu-outer"
                defaultValue={{
                  label: this.props.plate,
                  value: this.props.load1,
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,

                  colors: {
                    ...theme.colors,
                    primary25: "#faeded",
                    primary: "#faeded",
                  },
                })}
              />
            </div>
          </div>
          <div
            className="col-sm-3"
            style={{ top: "7px", position: "relative" }}
          >
            <div className="form-group" style={{ marginTop: "-4px" }}>
              <span
                style={{
                  fontSize: "10px",
                  position: "relative",
                  top: "-4px",
                  fontWeight: "300",
                  opacity: "0.5",
                }}
                className="font-weight-normal h6"
              >
                {t("date").toUpperCase()}
              </span>
              <input
                type="date"
                name="from"
                onChange={this.onChange}
                defaultValue={this.props.load}
                style={{ height: "38px" }}
                max={moment().format("YYYY-MM-DD")}
                className="form-control "
              />
            </div>
          </div>
          <div className="col-sm-3">
            <button
              type="submit"
              value="Get Data"
              className="btn  text-white font-weight-bolder  boxx1111 "
            >
              {t("showdata")}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default withTranslation()(MapForm);
