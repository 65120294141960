import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "./index.css";
import "./active.js";

class ProfileTop extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { t } = this.props;
    const baseUrl = process.env.PUBLIC_URL;
    return (
      <nav
        className="navbar navbar-expand-lg navbar-dark  "
        style={{ position: "relative", left: "-0.7%", marginBottom: "-7px" }}
      >
        <button
          className="navbar-toggler"
          style={{ background: "#13B760" }}
          type="button"
          data-toggle="collapse"
          data-target="#nnavbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="nnavbarSupportedContent">
          <ul className="navbar-nav mr-auto main_header ">
            <li className="nav-item btnnn  ">
              <a
                className="nav-link font-weight-bold ra "
                href={baseUrl + "/drivermetrics/"}
                style={{ color: "rgba(183, 28, 28, 1)", marginTop: "20px" }}
              >
                {t("driver_profile")}
              </a>
            </li>
            <li className="nav-item btnnn  ">
              <a
                className="nav-link font-weight-bold ra "
                href={baseUrl + "/carmetrics/"}
                style={{ color: "rgba(183, 28, 28, 1)", marginTop: "20px" }}
              >
                {t("car_profile")}
              </a>
            </li>

            <li className="nav-item  btnnn ">
              <a
                className="nav-link font-weight-bold ra  "
                href={baseUrl + "/profile/electric/"}
                style={{ color: "rgba(183, 28, 28, 1)", marginTop: "20px" }}
              >
                {t("electric_profile")}
              </a>
            </li>

            <li className="nav-item  btnnn ">
              <a
                className="nav-link font-weight-bold ra  "
                href={baseUrl + "/data/comp/"}
                style={{ color: "rgba(183, 28, 28, 1)", marginTop: "20px" }}
              >
                {t("firm")}
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default withTranslation()(ProfileTop);
