import React, { Component } from "react";
import Header1 from "../../../Components/Layout/Header1";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import arrow from "../../../img/arrow.png";
import config from "../../Main";
import User from "../../../Components/User";
import Datee from "../../Views/Date";
import { withTranslation } from "react-i18next";
import arrow_left from "../../../img/Aarrow.png";
import map from "../../../img/route1.png";
import compareByAsc from "../../../Service/Arrow/Sort/Asc";
import compareByDesc from "../../../Service/Arrow/Sort/Desc";
import Spinner from "../../../Container/Spinner";

class CoDriverCar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walk: [],
      driver: [],
      driverSummary: [],
      speed: [],
      isLoading: true,
      value: "select",
    };
  }

  change(event) {
    this.setState({
      value: event.target.value,
    });
  }

  getDriverList() {
    try {
      Promise.all([
        fetch(
          `${config.apiUrl.driver}${this.props.match.params.pk}/days/?critical_overspeed__gt=0&date__lte=${this.props.match.params.date__gte}&date__gte=${this.props.match.params.date__lte}`,
          config.head
        )
          .then((driver) => driver.json())
          .then((driver) => {
            driver.sort((a, b) => b.critical_overspeed - a.critical_overspeed);
            this.setState({
              ...this.state,
              driver,
              isLoading: false,
            });
          }),
        fetch(
          `${config.apiUrl.driver}${this.props.match.params.pk}/days/summary/?critical_overspeed__gt=0&date__lte=${this.props.match.params.date__gte}&date__gte=${this.props.match.params.date__lte}`,
          config.head
        )
          .then((driverSummary) => driverSummary.json())
          .then((driverSummary) => {
            this.setState({
              driverSummary,
            });
          }),
      ]);
    } catch (e) {
      console.log(e);
    }
  }

  getCarList() {
    try {
      Promise.all([
        fetch(
          `${config.apiUrl.car}${this.props.match.params.pk}/days/?critical_overspeed__gt=0&date__lte=${this.props.match.params.date__gte}&date__gte=${this.props.match.params.date__lte}`,
          config.head
        )
          .then((walk) => walk.json())
          .then((walk) => {
            walk.sort((a, b) => b.critical_overspeed - a.critical_overspeed);
            this.setState({
              ...this.state,
              walk,
              isLoading: false,
            });
          }),
        fetch(
          `${config.apiUrl.car}${this.props.match.params.pk}/days/summary/?critical_overspeed__gt=0&date__lte=${this.props.match.params.date__gte}&date__gte=${this.props.match.params.date__lte}`,
          config.head
        )
          .then((speed) => speed.json())
          .then((speed) => {
            this.setState({
              speed,
            });
          }),
      ]);
    } catch (e) {
      console.log(e);
    }
  }

  componentDidMount() {
    this.getDriverList("");
    this.getCarList("");
  }

  sortBy(key) {
    let arrayCopy = [...this.state.walk];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(compareByAsc(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(compareByDesc(key));
    }
    this.setState({ walk: arrayCopy, driver: arrayCopy });
  }

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  render() {
    const { walk, speed, driver, driverSummary, isLoading } = this.state;
    const { t } = this.props;

    const overzero = driver.length > 0 ? driver : walk;
    const summary = driver.length > 0 ? driverSummary : speed;
    const base = process.env.PUBLIC_URL;
    return (
      <div className="Main_section">
        <Helmet>
          {walk.slice(0, 1).map((c) => (
            <title>
              {c.plate_number ? `${c.plate_number}` : " Car Plate Number "}{" "}
            </title>
          ))}
        </Helmet>

        <div className="row row1">
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
          <div className="col-sm-6" style={{ marginTop: "-5px" }}></div>
        </div>
        <div style={{ marginTop: "-20px" }}>
          <Header1 />

          <div className="headerr">
            <div className="row" style={{ width: "100%" }}>
              <div className="col-sm-2" style={{ marginTop: "15px" }}>
                <a href=" " onClick={this.handleClick.bind(this)}>
                  <img
                    src={arrow_left}
                    alt="Left_Arrow"
                    style={{ height: "2.6em" }}
                  />
                </a>
                &nbsp;
                {overzero.length > 0 ? (
                  overzero
                    .slice(0, 1)
                    .map((c) => (
                      <strong>
                        {driver.length > 0
                          ? c.name
                            ? c.name
                            : ""
                          : c.plate_number
                          ? c.plate_number
                          : ""}
                      </strong>
                    ))
                ) : (
                  <strong>&nbsp;&nbsp; {t("no_data")}</strong>
                )}
              </div>

              <div className="col-sm-5" style={{ marginTop: "0px" }}>
                <div style={{ marginTop: "" }} className="text-center">
                  <p className="font-weight-bolder">
                    {t("from")}{" "}
                    {this.props.match.params.date__lte === "2016-01-04"
                      ? "-"
                      : `${this.props.match.params.date__gte}`}{" "}
                    {t("to")} {this.props.match.params.date__gte}
                  </p>
                </div>
              </div>
            </div>

            {isLoading === true ? (
              <Spinner />
            ) : (
              <div className="caaar">
                <table className="table table-hover " style={{ width: "100%" }}>
                  <thead style={{ position: "relative" }}>
                    <tr className="th">
                      <th className="sorttable_nosort" style={{ width: "5%" }}>
                        {t("Id")}
                      </th>
                      <th onClick={() => this.sortBy("date")}>
                        {t("date")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />{" "}
                      </th>

                      <th onClick={() => this.sortBy("milage")}>
                        {t("Milage")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>
                      <th onClick={() => this.sortBy("fuel_consumed")}>
                        {t("FuelComsumed")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>
                      <th onClick={() => this.sortBy("average_fuel_consumed")}>
                        {t("AverageFuel")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>
                      <th onClick={() => this.sortBy("overspeeding_distance")}>
                        {t("OverspeedingDistance")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>

                      <th
                        className=""
                        onClick={() => this.sortBy("critical_overspeed")}
                      >
                        {t("CriticalSpeed")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {overzero.length > 0 ? (
                      overzero.map((c, i) => (
                        <tr key={i + 1}>
                          <td>{i + 1}</td>
                          <td>{c.date ? `${c.date}` : "-"}</td>
                          <td>{c.milage ? `${c.milage.toFixed(1)}` : "-"}</td>
                          <td>
                            {c.fuel_consumed
                              ? `${c.fuel_consumed.toFixed(1)}`
                              : "-"}
                          </td>
                          <td>
                            {c.average_fuel_consumed
                              ? `${c.average_fuel_consumed.toFixed(1)}`
                              : "-"}
                          </td>
                          <td>
                            {c.overspeeding_percentage
                              ? `${c.overspeeding_percentage.toFixed(1)}`
                              : "-"}
                          </td>
                          <td>
                            {c.critical_overspeed
                              ? `${c.critical_overspeed}`
                              : "-"}
                          </td>

                          <td>
                            <a
                              href={
                                base +
                                `/carmap/${c.id}/${c.date}/${c.plate_number}`
                              }
                              className="font-weight-bold"
                              title="Click to view Map"
                              style={{ color: "#b71c1c" }}
                            >
                              <img
                                src={map}
                                alt="mapa"
                                title={t("map_title")}
                                style={{ height: "20px" }}
                              />
                            </a>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <p></p>
                    )}
                  </tbody>

                  <tfoot className="thead">
                    <tr>
                      <th className="sorttable_nosort"></th>
                      <th className="sorttable_nosort">
                        {t("Summary1").toUpperCase()}
                      </th>
                      <th className="sorttable_nosort">
                        {summary.milage ? `${summary.milage.toFixed(1)}` : "-"}
                      </th>
                      <th className="sorttable_nosort">
                        {summary.fuel_consumed
                          ? `${summary.fuel_consumed.toFixed(1)}`
                          : "-"}
                      </th>
                      <th className="sorttable_nosort">
                        {summary.average_fuel_consumed
                          ? `${summary.average_fuel_consumed.toFixed(1)}`
                          : "-"}
                      </th>
                      <th className="sorttable_nosort">
                        {summary.overspeeding_percentage
                          ? `${summary.overspeeding_percentage.toFixed(1)}`
                          : "-"}
                      </th>
                      <th className="sorttable_nosort">
                        {summary.critical_overspeed
                          ? `${summary.critical_overspeed}`
                          : "-"}
                      </th>

                      <th></th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(CoDriverCar));
