import Swal from "sweetalert2";

export const UserMessage = (title, text, icon) => {
  Swal.fire({
    title: title,
    type: icon,
    text: text,
    showConfirmButton: false,
    timer: 2000,
  });
};
