import React, { createRef, Component } from "react";
import { Helmet } from "react-helmet";
import MapForm from "../MapForm/MapForm";
import { Map, TileLayer, Polyline, Popup, FeatureGroup } from "react-leaflet";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import MapReport from "../Report/MapReport";
import TripReport from "../Report/TripReport";
import arrow_left from "../../../img/Aarrow.png";
import Spinner from "../../../Container/Spinner";
import { getCarMap, getCarReport } from "../../../Service/Car";
import "react-leaflet-fullscreen/dist/styles.css";
import FullscreenControl from "react-leaflet-fullscreen";

class Mapp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true,
      isOption: true,
      mapa: [],
      report: [],
      src: [0, 20],
      des: [0, -20],
      lol: "",
      fol: "",
      isLoading: true,
    };
    this.toggleOption = this.toggleOption.bind(this);
    this.mapRef = createRef();
    this.groupRef = createRef();
  }

  // For Hide and show option Map
  toggleOption() {
    this.setState({
      isOption: !this.state.isOption,
    });
  }

  // For Hide And Show Map Form
  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }

  handleClick() {
    let mapInst = this.mapRef.current.leafletElement;
    const group = this.groupRef.current.leafletElement; //get native featureGroup instance
    mapInst.fitBounds(group.getBounds());
  }

  async componentDidMount() {
    Promise.all([
      getCarMap(this.props.match.params.pk, this.props.match.params.date).then(
        (data) => {
          this.setState({ mapa: data, isLoading: false });
          if (this.state.mapa.length > 0) {
            return this.handleClick("");
          } else {
            console.log("nothin");
          }
        }
      ),
      getCarReport(
        this.props.match.params.pk,
        this.props.match.params.date
      ).then((data) => {
        this.setState({ report: data });
      }),
    ]);
  }

  // Get Data from filter date
  getData = async (e) => {
    this.setState({
      isLoading: true,
    });
    const from = e.target.elements.from.value;
    const plate = e.target.elements.plate.value;
    e.preventDefault();

    await Promise.all([
      getCarMap(plate, from).then((data) => {
        this.setState({
          mapa: data,
          lol: from,
          fol: plate,
          isLoading: false,
        });
        if (this.state.mapa.length > 0) {
          return this.handleClick("");
        } else {
          console.log("nothin");
        }
      }),
      getCarReport(plate, from).then((data) => {
        this.setState({ report: data, lol: from, fol: plate });
      }),
    ]);
  };

  render() {
    const { mapa, report } = this.state;

    const polyline1 = mapa.map((c) => {
      return c.coordinates;
    });

    const centerr = mapa.map((c) => {
      return c.coordinates[0];
    });

    const { t } = this.props;
    const { lol, fol } = this.state;
    const base = process.env.PUBLIC_URL;

    return (
      <div className="Main_section" style={{ marginTop: "30px" }}>
        <Helmet>
          <title>
            {this.props.match.params.plate_number}{" "}
            {this.props.match.params.date}
          </title>
        </Helmet>
        <MapReport
          load={this.props.match.params.date}
          plate={this.props.match.params.plate_number}
          state={this.state}
          vall={report}
        />
        <TripReport
          load={this.props.match.params.date}
          plate={this.props.match.params.plate_number}
          mapa={mapa}
        />
        <div>
          <a href="# " onClick={this.props.history.goBack}>
            <img
              src={arrow_left}
              alt="Left_Arrow"
              style={{ height: "2.6em" }}
            />
          </a>
        </div>

        <div className="headerr just" style={{ marginTop: "20px" }}>
          <h2
            className="font-weight-bolder"
            style={{ fontSize: "19px" }}
            id="mod1"
          >
            {t("map")} &nbsp; &nbsp;&nbsp;{" "}
            {mapa.length < 0 && (
              <span className="text-center"> {t("no_trip")} </span>
            )}
          </h2>

          <MapForm
            loaddata={this.getData}
            load={this.props.match.params.date}
            load1={this.props.match.params.pk}
            plate={this.props.match.params.plate_number}
          />
          {this.state.isLoading === true ? (
            <Spinner />
          ) : mapa.length > 0 ? (
            <div>
              <div className="row" style={{ marginTop: "30px" }}>
                <div className="col-sm-6">
                  <Map
                    center={centerr[0]}
                    zoom={8}
                    maxZoom={19}
                    ref={this.mapRef}
                    bounceAtZoomLimits={true}
                    maxBoundsViscosity={0.95}
                    maxBounds={[
                      [-180, -90],
                      [180, 90],
                    ]}
                    style={{ width: "auto", height: "480px", zIndex: "" }}
                  >
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <FeatureGroup ref={this.groupRef}>
                      <Polyline
                        positions={polyline1}
                        color={"#00008b"}
                        lineJoin={"round"}
                        lineCap={"round"}
                        opacity={1}
                        weight={5}
                        fill={false}
                        stroke={true}
                        fillRule={"evenodd"}
                      >
                        <Popup>A popup on click</Popup>
                      </Polyline>
                    </FeatureGroup>
                    <FullscreenControl position="topleft" />
                  </Map>
                </div>

                <div class="col-md-6 six">
                  <p id="mod1">{t("trips").toUpperCase()}</p>
                  {mapa.map((c, i) => (
                    <div key={c.pk} className="six_six">
                      <p className="ppp">
                        <strong>
                          {" "}
                          {t("trip")} {i + 1}( {t("distance")} :{" "}
                          {c.mileage.toFixed(1)} km
                          {", "}
                          &nbsp; {t("CriticalSpeed")}:{" "}
                          {c.crititalOverspeedingEventsCount
                            ? `${c.crititalOverspeedingEventsCount}`
                            : 0}{" "}
                          {c.crititalOverspeedingEventsCount > 0 && (
                            <sapn> , &nbsp; {t("red1")}: </sapn>
                          )}
                          {c.crititalOverspeedingEventsCount > 0
                            ? `${
                                c.overspeeding_50_display
                                  ? `${c.overspeeding_50_display}`
                                  : "-"
                              }`
                            : ""}
                          {" ,"} {t("acceleration1")} :{" "}
                          {c.accelerationEvents.length}
                          {" ,"} {t("braking1")} : {c.brakingEvents.length})
                        </strong>
                      </p>
                      <ul class="timeline">
                        <li>
                          <p>
                            {c.startPlace}
                            <br></br>{" "}
                            <span
                              // className="ppp"
                              style={{
                                fontSize: "12px",
                                opacity: "0.7",
                                marginTop: "-40px",
                                position: "relative",
                                top: "-6px",
                              }}
                            >
                              <i>{c.startTime}</i>
                            </span>
                          </p>
                        </li>

                        <li style={{ marginTop: "-20px" }}>
                          <p>
                            {c.endPlace}
                            <br></br>{" "}
                            <span
                              // className="ppp"
                              style={{
                                fontSize: "12px",
                                opacity: "0.7",
                                position: "relative",
                                top: "-6px",
                              }}
                            >
                              <i>{c.endTime}</i>
                            </span>
                          </p>
                        </li>
                      </ul>
                      <a
                        href={
                          base +
                          `/carmapdetail/${c.id}/${
                            lol ? `${lol}` : `${this.props.match.params.date}`
                          }/${
                            fol
                              ? `${parseInt(fol)}`
                              : `${this.props.match.params.plate_number}`
                          }/${i + 1}`
                        }
                        className="details_link"
                        
                        onClick={this.toggleHidden.bind(this)}
                      >
                        {" "}
                        {t("see_deatil")}
                      </a>
                    </div>
                  ))}
                </div>
              </div>

              <div style={{ marginTop: "40px" }}>
                <center>
                  <a
                    className="btn btn-login text-white font-weight-bolder boxx btn-vehicle"
                    href="#mapreport"
                    rel="modal:open"
                    id="bttt"
                  >
                    {t("daily_report")}
                  </a>
                  <a
                    className="btn btn-login text-white font-weight-bolder boxx btn-vehicle"
                    href="#tripreport"
                    style={{ marginLeft: "20px", width: "250px" }}
                    rel="modal:open"
                    id="bttt"
                  >
                    {t("trip_report").toUpperCase()}
                  </a>
                </center>
              </div>
            </div>
          ) : (
            <p
              className="text-center h4 font-weight-bolder"
              style={{ marginTop: "30px" }}
            >
              {t("no_data")}
            </p>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Mapp));
