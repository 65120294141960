import React, { Component } from "react";
import cross from "../../../img/Cross.png";
import { withTranslation } from "react-i18next";
// import { saveAs } from "file-saver";
import TableExport from "tableexport";

export class MapReport extends Component {
  handledownload() {
    TableExport(document.getElementsByTagName("table"), {
      headers: true, // (Boolean), display table headers (th or td elements) in the <thead>, (default: true)
      footers: true, // (Boolean), display table footers (th or td elements) in the <tfoot>, (default: false)
      formats: ["xlsx", "csv", "txt"], // (String[]), filetype(s) for the export, (default: ['xlsx', 'csv', 'txt'])
      exportButtons: true, // (Boolean), automatically generate the built-in export buttons for each of the specified formats (default: true)
      position: "bottom", // (top, bottom), position of the caption element relative to table, (default: 'bottom')
      trimWhitespace: false, // (Boolean), remove all leading/trailing newlines, spaces, and tabs from cell text in the exported file(s) (default: false)
    });
  }
  render() {
    const { t } = this.props;
    return (
      <div id="mapreport" class="modal">
        <a
          href="# "
          rel="modal:close"
          className="float-right text-dark "
          style={{ marginBottom: "90px" }}
        >
          <img src={cross} alt="Cross" style={{ height: "1.1em" }} />
        </a>
        <p className="mod" style={{ marginTop: "10px" }}>
          {t("daily_trip").toUpperCase()}
        </p>

        <div
          className="row"
          id="myInput"
          style={{ width: "100%", position: "relative", top: "-8%" }}
        >
          <table className="table" id="table">
            <tbody>
              <tr>
                <td>{t("date").toUpperCase()}:</td>
                <td className="font-weight-bold">
                  {" "}
                  {this.props.vall.date ? `${this.props.vall.date}` : "-"}
                </td>
              </tr>

              <tr>
                <td>{t("plate_number1").toUpperCase()}:</td>
                <td className="font-weight-bold">
                  {" "}
                  {this.props.vall.car_plate_number
                    ? `${this.props.vall.car_plate_number}`
                    : "-"}
                </td>
              </tr>
              <tr>
                <td>{t("distance")}:</td>
                <td>
                  {this.props.vall.distance
                    ? `${this.props.vall.distance.toFixed(1)}`
                    : "-"}{" "}
                  km
                </td>
              </tr>
              <tr>
                <td>{t("driving_time")}:</td>
                <td>{this.props.vall.duration_display}</td>
              </tr>

              <tr>
                <td>{t("average_speed")}:</td>
                <td>
                  {" "}
                  {this.props.vall.average_speed
                    ? `${this.props.vall.average_speed.toFixed(1)}`
                    : "-"}{" "}
                  km/h
                </td>
              </tr>

              <tr>
                <td>{t("max_speed")}:</td>
                <td>
                  {this.props.vall.max_speed
                    ? `${this.props.vall.max_speed.toFixed(1)}`
                    : "-"}{" "}
                  km/h
                </td>
              </tr>

              <tr>
                <td>{t("distance_with_overspeeding")}:</td>
                <td>
                  {this.props.vall.overspeeding_distance
                    ? `${this.props.vall.overspeeding_distance.toFixed(1)}`
                    : "-"}{" "}
                  km
                </td>
              </tr>

              <tr>
                <td>{t("CriticalSpeeding")}:</td>
                <td>
                  {this.props.vall.critical_overspeeding_events
                    ? `${this.props.vall.critical_overspeeding_events}`
                    : "-"}
                </td>
              </tr>

              <tr>
                <td>{t("distance_with_critical_overspeeding")}:</td>
                <td>
                  {this.props.vall.critical_overspeeding_distance
                    ? `${this.props.vall.critical_overspeeding_distance.toFixed(
                        1
                      )}`
                    : "-"}{" "}
                  km/h
                </td>
              </tr>

              <tr>
                <td>{t("ConsumedFule")}:</td>
                <td>
                  {this.props.vall.fuel
                    ? `${this.props.vall.fuel.toFixed(1)}`
                    : "-"}{" "}
                  l
                </td>
              </tr>
            </tbody>
          </table>
          {/* <div className="col-md-8">
          
          </div>
          <div className="col-md-4">
           
          </div> */}
        </div>
        <div style={{ marginTop: "-50px" }}>
          <center>
            <a
              href="# "
              type="submit"
              value="Get Data"
              className="btn btn-login text-white font-weight-bolder boxx  btn-vehicle"
              id="save"
              onClick={this.handledownload.bind(this)}
            >
              {t("save_file").toUpperCase()}
            </a>
          </center>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MapReport);
