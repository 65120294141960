import React, { Component } from "react";
import { Helmet } from "react-helmet";
import config from "../../Main";
import { withTranslation } from "react-i18next";
import {
  getFleetNotification,
  getUserNotification,
} from "../../../Service/Notification";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import Input from "./Input/Input";
const cookie = new Cookies();
let authToken = cookie.get("Token");

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fleet: [],
      user: [],
      checked: false,
      isHidden: "checked",
      notifications: false,
      notify_critical_overspeeding: false,
      notify_overspeeding: false,
      notify_exceeding_fuel: false,
      notify_engine_error: false,
      notify_campaign_confirmation: false,
      notify_campaign_start: false,
      notify_car_inspection: false,
      notify_insurance: false,
      notify_maintenance: false,
      receive_critical_overspeeding_email: false,
      receive_critical_aggressive_events: false,
      checkedItems: new Map(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleHidden = this.toggleHidden.bind(this);
  }

  handleChange = async (event) => {
    await this.setState({
      ...this.state,
      [event.target.name]: event.target.checked,
    });
  };

  toggleHidden() {
    this.setState({
      checked: !this.state.checked,
    });
  }

  handleChange1 = (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }));
  };

  handleAllChecked = (event) => {
    let fruites = this.state.notifications;
    fruites.forEach((fruite) => (fruite.isChecked = event.target.checked));
    this.setState({ fruites: fruites });
  };

  handleCheckChieldElement = (event) => {
    let fruites = [this.state];
    fruites.forEach((fruite) => {
      if (fruite.value === event.target.value)
        fruite.isChecked = event.target.checked;
    });
    this.setState({ fruites: fruites });
  };

  async handleSubmit(e) {
    e.preventDefault();
    Swal.fire({
      title: "Loading",
      type: "warning",
      showCancelButton: false,
      showConfirmButton: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    Promise.all([
      axios({
        method: "PATCH",
        url: config.apiUrl.fleetnotification,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + authToken,
        },
        data: this.state,
      })
        .then((res) => {
          Swal.fire({
            title: "Notification",
            type: "success",
            text: "Notification Updated Successfully  !",
            showConfirmButton: false,
            timer: 2000,
          });
          // window.location.reload();
        })
        .catch((err) => {
          Swal.fire({
            title: "Notification",
            type: "error",
            text: "Error while updating new notification!",
            timer: 2000,
          });
        }),
      axios({
        method: "PATCH",
        url: config.apiUrl.usernotification,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + authToken,
        },
        data: this.state,
      })
        .then((res) => {
          Swal.fire({
            title: "Notification",
            type: "success",
            text: "Notification Updated Successfully  !",
            showConfirmButton: false,
            timer: 2000,
          });
        })
        .catch((err) => {
          Swal.fire({
            title: "Notification",
            type: "error",
            text: "Error while updating new notification!",
            timer: 2000,
          });
        }),
    ]);
  }

  UserNotification = () => {
    getUserNotification().then((data) => {
      this.setState({
        receive_critical_overspeeding_email:
          data.receive_critical_overspeeding_email,
      });
    });
  };

  FleetNotification = () => {
    getFleetNotification().then((data) => {
      this.setState({
        notifications: data.notifications,
        notify_critical_overspeeding: data.notify_critical_overspeeding,
        notify_overspeeding: data.notify_overspeeding,
        notify_exceeding_fuel: data.notify_exceeding_fuel,
        notify_engine_error: data.notify_engine_error,
        notify_campaign_confirmation: data.notify_campaign_confirmation,
        notify_campaign_start: data.notify_campaign_start,
        notify_car_inspection: data.notify_car_inspection,
        notify_insurance: data.notify_insurance,
        notify_maintenance: data.notify_maintenance,
      });
    });
  };

  async componentDidMount() {
    this.UserNotification("");
    this.FleetNotification("");
  }
  render() {
    const { t } = this.props;
    return (
      <div className="container" style={{ marginTop: "30px" }}>
        <Helmet>
          <title>{t("notication_management")}</title>
        </Helmet>
        <div>
          <a href="/dashboard">
            <i
              className="fa fa-arrow-left fa-1x"
              aria-hidden="true"
              style={{ color: "#b71c1c", height: "20px" }}
            ></i>
          </a>
          &nbsp; <strong>{t("notication_management")}</strong> &nbsp;
          {/* <img src={cost} alt="contact" style={{ marginBottom: "-5px" }} /> */}
        </div>
        <div className="headerr" style={{ marginTop: "20px" }}>
          <form onSubmit={this.handleSubmit}>
            <div className="" style={{ margin: "auto" }}>
              <div className="row">
                <div
                  class=" col-md-7 "
                  style={{ marginTop: "0px", margin: "auto" }}
                >
                  <div
                    style={{
                      padding: "10px",
                      fontSize: "15px",
                      marginTop: "20px",
                    }}
                  >
                    <strong class="float-left font-weight-bold h5">
                      {t("Notification").toUpperCase()}
                    </strong>
                    <small
                      className="float-right"
                      style={{ position: "relative", top: "-12px" }}
                    >
                      <label class="switch">
                        <input
                          type="checkbox"
                          id="togBtn"
                          name="notifications"
                          onChange={this.handleChange}
                          checked={this.state.notifications}
                        />
                        <div class="slider round"></div>
                      </label>
                    </small>
                  </div>
                </div>
              </div>
              <br></br>
              {/* All Notification List here */}

              <Input change={this.handleChange} state={this.state} />

              {/*End All Notification List here */}
            </div>

            <center>
              <button
                className="btn btn-danger br text-white"
                value="create campaign"
                style={{ background: "#b71c1c" }}
              >
                {t("save").toUpperCase()}
              </button>
            </center>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Notification);
