import config from "../views/Main";

export async function getInsuranceProfile(to, from) {
  let products = new Promise((resolve, reject) => {
    fetch(
      `${config.apiUrl1.company_profile}?date__lte=${to ? to : ""}&date__gte=${
        from ? from : ""
      }`,
      config.head
    )
      .then((response) => {
        resolve(response.json());
      })
      .catch((reject) => console.log(reject));
  });
  return products;
}
