import React, { Component } from "react";
import cross from "../../../../../img/Cross.png";
import arrow_left from "../../../../../img/Aarrow.png";

import { withTranslation } from "react-i18next";

export class List extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <div id={"something" + this.props.id} class="modal campaign_chart">
          <a
            href="# "
            rel="modal:close"
            className="float-right text-dark "
            style={{ marginBottom: "0px" }}
          >
            <img
              src={cross}
              alt="Cross"
              style={{ height: "1.1em", marginTop: "30px" }}
            />
          </a>

          <p className="mod text-center" style={{ marginTop: "30px" }}>
            <a href="#contact" rel="modal:open" id="dd">
              <img
                src={arrow_left}
                alt="Left_Arrow"
                style={{ height: "2.6em" }}
              />
            </a>{" "}
            {this.props.cc.toUpperCase()}
          </p>

          {this.props.c.filter((dd) => dd.category === this.props.val).length >
          0 ? (
            <div class="row">
              {this.props.c
                .filter((dd) => dd.category === this.props.val)
                .map((d) => (
                  <div className="col-md-4">
                    <a
                      href={d.file}
                      className="image_file"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={d.file}
                        alt={d.sub_category}
                        className="image_car"
                      />
                    </a>
                    <p className="text-center font-weight-bold">
                      {d.sub_category}
                    </p>
                  </div>
                ))}
            </div>
          ) : (
            <h4
              className="text-center"
              style={{ position: "relative", top: "30%" }}
            >
              {t("no_data")}
            </h4>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(List);
