import React, { useState } from "react";
import cross from "../../../img/Cross.png";
import "./Feedback.css";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";

function Feedback() {
  const [password, setPassword] = useState({
    text: "",
  });
  const [fileNames, setFileNames] = useState([]);

  const rest = (e) => {
    e.preventDefault();
    setFileNames([]);
  };

  const handleDrop = (acceptedFiles) =>
    setFileNames(acceptedFiles.map((file) => file));

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPassword({ ...password, [name]: value });
  };

  // const handleImage = (e) => {
  //   let reader = new FileReader();
  //   let image = e.target.files[0];
  //   reader.readAsDataURL(image);
  //   reader.onloadend = (e) => {
  //     setPassword({
  //       ...password,
  //       [e.target.name]: reader.result,
  //       result: image,
  //     });
  //   };

  // };

  const submit = (e) => {
    e.preventDefault();
    if (password.text !== "") {
      Swal.fire({
        title: "Submitted !",
        type: "success",
        text: "Thank you for feedback we will reach you soon !",
        timer: 2000,
      });
      window.location.reload(1);
    } else {
      Swal.fire({
        title: "Error  !",
        type: "error",
        text: "You need to describe bugs or upload image !",
        timer: 2000,
      });
    }
  };
  const { t } = useTranslation();
  return (
    <div id="feedback" className="modal">
      <a
        href="# "
        rel="modal:close"
        className="float-right text-dark "
        style={{ marginBottom: "0px" }}
      >
        <img src={cross} alt="Cross" style={{ height: "1.1em" }} />
      </a>

      <div className="lolss">
        <span className="modd">Send feedback</span>
        <hr></hr>

        <form className="feedback_text" onSubmit={submit}>
          <div className="form-group">
            <textarea
              className="form-control feedback_text_field"
              rows="4"
              col="10"
              name="text"
              value={password.text}
              onChange={handleInputChange}
              placeholder={t("type")}
              maxLength="240"
              data-max="240"
            ></textarea>
            <span
              className="font-weight-bolder float-right"
              style={{ position: "relative" }}
            >
              {password.text.length}/240
            </span>
          </div>

          <div className="App">
            {fileNames.length > 0 ? (
              <div>
                <button className="btn" onClick={rest}>
                  Reset
                </button>

                <div>
                  {fileNames.map((file) => (
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      className="image_drop"
                    />
                  ))}
                </div>
                <p className="text-success">Uploaded Successfully</p>
              </div>
            ) : (
              <Dropzone onDrop={handleDrop} accept="image/*" multiple={false}>
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                }) => {
                  const additionalClass = isDragAccept
                    ? "accept"
                    : isDragReject
                    ? "reject"
                    : "";

                  return (
                    <div
                      {...getRootProps({
                        className: `dropzone ${additionalClass}`,
                      })}
                    >
                      <input {...getInputProps()} />
                      <span>{isDragActive ? "📂" : "📁"}</span>
                      <p>Drag'n'drop images, or click to select files</p>
                    </div>
                  );
                }}
              </Dropzone>
            )}
          </div>

          <p className="feedback_content">
            {" "}
            If you would like to find out more about DigitalFleet or VivaDrive
            entity you receive services from, or if you have any other
            questions, please reach out to us via the in-app chat in the
            DigitalFleet app
          </p>

          <div style={{ marginTop: "30px" }}>
            <center>
              <button
                type="submit"
                value="Get Data"
                className="btn text-white font-weight-bolder boxx btn-send"
                id="button1"
              >
                Send
              </button>
            </center>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Feedback;
