import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Additional from "./Form/Additional";
import UsedCar from "./Form/UsedCar";
import Fine from "./Form/Fine";
import Swal from "sweetalert2";
import Spinner from "../../../Container/Spinner";
import { withTranslation } from "react-i18next";
import axios from "axios";
import config from "../../Main";
import arrow_left from "../../../img/Aarrow.png";
import { withRouter } from "react-router-dom";
import ProfileTop from "../../../Components/Layout/ProfileTop";
import Cookies from "universal-cookie";
const cookie = new Cookies();
let authToken = cookie.get("Token");

class EditDriver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      swt: true,
      note: "",
      driver: [],
      first_name: "",
      last_name: "",
      employment_date: null,
      location: "",
      phone_number: "",
      email: "",
      gender: "",
      birth_year: null,
      claim_count: null,
      driving_license_issued: null,
      assigned_license_plate: "",
      status: "",
      loading: true,
      notes: [
        {
          text: "",
        },
      ],
      fines: [
        {
          car: "",
          text: "",
          date: null,
          value: "",
          other: "",
        },
      ],
      cars: [
        {
          since: null,
          until: null,
          car: "",
        },
      ],
    };
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
    console.log(this.state);
  }

  handleSubmit(e) {
    const { t } = this.props;
    const id = this.props.match.params.pk;
    const baseUrl = process.env.PUBLIC_URL;
    Swal.fire({
      title: "Loading",
      type: "warning",
      showCancelButton: false,
      showConfirmButton: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    e.preventDefault();

    const data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      employment_date: this.state.employment_date,
      location: this.state.location,
      phone_number: this.state.phone_number,
      email: this.state.email,
      gender: this.state.gender,
      birth_year: this.state.birth_year,
      claim_count: this.state.claim_count,
      driving_license_issued: this.state.driving_license_issued,
      assigned_license_plate: this.state.assigned_license_plate,
      notes: [...this.state.notes],
      cars: [...this.state.cars],
      fines: [...this.state.fines],
      status: this.state.status,
    };

    axios({
      method: "put",
      url: `${config.apiUrl.driver}${this.props.match.params.pk}/profile/`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
      data,
    })
      .then((res) => {
        Swal.fire({
          title: t("single_driver"),
          type: "success",
          text: t("Edit_success"),
          showConfirmButton: false,
          timer: 10000,
        });

        setTimeout(function () {
          window.location.href = baseUrl + `/overview/app/drivermetrics/${id}`;
        }, 2000);
      })
      .catch((reject) => {
        if (reject.response) {
          Swal.fire({
            title: " Error !",
            type: "error",
            text: reject.response.data.email
              ? reject.response.data.email
              : reject.response.data.claim_count,
            timer: 2000,
          });
          // console.log("Err", reject.response.data);
        } else {
          console.log("Error", reject.message);
        }
      });
  }

  componentDidMount() {
    axios
      .get(
        `${config.apiUrl.driver}${this.props.match.params.pk}/profile/`,
        config.head
      )
      .then((res) => {
        //console.log(res);
        this.setState({
          first_name: res.data.first_name,
          last_name: res.data.last_name,
          employment_date: res.data.employment_date,
          location: res.data.location,
          phone_number: res.data.phone_number,
          email: res.data.email,
          gender: res.data.gender,
          birthday: res.data.birthday,
          claim_count: res.data.claim_count,
          driving_license_issued: res.data.driving_license_issued,
          assigned_license_plate: res.data.assigned_license_plate,
          notes: [...res.data.notes],
          cars: [...res.data.cars],
          fines: [...res.data.fines],
          status: res.data.status,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleAddFields = () => {
    const values = this.state.notes;
    values.push({
      text: "",
    });
    this.setState({
      values,
    });
  };

  async onChange1(e, index) {
    if (["text"].includes(e.target.name)) {
      let cats = [...this.state.notes];

      cats[index][e.target.name] = e.target.value;
      await this.setState({
        cats,
      });
    } else {
      await this.setState({ [e.target.name]: e.target.value });
    }
    //console.log(this.state.notes);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  async onClick(e) {
    await this.setState({
      swt: !this.state.swt,
    });
  }

  render() {
    const { t } = this.props;

    //console.log(this.state.cars);

    return (
      <>
        <div className="Main_section" style={{ marginTop: "30px" }}>
          <Helmet>
            <title>{t("driver_add_edit")}</title>
          </Helmet>

          <ProfileTop />

          <div className="headerr" style={{ marginTop: "-6px" }}>
            {this.state.loading ? (
              <Spinner />
            ) : (
              <span>
                <div style={{ padding: "10px" }}>
                  <a href="# " onClick={this.handleClick.bind(this)}>
                    <img
                      src={arrow_left}
                      alt="Left_Arrow"
                      style={{ height: "2.6em" }}
                    />
                  </a>
                  &nbsp;&nbsp;
                  {t("driver_edit").toUpperCase()} / &nbsp;{" "}
                  <strong>
                    {this.state.first_name} {this.state.last_name}
                  </strong>
                </div>
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-sm-6">
                      <h3
                        className="font-weight-bold"
                        style={{ marginTop: "30px" }}
                      >
                        {t("basic_information")}

                        <span
                          className="float-right"
                          style={{
                            // borderBottom: "2px solid  rgb(183, 28, 28, 0.2)",
                            marginTop: "-6px",
                            //marginLeft: "-12px"
                          }}
                        >
                          <label class="switch1">
                            <input
                              type="checkbox"
                              id="togBtn"
                              name="status"
                              onChange={this.onChange.bind(this)}
                              checked={
                                this.state.status === "active" ? true : false
                              }
                              value={
                                this.state.status === "active"
                                  ? "inactive"
                                  : "active"
                              }
                            />
                            <div class="slider1 round"></div>
                          </label>
                        </span>

                        <p
                          className="float-right"
                          style={{
                            marginRight: "10px",
                            fontsize: "15px",
                            marginTop: "-5px",
                            // borderBottom: "2px solid  rgb(183, 28, 28, 0.2)"
                          }}
                        >
                          {" "}
                          <span>
                            {" "}
                            {!this.state.swt
                              ? `${t("disable")}`
                              : `${t("active")}`}
                          </span>
                        </p>
                      </h3>
                      <hr></hr>
                      <div className="row">
                        <div className="col-sm-5">
                          <div className="form-group">
                            <label id="p" for="exampleInputEmail1">
                              {t("first_name").toUpperCase()}
                            </label>
                            <input
                              type="text"
                              placeholder={t("type")}
                              name="first_name"
                              value={this.state.first_name}
                              onChange={this.onChange}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-sm-5">
                          <div className="form-group">
                            <label id="p" for="exampleInputEmail1">
                              {t("surname").toUpperCase()}
                            </label>
                            <input
                              type="text"
                              placeholder={t("type")}
                              name="last_name"
                              value={this.state.last_name}
                              onChange={this.onChange}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col-sm-5">
                          <div className="form-group">
                            <label id="p" for="exampleInputEmail1">
                              {t("phone").toUpperCase()}
                            </label>
                            <input
                              type="text"
                              placeholder={t("type")}
                              name="phone_number"
                              value={this.state.phone_number}
                              onChange={this.onChange}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-sm-5">
                          <div className="form-group">
                            <label id="p" for="exampleInputEmail1">
                              {t("email").toUpperCase()}
                            </label>
                            <input
                              type="text"
                              placeholder={t("type")}
                              name="email"
                              value={this.state.email}
                              onChange={this.onChange}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <h3
                        className="font-weight-bold"
                        style={{ marginTop: "30px" }}
                      >
                        {t("demographic_information")}
                      </h3>
                      <hr></hr>
                      <Additional loaddata={this.onChange} vall={this.state} />
                    </div>
                  </div>

                  <div className="carr" style={{ marginTop: "30px" }}>
                    <h3
                      className="font-weight-bold"
                      style={{ marginTop: "30px" }}
                    >
                      {t("used_car")}
                    </h3>
                    <hr></hr>
                    <UsedCar loaddata={this.onChange} vall={this.state.cars} />
                  </div>

                  <div className="carr" style={{ marginTop: "30px" }}>
                    <h3
                      className="font-weight-bold"
                      style={{ marginTop: "30px" }}
                    >
                      {t("fine_title")}
                    </h3>
                    <hr></hr>
                    <Fine loaddata={this.onChange} vall={this.state.fines} />
                  </div>

                  <p
                    className="h5 font-weight-bolder"
                    style={{ marginTop: "30px", marginBottom: "-20px" }}
                  >
                    {t("notes")}
                  </p>
                  <br></br>

                  {this.state.notes.slice(0, 1).map((inputField, index) => (
                    <div
                      className="Note"
                      style={{ marginTop: "10px" }}
                      key={`${inputField}~${index}`}
                    >
                      <div className="row">
                        <div className="col-sm-6">
                          <textarea
                            className="form-control"
                            rows="4"
                            col="10"
                            name="text"
                            value={inputField.text}
                            onChange={(e) => {
                              this.onChange1(e, index);
                            }}
                            placeholder={t("type")}
                            maxLength="240"
                            data-max="240"
                          ></textarea>
                          <span
                            className="font-weight-bolder float-right"
                            style={{ position: "relative" }}
                          >
                            {inputField.text.length}/240
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}

                  {this.state.notes.length === 0 && (
                    <a
                      href="# "
                      onClick={() => this.handleAddFields()}
                      className="p font-weight-bolder"
                      style={{ color: "#b71c1c" }}
                    >
                      {t("add_notes")}
                    </a>
                  )}

                  <center>
                    <button
                      className="btn btn-danger br text-white"
                      value="create campaign"
                      style={{ background: "#b71c1c" }}
                    >
                      {t("save").toUpperCase()}
                    </button>
                  </center>
                </form>
              </span>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(EditDriver));
