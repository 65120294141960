import React, { Component } from "react";
import Chart from "react-apexcharts";
import { withTranslation } from "react-i18next";

const colors = ["#783bff", "#6ac75a", "#ff6d43", "#465dff"];
export class Familiarty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        chart: {
          type: "donut",
        },
        labels: this.props.energy.labels,

        dataLabels: {
          enabled: false,
        },
        colors: colors,
        title: {
          align: "center",
          style: {
            fontSize: "17px",
            color: "black",
          },
        },
        tooltip: {
          y: {
            formatter: (value) => {
              return Number.parseFloat(value).toFixed(1) + "%";
            },
          },
        },

        legend: {
          position: "bottom",
          formatter: function (val, opts) {
            return (
              val +
              " - " +
              Number.parseFloat(
                opts.w.globals.series[opts.seriesIndex]
              ).toFixed(1) +
              "%"
            );
          },
        },
      },
    };
  }

  render() {
    const dat1 = this.props.energy.data;
    return (
      <div className="">
        <h3
          className="text-left font-weight-normal"
          style={{ paddingLeft: "20px" }}
        >
          {" "}
          {this.props.energy.title}
        </h3>
        <Chart
          style={{ marginTop: "22px" }}
          options={this.state.options}
          series={dat1}
          type="donut"
          height="260"
        />
      </div>
    );
  }
}

export default withTranslation()(Familiarty);
