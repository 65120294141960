import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import deletee from "../../img/delete.png";

class Prize extends Component {
  constructor(props) {
    super(props);
    this.state = { isHidden: true };
    this.toggleHie = this.toggleHie.bind(this);
  }
  toggleHidden() {
    this.setState({
      //  this.setState({ isHidden: false });
      isHidden: true,
    });
  }

  toggleHie() {
    this.setState({ isHidden: false });
  }

  handleAddFields = () => {
    const values = this.props.vall;
    values.push({
      number_list: [],
      prize_type: "",
      name: "",
      value: null,
      quantity: "",
    });
    this.setState({
      values,
    });
  };

  handleRemoveFields = (index) => {
    const values = this.props.vall;
    values.splice(index, 1);
    this.setState({
      values,
    });
  };

  async save(e, index) {
    if (["number_list"].includes(e.target.name)) {
      let cats = [...this.props.vall];

      cats[index][e.target.name] = e.target.value.split(",");
      await this.setState({
        cats,
      });
    } else {
      await this.setState({ [e.target.name]: e.target.value });
    }
    console.log(this.props.vall);
  }

  async onChange(e, index) {
    if (
      ["number_list", "prize_type", "name", "value", "quantity"].includes(
        e.target.name
      )
    ) {
      let cats = [...this.props.vall];

      cats[index][e.target.name] = e.target.value;
      await this.setState({
        cats,
      });
    } else {
      await this.setState({ [e.target.name]: e.target.value });
    }
    console.log(this.props.vall);
  }
  render() {
    const options = [];
    for (var i = 1; i <= 20; i++) {
      const don = i;

      options.push(
        <option value={don} key={don}>
          {don}
        </option>
      );
    }
    const { t } = this.props;
    //const { cats } = this.state;
    return (
      <div>
        <p id="ppp" style={{ marginTop: "30px" }}>
          {t("prizes").toUpperCase()}
        </p>
        <div className="row" style={{ width: "50%", marginLeft: "-35px" }}>
          <div className="col-sm-5">
            <div className="custom-control custom-radio custom-control-inline ">
              {" "}
              <input
                type="radio"
                name="prize"
                defaultChecked
                value="Prized"
                // onClick={this.toggleHidden.bind(this)}
                onClick={() => this.handleAddFields()}
                id="r5"
              />
              <label htmlFor="r5">{t("prized").toUpperCase()}</label>
            </div>
          </div>

          <div className="col-sm-5">
            <div className="custom-control custom-radio custom-control-inline">
              <input
                type="radio"
                name="prize"
                value="Without"
                onChange={this.props.loaddata}
                id="r6"
                // onClick={this.toggleHie}
                onClick={() => this.handleRemoveFields(0)}
              />
              <label htmlFor="r6">{t("without").toUpperCase()}</label>
            </div>
          </div>
        </div>

        <div
          style={{
            background: "rgba(248, 248, 248, 1.0)",
            padding: "20px",
          }}
        >
          <h3 className="font-weight-normal" style={{ marginTop: "30px" }}>
            {t("prize_specificiation")}
          </h3>
          <hr></hr>
          {/* Form End Here */}

          {this.state.isHidden && (
            <span>
              {this.props.vall.map((inputField, index) => (
                <div className="row" key={`${inputField}~${index}`}>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label id="p" for="title">
                        {t("title").toUpperCase()}
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        name="name"
                        value={inputField.name}
                        onChange={(e) => {
                          this.onChange(e, index);
                        }}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div className="form-group">
                      <label id="p" for="title">
                        {t("prize_type").toUpperCase()}
                      </label>
                      <select
                        class="form-control donn"
                        name="prize_type"
                        value={inputField.prize_type}
                        onChange={(e) => {
                          this.onChange(e, index);
                        }}
                      >
                        <option value="" disabled hidden selected>
                          {t("choose")}
                        </option>
                        <option value="voucher">{t("prize_voucher")}</option>
                        <option value="bonus">{t("prize_bounus")}</option>
                        <option value="prize_type">{t("prize_trip")}</option>
                        <option value="prize_gadget">
                          {t("prize_gadget")}
                        </option>
                        <option value="other">{t("prize_other")}</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div className="form-group">
                      <label id="p" for="title">
                        {t("prize_value").toUpperCase()}
                      </label>
                      <input
                        type="number"
                        placeholder={t("type")}
                        name="value"
                        style={{
                          border: "none",
                          borderBottom: "1px solid black",
                        }}
                        value={inputField.value}
                        onChange={(e) => {
                          this.onChange(e, index);
                        }}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div className="form-group">
                      <label id="p" for="title">
                        {t("prize_quantiy").toUpperCase()}
                      </label>
                      <input
                        type="number"
                        placeholder={t("type")}
                        name="quantity"
                        style={{
                          border: "none",
                          borderBottom: "1px solid black",
                        }}
                        value={inputField.quantity}
                        onChange={(e) => {
                          this.onChange(e, index);
                        }}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div className="form-group">
                      <label id="p" for="title">
                        {t("kind_metric").toUpperCase()}
                      </label>

                      <input
                        type="text"
                        name="number_list"
                        placeholder={t("type")}
                        value={inputField.number_list}
                        onChange={(e) => {
                          this.save(e, index);
                        }}
                        class="form-control donn"
                      />
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <button
                      className="btn btn-danger"
                      style={{
                        marginTop: "30px",
                        background: "none",
                        border: "none",
                      }}
                      type="button"
                      onClick={() => this.handleRemoveFields(index)}
                    >
                      <img src={deletee} alt="Delete" />
                    </button>
                  </div>
                </div>
              ))}

              <a
                href="# "
                className="ppp"
                style={{ color: "#b71c1c" }}
                onClick={() => this.handleAddFields()}
              >
                {t("add_more_prize")}
              </a>
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Prize);
