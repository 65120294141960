import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Campaign from "../../Components/Layout/Campaign";
import User from "../../Components/User";
import Datee from "../Views/Date";
import config from "../Main";
import arrow_left from "../../img/Aarrow.png";
import Additional from "./Additional";
import ReportData from "./Report/ReportData";
import Prize from "./Prize";
import Swal from "sweetalert2";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import Cookies from "universal-cookie";
const cookie = new Cookies();
let authToken = cookie.get("Token");

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true,
      title: "",
      lead: "",
      text: "",
      span: "",
      without: "",
      startDate: null,
      endDate: null,
      parameter: "",
      driverList: [],
      confirmed: true,
      status: "",
      prizes: [
        {
          number_list: "",
          prize_type: "",
          name: "",
          value: null,
          quantity: "",
        },
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.toggleHie = this.toggleHie.bind(this);
  }

  async onChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
  }

  componentDidMount() {
    axios
      .get(
        `https://digitalfleet.eu/api/1/campaigns/${this.props.match.params.pk}/manage/`,
        config.head
      )
      .then((res) => {
        // this.setState({...this.state, book: res.data})
        this.setState({
          title: res.data.title,
          lead: res.data.lead,
          text: res.data.text,
          span: res.data.span,
          without: res.data.without,
          startDate: res.data.startDate,
          endDate: res.data.endDate,
          parameter: res.data.parameter,
          confirmed: res.data.confirmed,
          prizes: [...res.data.prizes],
          driverList: [...res.data.driverList],
          status: res.data.status,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    Swal.fire({
      title: "Loading",
      type: "warning",
      showCancelButton: false,
      showConfirmButton: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    const id = this.props.match.params.pk;
    const data = {
      title: this.state.title,
      lead: this.state.lead,
      text: this.state.text,
      span: this.state.span,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      parameter: this.state.parameter,
      prizes: [...this.state.prizes],
      driverList: [...this.state.driverList],
      confirmed: this.state.confirmed,
    };

    axios({
      method: "put",
      url: `https://digitalfleet.eu/api/1/campaigns/${this.props.match.params.pk}/manage/`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },

      data,
    })
      .then((res) => {
        Swal.fire({
          title: "Campaign",
          type: "success",
          text: "Campaig Update Successfully  !",
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(function () {
          window.location.href = `/app/overview/campaign/${id}/Campaign/`;
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Campaign",
          type: "error",
          text: "Error while updating new Campaign!",
          timer: 2000,
        });
      });
  }

  toggleHidden() {
    this.setState({
      isHidden: false,
    });
  }

  toggleHie() {
    this.setState({ isHidden: true });
  }

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  handleChange = async (e) => {
    let target = e.target;
    let name = target.name;
    let value = Array.from(target.selectedOptions, (option) => option.value);
    await this.setState({
      [name]: value,
    });

    console.log(this.state.driverList);
  };
  render() {
    const { t } = this.props;
    if (this.state.status === "active") {
      window.location.href = "/campaign/";
    } else {
    }
    return (
      <div
        className="Main_section"
        style={{ marginTop: "30px", paddingLeft: "30px", paddingRight: "30px" }}
      >
        <Helmet>
          <title>{t("Camapaing_edit")}</title>
        </Helmet>

        <div
          className="row row1"
          style={{ width: "100%", marginBottom: "-30px" }}
        >
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
        </div>
        <Campaign />

        <div className="headerr">
          <div>
            <div>
              <a href="# " onClick={this.handleClick.bind(this)}>
                <img
                  src={arrow_left}
                  alt="Left_Arrow"
                  style={{ height: "2.6em" }}
                />
              </a>
              &nbsp;
              {t("Camapaing_edit").toUpperCase()}{" "}
              <strong> {this.state.title}</strong> &nbsp;
            </div>
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-sm-6">
                <h3
                  className="font-weight-normal"
                  style={{ marginTop: "30px" }}
                >
                  {t("new_campaing")}
                </h3>
                <hr></hr>

                {/* Start row here */}
                <div className="row">
                  <div className="col-sm-8">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("Campain_name").toUpperCase()}
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        name="title"
                        value={this.state.title}
                        onChange={this.onChange}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="form-group">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="confirmed"
                        onChange={this.onChange}
                        value={true}
                        id="exampleRadios2"
                      />
                      <label class="form-check-label" for="exampleRadios2">
                        CONFIRM
                      </label>
                    </div>
                  </div>
                </div>
                {/* End Here Row */}
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-sm-8">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("campaign_intro").toUpperCase()}
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        name="lead"
                        value={this.state.lead}
                        onChange={this.onChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                {/* Start row here */}

                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-sm-8">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("description").toUpperCase()}
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        name="text"
                        value={this.state.text}
                        onChange={this.onChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <h3
                  className="font-weight-normal"
                  style={{ marginTop: "30px" }}
                >
                  {t("additonal_information")}
                </h3>
                <hr></hr>
                <Additional
                  loaddata={this.onChange}
                  vall={this.state}
                  handle={this.handleChange}
                />
              </div>
            </div>

            <div
              style={{
                marginTop: "20px",
              }}
            >
              <ReportData
                vall={this.state}
                loaddata={this.handleChecked}
                select1={this.handleChange1}
                handle={this.handleChange}
                selectall={this.handleCheckAll}
              />
            </div>

            <div>
              <Prize
                loaddata={this.onChange}
                vall={this.state.prizes}
                state={this.state.without}
              />
            </div>
            <center>
              <button
                className="btn btn-danger br text-white"
                value="create campaign"
                style={{ background: "#b71c1c", width: "300px" }}
              >
                {t("edit_campaings")}
              </button>
            </center>
          </form>

          {/* Form End Here */}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Edit));
