import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";

import cross from "../../../img/Cross.png";

class Conatct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true,
      driver: "",
      body: "",
      title: "",
      emailid: "",
      notificationid: "",
    };
    this.onChange = this.onChange.bind(this);
    this.toggleHie = this.toggleHie.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggleHidden() {
    this.setState({
      // isHidden: !this.state.isHidden
      isHidden: false,
    });
  }

  toggleHie() {
    this.setState({ isHidden: false });
  }

  async onChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
    console.log(this.state);
  }

  //For Sms And Email
  handleSubmit(e) {
    Swal.fire({
      title: "Loading",
      type: "warning",
      showCancelButton: false,
      showConfirmButton: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    let authToken = localStorage.getItem("Token");
    try {
      e.preventDefault();
      Promise.all([
        axios({
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + JSON.parse(authToken),
          },

          url: "https://digitalfleet.eu/api/1/notifications/sms/",
          data: JSON.stringify({
            driver: this.state.driver,
            body: this.state.body,
          }),
        })
          .then((response) => {
            console.log(response.data);
            this.state.driver === ""
              ? console.log("")
              : Swal.fire({
                  title: "Sent",
                  type: "success",
                  text: "Successfully sent",
                  showConfirmButton: false,
                  timer: 3000,
                });
            setTimeout(function () {
              window.location.reload();
            }, 2000);
          })
          .catch((response) => {}),
        axios({
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + JSON.parse(authToken),
          },

          url: "https://digitalfleet.eu/api/1/notifications/email/",
          data: JSON.stringify({
            driver: this.state.emailid,
            body: this.state.body,
            title: this.state.title,
          }),
        })
          .then((response) => {
            console.log(response.data);
            this.state.emailid === ""
              ? console.log("")
              : Swal.fire({
                  title: "Sent",
                  type: "success",
                  text: "Successfully sent",
                  showConfirmButton: false,
                  timer: 3000,
                });
            setTimeout(function () {
              window.location.reload();
            }, 3000);
          })
          .catch((response) => {
            //handle error
            console.log(response);
          }),
        axios({
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + JSON.parse(authToken),
          },

          url: "https://digitalfleet.eu/api/1/notifications/push/",
          data: JSON.stringify({
            driver: this.state.notificationid,
            body: this.state.body,
            title: this.state.title,
          }),
        })
          .then((response) => {
            console.log(response.data);
            this.state.notificationid === ""
              ? console.log("")
              : Swal.fire({
                  title: "Sent",
                  type: "success",
                  text: "Notification Sent",
                  showConfirmButton: false,
                  timer: 3000,
                });
            setTimeout(function () {
              window.location.reload();
            }, 3000);
          })
          .catch((response) => {
            this.state.notificationid === ""
              ? console.log("")
              : Swal.fire({
                  title: "Sent",
                  type: "error",
                  text: "Error While Sending, please select some options",
                  showConfirmButton: false,
                  timer: 2000,
                });
          }),
      ]).catch((response) => {
        console.log("");
      });
    } catch (e) {
      console.log(e);
    }
  }

  // Get Data from fi
  render() {
    const { t } = this.props;
    return (
      <div id="contact" class="modal">
        <a
          href="# "
          rel="modal:close"
          className="float-right text-dark "
          style={{ marginBottom: "0px" }}
        >
          <img
            src={cross}
            alt="Cross"
            style={{ height: "1.1em", marginTop: "30px" }}
          />
        </a>

        <p className="mod" style={{ marginTop: "30px" }}>
          {t("contact")} {this.props.load.first_name}
        </p>
        <form onSubmit={this.handleSubmit}>
          <div className="list_type" style={{ marginTop: "20px" }}>
            {this.props.load.has_email === true ? (
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  name="emailid"
                  value={this.props.id}
                  // onChange={this.onChange}
                  onChange={(e) => {
                    this.onChange({
                      target: {
                        name: e.target.name,
                        value: this.props.id,
                      },
                    });
                  }}
                />
                &nbsp;&nbsp;&nbsp;
                <label
                  class="form-check-label"
                  for="exampleCheck1"
                  onChange={this.onChange}
                >
                  {t("email")}
                </label>
              </div>
            ) : (
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  disabled
                  style={{ opacity: "0.2" }}
                />
                &nbsp;&nbsp;&nbsp;
                <label
                  class="form-check-label"
                  for="exampleCheck1"
                  disabled
                  style={{ opacity: "0.2" }}
                >
                  {t("email")}
                </label>
              </div>
            )}
            {this.props.load.has_phone_number === true ? (
              <div class="form-check" style={{ marginTop: "15px" }}>
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  name="driver"
                  value={this.props.id}
                  // onClick={this.toggleHie.bind(this)}
                  onChange={this.onChange}
                />
                &nbsp;&nbsp;&nbsp;
                <label
                  class="form-check-label"
                  for="exampleCheck1"
                  onChange={this.onChange}
                >
                  SMS
                </label>
              </div>
            ) : (
              <div class="form-check" style={{ marginTop: "15px" }}>
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  name="sms"
                  disabled
                  style={{ opacity: "0.2" }}
                />
                &nbsp;&nbsp;&nbsp;
                <label
                  class="form-check-label"
                  for="exampleCheck1"
                  disabled
                  style={{ opacity: "0.2" }}
                >
                  SMS
                </label>
              </div>
            )}
            {this.props.load.can_receive_notifications === true ? (
              <div class="form-check" style={{ marginTop: "15px" }}>
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  name="notificationid"
                  value={this.props.id}
                  onChange={this.onChange}
                />
                &nbsp;&nbsp;&nbsp;
                <label
                  class="form-check-label"
                  for="exampleCheck1"
                  onChange={this.onChange}
                >
                  {t("application")}
                </label>
              </div>
            ) : (
              <div class="form-check" style={{ marginTop: "15px" }}>
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  disabled
                  style={{ opacity: "0.2" }}
                />
                &nbsp;&nbsp;&nbsp;
                <label
                  class="form-check-label"
                  for="exampleCheck1"
                  disabled
                  style={{ opacity: "0.2" }}
                >
                  {t("application")} &nbsp;
                </label>
              </div>
            )}
          </div>

          {this.state.isHidden && (
            <div className="subject" style={{ marginTop: "25px" }}>
              <div className="form-group">
                <label htmlFor="Subject" className="message">
                  {t("subject_of_message")}
                </label>
                <input
                  type="text"
                  style={{ border: "1px solid rgb(227, 225, 220)" }}
                  placeholder={t("type")}
                  name="title"
                  value={this.state.title}
                  onChange={this.onChange}
                  className="form-control"
                />
              </div>
            </div>
          )}

          <div className="message_box" style={{ marginTop: "25px" }}>
            <p className="message">
              {" "}
              {t("message_to")} {this.props.load.first_name}
            </p>

            <div style={{ marginTop: "-5px" }}>
              <textarea
                className="form-control"
                rows="4"
                col="10"
                style={{ border: "1px solid rgb(227, 225, 220)" }}
                name="body"
                value={this.state.body}
                // onClick={this.toggleHie.bind(this)}
                onChange={this.onChange}
                placeholder={t("type")}
                maxLength="240"
                data-max="240"
              ></textarea>
              <span
                className="font-weight-normal word_count float-right"
                style={{ position: "relative" }}
              >
                {this.state.body.length}/240
              </span>
            </div>
          </div>

          <center>
            <button
              className="btn btn-danger br text-white "
              value="create campaign"
              id="contact_button"
              // style={{
              //   background: "#b71c1c",
              //   width: "160px",
              //   marginTop: "30px",
              //   padding: "10px"
              // }}
            >
              {t("send").toUpperCase()}
            </button>
          </center>
        </form>
      </div>
    );
  }
}

export default withTranslation()(Conatct);
