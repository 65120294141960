import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Spinner from "../../../../../Container/Spinner";

const base = process.env.PUBLIC_URL;
class Available extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  click = (e, c) => {
    e.preventDefault();
    window.open(
      base +
        `/profile/ev/recommendation/${c.car}/${c.car_plate_number}/${
          this.props.mode ? `${this.props.mode}` : "demo"
        }/${this.props.mode1 ? `${this.props.mode1}` : "demo1"}/`
    );
  };

  render() {
    const { t } = this.props;

    return (
      <div>
        <h3 className="font-weight-bold h5">{t("conditinally")}</h3>
        <hr></hr>

        {this.props.loading ? (
          <div style={{ position: "relative", marginTop: "100px" }}>
            <Spinner />
          </div>
        ) : (
          <div className="caaar">
            <table className="table">
              <thead>
                <tr>
                  <th
                    style={{ width: "5%", backgroundColor: "#6AC75A" }}
                    className="t"
                  >
                    {t("id")}
                  </th>
                  <th
                    className="t"
                    style={{ textAlign: "center", backgroundColor: "#6AC75A" }}
                  >
                    {t("plate_number")}
                  </th>
                  <th
                    className="t"
                    style={{ textAlign: "center", backgroundColor: "#6AC75A" }}
                  >
                    {t("potential") + " [PLN]"}
                  </th>
                  <th
                    className="t"
                    style={{ textAlign: "center", backgroundColor: "#6AC75A" }}
                  >
                    Level of charging infrastructure
                  </th>
                  <th
                    className="t"
                    style={{ textAlign: "center", backgroundColor: "#6AC75A" }}
                  >
                    Number of options [No]
                  </th>
                  <th
                    className="t"
                    style={{ textAlign: "center", backgroundColor: "#6AC75A" }}
                  >
                    View options
                  </th>
                </tr>
              </thead>

              <tbody>
                {}
                {this.props.load.map((c, i) => (
                  <tr key={c.car}>
                    <td style={{ textAlign: "center" }}>{i + 1}</td>
                    <td style={{ textAlign: "center" }}>
                      <a
                        onClick={(e) => this.click(e, c)}
                        href="# "
                        style={{ color: "#5ae309" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-weight-bold"
                      >
                        {c.car_plate_number}
                      </a>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {Math.max(
                        ...c.recommended.map((o) => o.yearly_savings),
                        0
                      )}
                    </td>

                    <td style={{ textAlign: "center" }}> {c.charging}</td>

                    <td style={{ textAlign: "center" }}>
                      {" "}
                      {c.recommended.length}
                    </td>

                    <td style={{ textAlign: "center" }}>
                      {" "}
                      <button
                        onClick={(e) => this.click(e, c)}
                        type="button"
                        class="btn btn-see"
                      >
                        <span style={{ position: "relative", top: "-2px" }}>
                          {t("see")}
                        </span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(Available);
