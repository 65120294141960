import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Formal from "./AdditionAdd/Formal";
import { withTranslation } from "react-i18next";
import config from "../../Main";
import ProfileTop from "../../../Components/Layout/ProfileTop";
import Informations from "./Additional/Informations";
import Swal from "sweetalert2";
import axios from "axios";
import arrow_left from "../../../img/Aarrow.png";
import UsedDriver from "./AdditionAdd/UsedDriver";
import Cookies from "universal-cookie";
const cookie = new Cookies();
let authToken = cookie.get("Token");

class AddCar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      swt: true,
      isHidden: true,
      isHidden1: true,
      type: null,
      brand: "",
      model: "",
      production_year: "",
      engine_capacity: "",
      engine_power: "",
      transmission_type: "",
      plate_number: "",
      first_registration: "",
      next_registration_exam: "",
      insurance_expiration: "",
      oil_change_interval: "",
      last_oil_inspecion: "",
      last_oil_change_mileage: "",
      hours_start: "",
      hours_end: "",
      drivers: [
        {
          since: null,
          until: null,
          driver: "",
        },
      ],
      additional_information: [
        {
          information: "",
          date: "",
          mileage: "",
          notification_date: "",
          notification_mileage: "",
          notification_by_mileage: false,
          notification_by_date: false,
        },
      ],
    };
    this.onChange = this.onChange.bind(this);
    this.toggleHidden = this.toggleHidden.bind(this);
    this.toggleHidden1 = this.toggleHidden1.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  toggleHidden() {
    this.setState({
      isHidden: !this.state.isHidden,
    });
  }
  toggleHidden1() {
    this.setState({
      isHidden1: !this.state.isHidden1,
    });
  }

  handleSubmit(e) {
    const { t } = this.props;
    e.preventDefault();
    const data = {
      type: this.state.type,
      brand: this.state.brand,
      model: this.state.model,
      production_yea: this.state.production_year,
      engine_capacity: this.state.engine_capacity,
      engine_power: this.state.engine_power,
      transmission_type: this.state.transmission_type,
      plate_number: this.state.plate_number,
      first_registration: this.state.first_registration,
      next_registration_exam: this.state.next_registration_exam,
      insurance_expiration: this.state.insurance_expiration,
      oil_change_interval: this.state.oil_change_interval,
      last_oil_inspecion: this.state.last_oil_inspecion,
      last_oil_change_mileage: this.state.last_oil_change_mileage,
      hours_start: this.state.hours_start,
      hours_end: this.state.hours_end,
      drivers: [...this.state.drivers],
      additional_information: [...this.state.additional_information],
    };

    axios({
      method: "POST",
      url: config.apiUrl.car,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
      data,
    })
      .then((res) => {
        this.setState({
          type: null,
          brand: "",
          model: "",
          production_year: "",
          engine_capacity: "",
          engine_power: "",
          transmission_type: "",
          plate_number: "",
          first_registration: "",
          next_registration_exam: "",
          insurance_expiration: "",
          oil_change_interval: "",
          last_oil_inspecion: "",
          last_oil_change_mileage: "",
          hours_start: "",
          hours_end: "",
          drivers: [
            {
              since: null,
              until: null,
              driver: "",
            },
          ],
          additional_information: [
            {
              information: "",
              date: "",
              mileage: "",
              notification_date: "",
              notification_mileage: "",
            },
          ],
        });
        Swal.fire({
          title: t("single_car"),
          type: "success",
          text: t("added_success"),
          showConfirmButton: false,
          timer: 2000,
        });
        setTimeout(function () {
          window.location.href = "/carmetrics/";
        }, 2000);
      })
      .catch((reject) => {
        console.log(reject);
        if (reject.response) {
          console.log(reject.response.data);
          alert(reject.response.data);
          Swal.fire({
            title: " Error !",
            type: "error",
            text: "Please Fill all remaining the field",
            timer: 2000,
          });
          // console.log("Err", reject.response.data);
        } else {
          console.log("Error", reject.message);
        }
      });
  }

  async onChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
    console.log(this.state);
  }

  async onClick(e) {
    await this.setState({
      swt: !this.state.swt,
    });
  }
  render() {
    let fol = [];
    let year = 0;
    for (year = 2000; year < 2099; year++) {
      fol.push(year);
    }

    const { t } = this.props;

    return (
      <div className="container1" style={{ marginTop: "30px" }}>
        <Helmet>
          <title>{t("car_metrics_add")}</title>
        </Helmet>

        <ProfileTop />
        <div className="headerr" id="headerr" style={{ marginTop: "-6px" }}>
          <div style={{ padding: "10px" }}>
            <a href="/carmetrics/">
              <img
                src={arrow_left}
                alt="Left_Arrow"
                style={{ height: "2.6em" }}
              />
            </a>
            &nbsp;&nbsp;
            {t("car_metrics_add").toUpperCase()} &nbsp;
            <span className="float-right h6">
              <span className="start_icon"> * {t("required_field")}</span>
            </span>
          </div>
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-sm-7">
                <h3 className="font-weight-bold" style={{ marginTop: "30px" }}>
                  {t("basic_information")}

                  <span
                    className="float-right"
                    style={{
                      marginTop: "-6px",
                    }}
                  >
                    <label class="switch1">
                      <input
                        type="checkbox"
                        id="togBtn"
                        checked={this.state.swt}
                        onClick={this.onClick.bind(this)}
                        name="notify_critical_overspeeding"
                      />
                      <div class="slider1 round"></div>
                    </label>
                  </span>

                  <p
                    className="float-right"
                    style={{
                      marginRight: "10px",
                      fontsize: "15px",
                      marginTop: "-5px",
                      // borderBottom: "2px solid  rgb(183, 28, 28, 0.2)"
                    }}
                  >
                    {" "}
                    <span>
                      {" "}
                      {!this.state.swt ? `${t("disable")}` : `${t("active")}`}
                    </span>
                  </p>
                </h3>

                <hr></hr>

                {/* ANd Row start from here */}
                <div className="form-group">
                  <label id="p" htmlFor="exampleInputEmail1">
                    {t("engine_type").toUpperCase()}{" "}
                    <span className="start_icon"> * </span>
                  </label>
                  <div className="row">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div className="col-sm-2">
                      <input
                        type="radio"
                        placeholder="A Short Description"
                        name="type"
                        // defaultValue={this.state.type}
                        value="ON"
                        id="r1"
                        onChange={this.onChange}
                        class="form-check-input"
                      />
                      <label id="p" htmlFor="r1">
                        ON
                      </label>
                    </div>
                    <div
                      className="col-sm-9"
                      style={{ position: "relative", left: "-5%" }}
                    >
                      <div className="row">
                        <div className="col-sm-2">
                          <input
                            type="radio"
                            placeholder="A Short Description"
                            name="type"
                            //defaultValue={this.state.type}
                            value="Pb"
                            id="ree2"
                            onChange={this.onChange}
                            class="form-check-input"
                          />
                          <label id="p" htmlFor="ree2">
                            Pb
                          </label>
                        </div>
                        <div className="col-sm-2">
                          <input
                            type="radio"
                            placeholder="A Short Description"
                            name="type"
                            //defaultValue={this.state.type}
                            id="r3"
                            value="BEV"
                            onChange={this.onChange}
                            class="form-check-input"
                          />
                          <label id="p" htmlFor="r3">
                            BEV
                          </label>
                        </div>
                        <div className="col-sm-2">
                          <input
                            type="radio"
                            placeholder="A Short Description"
                            name="type"
                            //defaultValue={this.state.type}
                            id="r4"
                            value="HEV"
                            onChange={this.onChange}
                            class="form-check-input"
                          />
                          <label id="p" htmlFor="r4">
                            HEV
                          </label>
                        </div>
                        <div className="col-sm-2">
                          <input
                            type="radio"
                            placeholder="A Short Description"
                            name="type"
                            // defaultValue={this.state.type}
                            id="r5"
                            value="PHEV"
                            onChange={this.onChange}
                            class="form-check-input"
                          />
                          <label id="p" htmlFor="r5">
                            PHEV
                          </label>
                        </div>

                        <div className="col-sm-2">
                          <input
                            type="radio"
                            placeholder="A Short Description"
                            name="type"
                            // defaultValue={this.state.type}
                            id="r55"
                            value="CNG"
                            onChange={this.onChange}
                            class="form-check-input"
                          />
                          <label id="p" htmlFor="r55">
                            CNG
                          </label>
                        </div>
                        <div className="col-sm-2">
                          <input
                            type="radio"
                            placeholder="A Short Description"
                            name="type"
                            // defaultValue={this.state.type}
                            id="r45"
                            value="FCEV"
                            onChange={this.onChange}
                            class="form-check-input"
                          />
                          <label id="p" htmlFor="r45">
                            FCEV
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Row End Here */}

                {/* ANd Row start from here */}
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label id="p" htmlFor="brand">
                        {t("Brand").toUpperCase()}{" "}
                        <span className="start_icon"> * </span>
                      </label>
                      <input
                        placeholder={t("type")}
                        name="brand"
                        value={this.state.brand}
                        onChange={this.onChange}
                        class="form-control donn"
                      />
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="form-group">
                      <label id="p" htmlFor="model">
                        {t("Model").toUpperCase()}{" "}
                        <span className="start_icon"> * </span>
                      </label>
                      <input
                        placeholder={t("type")}
                        name="model"
                        value={this.state.model}
                        onChange={this.onChange}
                        class="form-control donn"
                      />
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="form-group">
                      <label id="p" htmlFor="year">
                        {t("production_year").toUpperCase()}{" "}
                        <span className="start_icon"> * </span>
                      </label>
                      <input
                        placeholder={t("type")}
                        name="production_year"
                        value={this.state.production_year}
                        onChange={this.onChange}
                        class="form-control donn"
                      />
                    </div>
                  </div>
                </div>
                {/* Row End Here */}

                {/* ANd Row start from here */}
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label id="p" htmlFor="brand">
                        {t("engine_capacity").toUpperCase()}{" "}
                        <span className="start_icon"> * </span>
                      </label>
                      <input
                        name="engine_capacity"
                        type="number"
                        placeholder={t("type")}
                        value={this.state.engine_capacity}
                        onChange={this.onChange}
                        className="form-control donn"
                      />
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="form-group">
                      <label id="p" htmlFor="model">
                        {t("power").toUpperCase()} [kW]{" "}
                        <span className="start_icon"> * </span>
                      </label>

                      <input
                        placeholder={t("type")}
                        name="engine_power"
                        type="number"
                        value={this.state.engine_power}
                        onChange={this.onChange}
                        class="form-control donn"
                      />
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="form-group">
                      <label id="p" htmlFor="year">
                        {t("gearbox_type").toUpperCase()}{" "}
                        <span className="start_icon"> * </span>
                      </label>
                      <select
                        name="transmission_type"
                        value={this.state.transmission_type}
                        onChange={this.onChange}
                        class="form-control donn"
                      >
                        <option value="" disabled hidden selected>
                          {t("choose")}
                        </option>
                        <option value="manual">Manual</option>
                        <option value="automatic">Automatic</option>
                      </select>
                    </div>
                  </div>
                </div>

                {/* Row End Here */}

                <div className="carr" style={{ marginTop: "30px" }}>
                  <h3
                    className="font-weight-bold"
                    style={{ marginTop: "30px" }}
                  >
                    {t("used_driver")} <span className="start_icon"> * </span>
                  </h3>
                  <hr></hr>
                  <UsedDriver
                    loaddata={this.onChange}
                    vall={this.state.drivers}
                  />
                </div>

                {/* ANd Row start from here */}
              </div>

              <div className="col-sm-5">
                <h3 className="font-weight-bold" style={{ marginTop: "30px" }}>
                  Formal Information
                </h3>
                <hr></hr>
                <Formal loadd={this.onChange} vall={this.state} />
              </div>
            </div>

            <div className="carr" style={{ marginTop: "30px" }}>
              <h3 className="font-weight-bold" style={{ marginTop: "30px" }}>
                {t("additonal_information")}{" "}
                <span className="start_icon"> * </span>
              </h3>
              <hr></hr>
              <Informations
                loaddata={this.onChange}
                vall={this.state.additional_information}
              />
            </div>

            <center>
              <button
                className="btn btn-danger br text-white"
                value="create campaign"
                style={{ background: "#b71c1c" }}
              >
                {t("add_car")}
              </button>
            </center>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AddCar);
