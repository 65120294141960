export { default as Recommend } from "../Settings/Recommend/Recommend";
export { default as Profilee } from "../Settings/Recommend/Profile/Profilee";
export { default as Choose } from "../Settings/Recommend/Choose";
export { default as Engament } from "../Settings/Recommend/Engament/Engament";
export { default as Ecosystem } from "../Settings/Recommend/Ecosystem/Ecosystem";
export { default as DriverCar } from "../Settings/Recommend/Driver/DriverCar";
export { default as Segement } from "../Settings/Recommend/Driver/Segement/Segement";
export { default as DetailView } from "../Settings/Recommend/Driver/Segement/DetailView";
export { default as Mobility } from "../Settings/Recommend/Mobility/Mobility";
export { default as CostR } from "../Settings/Recommend/Cost/Cost";

// This Path is only for test dummy data .!!
export { default as Dummy } from "../Settings/Car/Additional/Upload/DummyImage";

export { default as SegmentView } from "../Settings/Recommend/Driver/SegmentView/SegmentView";
