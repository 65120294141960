import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import cross from "../../../../../img/Cross.png";
import deletee from "../../../../../img/delete.png";

class Driving extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleAddFields = () => {
    const values = this.props.val;
    values.push({
      category: "driving-licence",
      sub_category: "",
      file: null,
      image: null,
    });
    this.setState({
      values,
    });
  };

  handleRemoveFields = (index) => {
    const values = this.props.val;
    values.splice(index, 1);
    this.setState({
      values,
    });
  };
  async onChange(e, index) {
    if (["category", "sub_category"].includes(e.target.name)) {
      let cats = [
        ...this.props.val.filter((c) => c.category === "driving-licence"),
      ];
      cats[index][e.target.name] = e.target.value;
      await this.setState({
        cats,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  async handleImage(e, index) {
    let reader = new FileReader();
    let image = e.target.files[0];
    reader.readAsDataURL(image);

    if (["file"].includes(e.target.name)) {
      reader.onloadend = (e) => {
        let cats = [
          ...this.props.val.filter((c) => c.category === "driving-licence"),
        ];
        let cats1 = [
          ...this.props.val.filter((c) => c.category === "driving-licence"),
        ];
        cats[index]["file"] = reader.result;
        cats1[index]["lol"] = image;
        this.setState({
          cats,
        });
      };
    } else {
      reader.onloadend = (e) => {
        this.setState({
          [e.target.name]: reader.result,
        });
      };
    }

    console.log(this.state.car_photo);
  }
  render() {
    const { t } = this.props;
    const insurance = this.props.val.filter(
      (c) => c.category === "driving-licence"
    );

    return (
      <div id="driving" className="modal">
        <a href="# " rel="modal:close" className="float-right text-dark ">
          <img src={cross} alt="Cross" style={{ height: "1.1em" }} />
        </a>
        <p className="mod" style={{ marginTop: "10px" }}>
          {t("driver_indentification").toUpperCase()}
        </p>
        <hr></hr>

        <div className="file_upload" style={{ marginTop: "25px" }}>
          <form>
            {insurance.map((inputField, index) => (
              <div className="row" key={index}>
                <div className="form-group">
                  <input
                    type="text"
                    name="category"
                    class="form-control donn"
                    placeholder={t("type")}
                    hidden
                    value={(inputField.category = "driving-licence")}
                    onChange={(e) => {
                      this.onChange(e, index);
                    }}
                    style={{ width: "220px" }}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="sub_category"
                    class="form-control donn"
                    placeholder={t("type")}
                    value={inputField.sub_category}
                    onChange={(e) => {
                      this.onChange(e, index);
                    }}
                    style={{ width: "220px" }}
                  />
                </div>
                &nbsp; &nbsp;
                <div className="">
                  {inputField.file === null ? (
                    ""
                  ) : (
                    <a
                      className="btn btn-success btn_image"
                      href={URL.createObjectURL(inputField.lol)}
                      target="_blank"
                      rel="noopener noreferrer"
                      type="button"
                    >
                      View Image
                    </a>
                  )}
                </div>
                <div class="form-group">
                  <div class="upload-btn-wrapper Specila_Button">
                    <button class="btn112" style={{ fontSize: "14px" }}>
                      <i className="fa fa-upload"></i>{" "}
                      {inputField.file === null ? "Upload file" : "Change File"}{" "}
                    </button>
                    <input
                      type="file"
                      accept="image/*"
                      name="file"
                      onChange={(e) => {
                        this.handleImage(e, index);
                      }}
                    />
                  </div>
                </div>
                <button
                  className="btn btn-danger"
                  style={{
                    background: "none",
                    border: "none",
                    marginTop: "-20px",
                  }}
                  type="button"
                  onClick={() => this.handleRemoveFields(index)}
                >
                  <img src={deletee} alt="Delete" />
                </button>
              </div>
            ))}

            <a
              href="# "
              onClick={this.handleAddFields}
              className="p font-weight-bolder"
              style={{ color: "#b71c1c" }}
            >
              {t("add_photo")}
            </a>
          </form>

          {insurance.length > 0 && (
            <center>
              <a
                href="# "
                rel="modal:close"
                className="btn btn-danger btn-lg bb1"
                style={{ width: "100px", marginTop: "20px", height: "40px" }}
              >
                {t("save").toUpperCase()}
              </a>
            </center>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Driving);
