import React, { Component } from "react";
import Chart from "react-apexcharts";
import { withTranslation } from "react-i18next";
// import config from "../../../Main";

const colors = ["#fF6d43"];
export class Temprature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: [],
      options: {
        chart: {
          id: "Temprature",
        },
        dataLabels: {
          enabled: false,
        },
        colors: colors,
        xaxis: {
          type: "category",
          labels: {
            style: {
              fontSize: "14px",
            },
          },

          categories: this.props.energy.labels,
        },

        tooltip: {
          y: {
            formatter: (value) => {
              return Number.parseFloat(value).toFixed(1) + "%";
            },
          },
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            labels: {
              formatter: function (value) {
                return Number.parseFloat(value).toFixed(1) + "%";
              },
            },
            axisBorder: {
              show: true,
            },
          },
        ],

        plotOptions: {
          bar: {
            columnWidth: "30%",
            endingShape: "flat",
            distributed: true,
          },
        },

        stroke: {
          show: true,
        },
        title: {
          align: "center",
          style: {
            fontSize: "17px",
            color: "black",
          },
        },
        legend: {
          show: false,
        },
        labels: this.props.energy.labels,
      },
    };
  }

  render() {
    const dat1 = this.props.energy.data;
    const newSeries = [];
    newSeries.push({
      data: dat1,
      name: this.props.energy.title,
    });
    return (
      <div className="">
        <h3
          className="text-left font-weight-normal"
          style={{ paddingLeft: "20px" }}
        >
          {this.props.energy.title}
        </h3>
        <Chart
          style={{ marginTop: "22px" }}
          options={this.state.options}
          series={newSeries}
          type="bar"
          stacked="true"
          height="250"
        />
      </div>
    );
  }
}

export default withTranslation()(Temprature);
