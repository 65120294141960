import React, { Component } from "react";
import Insurance from "./Upload/Insurance";
import Other from "./Upload/Other";
import Damage from "./Upload/Damage";
import Driving from "./Upload/Driving";
import Authorised from "./Upload/Authorised";
import { withTranslation } from "react-i18next";

export class Upload extends Component {
  render() {
    const { t } = this.props;

    return (
      <div className="row">
        <Damage val={this.props.state} />
        <Insurance val={this.props.state} />
        <Other val={this.props.state} />
        <Authorised val={this.props.state} />
        <Driving val={this.props.state} />
        <div className="col-sm-2 sd">
          <a
            href="#authorised"
            rel="modal:open"
            className="btn upload  text-dark"
          >
            {t("addition_documents")}
          </a>
        </div>
        <div className="col-sm-2 sd">
          <a
            href="#insurance"
            rel="modal:open"
            className="btn  upload text-dark"
          >
            {t("insurance_police")}
          </a>
        </div>
        <div className="col-sm-2 sd">
          <a href="#damage" rel="modal:open" className="btn upload   text-dark">
            {t("Damage_photo")}
          </a>
        </div>

        <div className="col-sm-2 sd">
          <a href="#driving" rel="modal:open" className="btn  upload text-dark">
            {t("driver_licence")}
          </a>
        </div>
        <div className="col-sm-2 sd">
          <a href="#other" rel="modal:open" className="btn  upload text-dark">
            {t("other_documents")}
          </a>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Upload);
