import React, { Component } from "react";
import Header1 from "../../../../Components/Layout/Header1";
import Search from "../../../Driver/Search";
import { Helmet } from "react-helmet";
import User from "../../../../Components/User";
import Datee from "../../../Views/Date";
import { withTranslation } from "react-i18next";
import arrow from "../../../../img/arrow.png";
import active from "../../../../img/active.png";
import print from "../../../../img/Rating/print.png";
import print_pl from "../../../../img/Rating/print_pl.png";
import { getDriverSummary, getMyDriver } from "../../../../Service/Driver";
import arrowL from "../../../../Service/Arrow/Arrow";
import DateGte from "../../../../Service/Arrow/DateGte";
import DateLte from "../../../../Service/Arrow/DateL";
import getPrint from "../../../../Service/Arrow/Print";
import compareByAsc1 from "../../../../Service/Arrow/Sort/Asc1";
import compareByDesc1 from "../../../../Service/Arrow/Sort/Desc1";
import compareByAsc from "../../../../Service/Arrow/Sort/Asc";
import compareByDesc from "../../../../Service/Arrow/Sort/Desc";
// import moment from "moment";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import Spinner from "../../../../Container/Spinner";
import "../../../Driver/index";
import Filter from "../../Filter";
import { ButtonToolbar, OverlayTrigger, Popover } from "react-bootstrap";

const moment = extendMoment(originalMoment);

class Th extends React.Component {
  handleClick = () => {
    const { onClick, id } = this.props;
    onClick(id);
  };
  render() {
    const { value } = this.props;
    return (
      <th onClick={this.handleClick} className="t">
        {value} <img src={arrow} alt="Arrow" style={{ height: "0.9em" }} />
      </th>
    );
  }
}

class MyDriver extends Component {
  constructor(props, context) {
    super(props, context);
    const today = moment();
    this.state = {
      sortBy: null,
      order: "ASC",
      newDriver: [],
      todos: [],
      speed: [],
      value1: "select",
      isOpen: false,
      today: null,
      lol: null,
      fol: null,
      isLoading: true,
      value: moment.range(today.clone().subtract(0, "days"), today.clone()),
    };
  }
  onToggle = (event) => {
    if (event.target.value === "date") {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  change(event) {
    this.setState({
      value1: event.target.value,
    });
  }

  // Get Data from filter date
  getData = async (value, states) => {
    this.setState({ isLoading: true });
    Promise.all([
      getMyDriver(
        value.end.format("YYYY-MM-DD"),
        value.start.format("YYYY-MM-DD")
      ).then((data) => {
        data.sort((a, b) => b.span.mileage.value - a.span.mileage.value);
        this.setState({
          todos: data,
          lol: value.start,
          fol: value.end,
          isLoading: false,
          isOpen: !this.state.isOpen,
        });
      }),
      getDriverSummary(
        value.end.format("YYYY-MM-DD"),
        value.start.format("YYYY-MM-DD")
      ).then((data) => {
        this.setState({
          speed: data,
        });
      }),
    ]);
  };

  getOptionData = async () => {
    this.setState({ isLoading: true });
    Promise.all([
      getMyDriver(DateGte(this.state.today), DateLte(this.state.today)).then(
        (data) => {
          data.sort((a, b) => b.span.mileage.value - a.span.mileage.value);
          this.setState({
            todos: data,
            isLoading: false,
          });
        }
      ),
      getDriverSummary(
        DateGte(this.state.today),
        DateLte(this.state.today)
      ).then((data) => {
        this.setState({ speed: data });
      }),
    ]);
  };

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.getOptionData("");
  };

  componentDidMount() {
    this.getOptionData("");
  }

  sortBy(key) {
    let arrayCopy = [...this.state.todos];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(compareByAsc1(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(compareByDesc1(key));
    }
    this.setState({ todos: arrayCopy });
  }

  sortBy1(key) {
    let arrayCopy = [...this.state.todos];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(compareByAsc(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(compareByDesc(key));
    }
    this.setState({ todos: arrayCopy });
  }

  render() {
    const { speed, todos } = this.state;
    const { t } = this.props;

    const color = (data) =>
      data <= 4
        ? "fourth"
        : `${
            data <= 6
              ? "third"
              : `${data <= 8 ? "second" : `${data <= 10}` ? "first" : ""}`
          }`;
    const base = process.env.PUBLIC_URL;
    return (
      <div className="Main_section">
        <Helmet>
          <title>Driver </title>
          <meta charSet="utf-8" />
          <meta
            name="Helmet application"
            content="Driver list with details of driven mileage, fuel consumed, critical event, score name and more"
          />
        </Helmet>
        <div
          className="row row1"
          style={{ width: "100%", marginBottom: "-30px" }}
        >
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
          <div className="col-sm-6 date_time" style={{ marginTop: "-5px" }}>
            <Filter
              lol={this.state.lol}
              fol={this.state.fol}
              today={this.state.today}
              option={this.state.isOpen}
              toggle={this.onToggle}
              change={this.getData}
              hello={this.onChange}
            />
          </div>
        </div>

        <div style={{ marginTop: "-20px" }}>
          <Header1 />

          <div className="headerr">
            <div className=" " style={{ width: "100%", marginBottom: "-30px" }}>
              <span
                className="nav-item  float-right  "
                disabled
                style={{ display: "flex" }}
              >
                <Search /> &nbsp; &nbsp;
                <a href="# " onClick={getPrint}>
                  <img
                    src={
                      window.localStorage.i18nextLng === "en" ? print : print_pl
                    }
                    alt="Print page"
                    style={{ marginTop: "-4px", height: "3em" }}
                  />
                </a>
              </span>
            </div>

            <div className="caaar table-responsive" style={{ width: "100%" }}>
              {this.state.isLoading === true ? (
                <Spinner />
              ) : (
                <table id="headerr" className="table table-hover tabell12">
                  <thead>
                    <tr className="th ">
                      <th className="t fix" style={{ width: "5%" }}>
                        {t("Id")}
                      </th>

                      <Th
                        onClick={() => this.sortBy1("name")}
                        id="name"
                        value={t("Name")}
                      />

                      {/* <th className="t fixed_header">{t("Name")} </th> */}
                      <th className="t">{t("plate_number")} </th>

                      <Th
                        onClick={() => this.sortBy("mileage")}
                        id="mileage"
                        value={t("Milage")}
                      />

                      <Th
                        onClick={() => this.sortBy("fuel_consumed")}
                        id="fuel_consumed"
                        value={t("FuelComsumed")}
                      />
                      <Th
                        onClick={() => this.sortBy("average_fuel_consumed")}
                        id="average_fuel_consumed"
                        value={t("AverageFuel")}
                      />
                      <th className="t font-weight-bolder">
                        <span
                          style={{ width: "150px", display: "inline-block" }}
                        >
                          <select
                            onChange={this.change.bind(this)}
                            value={this.state.value1}
                            style={{
                              position: "relative",
                              top: "8px",
                              width: "135px",
                            }}
                            class="minimal "
                          >
                            <option value="1"> {t("OverallScore")} </option>
                            <option value="2">{t("OverallSpeed")} </option>
                            <option value="3"> {t("OverallDynamic")} </option>
                            <option value="4">
                              {" "}
                              {t("OverallEfficiency")}{" "}
                            </option>
                          </select>

                          <img
                            style={{
                              position: "relative",
                              top: "8px",
                              width: "15px",
                              height: "0.9em",
                              marginTop: "15px",
                            }}
                            onClick={() =>
                              this.state.value1 === "1"
                                ? this.sortBy("overall_score")
                                : this.state.value1 === "2"
                                ? this.sortBy("speed_score")
                                : this.state.value1 === "3"
                                ? this.sortBy("dynamic_score")
                                : this.state.value1 === "4"
                                ? this.sortBy("efficiency_score")
                                : this.sortBy("overall_score")
                            }
                            src={arrow}
                            alt="Arrow"
                          />
                        </span>
                      </th>

                      <Th
                        onClick={() => this.sortBy("overspeeding_percentage")}
                        id="overspeeding_percentage"
                        value={t("OverspeedingDistance")}
                      />
                      <Th
                        onClick={() => this.sortBy("critical_overspeed")}
                        id="critical_overspeed"
                        value={t("CriticalSpeed")}
                      />
                      <th className="t">
                        {t("NOCar")}{" "}
                        <img
                          src={arrow}
                          alt="Arrow"
                          style={{ height: "0.9em" }}
                        />
                      </th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {todos.map((c, i) => (
                      <tr key={i + 1}>
                        <td className="fixed_header"> {i + 1}</td>
                        <td className="fixed_header">
                          <a
                            href={
                              base + "/overview/app/drivermetrics/" + c.id + "/"
                            }
                            className="font-weight-bold"
                            style={{ color: "#b71c1c" }}
                          >
                            {c.name ? `${c.name}` : " ? "}
                          </a>
                          <span className="last_used_app">
                            <ButtonToolbar>
                              <OverlayTrigger
                                trigger="click"
                                key="top"
                                placement="top"
                                overlay={
                                  <Popover id="popover-positioned-top">
                                    <Popover.Content>
                                      {c.app_user.includes(true)
                                        ? t("app_user")
                                        : t("app_not_user")} 
                                      <br></br>
                                      Last App Used:{" "}
                                      {c.last_app_use
                                        ? moment(c.last_app_use).format(
                                            "YYYY-MM-DD"
                                          )
                                        : "-"}
                                    </Popover.Content>
                                  </Popover>
                                }
                              >
                                <button className="btn">
                                  <img
                                    src={active}
                                    className={
                                      c.app_user.includes(true)
                                        ? "App_user"
                                        : "not_app_user"
                                    }
                                    alt="App Data"
                                  />
                                </button>
                              </OverlayTrigger>
                            </ButtonToolbar>
                          </span>
                        </td>
                        <td>
                          <a
                            href={base + "/overview/app/carmetrics/" + c.car_id}
                            className="font-weight-bold"
                            title="Click to view profile overview"
                            style={{ color: "#b71c1c" }}
                          >
                            {c.plate_number ? `${c.plate_number}` : " ? "}
                          </a>
                        </td>
                        <td>
                          {c.span.mileage.value
                            ? `${c.span.mileage.value.toFixed(1)}`
                            : "-"}{" "}
                          {arrowL(
                            c.span.mileage.changeDirection,
                            c.span.mileage.changePositive,
                            c.span.mileage.change
                          )}
                        </td>
                        <td>
                          {c.span.fuel_consumed.value
                            ? `${c.span.fuel_consumed.value.toFixed(1)}`
                            : "-"}
                          {arrowL(
                            c.span.fuel_consumed.changeDirection,
                            c.span.fuel_consumed.changePositive,
                            c.span.fuel_consumed.change
                          )}
                        </td>
                        <td>
                          {c.span.average_fuel_consumed.value
                            ? `${c.span.average_fuel_consumed.value.toFixed(1)}`
                            : "-"}{" "}
                          {arrowL(
                            c.span.average_fuel_consumed.changeDirection,
                            c.span.average_fuel_consumed.changePositive,
                            c.span.average_fuel_consumed.change
                          )}
                        </td>
                        <td>
                          <span
                            className={
                              this.state.value1 === "1"
                                ? color(c.span.overall_score.value)
                                : this.state.value1 === "2"
                                ? color(c.span.speed_score.value)
                                : this.state.value1 === "3"
                                ? color(c.span.dynamic_score.value)
                                : this.state.value1 === "4"
                                ? color(c.span.efficiency_score.value)
                                : color(c.span.overall_score.value)
                            }
                            style={{ width: "35px" }}
                          >
                            {this.state.value1 === "1"
                              ? `${
                                  c.span.overall_score.value
                                    ? `${Number(
                                        c.span.overall_score.value.toFixed(1)
                                      )}`
                                    : "-"
                                }`
                              : `${
                                  this.state.value1 === "2"
                                    ? `${
                                        c.span.speed_score.value
                                          ? `${Number(
                                              c.span.speed_score.value.toFixed(
                                                1
                                              )
                                            )}`
                                          : "-"
                                      }`
                                    : `${
                                        this.state.value1 === "3"
                                          ? `${
                                              c.span.dynamic_score.value
                                                ? `${Number(
                                                    c.span.dynamic_score.value.toFixed(
                                                      1
                                                    )
                                                  )}`
                                                : "-"
                                            }`
                                          : `${
                                              this.state.value1 === "4"
                                                ? `${
                                                    c.span.efficiency_score
                                                      .value
                                                      ? `${Number(
                                                          c.span.efficiency_score.value.toFixed(
                                                            1
                                                          )
                                                        )}`
                                                      : "-"
                                                  }`
                                                : `${
                                                    c.span.overall_score.value
                                                      ? `${Number(
                                                          c.span.overall_score.value.toFixed(
                                                            1
                                                          )
                                                        )}`
                                                      : "-"
                                                  }`
                                            }`
                                      }`
                                }`}
                          </span>

                          <span
                            className={
                              window.localStorage.i18nextLng === "en"
                                ? "special11 "
                                : "special1"
                            }
                          >
                            {this.state.value1 === "1"
                              ? arrowL(
                                  c.span.overall_score.changeDirection,
                                  c.span.overall_score.changePositive,
                                  c.span.overall_score.change
                                )
                              : this.state.value1 === "2"
                              ? arrowL(
                                  c.span.speed_score.changeDirection,
                                  c.span.speed_score.changePositive,
                                  c.span.speed_score.change
                                )
                              : this.state.value1 === "3"
                              ? arrowL(
                                  c.span.overall_score.changeDirection,
                                  c.span.overall_score.changePositive,
                                  c.span.overall_score.change
                                )
                              : this.state.value1 === "4"
                              ? arrowL(
                                  c.span.dynamic_score.changeDirection,
                                  c.span.dynamic_score.changePositive,
                                  c.span.dynamic_score.change
                                )
                              : arrowL(
                                  c.span.efficiency_score.changeDirection,
                                  c.span.efficiency_score.changePositive,
                                  c.span.efficiency_score.change
                                )}
                          </span>
                        </td>
                        <td>
                          {c.span.overspeeding_percentage.value
                            ? `${c.span.overspeeding_percentage.value.toFixed(
                                1
                              )}`
                            : "-"}{" "}
                          {arrowL(
                            c.span.overspeeding_percentage.changeDirection,
                            c.span.overspeeding_percentage.changePositive,
                            c.span.overspeeding_percentage.change
                          )}
                        </td>
                        <td>
                          {c.span.critical_overspeed.value
                            ? `${c.span.critical_overspeed.value.toFixed(1)}`
                            : "-"}{" "}
                          {arrowL(
                            c.span.critical_overspeed.changeDirection,
                            c.span.critical_overspeed.changePositive,
                            c.span.critical_overspeed.change
                          )}
                        </td>

                        <td>{c.carquanttity ? `${c.carquanttity}` : "-"} </td>
                        <td>
                          <a
                            href={
                              base + "/overview/app/my/driver/" + c.id + "/"
                            }
                            className="font-weight-bold"
                            title="Click to view Daily trip view"
                            style={{ color: "#b71c1c" }}
                          >
                            {t("trips").toUpperCase()}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>

                  <tfoot>
                    <tr className="thead sorttable_nosort">
                      <th className="sorttable_nosort"></th>

                      <th className="sorttable_nosort">
                        {" "}
                        {t("Summary1").toUpperCase()}
                      </th>
                      <th className="sorttable_nosort"></th>

                      <th className="sorttable_nosort">
                        {speed.milage ? `${speed.milage.toFixed(1)}` : "-"}
                      </th>
                      <th className="sorttable_nosort">
                        {speed.fuel_consumed
                          ? `${speed.fuel_consumed.toFixed(1)}`
                          : "-"}{" "}
                      </th>
                      <th className="sorttable_nosort">
                        {speed.average_fuel_consumed
                          ? `${speed.average_fuel_consumed.toFixed(1)}`
                          : "-"}
                      </th>
                      <th>
                        <span
                          className={
                            this.state.value1 === "1"
                              ? color(speed.overallscore)
                              : this.state.value1 === "2"
                              ? color(speed.overallspeedscore)
                              : this.state.value1 === "3"
                              ? color(speed.overalldynamicscore)
                              : this.state.value1 === "4"
                              ? color(speed.overallefficienyscore)
                              : color(speed.overallscore)
                          }
                          style={{ width: "35px" }}
                        >
                          {this.state.value1 === "1"
                            ? `${
                                speed.overallscore
                                  ? `${Number(speed.overallscore.toFixed(1))}`
                                  : " - "
                              }`
                            : `${
                                this.state.value1 === "2"
                                  ? `${
                                      speed.overallspeedscore
                                        ? `${Number(
                                            speed.overallspeedscore.toFixed(1)
                                          )}`
                                        : " - "
                                    }`
                                  : `${
                                      this.state.value1 === "3"
                                        ? `${
                                            speed.overalldynamicscore
                                              ? `${Number(
                                                  speed.overalldynamicscore.toFixed(
                                                    1
                                                  )
                                                )}`
                                              : " - "
                                          }`
                                        : `${
                                            this.state.value1 === "4"
                                              ? `${
                                                  speed.overallefficienyscore
                                                    ? `${Number(
                                                        speed.overallefficienyscore.toFixed(
                                                          1
                                                        )
                                                      )}`
                                                    : " - "
                                                }`
                                              : `${
                                                  speed.overallscore
                                                    ? `${Number(
                                                        speed.overallscore.toFixed(
                                                          1
                                                        )
                                                      )}`
                                                    : " - "
                                                }`
                                          }`
                                    }`
                              }`}
                        </span>
                      </th>
                      <th className="sorttable_nosort">
                        {speed.overspeeding_percentage
                          ? `${speed.overspeeding_percentage.toFixed(1)}`
                          : "-"}
                      </th>
                      <th className="sorttable_nosort">
                        {speed.critical_overspeed
                          ? `${speed.critical_overspeed.toFixed(1)}`
                          : "-"}
                      </th>

                      <th className="sorttable_nosort">
                        {speed.carquantity ? `${speed.carquantity}` : "-"}
                      </th>
                      <th></th>
                    </tr>
                  </tfoot>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MyDriver);
