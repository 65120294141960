import React, { Component } from "react";
import "../../Components/Layout/index";
import { getCostDetail, updateCost } from "../../Service/Cost";
import arrow_left from "../../img/Aarrow.png";
import { withTranslation } from "react-i18next";
import CssTextField from "../../Service/Arrow/Csstext";

class UpdateInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      car_plate_number: "",
      car_vin: "",
      invoice_number: "",
      net_value: "",
      value: "",
      date: "",
      description: "",
      cost_type: "",
      car_mileage: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    getCostDetail(this.props.match.params.pk).then((res) => {
      this.setState({
        car_plate_number: res.car_plate_number,
        car_vin: res.car_vin,
        invoice_number: res.invoice_number,
        net_value: res.net_value,
        value: res.value,
        date: res.date,
        description: res.description,
        cost_type: res.cost_type,
        car_mileage: res.car_mileage,
      });
    });
  }

  onChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    updateCost(this.state, this.props.match.params.pk).then((res) => {
      this.setState({
        res,
      });
    });
  }

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  render() {
    const { t } = this.props;
    return (
      <div className="container" style={{ marginTop: "10px" }}>
        <div id="">
          <div className="row">
            <div className="col-sm-2">
              <a href="# " onClick={this.handleClick.bind(this)}>
                <img
                  src={arrow_left}
                  alt="Left_Arrow"
                  style={{ height: "2.6em" }}
                />
              </a>
              &nbsp;{" "}
              <strong>
                {this.state.invoice_number
                  ? `${this.state.invoice_number}`
                  : "-"}
              </strong>{" "}
              &nbsp;
            </div>
          </div>
          <div className="headerr" style={{ marginTop: "5px" }}>
            <p className="mod" style={{ marginTop: "10px" }}>
              {t("invoice_edit").toUpperCase()}
            </p>

            <form
              noValidate
              autoComplete="off"
              onSubmit={this.handleSubmit}
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              <div className="row">
                <div className="">
                  <div className=" col ">
                    <div className="form-group">
                      <CssTextField
                        style={{
                          width: "200px",
                        }}
                        id="date"
                        label={t("date").toUpperCase()}
                        margin="normal"
                        type="date"
                        name="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={this.state.date}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col ">
                  <CssTextField
                    style={{
                      width: "200px",
                      marginLeft: "30px",
                    }}
                    id="custom-css-outlined-input"
                    label={t("plate_number1").toUpperCase()}
                    margin="normal"
                    disabled
                    name="car_plate_number"
                    autoComplete="off"
                    value={this.state.car_plate_number}
                    onChange={this.onChange}
                  />
                </div>

                <div className="col ">
                  <CssTextField
                    style={{
                      width: "200px",
                    }}
                    id="custom-css-outlined-input"
                    label={t("vin").toUpperCase()}
                    margin="normal"
                    name="car_vin"
                    disabled
                    autoComplete="off"
                    value={this.state.car_vin}
                    onChange={this.onChange}
                  />
                </div>

                <div className="col ">
                  <CssTextField
                    style={{
                      width: "200px",
                    }}
                    id="custom-css-outlined-input"
                    label={t("invoice_number").toUpperCase()}
                    margin="normal"
                    name="invoice_number"
                    autoComplete="off"
                    value={this.state.invoice_number}
                    onChange={this.onChange}
                  />
                </div>
                <div className="col ">
                  <CssTextField
                    style={{
                      width: "200px",
                      // marginLeft: "-30px"
                    }}
                    id="custom-css-outlined-input"
                    label={t("price_brutto").toUpperCase()}
                    margin="normal"
                    name="value"
                    autoComplete="off"
                    value={this.state.value}
                    onChange={this.onChange}
                  />
                </div>

                <div className="col ">
                  <CssTextField
                    style={{
                      width: "200px",
                      // marginLeft: "-30px"
                    }}
                    id="custom-css-outlined-input"
                    label={t("price_netto").toUpperCase()}
                    margin="normal"
                    name="net_value"
                    autoComplete="off"
                    value={this.state.net_value}
                    onChange={this.onChange}
                  />
                </div>

                <div className="col " style={{ marginTop: "6px" }}>
                  <label id="ppp">{t("invoice_type").toUpperCase()}</label>
                  <select
                    name="cost_type"
                    value={this.state.cost_type}
                    onChange={this.onChange}
                    class="form-control donn"
                    style={{
                      width: "205px",
                      borderBottom: "1px solid black",
                      opacity: "1",
                      marginTop: "-2px",
                    }}
                  >
                    <option value="" disabled selected hidden>
                      {t("invoice_type")}
                    </option>
                    <option value="Service"> {t("invoice_Serive")} </option>
                    <option value="Insurance Claim">
                      {" "}
                      {t("invoice_insurance_claim")}{" "}
                    </option>
                    <option value="Tire"> {t("inovice_tires")} </option>
                    <option value="Fuel"> {t("inovice_fuel")} </option>
                    <option value="Oil Review"> {t("invoice_oil")} </option>
                    <option value="Technical Inspection">
                      {" "}
                      {t("invoice_technical_inspection")}{" "}
                    </option>
                    <option value="Insurance">
                      {" "}
                      {t("invoice_insurance")}{" "}
                    </option>
                    <option value="Other"> {t("inovice_other")} </option>
                  </select>
                </div>

                <div className="col ci">
                  <CssTextField
                    style={{
                      width: "200px",
                      marginLeft: "30px",
                    }}
                    id="custom-css-outlined-input"
                    label={t("Milage")}
                    margin="normal"
                    name="car_mileage"
                    autoComplete="off"
                    value={this.state.car_mileage}
                    onChange={this.onChange}
                  />
                </div>
              </div>

              <div style={{ marginTop: "40px" }}>
                <p className="message">{t("description").toUpperCase()} </p>

                <textarea
                  name="description"
                  value={this.state.description}
                  onChange={this.onChange}
                  style={{ border: "1px solid rgb(227, 225, 220)" }}
                  rows="4"
                  cols="70"
                  className="form-control"
                  maxLength="240"
                  data-max="240"
                ></textarea>
                <span
                  className="font-weight-normal word_count float-right"
                  style={{ position: "relative" }}
                >
                  {this.state.description.length}/240
                </span>
              </div>

              <div style={{ marginTop: "180px", marginLeft: "-220px" }}>
                <center>
                  <button
                    type="submit"
                    value="Add Invoice"
                    className="btn btn-danger br"
                    style={{ background: "#b71c1c", color: "white" }}
                  >
                    {t("edit_invoice").toUpperCase()}
                  </button>
                </center>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(UpdateInvoice);
