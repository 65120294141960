import React, { Component } from "react";
//import ReactToExcel from "react-html-table-to-excel";
import CarReports from "../CarReports";
// import config from "../../Main";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import TableExport from "tableexport";
import arrow from "../../../img/arrow.png";

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = {
      walk: [],
      value: "select",
    };
  }

  handledownload() {
    TableExport(document.getElementsByTagName("table"), {
      headers: true, // (Boolean), display table headers (th or td elements) in the <thead>, (default: true)
      footers: true, // (Boolean), display table footers (th or td elements) in the <tfoot>, (default: false)
      formats: ["xlsx", "csv"], // (String[]), filetype(s) for the export, (default: ['xlsx', 'csv', 'txt'])
      filename: "Car-Report", // (id, String), filename for the downloaded file, (default: 'id')
      // bootstrap: true, // (Boolean), style buttons using bootstrap, (default: true)
      exportButtons: true, // (Boolean), automatically generate the built-in export buttons for each of the specified formats (default: true)
      position: "bottom", // (top, bottom), position of the caption element relative to table, (default: 'bottom')
      // ignoreRows: null,                   // (Number, Number[]), row indices to exclude from the exported file(s) (default: null)
      // ignoreCols: null,                   // (Number, Number[]), column indices to exclude from the exported file(s) (default: null)
      trimWhitespace: false, // (Boolean), remove all leading/trailing newlines, spaces, and tabs from cell text in the exported file(s) (default: false)
      // RTL: true // (Boolean), set direction of the worksheet to right-to-left (default: false)
      // sheetname: "id"                     // (id, String), sheet name for the exported spreadsheet, (default: 'id')
    });
  }

  change(event) {
    this.setState({
      value: event.target.value,
    });
  }

  compareByAsc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    };
  }

  compareByDesc(key) {
    return function (a, b) {
      if (a[key] < b[key]) return 1;
      if (a[key] > b[key]) return -1;
      return 0;
    };
  }

  sortBy(key) {
    let arrayCopy = [...this.props.location.state.movie[0]];
    const arrInStr = JSON.stringify(arrayCopy);
    arrayCopy.sort(this.compareByAsc(key));
    const arrInStr1 = JSON.stringify(arrayCopy);
    if (arrInStr === arrInStr1) {
      arrayCopy.sort(this.compareByDesc(key));
    }
    //const filter =  this.props.location.state.movie
    this.setState({
      ...(this.props.location.state.movie[0] = arrayCopy),
    });
  }

  render() {
    const { t } = this.props;

    const don = this.props.location.state.selectOptions1;

    return (
      <div className="" style={{ marginTop: "50px" }}>
        <CarReports />
        <div className="headerr" style={{ marginTop: "25px" }}>
          <h6>{t("raport").toUpperCase()}</h6>
          <p className="p">
            {t("from")} {this.props.location.state.lol} {t("to")}{" "}
            {this.props.location.state.fol}
          </p>

          <div className="caaar">
            <table className="table tabell table-hover  " id="table-to-xls">
              {/* Thead Starthere */}
              <p className="p" hidden>
                {t("from")} {this.props.location.state.lol} &nbsp; {t("to")}{" "}
                &nbsp;
                {this.props.location.state.fol}
              </p>

              <thead>
                <tr className="th">
                  <th style={{ width: "5%" }} className="  sorttable_nosort">
                    {t("Id")}
                  </th>
                  <th
                    className=""
                    onClick={() => this.sortBy("plate_number")}
                    style={{ width: "100px" }}
                  >
                    {t("plate_number1")}{" "}
                    <img src={arrow} alt="Arrow" style={{ height: "0.9em" }} />
                  </th>

                  {/* For Type Th */}
                  {don.includes("1") || don.includes("0") ? (
                    <th className="sorttable_nosort ">
                      <select
                        name="engine"
                        //
                        style={{
                          marginTop: "12px",
                          position: "relative",
                          top: "0px",
                          fontSize: "10px",
                          width: "140px",
                        }}
                        class="minimal"
                      >
                        <option value="">
                          {" "}
                          {t("Engine_type")}: {t("AllCar")}{" "}
                        </option>
                        <option value="Pb">{t("Engine_type")}: Pb</option>
                        <option value="ON"> {t("Engine_type")}: ON </option>
                        <option value="HEV">{t("Engine_type")}: HEV </option>
                        {/* <option value="BEV">{t("Engine_type")}: BEV </option>
                        <option value="PHEV">{t("Engine_type")}: PHEV </option>
                        <option value="CNG">{t("Engine_type")}: CNG </option>
                        <option value="FCEV">{t("Engine_type")}: FCEV </option> */}
                      </select>
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("2") || don.includes("0") ? (
                    <th onClick={() => this.sortBy("milage")} className="t">
                      {t("Milage")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("3") || don.includes("0") ? (
                    <th
                      onClick={() => this.sortBy("fuel_consumed")}
                      className="t"
                    >
                      {t("FuelComsumed")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("4") || don.includes("0") ? (
                    <th
                      onClick={() => this.sortBy("average_fuel_consumed")}
                      className="t"
                    >
                      {t("AverageFuel")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("5") || don.includes("0") ? (
                    <th
                      onClick={() => this.sortBy("overspeeding_percentage")}
                      className="t"
                      style={{ width: "155px" }}
                    >
                      {t("OverspeedingDistance")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("6") || don.includes("0") ? (
                    <th onClick={() => this.sortBy("dtc")} className="t">
                      {t("dtc")}{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}

                  {/* For Weekend  driving */}
                  {don.includes("7") || don.includes("0") ? (
                    <th className="t" onChange={this.change.bind(this)}>
                      <select
                        // onChange={this.change.bind(this)}
                        value={this.state.value}
                        defaultValue="1"
                        style={{
                          width: "220px",
                          top: "0px",
                        }}
                        class="minimal"
                      >
                        <option value="1"> {t("weekend_driving")} </option>
                        <option value="2"> {t("weekend_milage")} </option>
                        <option value="3"> {t("weekend_fuel")} </option>
                      </select>
                      <img
                        src={arrow}
                        onClick={() =>
                          this.state.value === "1"
                            ? this.sortBy("weekend_driving")
                            : this.state.value === "2"
                            ? this.sortBy("weekend_milage")
                            : this.state.value === "3"
                            ? this.sortBy("weekend_fuel_consumed")
                            : this.sortBy("weekend_driving")
                        }
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}

                  {/*  end weekend driving */}

                  {/* For Off Hours driving */}
                  {don.includes("8") || don.includes("0") ? (
                    <th className="t">
                      <select
                        // onChange={this.change.bind(this)}
                        value={this.state.value}
                        class="minimal"
                        style={{
                          width: "220px",
                          top: "0px",
                        }}
                      >
                        <option value="7"> {t("off_hour_driving")} </option>
                        <option value="9"> {t("off_hour_fuel")} </option>
                        <option value="8"> {t("off_hour_milage")} </option>
                      </select>
                      <img
                        src={arrow}
                        onClick={() =>
                          this.state.value === "7"
                            ? this.sortBy("off_hour_driving")
                            : this.state.value === "8"
                            ? this.sortBy("off_hour_milage")
                            : this.state.value === "9"
                            ? this.sortBy("off_hour_consumed")
                            : this.sortBy("off_hour_driving")
                        }
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {/*  end Off hour driving */}

                  {/* For Off Zone driving */}
                  {don.includes("9") || don.includes("0") ? (
                    <th
                      className="t"
                      onClick={() => this.sortBy("off_zone_driving")}
                    >
                      <select
                        // onChange={this.change.bind(this)}
                        value={this.state.value}
                        class="minimal"
                        style={{
                          width: "220px",
                          top: "0px",
                        }}
                      >
                        <option value="4"> {t("off_zone_driving")} </option>
                        <option value="5"> {t("off_zone_fuel")} </option>
                        <option value="6"> {t("off_zone_milage")} </option>
                      </select>
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}

                  {/*  end Off ZOne driving */}
                  {don.includes("10") || don.includes("0") ? (
                    <th onClick={() => this.sortBy("costs")} className="t">
                      {t("total_cost")} [pln]{" "}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}

                  {don.includes("11") || don.includes("0") ? (
                    <th
                      onClick={() => this.sortBy("energy_consumed")}
                      className="t"
                    >
                      {t("energy_cosumed")}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("12") || don.includes("0") ? (
                    <th
                      onClick={() => this.sortBy("average_energy_consumed")}
                      className="t"
                    >
                      {t("average_energy_cosumed")}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}

                  {don.includes("13") || don.includes("0") ? (
                    <th onClick={() => this.sortBy("fuel_eq")} className="t">
                      {t("fuel_eq")}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("14") || don.includes("0") ? (
                    <th onClick={() => this.sortBy("fuel_eq")} className="t">
                      {t("energy_from_recuperation")}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}

                  {don.includes("15") || don.includes("0") ? (
                    <th onClick={() => this.sortBy("fuel_eq")} className="t">
                      {t("driving_time")}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("16") || don.includes("0") ? (
                    <th onClick={() => this.sortBy("fuel_eq")} className="t">
                      {t("charging_time")}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("17") || don.includes("0") ? (
                    <th onClick={() => this.sortBy("fuel_eq")} className="t">
                      {t("number_of_charging")}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("18") || don.includes("0") ? (
                    <th onClick={() => this.sortBy("fuel_eq")} className="t">
                      {t("energy_from_charging")}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("19") || don.includes("0") ? (
                    <th onClick={() => this.sortBy("fuel_eq")} className="t">
                      {t("energy_from_ac")}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("20") || don.includes("0") ? (
                    <th onClick={() => this.sortBy("fuel_eq")} className="t">
                      {t("energy_from_dc")}
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ height: "0.9em" }}
                      />
                    </th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>

              {/* Thead End here */}

              <tbody>
                {this.props.location.state.movie[0].map((c, i) => (
                  <tr key={c.id}>
                    <td>{i + 1}</td>
                    <td>
                      <span
                        style={{ color: "#b71c1c" }}
                        className="font-weight-bold"
                      >
                        {c.plate_number ? `${c.plate_number}` : " ? "}
                      </span>
                    </td>
                    {don.includes("1") || don.includes("0") ? (
                      <td>
                        {this.state.value === "Pb"
                          ? `${c.type ? `${c.type.includes("Pb")}` : "-"}`
                          : `${
                              this.state.value === "ON"
                                ? `${(c.type = "ON" ? `${c.type}` : "-")}`
                                : `${c.type ? `${c.type}` : "-"}`
                            }`}
                      </td>
                    ) : (
                      ""
                    )}
                    {don.includes("2") || don.includes("0") ? (
                      <td>{c.milage ? `${c.milage.toFixed(1)}` : "-"}</td>
                    ) : (
                      ""
                    )}
                    {don.includes("3") || don.includes("0") ? (
                      <td>
                        {c.fuel_consumed
                          ? `${c.fuel_consumed.toFixed(1)}`
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}
                    {don.includes("4") || don.includes("0") ? (
                      <td>
                        {c.average_fuel_consumed
                          ? `${c.average_fuel_consumed.toFixed(1)}`
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}
                    {don.includes("5") || don.includes("0") ? (
                      <td>
                        {c.overspeeding_percentage
                          ? `${c.overspeeding_percentage.toFixed(1)}`
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}
                    {don.includes("6") || don.includes("0") ? (
                      <td>{c.dtc ? `${c.dtc}` : 0}</td>
                    ) : (
                      ""
                    )}
                    {don.includes("7") || don.includes("0") ? (
                      <td>
                        {this.state.value === "1"
                          ? `${
                              c.weekend_driving
                                ? `${c.weekend_driving.toFixed(1)}`
                                : "-"
                            }`
                          : `${
                              this.state.value === "2"
                                ? `${
                                    c.weekend_milage
                                      ? `${c.weekend_milage.toFixed(1)}`
                                      : "-"
                                  }`
                                : `${
                                    this.state.value === "3"
                                      ? `${
                                          c.weekend_fuel_consumed
                                            ? `${c.weekend_fuel_consumed.toFixed(
                                                1
                                              )}`
                                            : "-"
                                        }`
                                      : `${
                                          c.weekend_driving
                                            ? `${c.weekend_driving.toFixed(1)}`
                                            : "-"
                                        }`
                                  }`
                            }`}
                      </td>
                    ) : (
                      ""
                    )}
                    {don.includes("8") || don.includes("0") ? (
                      <td>
                        {this.state.value === "7"
                          ? `${
                              c.off_hour_driving
                                ? `${c.off_hour_driving.toFixed(1)}`
                                : "-"
                            }`
                          : `${
                              this.state.value === "8"
                                ? `${
                                    c.off_hour_milage
                                      ? `${c.off_hour_milage.toFixed(1)}`
                                      : "-"
                                  }`
                                : `${
                                    this.state.value === "9"
                                      ? `${
                                          c.off_hour_consumed
                                            ? `${c.off_hour_consumed.toFixed(
                                                1
                                              )}`
                                            : "-"
                                        }`
                                      : `${
                                          c.off_hour_driving
                                            ? `${c.off_hour_driving.toFixed(1)}`
                                            : "-"
                                        }`
                                  }`
                            }`}
                      </td>
                    ) : (
                      ""
                    )}
                    {don.includes("9") || don.includes("0") ? (
                      <td>
                        {this.state.value === "4"
                          ? `${
                              c.off_zone_driving
                                ? `${c.off_zone_driving.toFixed(1)}`
                                : "-"
                            }`
                          : `${
                              this.state.value === "5"
                                ? `${
                                    c.off_zone_milage
                                      ? `${c.off_zone_milage.toFixed(1)}`
                                      : "-"
                                  }`
                                : `${
                                    this.state.value === "6"
                                      ? `${
                                          c.off_zone_fuel_consumed
                                            ? `${c.off_zone_fuel_consumed.toFixed(
                                                1
                                              )}`
                                            : "-"
                                        }`
                                      : `${
                                          c.off_zone_driving
                                            ? `${c.off_zone_driving.toFixed(1)}`
                                            : "-"
                                        }`
                                  }`
                            }`}
                      </td>
                    ) : (
                      ""
                    )}
                    {don.includes("10") || don.includes("0") ? (
                      <td>{c.costs ? `${c.costs}` : "-"}</td>
                    ) : (
                      ""
                    )}
                    {don.includes("11") || don.includes("0") ? (
                      <td>
                        {c.energy_consumed ? `${c.energy_consumed}` : "-"}
                      </td>
                    ) : (
                      ""
                    )}
                    {don.includes("12") || don.includes("0") ? (
                      <td>
                        {c.average_energy_consumed
                          ? `${c.average_energy_consumed}`
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}
                    {don.includes("13") || don.includes("0") ? (
                      <td>{c.fuel_eq ? `${c.fuel_eq}` : "-"}</td>
                    ) : (
                      ""
                    )}

                    {don.includes("14") || don.includes("0") ? (
                      <td>
                        {c.energy_from_recuperation
                          ? `${c.energy_from_recuperation}`
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}
                    {don.includes("15") || don.includes("0") ? (
                      <td>{c.driving_time ? `${c.driving_time}` : "-"}</td>
                    ) : (
                      ""
                    )}
                    {don.includes("16") || don.includes("0") ? (
                      <td>{c.charging_time ? `${c.charging_time}` : "-"}</td>
                    ) : (
                      ""
                    )}

                    {don.includes("17") || don.includes("0") ? (
                      <td>
                        {c.number_of_charging ? `${c.number_of_charging}` : "-"}
                      </td>
                    ) : (
                      ""
                    )}
                    {don.includes("18") || don.includes("0") ? (
                      <td>
                        {c.energy_from_charging
                          ? `${c.energy_from_charging}`
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}
                    {don.includes("19") || don.includes("0") ? (
                      <td>
                        {c.energy_from_ac_charging
                          ? `${c.energy_from_ac_charging}`
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}
                    {don.includes("20") || don.includes("0") ? (
                      <td>
                        {c.energy_from_dc_charging
                          ? `${c.energy_from_dc_charging}`
                          : "-"}
                      </td>
                    ) : (
                      ""
                    )}
                  </tr>
                ))}
              </tbody>

              <tfoot>
                {/* {walk.reduce((accumulator, pilot) => {
                  // return accumulator + Number(pilot.costs);
                  return ( */}
                <tr className="thead sorttable_nosort">
                  <th className="sorttable_nosort"></th>

                  <th className="sorttable_nosort">
                    {" "}
                    {t("summary").toUpperCase()}
                  </th>
                  {don.includes("1") || don.includes("0") ? (
                    <th className="sorttable_nosort"></th>
                  ) : (
                    ""
                  )}
                  {don.includes("2") || don.includes("0") ? (
                    <th className="sorttable_nosort">
                      {this.props.location.state.speed[1].milage
                        ? `${this.props.location.state.speed[1].milage.toFixed(
                            1
                          )}`
                        : "-"}
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("3") || don.includes("0") ? (
                    <th className="sorttable_nosort">
                      {this.props.location.state.speed[1].fuel_consumed
                        ? `${this.props.location.state.speed[1].fuel_consumed.toFixed(
                            1
                          )}`
                        : "-"}
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("4") || don.includes("0") ? (
                    <th className="sorttable_nosort">
                      {this.props.location.state.speed[1].average_fuel_consumed
                        ? `${this.props.location.state.speed[1].average_fuel_consumed.toFixed(
                            1
                          )}`
                        : "-"}
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("5") || don.includes("0") ? (
                    <th className="sorttable_nosort">
                      {this.props.location.state.speed[1]
                        .overspeeding_percentage
                        ? `${this.props.location.state.speed[1].overspeeding_percentage.toFixed(
                            1
                          )}`
                        : "-"}
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("6") || don.includes("0") ? (
                    <th className="sorttable_nosort">
                      {this.props.location.state.speed[1].dtc
                        ? `${this.props.location.state.speed[1].dtc}`
                        : "-"}{" "}
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("7") || don.includes("0") ? (
                    <th className="sorttable_nosort">
                      {this.state.value === "1"
                        ? `${
                            this.props.location.state.speed[1].weekend_driving
                              ? `${this.props.location.state.speed[1].weekend_driving.toFixed(
                                  1
                                )}`
                              : "-"
                          }`
                        : `${
                            this.state.value === "2"
                              ? `${
                                  this.props.location.state.speed[1]
                                    .weekend_milage
                                    ? `${this.props.location.state.speed[1].weekend_milage.toFixed(
                                        1
                                      )}`
                                    : "-"
                                }`
                              : `${
                                  this.state.value === "3"
                                    ? `${
                                        this.props.location.state.speed[1]
                                          .weekend_fuel_consumed
                                          ? `${this.props.location.state.speed[1].weekend_fuel_consumed.toFixed(
                                              1
                                            )}`
                                          : "-"
                                      }`
                                    : `${
                                        this.props.location.state.speed[1]
                                          .weekend_driving
                                          ? `${this.props.location.state.speed[1].weekend_driving.toFixed(
                                              1
                                            )}`
                                          : "-"
                                      }`
                                }`
                          }`}
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("8") || don.includes("0") ? (
                    <th className="sorttable_nosort">
                      {this.state.value === "7"
                        ? `${
                            this.props.location.state.speed[1].off_hour_driving
                              ? `${this.props.location.state.speed[1].off_hour_driving.toFixed(
                                  1
                                )}`
                              : "-"
                          }`
                        : `${
                            this.state.value === "8"
                              ? `${
                                  this.props.location.state.speed[1]
                                    .off_hour_milage
                                    ? `${this.props.location.state.speed[1].off_hour_milage.toFixed(
                                        1
                                      )}`
                                    : "-"
                                }`
                              : `${
                                  this.state.value === "9"
                                    ? `${
                                        this.props.location.state.speed[1]
                                          .off_hour_consumed
                                          ? `${this.props.location.state.speed[1].off_hour_consumed.toFixed(
                                              1
                                            )}`
                                          : "-"
                                      }`
                                    : `${
                                        this.props.location.state.speed[1]
                                          .off_hour_driving
                                          ? `${this.props.location.state.speed[1].off_hour_driving.toFixed(
                                              1
                                            )}`
                                          : "-"
                                      }`
                                }`
                          }`}
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("9") || don.includes("0") ? (
                    <th className="sorttable_nosort">
                      {" "}
                      {this.state.value === "4"
                        ? `${
                            this.props.location.state.speed[1].off_zone_driving
                              ? `${this.props.location.state.speed[1].off_zone_driving.toFixed(
                                  1
                                )}`
                              : "-"
                          }`
                        : `${
                            this.state.value === "5"
                              ? `${
                                  this.props.location.state.speed[1]
                                    .off_zone_milage
                                    ? `${this.props.location.state.speed[1].off_zone_milage.toFixed(
                                        1
                                      )}`
                                    : "-"
                                }`
                              : `${
                                  this.state.value === "6"
                                    ? `${
                                        this.props.location.state.speed[1]
                                          .off_zone_fuel_consumed
                                          ? `${this.props.location.state.speed[1].off_zone_fuel_consumed.toFixed(
                                              1
                                            )}`
                                          : "-"
                                      }`
                                    : `${
                                        this.props.location.state.speed[1]
                                          .off_zone_driving
                                          ? `${this.props.location.state.speed[1].off_zone_driving.toFixed(
                                              1
                                            )}`
                                          : "-"
                                      }`
                                }`
                          }`}
                    </th>
                  ) : (
                    ""
                  )}
                  {don.includes("10") || don.includes("0") ? (
                    <th className="sorttable_nosort">
                      {this.props.location.state.speed[1].costs
                        ? `${this.props.location.state.speed[1].costs}`
                        : "-"}
                    </th>
                  ) : (
                    ""
                  )}
                </tr>
                {/* );
                }, 0)} */}
              </tfoot>
            </table>

            <div style={{ marginTop: "40px" }}>
              <center>
                <input
                  type="button"
                  className="btn btn-success btn-lg bb"
                  // id="btnExport"
                  onClick={this.handledownload.bind(this)}
                  value={t("export_report")}
                />

                {/* <ReactToExcel
                  className="btn btn-success btn-lg bb"
                  table="table-to-xls"
                  filename="DigitalFleet Car Report"
                  sheet="sheet 1"
                  buttonText={t("export_report")}
                /> */}

                <a className="btn" href="#car" rel="modal:open" id="bttt">
                  {t("new_report")}
                </a>
              </center>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Report));
