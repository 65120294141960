import React, { Component } from "react";
import cross from "../../../../../img/Cross.png";
import { withTranslation } from "react-i18next";

import TableExport from "tableexport";

export class DailyReport extends Component {
  handledownload() {
    TableExport(document.getElementsByTagName("table"), {
      headers: true,
      footers: true,
      formats: ["xlsx", "csv", "txt"],
      exportButtons: true,
      position: "bottom",
      trimWhitespace: false,
    });
  }
  render() {
    const { t } = this.props;

    return (
      <div id="dailyreport" class="modal">
        <a
          href="# "
          rel="modal:close"
          className="float-right text-dark "
          style={{ marginBottom: "90px" }}
        >
          <img src={cross} alt="Cross" style={{ height: "1.1em" }} />
        </a>
        <p className="mod" style={{ marginTop: "10px" }}>
          {t("generate_report").toUpperCase()}
        </p>

        <div
          className="row"
          id="myInput"
          style={{ width: "100%", position: "relative", top: "-8%" }}
        >
          <table className="table" id="table">
            <tbody>
              <tr>
                <td>{t("date")}:</td>
                <td className="font-weight-bold">{this.props.data.date}</td>
              </tr>

              <tr>
                <td>{t("plate_number1")}:</td>
                <td className="font-weight-bold">
                  {this.props.data.car_plate}
                </td>
              </tr>

              {this.props.basic.map((c, i) => (
                <tr key={i + 1}>
                  <td>{c.label}</td>
                  <td>{c.value ? `${c.value}` : "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div style={{ marginTop: "-50px" }}>
          <center>
            <a
              href="# "
              type="submit"
              value="Get Data"
              className="btn btn-login text-white font-weight-bolder boxx "
              id="save"
              onClick={this.handledownload.bind(this)}
              style={{
                height: "40px",
                fontSize: "13px",
                width: "200px",
                background: "rgba(183, 28, 28, 1)",
                border: "none",
                color: "white",
                margin: "auto",
              }}
            >
              {t("save_file").toUpperCase()}
            </a>
          </center>
        </div>
      </div>
    );
  }
}

export default withTranslation()(DailyReport);
