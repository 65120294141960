import React, { Component } from "react";
import ProfileTop from "../../../../Components/Layout/ProfileTop";
import User from "../../../../Components/User";
import Datee from "../../../Views/Date";
import Driverlist from "./Table/Driverlist";
import Filter from "../../Filter";
import arrow_left from "../../../../img/Aarrow.png";
import { Helmet } from "react-helmet";
import { getCarProfile } from "../../../../Service/Car";
import { withTranslation } from "react-i18next";
import Time from "./Graph/TIme";
import Temprature from "./Graph/Temprature";
import Weather from "./Graph/Weather";
import Type from "./Percent/Type";
import Zone from "./Percent/Zone";
import Road from "./Percent/Road";
import Driving1 from "../../Electric/Profile/Chart/Driving";
import Recover from "../../Electric/Profile/Chart/Recover";
import Plug from "../../Electric/Profile/Chart/Plug";
import Driving from "./Percent/Driving";
import Additional from "./Table/Additional";
import CarDoc from "./Table/CarDoc";
import edit from "../../../../img/edit.png";
import print from "../../../../img/Rating/print.png";
import print_pl from "../../../../img/Rating/print_pl.png";
import DateGte from "../../../../Service/Arrow/DateGte";
import DateLte from "../../../../Service/Arrow/DateL";
import getPrint from "../../../../Service/Arrow/Print";
import Spinner from "../../../../Container/Spinner";
import Chart from "../../MyDriver/Profile/Chart/Chart";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import arrowL from "../../../../Service/Arrow/Arrow";

const moment = extendMoment(originalMoment);

export class MyCarProfile extends Component {
  constructor(props) {
    super(props);
    const today = moment();
    this.state = {
      value1: "select",
      stats: [],
      temp: [],
      time: [],
      weather: [],
      road: [],
      drive: [],
      zone: [],
      kind: [],
      detail: [],
      fromal: [],
      fuel: [],
      additional: [],
      basic: [],
      power: [],
      operation: [],
      ac_dc: [],
      spent: [],
      isOpen: false,
      today: null,
      lol: null,
      fol: null,
      chart: [],
      isLoading: true,
      charge: [],
      widgets: [],
      driver_list: [],
      lol1: null,
      fol1: null,
      car_photo: [],
      value: moment.range(today.clone().subtract(0, "days"), today.clone()),
    };
  }

  onToggle = (event) => {
    if (event.target.value === "date") {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  // Get Data from filter date
  getData = async (value, states) => {
    this.setState({ isLoading: true });
    getCarProfile(
      this.props.match.params.pk,
      value.end.format("YYYY-MM-DD"),
      value.start.format("YYYY-MM-DD")
    ).then((data) => {
      this.setState({
        stats: data,
        temp: data.temperature,
        time: data.time,
        weather: data.weather,
        road: data.road,
        drive: data.driving,
        zone: data.zone,
        kind: data.kind,
        detail: data.stats,
        basic: data.basicInformation,
        fromal: data.formalInformation,
        fuel: data.fuelInformation,
        car_photo: data.carphotos,
        additional: data.additionalInformation,
        chart: data.charts,
        power: data.powerBodyKit,
        lol1: value.start,
        fol1: value.end,
        driver_list: data.drivers,
        charge: data.charging_information,
        operation: data.oprationTime,
        ac_dc: data.energySourcekwh,
        isLoading: false,
        spent: data.spentRecoveredTime,
        widgets: data.widgets,
        isOpen: !this.state.isOpen,
      });
    });
  };

  getOptionData = async () => {
    //this.setState({ isLoading: true });
    Promise.all([
      getCarProfile(
        this.props.match.params.pk,
        DateGte(this.state.today),
        DateLte(this.state.today)
      ).then((data) => {
        this.setState({
          stats: data,
          temp: data.temperature,
          time: data.time,
          weather: data.weather,
          road: data.road,
          drive: data.driving,
          zone: data.zone,
          kind: data.kind,
          detail: data.stats,
          basic: data.basicInformation,
          fromal: data.formalInformation,
          fuel: data.fuelInformation,
          additional: data.additionalInformation,
          power: data.powerBodyKit,
          chart: data.charts,
          charge: data.charging_information,
          operation: data.oprationTime,
          ac_dc: data.energySourcekwh,
          spent: data.spentRecoveredTime,
          widgets: data.widgets,
          car_photo: data.carphotos,
          driver_list: data.drivers,
          isLoading: false,
          lol: this.state.today,
          fol: this.state.today,
        });
      }),
    ]);
  };

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.getOptionData("");
  };

  componentDidMount() {
    this.getOptionData("");
  }
  // componentDidUpdate() {
  //   this.getOptionData("");
  // }

  render() {
    const {
      stats,
      detail,
      power,
      basic,
      fromal,
      driver_list,

      fuel,
      additional,
      temp,
      time,
      weather,
      road,
      drive,
      zone,
      kind,
      chart,
      charge,
      operation,
      ac_dc,
      spent,
      lol,
      fol,
      lol1,
      fol1,
      widgets,
    } = this.state;
    const { t } = this.props;
    var d = new Date();
    var mm = d.getMonth() + 1;
    var dd = d.getDate();
    var yy = d.getFullYear();
    var ccc = yy + "-" + mm + "-" + dd; //(US)
    const base = process.env.PUBLIC_URL;
    return (
      <div
        className="Main_section"
        style={{ marginTop: "30px", paddingLeft: "30px", paddingRight: "30px" }}
      >
        <Helmet>
          <title>
            {" "}
            {stats.license_plate
              ? stats.license_plate
              : "Car Metrics Profile Details"}{" "}
            Metric Profile Details
          </title>
          <meta charSet="utf-8" />
          <meta
            name="Helmet application"
            content="Car profile and metric page including all overall details score, basic information, formal information, vehicle information and so on ."
          />
        </Helmet>

        <div className="row row1" style={{ width: "100%", marginTop: "40px" }}>
          <div className="col-sm-3">
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
          <div className="col-sm-6">
            <Filter
              lol={this.state.lol}
              fol={this.state.fol}
              today={this.state.today}
              option={this.state.isOpen}
              toggle={this.onToggle}
              change={this.getData}
              hello={this.onChange}
            />
          </div>
        </div>
        <ProfileTop />

        <div className="headerr" id="headerr" style={{ marginTop: "-6px" }}>
          {stats.detail ? (
            this.state.isLoading === true ? (
              <Spinner />
            ) : (
              <p
                className="text-center h4 font-weight-bolder"
                style={{ marginTop: "30px" }}
              >
                {t("no_data")}
              </p>
            )
          ) : (
            <div>
              {this.state.isLoading === true ? <Spinner /> : ""}
              <div>
                <a href="# " onClick={this.handleClick.bind(this)}>
                  <img
                    src={arrow_left}
                    alt="Left_Arrow"
                    title="Back to previous page"
                    style={{ height: "2.6em" }}
                  />
                </a>
                {t("back")} &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;{" "}
                {t("car_profile").toUpperCase()} /{" "}
                <strong>
                  {stats.license_plate ? stats.license_plate : "-"}{" "}
                </strong>{" "}
                &nbsp;{" "}
                <a
                  href={
                    base + "/overview/app/my/car/" + stats.id + "/" + stats.type
                  }
                  title="Click to view car trips"
                  className="font-weight-bold"
                  style={{
                    color: "#b71c1c",
                    position: "relative",
                    right: "-50px",
                  }}
                >
                  {t("trips").toUpperCase()}
                </a>
              </div>

              <div className="" style={{ marginTop: "-50px" }}>
                <nav className="navbar navbar-expand-lg navbar-dark float-right">
                  <ul className="navbar-nav ">
                    <li className="nav-item  " style={{ marginLeft: "20px" }}>
                      <a
                        href={base + `/editcar/app/carmetrics/${stats.id}/`}
                        title="Click to edit car details"
                      >
                        <img
                          src={edit}
                          alt="Edit"
                          className={
                            window.localStorage.i18nextLng === "pl"
                              ? "polish1"
                              : "english"
                          }
                          style={{ marginBottom: "-5px", height: "1.5em" }}
                        />
                        <br></br>
                        <span
                          style={{
                            color: "rgb(183, 28, 28)",
                            fontSize: "11px",
                            marginLeft: "0px",
                          }}
                        >
                          {t("edit")}
                        </span>
                      </a>
                    </li>
                    <li
                      className="nav-item  "
                      disabled
                      style={{ marginLeft: "20px" }}
                    >
                      <a
                        href="# "
                        onClick={getPrint}
                        title="Click to view print page in pdf form"
                      >
                        <img
                          src={
                            window.localStorage.i18nextLng === "en"
                              ? print
                              : print_pl
                          }
                          alt="Print page"
                          style={{ marginTop: "2px", height: "3.1em" }}
                        />
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

              <div
                className="row  justify-content-center"
                style={{ width: "100%", paddingLeft: "0px" }}
              >
                {widgets.map((ii, i) => (
                  <div
                    className="col-md-2 something_image"
                    style={{
                      marginTop: "5px",

                      backgroundImage: `url(${ii.background})`,
                    }}
                    key={i + 1}
                  >
                    <div
                      className="box  "
                      style={{
                        width: "200px",
                        marginTop: "25px",
                        height: "132px",
                      }}
                    >
                      <div className="fleet_data">
                        {ii.link === "critical" ? (
                          <p id="fleet_main_title" className="text-left">
                            <a
                              href={
                                base +
                                `/critical/overspeed/data/${stats.id}/${
                                  lol1
                                    ? `${lol1.format("YYYY-MM-DD")}`
                                    : lol
                                    ? DateLte(this.state.today)
                                    : "2016-01-04"
                                }/${
                                  fol1
                                    ? `${fol1.format("YYYY-MM-DD")}`
                                    : fol
                                    ? DateGte(this.state.today)
                                    : `${ccc}`
                                }`
                              }
                              title="Click to view Critical overSpeeding"
                              id="fleet_main_title "
                              className="link_title"
                            >
                              {ii.label ? ii.label : "-"}
                            </a>
                          </p>
                        ) : (
                          <p id="fleet_main_title" className="text-left">
                            {ii.label ? ii.label : "-"}
                          </p>
                        )}

                        <div className="row  text-section ">
                          <div
                            className={
                              ii.icon === null ? "col-md-12" : "col-md-5"
                            }
                          >
                            <h1 className="fleet_title">
                              {" "}
                              {ii.value ? ii.value : "-"}
                            </h1>

                            <p  className="percentage_change">
                              {arrowL(
                                ii.changeDirection,
                                ii.changePositive,
                                ii.change
                              )}
                            </p>
                          </div>
                          <div
                            className={ii.icon === null ? "col" : "col-md-7"}
                          >
                            {ii.icon !== null && (
                              <img
                                src={ii.icon ? ii.icon : ""}
                                alt="weight"
                                className="fleet_image"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="fleet_graph" style={{ paddingLeft: "50px" }}>
                <div
                  className="row just_finish justify-content-center"
                  style={{ width: "100%" }}
                >
                  {chart.map((c, i) => (
                    <div className="col-md-6 sm" key={i + 1}>
                      <Chart chart={chart} i={c} />
                    </div>
                  ))}
                </div>
              </div>

              <div className="row" style={{ marginTop: "50px" }}>
                {/* Basic information section */}
                <div className="col-md-5">
                  <h3
                    className="font-weight-normal"
                    style={{ marginTop: "30px" }}
                  >
                    {t("vehicle_information")}
                  </h3>
                  <hr></hr>
                  <div className="row">
                    {basic.map((c) => (
                      <div
                        className="col-sm-6"
                        style={{ marginBottom: "20px" }}
                      >
                        <div className=" n">
                          <p id="pppp">{c.label.toUpperCase()}</p>
                          <p className="pppp1">
                            {c.value ? `${c.value}` : "-"}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Demographic information section */}
                <div className="col-md-7">
                  <h3
                    className="font-weight-normal"
                    style={{ marginTop: "30px" }}
                  >
                    {t("formal_information")}
                  </h3>
                  <hr></hr>
                  <div className="row">
                    {fromal.map((c) => (
                      <div
                        className="col-sm-4"
                        style={{ marginBottom: "20px" }}
                      >
                        <div className=" n">
                          <p id="pppp">{c.label.toUpperCase()}</p>
                          <p className="pppp1">
                            {c.value ? `${c.value}` : "-"}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Power Body and fuel section  */}

              <div>
                <h3
                  className="font-weight-normal"
                  style={{ marginTop: "70px" }}
                >
                  {t("power_body_kit")}
                </h3>
                <hr></hr>

                <div className="row" style={{ marginTop: "20px" }}>
                  {power.map((c) => (
                    <div className="col-sm-3">
                      <p id="pppp">{c.label.toUpperCase()}</p>
                      <p className="pppp1">{c.value ? `${c.value}` : "-"}</p>
                    </div>
                  ))}

                  {fuel.length > 0
                    ? fuel.map((c) => (
                        <div className="col-sm-3">
                          <p id="pppp">{c.label.toUpperCase()}</p>
                          <p className="pppp1">
                            {c.value ? `${c.value}` : "-"}
                          </p>
                        </div>
                      ))
                    : ""}
                </div>
              </div>

              {/* More Information */}
              <div>
                <h3
                  className="font-weight-normal"
                  style={{ marginTop: "70px" }}
                >
                  {t("more_information")}
                </h3>
                <hr></hr>

                <div className="row" style={{ marginTop: "20px" }}>
                  {detail.map((c) => (
                    <div className="col-sm-3">
                      <p id="pppp">{c.label.toUpperCase()}</p>
                      <p className="pppp1">{c.value ? `${c.value}` : "-"}</p>
                    </div>
                  ))}
                </div>
              </div>

              {stats.type === "BEV" && (
                <div className="row" style={{ marginTop: "30px" }}>
                  <div className="col-md-6">
                    <h3
                      className="font-weight-normal"
                      style={{ marginLeft: "15px" }}
                    >
                      {t("charging_information")} &nbsp; &nbsp;{" "}
                    </h3>
                    <hr></hr>

                    <div className="row">
                      {charge.map((c, i) => (
                        <div
                          className="col-md-4"
                          key={i + 1}
                          style={{ marginBottom: "20px" }}
                        >
                          <div className=" n">
                            <p id="pppp">{c.label.toUpperCase()}</p>
                            <p className="pppp1">
                              {c.value ? `${c.value}` : "-"}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h3 className="font-weight-normal">
                      {" "}
                      {t("energy_overview")}
                    </h3>
                    <hr></hr>
                    <div className="row">
                      <div className="col-md-4">
                        {operation.length > 1 ? (
                          <Driving1 energy={operation} />
                        ) : (
                          <p className="pppp1">No data available</p>
                        )}
                      </div>
                      <div className="col-md-4">
                        {ac_dc.length > 1 ? (
                          <Plug energy={ac_dc} />
                        ) : (
                          <p className="pppp1">No data available</p>
                        )}
                      </div>
                      <div className="col-md-4">
                        {spent.length > 1 ? (
                          <Recover energy={spent} />
                        ) : (
                          <p className="pppp1">No data available</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row" style={{ marginTop: "30px" }}>
                <div className="col-md-6">
                  <CarDoc photo={this.state.car_photo} />
                </div>

                <div className="col-md-6">
                  <Driverlist driver={driver_list} />
                </div>
              </div>

              <div className="static" style={{ marginTop: "30px" }}>
                <h3
                  className="font-weight-normal"
                  style={{ marginTop: "30px" }}
                >
                  {t("claim_doc")}
                </h3>
                <hr></hr>
              </div>

              {/* All Chart and histogram section */}
              <div className="static">
                <h3
                  className="font-weight-normal "
                  style={{ marginTop: "50px" }}
                >
                  {t("static")}
                </h3>
                <hr></hr>
                <div className="row" style={{ width: "100%" }}>
                  {time.length > 0 ? <Time load={time} /> : ""}
                  {weather.length > 0 ? <Weather load={weather} /> : ""}
                  {temp.length > 0 ? <Temprature load={temp} /> : ""}
                </div>

                <div
                  className="row"
                  style={{ marginTop: "30px", width: "100%" }}
                >
                  {drive.length > 0 ? <Driving load={drive} /> : ""}
                  {road.length > 0 ? <Road load={road} /> : ""}
                  {zone.length > 0 ? <Zone load={zone} /> : ""}
                  {kind.length > 0 ? <Type load={kind} /> : ""}
                </div>
              </div>

              <div className="" style={{ marginTop: "30px" }}>
                <h3
                  className="font-weight-normal"
                  style={{ marginTop: "30px" }}
                >
                  {t("additional_information")}
                </h3>
                <hr></hr>
                <Additional add={additional} />
              </div>

              {/* Recommendation and notes Section */}
              <div className="row" style={{ marginTop: "50px" }}>
                <div className="col-md-6">
                  <h3
                    className="font-weight-normal"
                    style={{ marginTop: "30px" }}
                  >
                    {t("Recommendation1")}
                  </h3>
                  <hr></hr>
                </div>

                {/* Notes section */}
                <div className="col-md-6">
                  <h3
                    className="font-weight-normal"
                    style={{ marginTop: "30px" }}
                  >
                    {t("notes")}
                  </h3>
                  <hr></hr>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(MyCarProfile);
