import React, { useState, useEffect } from "react";
import head from "../../../img/Logo1.png";
import config from "../../../views/Main";
import Language from "../../Layout/Language";
// import { useTranslation } from "react-i18next";

import Cookies from "universal-cookie";
const cookie = new Cookies();
let authToken = cookie.get("Token");

function Header() {
  const [name, setName] = useState([]);

  useEffect(() => {
    getUserData();
  });

  const getUserData = async () => {
    const res = await fetch(config.apiUrl.my, config.head);
    const data = await res.json();
    setName(data);
  };

  //const { t } = useTranslation();
  const baseUrl = process.env.PUBLIC_URL;

  return (
    <div className="bg-white">
      <nav className="navbar navbar-expand-lg navbar-dark bg-white fixed Main_section1">
        <a
          className="navbar-brand"
          href={authToken ? baseUrl + "/fleet/dashboard/" : baseUrl + "/"}
          style={{ color: "#212226" }}
        >
          <img
            src={name.logo ? name.logo : head}
            alt="MainImag"
            style={{ height: "45px" }}
          ></img>{" "}
        </a>

        <button
          className="navbar-toggler"
          style={{ background: "#b71c1c" }}
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <Language />
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Header;
