import React, { Component } from "react";
import Chart from "react-apexcharts";
import { withTranslation } from "react-i18next";
import Color from "../Color";

const colors = ["#465dff"];
export class Time extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      name: [],
      options: {
        chart: {
          id: "Time of The Day",
        },

        dataLabels: {
          enabled: false,
        },

        colors: colors,
        xaxis: {
          type: "category",
          title: {
            text: t("driver_graphy_time"),
            style: {
              fontSize: "10px",
              color: "#8D8D90",
            },
          },
          labels: {
            style: {
              //   colors: colors,
              fontSize: "14px",
            },
          },

          categories: ["7-10", "10-15", "15-19", "19-7"],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            title: {
              text: t("driver_graph_y"),
              style: {
                fontSize: "10px",
                color: "#8D8D90",
              },
            },
            labels: {
              formatter: function (value) {
                return value + " % ";
              },
            },
            axisBorder: {
              show: true,
              // color: "#b71c1c"
            },
          },
        ],

        tooltip: {
          y: {
            formatter: (value) => {
              return value + "%";
            },
          },
        },

        plotOptions: {
          bar: {
            columnWidth: "30%",
            endingShape: "flat",
            distributed: true,
          },
        },

        stroke: {
          width: [0, 2],
        },
        title: {
          // text: t("time_of_day"),
          align: "center",
          style: {
            fontSize: "17px",
            color: "black",
          },
        },
        legend: {
          show: false,
        },
        labels: ["7-10", "10-15", "15-19", "19-7"],
      },
      series: [
        {
          name: "Time of The Day",
          data: [],
        },
      ],
      // don: []
    };
  }

  render() {
    const fun = this.props.load;
    const { t } = this.props;
    const dat1 = fun.map((c) => {
      return c.data;
    });
    const newSeries = [];
    newSeries.push({
      data: dat1,
      name: t("time_of_day"),
    });

    return (
      <div className="row">
        <div className="col-sm-4">
          <h5
            className="text-left font-weight-normal"
            style={{ marginLeft: "26px", marginTop: "10px" }}
          >
            {t("time_of_day")}
          </h5>
          <Chart
            style={{ marginTop: "22px" }}
            options={this.state.options}
            series={newSeries}
            type="bar"
            stacked="true"
            height="250"
          />
        </div>

        <div className="col-sm-8" style={{ marginTop: "20px" }}>
          <table className="table">
            <thead>
              <tr>
                <th className="th_line">{t("time_day")}</th>
                <th className="th_line">{t("percentage")}</th>
                <th className="th_line">{t("OverallScore")}</th>
                <th className="th_line">{t("OverallSpeed")}</th>
                <th className="th_line"> {t("OverallDynamic")}</th>
                <th className="th_line">{t("OverallEfficiency")}</th>
              </tr>
            </thead>
            <tbody>
              {fun.length > 0 ? (
                fun.map((c, i) => (
                  <tr key={i + 1}>
                    <td>{c.label}</td>
                    <td>{c.data} %</td>
                    <td className="lom">
                      <span className={Color(c.overall_score)}>
                        {c.overall_score
                          ? `${c.overall_score.toFixed(1)}`
                          : "-"}
                      </span>
                    </td>
                    <td className="lom">
                      <span className={Color(c.speed_score)}>
                        {c.speed_score ? `${c.speed_score.toFixed(1)}` : "-"}
                      </span>
                    </td>
                    <td className="lom">
                      <span className={Color(c.dynamics_score)}>
                        {c.dynamics_score
                          ? `${c.dynamics_score.toFixed(1)}`
                          : "-"}
                      </span>
                    </td>
                    <td className="lom">
                      <span className="summary">
                        {/* {c.fuel_score ? `${c.fuel_score.toFixed(1)}` : "-"} */}
                        -
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <p className="text-center">No Data Available in this table</p>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Time);
