import React, { Component } from "react";
import { Helmet } from "react-helmet";
import config from "../Main";
import Additional from "./Additional";
import Prize from "./Prize";
import Swal from "sweetalert2";
import axios from "axios";
import Campaign from "../../Components/Layout/Campaign";
import User from "../../Components/User";
import Datee from "../Views/Date";
import arrow_left from "../../img/Aarrow.png";
import { withTranslation } from "react-i18next";
import { getDriverCampaign } from "../../Service/Driver";
import Cookies from "universal-cookie";
const cookie = new Cookies();
let authToken = cookie.get("Token");

class CreateR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driver_list: [],
      isHidden: true,
      isOption: true,
      title: "",
      lead: "",
      span: "",
      without: "",
      export: "",
      auto: "",
      isChecked: false,
      driver: [],
      startDate: null,
      endDate: null,
      parameter: "",
      driverList: [],
      prizes: [
        {
          number_list: [],
          prize_type: "",
          name: "",
          value: null,
          quantity: "",
        },
      ],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.toggleHie = this.toggleHie.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChecked() {
    this.setState({ isChecked: !this.state.isChecked });
  }
  toggleOption() {
    this.setState({ isOption: !this.state.isOption });
  }

  async onChange(event) {
    await this.setState({
      [event.target.name]: event.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    Swal.fire({
      title: "Loading",
      type: "warning",
      showCancelButton: false,
      showConfirmButton: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    const data = {
      title: this.state.title,
      lead: this.state.lead,
      span: this.state.span,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      parameter: this.state.parameter,
      prizes: [...this.state.prizes],
      driverList: this.props.location.state.selectedItems,
    };

    axios({
      method: "post",
      url: config.apiUrl.campaignn,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },

      data,
    })
      .then((res) => {
        this.setState({
          title: "",
          lead: "",
          span: "",
          startDate: "",
          endDate: "",
          parameter: "",
          prizes: [
            {
              number_list: [],
              prize_type: "",
              name: "",
              value: null,
              quantity: "",
            },
          ],
          driverList: [],
        });
        this.props.history.push("/campaign/");
        Swal.fire({
          title: "Campaign",
          type: "success",
          text: "Campaig Added Successfully  !",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          title: "Campaign",
          type: "error",
          text: "Error while Creating new Campaign!",
          timer: 2000,
        });
      });
  }

  handleChange = async (e) => {
    let target = e.target;
    let name = target.name;
    let value = Array.from(target.selectedOptions, (option) => option.value);
    await this.setState({
      [name]: value,
    });
  };

  toggleHidden() {
    this.setState({
      isHidden: false,
    });
  }

  toggleHie() {
    this.setState({ isHidden: true });
  }
  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }
  componentDidMount() {
    getDriverCampaign(this.props.location.state.selectedItems).then((data) => {
      this.setState({ driver_list: data });
    });
  }

  render() {
    const { t } = this.props;
    const { driver_list } = this.state;
    const base = process.env.PUBLIC_URL;
    return (
      <div
        className=""
        style={{ marginTop: "px", width: "100%", padding: "40px" }}
      >
        <Helmet>
          <title>{t("Camapaing_Add")}</title>
        </Helmet>

        <div
          className="row row1"
          style={{ width: "100%", marginBottom: "-30px" }}
        >
          <div className="col-sm-3 date_time" style={{ marginTop: "-5px" }}>
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
        </div>
        <Campaign />

        <div className="headerr">
          <div>
            <div>
              <a href="# " onClick={this.handleClick.bind(this)}>
                <img
                  src={arrow_left}
                  alt="Left_Arrow"
                  style={{ height: "2.6em" }}
                />
              </a>
              &nbsp;
              <strong>{t("Camapaing_Add")}</strong> &nbsp;
            </div>
          </div>
          <form>
            <div className="row">
              <div className="col-sm-6">
                <h3
                  className="font-weight-normal"
                  style={{ marginTop: "30px" }}
                >
                  {t("new_campaing")}
                </h3>
                <hr style={{ width: "200%" }}></hr>

                {/* Start row here */}
                <div className="row">
                  <div className="col-sm-8">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("Campain_name").toUpperCase()}
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        name="title"
                        value={this.state.title}
                        onChange={this.onChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                {/* End Here Row */}

                {/* Start row here */}
                <div className="row" style={{ marginTop: "10px" }}>
                  <div className="col-sm-8">
                    <div className="form-group">
                      <label id="p" for="exampleInputEmail1">
                        {t("description").toUpperCase()}
                      </label>
                      <input
                        type="text"
                        placeholder={t("type")}
                        name="lead"
                        value={this.state.lead}
                        onChange={this.onChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                {/* End Here Row */}
              </div>

              <div className="col-sm-6">
                {/* <h3
                  className="font-weight-normal"
                  style={{ marginTop: "64px" }}
                ></h3> */}

                <Additional
                  loaddata={this.onChange}
                  vall={this.state}
                  handle={this.handleChange}
                />
              </div>
            </div>

            <p>
              You Already Select driver segment <br></br>
              SelectDrive List :
              <ul id="menu">
                {this.props.location.state.selectedItems.map((c) => (
                  <li>{c}</li>
                ))}
              </ul>
              <table
                id="headerr"
                className="table table-hover tabell12 camp_table"
              >
                <thead>
                  <tr className="th ">
                    <th className="t">{t("Id")}</th>
                    <th className="t">{t("Name")} </th>
                    <th className="t">{t("plate_number")} </th>
                    <th className="t">{t("milage")} </th>
                  </tr>
                </thead>
                <tbody>
                  {driver_list.map((c, i) => (
                    <tr key={i + 1}>
                      <td>{i + 1}</td>
                      <td>
                        {" "}
                        <a
                          href={
                            base + "/overview/app/drivermetrics/" + c.id + "/"
                          }
                          style={{ color: "#b71c1c" }}
                          title="Click to view driver profile"
                          className="font-weight-bold"
                        >
                          {c.name ? `${c.name}` : " ? "}
                        </a>
                      </td>
                      <td>
                        <a
                          href={
                            base + "/overview/app/carmetrics/" + c.car_id + "/"
                          }
                          title="Click to view car profile"
                          style={{ color: "#b71c1c" }}
                          className="font-weight-bold"
                        >
                          {c.plate_number ? `${c.plate_number}` : " - "}{" "}
                        </a>
                      </td>
                      <td>{c.milage ? `${c.milage.toFixed(1)}` : " ? "}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* )} */}
            </p>

            {/* <div className="col-sm-8" style={{ marginTop: "15px" }}>
              <a
                className="text-right"
                style={{ color: "#b71c1c" }}
                href="# "
                onClick={this.toggleOption.bind(this)}
              >
                {t("hide/show").toUpperCase()}{" "}
              </a>
            </div> */}

            <div>
              <Prize
                loaddata={this.onChange}
                vall={this.state.prizes}
                state={this.state.without}
                handle={this.handleList}
              />
            </div>
            <center>
              <button
                className="btn btn-danger br text-white"
                value="create campaign"
                onClick={this.handleSubmit}
                style={{ background: "#b71c1c" }}
              >
                {t("create_campaing")}
              </button>
            </center>
          </form>

          {/* Form End Here */}
        </div>
      </div>
    );
  }
}

export default withTranslation()(CreateR);
