import React, { Component } from "react";
import { ButtonToolbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import Summary from "./Chart/Summary";
import Fullscreen from "react-full-screen";
import "../../Views/OldDashboard/Graph/index";
import "./Driver.css";

class Driver extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFull: false,
    };
  }

  entering = (e) => {
    e.children[0].style.borderTopColor = "black";
    e.children[1].style.color = "black";
    e.children[1].style.border = "1px solid black";

    e.children[1].style.background = "white";
  };
  goFull = () => {
    this.setState({ isFull: !this.state.isFull });
  };

  render() {
    // const { t } = this.props;

    return (
      <div>
        <div className="row" style={{ marginBottom: "7px" }}>
          <div className="col-sm-12">
            <h3 className="text-left font-weight-medium chart_text">
              <ButtonToolbar className="">
                <OverlayTrigger
                  key="top"
                  onEntering={this.entering}
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-topop">
                      {this.props.i.description}
                    </Tooltip>
                  }
                >
                  <span>
                    {this.props.i.label}
                    <i className="fa fa-info-circle "></i>
                  </span>
                </OverlayTrigger>
              </ButtonToolbar>
            </h3>
          </div>
        </div>

        <div className="boxx1 dfd ">
          <Fullscreen
            enabled={this.state.isFull}
            onChange={(isFull) => this.setState({ isFull })}
            style={{ height: "90vh" }}
          >
            <span class="chart_line">
              {window.fullScreen ? (
                <span
                  class=" fa  float-right bg-white"
                  style={{
                    marginLeft: "20px",
                    paddinRight: "30px",
                    cursor: "pointer",
                  }}
                >
                  <span onClick={this.goFull}>&#xf065;</span>
                </span>
              ) : (
                <i
                  class="fa fa-compress float-right"
                  style={{ marginLeft: "20px", cursor: "pointer" }}
                  onClick={this.goFull}
                ></i>
              )}

              {/* <span onClick={getPrintChart}> <i class="fa fa-print"></i> </span> */}
              <nav class="somethin_work">
                <ul
                  className="nav nav-pills mx-auto  tabbable bg-white"
                  id="pills-tab"
                  role="tablist"
                  style={{ width: "100%" }}
                >
                  {this.props.i.items.map((c, ii) => (
                    <li className="nav-item btnn" key={ii + 1}>
                      <a
                        className={
                          ii + 1 === 1
                            ? "nav-link  sss font-weight-medium active"
                            : "nav-link  sss font-weight-medium"
                        }
                        id={`pills-home-tab${c.slug}`}
                        data-toggle="pill"
                        href={`#pills-home${c.slug}`}
                        role="tab"
                        aria-controls={`pills-home${c.slug}`}
                        aria-selected="true"
                        style={{
                          background: "none",
                          color: "black",
                        }}
                      >
                        {c.label ? c.label : "-"}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>

              <div className="tab-content" id="pills-tabContent">
                {this.props.i.items.map((c, ii) => (
                  <div
                    key={ii + 1}
                    className={
                      ii + 1 === 1
                        ? "tab-pane fade show  fleet_data active"
                        : "tab-pane fade show  fleet_data"
                    }
                    id={`pills-home${c.slug}`}
                    role="tabpanel"
                    aria-labelledby={`pills-home-tab${c.slug}`}
                  >
                    {this.props.i.items.length > 0 ? <Summary chat={c} /> : ""}
                  </div>
                ))}
              </div>
            </span>
          </Fullscreen>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Driver);
