import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import search from "../../img/search.png";
import { withTranslation } from "react-i18next";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#1B1B23",
      opacity: "0.6",
      fontSize: "15px",

      letterSpacing: "0.2px",
    },

    "& label": {
      color: "rgb(27, 27, 35,0.5)",
      marginLeft: "5px",
      borderBottomColor: "rgb(27, 27, 35,0.2)",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#B71C1C",
    },

    "& .MuiInput-underline:before": {
      borderBottomColor: "#8d8d90",
      opacity: "1",
    },
  },
})(TextField);

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handeSeach = this.handeSeach.bind(this);
  }

  handeSeach(e) {
    try {
      e.preventDefault();
      var input, filter, table, tr, td, i;
      input = document.getElementById("custom-css-outlined-input");
      filter = input.value.toUpperCase();
      table = document.getElementById("headerr");
      tr = table.getElementsByTagName("tr");
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[1];
        if (td) {
          if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  render() {
    const { t } = this.props;
    return (
      <form
        noValidate
        autoComplete="off"
        onKeyUp={this.props.loaddata}
        style={{ marginTop: "-30px" }}
      >
        <div className="form-group">
          <CssTextField
            style={{
              width: "200px",
            }}
            onKeyUp={this.handeSeach}
            id="custom-css-outlined-input"
            label={t("find_driver")}
            margin="normal"
            name="search"
            autoComplete="off"
          />
        </div>
        <img
          src={search}
          alt="Search"
          className="search"
          style={{ width: "25px" }}
        />
        {/* <i className="fa fa-search search" style={{ width: "20px" }}></i> */}
      </form>
    );
  }
}

export default withTranslation()(Search);
