import React, { Component } from "react";
import ProfileTop from "../../../../Components/Layout/ProfileTop";
import User from "../../../../Components/User";
import Datee from "../../../Views/Date";
import Filter from "../../Filter";
import { Helmet } from "react-helmet";
import { withTranslation } from "react-i18next";
import arrow_left from "../../../../img/Aarrow.png";
import edit from "../../../../img/edit.png";
import Driving1 from "./Chart/Driving";
import Recover from "./Chart/Recover";
import Plug from "./Chart/Plug";
import { getCarProfile } from "../../../../Service/Car";
import Time from "../../MyCar/Profile/Graph/TIme";
import Temprature from "../../MyCar/Profile/Graph/Temprature";
import Weather from "../../MyCar/Profile/Graph/Weather";
import Type from "../../MyCar/Profile/Percent/Type";
import Zone from "../../MyCar/Profile/Percent/Zone";
import Road from "../../MyCar/Profile/Percent/Road";
import Driving from "../../MyCar/Profile/Percent/Driving";
import DateGte from "../../../../Service/Arrow/DateGte";
import DateLte from "../../../../Service/Arrow/DateL";
import arrowL from "../../../../Service/Arrow/Arrow";
import Add from "./Table/Add";
import Chart from "../../MyDriver/Profile/Chart/Chart";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import Spinner from "../../../../Container/Spinner";

const moment = extendMoment(originalMoment);

export class EDetails extends Component {
  constructor(props) {
    super(props);
    const today = moment();
    this.state = {
      stats: [],
      basic: [],
      charge: [],
      source: [],
      operation: [],
      ac_dc: [],
      spent: [],
      time: [],
      ctime: [],
      lol: null,
      fol: null,
      today: null,
      isOpen: false,
      stats1: [],
      temp: [],
      time1: [],
      weather: [],
      road: [],
      drive1: [],
      zone: [],
      kind: [],
      detail: [],
      fromal: [],
      fuel: [],
      additional: [],
      basic1: [],
      power: [],
      chart: [],
      isLoading: true,
      widgets: [],
      value: moment.range(today.clone().subtract(0, "days"), today.clone()),
    };
  }

  onToggle = (event) => {
    if (event.target.value === "date") {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  getData = async (value, states) => {
    this.setState({ isLoading: true });
    getCarProfile(
      this.props.match.params.pk,
      value.end.format("YYYY-MM-DD"),
      value.start.format("YYYY-MM-DD")
    ).then((data) => {
      this.setState({
        stats1: data,
        temp: data.temperature,
        time1: data.time,
        weather: data.weather,
        road: data.road,
        drive1: data.driving,
        zone: data.zone,
        kind: data.kind,
        detail: data.stats,
        basic1: data.basicInformation,
        fromal: data.formalInformation,
        fuel: data.fuelInformation,
        additional: data.additionalInformation,
        power: data.powerBodyKit,
        isLoading: false,
        charge: data.charging_information,
        lol: value.start,
        fol: value.end,
        chart: data.charts,
        isOpen: !this.state.isOpen,
        operation: data.oprationTime,
        ac_dc: data.energySourcekwh,
        widgets: data.widgets,
        spent: data.spentRecoveredTime,
      });
    });
  };

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  getOptionData = async () => {
    this.setState({ isLoading: true });
    getCarProfile(
      this.props.match.params.pk,
      DateGte(this.state.today),
      DateLte(this.state.today)
    ).then((data) => {
      this.setState({
        stats1: data,
        temp: data.temperature,
        time1: data.time,
        weather: data.weather,
        road: data.road,
        drive1: data.driving,
        zone: data.zone,
        kind: data.kind,
        detail: data.stats,
        basic1: data.basicInformation,
        fromal: data.formalInformation,
        fuel: data.fuelInformation,
        additional: data.additionalInformation,
        power: data.powerBodyKit,
        isLoading: false,
        charge: data.charging_information,
        operation: data.oprationTime,
        ac_dc: data.energySourcekwh,
        widgets: data.widgets,
        spent: data.spentRecoveredTime,
        chart: data.charts,
      });
    });
  };

  async componentDidMount() {
    this.getOptionData("");
  }

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.getOptionData("");
  };

  render() {
    const { t } = this.props;
    const {
      drive1,
      charge,
      operation,
      ac_dc,
      spent,
      detail,
      power,
      basic1,
      fromal,
      fuel,
      additional,
      temp,
      time1,
      weather,
      road,
      zone,
      kind,
      stats1,
      widgets,
      chart,
    } = this.state;

    const base = process.env.PUBLIC_URL;
    return (
      <div className="Main_section">
        <Helmet>
          <title>WW345566 Metric Profile Details</title>
          <meta charSet="utf-8" />
          <meta
            name="Helmet application"
            content="Electric car profile and metric page including all overall details score, basic information, formal information, vehicle information and so on ."
          />
        </Helmet>

        <div className="row row1" style={{ width: "100%", marginTop: "40px" }}>
          <div className="col-sm-3">
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
          <div className="col-sm-6">
            <Filter
              lol={this.state.lol}
              fol={this.state.fol}
              today={this.state.today}
              option={this.state.isOpen}
              toggle={this.onToggle}
              change={this.getData}
              hello={this.onChange}
            />
          </div>
        </div>
        <ProfileTop />

        {/* Header To section End here  */}
        <div className="headerr" style={{ marginTop: "-6px" }}>
          {stats1.detail ? (
            this.state.isLoading === true ? (
              <Spinner />
            ) : (
              <p
                className="text-center h4 font-weight-bolder"
                style={{ marginTop: "30px" }}
              >
                {t("no_data")}
              </p>
            )
          ) : (
            <div>
              {this.state.isLoading === true ? <Spinner /> : ""}
              <div>
                <a href="# " onClick={this.handleClick.bind(this)}>
                  <img
                    src={arrow_left}
                    alt="Left_Arrow"
                    title="Back to previous page"
                    style={{ height: "2.6em" }}
                  />
                </a>{" "}
                Back &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;{" "}
                {t("electric_profile").toUpperCase()} /{" "}
                <strong>
                  {stats1.license_plate ? stats1.license_plate : "-"}{" "}
                </strong>{" "}
                &nbsp;{" "}
                <a
                  href={base + `/Overview/app/electric/${stats1.id}`}
                  className="font-weight-bold"
                  style={{
                    color: "#b71c1c",
                    position: "relative",
                    right: "-50px",
                  }}
                >
                  {t("trips").toUpperCase()}
                </a>
              </div>

              <div className="" style={{ marginTop: "-50px" }}>
                <nav className="navbar navbar-expand-lg navbar-dark float-right">
                  <ul className="navbar-nav ">
                    <li className="nav-item  " style={{ marginLeft: "20px" }}>
                      <a href={base + `/editcar/app/carmetrics/${stats1.id}`}>
                        <img
                          src={edit}
                          className={
                            window.localStorage.i18nextLng === "pl"
                              ? "polish1"
                              : "english"
                          }
                          alt="contact"
                          style={{ marginBottom: "-5px", height: "1.5em" }}
                        />
                        <br></br>
                        <span
                          style={{
                            color: "rgb(183, 28, 28)",
                            fontSize: "11px",
                            marginLeft: "0px",
                          }}
                        >
                          {t("edit")}
                        </span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

              <div
                className="row justify-content-center "
                style={{ width: "100%", paddingLeft: "10px" }}
              >
                {widgets.map((ii, i) => (
                  <div
                    className="col-md-2 something_image"
                    style={{
                      marginTop: "5px",

                      backgroundImage: `url(${ii.background})`,
                    }}
                    key={i + 1}
                  >
                    <div
                      className="box  "
                      style={{
                        width: "200px",
                        marginTop: "25px",
                        height: "132px",
                      }}
                    >
                      <div className="fleet_data">
                        <p id="fleet_main_title" className="text-left">
                          {ii.label ? ii.label : "-"}
                        </p>

                        <div className="row  text-section ">
                          <div
                            className={
                              ii.icon === null ? "col-md-12" : "col-md-5"
                            }
                          >
                            <h1 className="fleet_title">
                              {" "}
                              {ii.value ? ii.value : "-"}
                            </h1>

                            <p className="percentage_change">
                              {arrowL(
                                ii.changeDirection,
                                ii.changePositive,
                                ii.change
                              )}
                            </p>
                          </div>
                          <div
                            className={ii.icon === null ? "col" : "col-md-7"}
                          >
                            {ii.icon !== null && (
                              <img
                                src={ii.icon ? ii.icon : ""}
                                alt="weight"
                                className="fleet_image"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="fleet_graph" style={{ paddingLeft: "50px" }}>
                <div
                  className="row just_finish justify-content-center"
                  style={{ width: "100%" }}
                >
                  {chart.map((c, i) => (
                    <div className="col-md-6 sm" key={i + 1}>
                      <Chart chart={chart} i={c} />
                    </div>
                  ))}
                </div>
              </div>

              <div className="row" style={{ marginTop: "50px" }}>
                {/* Basic information section */}
                <div className="col-md-6">
                  <h3
                    className="font-weight-normal"
                    style={{ marginTop: "30px" }}
                  >
                    {t("basic_information")}
                  </h3>
                  <hr></hr>
                  <div className="row">
                    {basic1.map((c, i) => (
                      <div
                        className="col-sm-4"
                        style={{ marginBottom: "20px" }}
                        key={i}
                      >
                        <div className=" n">
                          <p id="pppp">{c.label.toUpperCase()}</p>
                          <p className="pppp1">
                            {c.value ? `${c.value}` : "-"}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Demographic information section */}
                <div className="col-md-6">
                  <h3
                    className="font-weight-normal"
                    style={{ marginTop: "30px" }}
                  >
                    {t("formal_information")}
                  </h3>
                  <hr></hr>
                  <div className="row">
                    {fromal.map((c, i) => (
                      <div
                        className="col-sm-6"
                        style={{ marginBottom: "20px" }}
                        key={i}
                      >
                        <div className=" n">
                          <p id="pppp">{c.label.toUpperCase()}</p>
                          <p className="pppp1">
                            {c.value ? `${c.value}` : "-"}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Power And fuel section */}
              <div>
                <h3
                  className="font-weight-normal"
                  style={{ marginTop: "70px" }}
                >
                  {t("power_body_kit")}
                </h3>
                <hr></hr>

                <div className="row" style={{ marginTop: "20px" }}>
                  {power.map((c, i) => (
                    <div className="col-sm-3" key={i}>
                      <p id="pppp">{c.label.toUpperCase()}</p>
                      <p className="pppp1">{c.value ? `${c.value}` : "-"}</p>
                    </div>
                  ))}

                  {fuel.length > 0
                    ? fuel.map((c, i) => (
                        <div className="col-sm-3" key={i}>
                          <p id="pppp">{c.label.toUpperCase()}</p>
                          <p className="pppp1">
                            {c.value ? `${c.value}` : "-"}
                          </p>
                        </div>
                      ))
                    : ""}
                </div>
              </div>

              {/* More Information */}
              <div>
                <h3
                  className="font-weight-normal"
                  style={{ marginTop: "70px" }}
                >
                  {t("more_information")}
                </h3>
                <hr></hr>

                <div className="row" style={{ marginTop: "20px" }}>
                  {detail.map((c, i) => (
                    <div className="col-sm-3" key={i}>
                      <p id="pppp">{c.label.toUpperCase()}</p>
                      <p className="pppp1">{c.value ? `${c.value}` : "-"}</p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="row" style={{ marginTop: "30px" }}>
                <div className="col-md-6">
                  <h3
                    className="font-weight-normal"
                    style={{ marginLeft: "15px" }}
                  >
                    {t("charging_information")} &nbsp; &nbsp;{" "}
                  </h3>
                  <hr></hr>

                  <div className="row">
                    {charge.map((c, i) => (
                      <div
                        className="col-md-4"
                        key={i + 1}
                        style={{ marginBottom: "20px" }}
                      >
                        <div className=" n">
                          <p id="pppp" style={{ lineHeight: "10px" }}>
                            {c.label.toUpperCase()}
                          </p>
                          <p className="pppp1">
                            {c.value ? `${c.value}` : "-"}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-md-6">
                  <h3 className="font-weight-normal">
                    {" "}
                    {t("energy_overview")}
                  </h3>
                  <hr></hr>
                  <div className="row">
                    <div className="col-md-4">
                      {operation.length > 1 ? (
                        <Driving1 energy={operation} />
                      ) : (
                        <p className="pppp1">No data available</p>
                      )}
                    </div>
                    <div className="col-md-4">
                      {ac_dc.length > 1 ? (
                        <Plug energy={ac_dc} />
                      ) : (
                        <p className="pppp1">No data available</p>
                      )}
                    </div>
                    <div className="col-md-4">
                      {spent.length > 1 ? (
                        <Recover energy={spent} />
                      ) : (
                        <p className="pppp1">No data available</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Used Driver Section Section */}
              <div className="" style={{ marginTop: "30px" }}>
                <h3
                  className="font-weight-normal"
                  style={{ marginTop: "30px" }}
                >
                  Drivers
                </h3>
                <hr></hr>
                <table className="table" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>{t("Id")}</th>
                      <th>{t("Name")}</th>
                      <th>{t("phone")}</th>
                      <th>{t("email")}</th>
                      <th>{t("location")}</th>
                      <th className="">
                        <select
                          //onChange={this.change.bind(this)}
                          value={this.state.value1}
                          style={{
                            top: "0px",
                            width: "150px",
                          }}
                          class="minimal"
                        >
                          <option value="1"> {t("OverallScore")} </option>
                          <option value="2">{t("OverallSpeed")} </option>
                          <option value="3"> {t("OverallDynamic")} </option>
                          <option value="4"> {t("OverallEfficiency")} </option>
                        </select>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                </table>
              </div>

              {/* All Chart and histogram section */}
              <div className="static">
                <h3
                  className="font-weight-normal "
                  style={{ marginTop: "50px" }}
                >
                  {t("static")}
                </h3>
                <hr></hr>

                <div className="row" style={{ width: "100%" }}>
                  {time1.length > 0 ? <Time load={time1} /> : ""}

                  {weather.length > 0 ? <Weather load={weather} /> : ""}

                  {temp.length > 0 ? <Temprature load={temp} /> : ""}
                </div>

                <div
                  className="row"
                  style={{ marginTop: "30px", width: "100%" }}
                >
                  {/* {drive.length > 0 ? <Driving load={drive} /> : ""} */}
                  {drive1.length > 0 ? <Driving load={drive1} /> : ""}
                  {road.length > 0 ? <Road load={road} /> : ""}
                  {zone.length > 0 ? <Zone load={zone} /> : ""}
                  {kind.length > 0 ? <Type load={kind} /> : ""}
                </div>
              </div>

              <span>
                {" "}
                <Add add={additional} />{" "}
              </span>

              <div className="row" style={{ marginTop: "50px" }}>
                {/* Recommendation section */}
                <div className="col-md-6">
                  <h3
                    className="font-weight-normal"
                    style={{ marginTop: "30px" }}
                  >
                    Recommendation
                  </h3>
                  <hr></hr>
                </div>

                <div className="col-md-6">
                  <h3
                    className="font-weight-normal"
                    style={{ marginTop: "30px" }}
                  >
                    {t("notes")}
                  </h3>
                  <hr></hr>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(EDetails);
