import React, { Component } from 'react'
import { withTranslation } from "react-i18next";

export class Additional extends Component {
    constructor(props) {
        super(props);
        this.state ={}
    }
    render() {
        const {t} = this.props
        return (
            <span>
                {this.props.add.length > 0 && (
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ width: "10px" }}>{t("Id")}</th>
                    <th style={{ width: "150px" }}>{t("information")}</th>
                    <th>{t("Milage")}</th>
                    <th>{t("date")}</th>
                    <th>{t("Notification")}</th>
                    <th>{t("expirtation_milage")}</th>
                    <th>{t("expiration_date")}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.add.map((c) => (
                    <tr key={c.id}>
                      <td>{c.id}</td>
                      <td>{c.information ? `${c.information}` : "-"}</td>
                      <td>{c.mileage ? `${c.mileage}` : "-"} </td>
                      <td>{c.date ? `${c.date}` : "-"}</td>
                      <td> <span
                    style={{
                      marginTop: "-6px",
                    }}
                  >
                    <label class="switch1">
                      <input
                        type="checkbox"
                        id="togBtn"
                        checked={c.notification_by_mileage}
                    
                        name="notification_by_mileage"
                      />
                      <div class="slider1 round"></div>
                    </label>
                  </span></td>
                      <td>
                        {c.notification_mileage
                          ? `${c.notification_mileage}`
                          : "-"}
                      </td>
                      <td>
                        {c.notification_date ? `${c.notification_date}` : "-"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            </span>
        )
    }
}

export default withTranslation() (Additional)
