import React from "react";
import { useTranslation } from "react-i18next";

function Fine(props) {
  const { t } = useTranslation();
  return (
    <div className="" style={{ marginTop: "30px" }}>
      <h3 className="font-weight-normal" style={{ marginTop: "30px" }}>
        {t("fine")}
      </h3>
      <hr></hr>

      {props.fine.length > 0 && (
        <table className="table" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>{t("Id")}</th>
              <th>{t("date")}</th>
              <th style={{ width: "40%" }}>{t("description")}</th>
              <th>{t("plate_number")}</th>
              <th>{t("value")}</th>
            </tr>
          </thead>
          <tbody>
            {props.fine.map((c, i) => (
              <tr key={i + 1}>
                <td>{i + 1}</td>
                <td>{c.date}</td>
                <td>{c.text}</td>
                <td>{c.plate_number ? c.plate_number : c.other}</td>
                <td>{c.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Fine;
