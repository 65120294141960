import React, { Component } from "react";
import ProfileTop from "../../../../Components/Layout/ProfileTop";
import User from "../../../../Components/User";
import Datee from "../../../Views/Date";
import Filter from "../../Filter";
import arrow_left from "../../../../img/Aarrow.png";
import { Helmet } from "react-helmet";
import {
  getDriverProfile,
  getMyDriverRecommendation,
} from "../../../../Service/Driver";
import { withTranslation } from "react-i18next";
import Conatct from "../../../Driver/Conatct/Conatct";
import Time from "./Graph/Time";
import Temprature from "./Graph/Temprature";
import Weather from "./Graph/Weather";
import Type from "./Percent/Type";
import Zone from "./Percent/Zone";
import Road from "./Percent/Road";
import Driving from "./Percent/Driving";
import contact from "../../../../img/contact.png";
import edit from "../../../../img/edit.png";
import active from "../../../../img/active.png";
import print from "../../../../img/Rating/print.png";
import print_pl from "../../../../img/Rating/print_pl.png";
import DateGte from "../../../../Service/Arrow/DateGte";
import DateLte from "../../../../Service/Arrow/DateL";
import getPrint from "../../../../Service/Arrow/Print";
import arrowL from "../../../../Service/Arrow/Arrow";
import UsedCar from "./Table/UsedCar";
import Fine from "./Table/Fine";
import Spinner from "../../../../Container/Spinner";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import Chart from "./Chart/Chart";
const moment = extendMoment(originalMoment);

export class MyProfile extends Component {
  constructor(props) {
    super(props);
    const today = moment();
    this.state = {
      stats: [],
      carr: [],
      temp: [],
      time: [],
      weather: [],
      road: [],
      drive: [],
      zone: [],
      kind: [],
      detail: [],
      basic: [],
      demo: [],
      isOpen: false,
      today: null,
      lol: null,
      fol: null,
      lol1: null,
      fol1: null,
      note: [],
      isLoading: true,
      chart: [],
      widgets: [],
      fines: [],
      recommend: [],
      value: moment.range(today.clone().subtract(0, "days"), today.clone()),
    };
  }

  onToggle = (event) => {
    if (event.target.value === "date") {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  handleClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  // Get Data from filter date
  getData = async (value, states) => {
    this.setState({ isLoading: true });
    getDriverProfile(
      this.props.match.params.pk,
      value.end.format("YYYY-MM-DD"),
      value.start.format("YYYY-MM-DD")
    ).then((data) => {
      this.setState({
        stats: data,
        temp: data.temperature,
        time: data.time,
        weather: data.weather,
        road: data.road,
        drive: data.drive,
        zone: data.zone,
        kind: data.kind,
        detail: data.stats,
        basic: data.basicInformation,
        demo: data.demographicInformation,
        widgets: data.widgets,
        lol1: value.start,
        fol1: value.end,
        carr: data.usedCars,
        chart: data.charts,
        note: data.notes,
        fines: data.fines,
        isOpen: !this.state.isOpen,
        isLoading: false,
      });
    });
  };

  getOptionData = async () => {
    this.setState({ isLoading: true });
    Promise.all([
      getDriverProfile(
        this.props.match.params.pk,
        DateGte(this.state.today),
        DateLte(this.state.today)
      ).then((data) => {
        this.setState({
          stats: data,
          temp: data.temperature,
          time: data.time,
          weather: data.weather,
          road: data.road,
          drive: data.drive,
          zone: data.zone,
          kind: data.kind,
          detail: data.stats,
          basic: data.basicInformation,
          demo: data.demographicInformation,
          carr: data.usedCars,
          note: data.notes,
          widgets: data.widgets,
          chart: data.charts,
          fines: data.fines,
          isLoading: false,
          lol: this.state.today,
          fol: this.state.today,
        });
      }),
    ]);
  };

  onChange = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    this.getOptionData("");
  };

  getRecommendation = async () => {
    getMyDriverRecommendation(this.props.match.params.pk).then((data) => {
      this.setState({
        recommend: data,
      });
    });
  };
  componentDidMount() {
    this.getOptionData("");
    this.getRecommendation("");
  }

  // componentDidUpdate() {
  //   this.getOptionData("");
  // }

  render() {
    const {
      stats,
      detail,
      temp,
      time,
      weather,
      road,
      drive,
      zone,
      note,
      kind,
      basic,
      demo,
      lol,
      fol,
      lol1,
      fol1,
      carr,
      chart,
      widgets,
      recommend,
    } = this.state;
    const { t } = this.props;
    var d = new Date();
    var mm = d.getMonth() + 1;
    var dd = d.getDate();
    var yy = d.getFullYear();
    var ccc = yy + "-" + mm + "-" + dd; //(US)
    const base = process.env.PUBLIC_URL;
    return (
      <div
        className="Main_section"
        style={{ marginTop: "30px", paddingLeft: "30px", paddingRight: "30px" }}
      >
        <Helmet>
          <title>
            {" "}
            {stats.first_name
              ? stats.first_name
              : "Driver Metrics Profile Details"}{" "}
            {stats.last_name
              ? stats.last_name
              : "Driver Metrics Profile Details<"}{" "}
            Metric Profile Details
          </title>
          <meta charSet="utf-8" />
          <meta
            name="Helmet application"
            content="Driver profile and metric page including all overall details score, basic information, formal information, vehicle information and so on ."
          />
        </Helmet>

        <Conatct load={stats} id={this.props.match.params.pk} />

        <div className="row row1" style={{ width: "100%", marginTop: "40px" }}>
          <div className="col-sm-3">
            <strong>
              {t("Hello")}, <User />
            </strong>
            <Datee />
          </div>
          <div className="col-sm-6">
            <Filter
              lol={this.state.lol1}
              fol={this.state.fol1}
              today={this.state.today}
              option={this.state.isOpen}
              toggle={this.onToggle}
              change={this.getData}
              hello={this.onChange}
            />
          </div>
        </div>
        <ProfileTop />
        <div className="headerr" id="headerr" style={{ marginTop: "-6px" }}>
          {stats.detail ? (
            this.state.isLoading === true ? (
              <Spinner />
            ) : (
              <p
                className="text-center h4 font-weight-bolder"
                style={{ marginTop: "30px" }}
              >
                {t("no_data")}
              </p>
            )
          ) : (
            <div>
              {this.state.isLoading === true ? <Spinner /> : ""}
              <div>
                <a href="# " onClick={this.handleClick.bind(this)}>
                  <img
                    src={arrow_left}
                    alt="Left_Arrow"
                    title="Back to previous page"
                    style={{ height: "2.6em" }}
                  />
                </a>
                {t("back")}
                &nbsp;&nbsp; &nbsp; &nbsp;&nbsp;{" "}
                {t("driver_profile").toUpperCase()} /{" "}
                <strong>
                  {stats.first_name ? stats.first_name : "-"}{" "}
                  {stats.last_name ? stats.last_name : "-"}
                </strong>{" "}
                <img
                  src={active}
                  className={
                    stats.app_staus === true ? "App_user" : "not_app_user"
                  }
                  alt="App Data"
                  title={
                    stats.app_staus === true ? t("app_user") : t("app_not_user")
                  }
                />
                &nbsp; &nbsp;{" "}
                <a
                  href={base + "/overview/app/my/driver/" + stats.id + "/"}
                  className="font-weight-bold"
                  title="Click to view driver trips"
                  style={{
                    color: "#b71c1c",
                    position: "relative",
                    right: "-50px",
                  }}
                >
                  {t("trips").toUpperCase()}
                </a>
              </div>

              <div className="" style={{ marginTop: "-50px" }}>
                <nav className="navbar navbar-expand-lg navbar-dark float-right">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item  ">
                      <section>
                        <a
                          className=""
                          href="#contact"
                          rel="modal:open"
                          title="Click to contact driver"
                        >
                          <img
                            src={contact}
                            alt="contact"
                            className={
                              window.localStorage.i18nextLng === "pl"
                                ? "polish setting1"
                                : "setting1"
                            }
                          />
                          <br></br>
                          <span
                            style={{
                              color: "rgb(183, 28, 28)",
                              fontSize: "11px",
                              marginLeft: "28px",
                              position: "relative",
                              top: "3px",
                            }}
                          >
                            {t("contact")}
                          </span>
                        </a>
                      </section>
                    </li>

                    <li className="nav-item  ">
                      <section>
                        <a
                          className=""
                          title="Click to edit driver data"
                          href={
                            base + `/editdriver/app/drivermetrics/${stats.id}/`
                          }
                        >
                          <img
                            src={edit}
                            alt="contact"
                            className={
                              window.localStorage.i18nextLng === "pl"
                                ? "polish1"
                                : "english"
                            }
                            style={{
                              width: "1.6em",
                              marginLeft: "15px",
                              marginTop: "5px",
                            }}
                          />
                          <br></br>
                          <span
                            style={{
                              color: "rgb(183, 28, 28)",
                              fontSize: "11px",
                              marginLeft: "15px",
                            }}
                          >
                            {t("edit")}
                          </span>
                        </a>
                      </section>
                    </li>

                    <li className="nav-item  " style={{ marginLeft: "20px" }}>
                      <a
                        href="# "
                        onClick={getPrint}
                        title="Click to print page in pdf form"
                      >
                        <img
                          src={
                            window.localStorage.i18nextLng === "en"
                              ? print
                              : print_pl
                          }
                          alt="Print page"
                          style={{ marginTop: "6px", height: "3em" }}
                        />
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

              <div
                className="row  justify-content-center"
                style={{ width: "100%", paddingLeft: "30px" }}
              >
                {widgets.map((ii, i) => (
                  <div
                    className="col-md-2 something_image"
                    style={{
                      marginTop: "5px",

                      backgroundImage: `url(${ii.background})`,
                    }}
                    key={i + 1}
                  >
                    <div
                      className="box  "
                      style={{
                        width: "200px",
                        marginTop: "25px",
                        height: "132px",
                      }}
                    >
                      <div className="fleet_data">
                        {ii.link === "critical" ? (
                          <p id="fleet_main_title" className="text-left">
                            <a
                              href={
                                base +
                                `/critical/overspeed/data/${stats.id}/${
                                  lol1
                                    ? `${lol1.format("YYYY-MM-DD")}`
                                    : lol
                                    ? DateLte(this.state.today)
                                    : "2016-01-04"
                                }/${
                                  fol1
                                    ? `${fol1.format("YYYY-MM-DD")}`
                                    : fol
                                    ? DateGte(this.state.today)
                                    : `${ccc}`
                                }`
                              }
                              title="Click to view Critical overSpeeding"
                              id="fleet_main_title "
                              className="link_title"
                            >
                              {ii.label ? ii.label : "-"}
                            </a>
                          </p>
                        ) : (
                          <p id="fleet_main_title" className="text-left">
                            {ii.label ? ii.label : "-"}
                          </p>
                        )}

                        <div className="row  text-section ">
                          <div
                            className={
                              ii.icon === null ? "col-md-12" : "col-md-5"
                            }
                          >
                            <h1 className="fleet_title">
                              {" "}
                              {ii.value ? ii.value : "-"}
                            </h1>

                            <p className="percentage_change">
                              {arrowL(
                                ii.changeDirection,
                                ii.changePositive,
                                ii.change
                              )}
                            </p>
                          </div>
                          <div
                            className={ii.icon === null ? "col" : "col-md-7"}
                          >
                            {ii.icon !== null && (
                              <img
                                src={ii.icon ? ii.icon : ""}
                                alt="weight"
                                className="fleet_image"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="fleet_graph" style={{ paddingLeft: "50px" }}>
                <div
                  className="row just_finish justify-content-center"
                  style={{ width: "100%" }}
                >
                  {chart.map((c, i) => (
                    <div className="col-md-6 sm" key={i + 1}>
                      <Chart chart={chart} i={c} />
                    </div>
                  ))}
                </div>
              </div>

              <div className="row" style={{ marginTop: "50px" }}>
                {/* Basic information section */}
                <div className="col-md-6">
                  <h3
                    className="font-weight-normal"
                    style={{ marginTop: "30px" }}
                  >
                    {t("demographic_information")}
                  </h3>
                  <hr></hr>
                  <div className="row">
                    {basic.map((c, i) => (
                      <div
                        className="col-sm-6"
                        style={{ marginBottom: "20px" }}
                        key={i + 1}
                      >
                        <div className=" n">
                          <p id="pppp">{c.label.toUpperCase()}</p>
                          <p className="pppp1">
                            {c.value ? `${c.value}` : "-"}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Demographic information section */}
                <div className="col-md-6">
                  <h3
                    className="font-weight-normal"
                    style={{ marginTop: "30px" }}
                  >
                    {t("formal_information")}
                  </h3>
                  <hr></hr>
                  <div className="row">
                    {demo.map((c, i) => (
                      <div
                        className="col-sm-6"
                        style={{ marginBottom: "20px" }}
                        key={i + 1}
                      >
                        <div className=" n">
                          <p id="pppp">{c.label.toUpperCase()}</p>
                          <p className="pppp1">
                            {c.value
                              ? c.value === "Male"
                                ? t("male")
                                : c.value === "Female"
                                ? t("female")
                                : c.value
                              : "-"}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* More information Section */}
              <div className="" style={{ marginTop: "30px" }}>
                <h3
                  className="font-weight-normal"
                  style={{ marginTop: "30px" }}
                >
                  {t("more_information")}
                </h3>
                <hr></hr>
                <div className="row">
                  {detail.map((c, i) => (
                    <div
                      className="col-sm-3"
                      style={{ marginBottom: "20px" }}
                      key={i + 1}
                    >
                      <div className=" n">
                        <p id="pppp">{c.label.toUpperCase()}</p>
                        <p className="pppp1">{c.value ? `${c.value}` : "-"}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Used Car Section */}
              <span>
                <UsedCar car={carr} />
              </span>
              {/* Used Car End Section */}

              {/* Used Car Section */}
              <span>
                <Fine fine={this.state.fines} />
              </span>
              {/* Used Car End Section */}

              {/* All Chart and histogram section */}
              <div className="static">
                <h3
                  className="font-weight-normal "
                  style={{ marginTop: "50px" }}
                >
                  {t("static")}
                </h3>
                <hr></hr>

                <div className="statics">
                  <Time load={time} />
                  <br></br>
                  <Weather load={weather} />

                  <br></br>
                  <Temprature load={temp} />

                  <br></br>

                  <Type load={kind} />
                  <br></br>
                  <Driving load={drive} />
                  <br></br>
                  <Road load={road} />
                  <br></br>
                  <Zone load={zone} />
                </div>
              </div>

              <span>{/* <Additional car={carr} /> */}</span>

              {/* Recommendation and notes Section */}
              <div className="row" style={{ marginTop: "50px" }}>
                {/* Recommendation section */}
                <div className="col-md-6">
                  <h3
                    className="font-weight-normal"
                    style={{ marginTop: "30px" }}
                  >
                    {t("Recommendation1")}
                  </h3>
                  <hr></hr>

                  {recommend.map((c) => (
                    <div className="row" key={c.id}>
                      <p
                        id="pppp"
                        style={{ fontSize: "14px", padding: "10px" }}
                      >
                        <span className="float-left">{c.date}</span>
                        &nbsp; | &nbsp;&nbsp; &nbsp;
                        <span className="font-weight-bolder">
                          {c.text_pl ? `${c.text_pl}` : "-"}
                        </span>
                      </p>
                    </div>
                  ))}
                </div>

                {/* Notes section */}
                <div className="col-md-6">
                  <h3
                    className="font-weight-normal"
                    style={{ marginTop: "30px" }}
                  >
                    {t("notes")}
                  </h3>
                  <hr></hr>
                  {note.map((c) => (
                    <div className="col-sm-12" key={c.id}>
                      <p
                        id="pppp"
                        style={{ fontSize: "14px", padding: "10px" }}
                      >
                        <span className="float-left">{c.date}</span>
                        &nbsp; | &nbsp;&nbsp; &nbsp;
                        <span className="font-weight-bolder">
                          {c.body ? `${c.body}` : "-"}
                        </span>
                      </p>
                      {/* <p
                    className="pppp1"
                    style={{ paddingLeft: "10px", marginTop: "10px" }}
                  >
                    {c.body ? `${c.body}` : "-"}
                  </p> */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(MyProfile);
