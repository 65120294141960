import React from "react";

const arrowL = (data, data1, data2) => (
  <span id="Something_lol"
    className={
      data === "down" && data1 === true
        ? "fleet_down"
        : data === "up" && data1 === true
        ? "fleet_up "
        : data === "up" && data1 === false
        ? "fleet_down "
        : data === "down" && data1 === false
        ? "fleet_up"
        : "fleet_up"
    }
  >
    <i
      className={
        data === "down" && data1 === true
          ? "fa fa-arrow-down text-danger"
          : data === "up" && data1 === true
          ? "fa fa-arrow-up text-success "
          : data === "up" && data1 === false
          ? "fa fa-arrow-up text-danger "
          : data === "down" && data1 === false
          ? "fa fa-arrow-down text-success"
          : ""
      }
    ></i>

    {data === null || data1 === null ? "" : data2}
  </span>
);

export default arrowL;
